import React from 'react'
import styles from './DetailViewCollectionAddedInfoIcon.module.scss'
type DetailViewCollectionAddedInfoIconProps = {
  count: number
  icon: React.ElementType
  tooltipText: string
}
function DetailViewCollectionAddedInfoIcon(props: DetailViewCollectionAddedInfoIconProps) {
  const { icon: Icon, count, tooltipText } = props
  return (
    <div className={styles.dvcaic_container} data-tooltip={tooltipText}>
      <p className={styles.dvcaic_c_text}>{count}</p>
      <Icon className={styles.dvcaic_c_icon} />
    </div>
  )
}

export default DetailViewCollectionAddedInfoIcon
