import { Button, Dropdown, Space, Radio } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import './dropdown.css'

export default function BottomDropdown({ icon, text, items, setValues, selectedValue, setSelectedValue }) {
  const handleChange = (e) => {
    const selectedItem = items.find((item) => (item.value || item.name) === e.target.value) || { id: 'default' }
    setSelectedValue(e.target.value)
    setValues(e, selectedItem.id)
  }

  return (
    <Dropdown
      overlay={
        <Radio.Group
          size='small'
          value={selectedValue}
          style={{
            padding: '.5rem',
            border: '2px solid #EAECF0',
            backgroundColor: '#FCFCFD',
            borderRadius: '5px',
          }}
          onChange={handleChange}>
          <Space direction='vertical'>
            <Radio key={'index'} value={'Default Maps'}>
              Default Value
            </Radio>
            {items.map((item, index) => (
              <Radio key={index} value={item.value || item.name}>
                {item.value || item.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      }>
      <Button
        style={{
          border: '2px solid #EAECF0',
          color: '#344054',
          borderRadius: '5px',
          fontSize: '15px',
          fontWeight: '600',
          backgroundColor: '#FCFCFD',
          display: 'flex',
          alignItems: 'center',
          marginRight: '.5rem',
        }}>
        <Space>
          <img alt='icon' src={icon} style={{ marginBottom: '.2rem', height: '18px' }} />
          {text}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  )
}
