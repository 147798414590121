import { Button, Form, Input, Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import CreateNarrativeUniqueContent from '../CreateNarrativeUniqueContent/CreateNarrativeUniqueContent'
import { IUniqueContent } from 'models/models'

import './CreateNarrativeAdvancedModals.scss'

interface CreateNarrativeAdvancedModalsProps {
  uniqueContentList: IUniqueContent[]
  openModal: null | 'article' | 'publish' | 'save'
  saveNarrative: () => void
  publishNarrative: () => void
  publishNarrativeLoading: boolean
  setOpenModal: (modal: null | 'article' | 'publish' | 'save') => void
}

const CreateNarrativeAdvancedModals = observer((props: CreateNarrativeAdvancedModalsProps) => {
  const { uniqueContentList, openModal, saveNarrative, publishNarrative, setOpenModal, publishNarrativeLoading } = props
  const { defineNarrativeStore } = store
  const { formData, addFormData } = defineNarrativeStore
  const PublishModalContent = (
    <div className='define-advanced-modal__publish-menu'>
      <div className='define-advanced-modal__wrapper'>
        <label>Narrative Name</label>
        <Form.Item
          className='c-define__item c-define__item--1'
          name='name'
          rules={[{ required: true, message: 'Required field' }]}>
          <Input
            onChange={(e) => {
              addFormData({ ...formData, name: e.target.value })
            }}
          />
        </Form.Item>
      </div>
      <div className='define-advanced-modal__wrapper'>
        <label>Description</label>
        <Form.Item className='c-define__item c-define__item--1' name='description'>
          <Input.TextArea
            rows={4}
            onChange={(e) => {
              addFormData({ ...formData, description: e.target.value })
            }}
          />
        </Form.Item>
      </div>
      {uniqueContentList.length > 0 && (openModal === 'publish' || openModal === 'save') && (
        <div className='define-advanced-modal__wrapper'>
          <label>Links</label>
          <div>
            {uniqueContentList.map((el) => (
              <CreateNarrativeUniqueContent item={el} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
  const onOkClick = () => {
    switch (openModal) {
      case 'publish':
        return publishNarrative()
      case 'save':
        return saveNarrative()
    }
  }
  return (
    <>
      <Modal
        className='define-advanced-modal__publish'
        width={465}
        centered
        closable
        open={openModal === 'publish' || openModal === 'save'}
        title={<div className='define-advanced-modal__header'>Publish Narrative</div>}
        children={PublishModalContent}
        footer={
          <Button className={'ant-btn ant-btn-primary'} onClick={onOkClick} loading={publishNarrativeLoading}>
            Publish Narrative
          </Button>
        }
        onCancel={() => setOpenModal(null)}
        okText={
          openModal ? openModal?.charAt(0).toUpperCase() + openModal.slice(1, openModal.length) + ' Narrative' : ''
        }
        okButtonProps={{ className: 'define-advanced-modal__publish-button' }}
        okType={'primary'}
        cancelText={'Cancel'}
      />
    </>
  )
})

export default CreateNarrativeAdvancedModals
