import { Menu } from 'antd'
import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { store } from 'store'

export const TenantNavigation = () => {
  const { pathname } = useLocation()
  const { userStore } = store
  const { currentRole } = userStore
  const NAVIGATION_TABS = useMemo(() => {
    return [
      {
        key: 'tenants',
        label: 'Tenant Management',
        path: '',
      },
    ]
  }, [currentRole])

  let selectedKeys: string[] = ['0']

  if (pathname.startsWith('/settings/tenants')) {
    selectedKeys = ['tenants']
  }

  return (
    <nav className='tenants__navigation'>
      <div className='tenants__navigation__container'>
        <Menu mode='horizontal' selectedKeys={selectedKeys} triggerSubMenuAction='click'>
          {NAVIGATION_TABS.map((tab) => {
            return (
              <Menu.Item key={tab.key} id={tab.key}>
                <Link to={tab.path}>{tab.label}</Link>
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
    </nav>
  )
}
