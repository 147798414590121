import './LoadingState.scss'

type LoadingStateProps = {
  className?: string
}

function LoadingState(props: LoadingStateProps) {
  const { className = '' } = props
  return (
    <div className={`loading_state_container${className}`}>
      <div className='lsc_loader'></div>
      <p className='lsc_wait_text'>Please Wait...</p>
      <p className='lsc_wait_message'>Pendulum is gathering the data</p>
    </div>
  )
}

export default LoadingState
