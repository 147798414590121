import { ListPageSection } from 'components/common/ListPageSection/ListPageSection'
import { DetailsSection } from '../DetailsSection/DetailsSection'
import CombineDetailsView from 'pages/Vectors/CombineDetailsView/CombineDetailsView'

export const AnticipatoryFeed = () => {
  return (
    <div>
      <ListPageSection
        left={<DetailsSection />}
        right={
          <CombineDetailsView
            pageType={'details'}
            subStore={'anticipatoryIntelligence'}
            page={'anticipatoryIntelligence'}
          />
        }
        columns={{
          col1: {
            sm: 24,
            md: 8,
            lg: 8,
          },
          col2: {
            sm: 24,
            md: 16,
            lg: 16,
          },
        }}
      />
    </div>
  )
}
