import { useEffectOnce } from 'react-use'
import { Divider, Table } from 'antd'
import { Button } from 'components/common/Button/Button'
import { SetStateAction, useState } from 'react'
import { format } from 'date-fns'
import TextArea from 'antd/lib/input/TextArea'
import { spotlightAPI } from 'api/api'
import { getUsers } from 'api/users/getUsers.api'
import { observer } from 'mobx-react-lite'
import { UtilService } from 'services/Util/Util'
import { IUsers } from 'models/models'
import { useEffect } from 'react'
import { Dropdown, Input, Menu } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { store } from 'store'
import './Spotlight.scss'
import { ReactComponent as OpenIcon } from 'assets/images/open-icon.svg'
import { ReactComponent as TrashIcon } from 'assets/images/icon-trash.svg'
import dayjs from 'dayjs'
import { Card } from '../common/Card/Card'
import { Heading } from '../common/Heading/Heading'
import { ActionModal } from 'components/common/ActionModal/ActionModal'
export const Spotlight = observer(() => {
  const [isSpotlightModalVisible, setSpotlightModalVisible] = useState(false)
  const [briefText, setBriefText] = useState('')
  const initialSpotlightList: any[] | (() => any[]) = []
  const [spotlightList, setSpotlightList] = useState(initialSpotlightList)
  const initialSpotlight = {}
  const [currentSpotlight, setCurrentSpotlight] = useState(initialSpotlight)
  const snippetList: any[] | (() => any[]) = []
  const [snippets, setSnippets] = useState(snippetList)
  const [inputValue, updateInputValue] = useState('')
  const [users, setUsers] = useState<IUsers[]>([])
  const initialList: any[] | (() => any[]) = []
  const [selectedUsers, setSelectedUsers] = useState(initialList)
  const [inputDropdownState, setInputDropdownState] = useState(false)
  const { userStore } = store
  const loggedInUser = {
    userID: userStore.userId,
    userName: userStore.userName,
    userEmail: userStore.userEmail,
    givenName: userStore.given_name,
    familyName: userStore.family_name,
  }

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await getUsers()
        users.push(response.data)
        setUsers(response.data)
        selectedUsers.push({
          user_id: loggedInUser.userID,
          username: loggedInUser.userName,
          given_name: loggedInUser.givenName,
          family_name: loggedInUser.familyName,
        })
      } catch (error: any) {
        const response = error.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
        console.error('Error fetching users:', error)
      }
    }
    fetchUsers()
  }, [])

  const filteredUsers = users
    .filter((user: IUsers) => {
      const username = user.username?.toLowerCase()
      const realName = `${user.given_name} ${user.family_name}`?.toLowerCase()
      return username.includes(inputValue.toLowerCase()) || realName.includes(inputValue.toLowerCase())
    })
    .slice(0, 4)

  const currentUser = users.filter((user: IUsers) => user.id === loggedInUser.userID)
  let usersFiltered = !inputValue ? [...currentUser, ...users] : filteredUsers
  usersFiltered = usersFiltered.filter((user) => {
    return user.id !== loggedInUser.userID
  })

  const inputDropdownMenu = (
    <Menu className='share__users__input--dropdown'>
      {usersFiltered?.map((user: IUsers, index) => {
        const realName = `${user.given_name} ${user.family_name}`
        return (
          <div
            onClick={() => {
              setSelectedUsers((users: any) => {
                const userEmail = user.username
                if (!users.some((usr: any) => usr.username === userEmail)) {
                  return [
                    ...users,
                    {
                      user_id: user.id,
                      username: user.email,
                      given_name: user.given_name,
                      family_name: user.family_name,
                    },
                  ]
                } else return users.filter((usr: any) => usr.username !== userEmail)
              })
              const recipientInfo = {
                user_id: user.id,
                given_name: user.given_name,
                email: user.email,
                username: user.username,
                family_name: user.family_name,
              }
              selectedUsers.push(recipientInfo)
              setInputDropdownState(false)
            }}
            className='share__users__info-container'
            key={uuidv4()}>
            <div>
              <div className='share__users__details--name'>{realName}</div>
              <div className='share__users__details--email'>{user.username}</div>
            </div>
          </div>
        )
      })}
    </Menu>
  )
  const removeSelectedUser = (email: string) => {
    if (email === loggedInUser.userEmail) {
      UtilService.openNotification({
        type: 'error',
        message: 'Cannot remove owner from email list!',
      })
    } else {
      setSelectedUsers((users) => users.filter((user) => user.username !== email))
    }
  }
  useEffectOnce(() => {
    if (!spotlightList?.length) listSpotlightRecords()
  })

  const listSpotlightRecords = async () => {
    try {
      const response = await spotlightAPI.listSpotlights()
      const spotlightDates: SetStateAction<any[]> = []
      response.map((date: { created_on: any }) => {
        const createdOn = format(new Date(date.created_on), 'MMMM do, yyyy')
        spotlightDates.push(createdOn)
        date.created_on = createdOn
        return date.created_on
      })
      setSpotlightList(response)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })

      console.error('Error fetching data:', error)
    }
  }

  const getSpotlight = async (spotlightId: any) => {
    try {
      const response = await spotlightAPI.getSpotlight(spotlightId)
      const spotlightContent = JSON.parse(response.spotlight_content)
      const trimmedSnippets = spotlightContent[0].snippets.filter(
        (dict: { [s: string]: unknown } | ArrayLike<unknown>) =>
          Object.values(dict).some((value) => value !== null && value !== ''),
      )
      const currentSpotlight = {
        spotlightId: response.id,
        brief: spotlightContent[0].brief,
        snippets: trimmedSnippets,
      }
      setCurrentSpotlight(currentSpotlight)
      setSnippets(currentSpotlight.snippets)
      if (currentSpotlight.brief) {
        setBriefText(currentSpotlight.brief)
      }
      return currentSpotlight
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      console.error('Error fetching data:', error)
    }
  }

  const removeSnippet = async (post_id: string) => {
    try {
      await spotlightAPI.spotlightActions({
        action: 'REMOVE_SNIPPET',
        snippet: {
          post_id: post_id,
        },
      })
      setSnippets((snippets) => snippets.filter((snip) => snip.post_id !== post_id))
      UtilService.openNotification({ type: 'info', message: 'Success', description: 'Snippet removed successfully' })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      console.error('Error fetching data:', error)
    }
  }

  const sendSpotlight = async (currentSpotlight: any, selectedUsers: any, briefText: string) => {
    try {
      await spotlightAPI.sendSpotlight({
        id: currentSpotlight.spotlightId,
        recipients: selectedUsers,
        brief: briefText,
      })
      UtilService.openNotification({ type: 'info', message: 'Success', description: 'Spotlight sent successfully' })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  const generateBrief = async (currentSpotlight: any) => {
    try {
      const response = await spotlightAPI.spotlightActions({
        action: 'GENERATE_BRIEF',
        spotlight_id: currentSpotlight.spotlightId,
      })
      setBriefText(response)
      UtilService.openNotification({ type: 'info', message: 'Success', description: 'Brief created successfully' })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      console.error('Error fetching data:', error)
    }
  }

  const editBrief = (event: { target: { value: SetStateAction<string> } }) => {
    setBriefText(event?.target.value)
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: '1',
      sorter: (a: any, b: any) => {
        return a.created_on.localeCompare(b.created_on)
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: '2',
      render: (id: any) => {
        return (
          <Button
            type='secondary'
            onClick={() => {
              getSpotlight(id)
              setSpotlightModalVisible(true)
            }}>
            Send Spotlight
          </Button>
        )
      },
    },
  ]

  return (
    <Card className='c-users u-p-t-32' variant='1'>
      <section className='c-users__heading'>
        <Heading level='1' variant='2' className='u-m-b-8'>
          Spotlight Dashboard
        </Heading>
      </section>
      <Divider plain />
      <Table
        columns={columns}
        dataSource={spotlightList}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
      />

      {/*send spotlight modal*/}
      <ActionModal
        title='Send Spotlight'
        okText='Send'
        visible={isSpotlightModalVisible}
        overflow='scroll'
        maxHeight={600}
        onOk={async () => {
          setSpotlightModalVisible(false)
          setBriefText('')
          sendSpotlight(currentSpotlight, selectedUsers, briefText)
        }}
        onCancel={() => {
          setSpotlightModalVisible(false)
          setSnippets([])
        }}>
        <h3 style={{ marginBottom: '10px' }}>Brief</h3>
        <TextArea
          onChange={editBrief}
          style={{ marginRight: '10px', width: '65%', display: 'inline-block' }}
          value={briefText}
          placeholder='Generate or write brief'></TextArea>
        <Button
          style={{ display: 'inline-block' }}
          type='secondary'
          onClick={() => {
            generateBrief(currentSpotlight)
          }}>
          Generate Brief
        </Button>
        <h3 style={{ marginTop: '10px' }}>Add recipients</h3>
        <div>
          <Dropdown
            trigger={['click']}
            overlay={inputDropdownMenu}
            open={inputDropdownState}
            onOpenChange={() => {
              setInputDropdownState((state) => !state)
            }}>
            <Input
              className='share__users__search'
              placeholder={'Search by name or email'}
              onChange={(el) => updateInputValue(el.target.value)}
              value={inputValue}
            />
          </Dropdown>
          {selectedUsers &&
            selectedUsers?.map((user) => {
              return (
                <div className='share__users__info-container' key={uuidv4()}>
                  <div className='share__users__info-container___details--img'></div>
                  <div>
                    <div className='share__users__info-container__details--name'>
                      {user.given_name + ' ' + user.family_name}
                    </div>
                    <div className='share__users__info-container__details--email'>{user.username}</div>
                  </div>
                  <div className='share__users__info-container__details--role' style={{ marginRight: '20px' }}>
                    <button
                      className='share__users__remove-btn'
                      onClick={() => {
                        removeSelectedUser(user.username)
                      }}>
                      Remove
                    </button>
                  </div>
                </div>
              )
            })}
        </div>
        <h3>Snippets</h3>
        {snippets.map((snippet) => {
          var offset = snippet?.offset
          var hours = Math.floor(offset / 3600)
          var minutes = Math.floor(offset / 60)
          return (
            <div
              className='spotlight'
              style={{ borderStyle: 'solid', borderWidth: '2px', borderRadius: '5px', borderColor: 'black' }}>
              <p style={{ margin: '10px' }} className='spotlight__description'>
                {snippet.snippet_text}
              </p>
              <div className='spotlight__footer'>
                <div className='spotlight__footer__left'>
                  <div className='spotlight__footer__left__list' style={{ marginLeft: '10px' }}>
                    <span>{snippet?.impression_count} views</span>
                    <div className='spotlight__dot' />
                    <span>{snippet?.channel_name} </span>
                    <div className='spotlight__dot' />
                    <span>{snippet?.platform}</span>
                    <div className='spotlight__dot' />
                    <span>{dayjs(snippet.upload_date).format('MMM DD, YYYY')}</span>
                    <div className='spotlight__dot' />
                    <span>
                      Start: {hours}h {minutes}m
                    </span>
                  </div>
                </div>
                <div style={{ display: 'block' }}>
                  <button
                    style={{ float: 'left', margin: '2px', height: '20px' }}
                    className='spotlight__footer__right__preview-btn'
                    onClick={() => {
                      window.open(snippet.post_url || '#', '_blank', 'noopener,noreferrer')
                    }}>
                    <span>Preview</span>
                    <OpenIcon height={18} />
                  </button>
                  <button
                    className='spotlight__footer__right__preview-btn'
                    style={{
                      float: 'right',
                      color: 'black',
                      margin: '2px',
                      height: '20px',
                    }}
                    onClick={() => {
                      removeSnippet(snippet.post_id)
                    }}>
                    Remove
                    <TrashIcon height={15} />
                  </button>
                </div>
              </div>
            </div>
          )
        })}
      </ActionModal>
    </Card>
  )
})
