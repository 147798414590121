import { Tabs, TabsProps, Tag } from 'antd'
import { ReactComponent as DropdownIcon } from 'assets/images/icon-dropmenu-wider.svg'
import { isEmpty } from 'lodash'
import PulseDDListOptions from 'pages/PendulumPulse/PulseDDListOptions'
import { CategoriesTypes, OptionTypes } from 'pages/PendulumPulse/PulseLeftComponent'
import { useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { store } from 'store'
import { Unknown } from 'utils/commonTypes'
import { observer } from 'mobx-react-lite'
import './ContentSelector.scss'

type ItemsType = {
  brand: Unknown[]
  flags: Unknown[]
  searches: Unknown[]
  influencers: Unknown[]
  bookmarks: Unknown[]
}

const ContentSelector = ({
  items,
  setItems,
  key,
}: {
  key: string
  items: ItemsType
  setItems: (items: ItemsType) => void
}) => {
  const [isTabsOpen, setIsTabsOpen] = useState(false)

  const toggleTabs = () => setIsTabsOpen(!isTabsOpen)
  const [selectedTab, setSelectedTab] = useState<'searches' | 'brand' | 'flags' | 'bookmarks' | 'influencers'>(
    'searches',
  )

  const { assetsStore, vectorsStore, flagsStore, reportMainViewStore } = store
  const {
    flagsList,
    fetchFlagsListWithConditions,
    paginationValues: flagsPagination,
    setPaginationValues: setFlagsPagination,
  } = flagsStore
  const {
    fetchNarratives,
    fetchWatchlist,
    narrativesListData,
    watchlistListData,
    narrativeLoading,
    paginationValues: searchPagination,
    setPaginationValues: setSearchPagination,
  } = vectorsStore
  const {
    assetsList,
    assetsLoading,
    fetchAssetsList,
    fetchCategories,
    assetsCategories,
    paginationValues: brandPagination,
    setPaginationValues: setBrandPagination,
  } = assetsStore
  const {
    bookmarksList,
    fetchBookmarksWithParams,
    setCurrPage: setBookmarkCurrentPage,
    currPage: currentPageBookmark,
    bookmarksLoading,
  } = reportMainViewStore

  useEffectOnce(() => {
    const requestParams = {
      is_asset: true,
    }
    fetchCategories(requestParams)
  })

  useEffect(() => {
    const firstCallRequestParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
    }

    if (selectedTab === 'searches') {
      fetchNarratives({ params: firstCallRequestParams })
      setSearchPagination({ ...searchPagination, page: 1 })
    }

    if (selectedTab === 'flags') fetchFlagsListWithConditions({ params: firstCallRequestParams })

    if (selectedTab === 'influencers') {
      fetchWatchlist({ params: firstCallRequestParams })
      setSearchPagination({ ...searchPagination, page: 1 })
    }

    if (selectedTab === 'brand')
      fetchAssetsList({
        params: {
          ...firstCallRequestParams,
          is_asset: true,
          q: `category_id:eq:${brandCategory?.category_id}`,
        },
      })
    if (selectedTab === 'bookmarks') {
      fetchBookmarksWithParams(firstCallRequestParams)
      setBookmarkCurrentPage(1)
    }
  }, [selectedTab])

  const firstCallRequestParams = {
    page: 1,
    per_page: 10,
    sort: 'name:asc',
  }
  const brandCategory = assetsCategories?.find((item) => item.name === 'Brands')

  const assetsOptions = assetsList?.items?.map((asset) => ({
    label: asset.name,
    value: asset.id,
    type: 'ASSET',
    ...asset,
  }))
  const narrativeOptions = narrativesListData?.items?.map((narrative) => ({
    label: narrative.name,
    value: narrative.id,
    type: 'NARRATIVE',

    ...narrative,
  }))
  const watchlistOptions = watchlistListData?.items?.map((watchlist) => ({
    label: watchlist.name,
    value: watchlist.id,
    type: 'WATCHLIST',

    ...watchlist,
  }))
  const flagsOptions = flagsList?.items?.map((flag) => ({
    label: flag.name,
    value: flag.id,
    type: 'FLAG',
    ...flag,
  }))
  const bookmarksOptions = bookmarksList?.items?.map((bookmark) => ({
    label: bookmark.name,
    value: bookmark.id,
    type: 'BOOKMARK',
    ...bookmark,
  }))

  const onScroll = async () => {
    if (selectedTab === 'brand' && assetsList.total_count > assetsList.items?.length) {
      await fetchAssetsList({
        params: {
          ...firstCallRequestParams,
          page: brandPagination.page + 1,
          is_asset: true,
          q: `category_id:eq:${brandCategory?.category_id}`,
        },
        isInfiniteScrolling: true,
        fetchConditions: false,
      })
      setBrandPagination({ ...brandPagination, page: brandPagination.page + 1 })
    }

    if (selectedTab === 'flags' && flagsList.total_count > flagsList.items?.length) {
      await fetchFlagsListWithConditions({
        params: { ...firstCallRequestParams, page: flagsPagination.page + 1 },
        isInfiniteScrolling: true,
      })
      setFlagsPagination({ ...flagsPagination, page: flagsPagination.page + 1 })
    }

    if (selectedTab === 'influencers' && watchlistListData.total_count > watchlistListData.items?.length) {
      await fetchWatchlist({
        params: { ...firstCallRequestParams, page: searchPagination.page + 1 },
        isInfiniteScrolling: true,
      })
      setSearchPagination({ ...searchPagination, page: searchPagination.page + 1 })
    }

    if (selectedTab === 'searches' && narrativesListData.total_count > narrativesListData.items?.length) {
      await fetchNarratives({
        params: { ...firstCallRequestParams, page: searchPagination.page + 1 },
        isInfiniteScrolling: true,
      })
      setSearchPagination({ ...searchPagination, page: searchPagination.page + 1 })
    }
    if (selectedTab === 'bookmarks' && bookmarksList.total_count > bookmarksList.items?.length) {
      await fetchBookmarksWithParams({ ...firstCallRequestParams, page: currentPageBookmark + 1 })
      setBookmarkCurrentPage(currentPageBookmark + 1)
    }
  }

  const handleChangeItems = (values: OptionTypes) => {
    setItems({
      ...items,
      [values.category]: modifySelectionState(items[values.category], values),
    })
  }

  const tabItems: TabsProps['items'] = [
    {
      key: 'searches',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Searches</span>
          {!isEmpty(items.searches) && <span className='custom-tab-label__counter'>{items.searches?.length}</span>}
        </div>
      ),
      children: (
        <PulseDDListOptions
          category='searches'
          onScroll={onScroll}
          loading={narrativeLoading}
          options={narrativeOptions || []}
          modalSelectedContentItems={items}
          handleModalSelectedContentItems={handleChangeItems}
        />
      ),
    },
    {
      key: 'brand',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Brands</span>
          {!isEmpty(items.brand) && <span className='custom-tab-label__counter'>{items.brand?.length}</span>}
        </div>
      ),
      children: (
        <PulseDDListOptions
          category='brand'
          onScroll={onScroll}
          loading={assetsLoading}
          options={assetsOptions || []}
          modalSelectedContentItems={items}
          handleModalSelectedContentItems={handleChangeItems}
        />
      ),
    },
    {
      key: 'influencers',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Influencers</span>
          {!isEmpty(items.influencers) && (
            <span className='custom-tab-label__counter'>{items.influencers?.length}</span>
          )}
        </div>
      ),
      children: (
        <PulseDDListOptions
          onScroll={onScroll}
          loading={narrativeLoading}
          options={watchlistOptions}
          category='influencers'
          modalSelectedContentItems={items}
          handleModalSelectedContentItems={handleChangeItems}
        />
      ),
    },
    {
      key: 'bookmarks',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Bookmarks</span>
          {!isEmpty(items.bookmarks) && <span className='custom-tab-label__counter'>{items.bookmarks?.length}</span>}
        </div>
      ),
      children: (
        <PulseDDListOptions
          onScroll={onScroll}
          loading={bookmarksLoading}
          options={bookmarksOptions}
          category='bookmarks'
          modalSelectedContentItems={items}
          handleModalSelectedContentItems={handleChangeItems}
        />
      ),
    },
    {
      key: 'flags',
      label: (
        <div className='custom-tab-label'>
          <span className='custom-tab-label__label'>Flags</span>
          {!isEmpty(items.flags) && <span className='custom-tab-label__counter'>{items.flags?.length}</span>}
        </div>
      ),
      children: (
        <PulseDDListOptions
          onScroll={onScroll}
          loading={false}
          options={flagsOptions}
          category='flags'
          modalSelectedContentItems={items}
          handleModalSelectedContentItems={handleChangeItems}
        />
      ),
    },
  ]

  const onChangeTab = (key: string) => {
    setSearchPagination({ ...searchPagination, page: 1 })
    setFlagsPagination({ ...flagsPagination, page: 1 })
    setBrandPagination({ ...brandPagination, page: 1 })
    setBookmarkCurrentPage(1)
    setSelectedTab(key as CategoriesTypes)
  }

  const modifySelectionState = (existingData: OptionTypes[], newData: OptionTypes) => {
    const isAlreadyExist = existingData?.some((item) => item.value === newData.value)
    if (isAlreadyExist) return existingData?.filter((item) => item.value !== newData.value)
    return [...existingData, newData]
  }

  return (
    <div className='content-selector'>
      <div className='modal-selected-content-container' onClick={toggleTabs}>
        {Object.entries(items)?.map(([key, value]) =>
          isEmpty(value) ? null : (
            <Tag
              className='selected-content-tag'
              onMouseDown={() => null}
              closable={true}
              onClose={() => setItems({ ...items, [key]: [] })}
              style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}>
              <span className='category'>{key}</span>
              {/* @ts-ignore */}
              <span className='category-values'>{value?.map((item) => item.name)?.join(', ')}</span>
            </Tag>
          ),
        )}
        <DropdownIcon className='drop-down-arrow' />
      </div>
      {isTabsOpen && <Tabs defaultActiveKey='1' items={tabItems} onChange={onChangeTab} key={key || 'tabs'} />}
    </div>
  )
}

export default observer(ContentSelector)
