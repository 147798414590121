import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import './commonStyles.scss'

const EditButton = ({ onClick = () => {} }: { onClick?: () => void }) => (
  <div className='edit-button' onClick={onClick}>
    <EditIcon />
    <span>Edit</span>
  </div>
)

export default EditButton
