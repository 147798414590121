import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { ApiMethodType } from 'store/report/valueTypes'

export function getOrDeleteReport(method: ApiMethodType, id: string, instanceId?: string, data?: any) {
  return axios({
    method: method,
    url: `${ROUTES.reports}/${id}`,
    params: instanceId ? { instance_id: instanceId } : {},
    data: data || {},
  })
}
