import { Drawer } from 'antd'
import { RecurrenceForm, RecurrenceOptions } from '../steps/Recurrence'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as CloseIcon } from 'assets/images/icon-close.svg'
import { ReactComponent as ClockIcon } from 'assets/images/icons/report/clock.svg'
import './commonStyles.scss'

type RecurrenceFormProps = {
  recurrenceOptions: RecurrenceOptions
  setRecurrenceOptions: (options: RecurrenceOptions) => void
  editMode?: boolean
}
type Props = {
  openEditRecurrence: boolean
  closeRecurrenceForm: (status?: boolean) => void
} & RecurrenceFormProps

export default function EditRecurrence({ openEditRecurrence, closeRecurrenceForm, ...props }: Props) {
  return (
    <Drawer open={openEditRecurrence} onClose={() => closeRecurrenceForm(false)} width={718} closable={false}>
      <div className='edit-form-wrapper'>
        <div className='edit-form-wrapper__header'>
          <div className='title'>
            <ClockIcon />
            <span>Reoccurrence</span>
          </div>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => closeRecurrenceForm(false)} />
        </div>
        <RecurrenceForm editMode {...props} />
        <div className='review-form-actions '>
          <ButtonImage onClick={() => closeRecurrenceForm()} className='cancel-btn'>
            Cancel
          </ButtonImage>
          <ButtonImage onClick={() => closeRecurrenceForm(true)} className='continue-btn'>
            Save
          </ButtonImage>
        </div>
      </div>
    </Drawer>
  )
}
