import { Spin, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'

import AlertModal from 'components/AlertModal/AlertModal'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import SuccessModal from 'components/Flags/SuccessModal/SuccessModal'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import { DefaultPagination } from 'components/common/Pagination/Pagination'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import FolderPanel from 'components/Monitor/FolderPanel/FolderPanel'
import GridViewSection from 'components/Monitor/GridViewSection/GridViewSection'
import ListViewSection from 'components/Monitor/ListViewSection/ListViewSection'
import MoveToFolderModal from 'components/Monitor/MoveToFolderModal/MoveToFolderModal'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'

import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import { ReactComponent as AddIcon } from 'assets/images/icons/monitor/add_icon.svg'
import { ReactComponent as GridIcon } from 'assets/images/icons/monitor/grid_icon.svg'
import { ReactComponent as ListIcon } from 'assets/images/icons/monitor/list_icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/monitor/close_icon.svg'
import { ReactComponent as FolderIcon } from 'assets/images/icons/monitor/folder_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-20.svg'
import { ReactComponent as AddFolderIcon } from 'assets/images/icons/monitor/add_folder_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/report/delete-icon-size-20.svg'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import { MoveTopFolderDataType, NarrativeParamsType, NarrativesResponseType, SelectedTabType } from 'types/types'
import { UtilService } from 'services/Util/Util'
import { ShareReportEndpoint } from 'store/report/valueTypes'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import { ListSelectedRowData } from 'models/models'

import { store } from 'store'
import usePermissions from 'hooks/usePermissions'

import './VectorsMainPage.scss'
import TagManager from 'react-gtm-module'
import { useUnmount } from 'react-use'

const actionButtonsData = [
  { id: '0', name: 'Move to Folder', link: '#', icon: AddFolderIcon, eventName: 'MOVE_FOLDER' },
  { id: '1', name: 'Delete', link: '#', icon: DeleteIcon, eventName: 'DELETE' },
  { id: '2', name: 'Share', link: '#', icon: ShareIcon, eventName: 'SHARE' },
  { id: '3', name: 'Cancel', link: '#', icon: CloseIcon, eventName: 'CANCEL' },
]

const shareModalData = {
  narratives: {
    title: 'Share Narrative',
    desc: 'Share this Narrative with one or several team members',
    endpoint: 'narrative',
  },
  watchlists: {
    title: 'Share Watchlist',
    desc: 'Share this Watchlist with one or several team members',
    endpoint: 'watchlist',
  },
}

type VectorsTabType = {
  id: string
  key: SelectedTabType
  name: string
  link: string
}

const VectorsMainPage = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showFolderPanel, setShowFolderPanel] = useState<boolean>(false)
  const [showListView, setShowListView] = useState<boolean>(true)
  const [openMoveToModal, setOpenMoveToModal] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedRowsData, setSelectedRowsData] = useState<ListSelectedRowData[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [prevSearchValue, setPrevSearchValue] = useState<string>('')
  const [selectedFolderId, setSelectedFolderId] = useState<string>('')
  const [shareId, setShareId] = useState<string>('')

  const [entityId, setEntityId] = useState<string>('')
  const [alertId, setAlertId] = useState<string>('')
  const [openAlertSuccessModal, setOpenAlertSuccessModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const { vectorsStore, toasterStore, breadcrumbStore, tenantsStore, userStore, defineWatchlistStore } = store
  const {
    paginationValues,
    narrativeLoading,
    narrativesListData,
    communitiesListData,
    watchlistListData,
    selectedTab,
    userFolderList,
    deleteItem,
    setPaginationValues,
    setSelectedTab,
    fetchConditionsPayload,
    fetchConditionsPayloadForAssets,
    fetchNarratives,
    fetchCommunities,
    fetchWatchlist,
    fetchFolderList,
    CDUFolder,
    moveToFolder,
  } = vectorsStore
  const { userId, userName, tenantId, roleId } = userStore
  const { showToaster } = toasterStore
  const { isWatchlistSuccessModalOpen, setIsWatchlistSuccessModalOpen } = defineWatchlistStore
  const { rootPage, setBreadcrumbData } = breadcrumbStore
  const { changeLabelTextBasedOnTheme, customThemeLoading, isCorporateCommunicationsTheme } = tenantsStore

  const narrativeText = changeLabelTextBasedOnTheme('Narratives', isCorporateCommunicationsTheme)
  const watchListText = changeLabelTextBasedOnTheme('Watchlists', isCorporateCommunicationsTheme)
  const communitiesText = changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme)
  const communityText = changeLabelTextBasedOnTheme('Community', isCorporateCommunicationsTheme)

  const entityType = { narratives: 'NARRATIVE', watchlists: 'WATCHLIST', [communityText.toLowerCase()]: communityText }

  const modeType = {
    narratives: 'narrative',
    watchlists: 'watchlist',
    [communitiesText.toLowerCase()]: communityText.toLowerCase(),
    assets: 'asset',
  }

  const canAccessVectors = usePermissions('vectors') !== 'no_access'
  const canCreateWatchListOnly = usePermissions('vectors') === 'view_create_watchlists' && selectedTab === 'watchlists'
  const canCreateVectors = usePermissions('vectors') === 'create'

  const canCreateVector = canAccessVectors && (canCreateWatchListOnly || canCreateVectors)

  const roleBasedTabs = () => {
    if (isCorporateCommunicationsTheme && location.pathname?.includes('influencers'))
      return [
        { id: '0', key: 'watchlists', name: watchListText, link: '#' },
        { id: '1', key: 'communities', name: communitiesText, link: '#' },
      ]
    else if (isCorporateCommunicationsTheme && !location.pathname?.includes('influencers'))
      return [{ id: '0', key: 'narratives', name: narrativeText, link: '#' }]

    return [
      { id: '0', key: 'narratives', name: narrativeText, link: '#' },
      { id: '1', key: 'communities', name: communitiesText, link: '#' },
      { id: '2', key: 'watchlists', name: watchListText, link: '#' },
    ]
  }

  const isComingFromVectorsDetails = [
    'vectors/details',
    'influencers/details',
    '/vectors/watchlist_builder',
    '/vectors/create/watchlist_builder',
    '/vectors/create/watchlist_builder',
    '/search/create/watchlist_builder',
    '/influencers/watchlist_builder',
    '/influencers/create/watchlist_builder',
  ].some((path) => location.state?.comingFrom?.includes(path))

  const tabButtonsData = (roleBasedTabs() as VectorsTabType[])?.filter((item) =>
    !canAccessVectors ? item.name === communitiesText : item,
  )

  useEffect(() => {
    if (isComingFromVectorsDetails) {
      setPaginationValues({
        page: location.state?.pageNumber || 1,
        pageSize: location.state?.pageSize || 10,
      })
      setSelectedTab(location.state?.tabName)
    } else {
      if (isCorporateCommunicationsTheme && location.pathname?.includes('influencers')) {
        setSelectedTab('watchlists')
      } else if (isCorporateCommunicationsTheme && !location.pathname?.includes('influencers')) {
        setSelectedTab('narratives')
      } else {
        setPaginationValues({ page: 1, pageSize: 10 })
      }
    }
  }, [isCorporateCommunicationsTheme, location.pathname, isComingFromVectorsDetails])

  useEffect(() => {
    if (!canAccessVectors) setSelectedTab('communities')
    const params = {
      is_asset: true,
    }
    Promise.all([fetchConditionsPayload(), fetchConditionsPayloadForAssets(params), fetchFolderList({})])
  }, [])

  useEffect(() => {
    const firstCallRequestParams: NarrativeParamsType = {
      page: isComingFromVectorsDetails ? location.state?.pageNumber : 1,
      per_page: isComingFromVectorsDetails ? location.state?.pageSize : 10,
      sort: 'name:asc',
      in_library: false,
    }
    if (searchValue) {
      firstCallRequestParams['q'] = `name:like:${searchValue}`
    }

    if (selectedFolderId) {
      firstCallRequestParams['folder_id'] = selectedFolderId
    }

    if (selectedTab === 'narratives') {
      fetchNarratives({ params: firstCallRequestParams })
    } else if (selectedTab === 'communities') {
      fetchCommunities({ params: firstCallRequestParams })
    } else if (selectedTab === 'watchlists') {
      fetchWatchlist({ params: firstCallRequestParams })
    }
  }, [selectedTab, selectedFolderId, isComingFromVectorsDetails])

  useEffect(() => {
    if (selectedTab && rootPage) {
      const newBreadcrumbData = [
        { id: UtilService.generateUuid(), title: rootPage },
        {
          id: UtilService.generateUuid(),
          title: changeLabelTextBasedOnTheme(selectedTab, isCorporateCommunicationsTheme),
        },
      ]
      setBreadcrumbData(newBreadcrumbData)
    }
  }, [selectedTab, rootPage, isCorporateCommunicationsTheme])

  const renderListData: NarrativesResponseType = useMemo(() => {
    if (selectedTab === 'communities') {
      return communitiesListData
    }
    if (selectedTab === 'watchlists') {
      return watchlistListData
    }
    return narrativesListData
  }, [selectedTab, narrativesListData, communitiesListData, watchlistListData])

  const searchHandler = (value: string, prevValue: string) => {
    if (value || prevValue) {
      setPrevSearchValue(searchValue)
      let requestParams: NarrativeParamsType = {
        page: 1,
        per_page: paginationValues.pageSize,
        sort: 'name:asc',
        in_library: false,
      }
      if (value) {
        requestParams['q'] = `name:like:${value}`
      }
      if (selectedFolderId) {
        requestParams['folder_id'] = selectedFolderId
      }

      setPaginationValues({ page: 1, pageSize: paginationValues.pageSize })
      if (selectedTab === 'narratives') {
        fetchNarratives({ params: requestParams })
      }
      if (selectedTab === 'communities') {
        fetchCommunities({ params: requestParams })
      }
      if (selectedTab === 'watchlists') {
        fetchWatchlist({ params: requestParams })
      }
    }
  }

  const createFolderHandler = (value: string) => {
    if (value) {
      CDUFolder({ method: 'post', data: { name: value } }).then((res) => {
        if (res !== 'error') {
          fetchFolderList({})
        }
      })
    }
  }

  const moveToFolderhandler = (id: string) => {
    if (id && selectedTab !== 'communities') {
      const requestBody: MoveTopFolderDataType = {
        action: 'add',
        entities: [{ entity_id: selectedRows[0], entity_type: entityType[selectedTab] as 'NARRATIVE' | 'WATCHLIST' }],
      }
      moveToFolder({ id, data: requestBody }).then((res) => {
        if (res !== 'error') {
          showToaster({
            iconType: 'success',
            message: 'Successfully added items to your Folder',
          })
        }
        setSelectedRows([])
        setOpenMoveToModal(false)
      })
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_folder',
          //@ts-ignore
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
          entityId: selectedRows[0],
          entityType: entityType[selectedTab] as 'NARRATIVE' | 'WATCHLIST',
          folderId: id,
        },
      })
    }
  }

  const fetchListsAfterAlertsActions = () => {
    const requestParams: NarrativeParamsType = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
      in_library: false,
    }
    if (selectedTab === 'watchlists') fetchWatchlist({ params: requestParams })
    if (selectedTab === 'narratives') fetchNarratives({ params: requestParams })
  }

  const deleteHandler = () => {
    selectedRows[0] &&
      deleteItem(selectedRowsData[0]).then((res: any | 'error') => {
        if (res !== 'error') {
          setSelectedRows([])
          setOpenDeleteModal(false)
          fetchListsAfterAlertsActions()
        }
      })
  }

  useUnmount(() => {
    setPaginationValues({ page: 1, pageSize: 10 })
    setSelectedTab('narratives')
  })

  return (
    <Spin
      size='large'
      spinning={narrativeLoading || customThemeLoading}
      wrapperClassName='mmp_spinner_wrapper'
      className='mmp_spinner'>
      <div className='mmp_container'>
        <div className='mmp_header_container'>
          <SearchInput
            className='mmp_hc_search'
            value={searchValue}
            placeholder='Search'
            prefix={SearchIcon}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={() => searchHandler(searchValue, prevSearchValue)}
            onClear={() => {
              setSearchValue('')
              searchHandler('', prevSearchValue)
            }}
          />
          {canCreateVector && (
            <ButtonImage
              className='mmp_hc_button'
              onClick={() => {
                const state = {
                  tabName: selectedTab,
                  pageNumber: paginationValues.page,
                  pageSize: paginationValues.pageSize,
                }

                navigate(selectedTab === 'watchlists' ? 'watchlist_builder' : 'create', {
                  state: JSON.parse(JSON.stringify(state)),
                })
              }}
              icon={AddIcon}>
              Create new
            </ButtonImage>
          )}
        </div>
        <div className={`mmp_content_container${!showFolderPanel ? ' mmp_content_container_add_padding' : ''}`}>
          {showFolderPanel ? (
            <div className='mmp_cc_left_side_container'>
              <FolderPanel
                onClose={() => {
                  setShowFolderPanel(false)
                }}
                selectedId={selectedFolderId}
                setSelectedId={setSelectedFolderId}
              />
            </div>
          ) : null}
          <div className='mmp_cc_main_container'>
            <div className='mmp_cc_mc_sub_header_container'>
              <div className='mmp_shc_left_section'>
                {showFolderPanel ? null : (
                  <ButtonImage
                    className='mmp_cc_mc_shc_ls_button'
                    onClick={() => {
                      setShowFolderPanel(true)
                    }}
                    icon={FolderIcon}>
                    Folders
                  </ButtonImage>
                )}
                <div className='mmp_shc_ls_buttons'>
                  {tabButtonsData.map((tab) => (
                    <ButtonImage
                      key={tab.id}
                      className={`mmp_cc_mc_shc_ls_button${
                        selectedTab === tab.key ? ' mmp_cc_mc_shc_ls_button_active' : ''
                      }`}
                      onClick={() => {
                        setPaginationValues({ page: 1, pageSize: 10 })
                        setSelectedTab(tab.key)
                      }}>
                      {tab.name}
                    </ButtonImage>
                  ))}
                </div>
                {selectedRows.length ? (
                  <div className='mmp_shc_ls_buttons mmp_shc_ls_action_buttons'>
                    {actionButtonsData.map((action) => (
                      <ButtonImage
                        key={action.id}
                        className={`mmp_cc_mc_shc_ls_button${
                          selectedTab === 'communities' && action.eventName !== 'CANCEL'
                            ? ' mmp_cc_mc_shc_ls_button_disabled'
                            : ''
                        }`}
                        iconClassName='mmp_cc_mc_shc_ls_button_icon'
                        onClick={() => {
                          if (action.eventName === 'CANCEL') {
                            setSelectedRows([])
                          }
                          if (selectedTab !== 'communities') {
                            if (action.eventName === 'MOVE_FOLDER') {
                              setOpenMoveToModal(true)
                            }
                            if (action.eventName === 'SHARE') {
                              setShareId(selectedRows[0])
                            }
                            if (action.eventName === 'DELETE') {
                              setOpenDeleteModal(true)
                            }
                          }
                          // setSelectedTab(action.name.toLowerCase() as SelectedTabType)
                        }}
                        icon={action.icon}>
                        {action.name}
                      </ButtonImage>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className='mmp_shc_right_section'>
                <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={'vectors'} />
                <div className='mmp_shc_rs_buttons'>
                  <Tooltip title='List View' placement='bottom'>
                    <ButtonImage
                      className={`mmp_cc_mc_shc_rs_button${showListView ? ' mmp_cc_mc_shc_rs_button_active' : ''}`}
                      onClick={() => {
                        setShowListView(true)
                      }}
                      icon={ListIcon}
                      aria-label='List View'
                      iconClassName='mmp_button_icon'
                    />
                  </Tooltip>
                  <Tooltip title='Grid View' placement='bottom'>
                    <ButtonImage
                      className={`mmp_cc_mc_shc_rs_button${!showListView ? ' mmp_cc_mc_shc_rs_button_active' : ''}`}
                      onClick={() => {
                        setShowListView(false)
                      }}
                      icon={GridIcon}
                      aria-label='Grid View'
                      iconClassName='mmp_button_icon'
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className='mmp_cc_mc_content_container'>
              {showListView ? (
                <ListViewSection
                  selectedTab={selectedTab}
                  listData={renderListData}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  page={paginationValues}
                  subStore={'vectors'}
                  setAlertId={setAlertId}
                  setEntityId={setEntityId}
                  setSelectedRowsData={setSelectedRowsData}
                />
              ) : (
                <GridViewSection
                  gridData={renderListData}
                  selectedTab={selectedTab}
                  subStore={'vectors'}
                  page={paginationValues}
                />
              )}
            </div>
            <div className='mmp_cc_mc_pagination_container'>
              <DefaultPagination
                className='mmp_cc_mc_pagination'
                totalCount={renderListData.total_count}
                currentPage={paginationValues.page}
                pageSize={paginationValues.pageSize}
                showPageSizeChanger={false}
                // pageSizeOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                nextIcon={ArrowNextIcon}
                prevIcon={ArrowNextIcon}
                iconHeight={20}
                iconWidth={20}
                onChange={(page, pageSize) => {
                  let requestParams: NarrativeParamsType = {
                    page,
                    per_page: pageSize,
                    sort: 'name:asc',
                    in_library: false,
                  }
                  if (searchValue) {
                    requestParams['q'] = `name:like:${searchValue}`
                  }
                  if (selectedFolderId) {
                    requestParams['folder_id'] = selectedFolderId
                  }
                  setPaginationValues({ page, pageSize })
                  fetchNarratives({ params: requestParams })
                  fetchCommunities({ params: requestParams })
                  fetchWatchlist({ params: requestParams })
                }}
                locale={{ items_per_page: '' }}
              />
            </div>
          </div>
        </div>
        {openMoveToModal && (
          <MoveToFolderModal
            data={userFolderList.items}
            onClose={() => setOpenMoveToModal(false)}
            onConfirm={moveToFolderhandler}
            onCreate={createFolderHandler}
          />
        )}
        {shareId && selectedTab !== 'communities' && (
          <ShareReportModal
            endpoint={shareModalData[selectedTab as keyof typeof shareModalData].endpoint as ShareReportEndpoint}
            reportId={shareId}
            title={shareModalData[selectedTab as keyof typeof shareModalData].title}
            desc={shareModalData[selectedTab as keyof typeof shareModalData].desc}
            secondButtonText='Share'
            icon={null}
            onClose={() => setShareId('')}
          />
        )}

        {openAlertSuccessModal && (
          <SuccessModal
            title='Alert Created Successfully'
            desc='You will start receive new alerts according to the selected preferences'
            onClose={() => setOpenAlertSuccessModal(false)}
            closeButtonText='Continue'
          />
        )}

        <AlertModal
          entity_id={entityId}
          entity_type={selectedTab === 'watchlists' ? 'WATCHLIST' : selectedTab === 'narratives' ? 'NARRATIVE' : null}
          open={!!entityId || !!alertId}
          alertId={alertId}
          onClose={() => {
            setEntityId('')
            setAlertId('')
          }}
          onSuccess={() => {
            setOpenAlertSuccessModal(true)
            fetchListsAfterAlertsActions()
          }}
          onDeleteSuccess={fetchListsAfterAlertsActions}
        />

        {openDeleteModal && selectedRows[0] && (
          <DeleteConfirmationModal
            title={`Are you sure you want to delete selected ${modeType[selectedTab]}?`}
            onClose={() => setOpenDeleteModal(false)}
            onContinue={deleteHandler}
          />
        )}

        {isWatchlistSuccessModalOpen && (
          <SuccessModal
            footerClassName='watchlist-success-modal'
            title={`${changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)} created successfully`}
            desc={`Some of the accounts will be added to Pendulum's data pipeline and will be processed within 24 hours. Now you can share ${changeLabelTextBasedOnTheme(
              'Watchlist',
              isCorporateCommunicationsTheme,
            )} or proceed without sharing.`}
            secondButtonText={`Share ${changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)}`}
            onClose={() => {
              setIsWatchlistSuccessModalOpen('')
            }}
            onConfirm={() => {
              setShareId(isWatchlistSuccessModalOpen)
              setIsWatchlistSuccessModalOpen('')
            }}
          />
        )}
      </div>
    </Spin>
  )
})

export default VectorsMainPage
