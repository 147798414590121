import { Radio, Tooltip, Input, Select } from 'antd'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import { ReactComponent as CalendarIcon } from 'assets/images/icons/calendar.svg'
import { ReactComponent as InfoIcon } from 'assets/images/reports-tooltip.svg'
import { ReactComponent as DownArrowIcon } from 'assets/images/icons/report/dropdownicon-size-16.svg'
import styles from './ReportType.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import './ReportType.scss'
import { ReportDayType, ReportType as ReportTypeString } from 'store/report/valueTypes'
const { Option } = Select

const DATE_FORMAT = 'MMM DD, YYYY'

const reportType = {
  SINGLE: 'ADHOC',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
}

const days = [
  { key: '1', value: 'Monday' },
  { key: '2', value: 'Tuesday' },
  { key: '3', value: 'Wednesday' },
  { key: '4', value: 'Thursday' },
  { key: '5', value: 'Friday' },
  { key: '6', value: 'Saturday' },
  { key: '7', value: 'Sunday' },
]

type ReportTypeProps = {
  setIsCalendarOpen: (value: boolean) => void
  startDate: Date | null
  endDate: Date | null
  calendarRef: any
  isCalendarOpen: boolean
  onChange: (dates: [Date | null, Date | null]) => void
  selectedReportType: ReportTypeString
  setSelectedReportType: (val: ReportTypeString) => void
  selectedDay: ReportDayType
  setSelectedDay: (val: ReportDayType) => void
}

export default function ReportType(props: ReportTypeProps) {
  const {
    setIsCalendarOpen,
    startDate,
    endDate,
    calendarRef,
    isCalendarOpen,
    onChange,
    selectedReportType,
    setSelectedReportType,
    selectedDay,
    setSelectedDay,
  } = props

  const customPopupContainer = (triggerNode: any) => {
    return triggerNode.parentNode
  }

  return (
    <div className={styles.reportListForm}>
      <div className={styles.reportType}>
        <div className={styles.labelParent}>
          <CalendarIcon />
          <span className={styles.inputLabel}>Report type</span>
        </div>

        <Radio.Group
          className={styles.radioContainer}
          onChange={(e: any) => {
            setSelectedReportType(e.target.value)
          }}
          value={selectedReportType}>
          <Radio className={styles.row} value={reportType.SINGLE}>
            <div className={styles.text}>
              Single Report
              <Tooltip
                placement='bottom'
                overlayClassName='pendulum-themed-tooltip'
                trigger={['click']}
                getPopupContainer={customPopupContainer}
                title={
                  <div>
                    <p style={{ marginBottom: 'unset' }}>
                      One-time report summarizing
                      <br />
                      content from a specified date
                      <br />
                      range.
                    </p>
                  </div>
                }>
                <InfoIcon height={12} width={12} />
              </Tooltip>
            </div>
          </Radio>

          {selectedReportType === reportType.SINGLE && (
            <div className={styles.dateRangeContainer}>
              <div className={styles.inner}>
                <Input
                  bordered={false}
                  className={styles.drInput}
                  onClick={() => setIsCalendarOpen(true)}
                  placeholder='Select date range'
                  value={`${startDate ? dayjs(startDate).format(DATE_FORMAT) : ''}${
                    endDate ? ' - ' + dayjs(endDate).format(DATE_FORMAT) : ''
                  }`}
                />
                <DownArrowIcon className={styles.downArrow} onClick={() => setIsCalendarOpen(true)} />
              </div>
              <div className={styles.calendarDiv} ref={calendarRef}>
                {isCalendarOpen ? (
                  <DatePicker
                    calendarClassName='calendar-div__calendar'
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    onChange={onChange}
                    selectsRange
                    inline
                    popperContainer={customPopupContainer}
                  />
                ) : null}
              </div>
            </div>
          )}

          <Radio className={styles.row} value={reportType.DAILY}>
            <div className={styles.text}>
              Daily Report
              <Tooltip
                placement='bottom'
                overlayClassName='pendulum-themed-tooltip'
                trigger={['click']}
                getPopupContainer={customPopupContainer}
                title={
                  <div>
                    <p style={{ marginBottom: 'unset' }}>
                      Recurring report summarizing
                      <br />
                      content from the past 24
                      <br />
                      hours.
                    </p>
                  </div>
                }>
                <InfoIcon height={12} width={12} />
              </Tooltip>
            </div>
          </Radio>

          <Radio className={styles.row} value={reportType.WEEKLY}>
            <div className={styles.text}>
              Weekly Report
              <Tooltip
                placement='bottom'
                overlayClassName='pendulum-themed-tooltip'
                trigger={['click']}
                getPopupContainer={customPopupContainer}
                title={
                  <div>
                    <p style={{ marginBottom: 'unset' }}>
                      Recurring report summarizing
                      <br />
                      content from the last 7 days
                      <br />
                      from day selected.
                    </p>
                  </div>
                }>
                <InfoIcon height={12} width={12} />
              </Tooltip>
            </div>
          </Radio>

          {selectedReportType === reportType.WEEKLY && (
            <div className={styles.selectContainer}>
              <Select
                getPopupContainer={customPopupContainer}
                bordered={false}
                className={styles.select}
                value={selectedDay}
                onChange={(value: ReportDayType) => {
                  setSelectedDay(value)
                }}
                placeholder='Select day'
                suffixIcon={<DownArrowIcon className={styles.downArrowIcon} />}>
                {days.map((day) => (
                  <Option key={day.key} value={day.value}>
                    {day.value}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </Radio.Group>
      </div>
    </div>
  )
}
