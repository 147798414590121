import { useState } from 'react'
import { Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { SnippetFilterPayload } from 'models/models'
import { store } from 'store'
import { useEffectOnce } from 'react-use'
import { powerInsightsAPI } from 'api/api'
import { AssetListObjType } from 'store/asset/types'
import './BrandDiscussions.scss'

const Summary = ({ item }: { item: AssetListObjType }) => {
  const { getSnippetFilterPayload } = store.vectorsStore
  const [summary, setSummary] = useState('')
  const [isLoadingSummary, setIsLoadingSummary] = useState(true)
  const [isExecutiveSummaryTooLong, setIsExecutiveSummaryTooLong] = useState(false)
  const [fetchingError, setFetchingError] = useState(false)
  const [isReadMore, setIsReadMore] = useState(false)

  const fetchSummary = async () => {
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    if (!payload || !item.conditions) return
    try {
      const { data } = await powerInsightsAPI.summary.getSummaryV2(
        {
          ...payload,
          conditions: [...(payload.conditions || []), item.conditions],
        },
        true,
      )
      if (!data.response_code) {
        setSummary(data.sections[0].summary)
        if (data.sections[0].summary.length > 250) {
          setIsExecutiveSummaryTooLong(true)
        } else {
          setIsExecutiveSummaryTooLong(false)
        }
      } else if (data.response_code === 'NO_DATA') {
        setSummary('No summary to display')
      } else {
        setFetchingError(true)
      }
    } catch (error) {
      setFetchingError(true)
    } finally {
      setIsLoadingSummary(false)
    }
  }

  const retryFetchingSummary = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsLoadingSummary(true)
    fetchSummary()
  }

  useEffectOnce(() => {
    fetchSummary()
  })

  const readMoreToggle = (event: any) => {
    event.stopPropagation()
    setIsReadMore(!isReadMore)
  }

  if (isLoadingSummary) {
    return (
      <p className='skeleton-description'>
        <Skeleton paragraph active />
      </p>
    )
  }
  return (
    <p className='description'>
      {isExecutiveSummaryTooLong ? (isReadMore ? summary : summary.slice(0, 250).concat('...')) : summary}
      {isExecutiveSummaryTooLong && (
        <span className='read-more' onClick={readMoreToggle}>
          &nbsp;{isReadMore ? 'Read less' : 'Read more'}
        </span>
      )}
      {fetchingError && (
        <span className='try-again-text'>
          Failed to fetch summary
          <span className='try-again' onClick={retryFetchingSummary}>
            Try again
          </span>
        </span>
      )}
    </p>
  )
}

export default observer(Summary)
