import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

export async function getIssues() {
  try {
    let url = `${process.env.REACT_APP_API}${ROUTES.electionIssues}`
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    LogService.error({ message: 'getThreatTopics error', error })
  }
}
