import { useEffect, useMemo, useState } from 'react'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { DropdownOption } from 'components/common/Inputs/Dropdown/Dropdown'
import CreateAssetModalStep1, { transformToDropdownOption } from './CreateAssetModalStep1/CreateAssetModalStep1'
import CreateAssetModalStep2, { rules } from './CreateAssetModalStep2/CreateAssetModalStep2'
import CreateFlagModalStep4 from 'components/Flags/CreateFlagModal/CreateFlagModalStep4/CreateFlagModalStep4'
import { ReactComponent as AssetIcon } from 'assets/images/icons/outline/asset_icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/monitor/arrow-left-icon.svg'
import {
  AssetListParamsType,
  CreateAssetBodyType,
  AssetType,
  AssetListObjType,
  AssetCategoryType,
} from 'store/asset/types'
import TagManager from 'react-gtm-module'
import classNames from 'classnames'
import './CreateAssetModal.scss'

type CreateFlagModalProps = {
  onClose: () => void
  setOpenSuccessModal: () => void
  setOpenContactModal: (state: boolean) => void
  isEditMode?: boolean
  editData?: AssetListObjType
  selectedCategory?: AssetCategoryType
  noSuccessActionsNeeded?: boolean
  drawerManagedByParent?: boolean
}

export type RuleObjType = {
  id: string
  label: string
  ruleType: AssetType
}

const tabs = [
  { id: 1, name: 'Asset details' },
  { id: 2, name: 'Asset rule' },
]

const CreateAssetModal = observer((props: CreateFlagModalProps) => {
  const { assetsStore, userStore } = store
  const { assetsCategories, createAsset, setPaginationValues, fetchAssetsList, setIsFlagCreated, setIdsBasedList } =
    assetsStore
  const { userId, userName, roleId, tenantId } = userStore
  const {
    onClose,
    setOpenSuccessModal,
    isEditMode,
    editData,
    selectedCategory,
    setOpenContactModal,
    noSuccessActionsNeeded = false,
    drawerManagedByParent = false,
  } = props
  const [flagName, setFlagName] = useState<string>('')
  const [flagDesc, setFlagDesc] = useState<string>('')
  const [flagCategory, setFlagCategory] = useState<DropdownOption>({ id: '', label: '' })
  const [step, setStep] = useState<number>(1)
  const [selectedRule, setSelectedRule] = useState<RuleObjType>({ id: '', label: '', ruleType: '' })
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [prevSelectedIds, setPrevSelectedIds] = useState<string[]>([])
  const [booleanSearch, setBooleanSearch] = useState<string>('')

  const rulesHandler = (rule: RuleObjType) => {
    if (selectedIds.length) {
      setSelectedIds([])
    }
    if (booleanSearch) {
      setBooleanSearch('')
    }
    if (isEditMode) {
      setPrevSelectedIds([])

      setIdsBasedList([])
    }
    setSelectedRule(rule)
    setStep(3)
  }

  useEffect(() => {
    if (selectedCategory && Object.keys(selectedCategory).length && selectedCategory?.category_id !== '0') {
      const obj: DropdownOption = {
        id: selectedCategory.category_id,
        label: selectedCategory.name,
      }
      setFlagCategory(obj)
    }
  }, [selectedCategory?.category_id])

  useEffect(() => {
    if (isEditMode && editData && Object.keys(editData).length) {
      const { name, description, insight_condition, category_id, insight_type } = editData
      const findCategory = transformToDropdownOption(assetsCategories).find((category) => category.id === category_id)
      const findRule = rules.find((rule) => rule.ruleType === insight_type)
      setFlagName(name)
      setFlagDesc(description || '')
      setFlagCategory(findCategory || { id: '', label: '' })
      setSelectedRule(findRule || { id: '', label: '', ruleType: '' })
      if (insight_type === 'SEARCH_TERM') {
        setBooleanSearch(insight_condition)
      } else {
        const withoutBrackets = insight_condition.slice(1, -1)
        setSelectedIds(withoutBrackets.split(','))
        setPrevSelectedIds(withoutBrackets.split(','))
      }
    }
  }, [editData, assetsCategories, isEditMode])

  const createAssetHandler = (method: 'post' | 'put', id?: string) => {
    let requestBody: CreateAssetBodyType = {
      name: flagName,
      description: flagDesc,
      display_label: flagName,
      insight_type: selectedRule.ruleType,
      insight_condition: selectedRule.ruleType === 'SEARCH_TERM' ? booleanSearch : selectedIds,
      tenant_ids: [tenantId!],
      is_asset: true,
    }
    if (flagCategory.id) {
      requestBody['category_id'] = flagCategory.id
    }
    createAsset({ data: requestBody, method, id }).then((flag) => {
      if (flag !== 'error') {
        setIsFlagCreated(true)
        setOpenSuccessModal()
        if (noSuccessActionsNeeded) return
        const requestParams: AssetListParamsType = {
          page: 1,
          per_page: 10,
          sort: 'name:asc',
          is_asset: true,
        }
        setPaginationValues({ page: 1, pageSize: 10 })
        fetchAssetsList({ params: requestParams })
        onClose()
      }
    })
    TagManager.dataLayer({
      dataLayer: {
        event: 'create_asset',
        //@ts-ignore
        user_id: userId,
        tenantId: tenantId,
        roleId: roleId,
        user_name: userName,
        entityName: flagName,
      },
    })
  }

  const backStepHandler = () => {
    setSelectedRule({ id: '', label: '', ruleType: '' })
    setBooleanSearch('')
    setSelectedIds([])
    setStep(1)
  }

  const renderStep = useMemo(() => {
    if (isEditMode) {
      return (
        <p className='cfmc_ic_h_desc cfmc_ic_h_desc_edit_mode'>
          Provide a relevant display name i.e. “Kinetic Risk” and a short helpful description for your attention asset.
        </p>
      )
    }
    if (step === 1) {
      return (
        <>
          <div className='cfmc_ic_h_step_text_container'>
            <p className='cfmc_ic_h_step_text'>
              Step 1 of 3<span className='cfmc_ic_h_st_part'>Provide asset details</span>
            </p>
          </div>
          <p className='cfmc_ic_h_desc'>
            Provide a relevant display name i.e. “Kinetic Risk” and a short helpful description for your asset.
          </p>
        </>
      )
    } else if (step === 2) {
      return (
        <>
          <div className='cfmc_ic_h_step_text_container'>
            <ArrowLeftIcon className='cfmc_ic_h_icon' onClick={backStepHandler} />
            <p className='cfmc_ic_h_step_text'>
              Step 2 of 3<span className='cfmc_ic_h_st_part'>Select the rule for the asset</span>
            </p>
          </div>
          <p className='cfmc_ic_h_desc'>Select the rule on the basis of which your asset will operate.</p>
        </>
      )
    } else if (step === 3) {
      return (
        <>
          <div className='cfmc_ic_h_step_text_container'>
            <ArrowLeftIcon className='cfmc_ic_h_icon' onClick={backStepHandler} />
            <p className='cfmc_ic_h_step_text'>
              Step 3 of 3<span className='cfmc_ic_h_st_part'>Modify the rule</span>
            </p>
          </div>
          <p className='cfmc_ic_h_desc'>
            Modify your chosen rule to ensure that the asset accurately reflects the nature of the threat and labels
            only relevant content.
          </p>
        </>
      )
    } else if (step === 4) {
      return (
        <>
          <div className='cfmc_ic_h_step_text_container'>
            <p className='cfmc_ic_h_step_text'>
              Test the rule<span className='cfmc_ic_h_st_part'>Confirm that the rule is correct</span>
            </p>
          </div>
          <p className='cfmc_ic_h_desc'>
            Here are the snippets that our system found based on your rule. If these snippets are not relevant, please
            change the booleans search query.
          </p>
        </>
      )
    }
  }, [step, isEditMode])

  const renderButton = useMemo(() => {
    if (isEditMode) {
      if (step === 4) {
        return (
          <ButtonImage
            className='cfmc_ic_fc_b_button'
            onClick={(e) => {
              e.stopPropagation()
              setStep(3)
            }}>
            Back to editing
          </ButtonImage>
        )
      }
      return (
        <ButtonImage
          className='cfmc_ic_fc_b_button cfmc_ic_fc_b_button_edit_mode'
          disabled={selectedRule.ruleType === 'SEARCH_TERM' ? !booleanSearch : !selectedIds.length}
          onClick={(e) => {
            e.stopPropagation()
            createAssetHandler('put', editData?.id)
          }}>
          Save
        </ButtonImage>
      )
    }
    if (step === 1) {
      return (
        <ButtonImage
          className='cfmc_ic_fc_b_button'
          disabled={!flagName || !flagCategory.id}
          onClick={(e) => {
            e.stopPropagation()
            setStep((prevStep) => prevStep + 1)
          }}>
          Continue
        </ButtonImage>
      )
    }
    if (step === 2 && !selectedRule.ruleType) {
      return (
        <ButtonImage className='cfmc_ic_fc_b_button' disabled={true} onClick={() => {}}>
          Continue
        </ButtonImage>
      )
    }
    if (step === 4) {
      return (
        <ButtonImage
          className='cfmc_ic_fc_b_button'
          onClick={(e) => {
            e.stopPropagation()
            setStep(3)
          }}>
          Back to editing
        </ButtonImage>
      )
    }
    return (
      <ButtonImage
        className='cfmc_ic_fc_b_button'
        disabled={selectedRule.ruleType === 'SEARCH_TERM' ? !booleanSearch : !selectedIds.length}
        onClick={(e) => {
          e.stopPropagation()
          createAssetHandler('post')
        }}>
        Save and deploy
      </ButtonImage>
    )
  }, [step, flagName, flagCategory.id, flagDesc, selectedRule.ruleType, selectedIds, booleanSearch, isEditMode])

  const stepContent = useMemo(() => {
    if (step === 1) {
      return (
        <CreateAssetModalStep1
          categorties={assetsCategories}
          flagName={flagName}
          setFlagName={setFlagName}
          flagCategory={flagCategory}
          setFlagCategory={setFlagCategory}
          flagDesc={flagDesc}
          setFlagDesc={setFlagDesc}
        />
      )
    } else if (step === 4) {
      return <CreateFlagModalStep4 booleanSearch={booleanSearch} />
    }
    return (
      <CreateAssetModalStep2
        setStep={setStep}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        prevSelectedIds={prevSelectedIds}
        setPrevSelectedIds={setPrevSelectedIds}
        selectedRule={selectedRule}
        setSelectedRule={rulesHandler}
        booleanSearch={booleanSearch}
        setBooleanSearch={setBooleanSearch}
        isEditMode={isEditMode}
        onClose={onClose}
        setOpenContactModal={setOpenContactModal}
      />
    )
  }, [step, assetsCategories, flagName, flagCategory, flagDesc, selectedRule, selectedIds, booleanSearch, isEditMode])

  return (
    <div
      className={classNames('create_flag_container', {
        'not-managed': !drawerManagedByParent,
      })}>
      <div className='cfmc_inside_container'>
        <div className='cfmc_ic_header'>
          <div className='cfmc_ic_h_top'>
            <div className='cfmc_ic_ht_left'>
              {isEditMode ? (
                <p className='cfmc_ic_ht_l_title cfmc_ic_ht_l_title_edit'>Edit Asset</p>
              ) : (
                <>
                  <AssetIcon height={24} width={24} className='cfmc_ic_ht_l_flag_icon' />
                  <p className='cfmc_ic_ht_l_title'>Add new asset</p>
                </>
              )}
            </div>
            <CloseIcon height={24} width={24} className='cfmc_ic_ht_l_close_icon' onClick={onClose} />
          </div>
          {renderStep}
        </div>
        {isEditMode ? (
          <div className='cfmc_ic_fc_tab_buttons_container'>
            {tabs.map((tab) => (
              <ButtonImage
                key={tab.id}
                className={`cfmc_ic_fc_tbc_button${
                  step === tab.id || (step > 2 && step - 1 === tab.id) || (step > 3 && step - 2 === tab.id)
                    ? ' cfmc_ic_fc_tbc_button_active'
                    : ''
                }`}
                onClick={() => setStep(tab.id)}>
                {tab.name}
              </ButtonImage>
            ))}
          </div>
        ) : null}
        {stepContent}
        <div className='cfmc_ic_fc_buttons'>
          {step !== 4 ? (
            <ButtonImage className='cfmc_ic_fc_b_button' onClick={onClose}>
              Cancel
            </ButtonImage>
          ) : null}
          {renderButton}
        </div>
      </div>
    </div>
  )
})

export default CreateAssetModal
