import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  id: string
  instanceId?: string
  users: string[]
}
export function sendReportAsEmail({ id, instanceId, users }: Props) {
  const endpoint = `${ROUTES.reports}/${id}/export` + (instanceId ? `?instance_id=${instanceId} ` : '')

  return axios.post(endpoint, {
    recipient_list: users,
  })
}
