import { memo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import useClickAway from 'hooks/useClickAway'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import CreateFolderModal from '../CreateFolderModal/CreateFolderModal'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import { ReactComponent as DotsIcon } from 'assets/images/icons/monitor/dots_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/monitor/share_icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import { ReactComponent as TickIcon } from 'assets/images/icons/monitor/check_icon.svg'
import { ReactComponent as FolderIcon } from 'assets/images/icons/monitor/folder_icon.svg'
// import { ReactComponent as FavoriteIcon } from 'assets/images/icons/monitor/favorite_icon.svg'
import { ReactComponent as AddIcon } from 'assets/images/add-icon.svg'
import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { FolderListObjType } from 'types/types'
import './FolderPanel.scss'
import { ShareReportEndpoint } from 'store/report/valueTypes'
import TagManager from 'react-gtm-module'

type FolderPanelProps = {
  onClose: () => void
  selectedId: string
  setSelectedId: (val: string) => void
}

type FolderListChunkProps = {
  className?: string
  data: FolderListObjType
  selectedId: string
  setSelectedId: (val: string) => void
  shareHandler?: (val: string) => void
  editHandler?: (id: string, val: string) => void
  deleteHandler?: (val: string) => void
  showCheckbox?: boolean
  showOptionIcon?: boolean
}

export const FolderListChunk = (props: FolderListChunkProps) => {
  const {
    className = '',
    data,
    selectedId,
    setSelectedId,
    shareHandler,
    editHandler,
    deleteHandler,
    showCheckbox = false,
    showOptionIcon = true,
  } = props
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [editId, setEditId] = useState<string | null>(null)
  const [renameValue, setRenameValue] = useState<string>('')

  const onClickOutside = () => {
    setDropdownOpen(false)
  }
  useClickAway(dropdownRef, onClickOutside)
  return (
    <div
      ref={dropdownRef}
      className={`fpc_lc_chunk${!showCheckbox && data.id === selectedId ? ' fpc_lc_chunk_active' : ''}${
        showCheckbox ? ' fpc_lc_chunk_has_checkbox' : ''
      } ${className}`}
      onClick={() => {
        if (!editId && !showCheckbox) {
          setSelectedId(selectedId === data.id ? '' : data.id)
        }
      }}>
      <div className='fpc_lc_chunk_left'>
        {showCheckbox ? (
          <div
            className={`fpc_lc_cl_checkbox_container${
              data.id === selectedId ? ' fpc_lc_cl_checkbox_container_selected' : ''
            }`}
            onClick={(e) => {
              e.stopPropagation()
              setSelectedId(selectedId === data.id ? '' : data.id)
            }}>
            {data.id === selectedId && <CheckIcon />}
          </div>
        ) : null}
        <FolderIcon height={20} width={20} className='fpc_lc_c_icon' />

        {editId ? (
          <input
            className='fpc_lc_c_input'
            type='text'
            value={renameValue}
            onChange={(e) => {
              setRenameValue(e.target.value)
            }}
          />
        ) : (
          <p className='fpc_lc_c_text'>{data.name}</p>
        )}
      </div>
      {editId ? (
        <>
          <CloseIcon
            className='fpc_lc_chunk_dropdown_icon'
            onClick={(e) => {
              e.stopPropagation()
              setRenameValue('')
              setEditId(null)
            }}
          />
          {renameValue ? (
            <TickIcon
              className='fpc_lc_chunk_dropdown_icon'
              onClick={(e) => {
                e.stopPropagation()
                if (renameValue) {
                  editHandler?.(data.id, renameValue)
                  setRenameValue('')
                  setEditId(null)
                }
              }}
            />
          ) : null}
        </>
      ) : showOptionIcon ? (
        <DotsIcon
          className='fpc_lc_chunk_dropdown_icon'
          onClick={(e) => {
            e.stopPropagation()
            setDropdownOpen((prevState) => !prevState)
          }}
        />
      ) : null}
      {dropdownOpen && (
        <div className='fpc_lc_chunk_dropdown_container'>
          <ButtonImage
            className='fpc_lc_chunk_dc_button'
            icon={ShareIcon}
            onClick={(e) => {
              e.stopPropagation()
              shareHandler?.(data.id)
              onClickOutside()
            }}>
            Share
          </ButtonImage>
          <ButtonImage
            className='fpc_lc_chunk_dc_button'
            icon={EditIcon}
            onClick={(e) => {
              e.stopPropagation()
              setEditId(data.id)
              onClickOutside()
            }}>
            Rename
          </ButtonImage>
          <ButtonImage
            className='fpc_lc_chunk_dc_button'
            icon={DeleteIcon}
            onClick={(e) => {
              e.stopPropagation()
              deleteHandler?.(data.id)
              onClickOutside()
            }}>
            Delete
          </ButtonImage>
        </div>
      )}
    </div>
  )
}

const FolderPanel = observer((props: FolderPanelProps) => {
  const { vectorsStore, userStore } = store
  const { userId, userEmail, tenantId, roleId } = userStore
  const { userFolderList, fetchFolderList, CDUFolder } = vectorsStore
  const { onClose, selectedId, setSelectedId } = props
  const [deleteId, setDeleteId] = useState<string | null>(null)
  const [shareId, setShareId] = useState<string>('')
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)

  const deleteHandler = () => {
    if (deleteId) {
      CDUFolder({ method: 'delete', id: deleteId }).then((res) => {
        if (res !== 'error') {
          fetchFolderList({})
        }
        setDeleteId(null)
      })
    }
  }

  const editHandler = (id: string, value: string) => {
    CDUFolder({ method: 'put', id, data: { name: value } }).then((res) => {
      if (res !== 'error') {
        fetchFolderList({})
      }
    })
  }

  const createFolderHandler = (value: string) => {
    if (value) {
      CDUFolder({ method: 'post', data: { name: value } }).then((res) => {
        if (res !== 'error') {
          fetchFolderList({})
        }
        setOpenCreateModal(false)
        TagManager.dataLayer({
          dataLayer: {
            event: 'create_folder',
            //@ts-ignore
            user_id: userId,
            tenantId: tenantId,
            roleId: roleId,
            user_name: userEmail,
            entityName: value,
          },
        })
      })
    }
  }

  return (
    <div className='folder_panel_container'>
      <div className='fpc_header'>
        <p className='fpc_h_text'>Folders</p>
        <CloseIcon className='fpc_h_icon' onClick={onClose} />
      </div>
      <div className='fpc_list_container'>
        {/* <div
          className={`fpc_lc_chunk${'abc' === selectedId ? ' fpc_lc_chunk_active' : ''}`}
          // onClick={() => setSelectedId('abc')}
        >
          <div className='fpc_lc_chunk_left'>
            <FavoriteIcon className='fpc_lc_c_icon' />
            <p className='fpc_lc_c_text'>Favorites</p>
          </div>
        </div> */}
        {userFolderList.items.map((folder) => (
          <FolderListChunk
            key={folder.id}
            data={folder}
            deleteHandler={(id: string) => setDeleteId(id)}
            editHandler={editHandler}
            shareHandler={(id: string) => setShareId(id)}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        ))}
      </div>
      <div className='fpc_footer'>
        <ButtonImage
          className='fpc_f_button'
          iconClassName='fpc_f_b_icon'
          icon={AddIcon}
          onClick={() => setOpenCreateModal(true)}>
          Create New Folder
        </ButtonImage>
      </div>
      {deleteId && (
        <DeleteConfirmationModal
          title='Are you sure you want to delete this folder?'
          desc='This action cannot be undone.'
          onClose={() => setDeleteId(null)}
          onContinue={deleteHandler}
          disabledSecondButton={!deleteId}
        />
      )}
      {openCreateModal && (
        <CreateFolderModal onClose={() => setOpenCreateModal(false)} onConfirm={createFolderHandler} />
      )}
      {shareId && (
        <ShareReportModal
          endpoint={'folder' as ShareReportEndpoint}
          reportId={shareId}
          title='Share Folder'
          desc='Share this folder with one or several team members'
          secondButtonText='Share'
          icon={null}
          onClose={() => setShareId('')}
        />
      )}
    </div>
  )
})

export default memo(FolderPanel)
