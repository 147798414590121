import { ReactComponent as EmptyStateIcon } from 'assets/images/icons/report/reports-empty-state.svg'
import styles from './InvestigateReportsMainViewPage.module.scss'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as ReportButtonIcon } from 'assets/images/icons/report/report-button-stars-icon.svg'
import { useNavigate } from 'react-router-dom'

export default function EmptyState() {
  const navigate = useNavigate()
  return (
    <div className={styles.emptyState}>
      <div className={styles.card}>
        <EmptyStateIcon />
        <h5>Nothing to show here</h5>
        <p>No reports have been added yet. You can start by creating a new report.</p>
        <ButtonImage onClick={() => navigate('/reports/create')} icon={ReportButtonIcon}>
          Create Report
        </ButtonImage>
      </div>
    </div>
  )
}
