import { debounce, isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Modal, Radio, Switch, Collapse, Avatar, Button, Spin } from 'antd'

import UsersList from '../UsersList/UsersList'
import CustomSelect from '../common/CustomSelect/CustomSelect'
import SearchInput from '../common/Inputs/SearchInput/SearchInput'

import profileImage from 'assets/images/LogoiconMobile.svg'

import { store } from 'store'

import { alertAPI } from 'api/api'
import { displayApiError } from 'utils/helper'
import { SharedUserListObj, UserListObj } from 'store/asset/types'

import './AlertModal.scss'

const { Panel } = Collapse

type AlertModalProps = {
  open: boolean
  alertId: string
  entity_id: string
  onClose: () => void
  onSuccess: () => void
  onDeleteSuccess: () => void
  entity_type: 'NARRATIVE' | 'WATCHLIST' | 'ASSET' | null
}

const AlertModal: React.FC<AlertModalProps> = ({
  open,
  onClose,
  alertId,
  entity_type,
  entity_id,
  onSuccess,
  onDeleteSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeKey, setActiveKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [selectedFlags, setSelectedFlags] = useState<string[]>([])
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<SharedUserListObj[]>([])
  const [isActiveItems, setIsActiveItems] = useState(false)
  const [alertsForAllPosts, setAlertsForAllPosts] = useState(false)

  const { assetsStore, userStore } = store
  const { tenantId, userInfo } = userStore

  const {
    setGetAlertsLoading,
    getAlertsLoading,
    flagsListForAlerts,
    assetsListForAlerts,
    usersLIstWithSearch,
    fetchAndSearchUsers,
    setFlagsListForAlerts,
    setAssetsListForAlerts,
    getUserAndSearchLoading,
    fetchFlagsListForAlerts,
    fetchAssetsListForAlerts,
    setUsersListingWithSearch,
    createAssetsAlertsLoading,
    getFlagListForAlertsLoading,
    setCreateAssetsAlertsLoading,
    getAssetsListForAlertsLoading,
  } = assetsStore

  const disabledConfirmButton =
    (!isEmpty(selectedFlags) || !isEmpty(selectedAssets) || alertsForAllPosts) &&
    isActiveItems &&
    !isEmpty(selectedUsers)

  const flagsOptionsList = flagsListForAlerts.items?.map((item) => ({ value: item.id, label: item.name }))
  const assetsOptionsList = assetsListForAlerts.items?.map((item) => ({ value: item.id, label: item.name }))

  const isFlagsSelected = flagsListForAlerts.items?.some((item) => selectedFlags.includes(item.id))
  const isAssetsSelected = assetsListForAlerts.items?.some((item) => selectedAssets.includes(item.id))

  const toggleAlerts = async (isChecked: boolean) => {
    setIsActiveItems(isChecked)
    setActiveKey('')

    if (!isChecked) {
      setAlertsForAllPosts(false)
      setSelectedFlags([])
      setSelectedAssets([])
      if (alertId) handleDeleteAlert(alertId)
    }
  }

  const handleDeleteAlert = async (id: string) => {
    setCreateAssetsAlertsLoading(true)
    try {
      await alertAPI.deleteAlert(id)
      onDeleteSuccess()
      handleCloseModal()
    } catch (error) {
      displayApiError(error)
    } finally {
      setCreateAssetsAlertsLoading(false)
    }
  }

  const handleSelectAllPosts = () => {
    setAlertsForAllPosts((prev) => {
      if (!prev) {
        setSelectedFlags([])
        setSelectedAssets([])
        setActiveKey('')
      }
      return !prev
    })
  }

  const onScrollUsers = (e: React.UIEvent<HTMLDivElement>) => {
    if (!e.currentTarget || getUserAndSearchLoading) return
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    const sumScroll = scrollTop + clientHeight
    if (sumScroll >= scrollHeight - 1 && usersLIstWithSearch.total_count > usersLIstWithSearch.items.length)
      fetchAndSearchUsers({
        params: { ...usersLIstWithSearch.params, page: usersLIstWithSearch.params.page + 1 },
      })
  }

  const onFlagListScroll = () => {
    if (flagsListForAlerts.total_count > flagsListForAlerts.items.length)
      fetchFlagsListForAlerts({
        params: { ...flagsListForAlerts.params, page: flagsListForAlerts.params.page + 1 },
      })
  }

  const onAssetsListScroll = () => {
    if (assetsListForAlerts.total_count > assetsListForAlerts.items.length)
      fetchAssetsListForAlerts({
        params: { ...assetsListForAlerts.params, page: assetsListForAlerts.params.page + 1 },
      })
  }

  const condition = () => {
    if (!isEmpty(selectedFlags)) return { flag_ids: selectedFlags }
    if (!isEmpty(selectedAssets)) return { asset_ids: selectedAssets }
    return {}
  }

  const getAlertData = async () => {
    setGetAlertsLoading(true)
    try {
      const data = await alertAPI.getAlertUsers(alertId)
      const flag_ids = data.condition.flag_ids || []
      const asset_ids = data.condition.asset_ids || []
      const user_ids: SharedUserListObj[] = data.user_ids || []
      const owner = user_ids?.find((user) => user.email === userInfo.email)
      if (owner) setSelectedUsers(user_ids)
      else setSelectedUsers([...user_ids, userInfo])

      if (!isEmpty(flag_ids)) {
        setSelectedFlags(flag_ids)
        setActiveKey('1')
      } else if (!isEmpty(asset_ids)) {
        setSelectedAssets(asset_ids)
        setActiveKey('2')
      } else {
        setAlertsForAllPosts(true)
      }
    } catch (error) {
      displayApiError(error)
    } finally {
      setGetAlertsLoading(false)
    }
  }

  const searchDropHandler = useCallback((val: boolean) => {
    setIsOpen(val)
  }, [])

  const searchUsers = useCallback(
    (query: string) => {
      fetchAndSearchUsers({
        params: {
          page: 1,
          per_page: 10,
          sort: 'email:asc',
          q: `email:like:${query}`,
          tenant_id: tenantId || '',
        },
      })
    },
    [fetchAndSearchUsers, tenantId],
  )

  const debouncedSearchUsers = useMemo(() => debounce(searchUsers, 1000), [searchUsers])

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
      setSearchValue(newValue)
      if (newValue.trim()) debouncedSearchUsers(newValue.trim())
    },
    [debouncedSearchUsers],
  )

  const selectUserHandler = useCallback((userInfo: UserListObj) => {
    setSelectedUsers((prevUsers) => {
      const userIndex = prevUsers.findIndex((user) => user.user_id === userInfo.id)
      if (userIndex !== -1) return prevUsers.filter((_, index) => index !== userIndex)

      const newUser: SharedUserListObj = {
        family_name: userInfo.family_name,
        given_name: userInfo.given_name,
        user_id: userInfo.id,
        username: userInfo.username,
        email: userInfo.email,
        localAdd: true,
      }
      return [...prevUsers, newUser]
    })
    searchDropHandler(false)
  }, [])

  const handleAlertAction = async (action: 'create' | 'update') => {
    setCreateAssetsAlertsLoading(true)
    try {
      if (entity_type) {
        const payload = {
          entity_id,
          entity_type,
          user_ids: (selectedUsers.map((item) => item.user_id) as string[]) || [],
          condition: condition(),
        }
        if (action === 'create') {
          await alertAPI.createAlert({ payload })
        } else {
          await alertAPI.updatedAlert({ payload, id: alertId })
        }
        handleCloseModal()
        onSuccess()
      }
    } catch (error) {
      displayApiError(error)
    } finally {
      setCreateAssetsAlertsLoading(false)
    }
  }

  const handleCloseModal = useCallback(() => {
    setUsersListingWithSearch({
      items: [],
      total_count: 0,
      params: { page: 1, per_page: 10, sort: 'email:asc', q: '', tenant_id: tenantId || '' },
    })
    setFlagsListForAlerts({
      items: [],
      total_count: 0,
      params: { page: 1, per_page: 10, sort: 'name:asc' },
    })
    setAssetsListForAlerts({
      items: [],
      total_count: 0,
      params: { page: 1, per_page: 10, sort: 'name:asc', is_asset: true },
    })
    setAlertsForAllPosts(false)
    setIsActiveItems(false)
    setActiveKey('')
    setSelectedFlags([])
    setSelectedAssets([])
    setSelectedUsers([])
    onClose()
  }, [setUsersListingWithSearch, setFlagsListForAlerts, setAssetsListForAlerts, tenantId, onClose])

  const sortUsersWithTopEmail = (users: SharedUserListObj[]): SharedUserListObj[] => {
    const topUser = users.find((user) => user.email === userInfo.email)
    const otherUsers = users.filter((user) => user.email !== userInfo.email)

    return topUser ? [topUser, ...otherUsers] : otherUsers
  }

  const selectedUserIds = selectedUsers?.map((user) => user.user_id)
  const filteredUserList = usersLIstWithSearch.items?.filter((user) => !selectedUserIds?.includes(user.id))

  useEffect(() => {
    if (alertId) {
      getAlertData()
      setIsActiveItems(true)
    }
    if (open) {
      fetchAndSearchUsers({ params: { page: 1, per_page: 10, sort: 'email:asc', tenant_id: tenantId || '' } })
      fetchFlagsListForAlerts({ params: { page: 1, per_page: 10, sort: 'name:asc' } })
      fetchAssetsListForAlerts({ params: { page: 1, per_page: 10, sort: 'name:asc', is_asset: true } })
    }
  }, [open])

  useEffect(() => {
    if (isActiveItems)
      setSelectedUsers((prev) => {
        const owner = prev.some((user) => user.email === userInfo.email)
        if (owner) return prev
        return [...prev, userInfo]
      })
  }, [isActiveItems])

  return (
    <Modal
      title='Alerts'
      open={open && !!entity_type}
      className='alert-modal'
      onCancel={handleCloseModal}
      onOk={() => handleAlertAction(alertId ? 'update' : 'create')}
      okButtonProps={{ disabled: !disabledConfirmButton }}
      destroyOnClose
      footer={
        <div className='feed-export__footer'>
          <button className='feed-export__footer__cancel' onClick={handleCloseModal}>
            Cancel
          </button>
          <button
            className='feed-export__footer__send'
            onClick={() => handleAlertAction(alertId ? 'update' : 'create')}
            disabled={!disabledConfirmButton || createAssetsAlertsLoading || getAlertsLoading}>
            Confirm
          </button>
        </div>
      }>
      <Spin spinning={createAssetsAlertsLoading || getAlertsLoading}>
        <div className='alert-modal-body'>
          <div className='alert-modal-body-header'>
            <div className='alert-modal-body-header-info'>
              <div className='alert-modal-title'>Alerts</div>
              <div className='alert-modal-toggle-button-title'>Turn on alerts for the selected item</div>
            </div>
            <Switch className='amc_switch' checked={isActiveItems} onChange={toggleAlerts} />
          </div>
          <div className='alert-conditions-wrapper'>
            <div className='alert-conditions-title' aria-disabled={!isActiveItems}>
              Select alert condition for this item:
            </div>

            <div className='alert-conditions'>
              <div
                className='alert-condition'
                aria-disabled={!isActiveItems}
                onClick={() => (!isActiveItems ? null : handleSelectAllPosts())}>
                <Radio
                  className='alert-radio'
                  checked={alertsForAllPosts}
                  disabled={!isActiveItems}
                  onChange={() => null}
                />
                <div className='alert-condition-info'>
                  <div className='alert-condition-title' aria-disabled={!isActiveItems}>
                    All posts
                  </div>
                  <div className='alert-modal-radio-button-title' aria-disabled={!isActiveItems}>
                    You’ll be alerted about all new posts
                  </div>
                </div>
              </div>
              <Collapse activeKey={activeKey}>
                <Panel
                  collapsible={!isActiveItems ? 'disabled' : 'header'}
                  header={
                    <div
                      className='alert-condition'
                      aria-disabled={!isActiveItems}
                      onClick={() => {
                        if (!isActiveItems) return
                        setActiveKey('1')
                        setAlertsForAllPosts(false)
                        setSelectedAssets([])
                      }}>
                      <Radio
                        className='alert-radio'
                        checked={activeKey?.includes('1') || isFlagsSelected}
                        disabled={!isActiveItems}
                        onChange={() => null}
                      />
                      <div className='alert-condition-info'>
                        <div className='alert-condition-title' aria-disabled={!isActiveItems}>
                          Flagged posts
                        </div>
                        <div className='alert-modal-radio-button-title' aria-disabled={!isActiveItems}>
                          You’ll be alerted about all new flagged posts for the selected flags
                        </div>
                      </div>
                    </div>
                  }
                  key={'1'}>
                  <CustomSelect
                    placeholder='Select one or several flags'
                    selectedItems={selectedFlags}
                    setSelectedItems={setSelectedFlags}
                    options={flagsOptionsList}
                    onScroll={onFlagListScroll}
                    loading={getFlagListForAlertsLoading}
                  />
                </Panel>
              </Collapse>
              <Collapse activeKey={activeKey}>
                <Panel
                  collapsible={!isActiveItems ? 'disabled' : 'header'}
                  header={
                    <div
                      className='alert-condition'
                      aria-disabled={!isActiveItems}
                      onClick={() => {
                        if (!isActiveItems) return
                        setActiveKey('2')
                        setAlertsForAllPosts(false)
                        setSelectedFlags([])
                      }}>
                      <Radio
                        className='alert-radio'
                        checked={activeKey === '2' || isAssetsSelected}
                        disabled={!isActiveItems}
                        onChange={() => null}
                      />
                      <div className='alert-condition-info'>
                        <div className='alert-condition-title' aria-disabled={!isActiveItems}>
                          Asset posts
                        </div>
                        <div className='alert-modal-radio-button-title' aria-disabled={!isActiveItems}>
                          You’ll be alerted about all new assets posts for the selected assets
                        </div>
                      </div>
                    </div>
                  }
                  key={'2'}>
                  <CustomSelect
                    placeholder='Select one or several assets'
                    options={assetsOptionsList}
                    selectedItems={selectedAssets}
                    setSelectedItems={setSelectedAssets}
                    onScroll={onAssetsListScroll}
                    loading={getAssetsListForAlertsLoading}
                  />
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className='alert-modal-search-wrapper'>
            <div className='search-label' aria-disabled={!isActiveItems}>
              Who will receive alerts
            </div>
            <SearchInput
              showIcon={false}
              lastIcon
              isOpen={isOpen}
              showDrop={true}
              value={searchValue}
              onScroll={onScrollUsers}
              disabled={!isActiveItems}
              setIsOpen={searchDropHandler}
              onChange={handleSearchChange}
              className='alert-modal-search'
              loading={getUserAndSearchLoading}
              placeholder='Search by name or email'
              onClear={() => {
                setSearchValue('')
                fetchAndSearchUsers({
                  params: {
                    page: 1,
                    per_page: 10,
                    sort: 'email:asc',
                    tenant_id: tenantId || '',
                  },
                })
              }}>
              <div className='srmc_input_content_container'>
                <UsersList data={filteredUserList} className='srmc_input_cc_chunk' onClick={selectUserHandler} />
              </div>
            </SearchInput>
          </div>
          {isActiveItems && !isEmpty(selectedUsers) && (
            <div className='search-results'>
              {sortUsersWithTopEmail(selectedUsers)?.map((item) => (
                <div className='profile-wrapper' key={`${item.email}-profile`}>
                  <div className='user-profile'>
                    <div className='user-profile-image'>
                      <Avatar src={item.avatar_url || profileImage} size={'large'} />
                    </div>
                    <div className='user-profile-info'>
                      <div className='user-profile-info-name'>{`${item.given_name || ''} ${
                        item.family_name || ''
                      }`}</div>
                      <div className='user-profile-info-email'>{item.email || ''}</div>
                    </div>
                  </div>
                  <div className='profile-wrapper-actions'>
                    {item.email === userInfo.email ? (
                      <span className='owner-title'>Owner</span>
                    ) : (
                      <Button
                        className='profile-wrapper-actions-button'
                        onClick={() =>
                          setSelectedUsers((prev) => prev.filter((prevUser) => prevUser.user_id !== item.user_id))
                        }>
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default observer(AlertModal)
