import { ListPageSection } from 'components/common/ListPageSection/ListPageSection'
import { NavigationSettings } from 'components/common/NavigationSettings/NavigationSettings'
import { Spotlight } from 'components/Spotlight/Spotlight'

export const SpotlightPage = () => {
  return (
    <div>
      <ListPageSection
        left={<NavigationSettings />}
        right={<Spotlight />}
        columns={{
          col1: {
            sm: 4,
            md: 4,
            lg: 4,
          },
          col2: {
            sm: 20,
            md: 20,
            lg: 20,
          },
        }}
      />
    </div>
  )
}
