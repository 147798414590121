import axios from 'axios'

import { ROUTES } from 'settings/settings'

export const getSpotlight = async (id?: string) => {
  let url = process.env.REACT_APP_API + ROUTES.watchlist

  if (!!id) url = `${process.env.REACT_APP_API}${ROUTES.spotlight}/${id}`

  const { data } = await axios.get(url)

  return data
}
