import { API, userAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import { IUserToSave, IUsers, IUsersResponse } from 'models/models'
import { UtilService } from 'services/Util/Util'
import { ROLES } from 'settings/roles'

export class UsersStore {
  currentUser: IUsers | null = null
  users: IUsersResponse = {
    total_count: 0,
    items: [],
  }
  usersPagination: { total: number; pageSize: number; currentPageNumber: number } = {
    total: 0,
    pageSize: 10,
    currentPageNumber: 0,
  }
  searchParam: string | null = null

  constructor() {
    makeObservable(this, {
      currentUser: observable,
      users: observable,
      usersPagination: observable,
      searchParam: observable,
      setCurrentUser: action.bound,
      setUsers: action.bound,
      setUsersWithConcat: action.bound,
      setUsersPagination: action.bound,
      setSearchParam: action.bound,
    })
  }

  setCurrentUser = (data: IUsers) => {
    this.currentUser = data
  }

  setUsers = (data: IUsersResponse) => {
    this.users = data
  }

  setUsersWithConcat = (data: IUsersResponse) => {
    this.users = { total_count: data.total_count, items: [...this.users.items, ...data.items] }
  }

  setUsersPagination = (pagination: { total: number; pageSize: number; currentPageNumber: number }) => {
    this.usersPagination = pagination
  }

  setSearchParam = (value: string) => {
    this.searchParam = value
  }

  getUser = (userId: string) => {
    if (userId?.length) {
      const user = this.users.items.filter((user) => user.id === userId)
      const name = user?.length > 0 ? `${user[0]?.given_name || ''} ${user[0]?.family_name || ''}` : ''
      return name?.length ? name : 'N/A'
    }
    return 'N/A'
  }

  saveUser = async (user: IUserToSave) => {
    try {
      const { data } = await userAPI.saveUser(user)
      if (data) {
        this.fetchUsersWithPagination({ page: 1, pageSize: this.usersPagination.pageSize })
        return data
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  updateUserRole = async (user: IUsers, newRoleId: string) => {
    try {
      const newRoleString = ROLES.find((item) => item.id === newRoleId)?.role
      if (!newRoleString) return

      const { data } = await userAPI.updateUserRole({ username: user.username, newRoleId })
      return data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  deleteUser = async (userName: string) => {
    try {
      return await userAPI.deleteUser(userName)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  resetUserPassword = async (username: string) => {
    try {
      const { data } = await userAPI.resetUser({ username, admin_reset_password: true })
      if (data) {
        return data
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchUser = async (email: string) => {
    try {
      if (email?.length) {
        const { data } = await API.get({ route: 'user', id: email || '', getError: true })
        return data
      }
      return {
        username: '',
        email: '',
        given_name: '',
        family_name: '',
        role_id: '',
        tenant_id: '',
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchUsers = async (page?: number, pageSize?: number, concat?: boolean) => {
    try {
      const { data } = await API.get({ route: 'user', getError: true, page, pageSize })
      if (concat) {
        this.setUsersWithConcat(data)
      } else {
        this.setUsers(data)
      }
      return data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchUsersWithPagination = async ({ pageSize, page }: { pageSize: number; page: number }) => {
    try {
      const newPage = Math.ceil(this.usersPagination.total / this.usersPagination.pageSize)
      if (newPage < page && this.usersPagination.total !== 0) {
        return
      }
      const { data } = await API.get({
        route: 'user',
        page,
        sort: 'email:asc',
        pageSize,
        filter: this.searchParam && this.searchParam?.length > 0 ? `email:like:${this.searchParam}` : '',
        getError: true,
      })

      this.setUsers(data.items)
      this.setUsersPagination({
        total: data.total_count,
        pageSize: pageSize,
        currentPageNumber: page,
      })
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  resetStore = () => {
    this.currentUser = null
    this.users = {
      total_count: 0,
      items: [],
    }
    this.usersPagination = {
      total: 0,
      pageSize: 10,
      currentPageNumber: 0,
    }
    this.searchParam = ''
  }
}
