import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router-dom'

import { store } from 'store'

import { useUserRole } from 'hooks/UserRoleContext'

interface Props {
  element: JSX.Element
  allowedRoles: string[]
  [x: string]: any
}

export const PrivateRoute = observer(({ element, allowedRoles, ...rest }: Props): JSX.Element => {
  const { userStore } = store
  const { userName } = userStore
  const { userRole } = useUserRole()

  const isAllowed = allowedRoles.includes(userRole)
  return userName ? isAllowed ? element : <Navigate replace to='/assets' /> : <Navigate replace to='/signin' />
})
