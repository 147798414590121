import { useUserRole } from './UserRoleContext'

import { rolesPermissions } from 'utils/constant'

const usePermissions = (feature: string): string => {
  const { userRole } = useUserRole()
  return rolesPermissions[userRole][feature]
}

export default usePermissions
