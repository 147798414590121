import { useMemo } from 'react'
import Input from 'components/common/Inputs/Input/Input'
import Dropdown, { DropdownOption } from 'components/common/Inputs/Dropdown/Dropdown'
import TextArea from 'components/common/Inputs/TextArea/TextArea'
import { AssetCategoryType } from 'store/asset/types'
import './CreateAssetModalStep1.scss'

export function transformToDropdownOption(data: AssetCategoryType[]): DropdownOption[] {
  return data.map((item) => ({
    id: item.category_id,
    label: item.name,
  }))
}

type CreateAssetModalStep1Props = {
  categorties: AssetCategoryType[]
  flagName: string
  setFlagName: (name: string) => void
  flagCategory: DropdownOption
  setFlagCategory: (category: DropdownOption) => void
  flagDesc: string
  setFlagDesc: (name: string) => void
}

const CreateAssetModalStep1 = (props: CreateAssetModalStep1Props) => {
  const { categorties, flagName, setFlagName, flagDesc, setFlagDesc, flagCategory, setFlagCategory } = props

  const categories = useMemo(() => {
    return transformToDropdownOption(categorties)
  }, [categorties])

  return (
    <div className='create_asset_modal_step1_container'>
      <Dropdown
        data={categories}
        title='Select asset type'
        placeholder='Select asset type'
        value={flagCategory.label}
        onSelect={(option) => setFlagCategory(option)}
      />
      <Input
        title='Asset name'
        placeholder='Enter an asset name'
        value={flagName}
        onChange={(e) => setFlagName(e.target.value)}
      />
      <TextArea
        title='Description'
        placeholder='Enter a description...'
        value={flagDesc}
        onChange={(e) => setFlagDesc(e.target.value)}
      />
    </div>
  )
}

export default CreateAssetModalStep1
