import moment from 'moment'
import { store } from 'store'
import { Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range'

import InputForTime from './InputForTime'

import { SubStore } from 'types/types'
import { GenericDispatch } from 'utils/commonTypes'

import './DateTimeRangeFilter.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DateTimeRangeFilter = ({
  setUpdatedDateAndTime,
  subStore,
}: {
  setUpdatedDateAndTime: GenericDispatch<{ startDateAndTime: string; endDateAndTime: string }>
  subStore: SubStore
}) => {
  const { snippetsFilter } = store[`${subStore}Store`]
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const [startTime, setStartTime] = useState('12:00 AM')
  const [endTime, setEndTime] = useState('11:59 PM')
  const [isEndTimeEnabled, setIsEndTimeEnabled] = useState(false)

  const handleDateChange = (item: any) => {
    setDateRange(item.selection)
  }

  const handleTimeChange = (time: string, type: 'start' | 'end') => {
    if (type === 'start') {
      setStartTime(time)
    } else {
      setEndTime(time)
    }
  }

  const combineDateAndTime = (date: Date, time: string) => {
    return moment(date).format('YYYY-MM-DD') + ' ' + time
  }

  useEffect(() => {
    if (snippetsFilter.days.custom.isApplied && snippetsFilter.days.custom.dateRange?.length > 0) {
      setDateRange({
        startDate: moment(snippetsFilter.days.custom.dateRange[0]).toDate(),
        endDate: moment(snippetsFilter.days.custom.dateRange[1]).toDate(),
        key: 'selection',
      })

      setStartTime(moment(snippetsFilter.days.custom.dateRange[0]).format('hh:mm A'))
      setEndTime(moment(snippetsFilter.days.custom.dateRange[1]).format('hh:mm A'))
      setIsEndTimeEnabled(true)
    }
  }, [snippetsFilter.days.custom.isApplied, snippetsFilter.days.custom.dateRange])

  useEffect(() => {
    setUpdatedDateAndTime({
      startDateAndTime: combineDateAndTime(dateRange.startDate, startTime),
      endDateAndTime: combineDateAndTime(dateRange.endDate, endTime),
    })
  }, [dateRange, startTime, endTime])

  const handleSwitchChange = (checked: boolean) => {
    setIsEndTimeEnabled(checked)
    if (!checked) {
      setEndTime('11:59 PM')
    }
  }

  useEffect(() => {
    setUpdatedDateAndTime({
      startDateAndTime: combineDateAndTime(dateRange.startDate, startTime).toString(),
      endDateAndTime: combineDateAndTime(dateRange.endDate, endTime).toString(),
    })
  }, [dateRange, startTime, endTime])

  return (
    <div className='date-time-filter-container'>
      <DateRangePicker
        className='date-filter__calendar-dropdown__container'
        onChange={handleDateChange}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[dateRange]}
        direction='horizontal'
        staticRanges={[]}
        inputRanges={[]}
        editableDateInputs
        dateDisplayFormat={'yyyy-MM-dd'}
        monthDisplayFormat={'MMMM'}
        maxDate={new Date()}
      />

      <div className='time-inputs-container'>
        <div className='input-label-container'>
          <label>Start from</label>
          <InputForTime value={startTime} onChange={(time) => handleTimeChange(time, 'start')} />
        </div>

        <div className='enable-disable-switch'>
          <span>Add end time</span>
          <Switch
            className='amc_switch'
            checked={isEndTimeEnabled}
            onChange={handleSwitchChange}
            aria-label='Turn On/Off'
          />
        </div>

        {isEndTimeEnabled && (
          <div className='input-label-container'>
            <label>End at</label>
            <InputForTime value={endTime} onChange={(time) => handleTimeChange(time, 'end')} />
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(DateTimeRangeFilter)
