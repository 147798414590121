import React, { useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import ReportDateRange from '../ReportDateRange/ReportDateRange'
import DetailViewSections from '../DetailViewSections/DetailViewSections'
import { ReactComponent as CreatedIcon } from 'assets/images/icons/report/created-icon-size-16.svg'
import { ReactComponent as RecurringIcon } from 'assets/images/icons/report/recurring-icon-size-16.svg'
import { ReactComponent as CalendarIcon } from 'assets/images/icons/report/calendar-icon-size-14.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'
import DetailViewHistory from '../DetailViewHistory/DetailViewHistory'
import ShareWith from '../ShareWith/ShareWith'
import { LastUpdateObj, sectionObj } from 'store/report/valueTypes'
import styles from './DetailViewLeftSection.module.scss'
import ShareReportModal from '../ShareReportModal/ShareReportModal'

const DATE_RANGE_FORMATE = 'MMM DD, YYYY'
const CREATE_DATE_FORMATE = 'MMMM DD, YYYY'
const LAST_MODIFIED_DATE_FORMATE = 'DD MMMM YYYY'

const reportType = {
  ADHOC: 'Single',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
}

type DetailViewLeftSectionProps = {
  onClick?: (id: string) => void
  selectedSection?: sectionObj
  viewHistoryHandler?: () => void
}

const DetailViewLeftSection = observer((props: DetailViewLeftSectionProps) => {
  const navigate = useNavigate()
  const { onClick, selectedSection, viewHistoryHandler } = props
  const { reportMainViewStore } = store
  const { reportDetails, reportDetailsEntities, getOrDeleteReportHandler } = reportMainViewStore
  const [dateRangeValue, setDateRangeValue] = useState<LastUpdateObj>({})
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)

  const dateRangeHandler = (obj: LastUpdateObj) => {
    setDateRangeValue(obj)
    navigate(`/reports/${reportDetails.id}?instance_id=${obj.instance_id}`, { replace: true })
  }

  const dateRange = useMemo(() => {
    if (Object.keys(dateRangeValue).length) {
      return `${dayjs(dateRangeValue.start_date).format(DATE_RANGE_FORMATE)} - ${dayjs(dateRangeValue.end_date).format(
        DATE_RANGE_FORMATE,
      )}`
    } else if (reportDetailsEntities.start_date) {
      return `${dayjs(reportDetailsEntities.start_date).format(DATE_RANGE_FORMATE)} - ${dayjs(
        reportDetailsEntities.end_date,
      ).format(DATE_RANGE_FORMATE)}`
    }
    return ''
  }, [reportDetailsEntities.start_date, reportDetailsEntities.end_date, dateRangeValue])

  useEffect(() => {
    if (reportDetailsEntities.instance_id) {
      setDateRangeValue({
        instance_id: reportDetailsEntities.instance_id,
        start_date: new Date(reportDetailsEntities.start_date!),
        end_date: new Date(reportDetailsEntities.end_date!),
      })
    }
  }, [reportDetailsEntities.instance_id])

  return (
    <div className={styles.detail_view_left_container}>
      <ReportDateRange
        data={reportDetails.report_instance_history}
        value={dateRange}
        selectedValue={dateRangeValue}
        onSelect={(res) => dateRangeHandler(res)}
      />
      <p className={styles.dvlc_history_text} onClick={viewHistoryHandler}>
        View version history
      </p>
      <DetailViewSections data={reportDetailsEntities.sections} onClick={onClick} selectedSection={selectedSection} />
      <div className={styles.dvlc_history}>
        <DetailViewHistory icon={CalendarIcon} title='Created on:'>
          <p className={styles.dvlc_h_text}>{dayjs(reportDetails.created).format(CREATE_DATE_FORMATE)}</p>
        </DetailViewHistory>
        <DetailViewHistory icon={CreatedIcon} title='Report type:'>
          <p className={styles.dvlc_h_text}>
            {reportType[reportDetails.report_type as keyof typeof reportType] || '-'}
          </p>
        </DetailViewHistory>
        <DetailViewHistory icon={RecurringIcon} title='Recurring'>
          <p className={styles.dvlc_h_text}>
            {reportDetails.report_type === 'ADHOC'
              ? `${dayjs(reportDetailsEntities.start_date).format(DATE_RANGE_FORMATE)} - ${dayjs(
                  reportDetailsEntities.end_date,
                ).format(DATE_RANGE_FORMATE)}`
              : reportDetails.weekly_report_day || '-'}
          </p>
        </DetailViewHistory>
        <DetailViewHistory
          icon={ShareIcon}
          title='Shared with'
          className={styles.dvlc_h_share_container}
          onClick={() => {
            setIsShareModalOpen(true)
          }}>
          {'shared_users' in reportDetails && reportDetails.shared_users.length ? (
            <ShareWith data={reportDetails.shared_users} className={styles.dvlc_h_share_users} maxShow={5} />
          ) : (
            '-'
          )}
        </DetailViewHistory>
      </div>
      <p className={styles.dvlc_last_modify_text}>
        Last modified on: {dayjs(reportDetails.last_update_time).format(LAST_MODIFIED_DATE_FORMATE)}
      </p>

      {isShareModalOpen && (
        <ShareReportModal
          endpoint='reports'
          reportId={reportDetailsEntities.id}
          callback={() => getOrDeleteReportHandler('get', reportDetailsEntities.id)}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
    </div>
  )
})

export default DetailViewLeftSection
