import { useMemo } from 'react'
import { Avatar, Skeleton, Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as InfluencerEmptyStateImage } from 'assets/images/icons/dashboard/influencer-empty-state.svg'
import profileImage from 'assets/images/LogoiconMobile.svg'
import { IBarChartData } from 'models/models'
import './InfluencerTable.scss'

const InfluencerTable = ({
  subStore = 'assets',
  onRowClick,
}: {
  subStore?: 'assets' | 'vectors'
  onRowClick: (item: IBarChartData) => void
}) => {
  const {
    powerInsightsData: { watchlistData },
    isLoadingWatchListWithSummary,
  } = store[`${subStore}Store`]

  const columns = useMemo(
    () => [
      {
        title: () => <span className='creators-list__table__title'>Watchlist</span>,
        dataIndex: 'title',
        key: 'entity_name',
        width: '25%',
        render: (_: any, { entity_name, total_channels = 0 }: IBarChartData) => (
          <div className='info-wrapper'>
            <div className='avatar'>
              <Avatar src={profileImage} size='large' />
            </div>
            <div className='info'>
              <div className='name'>{entity_name}</div>
              <div className='count'>
                {total_channels} {total_channels > 1 ? 'creators' : 'creator'}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: () => <span className='creators-list__table__title'>Summary</span>,
        dataIndex: 'summary',
        key: 'summary',
        width: '75%',
        render: (_: any, item: IBarChartData) => {
          return <p className='description'>{item.summary?.text}</p>
        },
      },
    ],
    [],
  )

  const columnsEmptyState = useMemo(
    () => [
      {
        title: () => <span className='creators-list__table__title'>Watchlist</span>,
        key: 'name',
        width: '25%',
        render: () => (
          <div className='info-wrapper'>
            <Skeleton.Avatar size='large' active />
            <div className='info'>
              <Skeleton active className='skeleton-paragraph-small' />
            </div>
          </div>
        ),
      },
      {
        title: () => <span className='creators-list__table__title'>Summary</span>,
        key: 'summary',
        width: '75%',
        render: () => <Skeleton paragraph={{ rows: 1 }} active className='skeleton-paragraph' />,
      },
    ],
    [],
  )

  if (!isLoadingWatchListWithSummary && watchlistData?.completeData.length === 0) {
    return (
      <>
        <span className='monitor-assets__graph__title'>Influential voices</span>
        <div className='influencers-empty-state'>
          <InfluencerEmptyStateImage />
          <h5>Influential voices</h5>
          <p>No data to display here</p>
        </div>
      </>
    )
  }

  return (
    <>
      <span className='monitor-assets__graph__title'>Influential voices</span>
      <div className='influencer-table'>
        {!isLoadingWatchListWithSummary && (
          <Table
            className='influencer-table__table'
            columns={columns}
            dataSource={watchlistData?.completeData || []}
            pagination={{ pageSize: 5, showSizeChanger: false, position: ['bottomLeft'], hideOnSinglePage: true }}
            onRow={(record) => ({
              onClick: () => onRowClick(record),
            })}
          />
        )}
        {isLoadingWatchListWithSummary && (
          <Table
            className='influencer-table__table'
            columns={columnsEmptyState}
            dataSource={[1, 2, 3, 4, 5].map(() => ({}))}
            pagination={false}
          />
        )}
      </div>
    </>
  )
}

export default observer(InfluencerTable)
