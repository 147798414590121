import React from 'react'
import styles from './DetailPanelRightSectionHeader.module.scss'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import FilterDropdown from '../FilterDropdown/FilterDropdown'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import classNames from 'classnames'

type DetailPanelRightSectionHeaderProps = {
  className?: string
}

function DetailPanelRightSectionHeader(props: DetailPanelRightSectionHeaderProps) {
  const { className } = props
  const navigate = useNavigate()
  const { reportId } = useParams()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get('instance_id')
  return (
    <div className={classNames(styles.dprsh_container, className)}>
      <div className={styles.dprsh_c_heading_container}>
        <p className={styles.dprsh_c_heading}>Data Sources</p>
        <FilterDropdown />
      </div>
      <CloseIcon
        className={styles.dprsh_c_close_icon}
        onClick={() => {
          navigate(`/reports/${reportId}?instance_id=${instanceId}`)
        }}
      />
    </div>
  )
}

export default DetailPanelRightSectionHeader
