import { useMemo } from 'react'
import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import './Checkbox.scss'

type CheckboxProps = {
  onClick?: () => void
  checked?: boolean
  showLine?: boolean
  className?: string
  isPropagation?: boolean
}
function Checkbox(props: CheckboxProps) {
  const { onClick, checked, showLine, className = '', isPropagation = true } = props

  const renderUI = useMemo(() => {
    if (showLine) {
      return <div className='ccs_line'></div>
    }
    if (checked) {
      return <CheckIcon />
    }
  }, [showLine, checked])
  return (
    <div
      className={`checkbox_container${checked ? ' checkbox_container_selected' : ''} ${className}`}
      onClick={(e) => {
        if (isPropagation) e.stopPropagation()
        onClick?.()
      }}>
      {renderUI}
    </div>
  )
}

export default Checkbox
