import { notification } from 'antd'
import { ReactNode } from 'react'

export const openNotification = ({
  type = 'info',
  message,
  description,
  placement,
  className = 'c-notification',
  style,
  duration,
  icon,
}: {
  type: 'info' | 'error'
  message?: string
  description?: string | JSX.Element
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  className?: string
  style?: React.CSSProperties
  duration?: number
  icon?: ReactNode
}) => {
  notification[type]({
    message,
    description,
    className,
    style,
    placement,
    duration,
    icon,
  })
}
