import { store } from 'store'
import { useEffect } from 'react'
import classNames from 'classnames'
import { Avatar, Col, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import profileImage from 'assets/images/LogoiconMobile.svg'

import SearchStats from './SearchStats'

const PulseRightComponent = () => {
  const { vectorsStore } = store

  const {
    snippetsFilter,
    fetchWatchlist,
    fetchNarratives,
    watchListLoading,
    narrativeLoading,
    watchlistListData,
    narrativesListData,
    paginationValues: searchPagination,
    setPaginationValues: setSearchPagination,
  } = vectorsStore

  const firstCallRequestParams = {
    page: 1,
    per_page: 5,
    sort: 'name:asc',
  }

  const isNarrativePaginationActive = narrativesListData.items?.length < narrativesListData.total_count

  useEffect(() => {
    fetchNarratives({ params: firstCallRequestParams })
    fetchWatchlist({ params: firstCallRequestParams })
  }, [snippetsFilter])

  return (
    <Col span={6} className='pulse-dashboard__searches-container'>
      <div className='searches'>
        <div className='searches__pagination'>
          <div
            className={classNames('searches__pagination__back-button pagination-btn', {
              disabled_pagination: searchPagination.page <= 1,
            })}
            onClick={() => {
              if (searchPagination.page > 1) {
                setSearchPagination({ ...searchPagination, page: searchPagination.page - 1 })
                fetchNarratives({ params: { ...firstCallRequestParams, page: searchPagination.page - 1 } })
              }
            }}>
            <LeftOutlined />
          </div>
          <div className='searches__pagination__text'>Searches</div>
          <div
            className={classNames('searches__pagination__forward-button pagination-btn', {
              disabled_pagination: !isNarrativePaginationActive,
            })}
            onClick={() => {
              if (isNarrativePaginationActive) {
                setSearchPagination({ ...searchPagination, page: searchPagination.page + 1 })
                fetchNarratives({ params: { ...firstCallRequestParams, page: searchPagination.page + 1 } })
              }
            }}>
            <RightOutlined />
          </div>
        </div>
        <Spin spinning={narrativeLoading}>
          <div className='searches__listing'>
            {narrativesListData.items?.map((item, index) => (
              <SearchStats subStore='vectors' itemData={item} listItemNumber={index + 1} />
            ))}
          </div>
        </Spin>
      </div>

      <div className='watchlist' style={{ overflow: 'scroll', height: '385px' }}>
        <div className='watchlist__label'>Featured Watchlists</div>
        <Spin spinning={watchListLoading}>
          <div className='watchlist__content'>
            {watchlistListData.items?.map((item) => (
              <div className='watchlist__content__item' key={item?.name}>
                <div className='watchlist__content__item__avatars'>
                  <Avatar.Group maxCount={3} size={'large'}>
                    <Avatar src={profileImage} size={'large'} />
                  </Avatar.Group>
                </div>
                <div className='watchlist__content__item__stats'>
                  <div className='watchlist__content__item__stats__label'>{item?.name}</div>
                  {/* @ts-ignore */}
                  <div className='watchlist__content__item__stats__value'>{item?.total_channels} creators</div>
                </div>
              </div>
            ))}
          </div>
        </Spin>
      </div>
    </Col>
  )
}

export default observer(PulseRightComponent)
