import axios from 'axios'

import { ROUTES } from 'settings/settings'

interface Props {
  action?: 'ADD_SNIPPET' | 'REMOVE_SNIPPET' | 'GENERATE_BRIEF' | 'EDIT_BRIEF'
  spotlight_id?: string
  snippet?: {
    post_id?: string
    snippet_text?: string
    post_url?: string
    impression_count?: number
    upload_date?: string
    platform?: string
    channel_name?: string
    offset?: number
  }
  brief?: string
  recipients?: string[]
}

export const spotlightActions = async (spotlight: Props) => {
  let url = `${process.env.REACT_APP_API}${ROUTES.spotlight}/actions`

  const { data } = await axios.post(url, spotlight)

  return data
}
