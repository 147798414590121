import CustomModal from 'components/common/CustomModal/CustomModal'
import { observer } from 'mobx-react-lite'
import { ReactComponent as BellIcon } from 'assets/images/alert-icon.svg'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { Radio, Select, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { store } from 'store'
import { flagsAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'
import './DailyExecutiveSummary.scss'

const weeklySubscriptionDays = [
  { label: 'Every Monday', value: 'Monday' },
  { label: 'Every Tuesday', value: 'Tuesday' },
  { label: 'Every Wednesday', value: 'Wednesday' },
  { label: 'Every Thursday', value: 'Thursday' },
  { label: 'Every Friday', value: 'Friday' },
  { label: 'Every Saturday', value: 'Saturday' },
  { label: 'Every Sunday', value: 'Sunday' },
]

type WeekDayValue = (typeof weeklySubscriptionDays)[number]['value']
type SummarySubscriptionStatus = {
  is_subscribed: boolean
  subscription_frequency?: 'DAILY' | 'WEEKLY'
  weekly_subscription_day?: WeekDayValue
}

type PropsType = {
  setIsOpenSubscribeModal: (status: boolean) => void
  summarySubscriptionStatus?: SummarySubscriptionStatus
  onSuccess: () => void
}

const ExecutiveSummarySubscriptionModal = (props: PropsType) => {
  const { setIsOpenSubscribeModal, summarySubscriptionStatus, onSuccess } = props
  const [subscriptionType, setSubscriptionType] = useState<'DAILY' | 'WEEKLY'>('DAILY')
  const [weeklySubscriptionDay, setWeeklySubscriptionDay] = useState<WeekDayValue>('Monday')
  const [isLoading, setIsLoading] = useState(false)
  const { activeItem } = store.assetsStore

  useEffect(() => {
    if (summarySubscriptionStatus) {
      setSubscriptionType(summarySubscriptionStatus.subscription_frequency || 'DAILY')
      setWeeklySubscriptionDay(summarySubscriptionStatus.weekly_subscription_day || 'Monday')
    }
  }, [summarySubscriptionStatus])

  const onConfirm = async () => {
    setIsLoading(true)
    try {
      if (!activeItem?.id) return
      const data = {
        search_type: 'ASSET',
        subscription_frequency: subscriptionType,
        weekly_subscription_day: weeklySubscriptionDay,
      }

      await flagsAPI.createSummarySubscription({
        data,
        id: activeItem.id,
        method: 'post',
      })
      onSuccess()
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const unSubscribeSummary = async () => {
    setIsLoading(true)
    try {
      if (!activeItem?.id) return
      await flagsAPI.deleteSummarySubscription(activeItem.id)
      onSuccess()
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CustomModal
      title='Subscribe for Executive summary'
      firstButtonText='Cancel'
      secondButtonText='Confirm'
      onClose={() => setIsOpenSubscribeModal(false)}
      onCancel={() => setIsOpenSubscribeModal(false)}
      onConfirm={onConfirm}
      icon={null}
      bodyClassName='executive-summary-body'>
      <Spin spinning={isLoading}>
        {summarySubscriptionStatus?.is_subscribed && (
          <div className='subscription-status'>
            <div className='bell-icon'>
              <BellIcon />
            </div>
            <div className='status'>
              You are subscribed for <span>{summarySubscriptionStatus.subscription_frequency}</span> updates and receive
              updates each{' '}
              <span>
                {summarySubscriptionStatus.subscription_frequency === 'DAILY'
                  ? 'day'
                  : summarySubscriptionStatus.weekly_subscription_day}
              </span>
              .
            </div>
            <ButtonImage onClick={unSubscribeSummary} className='fhc_sc_button'>
              Unsubscribe
            </ButtonImage>
          </div>
        )}

        <div className='subscription-options'>
          <Radio.Group onChange={(event) => setSubscriptionType(event?.target.value)} value={subscriptionType}>
            <Space direction='vertical' size={16}>
              <Radio value='DAILY'>
                <div className='label-radio'>
                  <h4>Daily updates</h4>
                  <p>You’ll receive daily updates for your Executive summary</p>
                </div>
              </Radio>
              <Radio value='WEEKLY'>
                <div className='label-radio'>
                  <h4>Weekly updates</h4>
                  <p>You’ll receive updates for your Executive summary every week</p>
                </div>
              </Radio>
            </Space>
          </Radio.Group>
          {subscriptionType === 'WEEKLY' && (
            <div className='weekly-selection'>
              <h4>Select when you want to receive your updates</h4>
              <Select
                placement='bottomRight'
                size='large'
                onChange={(value) => setWeeklySubscriptionDay(value)}
                value={weeklySubscriptionDay}
                getPopupContainer={(trigger) => trigger.parentNode}
                className='weekDaySelector'>
                {weeklySubscriptionDays.map((day) => (
                  <Select.Option value={day.value}>{day.label}</Select.Option>
                ))}
              </Select>
            </div>
          )}
        </div>
      </Spin>
    </CustomModal>
  )
}
export default observer(ExecutiveSummarySubscriptionModal)
