import axios from 'axios'

import { ROUTES } from 'settings/settings'

export const EditWatchlist = async (watchlist: any, republishID?: string) => {
  let url = process.env.REACT_APP_API + ROUTES.watchlist

  if (!!republishID) url = `${process.env.REACT_APP_API}${ROUTES.watchlist}/${republishID}`

  const { data } = await axios.put(url, watchlist)

  return data
}
