import { Card } from 'components/common/Card/Card'

export function TermsPage(): JSX.Element {
  return (
    <div className='c-page'>
      <Card variant='1'>
        <h1>Terms of service</h1>
      </Card>
    </div>
  )
}
