import axios from 'axios'
import dayjs from 'dayjs'
import { UtilService } from 'services/Util/Util'

import { ROUTES } from 'settings/settings'

interface Props {
  search_expression: string
  enable_retweet: boolean
  sort: string
  verified_only: boolean
  profile_country: string
}

export const getTwitterPosts = async (searchData: Props) => {
  const searchParams = UtilService.generateURLSearchParams({ sort: searchData.sort })
  let url = process.env.REACT_APP_SEARCH_API + ROUTES.twitterSearch

  const fullSearchData = {
    ...searchData,
    to: dayjs().format('YYYY-MM-DD'),
    from: dayjs().subtract(90, 'days').format('YYYY-MM-DD'),
    max_results: '250',
  }

  const { data } = await axios.post(url + `?${searchParams}`, fullSearchData)

  return data
}
