import { Tooltip } from 'antd'
import { ExpandableType, IColumnType } from './Table'
import styles from './Table.module.scss'

type Props<T> = {
  columns: IColumnType<T>[]
  expandable?: ExpandableType<T>
}

function TableHeader<T>(props: Props<T>): JSX.Element {
  const { columns, expandable } = props
  return (
    <tr className={styles.table_header_cell}>
      {expandable && Object.keys(expandable).length && 'expandedRowRender' in expandable ? (
        <th className={styles.table_header_cell_chunk} style={{ width: 50 }}></th>
      ) : null}
      {columns.map((column, columnIndex) => (
        <th
          className={styles.table_header_cell_chunk}
          key={`table-head-cell-${columnIndex}`}
          style={{ width: column.width, textAlign: column.align || 'left' }}>
          <Tooltip title={column?.elementTitle || ''} placement='bottom' aria-label={column?.elementTitle || ''}>
            <span className={styles.table_header_cell_heading}>
              {typeof column.title === 'string' ? column.title : column.title()}
            </span>
          </Tooltip>
        </th>
      ))}
    </tr>
  )
}

export default TableHeader
