import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { PostBookmarkAPIPayload } from 'models/models'

interface UpdateBookmarkAPIPayload {
  bookmarkId: string
  bookmarkData: Partial<PostBookmarkAPIPayload>
}

export const updateBookmark = async ({ bookmarkId, bookmarkData }: UpdateBookmarkAPIPayload) => {
  let url = `${process.env.REACT_APP_API}/${ROUTES.bookmark}/${bookmarkId}`

  const { data } = await axios.put(url, bookmarkData)

  return data
}
