import axios from 'axios'
import { SnippetFilterPayload } from 'models/models'
import { ROUTES } from 'settings/settings'

export const getPromptSimulation = async ({ payload, prompt }: { payload: SnippetFilterPayload; prompt?: string }) => {
  const queryParams = `q=${payload.query}`
  const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.powerInsightsSummaryV2}?${queryParams}`

  const body: any = {
    conditions: payload.conditions,
  }
  if (prompt) body.custom_summary_prompt = prompt

  return axios.post(endpoint, body)
}
