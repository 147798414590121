import { StyleSheet } from '@react-pdf/renderer'

const colors = {
  color_prime: '#cca55a',
  color_dark_text: '#14192E',
  color_grey_1: '#FAFAFA',
  color_grey_2: '#F3F3F3',
  color_dark_grey: '#667085',
  header_bg: 'rgba(219, 175, 19, 0.06)',
  color_black: '#16191d',
  color_white: '#fff',
  color_border_grey: '#e2ebfa',
  color_grey_14: '#f6f6f6',
}

const {
  color_prime,
  color_dark_text,
  color_grey_1,
  color_grey_2,
  color_dark_grey,
  header_bg,
  color_black,
  color_white,
  color_border_grey,
  color_grey_14,
} = colors

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  page: {
    backgroundColor: color_grey_1,
  },

  header: { alignItems: 'center', paddingVertical: 24, gap: 8, backgroundColor: header_bg },
  logo_container: { flexDirection: 'row', gap: 8, justifyContent: 'center' },
  logo_icon_container: { height: 28, width: 28, alignItems: 'center', justifyContent: 'center' },
  logo_text_container: { width: 124, height: 25, alignItems: 'center', justifyContent: 'center' },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  header_text: {
    color: color_dark_text,
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'medium',
  },
  report_header_container: {
    paddingHorizontal: 32,
    paddingVertical: 24,
    backgroundColor: color_grey_2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rhc_left_container: { flexWrap: 'wrap', width: 380 },
  rhc_lc_heading: {
    color: color_dark_text,
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'semibold',
  },
  rhc_lc_date_heading: {
    color: color_dark_grey,
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'semibold',
    marginTop: 16,
    marginBottom: 4,
  },
  rhc_lc_date_text: { color: color_dark_text, fontFamily: 'Roboto', fontSize: 12, fontWeight: 'semibold' },
  rhc_right_container: {},
  rhc_rc_image_container: { width: 88, height: 88, alignItems: 'center', justifyContent: 'center' },
  rhc_rc_ic_image: { width: '100%', height: '100%', objectFit: 'contain' },
  intro_container: { paddingHorizontal: 32, paddingVertical: 24 },
  ic_text_container: { flexDirection: 'row', alignItems: 'center', gap: 4 },
  ic_hi_text: {
    color: color_dark_text,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
  },
  ic_tc_image_container: { height: 22, width: 15 },
  ic_tc_ic_image: { width: '100%', height: '100%', objectFit: 'contain' },
  ic_intro_desc: {
    color: color_dark_grey,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    marginTop: 8,
    marginBottom: 24,
    lineHeight: 1.4,
  },
  ic_link_button_container: {
    // backgroundColor: 'pink',
    textDecoration: 'none',
    alignItems: 'flex-start',
  },
  ic_lbc_button: {
    // height: 40,
    paddingHorizontal: 20,
    paddingVertical: 6,
    backgroundColor: color_black,
    borderRadius: 4,
    textDecoration: 'none',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ic_lbc_b_image_container: { height: 30, width: 30, alignItems: 'center', justifyContent: 'center' },
  ic_lbc_b_ic_image: { width: '100%', height: '100%', objectFit: 'contain' },
  ic_lbc_b_text: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'semibold',
    color: color_white,
  },
  report_sections_container: { gap: 24, paddingHorizontal: 32, paddingBottom: 24 },
  rsc_section_container: {},
  rsc_sc_heading: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'semibold',
    color: color_dark_text,
    marginBottom: 8,
  },
  rsc_sc_summary: {
    color: color_dark_grey,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 1.4,
  },
  rsc_sc_empty_data_container: {
    marginTop: 8,
    paddingVertical: 40,
    alignItems: 'center',
    backgroundColor: color_grey_14,
  },
  rsc_sc_edc_image_container: {
    width: 100,
    height: 79,
  },
  rsc_sc_edc_image: { width: '100%', height: '100%', objectFit: 'contain' },
  rsc_sc_edc_heading: {
    color: color_dark_grey,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'medium',
    marginTop: 24,
    marginBottom: 4,
  },
  rsc_sc_edc_desc: { color: color_dark_grey, fontFamily: 'Roboto', fontSize: 12, fontWeight: 'normal' },
  rsc_collection_text: {
    color: color_dark_grey,
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'semibold',
    marginTop: 16,
    marginBottom: 8,
  },
  rsc_collection_list: { gap: 8 },
  rsc_cl_text: { color: color_dark_grey, fontFamily: 'Roboto', fontSize: 12, fontWeight: 'normal' },
  rsc_cl_text_chunk: { textDecoration: 'underline' },
  rsc_cl_text_chunk_no_underline: { textDecoration: 'none' },
  report_details_container: { paddingHorizontal: 32 },
  rdc_inner_container: { borderTop: `1px solid ${color_border_grey}`, paddingVertical: 24, gap: 10 },
  rdc_ic_name: { color: color_dark_grey, fontFamily: 'Roboto', fontSize: 12, fontWeight: 'medium' },
  ic_link_button_container_2: {
    alignItems: 'center',
    marginBottom: 24,
  },
  footer: {
    backgroundColor: color_prime,
    paddingHorizontal: 32,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  f_left_container: { gap: 4 },
  f_lc_copy_right_container: { flexDirection: 'row', alignItems: 'center', gap: 2 },
  f_lc_crc_image_container: {
    height: 10,
    width: 10,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'gold',
  },
  f_lc_crc_image: { width: '100%', height: '100%', objectFit: 'cover' },
  f_lc_crc_text: { color: color_white, fontFamily: 'Roboto', fontSize: 12, fontWeight: 'medium' },
  f_lc_crc_text_underline: { textDecoration: 'underline' },
  f_lc_crc_text_no_underline: { textDecoration: 'none' },
  f_right_container: { flexDirection: 'row', gap: 16 },
  f_rc_image_container: { height: 16, width: 16, alignItems: 'center', justifyContent: 'center' },
  f_rc_image: { width: '100%', height: '100%', objectFit: 'container' },
})
