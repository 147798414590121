import { FormInstance } from 'antd'
import { API, attentionFlagsAPI, tenantsAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import {
  IAttentionFlagInfo,
  IAttentionFlagInfoMenu,
  IAttentionFlagPostInfo,
  IAttentionFlagsMenu,
  ICommunityDropdown,
  INarrativeDropdown,
  ISnippet,
  ITenant,
  SearchConditions,
  SnippetFilterPayload,
} from 'models/models'
import { UtilService } from 'services/Util/Util'

export class AttentionFlagsStore {
  tenant: ITenant[] = []
  tenantId: string = ''
  userTenantId: string = ''
  narrativeData: INarrativeDropdown[] = []
  communityData: ICommunityDropdown[] = []
  formData: IAttentionFlagsMenu = {
    id: '',
    name: '',
    description: '',
    displayLabel: '',
    insightType: 'NARRATIVES',
    insightCondition: '',
    tenantIds: '',
  }
  flags: IAttentionFlagsMenu[] = []
  flagsInfo: IAttentionFlagInfo = {}
  flagPostCount: IAttentionFlagPostInfo = { active: {}, inactive: {} }
  showResults: boolean = false
  snippets: ISnippet[] = []
  pagination = {
    current: 1,
    pageSize: 50,
    showSizeChanger: false,
  }
  idNumberList: { [x: string]: number } = {}

  constructor() {
    makeObservable(this, {
      tenant: observable,
      tenantId: observable,
      userTenantId: observable,
      narrativeData: observable,
      communityData: observable,
      formData: observable,
      flags: observable,
      flagsInfo: observable,
      flagPostCount: observable,
      showResults: observable,
      snippets: observable,
      pagination: observable,
      idNumberList: observable,
      setTenant: action.bound,
      setTenantId: action.bound,
      setUserTenantId: action.bound,
      setNarratives: action.bound,
      setCommunities: action.bound,
      setFormData: action.bound,
      setFlags: action.bound,
      setFlagsInfo: action.bound,
      setFlagPostCount: action.bound,
      setShowResults: action.bound,
      setSnippets: action.bound,
      setPagination: action.bound,
      setIdNumberList: action.bound,
      addFormData: action.bound,
      resetSnippetData: action.bound,
    })
  }

  get tableTabData() {
    return UtilService.getTableData({ source: this.flags, table: 'flags' })
  }

  get userFlags() {
    return this.flags.filter((flag) => flag.tenantIds.length === 0 || flag.tenantIds.includes(this.userTenantId))
  }

  get userFlagsAsObject() {
    const flags = this.flags.filter((flag) => flag.tenantIds.length === 0 || flag.tenantIds.includes(this.userTenantId))

    const obj: { [x: string]: IAttentionFlagsMenu } = {}
    flags.forEach((flag) => {
      obj[flag.name] = flag
    })
    return obj
  }

  setTenant = (tenant: ITenant[]) => {
    this.tenant = tenant
  }

  setTenantId = (id: string) => {
    this.tenantId = id
  }

  setUserTenantId = (tenant: string) => {
    this.userTenantId = tenant
  }

  setNarratives = (data: INarrativeDropdown[]) => {
    this.narrativeData = data
  }

  setCommunities = (data: ICommunityDropdown[]) => {
    this.communityData = data
  }

  setFormData = (formData: any) => {
    this.formData = formData
  }

  setShowResults = (state: boolean) => {
    this.showResults = state
  }

  setFlags = (data: IAttentionFlagsMenu[]) => {
    this.flags = data
  }

  setSnippets = (snippets: ISnippet[]) => {
    this.snippets = snippets
  }

  setPagination = ({ current }: { current: number }) => {
    this.pagination = { ...this.pagination, current }
  }

  addFormData = (newFormData: any) => {
    this.formData = { ...this.formData, ...newFormData }
  }

  setFlagsInfo = (data: IAttentionFlagInfoMenu[]) => {
    const obj: any = {}
    data.forEach((element: any) => (obj[element.id] = element))
    this.flagsInfo = obj
  }

  setFlagPostCount = (data: IAttentionFlagPostInfo) => {
    this.flagPostCount = data
  }

  setIdNumberList = (obj: { [x: string]: number }) => {
    this.idNumberList = { ...this.idNumberList, ...obj }
  }

  editMode = (data: IAttentionFlagsMenu | null, form: FormInstance<any>) => {
    if (data) {
      let ids: any = data?.insightCondition?.toString()
      this.setFormData(data)
      form.setFieldsValue(data)
      if (data.insightType !== 'SEARCH_TERM') {
        ids = ids.replace('{', '')
        ids = ids.replace('}', '')
        ids = ids.split(',')
        form.setFieldValue('insightCondition', ids)
      }
    }
  }
  saveFlag = async (isEditMode: boolean, id: string) => {
    try {
      const {
        name,
        description,
        displayLabel: display_label,
        insightType: insight_type,
        insightCondition: insight_condition,
        tenantIds: tenant_ids,
        companiesSwitch,
      } = this.formData
      const payload: any = {
        name,
        description,
        display_label,
        insight_type,
        insight_condition,
      }
      if (!companiesSwitch) payload['tenant_ids'] = tenant_ids

      if (isEditMode) {
        const response = await attentionFlagsAPI.editFlag(id, payload)
        return response
      } else {
        const response = await attentionFlagsAPI.createFlag(payload)
        return response
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })

      return 'error'
    }
  }

  deleteFlag = async (id: string) => {
    try {
      await attentionFlagsAPI.deleteFlag(id)
      this.fetchFlags()
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }
  fetchTenant = async () => {
    const data = await tenantsAPI.getTenant()
    if (data.items) this.setTenant(data.items)
  }

  fetchNarrativesAndCommunities = async () => {
    try {
      const [narrativesData, communitiesData] = await Promise.all([
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'narrative',
        }),
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'community',
        }),
      ])
      this.setCommunities(communitiesData.data.items)
      this.setNarratives(narrativesData.data.items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  fetchFlags = async (fetchFlagOnly: boolean = false) => {
    let flags = await attentionFlagsAPI.getFlags()
    let tenants: string[] = []

    flags = flags.map((item: any) => {
      if (item.tenant_ids.length !== 0) {
        tenants = tenants.concat(item.tenant_ids)
      }
      return {
        created: item.created,
        createdBy: item.created_by,
        description: item.descripion,
        displayLabel: item.display_label,
        id: item.id,
        insightCondition: item.insight_condition,
        insightType: item.insight_type,
        name: item.name,
        tenantIds: item.tenant_ids,
        userIds: item.user_ids,
      }
    })
    this.setFlags(flags)
    if (!fetchFlagOnly) {
      const uniqueIds = Array.from(new Set(tenants))
      this.fetchTenantDetails(uniqueIds)
    }
  }

  fetchTenantDetails = async (ids: string[]) => {
    const promises: any = []
    for (const id in ids) {
      promises.push(tenantsAPI.getTenantDetails(ids[id]))
    }
    Promise.all(promises).then((response) => {
      this.setFlagsInfo(response as any)
    })
  }

  fetchFlagPostCount = async (filters: SnippetFilterPayload) => {
    try {
      this.setFlagPostCount({ active: {}, inactive: {} })
      for (const flag of this.userFlags) {
        if (flag && Object.keys(flag).length > 0 && flag?.insightCondition?.length > 0) {
          let ids: any = flag?.insightCondition?.toString()
          const idNumbers = Object.keys(this.idNumberList)
          const idPromises = []
          let conditions: any = []

          if (flag.insightType !== 'SEARCH_TERM') {
            const modeData: any =
              flag.insightType === 'NARRATIVES'
                ? { mode: 'narrative', name: 'narratives', key: 'narrative_number' }
                : { mode: 'community', name: 'communities', key: 'community_number' }
            let savedNumbers = []

            ids = ids.replace('{', '')
            ids = ids.replace('}', '')
            ids = ids.split(',')

            for (const index in ids) {
              const id = ids[index]

              if (!idNumbers.includes(id)) {
                const obj: any = {}
                obj[id] = ''
                this.setIdNumberList(obj)
                idPromises.push(API.get({ route: modeData.mode, id }))
              } else savedNumbers.push(this.idNumberList[id])
            }

            let numbers = await Promise.all(idPromises).then((response) => {
              return response.map(({ data: item }) => {
                if (item && item[modeData.key]) {
                  const obj: any = {}
                  const id = item.id
                  obj[id] = item[modeData.key]
                  this.setIdNumberList(obj)
                  return item[modeData.key]
                } else return ''
              })
            })

            let obj: any = {}
            numbers = numbers.filter((item) => item && item !== '')
            savedNumbers = savedNumbers.filter((item) => item && item.toString() !== '')
            obj[modeData.name] = [...numbers, ...savedNumbers]
            if ([...numbers, ...savedNumbers].length > 0) conditions.push(obj)
          } else
            conditions.push({
              keywords_expression: ids,
            })
          const obj: { [x: string]: number } = {}
          if (conditions.length > 0) {
            this.fetchNoOfPosts(conditions, filters)
              .then((response) => {
                const active: { [x: string]: number } = { ...this.flagPostCount.active }
                const inactive: { [x: string]: number } = { ...this.flagPostCount.inactive }

                if (response?.total_value > 0) active[flag.name] = response.total_value
                else inactive[flag.name] = response?.total_value || 0

                this.setFlagPostCount({ active, inactive })
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            obj[flag.name] = 0
            this.setFlagPostCount({
              active: this.flagPostCount.active,
              inactive: { ...this.flagPostCount.inactive, ...obj },
            })
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  fetchNoOfPosts = async (conditions: SearchConditions[], filters: SnippetFilterPayload) => {
    try {
      const payload = { ...filters }
      payload.conditions = [...conditions, ...filters.conditions]
      return API.getMetricsUsingDimension(payload)
    } catch (error) {}
  }

  resetSnippetData = () => {
    this.setSnippets([])
    this.showResults = false
  }

  resetStore = () => {
    this.tenant = []
    this.tenantId = ''
    this.narrativeData = []
    this.communityData = []
    this.formData = {
      id: '',
      name: '',
      description: '',
      displayLabel: '',
      insightType: 'NARRATIVES',
      insightCondition: '',
      tenantIds: '',
    }
    this.flags = []
    this.showResults = false
    this.snippets = []
    this.pagination = {
      current: 1,
      pageSize: 50,
      showSizeChanger: false,
    }
  }
}
