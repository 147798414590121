import { makeAutoObservable } from 'mobx'
import { ToasterParamsType } from './types'

export class ToasterStore {
  toasterInfo: ToasterParamsType = {
    message: 'Success message',
    iconType: 'success',
  }
  showToast: boolean = false
  timer: NodeJS.Timeout | null = null
  constructor() {
    makeAutoObservable(this)
  }

  setToasterInfo = ({ message, iconType }: ToasterParamsType) => {
    this.toasterInfo = { message, iconType }
  }

  setShowToast = (value: boolean) => {
    this.showToast = value
  }

  hideToaster = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    this.setShowToast(false)
  }

  showToaster = ({ message, iconType, duration = 3000 }: ToasterParamsType) => {
    this.setToasterInfo({ message, iconType })
    this.setShowToast(true)
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      this.hideToaster()
    }, duration)
  }
}
