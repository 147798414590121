import { Modal } from 'antd'
import { ReactComponent as TickIcon } from 'assets/images/icons/toaster/success_icon.svg'

import './SuccessModal.scss'
import { useNavigate } from 'react-router-dom'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: (state: boolean) => void
}

export const SuccessModal = ({ isModalVisible, setIsModalVisible }: Props) => {
  const navigate = useNavigate()

  const Footer = (
    <div className='clip-report-success-modal__footer'>
      <button
        className='clip-report-success-modal__footer__button clip-report-success-modal__footer__close'
        onClick={() => setIsModalVisible(false)}>
        Close
      </button>
      <button
        className='clip-report-success-modal__footer__button clip-report-success-modal__footer__primary'
        onClick={() => navigate('/investigate/downloads')}>
        Go to Downloads
      </button>
    </div>
  )
  return (
    <Modal
      closable={false}
      centered
      width={387}
      open={isModalVisible}
      bodyStyle={{ overflow: 'auto' }}
      className='clip-report-success-modal'
      footer={Footer}>
      <div className='clip-report-success-modal__body'>
        <div className='clip-report-success-modal__icon'>
          <TickIcon />
        </div>
        <div className='clip-report-success-modal__info_container'>
          <span className='clip-report-success-modal__title'>Clip report has been successfully downloaded</span>
          <span className='clip-report-success-modal__description'>
            {'You can view and manage it in the My Stuff -> Downloads folder.'}
          </span>
        </div>
      </div>
    </Modal>
  )
}
