import { isEmpty } from 'lodash'

import { Unknown } from 'utils/commonTypes'
import { mergeArrayDynamic } from 'utils/helper'

export const formatConditions = (contentSelection1Conditions: Unknown, contentSelection2Conditions: Unknown) => {
  let conditions
  if (
    !isEmpty(mergeArrayDynamic(contentSelection1Conditions)) &&
    !isEmpty(mergeArrayDynamic(contentSelection2Conditions))
  )
    conditions = [mergeArrayDynamic(contentSelection1Conditions), mergeArrayDynamic(contentSelection2Conditions)]
  else if (
    !isEmpty(mergeArrayDynamic(contentSelection1Conditions)) &&
    isEmpty(mergeArrayDynamic(contentSelection2Conditions))
  )
    conditions = mergeArrayDynamic(contentSelection1Conditions)
  else conditions = mergeArrayDynamic(contentSelection2Conditions)

  return conditions
}
