import React from 'react'
import classNames from 'classnames'
import './Button.scss'

type ButtonProps = {
  children: React.ReactNode | string
  onClick: () => void
  className?: string
  [x: string]: any
}
function Button(props: ButtonProps) {
  const { children, onClick, className, ...rest } = props
  return (
    <button className={classNames('button_container', className)} onClick={onClick} {...rest}>
      {children}
    </button>
  )
}

export default Button
