import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { PostBookmarkAPIPayload } from 'models/models'

interface CreateBookmarkAPIPayload {
  bookmarkData: PostBookmarkAPIPayload
}

export const createBookmark = async ({ bookmarkData }: CreateBookmarkAPIPayload) => {
  let url = `${process.env.REACT_APP_API}/${ROUTES.bookmark}`

  const { data } = await axios.post(url, bookmarkData)

  return data
}
