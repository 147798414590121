import axios from 'axios'
import { monitorMode } from 'models/models'

export const genericShare = (
  id: string | number,
  userIds: string[],
  type: monitorMode | 'channel',
  action: 'share' | 'unshare',
  permission_level?: string,
) => {
  return axios.post(`/${type}/${id}/share`, { user_ids: userIds, action, permission_level })
}
