import SkeltonHeader from 'assets/images/skeleton.png'
import { ReactNode } from 'react'
import './CreatorsList.scss'

type SkeletonTableProps = {
  loading: boolean
  children: ReactNode
}

export default function SkeletonTable({ loading = false, children }: SkeletonTableProps): JSX.Element {
  return loading ? (
    <div className='table-skelton'>
      <div className='row-stats' />
      <img src={SkeltonHeader} alt='skelton-header-loading' className='header' />
      <div className='row' />
      <div className='row' />
      <div className='row' />
      <div className='row' />
      <div className='row' />
      <div className='pagination-wrapper'>
        <div className='pagination' />
      </div>
    </div>
  ) : (
    <>{children}</>
  )
}
