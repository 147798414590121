import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts'
import { useEffect, useMemo, useState } from 'react'
import millify from 'millify'

import { SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import { IBarChartData } from 'models/models'

import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-icon.svg'
import { SubStore } from 'types/types'

import './MonitorBarChart.scss'
import { BarChartHeader } from './BarChartHeader/BarChartHeader'
import { Spin, Tooltip } from 'antd'
import { DetailsObjType } from 'types/types'
import { useLocation } from 'react-router-dom'

interface Props {
  tab: 'company' | 'people' | 'language' | 'watchlist' | 'politician'
  subStore: SubStore
  dataLoading: boolean
  indexName: 'companyData' | 'peopleData' | 'languagesData' | 'watchlistData' | 'politicianData'
}

export const MonitorBarChart = observer(({ tab, subStore, indexName, dataLoading = false }: Props) => {
  const location = useLocation()
  const {
    powerInsightsData,
    snippetsFilter,
    filterChips,
    setFilterChips,
    setSnippetsFilter,
    activeItem,
    setPowerInsightsData,
    fetchPowerInsights,
    fetchSnippetsInfo,
    fetchWatchlistData,
    fetchPowerInsightsPeopleData,
    fetchPowerInsightsLanguagesData,
    fetchPowerInsightsCompaniesData,
    fetchPowerInsightsPoliticianData,
  } = store[`${subStore}Store`]

  const data = useMemo(() => {
    return powerInsightsData[indexName]?.data
  }, [powerInsightsData[indexName]])

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [clickedItem, setClickedItem] = useState('')

  const [graphData, setGraphData] = useState(data)
  const [searchCheck, setSearchCheck] = useState(false)
  const [tooltip, setTooltip] = useState({ visible: false, content: '' })

  const getBarChartData = async () => {
    if (tab === 'watchlist') await fetchWatchlistData()
    if (tab === 'people') await fetchPowerInsightsPeopleData()
    if (tab === 'company') await fetchPowerInsightsCompaniesData()
    if (tab === 'language') await fetchPowerInsightsLanguagesData()
    if (tab === 'politician') await fetchPowerInsightsPoliticianData()
  }

  useEffect(() => {
    getBarChartData()
  }, [location?.pathname])

  useEffect(() => {
    setGraphData(data)
  }, [powerInsightsData[indexName]])

  const CustomizedYAxisTick = (props: { x: number; y: number; index: number; payload: { value: string } }) => {
    const data = graphData?.find((item) => item?.entity_name === props.payload.value) || {
      metric_value: 0,
      entity_name: '',
    }

    return (
      <g
        transform={`translate(${props.x - 240},${props.y + 5})`}
        onMouseEnter={() => onBarChartMouseEnter(props, props.index)}
        onMouseOut={() => onBarChartMouseOut({}, -1)}
        onClick={() => onBarChartMouseClick({ payload: data }, props.index)}
        cursor='pointer'>
        <text
          fontSize='14px'
          fontWeight={isSelected(data, props.index) ? '500' : '400'}
          textAnchor='start'
          fill={data?.metric_value === 0 ? '#8598AD' : '#16191D'}>
          {tab !== 'language'
            ? props.payload.value.length > 0 &&
              props.payload.value[0].toUpperCase() +
                props.payload.value.substring(1, 25) +
                (props.payload.value.length > 25 ? '...' : '')
            : props.payload.value.length > 0 && SUPPORTED_LANGUAGES_CODE[props.payload.value]}
        </text>
        {tooltip.visible && props?.payload?.value.length > 25 && props.payload.value === tooltip.content && (
          <foreignObject x={0} y={5} width='600' height='50'>
            <Tooltip open title={props.payload.value} placement='bottomLeft' />
          </foreignObject>
        )}
        <text
          transform={`translate(${240},${0})`}
          fontSize='12px'
          fontWeight={isSelected(data, props.index) ? '500' : '400'}
          textAnchor='end'
          fill={data?.metric_value === 0 ? '#8598AD' : '#16191D'}>
          {millify(Number(data?.metric_value || '0'))}
        </text>
      </g>
    )
  }

  const onBarChartMouseEnter = (data: any, index: number) => {
    showTooltip(data?.payload?.value)
    setSelectedIndex(index)
  }

  const onBarChartMouseOut = (data: any, index: number) => {
    hideTooltip()
    if (clickedItem !== data?.payload?.entity_name) setSelectedIndex(-1)
  }

  const showTooltip = (value: string) => {
    if (value?.length > 25)
      setTooltip({
        visible: true,
        content: value,
      })
  }

  const hideTooltip = () => {
    setTooltip({ visible: false, content: '' })
  }

  useEffect(() => {
    if (clickedItem && !filterChips[tab]?.value) {
      setClickedItem('')
    }
  }, [clickedItem, filterChips])

  const onBarChartMouseClick = (data: any, index: number) => {
    hideTooltip()
    setSelectedIndex(-1)
    if (data?.payload?.entity_name === clickedItem) {
      setClickedItem('')
      if (tab === 'company') {
        let { company, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      } else if (tab === 'people') {
        let { people, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      } else if (tab === 'politician') {
        let { politician, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      } else if (tab === 'language') {
        let { filter_language, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      } else if (tab === 'watchlist') {
        let { watchlist, ...filter } = snippetsFilter
        setSnippetsFilter(filter)
      }
      const tabData = filterChips[tab]
      setFilterChips({
        ...filterChips,
        [tab]: { ...tabData, value: '' },
      })
    } else {
      setClickedItem(data?.payload?.entity_name)
      if (tab === 'watchlist') setSnippetsFilter({ ...snippetsFilter, watchlist: data?.payload?.urls })
      else {
        tab !== 'language'
          ? setSnippetsFilter({ ...snippetsFilter, [tab]: [data?.payload?.entity_name] })
          : setSnippetsFilter({ ...snippetsFilter, filter_language: data?.payload?.entity_name })
      }
      const tabData = filterChips[tab]
      setFilterChips({
        ...filterChips,
        [tab]: { ...tabData, value: data?.payload?.entity_name },
      })
    }

    fetchPowerInsights({ ...activeItem, activeTab: tab } as DetailsObjType)
    fetchSnippetsInfo(activeItem!)
  }

  const checkList = () => {
    if (tab !== 'language' && indexName !== 'languagesData')
      return (
        powerInsightsData[indexName] &&
        (powerInsightsData[indexName]?.data?.length || 0) < (powerInsightsData[indexName]?.completeData?.length || 0)
      )
    return false
  }

  const loadMore = () => {
    if (tab !== 'language' && indexName !== 'languagesData') {
      if (powerInsightsData && powerInsightsData[indexName] && powerInsightsData[indexName]?.data) {
        const remaining =
          (powerInsightsData[indexName]?.completeData?.length || 0) - (powerInsightsData[indexName]?.data?.length || 0)
        const count =
          remaining > 8
            ? (powerInsightsData[indexName]?.data?.length || 0) + 8
            : (powerInsightsData[indexName]?.data?.length || 0) + remaining
        const data = powerInsightsData[indexName]?.completeData?.slice(0, count)
        setPowerInsightsData({ ...powerInsightsData, [indexName]: { ...powerInsightsData[indexName], data } })
        setGraphData(data)
      }
    }
  }

  const isSelected = (entry: IBarChartData, index: number) => {
    const snippetTabData = tab !== 'language' ? snippetsFilter[tab] || [''] : snippetsFilter['filter_language'] || ['']
    return (
      selectedIndex === index ||
      (tab !== 'language' ? snippetTabData[0] === entry?.entity_name : snippetTabData === entry?.entity_name)
    )
  }

  return (
    <div>
      <Spin spinning={dataLoading}>
        <BarChartHeader subStore={subStore} tab={tab} searchCheck={setSearchCheck} indexName={indexName} />

        <div className='power-insights-bar-chart__chart-container'>
          <BarChart
            width={600}
            height={33.33 * (graphData?.length || 0)}
            data={graphData}
            layout={'vertical'}
            margin={{ right: 40 }}
            barGap={10}>
            <XAxis type='number' dataKey='metric_value' hide />
            <YAxis
              type='category'
              dataKey='entity_name'
              axisLine={false}
              width={250}
              tickLine={false}
              tick={CustomizedYAxisTick}
            />

            <Bar
              dataKey='metric_value'
              barSize={13}
              onMouseEnter={onBarChartMouseEnter}
              onMouseOut={onBarChartMouseOut}
              onClick={onBarChartMouseClick}>
              {graphData?.map((entry, index) => {
                return (
                  <Cell
                    cursor='pointer'
                    fill={isSelected(entry, index) ? '#CCA55A' : entry?.metric_value === 0 ? '#8598AD' : '#9BADFA'}
                    key={`cell-${index}`}
                  />
                )
              })}
            </Bar>
          </BarChart>
          {!searchCheck && checkList() && (
            <div className='power-insights-bar-chart__see-more' onClick={loadMore}>
              <span className='power-insights-bar-chart__see-more__label'>See more</span>
              <DropdownIcon />
            </div>
          )}
        </div>
      </Spin>
    </div>
  )
})
