import { Spin } from 'antd'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'

import { CustomPieChart } from 'components/common/PieChart/PieChart'

import { SubStore } from 'types/types'
import { AssetListObjType } from 'store/asset/types'
import { SnippetFilterPayload } from 'models/models'

import './Assets.scss'

type DataRecord = { name: string; total_value: number }
type ChartMetricInfo = { data: DataRecord[]; totalCount: number; title: string }
type ChartDataByMetric = { [x: string]: ChartMetricInfo }

interface Props {
  subStore: SubStore
}

export const BrandAssets = observer(({ subStore }: Props) => {
  const metrics = ['impression', 'mentions', 'engagement']

  const [chartData, setChartData] = useState<ChartDataByMetric>({
    impression: {
      title: 'Share of Voice Views',
      data: [],
      totalCount: 0,
    },
    mentions: {
      title: 'Share of Voice Mentions',
      data: [],
      totalCount: 0,
    },
    engagement: {
      title: 'Share of Voice Engagement',
      data: [],
      totalCount: 0,
    },
  })
  const [loading, setLoading] = useState<boolean>(false)
  const { assetsStore, tenantsStore } = store

  const { assetsLoading, getDate, assetsList, graphColorPalette, fetchInsightsMetrics } = assetsStore
  const {
    activeItem,
    snippetsFilter,
    filterChips,
    setSnippetsFilter,
    setFilterChips,
    fetchPowerInsights,
    fetchSnippetsInfo,
    getSnippetFilterPayload,
  } = store[`${subStore}Store`]
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore

  useEffect(() => {
    if (!assetsLoading && assetsList.total_count > 0) {
      createDataForChart()
    }
  }, [assetsLoading, assetsList, snippetsFilter])

  const createDataForChart = useCallback(async () => {
    try {
      setLoading(true)
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const assetsToFetch = assetsList.items.filter(
        (asset: AssetListObjType) => Object.keys(asset.conditions).length > 0,
      )
      for (const metric of metrics) {
        fetchDataForMetric(metric, assetsToFetch, payload)
      }
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setLoading(false)
    }
  }, [assetsList.items, getDate])

  const fetchDataForMetric = async (metric: string, assets: AssetListObjType[], payload: SnippetFilterPayload) => {
    try {
      const requestParams: any = {
        q: `start_date:gte:${getDate.startDate},end_date:lte:${getDate.endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
      }
      if (metric !== 'mentions') requestParams.measure = metric
      const responses = await Promise.all(
        assets.map((asset) =>
          fetchInsightsMetrics({ params: requestParams, data: { conditions: [asset.conditions] } }),
        ),
      )

      const totalCount = responses.reduce((sum, response) => sum + (response === 'error' ? 0 : response), 0)
      const updatedResponse = assets.map((asset, index) => {
        return {
          name: asset.name,
          conditions: asset.conditions,
          total_value: responses[index] === 'error' ? 0 : responses[index],
        }
      })
      setChartData((prev) => {
        return { ...prev, [metric]: { ...prev[metric], data: updatedResponse, totalCount } }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const onPieChartClick = (data?: any) => {
    if (snippetsFilter.assets?.name === data.name) {
      let { assets, ...filter } = snippetsFilter
      setSnippetsFilter(filter)
      setFilterChips({
        ...filterChips,
        assets: {
          ...filterChips.assets,
          value: '',
        },
      })
    } else {
      setSnippetsFilter({ ...snippetsFilter, assets: { name: data?.name, conditions: data?.conditions } })
      setFilterChips({
        ...filterChips,
        assets: {
          ...filterChips.assets,
          value: data.name,
        },
      })
    }

    fetchPowerInsights(activeItem!)
    fetchSnippetsInfo(activeItem!)
  }

  return (
    <Spin spinning={loading}>
      <div className='brands-assets__container'>
        <div className='brands-assets__header-div'>
          <span className={'brands-assets__header-div__title'}>
            {changeLabelTextBasedOnTheme('Assets', isCorporateCommunicationsTheme)}
          </span>
          <span className='brands-assets__header-div__description'>
            Represent the context around your Brand like product/brand portfolio, competitors, suppliers and executives
          </span>
        </div>
        <div className='brands-assets__body'>
          {Object.keys(chartData)?.map((key, index) => {
            const metricData = chartData[key]
            return (
              <div className='brands-assets__graph-container'>
                <span className='brands-assets__graph__title'>{metricData.title}</span>
                {
                  <CustomPieChart
                    height={192}
                    width={350}
                    cx={65}
                    data={metricData.data}
                    colorPalette={Object.values(graphColorPalette)[index]}
                    totalCount={metricData.totalCount}
                    narrativeTotal={metricData.totalCount}
                    onClickCell={onPieChartClick}
                    millifyStats
                    showLabelsBasedOnSelection
                    customLegendWrapperClass='custom-pie-chart__scrollable'
                    snippetsFilter={snippetsFilter}
                  />
                }
              </div>
            )
          })}
        </div>
      </div>
    </Spin>
  )
})
