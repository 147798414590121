import axios from 'axios'

import { ROUTES } from 'settings/settings'

export const searchTenant = async (name: string) => {
  const query = `?per_page=16&sort=name:asc&q=name:like:${name}`
  let url = `${process.env.REACT_APP_API}${ROUTES.tenant}${query}`
  const { data } = await axios.get(url)
  return data
}
