import classNames from 'classnames'
import React, { ButtonHTMLAttributes, memo } from 'react'

import styles from './ButtonImage.module.scss'

type ButtonImageProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  children?: React.ReactNode | string
  className?: string
  iconClassName?: string
  icon?: React.ElementType
  imageUrl?: string
  loading?: boolean
}

function ButtonImage(props: ButtonImageProps) {
  const { onClick, children, className, iconClassName, disabled, icon: Icon, imageUrl, loading, ...buttonProps } = props

  return (
    <button
      className={classNames(styles.button, className)}
      onClick={onClick}
      disabled={disabled || loading}
      {...buttonProps}>
      {Icon && <Icon className={classNames(styles.icon, iconClassName, { [styles.icon_loading]: loading })} />}
      {imageUrl && <img src={imageUrl} alt={`pendulum filter images`} />}
      {children}
    </button>
  )
}

export default memo(ButtonImage)
