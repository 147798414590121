import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-icon.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-icon.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-new-logo.svg'
import { ReactComponent as YouTubeIcon } from 'assets/images/youtube-new-icon.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-icon.svg'
import { ReactComponent as Chan4Icon } from 'assets/images/4chan-icon.svg'
import { ReactComponent as GabIcon } from 'assets/images/gab-icon.svg'
import { ReactComponent as TruthSocialIcon } from 'assets/images/truth-social-icon-dark.svg'
import { ReactComponent as PatriotsIcon } from 'assets/images/patriots-icon.svg'
import { ReactComponent as VkIcon } from 'assets/images/vk-icon-current-color.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-logo.svg'
import { ReactComponent as ThreadsIcon } from 'assets/images/threads-logo.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/telegram-logo.svg'
import { ReactComponent as BrighteonIcon } from 'assets/images/brighteon-logo-icon.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/snapchat-logo-icon.svg'
import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-icon.svg'
import { ReactComponent as Linkedin } from 'assets/images/linkedin.svg'
import { ReactComponent as BlueSky } from 'assets/images/bluesky.svg'

import { sourcePlatform } from 'models/models'

export const getPlatformBrandIcon = (platform: sourcePlatform | Lowercase<sourcePlatform>) => {
  let icon: React.ReactNode = null

  switch (platform) {
    case 'twitter':
    case 'Twitter':
      icon = <TwitterIcon />
      break

    case 'youtube':
    case 'YouTube':
      icon = <YouTubeIcon />
      break

    case 'tiktok':
    case 'TikTok':
      icon = <TikTokIcon />
      break

    case 'podcast':
    case 'Podcast':
      icon = <PodcastIcon />
      break

    case 'news':
    case 'News':
      icon = <NewsIcon />
      break

    case 'bitchute':
    case 'BitChute':
      icon = <BitChuteIcon />
      break

    case 'rumble':
    case 'Rumble':
      icon = <RumbleIcon />
      break

    case 'reddit':
    case 'Reddit':
      icon = <RedditIcon />
      break

    case '4chan':
      icon = <Chan4Icon />
      break

    case 'gab':
    case 'Gab':
      icon = <GabIcon />
      break

    case 'patriots.win':
    case 'Patriots.win':
      icon = <PatriotsIcon />
      break

    case 'truthsocial':
    case 'TruthSocial':
      icon = <TruthSocialIcon />
      break

    case 'vk':
      icon = <VkIcon />
      break

    case 'instagram':
    case 'Instagram':
      icon = <InstagramIcon />
      break

    case 'threads':
    case 'Threads':
      icon = <ThreadsIcon />
      break

    case 'telegram':
    case 'Telegram':
      icon = <TelegramIcon />
      break

    case 'brighteon':
    case 'Brighteon':
      icon = <BrighteonIcon />
      break

    case 'linkedin':
    case 'LinkedIn':
      icon = <Linkedin />
      break

    case 'bluesky':
    case 'Bluesky':
      icon = <BlueSky />
      break

    case 'Snapchat':
    case 'snapchat':
      icon = <SnapchatIcon />
      break

    case 'Odysee':
    case 'odysee':
      icon = <OdyseeIcon />
      break

    default:
      icon = null
      break
  }

  return icon
}
