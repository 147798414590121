import { useCallback, useEffect, useState } from 'react'
import { Button, Popover } from 'antd'
import classNames from 'classnames'

import { UtilService } from 'services/Util/Util'
import { WatchlistChannel, sourcePlatform } from 'models/models'

import { ReactComponent as ChevronUp } from 'assets/images/icons/outline/chevron-up.svg'
import { ReactComponent as ChevronDown } from 'assets/images/icons/outline/chevron-down.svg'

import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-new-icon-current-color.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-new-icon-current-color.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-icon-current-color.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-new-icon-current-color.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-icon-current-color.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-icon-current-color.svg'
import { ReactComponent as YouTubeIcon } from 'assets/images/youtube-new-icon-current-color.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-icon-current-color.svg'
import { ReactComponent as GabIcon } from 'assets/images/gab-icon-current-color.svg'
import { ReactComponent as Chan4Icon } from 'assets/images/4chan-icon-white.svg'
import { ReactComponent as TruthSocialIcon } from 'assets/images/truth-social-grey-icon.svg'
import { ReactComponent as PatriotsIcon } from 'assets/images/patriots-grey-icon.svg'
import { ReactComponent as VkIcon } from 'assets/images/vk-icon-current-color.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-icon-current-color.svg'
import { ReactComponent as ThreadsIcon } from 'assets/images/threads-icon-current-color.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/telegram-icon-current-color.svg'
import { ReactComponent as BrighteonIcon } from 'assets/images/brighteon-grey-icon.svg'
import { ReactComponent as Linkedin } from 'assets/images/linkedin.svg'
import { ReactComponent as BlueSky } from 'assets/images/bluesky.svg'
import { ReactComponent as BetaTag } from 'assets/images/beta-icon.svg'
// import { ReactComponent as SnapchatIcon } from 'assets/images/snapchat-grey-icon.svg'
// import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-grey-icon.svg'

import styles from './selectPlatformDropdown.module.scss'

const PLATFORMS: Array<{ title: Lowercase<sourcePlatform>; icon: JSX.Element }> = [
  { title: 'youtube', icon: <YouTubeIcon /> },
  { title: 'bitchute', icon: <BitChuteIcon /> },
  { title: 'rumble', icon: <RumbleIcon /> },
  { title: 'reddit', icon: <RedditIcon /> },
  { title: 'tiktok', icon: <TikTokIcon /> },
  { title: 'news', icon: <NewsIcon /> },
  { title: 'twitter', icon: <TwitterIcon /> },
  { title: 'truthsocial', icon: <TruthSocialIcon /> },
  { title: 'podcast', icon: <PodcastIcon /> },
  { title: '4chan', icon: <Chan4Icon /> },
  { title: 'gab', icon: <GabIcon /> },
  { title: 'patriots.win', icon: <PatriotsIcon /> },
  { title: 'vk', icon: <VkIcon className={styles.vkIcon} /> },
  { title: 'instagram', icon: <InstagramIcon /> },
  { title: 'threads', icon: <ThreadsIcon /> },
  { title: 'telegram', icon: <TelegramIcon className={styles.telegramIcon} /> },
  { title: 'brighteon', icon: <BrighteonIcon /> },
  { title: 'linkedin', icon: <Linkedin className={styles.linkedInIcon} /> },
  { title: 'bluesky', icon: <BlueSky className={styles.linkedInIcon} /> },
  // { title: 'snapchat', icon: <SnapchatIcon /> },
  // { title: 'odysee', icon: <OdyseeIcon /> },
]

const betaTag = {
  bluesky: <BetaTag className={styles.betaTag} />,
  linkedin: <BetaTag className={styles.betaTag} />,
}

function PlatformLabel({ platform }: { platform: string }) {
  const platformObj = PLATFORMS.find((p) => p.title === platform)
  if (!platformObj) {
    return null
  }

  return (
    <div className={styles.platformLabel}>
      {platformObj.icon}
      <span>{UtilService.getPlatformBrandName(platformObj.title)}</span>
      {betaTag[platformObj.title as keyof typeof betaTag] || null}
    </div>
  )
}

interface SelectPlatformDropdownProps {
  channel?: WatchlistChannel
  onPlatformSelect?: (platform: string) => void
  selectedPlatforms?: string[]
  isEditMode?: boolean
}

export const SelectPlatformDropdown: React.FC<SelectPlatformDropdownProps> = ({
  channel,
  onPlatformSelect,
  selectedPlatforms,
  isEditMode = true,
}) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)

  const handlePopoverVisibleChange = useCallback((visible: boolean) => {
    setPopoverVisible(visible)
  }, [])

  useEffect(() => {
    setPopoverVisible(false)
  }, [channel?.platform])

  return (
    <div className={styles.selectPlatformDropdown}>
      {isEditMode ? (
        <Popover
          trigger='click'
          content={
            <div className={styles.popoverContent}>
              {PLATFORMS.map((availablePlatform) => (
                <Button
                  key={availablePlatform.title}
                  className={classNames(styles.platformOptionBtn, {
                    [styles.isSelected]: availablePlatform.title === channel?.platform?.toLowerCase(),
                  })}
                  disabled={selectedPlatforms?.includes(availablePlatform.title.toLowerCase())}
                  onClick={() => onPlatformSelect && onPlatformSelect(availablePlatform.title)}>
                  {availablePlatform.icon}
                  <span>{UtilService.getPlatformBrandName(availablePlatform.title)}</span>
                  {betaTag[availablePlatform.title as keyof typeof betaTag] || null}
                </Button>
              ))}
            </div>
          }
          open={popoverVisible}
          onOpenChange={handlePopoverVisibleChange}
          placement='bottomLeft'
          overlayClassName={styles.platformOptionsPopover}>
          <Button
            style={{
              backgroundColor: channel?.platform
                ? UtilService.getPlatformBrandColor(
                    UtilService.getPlatformBrandName(channel?.platform?.toLowerCase() as Lowercase<sourcePlatform>),
                  )
                : '#fff',
            }}
            className={classNames(styles.dropdownTrigger, {
              [styles.platformSelected]: !!channel?.platform,
            })}>
            {channel?.platform ? <PlatformLabel platform={channel?.platform?.toLowerCase()} /> : 'Platform'}
            {popoverVisible ? <ChevronUp /> : <ChevronDown />}
          </Button>
        </Popover>
      ) : (
        <Button
          style={{
            backgroundColor: channel?.platform
              ? UtilService.getPlatformBrandColor(
                  UtilService.getPlatformBrandName(channel?.platform?.toLowerCase() as Lowercase<sourcePlatform>),
                )
              : '#fff',
          }}
          className={classNames(styles.dropdownTrigger, {
            [styles.platformSelected]: !!channel?.platform,
          })}>
          {channel?.platform ? <PlatformLabel platform={channel?.platform?.toLowerCase()} /> : 'Platform'}
          {popoverVisible ? <ChevronUp /> : <ChevronDown />}
        </Button>
      )}
    </div>
  )
}
