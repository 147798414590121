import { Col, Row, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { store } from 'store'

import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useMeasure } from 'react-use'
import { BeehivePlatformLegends } from 'components/Monitor/PowerInsights/MonitorBeehiveChart/BeehivePlatformLegends/BeehivePlatformLegends'
import { BeeChart, Tip, useTip } from '@pendulumfn/pendulum-viz'
import { BeehiveChartXAxis } from 'components/Monitor/PowerInsights/MonitorBeehiveChart/BeehiveChartXAxis/BeehiveChartXAxis'
import { BeehiveChartPopup } from 'components/Monitor/PowerInsights/MonitorBeehiveChart/BeehiveChartPopup/BeehiveChartPopup'
import { IVideoBubble, sourcePlatform } from 'models/models'
import { Unknown } from 'utils/commonTypes'
import { convertMobXProxiesToJSObject } from 'utils/helper'
import { MonitorGraph } from 'components/Monitor/PowerInsights/MonitorGraph/MonitorGraph'
import { MonitorHeatMap } from 'components/Monitor/PowerInsights/MonitorHeatMap/MonitorHeatMap'
import MentionViewCard from 'components/Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'
import BrandsList from './components/BrandsList/BrandsList'
import ActiveFlags from './components/ActiveFlags/ActiveFlags'
import { AssetListObjType } from 'store/asset/types'
import InfluencersList from './components/InfluencersList/InfluencersList'
import { uniqBy } from 'lodash'
import BrandSentiment from './components/BrandSentiment/BrandSentiment'
import Button from 'components/common/Buttons/Button/Button'
import { ReactComponent as DetailsIcon } from 'assets/images/details-icon.svg'
import { useNavigate } from 'react-router-dom'
import PortfolioShareChart from './components/PortfolioShareChart/PortfolioShareChart'
import CompetitorsShareChart from './components/CompetitorsShareChart/CompetitorsShareChart'
import Filters from './components/Filters/Filters'
import PlatformDistributionCard from './components/PlatformDistributionCard/PlatformDistributionCard'
import { DownloadOption } from './components/DownloadOption'
import './Dashboard.scss'

const Dashboard = () => {
  const tip = useTip<IVideoBubble>()
  const [measureRef, { width: beeChartWidth }] = useMeasure<HTMLDivElement>()
  const navigate = useNavigate()

  const { assetsStore, loaderStore } = store
  const {
    fetchPowerInsightsForBrandDashboard,
    beeSwarmVideoNodes,
    assetsLoading,
    assetsList,
    snippetsFilter,
    resetPowerInsightsData,
  } = assetsStore
  const { statsLineChartLoading, statsTreeDataLoading, statsBeeSwarmLoading } = loaderStore

  const [selectedBrand, setSelectedBrand] = useState<AssetListObjType>()

  useEffect(() => {
    if (!assetsLoading && assetsList.items.length > 0 && !selectedBrand) {
      const brand = assetsList.items.filter((brand) => brand.is_active)[0]
      if (brand) setSelectedBrand(brand)
    }
    if (!assetsLoading && assetsList.items.length === 0) resetPowerInsightsData()
  }, [assetsList])

  useEffect(() => {
    if (selectedBrand) {
      fetchPowerInsightsForBrandDashboard({
        name: selectedBrand.name,
        conditions: selectedBrand.conditions,
        mode: 'asset',
        id: selectedBrand.id,
      })
    }
  }, [selectedBrand, snippetsFilter])

  const platforms = useMemo(() => {
    return uniqBy(beeSwarmVideoNodes, (node) => node.data.platform).map((node) => node.data.platform)
  }, [beeSwarmVideoNodes])

  const xAxisData = useMemo(() => {
    return beeSwarmVideoNodes.map((node) => ({ date: dayjs(node.date).format('MMM DD, YYYY') }))
  }, [beeSwarmVideoNodes])

  const handleSelectAndOpen = (data: Record<string, Unknown>) => {
    const convertedData = convertMobXProxiesToJSObject(data)?.data
    const postUrl = convertedData?.post_url
    if (postUrl) {
      window.open(postUrl, '_blank')
    }
  }

  const onViewDetails = () => {
    if (!selectedBrand) return
    const state = {
      name: selectedBrand.name,
      conditions: selectedBrand.conditions,
      mode: 'asset',
      id: selectedBrand?.id,
      tabName: { name: 'Brands', category_id: selectedBrand?.category_id },
      pageNumber: 1,
      pageSize: 10,
      alert_id: selectedBrand.alert_id,
    }

    navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
  }

  if (assetsLoading) {
    return <div></div>
  }

  return (
    <div className='dashboard'>
      <Filters />
      <Row gutter={24} justify={'start'} align={'top'} className='dashboard-widgets'>
        <Col span={18}>
          <Row gutter={[0, 24]}>
            <Col span={24} className='dashboard-widgets__card'>
              <BrandsList selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} />
            </Col>
            <Col span={24}>
              <Row className='dashboard-widgets__card__sub-row'>
                <Col span={12} className='dashboard-widgets__card mention-view-widgets'>
                  <MentionViewCard selectedItem={selectedBrand} type='Mentions' key='Mentions' />
                </Col>
                <Col span={12} className='dashboard-widgets__card mention-view-widgets'>
                  <MentionViewCard selectedItem={selectedBrand} type='Views' key='Views' measure='impression' />
                </Col>
              </Row>
            </Col>
            <Col span={24} className='dashboard-widgets__card'>
              <div id='trendChart'>
                <div className='chart-heading'>
                  <span className='monitor-assets__graph__title'>Current Trend</span>
                  <DownloadOption elementToCapture='#trendChart' />
                </div>
                <MonitorGraph subStore='assets' dataLoading={statsLineChartLoading} fromBrandDashboard />
              </div>
            </Col>
            <Col span={24} className='dashboard-widgets__card'>
              <PlatformDistributionCard />
            </Col>
            <Col span={24} className='dashboard-widgets__card'>
              <ActiveFlags selectedBrand={selectedBrand} />
            </Col>
            <Col span={24} className='dashboard-widgets__card min-height-350' id='audienceChart'>
              <Spin spinning={statsTreeDataLoading}>
                <div className='chart-heading'>
                  <span className='monitor-assets__graph__title '>Audiences</span>
                  <DownloadOption elementToCapture='#audienceChart' />
                </div>
                <MonitorHeatMap subStore='assets' selectMode={true} name='Audiences' fromBrandDashboard />
              </Spin>
            </Col>
            <Col span={24} className='dashboard-widgets__card' ref={measureRef}>
              <div id='beehiveChart'>
                <Spin spinning={statsBeeSwarmLoading}>
                  <div className='chart-heading'>
                    <span className='monitor-assets__graph__title'>Influencer Hive</span>
                    <DownloadOption elementToCapture='#beehiveChart' />
                  </div>
                  {!statsBeeSwarmLoading && beeSwarmVideoNodes.length === 0 && <p>No data to display</p>}
                  <div className='c-bubble-chart__platforms'>
                    <BeehivePlatformLegends subStore='assets' platforms={platforms as sourcePlatform[]} />
                  </div>
                  <div className='c-bubble-chart__bubbles'>
                    <BeeChart
                      className='c-bubble-chart__bee'
                      nodes={beeSwarmVideoNodes}
                      width={beeChartWidth}
                      tip={tip}
                      onSelect={handleSelectAndOpen}
                      maxBubbles={1000}
                      widthFactor={0.5}
                      bubbleSize={0.5}
                      showBar={false}
                    />

                    <BeehiveChartXAxis data={xAxisData} maxWidth={beeChartWidth} />
                  </div>
                  <Tip {...tip.tipProps}>{tip.data && <BeehiveChartPopup data={tip.data} />}</Tip>
                </Spin>
              </div>
            </Col>
            <Col>
              {selectedBrand && (
                <Button onClick={onViewDetails} className='details-btn'>
                  View more details <DetailsIcon />
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={[0, 24]}>
            <Col span={24} className='dashboard-widgets__card'>
              <PortfolioShareChart />
            </Col>
            <Col span={24} className='dashboard-widgets__card'>
              <CompetitorsShareChart />
            </Col>
            <Col span={24} className='dashboard-widgets__card'>
              <BrandSentiment />
            </Col>
            <Col span={24} className='dashboard-widgets__card influencer-widgets'>
              <InfluencersList />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default observer(Dashboard)
