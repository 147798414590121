import { Tooltip } from 'antd'

import { ReactComponent as ForecastIcon } from 'assets/images/forecast-icon.svg'
import { ReactComponent as LanguageIcon } from 'assets/images/language-risk-icon.svg'
import { ReactComponent as NarrativeIcon } from 'assets/images/risk-icon.svg'
import { ReactComponent as NegativeSentimentIcon } from 'assets/images/negative-sentiment-icon.svg'
import { ReactComponent as MainstreamIcon } from 'assets/images/mainstream-icon.svg'

import './RequiresAttention.scss'
import { Link } from 'react-router-dom'

interface Props {
  narrativeNumber?: number
  userID?: string
  warnings: {
    is_forcasting_active?: boolean
    is_language_risk_active?: boolean
    is_narrative_risk_active?: boolean
    is_negative_sentiment_active?: boolean
    is_mainstream_coverage_active?: boolean
  }
  mode: 'executive' | 'narrative'
}

export const RequiresAttention = ({ warnings, mode, narrativeNumber, userID }: Props) => {
  const hoverMessages = {
    is_forcasting_active: {
      active: 'Pendulum in forecasting a sustained trend change',
      inactive: 'Pendulum is not detecting a significant trend change',
    },
    is_language_risk_active: {
      active: 'There are posts with language risk detected',
      inactive: 'There are no posts with language risk detected',
    },
    is_narrative_risk_active: {
      active: 'There are posts with High Risk Score detected',
      inactive: 'There are no posts with High Risk Score detected',
    },
    is_negative_sentiment_active: {
      active: 'There are posts with negative sentiment detected',
      inactive: 'There are no posts with negative sentiment detected',
    },
    is_mainstream_coverage_active: {
      active: 'Pendulum is detecting narrative becoming more mainstream',
      inactive: 'Pendulum is not detecting narrative becoming more mainstream',
    },
  }

  const mainstreamClassName = `c-executive-requires-attention__icon--${
    warnings?.is_mainstream_coverage_active ? 'active' : 'inactive'
  }`

  const narrativeRiskClassName = `c-executive-requires-attention__icon--${
    warnings?.is_narrative_risk_active ? 'active' : 'inactive'
  }`

  const negativeSentimentClassName = `c-executive-requires-attention__icon--${
    warnings?.is_negative_sentiment_active ? 'active' : 'inactive'
  }`

  return (
    <div className='c-executive-requires-attention'>
      <Tooltip title={hoverMessages.is_forcasting_active[warnings?.is_forcasting_active ? 'active' : 'inactive']}>
        <ForecastIcon
          className={`c-executive-requires-attention__icon--${warnings?.is_forcasting_active ? 'active' : 'inactive'}`}
          onClick={(e: any) => {
            if (warnings?.is_forcasting_active) e.stopPropagation()
          }}
        />
      </Tooltip>
      {mode === 'executive' ? (
        <>
          <Tooltip
            title={hoverMessages.is_language_risk_active[warnings?.is_language_risk_active ? 'active' : 'inactive']}>
            <LanguageIcon
              className={`c-executive-requires-attention__icon--${
                warnings?.is_language_risk_active ? 'active' : 'inactive'
              }`}
              onClick={(e: any) => {
                if (warnings?.is_language_risk_active) e.stopPropagation()
              }}
            />
          </Tooltip>
          <Tooltip
            title={hoverMessages.is_narrative_risk_active[warnings?.is_narrative_risk_active ? 'active' : 'inactive']}>
            <NarrativeIcon
              className={`c-executive-requires-attention__icon--${
                warnings?.is_narrative_risk_active ? 'active' : 'inactive'
              }`}
              onClick={(e: any) => {
                if (warnings?.is_narrative_risk_active) e.stopPropagation()
              }}
            />
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip
            title={
              hoverMessages.is_mainstream_coverage_active[
                warnings?.is_mainstream_coverage_active ? 'active' : 'inactive'
              ]
            }>
            <Link
              className={mainstreamClassName}
              to={`/executive-intelligence/details/${userID}/${narrativeNumber}/NarrativeMainstream`}>
              <MainstreamIcon
                className={mainstreamClassName}
                onClick={(e: any) => {
                  if (warnings?.is_mainstream_coverage_active) e.stopPropagation()
                }}
              />
            </Link>
          </Tooltip>
          <Tooltip
            title={hoverMessages.is_narrative_risk_active[warnings?.is_narrative_risk_active ? 'active' : 'inactive']}>
            <Link
              className={narrativeRiskClassName}
              to={`/executive-intelligence/details/${userID}/${narrativeNumber}/NarrativeRisk`}>
              <NarrativeIcon
                className={narrativeRiskClassName}
                onClick={(e: any) => {
                  if (warnings?.is_narrative_risk_active) e.stopPropagation()
                }}
              />
            </Link>
          </Tooltip>
          <Tooltip
            title={
              hoverMessages.is_negative_sentiment_active[warnings?.is_negative_sentiment_active ? 'active' : 'inactive']
            }>
            <Link
              className={negativeSentimentClassName}
              to={`/executive-intelligence/details/${userID}/${narrativeNumber}/NarrativeNegative`}>
              <NegativeSentimentIcon
                className={negativeSentimentClassName}
                onClick={(e: any) => {
                  if (warnings?.is_negative_sentiment_active) e.stopPropagation()
                }}
              />
            </Link>
          </Tooltip>
        </>
      )}
    </div>
  )
}
