import { TablePaginationConfig } from 'antd'

import Pagination, { PaginationLocale } from 'antd/lib/pagination/Pagination'
import { ReactComponent as RightArrowIcon } from 'assets/images/right-arrow.svg'
import './Pagination.scss'

type TablePaginationPosition = 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight'

interface Props {
  totalCount: number
  currentPage?: number
  pageSize?: number
  className?: string
  position?: TablePaginationPosition[]
  showPageSizeChanger?: boolean
  pageSizeOptions?: string[] | number[]
  locale?: PaginationLocale
  onChange?: (page: number, pageSize: number) => void
  prevIcon?: React.ElementType
  nextIcon?: React.ElementType
  iconHeight?: number
  iconWidth?: number
}
export const TablePagination = ({
  totalCount,
  currentPage,
  pageSize,
  className,
  position,
  showPageSizeChanger,
  pageSizeOptions,
  locale,
  onChange,
}: Props): TablePaginationConfig => {
  return {
    total: totalCount,
    current: currentPage,
    pageSize,
    className: `pagination ${className || ''}`,
    position,
    showSizeChanger: showPageSizeChanger,
    pageSizeOptions,
    locale,
    itemRender: (page, type) => {
      if (type === 'prev') {
        return (
          <div className='pagination__container'>
            <RightArrowIcon className='pagination__left-arrow' width={13} height={13} />
            <span className='pagination__label'>{'Previous'}</span>
          </div>
        )
      } else if (type === 'next') {
        return (
          <div className='pagination__container'>
            <span className='pagination__label'>{'Next'}</span>
            <RightArrowIcon width={13} height={13} />
          </div>
        )
      } else if (type === 'jump-next' || type === 'jump-prev') {
        return (
          <div className='pagination__container'>
            <span className='pagination__label'>{'...'}</span>
          </div>
        )
      }
      return (
        <div className='pagination__container pagination__container--padding'>
          <span className='pagination__label'>{page}</span>
        </div>
      )
    },
    onChange,
  }
}

export const DefaultPagination = ({
  totalCount,
  currentPage,
  pageSize,
  className,
  showPageSizeChanger,
  pageSizeOptions,
  locale,
  onChange,
  prevIcon: PrevIcon = RightArrowIcon,
  nextIcon: NextIcon = RightArrowIcon,
  iconHeight = 13,
  iconWidth = 13,
}: Props) => {
  return (
    <Pagination
      total={totalCount}
      current={currentPage}
      pageSize={pageSize}
      className={`pagination pagination__flex ${className || ''}`}
      showSizeChanger={showPageSizeChanger}
      pageSizeOptions={pageSizeOptions}
      locale={locale}
      itemRender={(page, type) => {
        if (type === 'prev') {
          return (
            <div className='pagination__container'>
              <PrevIcon className='pagination__left-arrow' width={iconWidth} height={iconHeight} />
              <span className='pagination__label'>{'Previous'}</span>
            </div>
          )
        } else if (type === 'next') {
          return (
            <div className='pagination__container'>
              <span className='pagination__label'>{'Next'}</span>
              <NextIcon width={iconWidth} height={iconHeight} />
            </div>
          )
        } else if (type === 'jump-next' || type === 'jump-prev') {
          return (
            <div className='pagination__container'>
              <span className='pagination__label'>{'...'}</span>
            </div>
          )
        }
        return (
          <div className='pagination__container pagination__container--padding'>
            <span className='pagination__label'>{page}</span>
          </div>
        )
      }}
      onChange={onChange}
    />
  )
}
