import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  exportId: string
  fileType: 'csv' | 'json'
}
export const downloadExportedFeed = async ({ exportId, fileType }: Props) => {
  const endpoint = `${process.env.REACT_APP_API}${ROUTES.feedExport}/${exportId}/download?file_type=${fileType}`
  const { data } = await axios.get(endpoint)
  return data
}
