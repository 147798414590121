import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { store } from 'store'
import { Switch, Table, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useEffectOnce } from 'react-use'

import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import { ReactComponent as CardViewIcon } from 'assets/images/card-view-icon.svg'
import { ReactComponent as ListViewIcon } from 'assets/images/list-view-icon.svg'

import { TenantConfigurationEditModal } from '../Modals/TenantConfigurationEditModal/TenantConfigurationEditModal'
import { IActiveTenantConfigurationDetails, ITenantConfiguration } from 'models/models'
import { CardView } from 'components/common/CardView/CardView'
import { Card } from 'components/common/CardView/Card/Card'

import '../TenantDetails.scss'
import './TenantConfiguration.scss'

export const TenantConfiguration = observer(() => {
  const { id } = useParams()
  const columns = [
    {
      title: 'configurations',
      key: 'label',
      width: '70%',
      render: (col: any) => {
        return (
          <div className='tenant-configuration__table-col__container'>
            <span className='tenant-configuration__settings-div__label'>{col.label}</span>
            <span className='tenant-configuration__settings-div__description'>{col.description}</span>
          </div>
        )
      },
    },
    {
      title: 'can view',
      dataIndex: 'admin_may_view',
      key: 'view',
      width: '10%',
      render: (value: any) => {
        return <Switch checked={value} disabled />
      },
    },
    {
      title: 'can edit',
      dataIndex: 'admin_may_edit',
      key: 'edit',
      width: '10%',
      render: (value: any) => {
        return <Switch checked={value} disabled />
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: (col: any) => {
        return (
          <div className='tenant-users__actions'>
            <Tooltip
              overlayClassName='tenant-details__content__header__right__view-div__tooltip'
              title={'Edit configuration'}
              placement='bottom'>
              <div
                className={classNames('tenant-users__actions-div', {
                  'tenant-users__actions-div--disabled': tenantId === id ? false : !col.admin_may_view,
                })}>
                <EditIcon
                  height={18}
                  className='tenant-users__actions__icon tenant-users__icon'
                  onClick={(e) => {
                    showConfigModal(col)
                  }}
                />
              </div>
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const { tenantsStore, userStore } = store
  const {
    activeTenant,
    tenantConfiguration,
    tenantConfigurationDetails,
    setActiveTenant,
    fetchTenantDetails,
    fetchTenantConfiguration,
    fetchTenantConfigDetails,
  } = tenantsStore
  const { tenantId } = userStore

  const [fetchData, setFetchData] = useState<boolean>(true)
  const [showConfigurationEditModal, setShowConfigurationEditModal] = useState<boolean>(false)
  const [activeConfig, setActiveConfig] = useState<IActiveTenantConfigurationDetails | null>(null)
  const [currentView, setCurrentView] = useState<'card' | 'list'>('card')

  useEffectOnce(() => {
    if (!tenantConfiguration?.length) {
      fetchTenantConfiguration()
    }
  })

  useEffect(() => {
    if (fetchData) {
      if (id) {
        if (!activeTenant) {
          fetchTenantDetails(id).then((response) => {
            setActiveTenant(response)
            fetchTenantConfigDetails(id)
          })
        } else {
          fetchTenantConfigDetails(id)
        }
      }
      setFetchData(false)
    }
  }, [showConfigurationEditModal])

  const showConfigModal = (info: ITenantConfiguration) => {
    setShowConfigurationEditModal(true)
    const config = tenantConfigurationDetails.find((item) => item.configuration_name === info.configuration_name) || {
      configuration_name: '',
      config_json: {},
    }

    setActiveConfig({ ...info, ...config })
  }

  const getCardList = () => {
    return tenantConfiguration.map((data) => (
      <Card
        variant={'1'}
        children={getCardBody(data)}
        header={{
          title: data.label,
          icon: EditIcon,
          iconClass: classNames('tenant-configuration__settings-div__edit-icon', {
            'tenant-users__actions-div--disabled': tenantId === id ? false : !data.admin_may_view,
          }),
          iconClick: () => showConfigModal(data),
        }}
      />
    ))
  }

  const getCardBody = (data: ITenantConfiguration) => {
    return (
      <div className='tenant-configuration__settings-div'>
        <span className='tenant-configuration__settings-div__description'>{data.description}</span>
        <div className='tenant-configuration__settings-div__permissions'>
          <span className='tenant-configuration__settings-div__permissions__label'>Admin Permissions:</span>
          <div className='tenant-configuration__settings-div__permissions__switch'>
            <div className='tenant-configuration__settings-div__permissions__switch-div'>
              <span className='tenant-configuration__settings-div__permissions__label'>Can view</span>
              <Switch checked={data.admin_may_view} disabled />
            </div>
            <div className='tenant-configuration__settings-div__permissions__switch-div'>
              <span className='tenant-configuration__settings-div__permissions__label'>Can edit</span>
              <Switch checked={data.admin_may_edit} disabled />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='tenant-configuration'>
        <div className='tenant-details__content__header'>
          <span className='tenant-details__content__header__title'>
            Manage configurations and edit their permissions.
          </span>
          <div className='tenant-details__content__header__right'>
            <div className='tenant-details__content__header__right__view-div'>
              <Tooltip
                overlayClassName='tenant-details__content__header__right__view-div__tooltip'
                title={'Card View'}
                placement='bottom'>
                <CardViewIcon
                  className={classNames('tenant-details__content__header__right__view-div__card-icon', {
                    'tenant-details__content__header__right__view-div__card-icon--active': currentView === 'card',
                  })}
                  onClick={() => setCurrentView('card')}
                />
              </Tooltip>

              <Tooltip
                overlayClassName='tenant-details__content__header__right__view-div__tooltip'
                title={'List View'}
                placement='bottom'>
                <ListViewIcon
                  className={classNames('tenant-details__content__header__right__view-div__list-icon', {
                    'tenant-details__content__header__right__view-div__list-icon--active': currentView === 'list',
                  })}
                  onClick={() => setCurrentView('list')}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        {currentView === 'card' ? (
          <CardView children={getCardList()} />
        ) : (
          <Table columns={columns} dataSource={tenantConfiguration} pagination={false} />
        )}
      </div>
      <TenantConfigurationEditModal
        showModal={showConfigurationEditModal}
        setFetchData={setFetchData}
        configInfo={activeConfig}
        setShowModal={setShowConfigurationEditModal}
      />
    </div>
  )
})
