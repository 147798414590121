import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import millify from 'millify'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { ReactComponent as UpArrowIcon } from 'assets/images/icons/monitor/up_arrow_icon.svg'
import { ConditionsDataType, ConditionsPayloadType, FlagsCountType } from 'types/types'
import './AllPostSection.scss'
import { AssetListObjType } from 'store/asset/types'
import { SubStore } from 'types/types'
import { SearchConditions, SnippetFilterPayload } from 'models/models'

type RenderALLPostType = {
  itemData: AssetListObjType
  className?: string
  subStore: SubStore
  measure?: 'impression' | null
}

const AllPostSection = observer((props: RenderALLPostType) => {
  const { itemData, className = '', measure = null } = props
  const { fetchInsightsMetrics, selectedTab, getDate, snippetsFilter, getSnippetFilterPayload } = store.assetsStore

  const [postsCount, setPostsCount] = useState<FlagsCountType>({
    currentFlagCount: null,
    prevFlagCount: null,
  })

  const renderConditions = useMemo(() => {
    if (Object.keys((itemData as AssetListObjType).conditions).length) {
      return [(itemData as AssetListObjType).conditions]
    }
    return []
  }, [selectedTab, itemData])

  const insightsMetricsHandler = async () => {
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
    let prevStartDate = new Date(startDate)
    let prevEndDate = new Date(startDate)
    prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
    prevEndDate.setDate(prevEndDate.getDate() - 1)
    const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
    const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

    //use community,platform,sentiment and flags filter,
    const requestParams: { q: string; measure?: string } = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }
    const requestParamsForPrev: { q: string; measure?: string } = {
      q: `start_date:gte:${prevStartDateFormat},end_date:lte:${prevEndDateFormat}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }
    if (measure === 'impression') {
      requestParams.measure = measure
      requestParamsForPrev.measure = measure
    }

    if (renderConditions.length) {
      const requestData: ConditionsDataType = {
        conditions: renderConditions as ConditionsPayloadType[],
      }
      if (snippetsFilter.flags && Object.keys(snippetsFilter.flags).length > 0) {
        requestData.conditions.push(snippetsFilter.flags.conditions as SearchConditions)
      }

      const [currentFlagCount, prevFlagCount] = await Promise.all([
        fetchInsightsMetrics({ params: requestParams, data: requestData }),
        fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData }),
      ])
      setPostsCount({
        currentFlagCount: currentFlagCount === 'error' ? 0 : currentFlagCount,
        prevFlagCount: prevFlagCount === 'error' ? 0 : prevFlagCount,
      })
    } else {
      setPostsCount({
        currentFlagCount: null,
        prevFlagCount: null,
      })
    }
  }

  useEffect(() => {
    insightsMetricsHandler()
  }, [renderConditions, snippetsFilter])

  const renderUI = useMemo(() => {
    const riskValue = postsCount.currentFlagCount! - postsCount.prevFlagCount!
    if (riskValue === 0) {
      return
    }

    return (
      <>
        <UpArrowIcon className='apc_arrow_icon' />
        <span className='apc_trending_count'>{millify(Math.abs(riskValue))}</span>
      </>
    )
  }, [postsCount])

  const postCount = millify(postsCount?.currentFlagCount || 0)
  const displayPostCount = postCount === '0' ? '-' : postCount

  return (
    <>
      {postsCount.currentFlagCount === null || postsCount.prevFlagCount === null ? (
        <div className={`all_posts_container ${className}`}>
          <span className='apc_count'>-</span>
        </div>
      ) : (
        <div
          className={`all_posts_container${
            postsCount.currentFlagCount - postsCount.prevFlagCount < 0 ? ' all_posts_container_down' : ''
          } ${className}`}>
          <span className='apc_count'>{displayPostCount}</span>
          {postCount !== '0' && renderUI}
        </div>
      )}
    </>
  )
})

export default AllPostSection
