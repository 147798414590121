import { IUserToSave } from 'models/models'
import { Form, Input, Select, Checkbox, Spin } from 'antd'
import classNames from 'classnames'
import { useState } from 'react'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { ReactComponent as DropdownIcon } from 'assets/images/executive-intelligence/icons/add-person/dropdown-arrow.svg'
import { ReactComponent as SuccessIcon } from 'assets/images/tick-colored-icon.svg'

import Modal from 'antd/lib/modal/Modal'
import { ROLES } from 'settings/roles'
import { UtilService } from 'services/Util/Util'

import '../TenantModals.scss'
import './AddUserForm.scss'

interface Props {
  modalOpen: boolean
  setModalOpen: (state: boolean) => void
}

export const AddUserFormModal = observer(({ modalOpen, setModalOpen }: Props) => {
  const { id: tenantId } = useParams()
  const [form] = Form.useForm()
  const [userData, setUserData] = useState<IUserToSave>({
    username: '',
    email: '',
    given_name: '',
    family_name: '',
    role_id: '',
    tenant_id: '',
    notify_user: false,
    temporary_password: '',
  })

  const { usersStore, tenantsStore, navViewStore, loaderStore } = store
  const { saveUser } = usersStore
  const { isNavOpen } = navViewStore
  const { isLoadingUsers } = loaderStore
  const { tenantUsers, fetchTenantUsers } = tenantsStore

  const updateFormData = (newFormData: any) => {
    setUserData((prev) => {
      return { ...prev, ...newFormData }
    })
  }

  const onCancel = () => {
    setModalOpen(false)
  }

  const reset = () => {
    form.resetFields()
    setUserData({
      username: '',
      email: '',
      given_name: '',
      family_name: '',
      role_id: '',
      tenant_id: '',
      notify_user: false,
      temporary_password: '',
    })
  }

  const submitForm = async () => {
    try {
      const check = await form.validateFields()
      if (Object.values(check || {})?.length > 0 && tenantId?.length) {
        const userDetails = {
          ...userData,
          username: userData.email,
          tenant_id: tenantId,
        }
        const response = await saveUser(userDetails)

        if (response) {
          fetchTenantUsers({ id: tenantId, pageSize: tenantUsers.pageSize, page: tenantUsers.currentPageNumber })
          UtilService.openNotification({
            type: 'info',
            placement: 'bottomLeft',
            description: <span className='tenant-modal__notification__description'>User added successfully</span>,
            className: classNames('tenant-modal__notification', { 'tenant-modal__notification__margin': isNavOpen }),
            icon: <SuccessIcon />,
          })
          form.resetFields()
          setModalOpen(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      title={<span className='add-user__title'>Add new user</span>}
      open={modalOpen}
      destroyOnClose={true}
      closable={true}
      className='tenant-modal'
      onCancel={onCancel}
      afterClose={reset}
      centered={true}
      footer={
        <div className='add-user__footer'>
          <button className='add-user__footer__cancel' onClick={onCancel}>
            Cancel
          </button>
          <button className='add-user__footer__add' onClick={submitForm}>
            Add user
          </button>
        </div>
      }>
      <Spin spinning={isLoadingUsers}>
        <Form autoComplete='off' form={form} onValuesChange={updateFormData}>
          <div className='add-user__body'>
            <div className='add-user__body__container'>
              <div className='tenant-modal__info-div'>
                <label className='tenant-modal__info-div__label'>Email</label>
                <Form.Item name='email' rules={[{ required: true, message: 'Enter valid email', type: 'email' }]}>
                  <Input placeholder='Enter user email address' />
                </Form.Item>
              </div>
              <div className='tenant-modal__info-div'>
                <label className='tenant-modal__info-div__label'>First name</label>
                <Form.Item name='given_name' rules={[{ required: true, message: 'Required field' }]}>
                  <Input placeholder='Enter user first name' />
                </Form.Item>
              </div>
              <div className='tenant-modal__info-div'>
                <label className='tenant-modal__info-div__label'>Last name</label>
                <Form.Item name='family_name' rules={[{ required: true, message: 'Required field' }]}>
                  <Input placeholder='Enter user last name' />
                </Form.Item>
              </div>
              <div className='tenant-modal__info-div'>
                <label className='tenant-modal__info-div__label'>Role</label>
                <Form.Item name='role_id' rules={[{ required: true, message: 'Required field' }]}>
                  <Select
                    placeholder={'Choose user role'}
                    showSearch={false}
                    suffixIcon={<DropdownIcon className='add-user__body__role__icon' />}
                    popupClassName='add-user__body__role__dropdown'>
                    {ROLES?.map((role) => {
                      return (
                        <Select.Option key={role.id} value={role.id}>
                          <span className='add-user__body__role__option'>
                            {role.role.replaceAll('_', ' ').toLowerCase()}
                          </span>
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className='tenant-modal__info-div'>
                <label className='tenant-modal__info-div__label'>Temporary password (optional) </label>
                <Form.Item
                  name='temporary_password'
                  rules={[
                    {
                      validator: (_, value: string) => {
                        if (value?.length > 0) {
                          const passwordRegex =
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./])[a-zA-Z\d!@#$%^&*()_+{}|:"<>?`\-=[\]\\;',./]{8,}$/gm
                          const result = passwordRegex.test(value)
                          return result
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  `Contains at least one lowercase letter.
                                     Contains at least one uppercase letter.
                                     Contains at least one digit.
                                     Contains at least one special character.
                                     Has a minimum length of 8 characters.`,
                                ),
                              )
                        } else return Promise.resolve()
                      },
                    },
                  ]}>
                  <Input placeholder='Enter temporary password' type='password' />
                </Form.Item>
              </div>
            </div>
            <Form.Item name='notify_user' valuePropName='checked'>
              <Checkbox className='add-user__body__notify-permission'>
                Don’t send login information to the user
              </Checkbox>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  )
})
