import { observer } from 'mobx-react-lite'

import './ThreatsCarousel.scss'

interface Props {
  title: string
  description: JSX.Element
  image: JSX.Element
  active: JSX.Element
}
export const ThreatsCarousel = observer(({ title, description, image, active }: Props) => {
  return (
    <div className='threats-carousel'>
      <div className='threats-carousel__container__left'>
        <span className='threats-carousel__container__left__title'>{title}</span>
        <span className='threats-carousel__container__left__description'>{description}</span>
        {image}
      </div>
      <div className='threats-carousel__container__right'>
        <div className='threats-carousel__container__right__margin'>{active}</div>
      </div>
    </div>
  )
})
