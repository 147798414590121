import React, { useState, useEffect } from 'react'
import { Collapse, Select } from 'antd'
import styles from './Sections.module.scss'
import SectionIcon from './SectionIcon'
import TagRender from '../TagRender/TagRender'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as DownArrowIcon } from 'assets/images/icons/report/dropdownicon-size-16.svg'
import { ResponseListType } from 'store/report/valueTypes'

const { Panel } = Collapse
const { Option } = Select

// type DropdownNames = 'watchlist' | 'narratives' | 'bookmarks' | 'asset'

const types = {
  watchlist: 'WATCHLIST',
  narratives: 'NARRATIVE',
  bookmarks: 'BOOKMARK',
  assets: 'ASSET',
}

const Subsection = observer((props: any) => {
  const { reportMainViewStore } = store

  const {
    watchlistsList,
    narrativesList,
    bookmarksList,
    assetList,
    fetchNarrativesWithParams,
    fetchWatchListsWithParams,
    fetchBookmarksWithParams,
    fetchAssetWithParams,
  } = reportMainViewStore

  //.............states for managing selected values in the inner dropdownds............................
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true)
  const [selectedValuesWatchlistOptions, setSelectedValuesWatchlistOptions] = useState<string[]>([])
  const [selectedValuesNarrativesOptions, setSelectedValuesNarrativesOptions] = useState<string[]>([])
  const [selectedValuesBookmarksOptions, setSelectedValuesBookmarksOptions] = useState<string[]>([])
  const [selectedValuesAssetOptions, setSelectedValuesAssetOptions] = useState<string[]>([])
  const [narrativePage, setNarrativePage] = useState<number>(2)
  const [watchlistsPage, setWatchlistsPage] = useState<number>(2)
  const [bookmarksPage, setBookmarksPage] = useState<number>(2)
  const [assetPage, setAssetPage] = useState<number>(2)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const getOptionsList = (optionArr: any, callback: any) => {
    return callback(optionArr.map((option: any) => ({ type: option.type, id: option.id, value: option.value })))
  }

  const handleSelectChange = (selectedValues: string[], options: any, dropdownName: string) => {
    if (dropdownName === 'watchlist') {
      getOptionsList(options, setSelectedValuesWatchlistOptions)
    } else if (dropdownName === 'narratives') {
      getOptionsList(options, setSelectedValuesNarrativesOptions)
    } else if (dropdownName === 'bookmarks') {
      getOptionsList(options, setSelectedValuesBookmarksOptions)
    } else if (dropdownName === 'assets') {
      getOptionsList(options, setSelectedValuesAssetOptions)
    }
  }

  useEffect(() => {
    props.entitiesOnChange([
      ...selectedValuesWatchlistOptions,
      ...selectedValuesNarrativesOptions,
      ...selectedValuesBookmarksOptions,
      ...selectedValuesAssetOptions,
    ])
  }, [
    selectedValuesWatchlistOptions,
    selectedValuesNarrativesOptions,
    selectedValuesBookmarksOptions,
    selectedValuesAssetOptions,
  ])

  useEffect(() => {
    if (props.editMode && props.entities.length && isFirstTime) {
      let watchlists = [],
        narratives = [],
        bookmarks = [],
        assets = []

      for (const entity of props.entities) {
        if (entity.type === types.watchlist) {
          watchlists.push(entity)
        } else if (entity.type === types.narratives) {
          narratives.push(entity)
        } else if (entity.type === types.bookmarks) {
          bookmarks.push(entity)
        } else if (entity.type === types.assets) {
          assets.push(entity)
        }
      }
      setIsFirstTime(false)
      setSelectedValuesWatchlistOptions(watchlists)
      setSelectedValuesNarrativesOptions(narratives)
      setSelectedValuesBookmarksOptions(bookmarks)
      setSelectedValuesAssetOptions(assets)
    }
  }, [props.editMode, props.entities, isFirstTime])

  const customPopupContainer = (triggerNode: any) => {
    return triggerNode.parentNode
  }

  const panels = [
    {
      key: '1',
      header: 'Watchlist',
      content: innerDropDown(
        watchlistsList.items,
        'Choose an existing watchlist',
        selectedValuesWatchlistOptions,
        'watchlist',
      ),
    },
    {
      key: '2',
      header: 'Narratives',
      content: innerDropDown(
        narrativesList.items,
        'Choose an existing narrative',
        selectedValuesNarrativesOptions,
        'narratives',
      ),
    },
    {
      key: '3',
      header: 'Bookmarks',
      content: innerDropDown(
        bookmarksList.items,
        'Choose an existing bookmark',
        selectedValuesBookmarksOptions,
        'bookmarks',
      ),
    },
    {
      key: '4',
      header: 'Asset',
      content: innerDropDown(assetList.items, 'Choose an existing asset', selectedValuesAssetOptions, 'assets'),
    },
  ]

  const handleScroll = (e: React.UIEvent<HTMLDivElement>, type: string): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget

    if (scrollTop + clientHeight === scrollHeight) {
      if (type === 'narratives' && narrativesList.total_count !== narrativesList.items.length && !isFetching) {
        setIsFetching(true)
        const requestParams = {
          page: narrativePage,
          per_page: 10,
          sort: 'name:asc',
        }
        fetchNarrativesWithParams(requestParams).then((res: ResponseListType | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...narrativesList.items, ...res.items].length !== res.total_count) {
            setNarrativePage((prevPage) => prevPage + 1)
          }
        })
      } else if (type === 'watchlist' && watchlistsList.total_count !== watchlistsList.items.length && !isFetching) {
        setIsFetching(true)
        const requestParams = {
          page: watchlistsPage,
          per_page: 10,
          sort: 'name:asc',
        }
        fetchWatchListsWithParams(requestParams).then((res: ResponseListType | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...watchlistsList.items, ...res.items].length !== res.total_count) {
            setWatchlistsPage((prevPage) => prevPage + 1)
          }
        })
      } else if (type === 'bookmarks' && bookmarksList.total_count !== bookmarksList.items.length && !isFetching) {
        setIsFetching(true)
        const requestParams = {
          page: bookmarksPage,
          per_page: 10,
          sort: 'name:asc',
        }
        fetchBookmarksWithParams(requestParams).then((res: ResponseListType | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...bookmarksList.items, ...res.items].length !== res.total_count) {
            setBookmarksPage((prevPage) => prevPage + 1)
          }
        })
      } else if (type === 'assets' && assetList.total_count !== assetList.items.length && !isFetching) {
        setIsFetching(true)
        const requestParams = {
          is_asset: true,
          page: assetPage,
          per_page: 10,
          sort: 'name:asc',
        }
        fetchAssetWithParams(requestParams).then((res: ResponseListType | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...assetList.items, ...res.items].length !== res.total_count) {
            setAssetPage((prevPage) => prevPage + 1)
          }
        })
      }
    }
  }

  function innerDropDown(list: any[], placeholder: string, selectedValues: any[], dropdownName: string) {
    return (
      <Select
        tagRender={(props) => <TagRender {...props} name={dropdownName} />}
        mode='multiple'
        showArrow={true}
        suffixIcon={<DownArrowIcon className={styles.downArrowIcon} />}
        bordered={false}
        className={styles.subselect}
        placeholder={placeholder}
        value={selectedValues}
        getPopupContainer={customPopupContainer}
        onPopupScroll={(e) => handleScroll(e, dropdownName)}
        onChange={(selectedValues, option) => {
          handleSelectChange(selectedValues, option, dropdownName)
        }}>
        {list.map((option: any) => (
          <Option
            key={option.id}
            id={option.id}
            value={option.name || option.label}
            type={types[dropdownName as keyof typeof types]}>
            {option.name || option.label}
          </Option>
        ))}
      </Select>
    )
  }
  // .............................inner dropdown header........................................
  function SubsectionHeader({ panel }: any) {
    let subheaderContent = 0

    if (panel.key === '1') {
      subheaderContent = selectedValuesWatchlistOptions.length
    } else if (panel.key === '2') {
      subheaderContent = selectedValuesNarrativesOptions.length
    } else if (panel.key === '3') {
      subheaderContent = selectedValuesBookmarksOptions.length
    } else if (panel.key === '4') {
      subheaderContent = selectedValuesAssetOptions.length
    }
    return (
      <p className={styles.subheader}>
        {panel.header}
        <span className={subheaderContent > 0 ? styles.subheaderIcon : ''}>
          {subheaderContent > 0 ? subheaderContent : ''}
        </span>
      </p>
    )
  }
  const customExpandIcon = ({ isActive }: any) => <SectionIcon isActive={isActive}></SectionIcon>

  return (
    <Collapse
      onChange={props.handleCollapseChange}
      className={classNames(styles.subsection, { [styles.subsectionES]: props.isSection })}
      expandIcon={customExpandIcon}
      ghost={true}>
      {panels.map((panel: any) => (
        <Panel
          key={panel.key}
          className={classNames(styles.panel, { [styles.panelES]: props.isSection })}
          header={<SubsectionHeader panel={panel}></SubsectionHeader>}>
          {panel.content}
        </Panel>
      ))}
    </Collapse>
  )
})

export default React.memo(Subsection)
