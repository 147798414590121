import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { AssetListParamsType } from 'store/asset/types'

export function getCategories(params?: AssetListParamsType) {
  return axios({
    method: 'get',
    url: ROUTES.flagsCategories,
    params,
  })
}
