import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  name: string
  description: string
  display_label: string
  insight_type: string
  insight_condition: string[]
  tenant_ids?: string[]
}

export const createFlag = async (payload: Props) => {
  let url = process.env.REACT_APP_API + ROUTES.attentionFlags
  const { data } = await axios.post(url, payload)

  return data
}
