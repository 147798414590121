import './commonStyles.scss'

const StepsHeader = ({
  stepText,
  title = '',
  description = '',
}: {
  stepText: string
  title: string
  description: string
}) => {
  return (
    <div className='steps-header'>
      <div className='head'>
        {stepText && <h5>{stepText}</h5>}
        <h5 className='title'>{title}</h5>
      </div>
      <p className='description'>{description}</p>
    </div>
  )
}

export default StepsHeader
