import { store } from 'store'
import millify from 'millify'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'

import { MonitorSnippetItem } from './MonitorSnippetItem/SnippetItem'
import { DataFetching } from 'components/common/DataFetching/DataFetching'

import { Mode, SubStore } from 'types/types'

import { ReactComponent as BackToTopButton } from 'assets/images/backToTopScroll.svg'

import useInfiniteScroll from 'hooks/useInfiniteScroll'

import './SnippetList.scss'
import { DashboardSnippetItem } from './MonitorSnippetItem/DashboardSnippetItem'
import classNames from 'classnames'

interface Props {
  mode: Mode
  subStore: SubStore
  showSearchChip: boolean
  isDashboardSnippet?: boolean
  searchedDataResults: number
}

export const SnippetList = observer(
  ({ mode, subStore, showSearchChip, searchedDataResults, isDashboardSnippet = false }: Props) => {
    const { loaderStore } = store

    const {
      snippets,
      watchlists,
      communities,
      showSurprise,
      snippetsTotal,
      getWatchlists,
      fetchSnippets,
      setIsFeedEmpty,
      snippetsFilter,
      snippetsWithSurprises,
    } = store[`${subStore}Store`]
    const { isLoadingFeed } = loaderStore
    const listRef = useRef<HTMLDivElement>(null)
    const [showButton, setShowButton] = useState(false)

    const { lastElementRef } = useInfiniteScroll({
      onIntersect: () => {
        if ((snippetsWithSurprises?.length || 0) < (snippetsTotal || 0)) {
          fetchSnippets()
        }
      },
      enabled: !isLoadingFeed && snippetsWithSurprises.length > 0,
    })

    const handleShowButton = () => {
      const element = document.querySelector('.c-snippets-list__list')
      element?.addEventListener('scroll', (e) => {
        setShowButton((e.currentTarget as HTMLElement)?.scrollTop > 780)
      })
      return () => {
        element?.addEventListener('scroll', (e) => {
          setShowButton((e.currentTarget as HTMLElement)?.scrollTop > 780)
        })
      }
    }

    useEffect(() => {
      handleShowButton()
    }, [])

    const checkFeed = () => {
      return (
        <div
          className={classNames('c-snippets-list__list', { dashboard_snippet_list: isDashboardSnippet })}
          ref={listRef}>
          {!isLoadingFeed && !snippets.length ? (
            <DataFetching title={'title'} page={'notFound'} daysFilters={snippetsFilter.days} />
          ) : (
            <>
              {snippetsWithSurprises?.map((item, index) => (
                <div key={item.id}>
                  {isDashboardSnippet ? (
                    <DashboardSnippetItem
                      ref={index === snippetsWithSurprises.length - 1 ? lastElementRef : null}
                      mode={mode}
                      subStore={subStore}
                      snippet={item}
                      allWatchlist={watchlists}
                      allCommunities={communities}
                      showSurprise={showSurprise}
                    />
                  ) : (
                    <MonitorSnippetItem
                      ref={index === snippetsWithSurprises.length - 1 ? lastElementRef : null}
                      mode={mode}
                      subStore={subStore}
                      snippet={item}
                      allWatchlist={watchlists}
                      allCommunities={communities}
                      showSurprise={showSurprise}
                    />
                  )}
                </div>
              ))}
            </>
          )}

          {showButton && (
            <BackToTopButton
              className='snippet-list__backToTop'
              style={{ position: 'absolute', bottom: '20px', right: '20px', zIndex: 999999 }}
              onClick={() =>
                listRef.current?.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }
            />
          )}
        </div>
      )
    }

    useEffectOnce(() => {
      getWatchlists()
    })

    useUnmount(() => {
      setIsFeedEmpty(true)
    })

    return (
      <>
        <div className='snippet-list__wrapper'>
          {showSearchChip && searchedDataResults > 0 && (
            <div className='snippet-list__container'>
              <>
                <span className='snippet-list__search-result'>{millify(searchedDataResults) + ' results'}</span>
              </>
            </div>
          )}
          {checkFeed()}
        </div>
      </>
    )
  },
)
