import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { store } from 'store'
import { useEffectOnce } from 'react-use'
import classNames from 'classnames'

import { CLOSE_RANGE_INFO, MID_RANGE_INFO } from 'settings/settings'
import { IThreatTopic } from 'models/models'

import { ReactComponent as ListViewIcon } from 'assets/images/listview-icon.svg'
import { ReactComponent as ChordChartIcon } from 'assets/images/chord-chart-icon.svg'
import { ReactComponent as ThreeDIcon } from 'assets/images/riskwand/3d_chart_icon.svg'
import { ReactComponent as RightArrowIcon } from 'assets/images/right-arrow.svg'
import { ReactComponent as ElectionChordChartIcon } from 'assets/images/elecion-icon.svg'

import './RiskFlagsHeader.scss'
import TagManager from 'react-gtm-module'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import { Tooltip } from 'antd'

interface Props {
  threatTopics: IThreatTopic
  setExpandedKeys: (key: string[]) => void
}
export const RiskFlagsHeader = observer(({ threatTopics, setExpandedKeys }: Props) => {
  const listRef = useRef<HTMLDivElement | null>(null)
  const { anticipatoryIntelligenceStore, userStore } = store
  const { userId, userName, tenantId, roleId } = userStore
  const {
    chartName,
    attentionFlags,
    risks,
    attentionFlagsThreatDataListView,
    riskThreatDataListView,
    selectedFlagListView,
    setChartName,
    setIsSideDetailsOpen,
    setSelectedFlagListView,
    fetchThreatDetails,
    fetchAttentionFlags,
    fetchRisks,
    fetchAttentionFlagsDetailsListView,
    fetchRisksDetailsListView,
  } = anticipatoryIntelligenceStore

  useEffectOnce(() => {
    fetchAttentionFlags()
    fetchRisks()
  })

  useEffect(() => {
    if (Object.keys(attentionFlags).length > 0 && Object.keys(attentionFlagsThreatDataListView)?.length === 0)
      fetchAttentionFlagsDetailsListView()
    if (Object.keys(risks).length > 0 && Object.keys(riskThreatDataListView)?.length === 0) fetchRisksDetailsListView()
  }, [attentionFlags, risks])

  const scrollHandler = (scrollOffset: number) => {
    if (listRef.current) {
      listRef.current.scrollLeft += scrollOffset
    }
  }

  const changeChart = (name: 'list' | 'chord' | '3d' | 'election') => {
    setChartName(name)
    setIsSideDetailsOpen('')
  }

  const setFlag = (name: string, type: 'attention' | 'risk' | '') => {
    if (selectedFlagListView.name === name) {
      setSelectedFlagListView({ name: '', type: '' })
    } else {
      setSelectedFlagListView({ name: name, type: type })
      setExpandedKeys(Object.keys(threatTopics))
      TagManager.dataLayer({
        dataLayer: {
          event: 'riskwand_risk_flag_filter',
          riskCategory: name,
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
        },
      })
    }
  }

  const onChangeDateFilter = () => {
    fetchThreatDetails()
    fetchRisksDetailsListView()
    fetchAttentionFlagsDetailsListView()
  }

  return (
    <div className='risk-flags-header'>
      <div className='risk-flags-header__header'>
        <div className='risk-flags-header__header__filters'>
          <div className='risk-flags-header__graph-div'>
            <div
              className={classNames({
                'risk-flags-header__graph-div__active-chart': chartName === 'list',
              })}
              onClick={() => {
                changeChart('list')
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'anticipatory_intel_navigation',
                    //@ts-ignore
                    user_id: userId,
                    user_name: userName,
                    tenantId: tenantId,
                    roleId: roleId,
                    anticipatoryIntelPage: 'list',
                  },
                })
              }}>
              <Tooltip title='List View' placement='bottom'>
                <ListViewIcon aria-label='List View' />
              </Tooltip>
              {chartName === 'list' && (
                <span
                  className={classNames('risk-flags-header__graph-div__title', {
                    'risk-flags-header__graph-div__title--active': chartName === 'list',
                  })}>
                  {'List View'}
                </span>
              )}
            </div>
            <div
              className={classNames({
                'risk-flags-header__graph-div__active-chart': chartName === 'chord',
              })}
              onClick={() => {
                changeChart('chord')
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'anticipatory_intel_navigation',
                    //@ts-ignore
                    user_id: userId,
                    user_name: userName,
                    tenantId: tenantId,
                    roleId: roleId,
                    anticipatoryIntelPage: 'chord',
                  },
                })
              }}>
              <Tooltip title='Chord Chart' placement='bottom'>
                <ChordChartIcon aria-label='Chord Chart' />
              </Tooltip>
              {chartName === 'chord' && (
                <span
                  className={classNames('risk-flags-header__graph-div__title', {
                    'risk-flags-header__graph-div__title--active': chartName === 'chord',
                  })}>
                  {'Chord Chart'}
                </span>
              )}
            </div>
            <div
              className={classNames({
                'risk-flags-header__graph-div__active-chart': chartName === '3d',
              })}
              onClick={() => {
                changeChart('3d')
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'anticipatory_intel_navigation',
                    //@ts-ignore
                    user_id: userId,
                    user_name: userName,
                    tenantId: tenantId,
                    roleId: roleId,
                    anticipatoryIntelPage: '3d',
                  },
                })
              }}>
              <Tooltip title='3D Chart' placement='bottom'>
                <ThreeDIcon aria-label='3D Chart' />
              </Tooltip>
              {chartName === '3d' && (
                <span
                  className={classNames('risk-flags-header__graph-div__title', {
                    'risk-flags-header__graph-div__title--active': chartName === '3d',
                  })}>
                  3D Charts
                </span>
              )}
            </div>
            <div
              className={classNames({
                'risk-flags-header__graph-div__active-chart': chartName === 'election',
              })}
              onClick={() => {
                changeChart('election')
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'anticipatory_intel_navigation',
                    //@ts-ignore
                    user_id: userId,
                    user_name: userName,
                    tenantId: tenantId,
                    roleId: roleId,
                    anticipatoryIntelPage: 'election',
                  },
                })
              }}>
              <Tooltip title='Election Chord Chart' placement='bottom'>
                <ElectionChordChartIcon aria-label='Election Chord Chart' />
              </Tooltip>
              {chartName === 'election' && (
                <span
                  className={classNames('risk-flags-header__graph-div__title', {
                    'risk-flags-header__graph-div__title--active': chartName === 'election',
                  })}>
                  {'Election Chord Chart'}
                </span>
              )}
            </div>
          </div>
        </div>

        <DateFilter
          days={[1, 7, 30, 90, 180, 365, 730]}
          pageType={'list'}
          subStore={'anticipatoryIntelligence'}
          addCustomDateOption={false}
          onClick={onChangeDateFilter}
        />
      </div>

      <div className='risk-flags-header--inner'>
        <div className='risk-flags-header__list'>
          <div className='risk-flags-header__list' ref={listRef}>
            {CLOSE_RANGE_INFO.map((item) => {
              return (
                <div
                  className={classNames('risk-flags-header__list__item', {
                    'risk-flags-header__list__item--selected':
                      selectedFlagListView.type === 'attention' && selectedFlagListView.name === item.name,
                  })}
                  key={item.label}
                  onClick={() => setFlag(item.name, 'attention')}>
                  <Tooltip title={item.elementTitle} placement='bottom'>
                    <span className='risk-flags-header__list__item__icon' aria-label={item.elementTitle}>
                      {item.icon}
                    </span>
                  </Tooltip>
                  <span
                    className={classNames('risk-flags-header__list__item__title', {
                      'risk-flags-header__list__item__title--selected':
                        selectedFlagListView.type === 'attention' && selectedFlagListView.name === item.name,
                    })}>
                    {item.label}
                  </span>
                </div>
              )
            })}
            {MID_RANGE_INFO.map((item) => {
              return (
                <div
                  className={classNames('risk-flags-header__list__item', {
                    'risk-flags-header__list__item--selected':
                      selectedFlagListView.type === 'risk' && selectedFlagListView.name === item.name,
                  })}
                  key={item.label}
                  onClick={() => setFlag(item.name, 'risk')}>
                  <Tooltip title={item.elementTitle} placement='bottom'>
                    <span className='risk-flags-header__list__item__icon' aria-label={item.elementTitle}>
                      {item.icon}
                    </span>
                  </Tooltip>
                  <span
                    className={classNames('risk-flags-header__list__item__title', {
                      'risk-flags-header__list__item__title--selected':
                        selectedFlagListView.type === 'risk' && selectedFlagListView.name === item.name,
                    })}>
                    {item.label}
                  </span>
                </div>
              )
            })}
          </div>
          <button
            className='risk-flags-header__list__forward-btn'
            onClick={() => {
              scrollHandler(25)
            }}>
            <RightArrowIcon />
          </button>
        </div>
      </div>
    </div>
  )
})
