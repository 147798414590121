import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import UsersList from 'components/UsersList/UsersList'
import CustomModal from 'components/common/CustomModal/CustomModal'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import profileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as SuccessIcon } from 'assets/images/copied_icon.svg'

import './ShareReportModal.scss'
import useDebounce from 'hooks/useDebounce'
import { UtilService } from 'services/Util/Util'
import {
  ActionType,
  ApiMethodType,
  PermissionLevelType,
  ShareReportEndpoint,
  UserListResponse,
} from 'store/report/valueTypes'
import { Spin, Switch } from 'antd'
import ShareUserDropdown, { ShareUserDataType } from '../ShareUserDropdown/ShareUserDropdown'
import { openNotification } from 'services/Util/openNotification'
import TagManager from 'react-gtm-module'

type UserListObj = {
  id: string
  username: string
  email: string
  teant_id: string
  given_name: string
  family_name: string
  role: string
  avatar_url: string | null
  created: string
  last_updated: string
  status: string
}

type SharedUserListObj = {
  user_id?: string
  username?: string
  given_name?: string
  family_name?: string
  permission_level?: string
  localAdd?: boolean
}

type ShareReportModalProps = {
  onClose: () => void
  endpoint: ShareReportEndpoint
  reportId?: string
  instanceId?: string
  icon?: React.ElementType | null
  title?: string
  desc?: string
  type?: 'share' | 'email'
  secondButtonText?: string
  callback?: () => void
}

type SelectedValueAndUserObj = {
  selectedValue: ShareUserDataType
  selectedUser: SharedUserListObj
}

type SharedUsersListProps = {
  data?: SharedUserListObj[]
  className?: string
  type?: string
  onClick: (id: string) => void
  onSelect?: ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => void
}

type SharedUserListChunkProps = {
  className?: string
  [x: string]: any
  onClick?: (id: string) => void
  onSelect?: ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => void
}

const PER_PAGE = 50

const dropdownData = [
  { id: '0', option: 'can view', value: 'VIEWER' },
  { id: '1', option: 'can edit', value: 'EDITOR' },
]

const toastTarget = {
  reports: 'Report',
  narrative: 'Narrative',
  tag: 'Tag',
  watchlist: 'Watchlist',
  folder: 'Folder',
  bookmark: 'Bookmark',
}

const SharedUserListChunk = (props: SharedUserListChunkProps) => {
  const { className, onClick, onSelect, ...rest } = props
  const findObj = dropdownData.find((res) => res.value === rest.permission_level) || dropdownData[0]

  return (
    <div className={`users_list_container ${className}`}>
      <div className='ulc_image_container'>
        <img
          src={UtilService.isImageUrl(rest?.avatar_url) ? rest?.avatar_url : profileImage}
          alt='danger'
          className='ulc_image'
        />
      </div>
      <div className='ulc_content'>
        <div className='ulc_c_name_container'>
          <p className='ulc_c_name'>{rest?.given_name}</p>

          {rest?.type === 'email' ? (
            <p className='ulc_c_remove_email_share' onClick={() => onClick?.(rest?.user_id)}>
              Remove
            </p>
          ) : rest?.permission_level === 'owner' ? (
            <p className='ulc_c_remove ulc_c_owner_share'>Owner</p>
          ) : (
            <ShareUserDropdown
              data={dropdownData}
              closeWhenClick={true}
              value={findObj.option}
              selectedValue={findObj}
              onSelect={(val) => {
                onSelect?.({ selectedValue: val as ShareUserDataType, selectedUser: rest as SharedUserListObj })
              }}
              onRemove={() => onClick?.(rest?.user_id)}
            />
          )}
        </div>
        <p className='ulc_c_email'>{rest?.username}</p>
      </div>
    </div>
  )
}

const SharedUsersList = (props: SharedUsersListProps) => {
  const { data = [], type = 'share', onClick, onSelect } = props
  return (
    <div>
      {data.map((user: any) => (
        <SharedUserListChunk key={user.user_id} {...user} onClick={onClick} onSelect={onSelect} type={type} />
      ))}
    </div>
  )
}

const ShareReportModal = observer((props: ShareReportModalProps) => {
  const {
    onClose,
    endpoint,
    reportId = '',
    instanceId = '',
    title = 'Report Sharing',
    icon,
    desc = 'Invite other members to collaborate and review reports.',
    type = 'share',
    secondButtonText = 'Share Report',
    callback,
  } = props
  const { reportMainViewStore, navViewStore, toasterStore, userStore } = store
  const { userId, userName, tenantId, roleId } = userStore
  const {
    users,
    shareUsers,
    setShareUsers,
    updateSharedList,
    shareToAll,
    fetchUsersAndShareList,
    fetchUsersWithParams,
    sendReportAsEmail,
    isLoadingSharedUser,
    isConfirmLoading,
    isSendingEmail,
  } = reportMainViewStore

  const { isNavOpen } = navViewStore
  const { showToaster } = toasterStore
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedUsers, setSelectedUsers] = useState<SharedUserListObj[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [usersPage, setUsersPage] = useState<number>(2)
  const [isShareToAll, setShareToAll] = useState<boolean>(false)
  const [isPermission, setPermission] = useState('can view')
  const [isPermissionForAPI, setPermissionForAPI] = useState('VIEWER')

  const debouncedSearchValue = useDebounce(searchValue)

  useEffect(() => {
    // getUsersList()
    const requestParams = {
      page: 1,
      per_page: PER_PAGE,
    }
    fetchUsersAndShareList(endpoint, reportId, requestParams)
  }, [])

  useEffect(() => {
    setPermission((dropdownData.find((res) => res.value === shareUsers?.tenant_level_access) || dropdownData[0]).option)
    setShareToAll(shareUsers?.tenant_level_access ? true : false)
  }, [reportId, shareUsers?.tenant_level_access])

  const searchDropHandler = useCallback(
    (val: boolean) => {
      setIsOpen(val)
    },
    [isOpen],
  )

  const newUsers = useMemo(() => {
    if (users.items.length && 'shared_users' in shareUsers && shareUsers.shared_users.length) {
      return users.items.filter(
        (user) => !shareUsers.shared_users.some((res: SharedUserListObj) => res.user_id === user.id),
      )
    }
    return users.items
  }, [users, shareUsers?.shared_users])

  const filterUsers = useMemo(() => {
    if (debouncedSearchValue && users.items.length) {
      return newUsers.filter(
        (user) =>
          user.given_name.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          user.email.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
      )
    }
    return newUsers
  }, [debouncedSearchValue, newUsers])

  const sharedUserListHandler = async (
    methodType: ApiMethodType,
    userIds?: string[],
    action?: ActionType,
    permissionLevel?: PermissionLevelType,
  ) => {
    if (methodType === 'post') {
      const requestPayload = {
        user_ids: userIds ? userIds : [''],
        action: action ? action : '',
        permission_level: permissionLevel ? permissionLevel : '',
      }
      return updateSharedList(methodType, endpoint, reportId, requestPayload)
    } else {
      return updateSharedList(methodType, endpoint, reportId)
    }
  }

  const ShareWithAll = (props: SharedUserListChunkProps) => {
    const { className, onClick, onSelect, ...rest } = props
    const permissionCheck = (permission: any) => {
      setPermission(permission.option)
      setPermissionForAPI(permission.value)
    }
    return (
      <div>
        {rest?.permission_level === 'viewer' ? (
          <p className='ulc_c_remove ulc_c_owner_share'>can edit</p>
        ) : (
          <ShareUserDropdown
            data={dropdownData}
            closeWhenClick={true}
            value={isPermission}
            onSelect={(val) => {
              permissionCheck(val)
            }}
            ShareAll={true}
          />
        )}
      </div>
    )
  }

  const selectUserHandler = (userInfo: UserListObj) => {
    const haveUser = selectedUsers.some((user) => user.user_id === userInfo.id)
    if (haveUser) {
      const removeUser = selectedUsers.filter((user) => user.user_id !== userInfo.id)
      setSelectedUsers(removeUser)
      searchDropHandler(false)
      return
    }
    const addUser = {
      family_name: userInfo.family_name,
      given_name: userInfo.given_name,
      permission_level: 'VIEWER',
      user_id: userInfo.id,
      username: userInfo.username,
      localAdd: true,
    }

    setSelectedUsers((prevState: SharedUserListObj[]) => [...prevState, addUser as SharedUserListObj])
    searchDropHandler(false)
  }

  const updateShareUsersPermission = ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => {
    sharedUserListHandler('post', [selectedUser.user_id!], 'share', selectedValue.value).then((res) => {
      if (res !== 'error') {
        sharedUserListHandler('get')
      }
    })
  }

  const updateSelectedUsersPermission = ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => {
    setSelectedUsers((prevState: SharedUserListObj[]) =>
      prevState.map((user) =>
        user.user_id === selectedUser.user_id ? { ...user, permission_level: selectedValue.value } : user,
      ),
    )
  }

  const shareUsersHandler = () => {
    const viewerIds = selectedUsers.filter((res) => res.permission_level === 'VIEWER').map((user) => user.user_id!)
    const editorIds = selectedUsers.filter((res) => res.permission_level === 'EDITOR').map((user) => user.user_id!)
    if (isShareToAll === true) {
      const data = {
        permission_level: isPermissionForAPI,
        user_ids: ['*'],
        action: 'share',
      }
      Promise.all([shareToAll(endpoint, reportId, data)]).then((result) => {
        if (result.every((res) => res !== 'error')) {
          showToaster({
            iconType: 'success',
            message: `Successfully shared ${toastTarget[endpoint]} with all team members`,
          })

          sharedUserListHandler('get').then((res) => {
            if (res !== 'error') {
              setSelectedUsers([])
              setSearchValue('')
              callback?.()
            }
          })
        }
      })
      // google analytics share item
      TagManager.dataLayer({
        dataLayer: {
          event: 'share',
          entityType: endpoint,
          entityId: reportId,
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
          sharedUsers: data.user_ids,
        },
      })
    } else if (viewerIds.length && editorIds.length) {
      Promise.all([
        sharedUserListHandler('post', viewerIds, 'share', 'VIEWER'),
        sharedUserListHandler('post', editorIds, 'share', 'EDITOR'),
      ]).then((result) => {
        if (result.every((res) => res !== 'error')) {
          showToaster({
            iconType: 'success',
            message: `Successfully shared ${toastTarget[endpoint]} with ${selectedUsers.length} team members`,
          })

          sharedUserListHandler('get').then((res) => {
            if (res !== 'error') {
              setSelectedUsers([])
              setSearchValue('')
              callback?.()
            }
          })
        }
      })
      TagManager.dataLayer({
        dataLayer: {
          event: 'share',
          entityType: endpoint,
          entityId: reportId,
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
          sharedUsers: viewerIds && editorIds,
        },
      })
    } else if (viewerIds.length) {
      sharedUserListHandler('post', viewerIds, 'share', 'VIEWER').then((res) => {
        if (res !== 'error') {
          sharedUserListHandler('get').then((res) => {
            if (res !== 'error') {
              showToaster({
                iconType: 'success',
                message: `Successfully shared ${toastTarget[endpoint]} with ${selectedUsers.length} team members`,
              })
              setSelectedUsers([])
              setSearchValue('')
              callback?.()
            }
          })
        }
      })
      TagManager.dataLayer({
        dataLayer: {
          event: 'share',
          entityType: endpoint,
          entityId: reportId,
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
          sharedUsers: viewerIds,
        },
      })
    } else if (editorIds.length) {
      sharedUserListHandler('post', editorIds, 'share', 'EDITOR').then((res) => {
        if (res !== 'error') {
          sharedUserListHandler('get').then((res) => {
            if (res !== 'error') {
              showToaster({
                iconType: 'success',
                message: `Successfully shared ${toastTarget[endpoint]} with ${selectedUsers.length} team members`,
              })
              setSelectedUsers([])
              setSearchValue('')
              callback?.()
            }
          })
        }
      })
    } else if ((shareUsers.tenant_level_access?.length ?? 0) > 0) {
      const data = {
        permission_level: 'VIEWER',
        user_ids: ['*'],
        action: 'unshare',
      }
      shareToAll(endpoint, reportId, data)
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'share',
        entityType: endpoint,
        entityId: reportId,
        user_id: userId,
        user_name: userName,
        tenantId: tenantId,
        roleId: roleId,
        sharedUsers: editorIds,
      },
    })
    onClose()
  }

  const removeUserHandler = (id: string) => {
    const ids = [id]
    sharedUserListHandler('post', ids, 'unshare', 'VIEWER').then((res) => {
      if (res !== 'error') {
        sharedUserListHandler('get').then((res) => {
          if (res !== 'error') {
            callback?.()
          }
        })
      }
    })
  }

  const localRemoveUserHandler = (id: string) => {
    const filterSelectedUsers = selectedUsers.filter((user) => user.user_id !== id)
    setSelectedUsers(filterSelectedUsers)
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    const sumScroll = scrollTop + clientHeight
    if (sumScroll === scrollHeight || Math.ceil(sumScroll) === scrollHeight || Math.floor(sumScroll) === scrollHeight) {
      if (users.total_count !== users.items.length && !isFetching) {
        setIsFetching(true)
        const requestParams = {
          page: usersPage,
          per_page: PER_PAGE,
        }
        fetchUsersWithParams(requestParams).then((res: UserListResponse | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...users.items, ...res.items].length !== res.total_count) {
            setUsersPage((prevPage) => prevPage + 1)
          }
        })
      }
    }
  }

  const removeEmailUserHandler = (id: string) => {
    const remainingUsers = shareUsers.shared_users.filter((user) => user.user_id !== id)
    setShareUsers({ ...shareUsers, shared_users: remainingUsers })
  }

  const sendEmailHandler = async () => {
    const users = shareUsers.shared_users.map((user) => user.user_id)
    const response = await sendReportAsEmail({
      id: reportId,
      instanceId,
      users: [shareUsers?.owner?.user_id, ...users],
    })
    onClose()
    if (response) {
      openNotification({
        type: 'info',
        placement: 'bottomLeft',
        description: <span className='ren_description'>Report sent successfully</span>,
        className: isNavOpen ? 'report_email_notification ren_margin' : 'ren_notification',
        icon: <SuccessIcon width={24} height={24} />,
      })
    }
  }
  const onChangeShareAll = (checked: boolean) => {
    setShareToAll(checked)
  }

  return (
    <CustomModal
      onClose={onClose}
      onConfirm={type === 'email' ? sendEmailHandler : shareUsersHandler}
      disabledSecondButton={
        isShareToAll === true
          ? false
          : (shareUsers.tenant_level_access?.length ?? 0) > 0
          ? false
          : type === 'email'
          ? selectedUsers?.length < 1 && shareUsers?.shared_users?.length < 1
          : selectedUsers.length < 1
      }
      title={title}
      icon={icon}
      secondButtonText={secondButtonText}
      footerClassName='share_report_modal_footer_container'>
      <div className='share_report_modal_container'>
        <p className='srmc_text'>{desc}</p>
        <Spin spinning={isLoadingSharedUser || isConfirmLoading || isSendingEmail}>
          <SearchInput
            placeholder='Search by name or email'
            showIcon={false}
            className='srmc_input'
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            onClear={() => setSearchValue('')}
            showDrop={true}
            isOpen={isOpen}
            value={searchValue}
            setIsOpen={searchDropHandler}
            onScroll={handleScroll}>
            <div className='srmc_input_content_container'>
              <UsersList data={filterUsers} className='srmc_input_cc_chunk' onClick={selectUserHandler} />
            </div>
          </SearchInput>
          <div className='share_report_content_container'>
            <div className='users_list_container' style={{ justifyContent: 'space-between' }}>
              <div className='c_name_container' style={{ fontSize: '14px' }}>
                <Switch
                  className='ant-switch amc_switch'
                  onChange={onChangeShareAll}
                  style={{ marginRight: '10px' }}
                  checked={isShareToAll}
                />
                Share with all team members
              </div>
              {type !== 'email' && 'owner' in shareUsers && Object.keys(shareUsers.owner).length ? (
                <ShareWithAll permission_level='owner' {...shareUsers.owner} />
              ) : null}
            </div>
            {type !== 'email' && 'owner' in shareUsers && Object.keys(shareUsers.owner).length ? (
              <SharedUserListChunk permission_level='owner' {...shareUsers.owner} />
            ) : null}
            {'shared_users' in shareUsers && shareUsers.shared_users.length ? (
              <SharedUsersList
                data={shareUsers.shared_users}
                type={type}
                onClick={type === 'email' ? removeEmailUserHandler : removeUserHandler}
                onSelect={updateShareUsersPermission}
              />
            ) : null}
            {selectedUsers.length ? (
              <SharedUsersList
                data={selectedUsers}
                type={type}
                onClick={localRemoveUserHandler}
                onSelect={updateSelectedUsersPermission}
              />
            ) : null}
          </div>
        </Spin>
      </div>
    </CustomModal>
  )
})

export default ShareReportModal
