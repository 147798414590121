import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

export async function deleteWhiteboard(whiteboardId: string, version: number) {
  try {
    const { data } = await axios({
      method: 'DELETE',
      url: ROUTES.whiteboardById(whiteboardId),
      baseURL: process.env.REACT_APP_API,
      params: {
        version: version,
      },
    })

    return { data }
  } catch (error: any) {
    LogService.error({ message: 'deleteWhiteboard', error })
    return { data: null, error: error.response?.data }
  }
}
