import React, { useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as WatchlistIcon } from 'assets/images/icons/report/watchlist-icon.svg'
import { ReactComponent as NarrativeIcon } from 'assets/images/icons/report/narrative-icon.svg'
import { ReactComponent as BookmarkIcon } from 'assets/images/icons/report/bookmark-icon.svg'
import { ReactComponent as TagIcon } from 'assets/images/icons/report/tag-icon.svg'
import { ReactComponent as SummaryIcon } from 'assets/images/icons/report/summary-icon.svg'
import { ReactComponent as DropdownArrowIcon } from 'assets/images/dropdown-arrow-size-12.svg'
import DetailViewCollectionAddedInfoIcon from './DetailViewCollectionAddedInfoIcons/DetailViewCollectionAddedInfoIcons'

import styles from './DetailViewCollectionAdded.module.scss'
import { collectionObj } from 'store/report/valueTypes'
import usePermissions from 'hooks/usePermissions'

type DetailViewCollectionAddedProps = {
  collection: collectionObj
  editable?: boolean
}

const collectionInfoList = [
  { id: '0', icon: WatchlistIcon, tooltipText: 'Watchlist', key: 'watchlists' },
  { id: '1', icon: NarrativeIcon, tooltipText: 'Narrative', key: 'narratives' },
  { id: '2', icon: BookmarkIcon, tooltipText: 'Bookmarks', key: 'bookmarks' },
  { id: '3', icon: TagIcon, tooltipText: 'Assets', key: 'assets' },
]

const DetailViewCollectionAdded = observer((props: DetailViewCollectionAddedProps) => {
  const { collection, editable } = props
  const { navViewStore } = store
  const { isNavOpen } = navViewStore

  const [collapse, setCollapse] = useState<boolean>(true)

  const canReviewAndReceive = usePermissions('monitor') === 'review_and_receive'

  const canView = ['bookmarks', 'assets']

  const filteredInfoListOnRoleBase = collectionInfoList.filter((item) => canView.includes(item.key))

  const roleBasedCollectionInfoList = canReviewAndReceive ? filteredInfoListOnRoleBase : collectionInfoList

  return (
    <div className={classNames(styles.dvca_container)}>
      <div
        className={classNames(styles.dvca_c_header, { [styles.dvca_c_header_expand]: collapse })}
        onClick={() => setCollapse(!collapse)}>
        <div
          className={classNames(styles.dvca_c_header_left_container, {
            [styles.dvca_c_header_left_container_expand]: !collapse,
          })}>
          <p className={styles.dvca_c_header_text}>Collection added</p>
          <div className={styles.dvca_c_header_icons_container}>
            {roleBasedCollectionInfoList.map((list) => (
              <DetailViewCollectionAddedInfoIcon
                key={list.id}
                icon={list.icon}
                tooltipText={list.tooltipText}
                count={collection[list.key as keyof collectionObj].length}
              />
            ))}
          </div>
        </div>
        <div className={styles.dvca_c_dropdown_icon_container}>
          <DropdownArrowIcon
            className={classNames(styles.dvca_c_dropdown_icon, { [styles.dvca_c_rotate_dropdown_icon]: !collapse })}
          />
        </div>
      </div>
      <div className={styles.dvca_content_main_container}>
        <div>
          <div
            className={classNames(styles.dvca_content_container, {
              [styles.dvca_content_container_collapse]: !collapse,
              [styles.dvca_cc_with_active_menu]: isNavOpen && !editable,
              [styles.dvca_cc_without_active_menu]: !isNavOpen && !editable,
              [styles.dvca_cc_edit_with_active_menu]: isNavOpen && editable,
              [styles.dvca_cc_edit_without_active_menu]: !isNavOpen && editable,
            })}>
            {!canReviewAndReceive && (
              <>
                <div className={styles.dvca_cc_chunk}>
                  <div className={styles.dvca_cc_c_header}>
                    <WatchlistIcon className={styles.dvca_cc_header_icon} />
                    <p className={styles.dvca_cc_c_header_text}>Watchlists</p>
                  </div>

                  <div className={styles.dvca_cc_c_links_container}>
                    {collection.watchlists.map((link) => (
                      <p key={link.id} className={styles.dvca_cc_c_lc_link}>
                        {link.value}
                      </p>
                    ))}
                  </div>
                </div>
                <div className={styles.dvca_cc_chunk}>
                  <div className={styles.dvca_cc_c_header}>
                    <NarrativeIcon className={styles.dvca_cc_header_icon} />
                    <p className={styles.dvca_cc_c_header_text}>Narratives</p>
                  </div>
                  <div className={styles.dvca_cc_c_links_container}>
                    {collection.narratives.map((link) => (
                      <p key={link.id} className={styles.dvca_cc_c_lc_link}>
                        {link.value}
                      </p>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className={styles.dvca_cc_chunk}>
              <div className={styles.dvca_cc_c_header}>
                <BookmarkIcon className={styles.dvca_cc_header_icon} />
                <p className={styles.dvca_cc_c_header_text}>Bookmarks</p>
              </div>
              <div className={styles.dvca_cc_c_links_container}>
                {collection.bookmarks.map((link) => (
                  <p key={link.id} className={styles.dvca_cc_c_lc_link}>
                    {link.value}
                  </p>
                ))}
              </div>
            </div>
            <div className={styles.dvca_cc_chunk}>
              <div className={styles.dvca_cc_c_header}>
                <TagIcon className={styles.dvca_cc_header_icon} />
                <p className={styles.dvca_cc_c_header_text}>Assets</p>
              </div>
              <div className={styles.dvca_cc_c_links_container} style={{ display: 'inline-block' }}>
                {collection.assets.map((link) => (
                  <p key={link.id} className={styles.dvca_cc_c_lc_link}>
                    {link.value}
                  </p>
                ))}
              </div>
            </div>
            <div className={styles.dvca_cc_chunk}>
              <div className={styles.dvca_cc_c_header}>
                <SummaryIcon className={styles.dvca_cc_header_icon} />
                <p className={styles.dvca_cc_c_header_text}>Summarisation instructions</p>
              </div>
              <div className={styles.dvca_cc_c_links_container}>
                <p className={classNames(styles.dvca_cc_c_lc_link, styles.dvca_cc_c_lc_link_desc)}>
                  {collection.summary}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default DetailViewCollectionAdded
