import { Tooltip } from 'antd'
import classNames from 'classnames'
import { LinkOutlined } from '@ant-design/icons'
import { memo, useEffect, useState } from 'react'

import { API } from 'api/api'

import Avatar from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
import { ReactComponent as EditIcon } from 'assets/images/editicon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/deleteicon.svg'
import { CreatorInfoProps, CreatorInfoTypes } from 'pages/Vectors/CreateEditWatchListStepper/types'

const CreatorInfo = ({ urlData, handleEditURL, handleDeleteURL, setFormData }: CreatorInfoProps) => {
  const [creatorInfo, setCreatorInfo] = useState<CreatorInfoTypes | undefined>()

  const fetchCreator = async () => {
    if (!urlData?.channel_id) return

    try {
      const { data } = await API.get({
        route: 'channel',
        customAPI: process.env.REACT_APP_SEARCH_API,
        ids: urlData.channel_id,
      })

      if (data?.items?.length > 0) {
        setCreatorInfo(data.items[0])
        setFormData((prev) => ({
          ...prev,
          addedURLs: prev.addedURLs?.map((item) => {
            if (item.channel_id === data.items[0]?.channel_id) return { ...item, ...data.items[0], registered: true }
            return item
          }),
        }))
      }
    } catch (error) {
      console.error('Error fetching creator info:', error)
    }
  }

  useEffect(() => {
    fetchCreator()
  }, [urlData?.channel_id])

  const renderChannelInfo = () => {
    if (creatorInfo?.channel_title || creatorInfo?.channel_thumb_url) {
      return (
        <span className='channel-info'>
          <img
            src={creatorInfo.channel_thumb_url || Avatar}
            alt='Channel Thumbnail'
            onError={(e) => {
              e.currentTarget.onerror = null
              e.currentTarget.src = Avatar
            }}
          />
          <span className='channel-info__description'>
            <span className='channel-info__description__name'>{creatorInfo.channel_title || ''}</span>
            <span className='channel-info__description__url'>{creatorInfo.channel_url || ''}</span>
          </span>
        </span>
      )
    }

    const isInvalid = urlData?.isInvalid

    return (
      <Tooltip
        title={
          isInvalid
            ? !urlData?.platform
              ? 'The platform cannot be identified due to an incorrect URL format or because the platform is not supported'
              : 'Invalid url. Please provide a new link'
            : ''
        }
        placement='top'>
        <span className={classNames('create-url', { invalid: isInvalid })}>
          {!isInvalid && <LinkOutlined />}
          {isInvalid && (
            <span className='create-url__icon info-icon'>
              <InfoIcon />
            </span>
          )}
          <span className='create-url__url' title={urlData.channel_url}>
            {urlData.channel_url}
          </span>
          {isInvalid && (
            <span className='create-url--invalid'>
              <span className='create-url__icon' onClick={() => handleEditURL(urlData.channel_url, urlData?.platform)}>
                <EditIcon />
              </span>
              <span className='create-url__icon' onClick={() => handleDeleteURL(urlData.channel_url)}>
                <DeleteIcon />
              </span>
            </span>
          )}
        </span>
      </Tooltip>
    )
  }

  return <>{renderChannelInfo()}</>
}

export default memo(CreatorInfo)
