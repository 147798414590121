import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { store } from 'store'
import 'moment/locale/en-gb'
import moment from 'moment'
import { DateRangePicker } from 'react-date-range'
import { Dropdown, Menu } from 'antd'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { ReactComponent as CalenderIcon } from 'assets/images/calendar-icon.svg'

import { DaysFilter, PageType, SubStore } from 'types/types'

import './DateFilter.scss'

moment.locale('en-gb', {
  week: {
    dow: 0,
  },
  weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
})

interface Props {
  days: DaysFilter
  addCustomDateOption?: boolean
  pageType: PageType
  subStore: SubStore
  onClick?: () => void
}
export const DateFilter = observer(({ days, addCustomDateOption = true, pageType, subStore, onClick }: Props) => {
  const calendarRef: any = useRef(null)
  const { snippetsFilter, setSnippetsFilter } = store[`${subStore}Store`]

  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  useEffect(() => {
    if (snippetsFilter.days.custom.isApplied && snippetsFilter.days.custom.dateRange?.length > 0) {
      setDate([
        {
          startDate: moment(snippetsFilter.days.custom.dateRange[0]).toDate(),
          endDate: moment(snippetsFilter.days.custom.dateRange[1]).toDate(),
          key: 'selection',
        },
      ])
    }
  }, [snippetsFilter.days.custom.isApplied, snippetsFilter.days.custom.dateRange])

  useEffect(() => {
    function onClickOutside(event: any) {
      if (calendarRef.current && !calendarRef.current.contains(event?.target)) {
        setIsCalendarOpen(false)
      }
    }
    document.addEventListener('mousedown', onClickOutside)
    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  })

  const openCalendar = () => {
    setIsCalendarOpen((state) => !state)
  }

  const calenderFooter = () => {
    return (
      <div className='date-filter__calendar'>
        <div className='date-filter__calendar__date__button'>
          <button className='date-filter__calendar__date__button__cancel' onClick={onCancelDate}>
            Cancel
          </button>
          <button
            className='date-filter__calendar__date__button__apply'
            onClick={onApplyDate}
            disabled={!date[0].startDate || !date[0].endDate}>
            Apply
          </button>
        </div>
      </div>
    )
  }

  const onApplyDate = () => {
    if (date) {
      const newDates = [moment(date[0].startDate).format('YYYY-MM-DD'), moment(date[0].endDate).format('YYYY-MM-DD')]
      const filter = {
        days: {
          custom: {
            isApplied: true,
            dateRange: newDates,
          },
          noOfDays: 0,
        },
      }
      setSnippetsFilter({ ...snippetsFilter, ...filter })

      onClick?.()
      setIsCalendarOpen(false)
    }
  }

  const onCancelDate = () => {
    setIsCalendarOpen(false)
    if (snippetsFilter.days.custom.isApplied && snippetsFilter.days.custom.dateRange?.length > 0) {
      setDate([
        {
          startDate: moment(snippetsFilter.days.custom.dateRange[0]).toDate(),
          endDate: moment(snippetsFilter.days.custom.dateRange[1]).toDate(),
          key: 'selection',
        },
      ])
    } else
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ])
  }

  const getSelectedDate = () => {
    return (
      (snippetsFilter.days.noOfDays > -1 || snippetsFilter.days.custom.isApplied) && (
        <span className='date-filter__text'>
          {snippetsFilter.days.custom.isApplied
            ? `${moment(snippetsFilter.days.custom.dateRange[0]).format('MMM DD, YYYY')} - ${moment(
                snippetsFilter.days.custom.dateRange[1],
              ).format('MMM DD, YYYY')}`
            : snippetsFilter.days.noOfDays === 1
            ? 'Yesterday'
            : snippetsFilter.days.noOfDays === 7
            ? 'Last 7 days'
            : snippetsFilter.days.noOfDays === 30
            ? 'Last 30 days'
            : snippetsFilter.days.noOfDays === 90
            ? 'Last 90 days'
            : snippetsFilter.days.noOfDays === 180
            ? 'Last 6 months'
            : snippetsFilter.days.noOfDays === 365
            ? 'Last 1 year'
            : snippetsFilter.days.noOfDays === 730
            ? 'Last 2 years'
            : `Last ${snippetsFilter.days.noOfDays} days`}
        </span>
      )
    )
  }

  const dayFilterDropdown = (
    <Menu>
      <Menu.Item className='date-filter__title' key='days_filter'>
        Show Data
      </Menu.Item>
      {days.map((el) => (
        <Menu.Item
          key={el}
          className='date-filter__dropdown-item'
          onClick={({ key }) => {
            const filter = {
              days: {
                custom: {
                  isApplied: false,
                  dateRange: [],
                },
                noOfDays: Number(key),
              },
            }
            setSnippetsFilter({ ...snippetsFilter, ...filter })
            onClick?.()
          }}>
          <span
            className={classNames('date-filter__dropdown-item__text', {
              'date-filter__dropdown-item--selected': snippetsFilter.days.noOfDays === el,
            })}>
            {el === 1
              ? `Yesterday`
              : el === 7
              ? 'Last 7 days'
              : el === 30
              ? 'Last 30 days'
              : el === 90
              ? 'Last 90 days'
              : el === 180
              ? 'Last 6 months'
              : el === 365
              ? 'Last 1 year'
              : el === 730
              ? 'Last 2 years'
              : `Last ${el} days`}
          </span>
        </Menu.Item>
      ))}
      {addCustomDateOption && (
        <Menu.Item key={0} className='date-filter__dropdown-item'>
          <button
            className={classNames('date-filter__custom-btn', {
              'date-filter__dropdown-item--selected': snippetsFilter.days.noOfDays === 0,
            })}
            onClick={openCalendar}>
            {`Custom`}
          </button>
        </Menu.Item>
      )}
    </Menu>
  )

  return (
    <div style={{ display: 'flex' }}>
      {isCalendarOpen ? (
        <div
          className={classNames(
            {
              'date-filter__filter-icon': snippetsFilter.days.noOfDays > -1 || snippetsFilter.days.custom.isApplied,
            },
            'date-filter__filter-not-applied',
          )}
          ref={calendarRef}>
          <CalenderIcon />
          <div
            className={classNames('date-filter__calendar-dropdown', {
              'date-filter__calendar-dropdown__list-view': pageType === 'list',
            })}>
            <DateRangePicker
              className='date-filter__calendar-dropdown__container'
              onChange={(item: any) => {
                setDate([item.selection])
              }}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={date}
              direction='horizontal'
              staticRanges={[]}
              inputRanges={[]}
              editableDateInputs
              dateDisplayFormat={'yyyy-MM-dd'}
              monthDisplayFormat={'MMMM'}
              maxDate={new Date()}
            />
            <div className='date-filter__calendar-dropdown__footer'>{calenderFooter()} </div>
          </div>
        </div>
      ) : (
        <Dropdown
          className='date-filter'
          overlayClassName='date-filter__dropdown'
          trigger={['click']}
          placement={'bottomRight'}
          overlay={dayFilterDropdown}>
          <div
            className={classNames(
              {
                'date-filter__filter-icon': snippetsFilter.days.noOfDays > -1 || snippetsFilter.days.custom.isApplied,
              },
              'date-filter__filter-not-applied',
            )}>
            <CalenderIcon />
            {getSelectedDate()}
          </div>
        </Dropdown>
      )}
    </div>
  )
})
