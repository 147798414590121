import { Area, AreaChart, ResponsiveContainer } from 'recharts'

import { ReactComponent as UpArrowIcon } from 'assets/images/icons/monitor/up_arrow_icon.svg'

import './MentionViewCard.scss'
import { AssetListObjType } from 'store/asset/types'
import { store } from 'store'
import { ConditionsDataType, ConditionsPayloadType, FlagsCountType, NarrativeListDataType } from 'types/types'
import { useEffect, useMemo, useState } from 'react'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { observer } from 'mobx-react-lite'
import { getPercentageDifference } from 'services/Util/getPercentageDifference'
import { Skeleton } from 'antd'
import millify from 'millify'
import { SearchConditions, SnippetFilterPayload } from 'models/models'
import classNames from 'classnames'
import { ReactComponent as ChartEmptyState } from 'assets/images/icons/dashboard/chart-empty-state.svg'

type PropTypes = {
  measure?: 'impression' | 'engagement' | 'impressions' | null
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
  type: 'Mentions' | 'Views' | 'Engagement' | 'Impressions'
  customGraphColors?: {
    shadeColorUp: string
    lineColorUp: string
    shadeColorDown: string
    lineColorDown: string
    theme?: string
  }
  chartWrapperClassName?: string
  subStore?: 'assets' | 'vectors'
}

type chartDataObj = {
  value: number
}

const MentionViewCard = observer((props: PropTypes) => {
  const {
    selectedItem,
    measure = null,
    type,
    customGraphColors = null,
    chartWrapperClassName = '',
    subStore = 'assets',
  } = props

  const { fetchInsightsMetrics, getDate, snippetsFilter, getSnippetFilterPayload } = store[`${subStore}Store`]
  const [postsCount, setPostsCount] = useState<FlagsCountType>({
    currentFlagCount: null,
    prevFlagCount: null,
  })
  const [chartData, setChartData] = useState<chartDataObj[]>([])
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)

  const renderConditions = useMemo(() => {
    if (selectedItem && Object.keys((selectedItem as AssetListObjType).conditions).length) {
      return [(selectedItem as AssetListObjType).conditions]
    }
    return []
  }, [selectedItem])

  const insightsMetricsHandler = async () => {
    setIsLoadingData(true)
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })

    let prevStartDate = new Date(startDate)
    let prevEndDate = new Date(startDate)
    prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
    prevEndDate.setDate(prevEndDate.getDate() - 1)
    const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
    const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

    //use community,platform,sentiment and flags filter,
    const requestParams: { q: string; measure?: string } = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}${payload.query.risk}${payload.query.source}`,
    }
    const requestParamsForPrev: { q: string; measure?: string } = {
      q: `start_date:gte:${prevStartDateFormat},end_date:lte:${prevEndDateFormat}${payload.query.community}${payload.query.platform}${payload.query.sentiment}${payload.query.risk}${payload.query.source}`,
    }
    if (measure === 'impression') {
      requestParams.measure = 'impression'
      requestParamsForPrev.measure = 'impression'
    }
    if (measure === 'engagement') {
      requestParams.measure = 'engagement'
      requestParamsForPrev.measure = 'engagement'
    }
    if (renderConditions.length) {
      const requestData: ConditionsDataType = {
        conditions: renderConditions as ConditionsPayloadType[],
      }
      if (snippetsFilter.flags && Object.keys(snippetsFilter.flags).length > 0) {
        requestData.conditions.push(snippetsFilter.flags.conditions as SearchConditions)
      }

      const [currentFlag, prevFlag] = await Promise.all([
        fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true }),
        fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData, fullResponse: true }),
      ])
      setPostsCount({
        currentFlagCount: currentFlag === 'error' ? 0 : currentFlag.total_value,
        prevFlagCount: prevFlag === 'error' ? 0 : prevFlag.total_value,
      })
      if (currentFlag !== 'error') {
        const chartData = currentFlag.data_points.map((point: { metric_value: number }) => ({
          value: point.metric_value,
        }))
        if (chartData.length === 1) {
          chartData.unshift({ value: 0 })
        }
        setChartData(chartData)
      }
    } else {
      setPostsCount({
        currentFlagCount: null,
        prevFlagCount: null,
      })
    }
    setIsLoadingData(false)
  }

  useEffect(() => {
    if (selectedItem) {
      insightsMetricsHandler()
    }
  }, [renderConditions, snippetsFilter, selectedItem])

  const percentageDifference = useMemo((): number => {
    if (postsCount.currentFlagCount && postsCount.prevFlagCount) {
      return getPercentageDifference(postsCount.currentFlagCount, postsCount.prevFlagCount)
    }
    return 0
  }, [postsCount.currentFlagCount, postsCount.prevFlagCount])

  const comparisonDuration = useMemo(() => {
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
    switch (daysDifference - 1) {
      case 1:
        return 'Yesterday'
      case 7:
        return 'last 7 days'
      case 30:
        return 'last 30 days'
      case 31:
        return 'last 30 days'
      case 90:
        return 'last 90 days'
      case 180:
        return 'last 6 months'
      case 365:
        return 'last 1 year'
      case 730:
        return 'last 2 year'
      default:
        return `last ${daysDifference - 1} days`
    }
  }, [getDate.startDate, getDate.endDate])

  const shadeColor =
    percentageDifference >= 0
      ? customGraphColors
        ? customGraphColors.shadeColorUp
        : '#D7F2E5'
      : customGraphColors
      ? customGraphColors.shadeColorDown
      : '#F8DEDB'

  if (isLoadingData) {
    return (
      <>
        <span className='monitor-assets__graph__title'>{type}</span>
        <div className='mention-view-card'>
          <div className='mention-view-card__empty-state'>
            <p className='skeleton-paragraph__small'>
              <Skeleton paragraph active />
            </p>
            <p className='skeleton-paragraph__large'>
              <Skeleton paragraph active />
            </p>
          </div>
          <div className={`chart-wrapper ${chartWrapperClassName}`}>
            <ChartEmptyState />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <span className='monitor-assets__graph__title'>{type}</span>
      <div className='mention-view-card'>
        <div className='mention-view-card__status'>
          <div className='mention-view-card__status__counts'>{millify(postsCount?.currentFlagCount || 0)}</div>
          <div className='mention-view-card__status__trend'>
            <div
              className={classNames(
                `mention-view-card__status__trend__detail  `,
                {
                  trending: percentageDifference >= 0,
                },
                `${customGraphColors?.theme ? customGraphColors?.theme : ''}`,
              )}>
              <UpArrowIcon />
              <span className='mention-view-card__status__trend__detail__percentage'>
                {percentageDifference.toFixed(0)}%
              </span>
            </div>
            <div className='mention-view-card__status__trend__duration'>vs {comparisonDuration}</div>
          </div>
        </div>
        <div className={`chart-wrapper ${chartWrapperClassName}`}>
          {chartData.length > 1 && (
            <ResponsiveContainer width='100%' height='100%'>
              <AreaChart data={chartData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient
                    id={`colorValue-${percentageDifference >= 0 ? 'up' : 'down'}`}
                    x1='0'
                    y1='0'
                    x2='0'
                    y2='1'>
                    <stop offset='5%' stopColor={shadeColor} stopOpacity={0.8} />
                    <stop offset='95%' stopColor={shadeColor} stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <Area
                  type='monotone'
                  dataKey='value'
                  stroke={
                    percentageDifference >= 0
                      ? customGraphColors
                        ? customGraphColors?.lineColorUp
                        : '#17b26a'
                      : customGraphColors
                      ? customGraphColors?.lineColorDown
                      : '#f04438'
                  }
                  strokeWidth={2}
                  fill={`url(#colorValue-${percentageDifference >= 0 ? 'up' : 'down'})`}
                  fillOpacity={1}
                  isAnimationActive={false}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </>
  )
})

export default MentionViewCard
