import { Select, Spin } from 'antd'
import { useRef, useState } from 'react'
import VirtualList, { ListRef } from 'rc-virtual-list'
import { CloseOutlined, DownOutlined } from '@ant-design/icons'

import Checkbox from '../Checkbox/Checkbox'

import { GenericDispatch } from 'utils/commonTypes'

import './CustomSelect.scss'

type CustomSelectProps = {
  placeholder: string
  onScroll: () => void
  loading?: boolean
  selectedItems: string[]
  setSelectedItems: GenericDispatch<string[]>
  options: { value: string; label: string }[]
}

const CustomSelect = ({
  options,
  onScroll,
  selectedItems,
  loading = false,
  placeholder = '',
  setSelectedItems,
}: CustomSelectProps) => {
  const listRef = useRef<ListRef>(null)
  const [open, setOpen] = useState(false)

  const ContainerHeight = 250
  const ItemHeight = 44

  const handleDropdownVisibleChange = (open: boolean) => {
    setOpen(open)
  }

  const handleChange = (selectedItems: string[]) => {
    setSelectedItems(selectedItems)
  }

  const handleSelectItems = (value: string) => {
    const newSelectedItems = selectedItems.includes(value)
      ? selectedItems.filter((selectedItem) => selectedItem !== value)
      : [...selectedItems, value]
    setSelectedItems(newSelectedItems)
  }

  const dropdownRender = () => {
    return (
      <Spin spinning={loading}>
        <VirtualList
          ref={listRef}
          className='custom-select-options-container'
          data={options}
          itemHeight={ItemHeight}
          height={ContainerHeight}
          itemKey='id'
          onScroll={(e) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) onScroll()
          }}>
          {(item) => {
            return (
              <div key={item.value} className='custom-option' onClick={() => handleSelectItems(item.value)}>
                <Checkbox checked={selectedItems.includes(item.value)} onClick={() => null} isPropagation={false} />
                <span>{item.label}</span>
              </div>
            )
          }}
        </VirtualList>
      </Spin>
    )
  }

  return (
    <div className='custom-select-container'>
      <div className='select-wrapper'>
        <Select
          showArrow
          allowClear
          open={open}
          mode='multiple'
          maxTagCount={0}
          options={options}
          value={selectedItems}
          optionLabelProp='label'
          onChange={handleChange}
          style={{ width: '100%' }}
          filterOption
          clearIcon={<CloseOutlined />}
          suffixIcon={<DownOutlined />}
          className='alert-custom-select'
          dropdownRender={dropdownRender}
          placeholder={placeholder}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          getPopupContainer={() => document.querySelector('.select-wrapper') as HTMLElement}
          maxTagPlaceholder={(omittedValues) => `${omittedValues.length} flags selected`}
        />
      </div>
    </div>
  )
}

export default CustomSelect
