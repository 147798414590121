import { Form, Input, Alert } from 'antd'
import { useState } from 'react'
import classNames from 'classnames'

import { ResetPasswordTitle } from '../ResetPasswordTitle/ResetPasswordTitle'
import { Button } from 'components/common/Button/Button'

import { ReactComponent as Check } from 'assets/images/icon-check.svg'
import { ReactComponent as EyeOutlined } from 'assets/images/icon-eye-normal.svg'
import { ReactComponent as EyeInvisibleOutlined } from 'assets/images/icon-eye-hidden.svg'
import { SignInLink } from '../SignInLink/SignInLink'
import './ResetPassStage2.scss'

interface Props {
  info: { message: string; name?: string; code?: string; type: 'success' | 'error' } | null
}

export const ResetPassStage2 = ({ info }: Props) => {
  const [validPasswords, setValidPasswords] = useState({ password1: false, password2: false })
  const bothPasswordsValid = validPasswords.password1 && validPasswords.password2

  const customSuffix = (visible: boolean) => (
    <div>
      {bothPasswordsValid && <Check className='c-reset-pass-stage-2__password-field-check' />}
      {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
    </div>
  )
  return (
    <>
      <ResetPasswordTitle>Reset Password</ResetPasswordTitle>
      {info && <Alert message={info.message} type={info.type} />}

      <Form.Item
        label='Email Confirmation Code'
        name='code'
        rules={[{ required: true, message: 'Please input a valid code!' }]}>
        <Input placeholder='Your confirmation code' />
      </Form.Item>

      <Form.Item
        label={
          <>
            <p>New password</p>
            <div className='c-reset-pass-stage-2__label-pwd-info'>
              New password should have:
              <ul>
                <li>at least 8 characters</li>
                <li>at least one capital letter & number</li>
                <li>at least one special character ($,%,!)</li>
              </ul>
            </div>
          </>
        }
        name='newPassword'
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
          () => ({
            validator(_, value) {
              let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$')

              if (value.length === 0) return Promise.resolve() //just to prevent having 2 errors at the same time
              if (value.length >= 8)
                if (regex.test(value)) {
                  setValidPasswords((state) => {
                    return { ...state, password1: true }
                  })
                  return Promise.resolve()
                }

              setValidPasswords((state) => {
                return { ...state, password1: false }
              })
              return Promise.reject(new Error('Your password doesn`t match the criterias listed above!'))
            },
          }),
        ]}>
        <Input.Password
          iconRender={customSuffix}
          className={classNames({ 'c-reset-pass-stage-2__password-field--valid': bothPasswordsValid })}
          placeholder='Strong password'
        />
      </Form.Item>

      <Form.Item
        label='Confirm New Password'
        name='repeatedNewPassword'
        rules={[
          { required: true, message: 'Please repeat your password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                setValidPasswords((state) => {
                  return { ...state, password2: true }
                })
                return Promise.resolve()
              }
              setValidPasswords((state) => {
                return { ...state, password2: false }
              })
              return Promise.reject(new Error('The two passwords that you entered do not match!'))
            },
          }),
        ]}>
        <Input.Password
          iconRender={customSuffix}
          className={classNames({ 'c-reset-pass-stage-2__password-field--valid': bothPasswordsValid })}
          placeholder='Strong password'
        />
      </Form.Item>

      <Button type='secondary' htmlType='submit'>
        Update Password
      </Button>
      <SignInLink />
    </>
  )
}
