import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'
import { IListItem } from 'models/models'

interface Props {
  list?: IListItem[]
  narratives?: number[]
}

export async function getGrowth({ list, narratives }: Props) {
  try {
    let allIds = ''
    if (list) {
      list.forEach((item: IListItem) => {
        allIds += item.idNumber + ','
      })
    } else if (narratives) {
      narratives.forEach((item: number) => {
        allIds += item + ','
      })
    }
    if (allIds.length > 0) {
      let url = process.env.REACT_APP_SEARCH_API + `${ROUTES.narrativeGrowthForecast}?ids=${allIds}`

      const { data } = await axios.get(url)
      return data
    } else return null
  } catch (error) {
    LogService.error({ message: 'getNarrativeGrowth error', error })
  }
}
