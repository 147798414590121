import { Checkbox, Modal, Spin } from 'antd'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { useState } from 'react'
import { flagsAPI } from 'api/api'
import './BrandsListVertical.scss'

type Props = {
  onClose: (status?: boolean) => void
  brandsList: any[]
}
export default function DeactivatedBrands({ onClose, brandsList }: Props) {
  const [checkedIds, setCheckedIds] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const onUpdateCheckStatus = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedIds([...checkedIds, id])
      return
    }
    const filteredIds = checkedIds.filter((checkedId) => checkedId !== id)
    setCheckedIds(filteredIds)
  }

  const activateBrands = async () => {
    try {
      setIsLoading(true)
      await flagsAPI.toggleFlagStatus({ ids: checkedIds, status: 'activate' })
      onClose(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      open
      destroyOnClose
      onOk={() => null}
      closable={true}
      title='Deactivated brands'
      onCancel={() => onClose()}
      className='deactivated-brands-modal'
      centered
      footer={
        <div className='deactivated-brands-modal__footer'>
          <ButtonImage onClick={() => onClose()} className='deactivated-brands-modal__footer__cancel-btn '>
            Cancel
          </ButtonImage>
          <ButtonImage
            onClick={activateBrands}
            loading={isLoading}
            className='deactivated-brands-modal__footer__continue-btn '>
            {checkedIds.length > 0
              ? `Activate ${checkedIds.length} ${checkedIds.length > 1 ? 'brands' : 'brand'}`
              : 'Activate'}
          </ButtonImage>
        </div>
      }>
      <Spin spinning={isLoading}>
        <div className='deactivated-brands-modal__content'>
          <p className='status'>
            You have {brandsList.length} deactivated brands. Check the box to activate them again.
          </p>
          <div className='brand-list'>
            {brandsList.map((brand) => (
              <div className='brand-list__brand'>
                <Checkbox
                  checked={checkedIds.includes(brand.id)}
                  onChange={(event) => onUpdateCheckStatus(event.target.checked, brand.id)}
                />
                <h5>{brand.name}</h5>
              </div>
            ))}
          </div>
        </div>
      </Spin>
    </Modal>
  )
}
