import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg'
import { ReactComponent as BulletIcon } from 'assets/images/icons/bullet.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import styles from './DeleteSectionsforReport.module.scss'

type clickedObj = {
  isTrashClicked: boolean
  isDeleteClicked: boolean
}

export default function DeleteSectionsforReport(props: any) {
  const [sectionStates, setSectionStates] = useState<clickedObj[]>([])

  const handleTrashClick = (index: number) => {
    setSectionStates((prevStates: any) =>
      prevStates.map((state: any, arrayIndex: number) => {
        if (arrayIndex === index) {
          return {
            ...state,
            isTrashClicked: true,
            isDeleteClicked: false,
          }
        }
        return state
      }),
    )
  }

  useEffect(() => {
    if (props.sections.length) {
      const clickedArr = props.sections.map(() => ({
        isTrashClicked: false,
        isDeleteClicked: false,
      }))
      setSectionStates(clickedArr)
    }
  }, [props.sections])

  const handleDeleteClick = (index: number) => {
    setSectionStates((prevStates: any) =>
      prevStates.map((state: any, stateIndex: number) => {
        if (stateIndex === index) {
          return {
            ...state,
            isTrashClicked: false,
            isDeleteClicked: true,
          }
        }
        return state
      }),
    )

    // after 3 seconds come back to main content after deleting the section
    setTimeout(() => {
      setSectionStates((prevStates: any) =>
        prevStates.map((state: any, stateIndex: number) => {
          if (stateIndex === index) {
            return {
              ...state,
              isTrashClicked: false,
              isDeleteClicked: false,
            }
          }
          return state
        }),
      )
    }, 3000)
  }

  const handleUndoClick = (index: number) => {
    setSectionStates((prevStates: any) =>
      prevStates.map((state: any, stateIndex: number) => {
        if (stateIndex === index) {
          return {
            ...state,
            isTrashClicked: false,
            isDeleteClicked: false,
          }
        }
        return state
      }),
    )
  }

  const handleCloseClick = (index: number) => {
    setSectionStates((prevStates: any) =>
      prevStates.map((state: any, arrayIndex: number) => {
        if (arrayIndex === index) {
          return {
            ...state,
            isTrashClicked: false,
            isDeleteClicked: false,
          }
        }
        return state
      }),
    )
  }

  return (
    <div className={styles.dsrMain}>
      {props.sections.map((section: any, index: number) => (
        <div
          className={classNames(styles.dsrContainer, {
            [styles.onFlagClick]: sectionStates[index]?.isTrashClicked || sectionStates[index]?.isDeleteClicked,
          })}
          key={section.id}>
          {/* main content when trash or delete are not clicked */}
          {!sectionStates[index]?.isTrashClicked && !sectionStates[index]?.isDeleteClicked && (
            <>
              <BulletIcon className={styles.dsrBullet} />
              <p className={styles.dsrTitle}>{section.title}</p>
              <TrashIcon className={styles.dsrTrash} onClick={() => handleTrashClick(index)} />
            </>
          )}

          {/* trash clicked */}
          {sectionStates[index]?.isTrashClicked && (
            <>
              <p className={classNames(styles.dsrTitle, { [styles.bold]: sectionStates[index]?.isTrashClicked })}>
                Are you sure to delete {section.title.substring(0, 17)}...
              </p>
              <p className={styles.deleteLink} onClick={() => handleDeleteClick(index)}>
                Delete
              </p>
              <CloseIcon className={styles.close} onClick={() => handleCloseClick(index)} />
            </>
          )}

          {/* delete text clicked */}
          {sectionStates[index]?.isDeleteClicked && (
            <>
              <p className={classNames(styles.dsrTitle, { [styles.bold]: sectionStates[index]?.isDeleteClicked })}>
                Section Deleted Successfully
              </p>
              <p className={styles.undoLink} onClick={() => handleUndoClick(index)}>
                Undo
              </p>
              <CloseIcon className={styles.close} onClick={() => handleCloseClick(index)} />
            </>
          )}
        </div>
      ))}
    </div>
  )
}
