import { useMemo, useState } from 'react'
import { SectionObjType } from '../../CreateReport'
import EditButton from '../EditButton'
import { ReactComponent as DropdownIcon } from 'assets/images/icon-dropmenu-wider.svg'
import classNames from 'classnames'
import Tag from '../Tag'
import { isEmpty } from 'lodash'
import './ReviewContentDisplay.scss'

const FilterTag = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className='content-filters-list-item__container'>
      <div className='content-filters-list-item__info'>
        <span className='content-filters-list-item__info__title'>{label}</span>
      </div>
      <div className='content-filters-list-item__tags'>{value}</div>
    </div>
  )
}

const ReviewContentDisplay = ({
  section,
  openSectionToEdit,
}: {
  section: SectionObjType
  openSectionToEdit: (section: SectionObjType) => void
}) => {
  const [openPrompt, setOpenPrompt] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)

  const filtersApplied = useMemo(() => {
    let main = 0
    let refined = 0
    let query = 0

    Object.keys(section.filters).forEach((filter) => {
      const filterKey = filter as keyof typeof section.filters
      query = query + section.filters[filterKey].length
    })
    Object.keys(section.mainItems).forEach((filter) => {
      const filterKey = filter as keyof typeof section.mainItems
      main = main + section.mainItems[filterKey].length
    })
    Object.keys(section.optionalItems).forEach((filter) => {
      const filterKey = filter as keyof typeof section.optionalItems
      refined = refined + section.optionalItems[filterKey].length
    })
    return {
      count: main + refined + query,
      main,
      refined,
      query,
    }
  }, [section])

  const summaryPromptExists = section.summary_prompt.length > 0

  const onEditFilters = () => {
    openSectionToEdit(section)
  }

  return (
    <div className='review-content'>
      <div className='review-content__display'>
        <div className='review-content__display__header'>
          <span className='review-content__display__header__title'>{section.title}</span>
          <div className='review-content__display__header__action'>
            <Tag text={`${filtersApplied.count} filters applied`} />
            <EditButton onClick={onEditFilters} />
            <DropdownIcon
              onClick={() => setOpenFilters(!openFilters)}
              className={classNames({ 'review-content__display__header__action__open': openFilters })}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        {openFilters && (
          <div className='review-content__filters-container'>
            {filtersApplied.main > 0 && (
              <div className='review-content__filters'>
                <span className='review-content__filters__title'>Content</span>
                {Object.entries(section.mainItems).map(([key, value]) =>
                  isEmpty(value) ? null : <FilterTag label={key} value={value?.map((item) => item.name)?.join(', ')} />,
                )}
              </div>
            )}
            {filtersApplied.refined > 0 && (
              <div className='review-content__filters'>
                <span className='review-content__filters__title'>Refined Content</span>
                {Object.entries(section.optionalItems).map(([key, value]) =>
                  isEmpty(value) ? null : <FilterTag label={key} value={value?.map((item) => item.name)?.join(', ')} />,
                )}
              </div>
            )}
            {filtersApplied.query > 0 && (
              <div className='review-content__filters'>
                <span className='review-content__filters__title'>Additional filters</span>
                {Object.entries(section.filters).map(([key, value]) =>
                  isEmpty(value) ? null : <FilterTag label={key} value={value?.map((item: any) => item).join(', ')} />,
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div className='review-content__summary'>
        <div className='review-content__summary__link' />
        <div className='review-content__display'>
          <div className='review-content__display__header'>
            <span className='review-content__display__header__title'>Summarization</span>
            <div className='review-content__display__header__action'>
              <Tag
                text={summaryPromptExists ? 'Custom prompt' : 'Create Executive Summary'}
                onClick={() => {
                  if (!summaryPromptExists) onEditFilters()
                }}
              />
              <DropdownIcon
                onClick={() => setOpenPrompt(!openPrompt)}
                className={classNames({ 'review-content__display__header__action__open': openPrompt })}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
          {openPrompt && summaryPromptExists && (
            <div className='review-content__summary__text'>{section.summary_prompt}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReviewContentDisplay
