import axios from 'axios'

import { ROUTES } from 'settings/settings'
interface Props {
  name: string
  description: string
  communities: string[]
  keywords_expression: string
  parent_narrative_ids: string[]
  related_contents?: string[]
  tenant_id: string
  type: string
}

export const saveNarrative = async (narrative: Props) => {
  let url = process.env.REACT_APP_API + ROUTES.newNarrativeSave

  const { data } = await axios.post(url, narrative)

  return data
}
