import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { Spin } from 'antd'
import { ReactComponent as ChevronRightIcon } from 'assets/images/icons/monitor/chevron_fill_right_icon.svg'
import { ReactComponent as HashIcon } from 'assets/images/icons/monitor/relevence_icon.svg'
import { ReactComponent as ThemeIcon } from 'assets/images/icons/monitor/themes_icon.svg'
import { ReactComponent as ElectionIcon } from 'assets/images/icons/monitor/elections_icon.svg'
import { ReactComponent as IndustryIcon } from 'assets/images/icons/monitor/industries_icon.svg'
import { ReactComponent as RiskFlagIcon } from 'assets/images/icons/monitor/riskflags_icon.svg'
import { ReactComponent as JobIcon } from 'assets/images/icons/monitor/job_function.svg'
import { ReactComponent as RegionIcon } from 'assets/images/icons/monitor/region_icon.svg'
import { ReactComponent as OilAndGasIcon } from 'assets/images/icons/monitor/oil_and_gas_icon.svg'
import { ReactComponent as HealthCareIcon } from 'assets/images/icons/monitor/healthcare.svg'
import { FolderListObjType } from 'types/types'
import './CollectionSection.scss'
import TagManager from 'react-gtm-module'

type IconObjType = { [key: string]: any }

const Icons: IconObjType = {
  industries: <IndustryIcon className='csc_c_c_hc_h_collection_icon' />,
  themes: <ThemeIcon className='csc_c_c_hc_h_collection_icon' />,
  electionsiq: <ElectionIcon className='csc_c_c_hc_h_collection_icon' />,
  'job functions': <JobIcon className='csc_c_c_hc_h_collection_icon' />,
  region: <RegionIcon className='csc_c_c_hc_h_collection_icon' />,
  relevance: <HashIcon className='csc_c_c_hc_h_collection_icon' />,
  'risk flags': <RiskFlagIcon className='csc_c_c_hc_h_collection_icon' />,
  'oil, gas & energy': <OilAndGasIcon className='csc_c_c_hc_h_collection_fill_icon' />,
  healthcare: <HealthCareIcon className='csc_c_c_hc_h_collection_fill_icon_2' />,
}

const constructHierarchy = (items: FolderListObjType[], parentPath: string = ''): FolderListObjType[] => {
  let modifiedItems: FolderListObjType[] = []

  items.forEach((item) => {
    let newParentPathWithChild = parentPath.length ? `/${parentPath}/${item.name}/` : `/${item.name}/`
    let currentPath = item.full_path
    if (newParentPathWithChild === currentPath) {
      const child = constructHierarchy(items, currentPath.slice(1, -1))
      modifiedItems.push({ ...item, children: child })
    }
  })

  return modifiedItems
}

type SubCollectionsProps = {
  item: FolderListObjType
  openSubCollections: string[]
  setOpenSubCollections: (arr: string[]) => void
  activeCollection: string
  setActiveCollection: (val: string) => void
  addSpace?: number
}

type CollectionSectionProps = {
  activeCollection: string
  setActiveCollection: (val: string) => void
}

const SubCollections = (props: SubCollectionsProps) => {
  const { item, openSubCollections, setOpenSubCollections, addSpace, activeCollection, setActiveCollection } = props

  if (item.children?.length) {
    return (
      <div
        className={`csc_c_c_children_container${
          openSubCollections.includes(item.id) ? ' csc_c_c_children_container_active' : ''
        }`}>
        <div className='csc_c_c_cc_lists'>
          {item.children.map((subCollection) => (
            <div className='csc_c_c_cc_l_chunk_container' key={subCollection.id}>
              <div
                className={`csc_c_c_cc_l_chunk${
                  activeCollection === subCollection.id ? ' csc_c_c_cc_l_chunk_active' : ''
                }${openSubCollections.includes(subCollection.id) ? ' csc_c_c_cc_l_chunk_hightlight' : ''}`}
                style={{ paddingLeft: addSpace ? addSpace * 24 + 72 : 72 }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (subCollection.children?.length) {
                    const filterCollections = [...openSubCollections].includes(item.id)
                      ? [...openSubCollections].filter((res: string) => res !== item.id)
                      : [...openSubCollections, item.id]
                    setOpenSubCollections(filterCollections)
                  } else {
                    setActiveCollection(activeCollection === subCollection.id ? '' : subCollection.id)
                  }
                }}>
                {subCollection.children?.length ? (
                  <ChevronRightIcon
                    className={`csc_c_c_cc_h_right_icon${
                      openSubCollections.includes(item.id) ? ' csc_c_c_cc_h_right_icon_rotate' : ''
                    }`}
                  />
                ) : null}
                <p className='csc_c_c_cc_h_collection_name'>{subCollection.name}</p>
              </div>
              {subCollection.children?.length ? (
                <SubCollections
                  item={subCollection}
                  openSubCollections={openSubCollections}
                  setOpenSubCollections={setOpenSubCollections}
                  activeCollection={activeCollection}
                  setActiveCollection={setActiveCollection}
                  addSpace={addSpace ? 1 + addSpace : 1}
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    )
  }
  return null
}

const CollectionSection = observer((props: CollectionSectionProps) => {
  const { vectorsStore, userStore } = store
  const { userId, userName, tenantId, roleId } = userStore
  const { activeCollection, setActiveCollection } = props
  const { folderList, fetchFolderList, folderListLoading } = vectorsStore
  const [openCollections, setOpenCollections] = useState<string[]>([])
  const [openSubCollections, setOpenSubCollections] = useState<string[]>([])

  useEffect(() => {
    const requestParams = { vector_lib: true }
    fetchFolderList({ params: requestParams })
  }, [])

  const modifyFolders: FolderListObjType[] = useMemo(() => {
    if (folderList.items.length) {
      return constructHierarchy(folderList.items)
    }

    return []
  }, [folderList.items])
  TagManager.dataLayer({
    dataLayer: {
      event: 'navigate_library',
      //@ts-ignore
      user_id: userId,
      user_name: userName,
      tenantId: tenantId,
      roleId: roleId,
      folderId: activeCollection,
    },
  })

  return (
    <div className='collection_section_container'>
      <p className='csc_heading'>Collections</p>
      <Spin size='small' spinning={folderListLoading} wrapperClassName='csc_spinner_wrapper' className='csc_spinner'>
        <div className='csc_collections'>
          {modifyFolders.map((collection) => (
            <div
              key={collection.id}
              className={`csc_c_chunk${openCollections.includes(collection.id) ? ' csc_c_chunk_active' : ''}${
                activeCollection === collection.id ? ' csc_c_chunk_highlight' : ''
              }`}>
              <div
                className='csc_c_c_heading_container'
                onClick={() => {
                  if (collection.children?.length) {
                    setOpenCollections((prevState) =>
                      prevState.includes(collection.id)
                        ? prevState.filter((res) => res !== collection.id)
                        : [...prevState, collection.id],
                    )
                  } else {
                    setActiveCollection(activeCollection === collection.id ? '' : collection.id)
                  }
                }}>
                <div className='csc_c_c_hc_header'>
                  {collection.children?.length ? <ChevronRightIcon className='csc_c_c_hc_h_right_icon' /> : null}
                  {Icons[`${collection.name.toLowerCase()}`] || Icons['relevance']}
                  {/* <HashIcon className='csc_c_c_hc_h_collection_icon' /> */}
                  <p className='csc_c_c_hc_h_collection_name'>{collection.name}</p>
                </div>
              </div>

              {collection.children?.length ? (
                // <SubCollections item={collection} />
                <div
                  className={`csc_c_c_children_container ${
                    openCollections.includes(collection.id) ? ' csc_c_c_children_container_active' : ''
                  }`}>
                  <div className='csc_c_c_cc_lists'>
                    {collection.children.map((subCollection) => (
                      <div className='csc_c_c_cc_l_chunk_container' key={subCollection.id}>
                        <div
                          className={`csc_c_c_cc_l_chunk${
                            activeCollection === subCollection.id ? ' csc_c_c_cc_l_chunk_active' : ''
                          }${openSubCollections.includes(subCollection.id) ? ' csc_c_c_cc_l_chunk_hightlight' : ''}`}
                          onClick={(e) => {
                            e.stopPropagation()
                            if (subCollection.children?.length) {
                              const filterCollections = [...openSubCollections].includes(subCollection.id)
                                ? [...openSubCollections].filter((res: string) => res !== subCollection.id)
                                : [...openSubCollections, subCollection.id]
                              setOpenSubCollections(filterCollections)
                            } else {
                              setActiveCollection(activeCollection === subCollection.id ? '' : subCollection.id)
                            }
                          }}>
                          {subCollection.children?.length ? (
                            <ChevronRightIcon
                              className={`csc_c_c_cc_h_right_icon${
                                openSubCollections.includes(subCollection.id) ? ' csc_c_c_cc_h_right_icon_rotate' : ''
                              }`}
                            />
                          ) : null}
                          <p className='csc_c_c_cc_h_collection_name'>{subCollection.name}</p>
                        </div>
                        {subCollection.children?.length ? (
                          <SubCollections
                            item={subCollection}
                            openSubCollections={openSubCollections}
                            setOpenSubCollections={setOpenSubCollections}
                            activeCollection={activeCollection}
                            setActiveCollection={setActiveCollection}
                            addSpace={1}
                          />
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </Spin>
    </div>
  )
})

export default CollectionSection
