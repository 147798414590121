import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

import {
  IAttentionFlagsMenu,
  ICOMMUNITY,
  ICommunity,
  INARRATIVE,
  INarrative,
  IExecutiveData,
  IThreatAttentionFlagsCategoryInfo,
  IThreatAttentionFlagsDataListView,
  IThreatDataPosts,
  IThreatRisksDataCategoryInfo,
  IThreatRisksDataListView,
  IThreatTopic,
  IThreatTopicCategories,
  IVideo,
} from 'models/models'
import millify from 'millify'

function getVideos(source: IVideo[]) {
  return source?.map((item: IVideo) => ({
    col1: {
      ...item,
    },

    key: uuidv4(),
  }))
}

function getNarratives(source: INarrative[]) {
  return source?.map((item: INarrative) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
      isDraft: item.isDraft,
      isMLGenerated: item.isMLGenerated,
      alertId: item.alertId,
    },
    col2: { tags: item?.tags, itemID: item.id },
    col3: {
      creatorImage: item.creatorImage,
      creatorName: item.creatorName,
    },
    col4: {
      dateLastModified: item.dateLastModified,
    },

    editColumn: {
      id: item.id,
      name: item.name,
      alertId: item.alertId,
    },
    key: uuidv4(),
  }))
}

const getCommunities = (source: ICommunity[]) => {
  return source?.map((item: ICommunity) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: { tags: item?.tags, itemID: item.id },
    col3: {
      creatorImage: item.creatorImage,
      creatorName: item.creatorName,
      key: 'owner_id',
    },
    col4: {
      dateCreated: item.dateCreated,
    },

    editColumn: {
      id: item.id,
    },
    key: uuidv4(),
  }))
}

const getExecutive = (source: IExecutiveData[]) => {
  return source.map((person: IExecutiveData) => {
    return {
      col1: {
        id: person.id,
        name: person.name,
        avatar: person.avatar_url,
      },
      col2: { postsMillify: millify(person.posts || 0) },
      col3: { dataPoints: person.trendLine },
      col4: { warnings: person.warnings },
    }
  })
}

const getAttentionFlags = (source: IAttentionFlagsMenu[]) => {
  return source?.map((item: IAttentionFlagsMenu) => {
    return {
      name: item.name,
      metadata: item.insightType[0] + item.insightType.substring(1).toLowerCase(),
      applied: item.tenantIds,
      actions: {
        id: item.id,
      },
      key: uuidv4(),
    }
  })
}

type tableType = 'narrative' | 'community' | 'watchlist' | 'videos' | 'channel' | 'executive' | 'flags'

export function getTableData({ source, table }: { source: any; table: tableType }) {
  switch (table) {
    case 'narrative':
      return getNarratives(source)

    case 'community':
      return getCommunities(source)

    case 'videos':
      return getVideos(source)

    case 'executive':
      return getExecutive(source)

    case 'flags':
      return getAttentionFlags(source)
  }
}

export const getRiskWand = (
  source: IThreatTopic,
  attentionFlags: IThreatAttentionFlagsDataListView,
  risks: IThreatRisksDataListView,
  posts: IThreatDataPosts,
  selectedFlag: { name: string; type: string },
) => {
  const keys = Object.keys(source)
  const data: any = []
  const flagsData = selectedFlag.type === 'attention' && selectedFlag.name.length > 0 ? attentionFlags : risks

  keys?.forEach((key: string) => {
    const topic = source[key]
    let flagsCount = 0

    if (!selectedFlag.name.length) {
      if (attentionFlags && Object.keys(attentionFlags).length > 0) {
        const attentionFlagsData = attentionFlags[key]
        if (attentionFlagsData && Object.keys(attentionFlagsData).length > 0) {
          const flagsKeys = Object.keys(attentionFlagsData)
          flagsKeys?.forEach((key) => {
            const category = attentionFlagsData[key]
            const categoryKeys = Object.values(category)
            let filteredData = categoryKeys?.filter((value) => value?.noOfPosts && value?.noOfPosts > 0)
            flagsCount += filteredData.length
          })
        }
      }
      if (risks && Object.keys(risks).length > 0) {
        const risksData = risks[key]
        if (risksData && Object.keys(risksData).length > 0) {
          const flagsKeys = Object.keys(risksData)
          flagsKeys?.forEach((key) => {
            const category = risksData[key]
            const categoryKeys = Object.values(category)
            let filteredData = categoryKeys?.filter((value) => value?.noOfPosts && value?.noOfPosts > 0)
            flagsCount += filteredData.length
          })
        }
      }
    } else {
      if (flagsData && Object.keys(flagsData).length > 0) {
        const flagsDataObj = flagsData[key]
        if (flagsDataObj && Object.keys(flagsDataObj).length > 0) {
          const flagsKeys = Object.keys(flagsDataObj)
          flagsKeys?.forEach((key) => {
            const category = flagsDataObj[key]
            const categoryKeys = Object.values(category)
            let filteredData = categoryKeys?.filter(
              (value) => value.name === selectedFlag.name && value?.noOfPosts && value?.noOfPosts > 0,
            )
            flagsCount += filteredData.length
          })
        }
      }
    }

    data.push({
      topic: topic.label,
      line: {
        key: key,
        metricValues: topic.metricValues,
      },
      posts: {
        key: key,
        topic: topic,
        noOfPosts: millify(topic.noOfPosts || 0) || 0,
      },
      flags: { key: key, flagsCount },
      key: key,
    })
  })
  return data
}

export const getRiskWandCategoriesTableData = (
  source: IThreatTopicCategories[],
  attentionFlags: IThreatAttentionFlagsCategoryInfo,
  risks: IThreatRisksDataCategoryInfo,
  selectedFlag: { name: string; type: string },
  activeTopic: string,
) => {
  return source?.map((category) => {
    const isSelectedFlag = selectedFlag.name.length > 0
    let flagObj: any = {}
    let risksData
    let attentionFlagsData

    if (isSelectedFlag) {
      flagObj[selectedFlag.name] =
        selectedFlag.type === 'attention'
          ? attentionFlags && attentionFlags[category.name]
            ? attentionFlags[category.name][selectedFlag.name] &&
              (attentionFlags[category.name][selectedFlag.name]?.noOfPosts || 0) > 0
              ? attentionFlags[category.name][selectedFlag.name]
              : {}
            : {}
          : risks && risks[category.name]
          ? risks[category.name][selectedFlag.name] && (risks[category.name][selectedFlag.name]?.noOfPosts || 0) > 0
            ? risks[category.name][selectedFlag.name]
            : {}
          : {}
    } else {
      if (attentionFlags) attentionFlagsData = attentionFlags[category.name]
      if (risks) risksData = risks[category.name]
    }

    if (isSelectedFlag && !Object.keys(flagObj[selectedFlag.name]).length) {
      return {}
    }

    return {
      category: category.label,
      line: category.metricValues,
      posts: {
        topic: activeTopic,
        category: category,
        noOfPosts: millify(category.noOfPosts || 0),
      },
      flags: {
        attentionFlags: selectedFlag.type === 'attention' && isSelectedFlag ? flagObj : attentionFlagsData || {},
        risks: selectedFlag.type === 'risk' && isSelectedFlag ? flagObj : risksData || {},
        categoryName: category.name || '',
      },
      key: category.name,
    }
  })
}

export function getTagDetailsData({ source, type }: { source: INARRATIVE[] | ICOMMUNITY[]; type: string }) {
  switch (type) {
    case 'narrative':
      return getDetailedNarrativeTags(source)
    case 'community':
      return getDetailedCommunityTags(source)
    case 'post':
      return getDetailedPostTags(source)
    case 'creator':
      return getDetailedCreatorTags(source)
  }
}

const getDetailedNarrativeTags = (source: INARRATIVE[]) => {
  return source?.map((item: INARRATIVE) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: item.created_by,
    col3: dayjs(item.creation_timestamp).format('MM/DD/YYYY'),
    col4: { id: item.id, type: 'narrative' },
  }))
}

const getDetailedCommunityTags = (source: ICOMMUNITY[]) => {
  return source?.map((item: ICOMMUNITY) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: item.created_by,
    col3: dayjs(item.creation_timestamp).format('MM/DD/YYYY'),
    col4: { id: item.id, type: 'community' },
  }))
}
const getDetailedPostTags = (source: any[]) => {
  return source?.map((item: any) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: item.created_by,
    col3: dayjs(item.creation_timestamp).format('MM/DD/YYYY'),
    col4: { id: item.id, type: 'narrative' },
  }))
}
const getDetailedCreatorTags = (source: any[]) => {
  return source?.map((item: any) => ({
    col1: {
      id: item.id,
      name: item.name,
      description: item.description,
    },
    col2: item.created_by,
    col3: dayjs(item.creation_timestamp).format('MM/DD/YYYY'),
    col4: { id: item.id, type: 'narrative' },
  }))
}
