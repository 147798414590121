import React from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as SuccessIcon } from 'assets/images/icons/toaster/success_icon.svg'
import './Toaster.scss'

type IconsObjType = {
  success: React.ReactNode
  error: React.ReactNode
  info: React.ReactNode
  warning: React.ReactNode
}

const icons: IconsObjType = {
  success: (
    <div className='tc_icon_container tc_success_icon_container'>
      <SuccessIcon className='tc_icon tc_success_icon' />
    </div>
  ),
  error: (
    <div className='tc_icon_container tc_error_icon_container'>
      <SuccessIcon className='tc_icon tc_error_icon' />
    </div>
  ),
  info: (
    <div className='tc_icon_container tc_info_icon_container'>
      <SuccessIcon className='tc_icon tc_info_icon' />
    </div>
  ),
  warning: (
    <div className='tc_icon_container tc_warning_icon_container'>
      <SuccessIcon className='tc_icon tc_warning_icon' />
    </div>
  ),
}

const Toaster = observer(() => {
  const { toasterStore } = store
  const { toasterInfo } = toasterStore

  return (
    <div className='toaster_container'>
      {icons[toasterInfo.iconType]}
      <p className='tc_text'>{toasterInfo.message}</p>
    </div>
  )
})

export default Toaster
