import { Heading } from 'components/common/Heading/Heading'

import './ResetPasswordTitle.scss'

export const ResetPasswordTitle = ({ children }: { children: any }) => {
  return (
    <Heading level='1' variant='2' className='c-reset-password-title'>
      {children}
    </Heading>
  )
}
