import { IRole } from 'models/models'

export const ROLES: IRole[] = [
  {
    id: 'ADMIN',
    role: 'ADMIN',
  },
  {
    id: 'PENDULUM_ADMIN',
    role: 'PENDULUM_ADMIN',
  },
  {
    id: 'USER',
    role: 'USER',
  },
  {
    id: 'VIEWER',
    role: 'VIEWER',
  },
  {
    id: 'SCORECARD_USER',
    role: 'SCORECARD_USER',
  },
  {
    id: 'DEPLOYMENT_STRATEGIST',
    role: 'DEPLOYMENT_STRATEGIST',
  },
  {
    id: 'BUILDER',
    role: 'BUILDER',
  },
  {
    id: 'ANALYST',
    role: 'ANALYST',
  },
]
