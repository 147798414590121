import { observer } from 'mobx-react-lite'

import './CardView.scss'

interface Props {
  className?: string
  style?: any
  children: JSX.Element | JSX.Element[] | string
}

export const CardView = observer(({ children }: Props) => {
  return (
    <div className='card-view'>
      <div className='card-view__body'>
        <div className='card-view__container'>{children}</div>
      </div>
    </div>
  )
})
