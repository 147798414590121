import { observer } from 'mobx-react-lite'
import { TenantNavigation } from './TenantNavigation/TenantNavigation'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import './Tenants.scss'

export const Tenants = observer(() => {
  const location = useLocation()
  const subNavigation = ['list', 'configuration']
  const containsSubnav = subNavigation.some((el) => location.pathname.includes(el))

  return (
    <div className='tenants'>
      {!containsSubnav && <Navigate to='list' />}
      <TenantNavigation />
      <Outlet />
    </div>
  )
})
