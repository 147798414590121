import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

export async function listWhiteboards(query: string = '', sort: string = '', page: number = 0, perPage: number = 20) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: ROUTES.whiteboard,
      baseURL: process.env.REACT_APP_API,
      params: {
        ...(query && { q: query }),
        ...(sort && { sort: sort }),
        per_page: perPage,
        page: page,
      },
    })

    return { data }
  } catch (error: any) {
    LogService.error({ message: 'listWhiteboards', error })
    return { data: null, error: error.response?.data?.err_msg }
  }
}

export async function getWhiteboardById(whiteboardId: string, version: number = 0) {
  try {
    const { data } = await axios({
      method: 'GET',
      url: ROUTES.whiteboardById(whiteboardId),
      baseURL: process.env.REACT_APP_API,
      ...(version > 0 && { params: { version: version } }),
    })

    return { data }
  } catch (error: any) {
    LogService.error({ message: 'getWhiteboardById', error })
    return { data: null, error: error.response?.data?.message }
  }
}
