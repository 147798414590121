import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'
import { SnippetFilterPayload } from 'models/models'

interface Props extends SnippetFilterPayload {
  dimension?: string
}

export async function getMetricsUsingDimension({ page = 1, per_page = 1, query, dimension, sort, conditions }: Props) {
  try {
    const dimensionQuery = dimension ? `&dimension=${dimension}` : ''
    const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}${dimensionQuery}`

    // construct the query params string
    const queryParams = `q=${q}&sort=${sort}&per_page=${per_page}&page=${page}`

    // send the actual HTTP request
    const url = process.env.REACT_APP_SEARCH_API + `${ROUTES.executiveMetrics}?${queryParams}`
    const data = await axios.post(url, { conditions })

    return data?.data
  } catch (error) {
    LogService.error({ message: 'getExecutiveMetrics.api error', error })
  }
}
