import { ReactElement } from 'react'
import { Button as AntdButton } from 'antd'
import classNames from 'classnames'

import './Button.scss'

export const Button = ({
  type,
  children,
  labelIcon,
  size = 'large',
  disabled = false,
  iconPosition,
  icon,
  ...rest
}: {
  type: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'tertiary-grayed'
  children?: React.ReactNode
  labelIcon?: 'X' | 'V'
  size?: 'small' | 'large'
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  icon?: ReactElement
  [x: string]: any
}) => {
  const classObj: { [x: string]: boolean } = {}
  classObj[`custom-button-disabled--${type}`] = disabled

  return (
    <AntdButton
      disabled={disabled}
      {...rest}
      className={classNames(
        'custom-button',
        `custom-button--${type}`,
        `custom-button--${size}`,
        { ...rest }?.className,
        classObj,
      )}>
      <div className='custom-button__content'>
        {iconPosition && <div>{iconPosition === 'left' && icon}</div>}
        <div className='custom-button__content-children'>{children}</div>
        {iconPosition && <div>{iconPosition === 'right' && icon}</div>}
      </div>
    </AntdButton>
  )
}
