import millify from 'millify'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

import { store } from 'store'

import { getDaysDifference } from 'services/Util/getDaysDifference'

import { SubStore } from 'types/types'
import { Unknown } from 'utils/commonTypes'
import { ConditionsDataType, FlagsCountType, NarrativeListDataType } from 'types/types'

type RenderALLPostType = {
  listItemNumber: number
  subStore: SubStore
  itemData: NarrativeListDataType
}

const SearchStats = observer((props: RenderALLPostType) => {
  const { itemData, listItemNumber = 1 } = props
  const { fetchInsightsMetrics, getDate, snippetsFilter } = store[`${props.subStore}Store`]

  const [postsCount, setPostsCount] = useState<FlagsCountType>({
    currentFlagCount: null,
    prevFlagCount: null,
  })

  const renderConditions = useMemo(() => {
    return [{ narratives: [itemData?.narrative_number] }]
  }, [itemData])

  const insightsMetricsHandler = async () => {
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
    let prevStartDate = new Date(startDate)
    let prevEndDate = new Date(startDate)
    prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
    prevEndDate.setDate(prevEndDate.getDate() - 1)
    const requestParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
    }
    if (renderConditions.length) {
      const requestData: ConditionsDataType = {
        conditions: renderConditions as Unknown[],
      }

      const [currentFlagCount] = await Promise.all([fetchInsightsMetrics({ params: requestParams, data: requestData })])
      setPostsCount({
        currentFlagCount: currentFlagCount === 'error' ? 0 : currentFlagCount,
        prevFlagCount: 0,
      })
    } else {
      setPostsCount({
        currentFlagCount: null,
        prevFlagCount: null,
      })
    }
  }

  useEffect(() => {
    insightsMetricsHandler()
  }, [renderConditions, snippetsFilter.days])

  return (
    <div className='searches__listing__item'>
      <div className='searches__listing__item__count'>{listItemNumber}</div>
      <div className='searches__listing__item__content'>
        <div className='searches__listing__item__content__label'>{itemData.name}</div>
        <div className='searches__listing__item__content__value'>
          {millify(postsCount.currentFlagCount || 0)} snippets in last 24 hours
        </div>
      </div>
    </div>
  )
})

export default SearchStats
