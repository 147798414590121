import { IUniqueContent } from 'models/models'

import YoutubeIcon from 'assets/images/youtube-icon.svg'
import TwitterIcon from 'assets/images/twitter-x-icon-current-color.svg'
import PodcastIcon from 'assets/images/podcast-icon.svg'
import RumbleIcon from 'assets/images/rumble-icon.svg'
import BitchuteIcon from 'assets/images/bitchute-icon.svg'

import './CreateNarrativeUniqueContent.scss'

interface Props {
  item: IUniqueContent
}

const CreateNarrativeUniqueContent = ({ item }: Props) => {
  const isTitleTooLong = item.title.length > 55
  const isWebsiteTooLong = item.website.length > 55
  const isLinkTooLong = item.link.length > 55

  const iconsObj = {
    YouTube: YoutubeIcon,
    Twitter: TwitterIcon,
    Podcast: PodcastIcon,
    Rumble: RumbleIcon,
    BitChute: BitchuteIcon,
  }

  //@ts-ignore
  const image = item.website === 'News' ? item.imgLink : iconsObj[item.website]

  return (
    <div className='c-define-narrative-unique-content'>
      <img className='c-define-narrative-unique-content__image' alt={''} src={image} />
      <a className='c-define-narrative-unique-content__text' href={item.link} target='_blank' rel='noreferrer'>
        <p className='c-define-narrative-unique-content__text-title'>
          {isTitleTooLong ? `${item.title.slice(0, 55)}...` : item.title}
        </p>
        <p className='c-define-narrative-unique-content__text-website'>
          {isWebsiteTooLong ? `${item.website.slice(0, 55)}...` : item.website}
        </p>
        <p className='c-define-narrative-unique-content__text-website-link'>
          {isLinkTooLong ? `${item.link.slice(0, 55)}...` : item.link}
        </p>
      </a>
    </div>
  )
}

export default CreateNarrativeUniqueContent
