import axios, { AxiosResponse } from 'axios'

export async function fetchDefaultFile(): Promise<AxiosResponse<string>> {
  const endpoint = `${process.env.REACT_APP_DOCS_API}docs?file=data_backend_api_swagger.yaml`
  try {
    const response: AxiosResponse<string> = await axios.get(endpoint)
    if (!response || !response.data) {
      throw new Error('Failed to fetch YAML')
    }
    return response
  } catch (error) {
    throw error
  }
}

export async function fetchSelectedFile(selectedFile: string): Promise<AxiosResponse<string>> {
  const endpoint = `${process.env.REACT_APP_DOCS_API}docs?file=${selectedFile}`
  try {
    const response: AxiosResponse<string> = await axios.get(endpoint)
    if (!response || !response.data) {
      throw new Error('Failed to fetch YAML')
    }
    return response
  } catch (error) {
    throw error
  }
}
