import { Skeleton } from 'antd'
import { FlagListObjType } from 'store/flags/types'
import issueImage from 'assets/images/icons/dashboard/issue.png'
import millify from 'millify'
import Summary from './Summary'
import './FollowedIssues.scss'

export default function IssueCard({
  emptyState = false,
  flag,
  onSelectFlag,
  subStore = 'assets',
}: {
  emptyState?: boolean
  flag?: FlagListObjType | undefined
  onSelectFlag: (flag: FlagListObjType) => void
  subStore?: 'assets' | 'vectors'
}) {
  if (emptyState || !flag) {
    return (
      <div className='followed-issue-card'>
        <Skeleton.Image className='skeleton-image' active />
        <div className='summary'>
          <h6 className='skeleton-title'>
            <Skeleton paragraph active />
          </h6>
          <p className='skeleton-description'>
            <Skeleton paragraph active />
          </p>
        </div>
        <div className='stats'>
          <div className='item'>
            <h6>Mentions</h6>
            <p className='skeleton-stats-item'>
              <Skeleton paragraph active />
            </p>
          </div>
          <div className='item'>
            <h6>Impressions</h6>
            <p className='skeleton-stats-item'>
              <Skeleton paragraph active />
            </p>
          </div>
          <div className='item'>
            <h6>Engagment</h6>
            <p className='skeleton-stats-item'>
              <Skeleton paragraph active />
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='followed-issue-card' onClick={() => onSelectFlag(flag)}>
      <div className='img-wrapper'>
        <img src={issueImage} alt='Issue' className='img' />
      </div>
      <div className='summary'>
        <h6 className='title'>{flag.name}</h6>
        <Summary flag={flag} subStore={subStore} />
      </div>
      <div className='stats'>
        <div className='item'>
          <h6>Mentions</h6>
          <p>{millify(flag.metric.views)}</p>
        </div>
        <div className='item'>
          <h6>Impressions</h6>
          <p>{millify(flag.metric.impressions)}</p>
        </div>
        <div className='item'>
          <h6>Engagment</h6>
          <p>{millify(flag.metric.engagement)}</p>
        </div>
      </div>
    </div>
  )
}
