import React from 'react'
import './Input.scss'

type InputProps = {
  [key: string]: any
  className?: string
  title?: string
  type?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
}

function Input(props: InputProps) {
  const { className = '', title, value, type = 'text', onChange, ...rest } = props
  return (
    <div className={`input_container ${className}`}>
      {title ? <p className='ic_title'>{title}</p> : null}
      <input className='ic_input' value={value} type={type} onChange={onChange} {...rest} />
    </div>
  )
}

export default Input
