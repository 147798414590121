import html2canvas from 'html2canvas'

const downloadHtmlAsImage = (id: string, contentScrollable: boolean) => {
  const element: HTMLElement | null = document.querySelector(id)
  if (!element) return

  if (contentScrollable) {
    element.style.width = 'min-content'
  }

  html2canvas(element, {
    ignoreElements: (element) => {
      return element.classList.contains('download-icon')
    },
  })
    .then((canvas) => {
      let imgData = canvas.toDataURL('image/png')

      let link = document.createElement('a')
      link.href = imgData
      link.download = 'captured-image.png' // Filename for the download

      document.body.appendChild(link)
      link.click()
      if (contentScrollable) {
        element.style.width = '100%'
      }
    })
    .catch((error) => console.log('error', error))
}

export default downloadHtmlAsImage
