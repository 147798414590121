import { observer } from 'mobx-react-lite'
import { BarChart, XAxis, YAxis, Bar, ReferenceLine, LabelList, Cell } from 'recharts'
import classNames from 'classnames'

import './BarChart.scss'

interface Props {
  width?: number
  height?: number
  data?: any[] | undefined
  selectedCell: string
  onClickCell: (data: any) => void
}
export const CustomBarChart = observer((props: Props) => {
  const { data, width, height, selectedCell, onClickCell } = props

  const renderCustomLabel = (props: any) => {
    const { x, y, width, name } = props
    const category = data?.filter((item) => item.name === name)
    if (category?.length) {
      const value = Number(category[0]?.total_value || 0)
      if (!value) return <></>
      else
        return (
          <text x={x + width + 16} y={y} dy={15} className='custom-bar-chart__total-count-text'>
            {value.toLocaleString() || ''}
          </text>
        )
    } else return <></>
  }

  const getMagnitude = (num: number) => {
    if (num === 0 || !Number.isFinite(num)) return 0
    else {
      const length = num.toString().length
      if (length > 0) {
        const magnitude = '1' + '0'.repeat(length - 1)
        return Number(magnitude)
      } else return 0
    }
  }

  const getReferenceLinesAlongYAxis = () => {
    return data?.map((entry, index) => {
      return (
        <ReferenceLine
          stroke='#D0D5DD'
          position='middle'
          strokeDasharray='3 3'
          xAxisId={'negative'}
          segment={[
            {
              x: -58,
              y: data[index].name,
            },
            {
              x: -58,
              y: data[index - 1 > -1 ? index - 1 : 0].name,
            },
          ]}
        />
      )
    })
  }

  const getReferenceLinesAlongXAxis = () => {
    return data?.map((entry, index) => {
      if (!entry.name?.length) return <></>
      return (
        <ReferenceLine
          key={`refLine-${index}`}
          stroke='#D0D5DD'
          position='middle'
          strokeDasharray='3 3'
          xAxisId={'negative'}
          segment={[
            {
              x: entry.total_value - getMagnitude(entry.total_value),
              y: entry.name,
            },
            {
              x: -58,
              y: entry.name,
            },
          ]}
        />
      )
    })
  }

  return (
    <BarChart
      width={width}
      height={height}
      layout={'vertical'}
      margin={{ right: 20, left: 20 }}
      data={data}
      style={{ marginTop: -31 }}>
      <XAxis
        type='number'
        dataKey='total_value'
        padding={{ left: 48 }}
        domain={[0, (dataMax: number) => dataMax + (getMagnitude(dataMax) || 100)]}
        allowDataOverflow
        hide
      />
      <XAxis
        xAxisId={'negative'}
        type='number'
        dataKey='total_value'
        domain={[-58, (dataMax: number) => dataMax + (getMagnitude(dataMax) || 100)]}
        hide
      />
      <YAxis type='category' dataKey='name' hide />

      {getReferenceLinesAlongXAxis()}
      {getReferenceLinesAlongYAxis()}

      <Bar
        dataKey='total_value'
        label={renderCustomLabel}
        barSize={18}
        onClick={(data: any) => {
          onClickCell(data)
        }}>
        {data?.map((entry, index) => (
          <Cell
            className={classNames('custom-bar-chart__cell', {
              'custom-bar-chart__cell__selected': selectedCell === entry.name,
            })}
            radius={4}
            cursor='pointer'
            key={`cell-${index}`}
          />
        ))}
        <LabelList
          content={(props) => {
            const { x, y, name } = props
            return (
              <g>
                <text x={x} y={Number(y) - 12} textAnchor='start' className='custom-bar-chart__bar-title'>
                  {name}
                </text>
              </g>
            )
          }}
        />
      </Bar>
    </BarChart>
  )
})
