import './LoadingOverlay.scss'
type LoadingOverlayProps = {
  className?: string
  description: string
}
export default function LoadingOverlay(props: LoadingOverlayProps) {
  const { className, description } = props
  return (
    <div className={`loading-overlay ${className}`}>
      <p className='header'>Please wait ...</p>
      <p className='body'>{description}</p>
    </div>
  )
}
