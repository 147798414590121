import { getRandomColor } from './getRandomColor'
import { sourcePlatform } from 'models/models'

export const getPlatformColor = (platform: sourcePlatform | Lowercase<sourcePlatform>) => {
  let color = ''

  switch (platform) {
    case 'twitter':
    case 'Twitter':
      color = '#F2DF6B'
      break

    case 'youtube':
    case 'YouTube':
      color = '#F9A978'
      break

    case 'tiktok':
    case 'TikTok':
      color = '#5E9BAE'
      break

    case 'podcast':
    case 'Podcast':
      color = '#87C3A1'
      break

    case 'news':
    case 'News':
      color = '#FFAADD'
      break

    case 'bitchute':
    case 'BitChute':
      color = '#BDCFB1'
      break

    case 'rumble':
    case 'Rumble':
      color = '#FFD3CD'
      break

    case 'reddit':
    case 'Reddit':
      color = '#5F384C'
      break

    case '4chan':
      color = '#858F87'
      break

    case 'gab':
    case 'Gab':
      color = '#9E90A8'
      break

    case 'patriots.win':
    case 'Patriots.win':
      color = '#D59DA0'
      break

    case 'truthsocial':
    case 'TruthSocial':
      color = '#D8B08F'
      break

    case 'vk':
      color = '#0077FF'
      break

    case 'instagram':
    case 'Instagram':
      color = '#FCC6B9'
      break

    case 'threads':
    case 'Threads':
      color = '#A3C9E8'
      break

    case 'telegram':
    case 'Telegram':
      color = '#C5DED5'
      break

    case 'c-span':
    case 'C-SPAN':
      color = '#001a72'
      break

    case 'snapchat':
    case 'Snapchat':
      color = '#FFFC00'
      break

    case 'Odysee':
    case 'odysee':
      color = '#CC2C53'
      break

    default:
      color = getRandomColor()
  }

  return color
}
