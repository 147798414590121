import { useEffect, useState } from 'react'
import millify from 'millify'

import { API } from 'api/api'
import { IVideoBubble, ICreator } from 'models/models'
import { UtilService } from 'services/Util/Util'

import './BeehiveChartPopup.scss'

interface Props {
  data: IVideoBubble
}

export const BeehiveChartPopup: React.FC<Props> = ({ data }) => {
  const [channel, setChannel] = useState<ICreator>()

  const { channel_id, post_title, date, post_id: videoId, postViewsMillify, platform, media_url: mediaUrl } = data

  const videoIframe = UtilService.getVideoFrame({ mediaUrl, platform, videoId })

  useEffect(() => {
    const getChannelsInfo = async () => {
      const { data: channelData } = await API.get({
        route: 'channel',
        customAPI: process.env.REACT_APP_SEARCH_API,
        ids: channel_id,
      })

      setChannel(channelData.items[0])
    }

    getChannelsInfo()
  }, [channel_id])

  return (
    <div className='c-bubble-tip'>
      <div className='c-bubble-tip__left'>{videoIframe}</div>

      <div className='c-bubble-tip__right'>
        <h3 className='c-bubble-tip__title'>{post_title}</h3>

        <p className='c-bubble-tip__subtitle'>
          <strong>{postViewsMillify !== '0' ? postViewsMillify : 'N/A'}</strong> views | {platform} | {date}
        </p>

        {channel && (
          <p className='c-bubble-tip__channel'>
            <img className='c-bubble-tip__img' src={channel.channel_thumb_url} alt='channel thumbnail' />

            <span className='c-bubble-tip__channel-aside'>
              <span className='c-bubble-tip__channel-title'>{channel.channel_title || channel.channel_name}</span>
              <strong>{channel.followers_count !== -1 ? millify(channel.followers_count!) : 'N/A'}</strong> subscribers
            </span>
          </p>
        )}
      </div>
    </div>
  )
}
