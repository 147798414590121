import { API, reportsAPI, flagsAPI, monitorAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'

import { UtilService } from 'services/Util/Util'
import {
  KeyValue,
  ListsObj,
  //  ReportAPIType,
  ReportRoutesType,
  UpdateShareListObj,
  ApiMethodType,
  reportDetailsInstanceObj,
  ReportFieldParams,
  ReportFeedsData,
  CreateReportObj,
  ReportSectionFeedsObj,
  ReportFeedsFeedback,
  ReportFeedsFilters,
  ShareReportEndpoint,
  ReportSummaryHistoryObj,
  ResponseListType,
  UserListResponse,
  ShareUsersResponse,
} from './valueTypes'
import { ROUTES } from 'settings/settings'
import millify from 'millify'
import { ICommunityLight, ICreator } from 'models/models'
import { ConditionsPayloadType, NarrativeParamsType } from 'types/types'
import { AssetListObjType, AssetListParamsType, ConditionsPayloadParamsForAssetType } from 'store/asset/types'
import TagManager from 'react-gtm-module'
import { uniqBy } from 'lodash'

export class ReportMainViewStore {
  reports: ResponseListType = {
    total_count: 0,
    items: [],
  }

  watchlistsList: ResponseListType = {
    total_count: 0,
    items: [],
  }

  narrativesList: ResponseListType = {
    total_count: 0,
    items: [],
  }

  bookmarksList: ResponseListType = {
    total_count: 0,
    items: [],
  }

  assetList: ResponseListType = {
    total_count: 0,
    items: [],
  }

  users: UserListResponse = {
    total_count: 0,
    items: [],
  }
  shareUsers: ShareUsersResponse = {
    owner: {
      family_name: '',
      given_name: '',
      user_id: '',
      username: '',
    },
    shared_users: [],
    total_count: 0,
  }
  gereratingReport: boolean = false
  reportDetails: ListsObj = {
    id: '',
    tenant_id: '',
    owner_id: '',
    name: '',
    description: null,
    frequency: null,
    last_report_time: null,
    last_update_time: null,
    new_report: false,
    generating_report: false,
    report_instance_history: [],
    last_updated_by: '',
    next_report_date: null,
    created: '',
    sections: [],
    shared_users: [],
    report_type: '',
    weekly_report_day: '',
    is_enabled: false,
  }

  isLoading: boolean = false
  isLoadingSharedUser: boolean = false
  isLoadingReports: boolean = false
  isConfirmLoading: boolean = false
  isSummaryHistoryLoading: boolean = false
  isReportFeedsLoading: boolean = false
  isSendingEmail: boolean = false

  reportDetailsEntities: reportDetailsInstanceObj = {
    id: '',
    instance_id: '',
    tenant_id: '',
    owner_id: '',
    name: '',
    description: null,
    frequency: null,
    next_report_date: null,
    created: '',
    last_update_time: null,
    last_updated_by: '',
    start_date: '',
    end_date: '',
    sections: [],
    shared_users: [],
    read_time_txt: '',
    report_type: '',
    weekly_report_day: '',
  }

  reportSummaryHistory: ReportSummaryHistoryObj = {
    total_count: 0,
    items: [],
  }

  reportSectionFeeds: ReportSectionFeedsObj = { total_count: 0, items: [] }
  trendsDataPoints = {
    posts: { all: [], sentiment: [], platform: [] },
    impressions: { all: [], sentiment: [], platform: [] },
  }

  feedsPayload: ReportFeedsData = {
    conditions: [],
  }
  feedsFilters: ReportFeedsFilters = {
    risk_score: [],
  }
  loadedCreatorIds: string[] = []
  loadedCreators: ICreator[] = []
  liteCommunities: ICommunityLight[] = []
  currPage: number = 1
  prevPage: number = 0
  feedSearch: string = ''
  searchInputFeeds: string = ''
  bookmarksLoading: boolean = false

  constructor() {
    makeObservable(this, {
      watchlistsList: observable,
      narrativesList: observable,
      bookmarksList: observable,
      assetList: observable,
      reports: observable,
      users: observable,
      setUsers: action.bound,
      setUsersWithParams: action.bound,
      setShareUsers: action.bound,
      shareUsers: observable,
      reportDetails: observable,
      reportDetailsEntities: observable,
      reportSummaryHistory: observable,
      setLists: action.bound,
      updateSharedList: action.bound,
      fetchAllLists: action.bound,
      fetchReportDetailsAndSections: action.bound,
      fetchReportSummaryHistory: action.bound,
      fetchUsersAndShareList: action.bound,
      getReports: action.bound,
      getUsersList: action.bound,
      updateReportHandler: action.bound,
      isLoadingSharedUser: observable,
      setIsLoadingSharedUser: action.bound,
      isLoading: observable,
      setLoading: action.bound,
      getShareReportList: action.bound,
      isLoadingReports: observable,
      setLoadingReports: action.bound,
      isConfirmLoading: observable,
      setIsConfirmLoading: action.bound,
      isSummaryHistoryLoading: observable,
      setIsSummaryHistoryLoading: action.bound,
      isReportFeedsLoading: observable,
      setIsReportFeedsLoading: action.bound,
      reportSectionFeeds: observable,
      fetchReportFeedsHandler: action.bound,
      trendsDataPoints: observable,
      fetchAllTrendsList: action.bound,
      feedsPayload: observable,
      setFeedsPayload: action.bound,
      feedsFilters: observable,
      setFeedsFilters: action.bound,
      reportFeedsFeedbackHandler: action.bound,
      liteCommunities: observable,
      setCommunities: action.bound,
      currPage: observable,
      setCurrPage: action.bound,
      prevPage: observable,
      setPrevPage: action.bound,
      feedSearch: observable,
      setFeedsSearch: action.bound,
      searchInputFeeds: observable,
      setSearchInputFeeds: action.bound,
      isSendingEmail: observable,
      setIsSendingEmail: observable,
      bookmarksLoading: observable,
    })
  }
  setIsLoadingSharedUser = (value: boolean) => {
    this.isLoadingSharedUser = value
  }
  setLoading = (value: boolean) => {
    this.isLoading = value
  }
  setLoadingReports = (value: boolean) => {
    this.isLoadingReports = value
  }
  setIsConfirmLoading = (value: boolean) => {
    this.isConfirmLoading = value
  }
  setIsSummaryHistoryLoading = (value: boolean) => {
    this.isSummaryHistoryLoading = value
  }
  setIsReportFeedsLoading = (value: boolean) => {
    this.isReportFeedsLoading = value
  }

  setIsSendingEmail = (value: boolean) => {
    this.isSendingEmail = value
  }

  setLists = (key: KeyValue, lists: ResponseListType) => {
    this[key] = lists
  }

  setListsWithParams = (key: KeyValue, lists: ResponseListType) => {
    this[key] = {
      total_count: lists.total_count,
      items: uniqBy([...this[key].items, ...lists.items], (item) => item.id),
    }
  }

  setBookMarksLoading = (status: boolean) => {
    this.bookmarksLoading = status
  }

  setFeedsPayload = (payload: ReportFeedsData) => {
    this.feedsPayload = payload
  }

  setFeedsFilters = (payload: ReportFeedsFilters) => {
    this.feedsFilters = payload
  }

  setCurrPage = (num: number) => {
    this.currPage = num
  }

  setPrevPage = (num: number) => {
    this.prevPage = num
  }
  setFeedsSearch = (searchValue: string) => {
    this.feedSearch = searchValue
  }
  setUsers = (userResponse: UserListResponse) => {
    this.users = userResponse
  }

  setUsersWithParams = (lists: UserListResponse) => {
    this.users = { total_count: lists.total_count, items: [...this.users.items, ...lists.items] }
  }

  setShareUsers = (userResponse: ShareUsersResponse) => {
    this.shareUsers = userResponse
  }
  setSearchInputFeeds = (searchValue: string) => {
    this.searchInputFeeds = searchValue
  }

  getReports = async () => {
    this.setLoadingReports(true)
    try {
      const reportsList = await reportsAPI.getLists(ROUTES.reports as ReportRoutesType)
      this.setLists('reports', reportsList.data)
      return reportsList.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setLoadingReports(false)
    }
  }

  getUsersList = async () => {
    try {
      const usersList = await reportsAPI.getLists(ROUTES.listUsers as ReportRoutesType)
      this.setUsers(usersList.data)
      return usersList
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchUsersWithParams = async (params: NarrativeParamsType) => {
    try {
      const usersData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.listUsers as ReportRoutesType,
        params,
      })
      this.setUsersWithParams(usersData.data)

      return usersData.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchNarrativesWithParams = async (params: NarrativeParamsType) => {
    try {
      const narrativesData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.reportNarrative as ReportRoutesType,
        params,
      })
      this.setListsWithParams('narrativesList', narrativesData.data)

      return narrativesData.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchWatchListsWithParams = async (params: NarrativeParamsType) => {
    try {
      const watchlistsData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.reportWatchlist as ReportRoutesType,
        params,
      })
      this.setListsWithParams('watchlistsList', watchlistsData.data)

      return watchlistsData.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchBookmarksWithParams = async (params: NarrativeParamsType) => {
    this.setBookMarksLoading(true)
    try {
      const bookmarksData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.reportBookmark as ReportRoutesType,
        params,
      })
      this.setListsWithParams('bookmarksList', bookmarksData.data)

      return bookmarksData.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setBookMarksLoading(false)
    }
  }

  fetchConditionsPayload = async (params?: ConditionsPayloadParamsForAssetType | undefined) => {
    try {
      const response = await monitorAPI.getSearchPayload(params)

      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchDataForObject = async (object: AssetListObjType, type: 'flag'): Promise<AssetListObjType> => {
    // Assuming you have an API endpoint to fetch data based on object.id
    const requestParams = {
      q: `${type}_id:eq:${object.id}`,
      is_asset: true,
    }
    const conditionObj: ConditionsPayloadType | 'error' = await this.fetchConditionsPayload(requestParams)

    if (conditionObj !== 'error') {
      object.conditions = conditionObj
    } else {
      object.conditions = {}
    }
    object.mode = 'asset'
    return object
  }

  fetchAssetWithParams = async (params: AssetListParamsType) => {
    try {
      const response = await flagsAPI.getFlagsList({ params })
      const updatedItems = await Promise.all(
        response.data.items.map(async (obj: AssetListObjType) => {
          return await this.fetchDataForObject(obj, 'flag')
        }),
      )
      const assetData = { total_count: response.data.total_count, items: updatedItems }
      this.setListsWithParams('assetList', assetData)

      return assetData
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  createReportHandler = async (requestPayload: any) => {
    this.setIsConfirmLoading(true)
    try {
      const response = await reportsAPI.createReport(ROUTES.reports as ReportRoutesType, requestPayload)
      return response
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      TagManager.dataLayer({
        dataLayer: {
          event: 'create_report',
          entityName: this.reportDetails.name,
          entityType: 'report',
          user_id: this.reportDetails.owner_id,
          tenantId: this.reportDetails.tenant_id,
        },
      })
      this.setIsConfirmLoading(false)
    }
  }

  updateReportHandler = async (id: string, requestPayload: CreateReportObj) => {
    this.setIsConfirmLoading(true)
    try {
      const response = await reportsAPI.updateReport(id, requestPayload)
      this.reportDetailsEntities = response.data
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setIsConfirmLoading(false)
    }
  }

  getOrDeleteReportHandler = async (methodType: ApiMethodType, id: string, instanceId?: string, body?: any) => {
    // to hide loader for delete modal
    if (methodType === 'delete') {
      this.setIsConfirmLoading(true)
    } else {
      this.setLoading(true)
    }

    try {
      const response = await reportsAPI.getOrDeleteReport(methodType, id, instanceId, body)
      if (methodType !== 'post') {
        if (instanceId) {
          this.reportDetailsEntities = response.data
        } else if (methodType === 'get') {
          this.reportDetails = response.data
        }
      }
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setLoading(false)
      this.setIsConfirmLoading(false)
    }
  }

  getShareReportList = async (endpoint: ShareReportEndpoint, id: string) => {
    this.setLoading(true)
    try {
      const response = await reportsAPI.shareReport('get', endpoint, id)
      this.setShareUsers(response.data)
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setLoading(false)
    }
  }

  updateSharedList = async (
    methodType: ApiMethodType,
    endpoint: ShareReportEndpoint,
    id: string,
    requestPayload?: UpdateShareListObj,
  ) => {
    this.setIsConfirmLoading(true)
    try {
      if (methodType === 'post') {
        const response = await reportsAPI.shareReport(methodType, endpoint, id, requestPayload)
        return response.data
      }
      const response = await reportsAPI.shareReport(methodType, endpoint, id)
      this.setShareUsers(response.data)
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setIsConfirmLoading(false)
    }
  }

  fetchReportSummaryHistory = async (id: string, dates?: { start_date: string; end_date: string }) => {
    this.setIsSummaryHistoryLoading(true)
    try {
      const response = await reportsAPI.getReportSummary(id, dates!)
      this.reportSummaryHistory = response.data
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setIsSummaryHistoryLoading(false)
    }
  }

  fetchReportDetailsAndSections = async (id: string, instanceId?: string) => {
    this.setLoadingReports(true)
    try {
      const [reportDetails, reportDetailsSection] = await Promise.all([
        reportsAPI.getOrDeleteReport('get', id),
        reportsAPI.getOrDeleteReport('get', id, instanceId),
      ])
      this.reportDetails = reportDetails.data
      this.reportDetailsEntities = reportDetailsSection.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setLoadingReports(false)
    }
  }

  fetchUsersAndShareList = async (endpoint: ShareReportEndpoint, id: string, params: NarrativeParamsType) => {
    this.setIsLoadingSharedUser(true)
    try {
      const [usersList, shareUsers] = await Promise.all([
        reportsAPI.getListsWithParams({ endpoint: ROUTES.listUsers as ReportRoutesType, params }),
        reportsAPI.shareReport('get', endpoint, id),
      ])
      this.setUsers(usersList.data)
      this.setShareUsers(shareUsers.data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setIsLoadingSharedUser(false)
    }
  }

  // to get all the reports for the reports main page
  fetchAllLists = async (params: NarrativeParamsType, assetParams: AssetListParamsType) => {
    this.setIsConfirmLoading(true)
    try {
      const [
        // reportsList,
        watchListData,
        narrativesData,
        bookmarksData,
        assetData,
      ] = await Promise.all([
        // API.get({
        //   isPromise: true,
        //   route: ROUTES.reportWatchlist as ReportAPIType,
        // }),
        // API.get({
        //   isPromise: true,
        //   route: ROUTES.reportNarrative as ReportAPIType,
        // }),
        // API.get({
        //   isPromise: true,
        //   route: ROUTES.reportBookmark as ReportAPIType,
        // }),
        // API.get({
        //   isPromise: true,
        //   route: ROUTES.reportTag as ReportAPIType,
        // }),

        // reportsAPI.getLists(ROUTES.reports as ReportRoutesType),
        reportsAPI.getListsWithParams({ endpoint: ROUTES.reportWatchlist as ReportRoutesType, params }),
        reportsAPI.getListsWithParams({ endpoint: ROUTES.reportNarrative as ReportRoutesType, params }),
        reportsAPI.getListsWithParams({ endpoint: ROUTES.reportBookmark as ReportRoutesType, params }),
        flagsAPI.getFlagsList({ params: assetParams }),
      ])

      // this.setLists('reports', reportsList.data.items)
      this.setLists('watchlistsList', watchListData.data)
      this.setLists('narrativesList', narrativesData.data)
      this.setLists('bookmarksList', bookmarksData.data)
      this.setLists('assetList', assetData.data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setIsConfirmLoading(false)
    }
  }

  setCommunities = (communities: ICommunityLight[]) => {
    this.liteCommunities = communities
  }

  setCreators = (creators: any[]) => {
    this.loadedCreators = creators
  }

  setCreatorIds = (creatorIds: string[]) => {
    this.loadedCreatorIds = creatorIds
  }

  getCommunities = async (communitiesRequest: number[]) => {
    const checkedCommunities = communitiesRequest.filter((community, index) => {
      return communitiesRequest.indexOf(community) === index
    })

    const allreadyHave = this.liteCommunities
      .filter((community) => checkedCommunities.includes(community.id))
      .map((community) => community.id)

    const filteredCommunities = checkedCommunities.filter((community) => !allreadyHave.includes(community))

    if (filteredCommunities?.length === 0) return

    const { data } = await API.get({
      route: 'community',
      filter: `community_number:in:${JSON.stringify(filteredCommunities)}`,
    })

    const communitiesData = data?.items?.map((community: any) => {
      return { id: community.community_number, name: community?.name }
    })

    if (communitiesData) {
      this.setCommunities([...this.liteCommunities, ...communitiesData])
    }
  }

  getCreators = async (idArray: string[]) => {
    const ids = idArray.filter((id) => !this.loadedCreatorIds.includes(id))
    this.setCreatorIds([...this.loadedCreatorIds, ...ids])
    const idString = ids.join(',')

    let data

    if (idString?.length)
      ({ data } = await API.get({ route: 'channel', customAPI: process.env.REACT_APP_SEARCH_API, ids: idString }))

    if (data?.items) this.setCreators([...this.loadedCreators, ...data.items])

    const updatedReportSectionFeeds = this.reportSectionFeeds.items.map((snippet) => {
      if (snippet?.author?.channel_title || snippet?.author?.channel_name) return snippet
      const creator = this.loadedCreators.filter((creator: any) => {
        return creator.channel_id === snippet.channel_id
      })
      return { ...snippet, author: creator.length ? creator[0] : {} }
    })

    this.reportSectionFeeds = { ...this.reportSectionFeeds, items: updatedReportSectionFeeds } as ReportSectionFeedsObj

    const snippetCommunities = this.reportSectionFeeds.items.map((snippet) => {
      return snippet?.author?.communities
    })

    const communitiesRequest: number[] = []

    snippetCommunities?.forEach((communityArray) => {
      communityArray?.forEach((community) => {
        if (!communitiesRequest.includes(community)) communitiesRequest.push(community)
      })
    })

    this.getCommunities(communitiesRequest)
  }

  fetchReportFeedsHandler = async (params: ReportFieldParams, data: ReportFeedsData, addMore?: boolean) => {
    this.setIsReportFeedsLoading(true)
    try {
      const response = await reportsAPI.fetchReportFeeds(params, data)

      if (response.data?.items.length) {
        let creators: string[] = []
        const snippets = response.data.items.map((snippet: any, index: number) => {
          if (snippet.platform !== 'Twitter' && snippet.platform !== 'News') creators.push(snippet?.channel_id)

          let author: { [key: string]: any } = {}
          let extras: { [key: string]: any } = {}

          if (snippet.platform === 'Twitter') {
            author.channel_title = snippet.post_metadata?.author_name || ''
            author.channel_thumb_url = snippet.post_metadata?.profile_image_url || ''

            extras.retweets = snippet.share_count || 0
            extras.likes = snippet.like_count || 0
            extras.comments = snippet.comment_count || 0
          }

          if (snippet.platform === 'News') {
            author.channel_title = snippet.post_metadata?.post_source || ''

            extras.articleThumbImg = snippet.post_metadata?.post_thumb || ''
            extras.articleAuthor = snippet.post_metadata?.author_name || ''
          }

          return {
            /* HACK: added the index so all the ids are unique */
            ...snippet,
            id: snippet.post_id + index,
            snippet_text: snippet.snippet_text,
            title: snippet.post_title,
            date: snippet.upload_date,
            riskScore: snippet?.risk_score?.toFixed(0),
            views: snippet.impression_count,
            viewsMilify: millify(snippet.impression_count || 0),
            views_diff: snippet.impression_diff_count,
            views_diff_milify: millify(snippet.impression_diff_count || 0),
            playSourceLink: snippet?.media_url,
            openSourceLink: snippet?.post_url,
            sourcePlatform: snippet.platform,
            channelId: snippet?.channel_id,
            author,
            extras,
            duration: snippet.duration,
            offset: snippet.offset,
            sentiment: snippet.sentiment,
            postUrl: snippet.post_url,
            feedback: 'none',
            translatedSnippet: snippet.translated_snippet,
            documentId: snippet.document_id,
          }
        })
        if (addMore) {
          this.reportSectionFeeds = {
            items: [
              ...JSON.parse(JSON.stringify(this.reportSectionFeeds.items)),
              ...JSON.parse(JSON.stringify(snippets)),
            ],
            total_count: response.data.total_count,
          }
        } else {
          this.reportSectionFeeds = { items: snippets, total_count: response.data.total_count }
        }
        creators = creators.filter((creator, index) => creators.indexOf(creator) === index)

        await this.getCreators(creators)
      } else {
        if (addMore) {
          this.reportSectionFeeds = {
            items: [
              ...JSON.parse(JSON.stringify(this.reportSectionFeeds.items)),
              ...JSON.parse(JSON.stringify(response.data.items)),
            ],
            total_count: response.data.total_count,
          }
        } else {
          this.reportSectionFeeds = response.data
        }
      }
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setIsReportFeedsLoading(false)
    }
  }

  reportFeedsFeedbackHandler = async (data: ReportFeedsFeedback) => {
    try {
      const response = await reportsAPI.reportFeedsFeedback(data)
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchAllTrendsList = async (
    startDate: string,
    endDate: string,
    data: ReportFeedsData,
    risk_score: { min: number; max: number } = { min: 0, max: 100 },
    platform?: string[] | string,
    sentiment?: string[],
  ) => {
    // this.setIsConfirmLoading(true)
    let qParam = `start_date:gte:${startDate},end_date:lte:${endDate}`
    if (platform && platform.length) {
      qParam = `${qParam},platform:in:${Array.isArray(platform) ? platform.join(',') : platform}`
    }
    if (sentiment && sentiment.length) {
      qParam = `${qParam},sentiment:in:${sentiment.join(',')}`
    }
    qParam = `${qParam},risk_score:gte:${risk_score.min},risk_score:lte:${risk_score.max}`

    try {
      const [allPosts, allImpressions, sentimentPosts, sentimentImpressions, platformPosts, platformImpressions] =
        await Promise.all([
          reportsAPI.fetchReportStats({ q: qParam }, data),
          reportsAPI.fetchReportStats(
            {
              q: qParam,
              metric: 'impression',
            },
            data,
          ),
          reportsAPI.fetchReportStats(
            {
              q: qParam,
              dimension: 'sentiment',
            },
            data,
          ),
          reportsAPI.fetchReportStats(
            {
              q: qParam,
              metric: 'impression',
              dimension: 'sentiment',
            },
            data,
          ),
          reportsAPI.fetchReportStats(
            {
              q: qParam,
              dimension: 'platform',
            },
            data,
          ),
          reportsAPI.fetchReportStats(
            {
              q: qParam,
              metric: 'impression',
              dimension: 'platform',
            },
            data,
          ),
        ])
      this.trendsDataPoints.posts.all = allPosts.data.data_points
      this.trendsDataPoints.impressions.all = allImpressions.data.data_points
      this.trendsDataPoints.posts.sentiment = sentimentPosts.data.data_points
      this.trendsDataPoints.impressions.sentiment = sentimentImpressions.data.data_points
      this.trendsDataPoints.posts.platform = platformPosts.data.data_points
      this.trendsDataPoints.impressions.platform = platformImpressions.data.data_points
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  sendReportAsEmail = async (payload: { id: string; instanceId?: string; users: string[] }) => {
    this.setIsSendingEmail(true)
    try {
      return await reportsAPI.sendReportAsEmail(payload)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        type: 'user message',
        userErrorInfo: {
          title: 'Report not sent',
          description: 'Report not sent. Please check the email address and try again.',
        },
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      this.setIsSendingEmail(false)
    }
  }

  shareToAll = async (endpoint: ShareReportEndpoint, id: string, data: UpdateShareListObj) => {
    this.setLoading(true)
    try {
      const response = await reportsAPI.shareReport('post', endpoint, id, data)
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setLoading(false)
    }
  }
}
