import axios from 'axios'

import { ROUTES } from 'settings/settings'

interface Props {
  name: string
  type: string
  description: string
  communities: string[]
  keywords_expression: string
  parent_narrative_ids: string
  related_contents?: string[]
  tenant_id: string
  enable_retweet: boolean
  verified_only: boolean
  profile_country: string
  multi_lang_kwd_expression?: { [x: string]: string }
}

export const publishNarrative = async (narrative: Props, republishID?: string) => {
  let url = process.env.REACT_APP_API + ROUTES.newNarrativePublish

  if (!!republishID) url = `${process.env.REACT_APP_API}/${ROUTES.narrative}/${republishID}/publish`

  const { data } = await axios.post(url, narrative)

  return data
}
