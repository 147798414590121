import { Form, Input } from 'antd'
import Modal from 'antd/lib/modal/Modal'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: (state: boolean) => void
  submitFeedback: (feedbackName: string) => void
  isNarrative?: boolean
}

export const SendFeedbackModal = ({
  isModalVisible,
  setIsModalVisible,
  submitFeedback,
  isNarrative = false,
}: Props) => {
  const [form] = Form.useForm()

  const handleOk = async () => {
    try {
      await form.validateFields()
      submitFeedback(form.getFieldValue('feedbackField'))
      form.setFieldsValue({
        feedbackField: '',
      })
      setIsModalVisible(false)
    } catch (e) {}
  }

  return (
    <Modal
      centered
      closable
      width={800}
      okText='Submit'
      visible={isModalVisible}
      title={isNarrative ? 'How can we help?' : 'Submit feedback'}
      wrapClassName='c-request-modal'
      bodyStyle={{ overflow: 'auto' }}
      onOk={() => {
        handleOk()
      }}
      onCancel={() => {
        setIsModalVisible(false)
        form.setFieldsValue({ feedbackField: '' })
      }}>
      <Form form={form}>
        <div className='c-request-modal__centered-text-wraper'>
          <p className='c-request-modal__centered-text'>
            {isNarrative
              ? 'We will respond within 24-48 hours, and most of the time we do so immediately.'
              : 'Please tell us how we can help or do better.'}
          </p>
        </div>

        <p className='c-request-modal__input-text'>Briefly describe your request</p>

        <Form.Item
          name={'feedbackField'}
          className='c-define__item c-define__item--1'
          rules={[{ required: true, message: 'Please add your feedback in the form!' }]}>
          <Input.TextArea
            placeholder={isNarrative ? 'Help me to...' : 'Feedback'}
            className='c-request-modal__input'
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
