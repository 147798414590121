import { Modal } from 'antd'
import { ReactComponent as ProgressImage } from 'assets/images/export-success-image.svg'

import './SuccessModal.scss'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: (state: boolean) => void
}

export const SuccessModal = ({ isModalVisible, setIsModalVisible }: Props) => {
  return (
    <Modal
      closable={false}
      centered
      width={481}
      open={isModalVisible}
      bodyStyle={{ overflow: 'auto' }}
      className='success-modal'
      footer={
        <button className='success-modal__close-btn' onClick={() => setIsModalVisible(false)}>
          Continue
        </button>
      }>
      <div className='success-modal__body'>
        <ProgressImage />
        <span className='success-modal__title'>Export in progress</span>
        <span className='success-modal__description'>
          We're preparing your data for export. Once the export is ready, we'll notify you, and you'll be able to
          download the file directly from the {<b>Downloads</b>} section. You can continue to use the platform while we
          handle this.
        </span>
      </div>
    </Modal>
  )
}
