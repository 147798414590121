import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { ConditionsPayloadParamsType, SubjectMetricsPayload } from 'types/types'

export function getSubjectSentimentMetrics({
  params,
  data,
}: {
  params: ConditionsPayloadParamsType
  data: SubjectMetricsPayload
}) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SEARCH_API}${ROUTES.subjectSentiment}`,
    params,
    data,
  })
}
