import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { MoveTopFolderDataType } from 'types/types'

export function moveToFolder({ id, data }: { id: string; data: MoveTopFolderDataType }) {
  return axios({
    method: 'post',
    url: `${ROUTES.folder}/${id}/move`,
    data,
  })
}
