import { Form, Input, Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { SuccessModal } from './SuccessModal'

import { submitFeedback } from 'api/feedbackAPI/submitFeedback'
import './ContactUs.scss'

interface Props {
  openModal: boolean
  setOpenModal: (open: boolean) => void
}

export const ContactUs = observer(({ openModal, setOpenModal }: Props) => {
  const [form] = Form.useForm()

  const [openSuccessModal, setOpenSuccessModal] = useState(false)

  const onSuccess = async () => {
    await submitFeedback(form.getFieldValue('feedbackField'))
    form.setFieldsValue({
      feedbackField: '',
    })
    setOpenModal(false)
    setOpenSuccessModal(true)
  }
  return (
    <div>
      <Modal
        className='contact-us'
        title={<div className='contact-us__title'>{'Contact Pendulum'}</div>}
        open={openModal}
        footer={
          <div className='contact-us__footer'>
            <button className='contact-us__footer__cancel' onClick={() => setOpenModal(false)}>
              Cancel
            </button>
            <button className='contact-us__footer__send' onClick={onSuccess}>
              Send
            </button>
          </div>
        }
        onCancel={() => setOpenModal(false)}>
        <Form form={form}>
          <div>
            <span className='contact-us__description'>{'We would love to connect live.'}</span>
            <Form.Item
              name={'feedbackField'}
              className='c-define__item c-define__item--1'
              rules={[{ required: true, message: 'Please add your feedback in the form!' }]}>
              <Input.TextArea rows={8} placeholder={'Please tell us how we can help.'}></Input.TextArea>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <SuccessModal isModalVisible={openSuccessModal} setIsModalVisible={setOpenSuccessModal} />
    </div>
  )
})
