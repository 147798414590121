import { Area, AreaChart, ResponsiveContainer } from 'recharts'

import './FlagStats.scss'
import { AssetListObjType } from 'store/asset/types'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { ReactComponent as UpArrowIcon } from 'assets/images/icons/monitor/up_arrow_icon.svg'
import millify from 'millify'

type PropTypes = {
  selectedBrand: AssetListObjType | undefined
  flag: any
}

const FlagStats = observer((props: PropTypes) => {
  const { metric } = props.flag

  const countDifference = useMemo((): number => {
    if (metric.currentFlagCount && metric.prevFlagCount) {
      return metric.currentFlagCount - metric.prevFlagCount
    }
    return 0
  }, [metric.currentFlagCount, metric.prevFlagCount])

  const renderUI = useMemo(() => {
    const riskValue = metric.currentFlagCount! - metric.prevFlagCount!
    if (riskValue === 0) {
      return
    }

    return (
      <>
        <UpArrowIcon className='apc_arrow_icon' />
        <span className='apc_trending_count'>{millify(Math.abs(riskValue))}</span>
      </>
    )
  }, [metric])

  const shadeColor = countDifference >= 0 ? '#D7F2E5' : '#F8DEDB'

  return (
    <div className='flag-stats'>
      <div className='flag-stats__numbers'>
        {metric.currentFlagCount === null || metric.prevFlagCount === null ? (
          <div className={`all_posts_container`}>
            <span className='apc_count'>-</span>
          </div>
        ) : (
          <div
            className={`all_posts_container${
              metric.currentFlagCount - metric.prevFlagCount < 0 ? ' all_posts_container_down' : ''
            }`}>
            <span className='apc_count'>{metric.currentFlagCount ? millify(metric.currentFlagCount) : '-'}</span>
            {renderUI}
          </div>
        )}
      </div>
      <div className='flag-stats__chart'>
        {metric?.chartData?.length > 1 && (
          <ResponsiveContainer width='100%' height='100%'>
            <AreaChart data={metric?.chartData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id={`colorValue-${countDifference >= 0 ? 'up' : 'down'}`} x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor={shadeColor} stopOpacity={0.8} />
                  <stop offset='95%' stopColor={shadeColor} stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <Area
                type='monotone'
                dataKey='value'
                stroke={countDifference >= 0 ? '#17b26a' : '#f04438'}
                strokeWidth={2}
                fill={`url(#colorValue-${countDifference >= 0 ? 'up' : 'down'})`}
                fillOpacity={1}
                isAnimationActive={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
})

export default FlagStats
