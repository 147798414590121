import classNames from 'classnames'
import TagManager from 'react-gtm-module'
import { observer } from 'mobx-react-lite'
import { Spin, Switch, Tooltip } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'

import AlertModal from 'components/AlertModal/AlertModal'
import Table, { IColumnType } from 'components/common/Table/Table'
import AssetHeader from 'components/Asset/AssetHeader/AssetHeader'
import SuccessModal from 'components/Flags/SuccessModal/SuccessModal'
import FlagPosts from 'components/Monitor/FlagPosts/FlagPosts'
import { DefaultPagination } from 'components/common/Pagination/Pagination'
import CreateAssetModal from 'components/Asset/CreateAssetModal/CreateAssetModal'
import AllPostSection from 'components/Monitor/AllPostSection/AllPostSection'
import ActionButtons from 'components/Investigate/Reports/ActionButtons/ActionButtons'
import ExpandListChunk from 'components/Monitor/ExpandListChunk/ExpandListChunk'
import CorporateCommunicationsBrandsDashboard from 'components/Asset/BrandsDashboard/Dashboard'

import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import { ReactComponent as NotificationIcon } from 'assets/images/icons/monitor/notification_icon.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'

import { store } from 'store'
import { flagsAPI } from 'api/api'

import usePermissions from 'hooks/usePermissions'

import { UtilService } from 'services/Util/Util'
import { displayApiError } from 'utils/helper'
import { AssetCategoryType, AssetListObjType, AssetListParamsType } from 'store/asset/types'
import { ContactUs } from 'components/common/ContactUs/ContactUs'

import { useUnmount } from 'react-use'
import './Asset.scss'

const BRANDS = 'Brands'
const DASHBOARD = 'dashboard'

const Asset = observer(() => {
  const navigate = useNavigate()
  const { assetsStore, breadcrumbStore, tenantsStore, userStore } = store

  const { state } = useLocation()
  const [loader, setLoader] = useState('')
  const [entityId, setEntityId] = useState<string>('')
  const [alertId, setAlertId] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const [openAlertSuccessModal, setOpenAlertSuccessModal] = useState<boolean>(false)
  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([])
  const [openEditSuccessModal, setOpenEditSuccessModal] = useState<boolean>(false)
  const [editAssetData, setEditAssetData] = useState<AssetListObjType | null>(null)
  const [openContactModal, setOpenContactModal] = useState(false)

  const isComingFromAssetDetailPage = state?.comingFrom?.includes('assets/details')

  const canCreate = usePermissions('assets') === 'create'
  const canActiveDeActive = usePermissions('assets') === 'turn_on_off' || usePermissions('assets') === 'create'

  useEffect(() => {
    const brandCategory = assetsStore.assetsCategories?.find((item) => item.name === BRANDS)
    if (!isComingFromAssetDetailPage) {
      if (!tenantsStore.customThemeLoading && !tenantsStore.isCorporateCommunicationsTheme && !brandCategory) {
        assetsStore.setAssetSelectedTab({
          category_id: '0',
          name: 'All Assets',
        })
      } else if (tenantsStore.customThemeLoading || !brandCategory) {
        assetsStore.setAssetSelectedTab({
          category_id: '',
          name: '',
        })
      } else
        assetsStore.setAssetSelectedTab({
          category_id: brandCategory?.category_id || '',
          name: BRANDS,
        })
    }

    if (state?.tabName) {
      assetsStore.setAssetSelectedTab(state?.tabName)
      assetsStore.setPaginationValues({ page: state?.pageNumber, pageSize: state?.pageSize })
    }
  }, [tenantsStore.customThemeLoading, assetsStore.assetsCategories, isComingFromAssetDetailPage])

  const expandedRowsHandler = (item: AssetListObjType) => {
    setExpandedRows((prevState) =>
      prevState.includes(item.id) ? prevState.filter((res) => res !== item.id) : [...prevState, item.id],
    )
  }

  const expandedRowsCondition = (item: AssetListObjType) => {
    return expandedRows.includes(item.id)
  }

  const disabledRowCondition = (item: AssetListObjType) => {
    return !item.is_active
  }

  useEffect(() => {
    if (assetsStore.isFlagCreated) {
      setSelectedRows([])
      assetsStore.setPaginationValues({ page: 1, pageSize: 10 })
    }
  }, [assetsStore.isFlagCreated, tenantsStore.customThemeLoading])

  useEffect(() => {
    if (breadcrumbStore.rootPage) {
      const newBreadcrumbData = [
        { id: UtilService.generateUuid(), title: breadcrumbStore.rootPage },
        { id: UtilService.generateUuid(), title: assetsStore.assetSelectedTab?.name || '' },
      ]

      breadcrumbStore.setBreadcrumbData(newBreadcrumbData)
    }
  }, [assetsStore.assetSelectedTab?.name, breadcrumbStore.rootPage, tenantsStore.customThemeLoading])

  useEffect(() => {
    let firstCallRequestParams: AssetListParamsType = {
      page: isComingFromAssetDetailPage ? assetsStore.paginationValues.page : 1,
      per_page: isComingFromAssetDetailPage ? assetsStore.paginationValues.pageSize : 10,
      sort: 'name:asc',
      is_asset: true,
    }
    if (!tenantsStore.customThemeLoading && assetsStore.assetSelectedTab.name) {
      if (tenantsStore.isCorporateCommunicationsTheme && !assetsStore.assetSelectedTab.category_id) return

      if (tenantsStore.isCorporateCommunicationsTheme && assetsStore.corporateCommunicationBrandsView === DASHBOARD)
        firstCallRequestParams.is_active = true
      const modifiedParams = assetsStore.assetSelectedTab.category_id
        ? { ...firstCallRequestParams, q: `category_id:eq:${assetsStore.assetSelectedTab.category_id}` }
        : firstCallRequestParams
      assetsStore.fetchAssetsList({ params: modifiedParams })
      assetsStore.fetchConditionsPayload(undefined, false)
    }
  }, [
    tenantsStore.customThemeLoading,
    tenantsStore.isCorporateCommunicationsTheme,
    assetsStore.corporateCommunicationBrandsView,
    assetsStore.assetSelectedTab,
    isComingFromAssetDetailPage,
  ])

  useUnmount(() => {
    assetsStore.setPaginationValues({ page: 1, pageSize: 10 })
    assetsStore.setAssetSelectedTab({ category_id: '', name: '' })
  })

  const requestParams: AssetListParamsType = {
    page: assetsStore.paginationValues.page,
    per_page: assetsStore.paginationValues.pageSize,
    sort: 'name:asc',
    is_asset: true,
  }

  const activeHandler = async (id: string, checked: boolean) => {
    try {
      setExpandedRows((prev) => {
        return prev.filter((item) => item !== id)
      })

      setLoader(id)
      await flagsAPI.toggleFlagStatus({ ids: [id], status: checked ? 'activate' : 'deactivate' })
      if (assetsStore.assetSelectedTab?.category_id)
        requestParams.q = `category_id:eq:${assetsStore.assetSelectedTab.category_id}`
      assetsStore.fetchAssetsList({ params: requestParams })
      const newItems = [...assetsStore.assetsList.items]
      const index = newItems.findIndex((item) => item.id === id)
      if (index > -1) {
        newItems[index].is_active = checked
        assetsStore.setAssetsList({ items: newItems, total_count: assetsStore.assetsList.total_count })
      }
      setLoader('')
    } catch (error: any) {
      setLoader('')
      const newItems = [...assetsStore.assetsList.items]
      const index = newItems.findIndex((item) => item.id === id)
      if (index > -1) {
        newItems[index].is_active = !checked
        assetsStore.setAssetsList({ items: newItems, total_count: assetsStore.assetsList.total_count })
      }
      displayApiError(error)
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'toggle_asset',
        //@ts-ignore
        user_id: userStore.userId,
        user_name: userStore.userName,
        tenantId: userStore.tenantId,
        roleId: userStore.roleId,
        entityId: id,
        status: checked ? 'activate' : 'deactivate',
      },
    })
  }
  /*  eslint-disable no-useless-computed-key */
  const columns: IColumnType<AssetListObjType>[] = useMemo(() => {
    return [
      {
        key: 'checkbox',
        title: '',
        width: 44,
        render: (column, item) => {
          const { id } = item
          return (
            <>
              {canCreate && (
                <Tooltip title='Select' placement='bottom'>
                  <div
                    className={classNames('amc_th_checkbox_container', {
                      ['amc_th_checkbox_container_selected']: selectedRows.includes(id),
                    })}
                    aria-label='Select'
                    onClick={(e) => {
                      e.stopPropagation()

                      setSelectedRows((prevState) =>
                        prevState.includes(id) ? prevState.filter((res) => res !== id) : [id],
                      )
                    }}>
                    {selectedRows.includes(id) && <CheckIcon aria-label='Select' />}
                  </div>
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        key: 'name',
        title: 'Name',
        //   width: 310,
        render: (column, item) => {
          const { display_label } = item
          return <span className='amc_t_flag_name'>{display_label}</span>
        },
      },
      {
        key: 'actions',
        title: '',
        width: 150,
        render: (column, item) => {
          return (
            <>
              {canCreate && (
                <ActionButtons
                  showTooltip={true}
                  className={classNames('action_button_container', { ['abc_disabled']: !item.is_active })}
                  hideExtraIcons={true}
                  hideDeleteIcon={true}
                  shareHandler={() => {}}
                  editHandler={() => item.is_active && setEditAssetData(item)}
                />
              )}
            </>
          )
        },
      },
      {
        key: 'all_posts',
        title: 'All Posts',
        width: 150,
        render: (column, item) => {
          return item.is_active ? <AllPostSection forAsset={true} itemData={item} subStore={'assets'} /> : '-'
        },
      },
      {
        key: 'flag_posts',
        title: 'Flagged Posts',
        render: (column, item) => {
          return item.is_active ? <FlagPosts forAsset={true} itemData={item} subStore={'assets'} /> : '-  '
        },
      },
      {
        key: 'notification',
        title: '',
        width: 70,
        onCell: (record) => ({
          onClick: (e: any) => {
            e.stopPropagation()
            if (!record.is_active) return
            setAlertId(record?.alert_id)
            setEntityId(record?.id)
          },
          style: {
            textAlign: 'center',
          },
        }),
        render: (_, record) =>
          record?.alert_id ? (
            <Tooltip title='Turn On/Off' placement='bottom'>
              <FilledNotificationIcon className='amc_bell_icon' aria-label='Turn On/Off' />
            </Tooltip>
          ) : (
            <Tooltip title='Turn On/Off' placement='bottom'>
              <NotificationIcon className='amc_bell_icon' aria-label='Turn On/Off' />
            </Tooltip>
          ),
      },
      {
        key: 'active',
        title: 'Active',
        width: 92,
        onCell: () => ({
          onClick: (e: any) => {
            e.stopPropagation()
          },
          style: {
            textAlign: 'center',
          },
        }),
        render: (column, item) => {
          return (
            <Tooltip title='Turn On/Off' placement='bottom'>
              <Switch
                className='amc_switch'
                checked={item.is_active}
                disabled={!canActiveDeActive}
                aria-label='Turn On/Off'
                onChange={(value, e) => {
                  activeHandler(item.id, value)
                }}
                loading={item.id === loader}
              />
            </Tooltip>
          )
        },
      },
    ]
  }, [selectedRows, assetsStore.assetsList.items, loader])

  const renderRowSubComponent = useCallback((records: AssetListObjType) => {
    return <ExpandListChunk data={records} forAsset={true} subStore={'assets'} />
  }, [])

  const rowClickHandler = (item: AssetListObjType) => {
    const state = {
      name: item.name,
      conditions: item.conditions,
      mode: item.mode,
      id: item?.id,
      tabName: assetsStore.assetSelectedTab,
      pageNumber: assetsStore.paginationValues.page,
      pageSize: assetsStore.paginationValues.pageSize,
      alert_id: item.alert_id,
    }
    navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
  }

  useUnmount(() => {
    assetsStore.setPaginationValues({ page: 1, pageSize: 10 })
    assetsStore.setAssetSelectedTab({ category_id: '', name: '' })
  })

  const isCorporateCommunicationBrandsCategory =
    tenantsStore.isCorporateCommunicationsTheme && assetsStore.assetSelectedTab?.name === BRANDS
  const isCorporateBrandsDashboard =
    isCorporateCommunicationBrandsCategory && assetsStore.corporateCommunicationBrandsView === DASHBOARD

  return (
    <>
      <Spin spinning={assetsStore.assetsLoading} className='Assets_main_container_loader'>
        <div className='Assets_main_container'>
          <AssetHeader
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectedCategory={assetsStore.assetSelectedTab as AssetCategoryType}
            setSelectedCategory={assetsStore.setAssetSelectedTab}
            addHandler={() => {
              setOpenModal(true)
            }}
            isCorporateBrandsDashboard={isCorporateBrandsDashboard}
            isCorporateCommunicationBrandsCategory={isCorporateCommunicationBrandsCategory}
          />
          {!tenantsStore.customThemeLoading && !isCorporateBrandsDashboard && (
            <>
              <div className='amc_table_container'>
                <Table
                  data={assetsStore.assetsList.items}
                  columns={columns}
                  className='amc_table'
                  onRowClick={rowClickHandler}
                  disabledRow={disabledRowCondition}
                  expandable={{
                    expandedRowRender: renderRowSubComponent,
                    setExpandedRows: expandedRowsHandler,
                    expandedRowsCondition: expandedRowsCondition,
                  }}
                />
              </div>
              <div className='amc_pagination_container'>
                <DefaultPagination
                  className='amc_pagination'
                  totalCount={assetsStore.assetsList.total_count}
                  currentPage={assetsStore.paginationValues.page}
                  pageSize={assetsStore.paginationValues.pageSize}
                  showPageSizeChanger={false}
                  nextIcon={ArrowNextIcon}
                  prevIcon={ArrowNextIcon}
                  iconHeight={20}
                  iconWidth={20}
                  onChange={(page, pageSize) => {
                    if (assetsStore.assetSelectedTab?.category_id)
                      requestParams.q = `category_id:eq:${assetsStore.assetSelectedTab.category_id}`
                    assetsStore.setPaginationValues({ page, pageSize })
                    assetsStore.fetchAssetsList({ params: { ...requestParams, page, per_page: pageSize } })
                    setSelectedRows([])
                  }}
                  locale={{ items_per_page: '' }}
                />
              </div>
            </>
          )}
          {!tenantsStore.customThemeLoading && isCorporateBrandsDashboard && <CorporateCommunicationsBrandsDashboard />}
        </div>
        {openModal && (
          <CreateAssetModal
            onClose={() => setOpenModal(false)}
            setOpenSuccessModal={() => setOpenSuccessModal(true)}
            selectedCategory={assetsStore.assetSelectedTab}
            setOpenContactModal={setOpenContactModal}
          />
        )}
        {editAssetData && (
          <CreateAssetModal
            isEditMode={true}
            onClose={() => setEditAssetData(null)}
            editData={editAssetData}
            setOpenSuccessModal={() => setOpenEditSuccessModal(true)}
            setOpenContactModal={setOpenContactModal}
          />
        )}
        {openSuccessModal && (
          <SuccessModal
            title='Asset created successfully'
            desc='Your created asset will turned ON if your defined rule matches your search.'
            onClose={() => {
              assetsStore.setIsFlagCreated(false)
              setOpenSuccessModal(false)
            }}
          />
        )}
        {openEditSuccessModal && (
          <SuccessModal
            title='All changes saved'
            desc='You successfully saved all the changes to the selected asset.'
            onClose={() => {
              assetsStore.setIsFlagCreated(false)
              setOpenEditSuccessModal(false)
            }}
          />
        )}

        {openAlertSuccessModal && (
          <SuccessModal
            title='Alert Created Successfully'
            desc='You will start receive new alerts according to the selected preferences'
            onClose={() => setOpenAlertSuccessModal(false)}
            closeButtonText='Continue'
          />
        )}

        <AlertModal
          entity_id={entityId}
          entity_type='ASSET'
          open={!!entityId || !!alertId}
          alertId={alertId}
          onClose={() => {
            setEntityId('')
            setAlertId('')
          }}
          onSuccess={() => {
            setOpenAlertSuccessModal(true)
            assetsStore.fetchAssetsList({ params: requestParams })
          }}
          onDeleteSuccess={() => assetsStore.fetchAssetsList({ params: requestParams })}
        />
      </Spin>
      <ContactUs openModal={openContactModal} setOpenModal={setOpenContactModal} />
    </>
  )
})

export default Asset
