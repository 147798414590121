import axios from 'axios'
import { ROUTES } from 'settings/settings'

export const deleteBookmark = async (bookmarkId?: string) => {
  let url = process.env.REACT_APP_API + ROUTES.bookmark
  if (!!bookmarkId) {
    url = `${process.env.REACT_APP_API}/${ROUTES.bookmark}/${bookmarkId}`
  }

  const { data } = await axios.delete(url)

  return data
}
