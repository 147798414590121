import { Modal } from 'antd'
import { ReactComponent as WarningIcon } from 'assets/images/alert-triangle-icon.svg'

import './WarningModal.scss'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: (state: boolean) => void
  setMainModal: (state: boolean) => void
}

export const WarningModal = ({ isModalVisible, setIsModalVisible, setMainModal }: Props) => {
  return (
    <Modal
      closable={false}
      centered
      width={560}
      open={isModalVisible}
      destroyOnClose={true}
      bodyStyle={{ overflow: 'auto' }}
      className='warning-modal'
      footer={
        <div className='warning-modal__footer'>
          <button className='warning-modal__close-btn' onClick={() => setIsModalVisible(false)}>
            Continue Export
          </button>
          <button
            className='warning-modal__cancel-btn'
            onClick={() => {
              setIsModalVisible(false)
              setMainModal(false)
            }}>
            Cancel Export
          </button>
        </div>
      }>
      <div className='warning-modal__body'>
        <div className='warning-modal__body__icon'>
          <WarningIcon />
        </div>
        <div className='warning-modal__main'>
          <span className='warning-modal__title'>Are you sure you want to cancel the export?</span>
          <span className='warning-modal__description'>Please note: This action cannot be undone.</span>
        </div>
      </div>
    </Modal>
  )
}
