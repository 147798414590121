import { useCallback } from 'react'
import { Button, Modal } from 'antd'

import { ISnippet } from 'models/models'
import { bookmarksAPI } from 'api/api'

import styles from './RemoveSnippetFromBookmarkListsModal.module.scss'

interface Props {
  snippet: ISnippet
  isModalVisible: boolean
  closeModal: (shouldRefresh: boolean) => void
  isLoading?: boolean
}

export const RemoveSnippetFromBookmarkListsModal = ({ isModalVisible, closeModal, snippet, isLoading }: Props) => {
  const removeSnippetFromBookmarkListsHandler = useCallback(async () => {
    await bookmarksAPI.removeSnippetFromBookmark({
      payload: {
        entity_id: snippet.documentId!,
        entity_type: 'SNIPPET',
      },
    })

    // snippet removed from the bookmark lists. close the modal now
    closeModal(true)
  }, [])

  return (
    <Modal
      centered
      width={387}
      closable={false}
      open={isModalVisible}
      onCancel={() => closeModal(false)}
      confirmLoading={isLoading}
      wrapClassName={styles.removeSnippetFromBookmarkListsModalWrapper}
      footer={
        <div className={styles.modalFooter}>
          <Button className={styles.cancelButton} onClick={() => closeModal(false)}>
            Cancel
          </Button>
          <Button className={styles.removeButton} onClick={removeSnippetFromBookmarkListsHandler} loading={isLoading}>
            Remove
          </Button>
        </div>
      }>
      <div className={styles.deleteModalBody}>
        <p className={styles.heading}>Are you sure you want to remove this snippet from the list</p>
        <p className={styles.description}>Do not worry you can again bookmark the snippet.</p>
      </div>
    </Modal>
  )
}
