import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'

import { ReactComponent as DeleteIcon } from 'assets/images/deleteicon.svg'
import classNames from 'classnames'

const CreateEditWatchlistFooter = ({
  onCancel,
  onContinue,
  selectedRowKeys,
  invalidLinkCount = false,
  isCancelVisible = true,
  isContinueVisible = true,
  cancelButtonText = 'Cancel',
  isContinueDisabled = false,
  confirmButtonLoading = false,
  continueButtonText = 'Continue',
}: {
  onCancel: () => void
  onContinue: () => void
  isCancelVisible?: boolean
  cancelButtonText?: string
  selectedRowKeys?: string[]
  invalidLinkCount?: boolean
  isContinueVisible?: boolean
  continueButtonText?: string
  isContinueDisabled?: boolean
  confirmButtonLoading?: boolean
}) => {
  return (
    <div className='watchlist-builder__section--center__footer'>
      {isCancelVisible && (
        <ButtonImage onClick={onCancel} className='cancel-btn' type='button'>
          {cancelButtonText}
        </ButtonImage>
      )}
      {isContinueVisible && (
        <ButtonImage
          loading={confirmButtonLoading}
          icon={selectedRowKeys?.length ? DeleteIcon : undefined}
          onClick={onContinue}
          disabled={isContinueDisabled || (!selectedRowKeys?.length && invalidLinkCount)}
          className={classNames('continue-btn', { delete_btn: selectedRowKeys?.length })}
          type='button'>
          {continueButtonText}
        </ButtonImage>
      )}
    </div>
  )
}

export default CreateEditWatchlistFooter
