import { Menu, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { store } from 'store'

import { ReactComponent as BackIcon } from 'assets/images/back-btn-icon.svg'
import SampleIcon from 'assets/images/add-logo-sample.svg'

import { TenantConfiguration } from './TenantConfiguration/TenantConfiguration'
import { TenantPlatforms } from './TenantPlatforms/TenantPlatforms'
import { TenantUsers } from './TenantUsers/TenantUsers'

import './TenantDetails.scss'

export const TenantDetails = observer(() => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [selectedKey, setSelectedKey] = useState<string>('config')

  const { tenantsStore, userStore, loaderStore } = store
  const { currentRole } = userStore
  const { isLoadingTenants, isLoadingUsers } = loaderStore

  const NAVIGATION_TABS = useMemo(() => {
    return [
      {
        key: 'config',
        label: 'Configurations',
        path: '',
      },
      {
        key: 'platforms',
        label: 'Default platform subsets',
        path: '',
      },
      {
        key: 'users',
        label: 'Users',
        path: '',
      },
    ]
  }, [currentRole])

  const { activeTenant, backURL, setActiveTenant, fetchTenantDetails, resetStore } = tenantsStore

  useEffectOnce(() => {
    if (!activeTenant && id) {
      fetchTenantDetails(id).then((response) => {
        setActiveTenant(response)
      })
    }
  })

  useUnmount(() => {
    resetStore()
  })

  const changeMenu = ({ key }: any) => {
    setSelectedKey(key)
  }

  const onBack = () => {
    let url = backURL?.length > 0 ? backURL : '/settings'
    return navigate(url)
  }

  const getContent = () => {
    switch (selectedKey) {
      case 'config':
        return <TenantConfiguration />

      case 'platforms':
        return <TenantPlatforms />

      case 'users':
        return <TenantUsers />
      default:
        return <div></div>
    }
  }

  return (
    <Spin spinning={isLoadingTenants || isLoadingUsers}>
      <div className='tenant-details'>
        <div className='tenant-details__header'>
          <div className='tenant-details__header__back' onClick={onBack}>
            <BackIcon />
            <span className='tenant-details__header__back_label'>Back</span>
          </div>
          <div className='tenant-details__header__info'>
            <div className='tenant-details__header__logo-div'>
              <div className='tenant-details__header__logo'>
                <img
                  src={activeTenant?.logo_url ?? SampleIcon}
                  alt=''
                  onError={(e: any) => {
                    e.target.src = SampleIcon
                  }}
                />
              </div>
              <span className='tenant-details__header__name'>{activeTenant?.name}</span>
            </div>
            {(activeTenant?.industry?.length || 0) > 0 && (
              <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none'>
                <circle cx='2.38281' cy='2' r='1.5' fill='#667085' />
              </svg>
            )}
            <span className='tenant-details__header__industry'>{activeTenant?.industry}</span>
            {(activeTenant?.domain?.length || 0) > 0 && (
              <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none'>
                <circle cx='2.38281' cy='2' r='1.5' fill='#667085' />
              </svg>
            )}
            <a
              className='tenant-details__header__domain'
              target='_blank'
              rel='noopener noreferrer'
              href={activeTenant?.domain}>
              {activeTenant?.domain}
            </a>
          </div>
        </div>
        <nav className='tenants__navigation tenant-details__navigation'>
          <div className='tenants__navigation__container'>
            <Menu mode='horizontal' selectedKeys={[selectedKey]} triggerSubMenuAction='click'>
              {NAVIGATION_TABS.map((tab) => {
                return (
                  <Menu.Item key={tab.key} id={tab.key} onClick={changeMenu}>
                    <Link to={tab.path}>{tab.label}</Link>
                  </Menu.Item>
                )
              })}
            </Menu>
          </div>
        </nav>
        {getContent()}
      </div>
    </Spin>
  )
})
