import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useEffectOnce } from 'react-use'
import { useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'

import { CreatorsList } from './CreatorsList/CreatorsList'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import { FilterAndSorterDropdown } from './FilterAndSorterDropdown/FilterAndSorterDropdown'

import { SubStore } from 'types/types'
import { ICreatorData } from 'models/models'

import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg'

import './Creators.scss'

interface Props {
  subStore: SubStore
}

export const Creators = observer((props: Props) => {
  const location = useLocation()
  const [searchInput, setSearchInput] = useState('')
  const [searchedCreators, setSearchedCreators] = useState<ICreatorData[]>([])
  const [showSearchedData, setShowSearchedData] = useState<boolean>(false)

  const { subStore } = props
  const { powerInsightsData, snippetsFilter, fetchPowerInsightsCreators } = store[`${subStore}Store`]
  const { isLoadingPICreators } = store['loaderStore']

  useEffectOnce(() => {
    fetchPowerInsightsCreators('PI')
  })

  useEffect(() => {
    setSearchInput('')
    setSearchedCreators([])
    setShowSearchedData(false)
  }, [snippetsFilter])

  const searchCreators = () => {
    if (searchInput.length > 0) {
      setShowSearchedData(true)
      const creators =
        powerInsightsData.creatorsData?.items?.filter((creator) =>
          (creator.channel_title || creator.channel_name).toLowerCase().includes(searchInput.toLowerCase()),
        ) || []
      setSearchedCreators(creators)
    } else {
      setShowSearchedData(false)
      setSearchedCreators([])
    }
  }

  const CreatorsCount = useMemo(() => {
    if (showSearchedData) {
      return searchedCreators.length
    } else return powerInsightsData.creatorsData?.total_count ?? 0
  }, [powerInsightsData.creatorsData?.total_count, searchedCreators])

  return (
    <div className='power-insights-creators'>
      {location.pathname?.includes('/power-insights/creators') && (
        <div className='power-insights-creators__title'>Creators</div>
      )}
      <SearchInput
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onPressEnter={searchCreators}
        onClear={() => {
          setShowSearchedData(false)
          setSearchInput('')
          setSearchedCreators([])
        }}
        className='power-insights-creators__search__container'
        placeholder={'Search'}
        iconClassName={'power-insights-creators__search__icon'}
        prefix={SearchIcon}
        showIcon={true}
      />
      <FilterAndSorterDropdown subStore={subStore} />
      {!isLoadingPICreators && CreatorsCount < 2000 && (
        <div className='power-insights-creators__count-div'>
          <span className='power-insights-creators__count-div__title'>Number of creators</span>
          <span className='power-insights-creators__count-div__text'>
            {showSearchedData ? searchedCreators.length : powerInsightsData.creatorsData?.total_count || 'N/A'}
          </span>
        </div>
      )}
      {!isLoadingPICreators && CreatorsCount > 2000 && (
        <div className='power-insights-creators__top-creators-div'>
          <span className='power-insights-creators__top-creators-div__text'>Top 2000 creators</span>
        </div>
      )}
      <CreatorsList
        data={{
          items: powerInsightsData.creatorsData?.items || [],
          total_count: powerInsightsData.creatorsData?.total_count || 0,
        }}
        subStore={subStore}
        showSearchedData={showSearchedData}
        searchedCreator={searchedCreators || []}
        isLoadingPICreators={isLoadingPICreators}
        showSelectionCheckBoxes={true}
      />
    </div>
  )
})
