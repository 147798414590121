import './Privacy.scss'

export const Privacy = () => {
  return (
    <div className='c-privacy'>
      <div className='c13'>
        <h1 className='c11' id='h.qlfbwnfz49ra'>
          <span className='c3 c5'>Privacy</span>
        </h1>
        <p className='c0'>
          <span className='c3 c2'>Updated June 14, 2022</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Pendulum Intelligence, Inc. (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;) operates
            Pendulum&rsquo;s products and services, including Web-Apps, web services, and data products (the
            &ldquo;Site&rdquo;). This page informs you of our policies regarding the collection, use and disclosure of
            Personal Information we receive from users of the Site.{' '}
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            These terms may be superseded by any additional agreements or terms of use agreed upon separately between us
            and your employer, reseller, or other similar applicable intermediaries.{' '}
          </span>
        </p>
        <p className='c0 c6'>
          <span className='c3 c2'></span>
        </p>
        <p className='c0'>
          <span className='c2 c4'>1. What This Privacy Policy Covers</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            This Privacy Policy covers how Pendulum Intelligence Inc. (&ldquo;Pendulum,&rdquo; &ldquo;we,&rdquo;
            &ldquo;us,&rdquo; or &ldquo;our&rdquo;), treats information that it collects and receives from its Web
            Application, which is accessible at https://www.pendulumintel.com ,any mobile or localized version of the
            website, and any other Pendulum owned sites (collectively, the &ldquo;Site(s)&rdquo;), products, or services
            that link to this Privacy Policy. It also covers the use of information we may collect through content we
            make available on third-party sites or platforms.
          </span>
        </p>
        <p className='c0'>
          <span className='c2'>
            Our Privacy Policy is designed to help you understand how we collect, use, share and protect information
            about you. By using or accessing the Site, you are accepting the practices described in this Privacy Policy,
            your terms of service in your contract and all other policies or notices posted by us on the Site, and you
            consent to our collection and use of your information as described in this Privacy Policy, including that
            your information may be transferred to and stored in the United States.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            This Privacy Policy applies to personal data from the European Union and from Switzerland that is collected,
            used, retained and otherwise processed by Pendulum in the United States.
          </span>
        </p>
        <p className='c0 c6'>
          <span className='c3 c2'></span>
        </p>
        <p className='c0'>
          <span className='c4 c2'>2. Types of Information We Collect</span>
        </p>
        <p className='c0 c6'>
          <span className='c3 c2'></span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>We collect the following types of information:</span>
        </p>
        <p className='c0'>
          <span className='c2'>
            Your first and last name, email, organization, or other information that, alone or in combination can be
            used to identify you that (&ldquo;Personal Information&rdquo;) you provide when you create a user account;
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Usage, viewing and technical data automatically collected from your computer or device through cookies and
            other technologies such as your device identifier or IP address, browser type, internet service provider
            (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data;
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>&zwj;</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Information we collect when you interact with our content on the Site or on third-party sites or platforms,
            such as social networking sites. This may include information such as &ldquo;Likes&rdquo;, profile
            information gathered from social networking sites or the fact that you viewed or interacted with our
            content.
          </span>
        </p>
        <p className='c0'>
          <span className='c2 c8'>3. How We Collect Information</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            We and third-party service providers that provide functionality to the Services, may collect information
            about you in the following ways:
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            When you request information from us, fill out a form, or otherwise interact with us through the Site;
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>When you contact us separately, such as by email</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Automatically through technology such as cookies or other tracking/recording tools, including when you click
            on a link to visit the Site, interact with our content on the Site or on third-party sites or platforms, or
            open the emails we send. By using the Site or interacting with our content, you agree that we and our
            service providers may set cookies on your computer or device and use other tracking technologies described
            above.
          </span>
        </p>
        <p className='c0'>
          <span className='c2 c8'>4. How We Use the Information We Collect</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>We use your information in the following ways:</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            To provide, maintain and improve the Site or enhance your experience as a user of Pendulum&rsquo;s products.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            To control unauthorized use or abuse of the Site and our products and services, or otherwise detect,
            investigate or prevent activities that may violate our policies or be illegal;
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            To analyze trends, administer or optimize the Site, monitor usage or traffic patterns (including to track
            users&rsquo; movements around the Site) and gather demographic information about our user base as a whole;
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>To allow us to better service you in responding to any information</span>
        </p>
        <p className='c0'>
          <span className='c2'>
            To communicate directly with you, including sending you emails or communicating other information (e.g.
            grant requests or grant summary impact) You may opt out of any communications at any time by contacting{' '}
          </span>
          <span className='c1'>
            <a className='c9' href='mailto:support@pendulumfn.com'>
              support@pendulumfn.com
            </a>
          </span>
          <span className='c3 c2'>
            &nbsp;or another manner described to you at the time of collection or as otherwise described in this Privacy
            Policy or as authorized or required by applicable laws.
          </span>
        </p>
        <p className='c0'>
          <span className='c2 c8'>5. Sharing Your Information with Third Parties</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Pendulum may share information, including Personal Information in the following circumstances:We may share
            your personal information with third-party service providers to permit such parties to provide services that
            help us with our activities, which may include assisting us with communications, or providing, maintaining
            and improving the features and functionality of Pendulum&rsquo;s products, among other things. This sharing
            of information will be governed by any applicable terms contained in purchase orders, service contracts, or
            similar documents, and will be contained to the scope described within them. In addition, these third
            parties are authorized to use your personal information only as necessary to provide services to us or as
            required by law;
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            We may share your personal information when we have a good faith belief that access, use, preservation or
            disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal
            process or enforceable governmental request, including lawful requests by public authorities, for example to
            meet national security or law enforcement requirements, (b) enforce our Terms of Service or other applicable
            policies or agreements governing your use of the Site, including investigation of potential violations
            thereof, or (c) protect against imminent harm to the rights, property or safety of Pendulum, or the public
            as required or permitted by law;
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            We may share your personal information with third parties (including our service providers and government
            entities) to detect, prevent, or otherwise address fraud, security or technical issues;
          </span>
        </p>
        <p className='c0'>
          <span className='c2 c8'>6. Children&rsquo;s Information</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Pendulum&rsquo;s products are not intended for use by anyone under the age of 18.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Pendulum does not knowingly collect or solicit Personal Information from anyone under the age of 13. In the
            event that we confirm that we have collected Personal Information from someone under the age of 13, we will
            delete that information. If you believe that we might have any information from a child under 13, please
            contact us at info@pendulumfn.com or mailing address provided at the end of this Privacy Policy.
          </span>
        </p>
        <p className='c0'>
          <span className='c2 c8'>7. Explanation of Cookies and Other Technologies</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            This section provides more information about some of those technologies used on our Site and how they work.
          </span>
        </p>
        <p className='c0'>
          <span className='c2'>
            Cookies. A cookie is a small text file that is stored on a user&rsquo;s computer or device for
            record-keeping purposes. Cookies may be used for various purposes, such as to confirm that users are logged
            in. Pendulum uses cookies for validating authenticated users and tracking a user&rsquo;s application session
            information in order to improve user experience, as well as prevent any unauthorized access and to maintain
            security.
          </span>
        </p>
        <p className='c0 c6'>
          <span className='c3 c2'></span>
        </p>
        <p className='c0'>
          <span className='c2 c8'>8. Third Party Sites</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Third Party Sites. Pendulum&rsquo;s products and services e may contain links to third party websites. This
            Privacy Policy applies solely to information collected by Pendulum. Even if the third party is affiliated
            with us through a business partnership or otherwise, we are not responsible for the privacy practices of
            other websites. If you submit personal information to any of those third party sites, your information is
            governed by their privacy policies. We encourage you to familiarize yourself with the privacy policies of
            such websites to determine how they handle any information they separately collect from you. Please be aware
            that we do not warn you when you have chosen to click through to another website or property when using the
            Site.
          </span>
        </p>
        <p className='c0'>
          <span className='c2 c8'>9. Your Controls and Choices</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Opt-Outs. We may provide you with the opportunity to &ldquo;opt-out&rdquo; of (i) disclosing your personal
            information to third parties, except to a third party that is acting as an agent to perform tasks on our
            behalf and under our instructions as described in the &ldquo;Sharing Your Information with Third
            Parties&rdquo; paragraph above; (ii) using your personal information for a purpose that is materially
            different from the purpose for which it was originally collected or that you authorized. If you decide to
            opt out, we may not be able to provide certain features of the Site to you.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Communication Preferences. If you no longer wish to receive our newsletter and promotional communications,
            you may opt out of receiving them by following the instructions included on such communications or on the
            Site.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Blocking Cookies. You can remove or block certain cookies using the settings in your browser but the Site
            may cease to function properly if you do so.
          </span>
        </p>
        <p className='c0 c6'>
          <span className='c3 c2'></span>
        </p>
        <p className='c0'>
          <span className='c2 c8'>10. Accessing and Updating Personal Information</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            You have the right to know what personal information about you is held in Pendulum databases and to ensure
            that such personal information is accurate and relevant for the purposes for which Pendulum collected it.
            You may review your personal information stored in our databases and request to correct, erase, or block any
            data that is incorrect or processed in violation of the Privacy Shield Principles, as permitted by
            applicable law and Pendulum policies. If you would like to request access to, or correction of, your
            personal information we hold about you, please contact us by sending an email to support@pendulumfn.com. We
            will respond to all such requests within thirty (30) days and will provide you with access to the
            information we hold about you, including for the purpose of correcting or updating that information, unless
            a legal exemption from providing such access applies. If we refuse to provide you with access to, or
            correct, the information, we will notify you of our reasons for the refusal to the extent required and how
            you may complain about the refusal.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            We ask individual users to identify themselves and the information requested to be accessed, corrected or
            removed before processing such requests, and we may decline to process requests that are unreasonably
            repetitive and vexatious, require disproportionate technical effort (for instance, requests concerning
            information residing on backup tapes), jeopardize the privacy of others, or would be extremely impractical,
            or for which access is not otherwise required. In any case where we provide information access and
            correction, we perform this service free of charge, except if doing so would require a disproportionate
            effort. However, we will not charge you for the making of the request or to correct or update your personal
            information.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Please note that if you cease using the Site or we terminate your access to Pendulum&rsquo;s products or
            services, you may no longer have the ability to access or update your personal information.
          </span>
        </p>
        <p className='c0'>
          <span className='c4 c2'>11. Confidentiality, Storage and Security</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            We use physical, electronic and procedural safeguards to protect personal information about you. We hold
            your personal information in a combination of hard copy and electronic files for the period necessary to
            support our products and services, comply with our legal obligations, resolve disputes, or otherwise fulfill
            the purposes outlined in this Privacy Policy. Even after you request to your delete your information, copies
            of some information from your account may remain viewable in some circumstances where, for example, you have
            shared information with social media or other services. We may also retain backup information related to
            your account on our servers for some time after cancelation for fraud detection or to comply with applicable
            law or our internal security policies. We do not always remove or delete all of your information for a
            number of reasons including due to technical and systems constraints, contractual, financial or legal
            requirements.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            If Pendulum learns of a security breach that may affect your data, we may attempt to notify you
            electronically so that you can take appropriate protective steps. We may communicate with you electronically
            regarding security, privacy and administrative issues related to your use of the Site. Depending on where
            you live, you may have a legal right to receive notice of a security breach in writing.
          </span>
        </p>
        <p className='c0 c6'>
          <span className='c4 c2'></span>
        </p>
        <p className='c0'>
          <span className='c4 c2'>12. Terms of Service, Notices and Revisions</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            Please also see our Terms of Service, which describe the restrictions, disclaimers, indemnification and
            limitation of liability governing the use of the Site.
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            We reserve the right to revise, modify or update this Privacy Policy at any time. We encourage you to refer
            to this Privacy Policy on an ongoing basis so that you understand our current privacy practices. Unless
            stated otherwise in a separate agreement or privacy statement, this Privacy Policy applies to all
            information that we have about you. We may also issue specific information collection and/or privacy notices
            from time-to-time relating to particular products or services.
          </span>
        </p>
        <p className='c0 c6'>
          <span className='c3 c2'></span>
        </p>
        <p className='c0'>
          <span className='c4 c2'>13. Notice to California Residents: Your California Privacy Rights</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            California Civil Code Section 1798.83 permits customers who are California residents to request that
            Pendulum not share their personal data with third parties for such parties&rsquo; direct marketing purposes.
            To make such a request, please send an e-mail with &ldquo;California Privacy&rdquo; in the subject line to
            info@pendulumfn.com or write to us at the address listed below. Please note that even if you do choose to
            opt out, we may still share your personally identifiable information with third-parties for purposes other
            than direct marketing, such as for completing a requested transaction or any other non-direct marketing
            purposes permitted under this Privacy Policy.
          </span>
        </p>
        <p className='c0 c6'>
          <span className='c2 c3'></span>
        </p>
        <p className='c0'>
          <span className='c4 c2'>14. Contact Us</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>
            If you have questions or suggestions about this Privacy Policy, or to make any request described above,
            please feel free to contact us by email at support@pendulumfn.com or by mail directed to:
          </span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>Privacy Request</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>&zwj;Pendulum Intelligence, Inc.</span>
        </p>
        <p className='c0'>
          <span className='c3 c2'>999 3rd Ave</span>
          <span className='c3 c2'>
            <br />
            Suite 3300
          </span>
          <span className='c3 c2'>
            <br />
            Seattle, WA 98104
          </span>
        </p>
      </div>
    </div>
  )
}
