import type { MouseEvent } from 'react'
import { Button, Modal, Tag } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'

import { UtilService } from 'services/Util/Util'
import { WatchlistChannel, sourcePlatform } from 'models/models'

import styles from './WatchlistLinksModal.module.scss'

const PlatformTitle = ({ platform }: { platform: Lowercase<sourcePlatform> }) => {
  return (
    <div className={styles.platformTitleWrapper}>
      <span className={styles.icon}>{UtilService.getPlatformBrandIcon(platform)}</span>
      <span className={styles.titleText}>{UtilService.getPlatformBrandName(platform)} Links</span>
    </div>
  )
}

interface WatchlistLinksModalProps {
  isModalVisible: boolean
  closeModal: (event: MouseEvent) => void
  channel: WatchlistChannel | null
  deleteAllLinksHandler?: () => void
  onLinkRemoveHandler?: (link: string) => void
  isEditMode?: boolean
}

export const WatchlistLinksModal: React.FC<WatchlistLinksModalProps> = ({
  isModalVisible,
  closeModal,
  channel,
  deleteAllLinksHandler,
  onLinkRemoveHandler,
  isEditMode = false,
}) => {
  const handleDeleteAllLinks = () => {
    if (!isEditMode) {
      // Modal is not opened in Edit mode. Do not remove the links
      return
    }

    if (deleteAllLinksHandler) {
      deleteAllLinksHandler()
    }
  }

  const handleLinkRemove = (link: string) => {
    if (!isEditMode) {
      // Modal is not opened in Edit mode. Do not remove the closed link
      return
    }

    if (onLinkRemoveHandler) {
      onLinkRemoveHandler(link)
    }
  }

  return (
    <Modal
      centered
      open={isModalVisible}
      onCancel={closeModal}
      title={<PlatformTitle platform={channel?.platform as Lowercase<sourcePlatform>} />}
      closeIcon={<CloseIcon />}
      wrapClassName={styles.watchlistLinksModalWrapper}
      footer={null}>
      <div className={styles.linksModalBody}>
        <div className={styles.bodyHeader}>
          <div className={styles.totalLinksCount}>Total Links: {channel?.channel_urls.length}</div>

          {isEditMode && (
            <div className={styles.actionButtons}>
              <Button type='text' onClick={handleDeleteAllLinks}>
                Delete All
              </Button>
            </div>
          )}
        </div>

        <div className={styles.linksList}>
          {channel?.channel_urls.map((channelUrl) => (
            <Tag
              key={uuidv4()}
              closable={isEditMode}
              closeIcon={<CloseIcon />}
              onClose={() => handleLinkRemove(channelUrl)}
              className={styles.singleLink}>
              {channelUrl}
            </Tag>
          ))}
        </div>
      </div>
    </Modal>
  )
}
