import './wdyr'
import axios from 'axios'
import { Amplify } from 'aws-amplify'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'

import reportWebVitals from './reportWebVitals'
import { cognitoConfig } from 'configs/cognito.config'
import { UserRoleProvider } from 'hooks/UserRoleContext'

import App from './App'

import './index.css'

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID || '',
})

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: cognitoConfig.REGION,
    userPoolId: cognitoConfig.USER_POOL_ID,
    identityPoolId: cognitoConfig.IDENTITY_POOL_ID,
    userPoolWebClientId: cognitoConfig.APP_CLIENT_ID,
    federationTarget: 'COGNITO_USER_POOLS',
    oauth: {
      domain: cognitoConfig.AUTH_DOMAIN,
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'], // Specify the desired scopes
      redirectSignIn: cognitoConfig.REDIRECT_SIGNIN, // Specify the redirect URL after sign-in
      redirectSignOut: cognitoConfig.REDIRECT_SIGNOUT, // Specify the redirect URL after sign-out
      responseType: 'code', // Specify the response type
    },
  },
})

axios.defaults.baseURL = process.env.REACT_APP_API

const rootElement = document.getElementById('root')
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <UserRoleProvider>
      <App />
    </UserRoleProvider>,
  )
} else {
  console.error("The 'root' element was not found in the document.")
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// TODO: Start using web vitals

reportWebVitals()
