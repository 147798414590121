import axios from 'axios'

interface Props {
  is_experimental?: number
}
export const getDashboardUrl = async ({ is_experimental }: Props) => {
  let url = `${process.env.REACT_APP_API}/analyze/dashboard`
  if (is_experimental === 1) url += '?is_experimental=1'
  const { data } = await axios.get(url)
  return data.EmbedUrl
}
