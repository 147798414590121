import { Col, Row } from 'antd'
import StepsHeader from '../components/StepsHeader'
import Input from 'components/common/Inputs/Input/Input'
import { ReactComponent as AddIcon } from 'assets/images/add-icon.svg'
import Tag from '../components/Tag'
import EditButton from '../components/EditButton'
import ReviewContentDisplay from '../components/ReviewContentDisplay/ReviewContentDisplay'
import { SectionObjType } from '../CreateReport'
import './commonStyles.scss'

const DESCRIPTION = 'Confirm your selections and preview your report before submission.'

const Review = ({
  recurrenceType,
  sections,
  reportName,
  setReportName,
  errors,
  setErrors,
  setOpenEditRecurrence,
  openSectionToEdit,
  addNewSection,
  editMode,
}: {
  recurrenceType: string
  reportName: string
  errors: any
  setErrors: (erros: any) => void
  setReportName: (name: string) => void
  sections: SectionObjType[]
  setOpenEditRecurrence: (status: boolean) => void
  openSectionToEdit: (section: SectionObjType) => void
  addNewSection: () => void
  editMode?: boolean
}) => {
  const onChangeReportName = (event: any) => {
    if (errors.reportName && event.target.value) {
      const { reportName, ...existingErrors } = errors
      setErrors(existingErrors)
    }
    setReportName(event.target.value)
  }

  return (
    <Row>
      <Col span={6}></Col>
      <Col span={12}>
        {!editMode ? (
          <StepsHeader title='Review' stepText={'Step 4'} description={DESCRIPTION} />
        ) : (
          <div className='review-form__spacing' />
        )}
        <div className='review-form'>
          <div className='review-form__step'>
            <div className='review-form__title'>
              <span>Report name</span>
            </div>
            <div className='review-form__content'>
              <Input placeholder='Name this Report' value={reportName} onChange={onChangeReportName} />
              {errors.reportName && <span className='content-select__title-input__error'>{errors.reportName}</span>}
            </div>
          </div>
          <div className='review-form__step'>
            <div className='review-form__title'>
              <span>Recurrence</span>
              <div className='review-form__title__action'>
                <Tag text={recurrenceType} />
                <EditButton onClick={() => setOpenEditRecurrence(true)} />
              </div>
            </div>
          </div>
          <div className='review-form__step'>
            <div className='review-form__title'>
              <span>Content</span>
              <div className='review-form__title__action'>
                <div className='edit-button' onClick={addNewSection}>
                  <AddIcon />
                  <span>Add section</span>
                </div>
              </div>
            </div>
            <div className='review-form__content__sections'>
              {sections.map((section) => (
                <ReviewContentDisplay section={section} openSectionToEdit={openSectionToEdit} />
              ))}
            </div>
          </div>
        </div>
      </Col>
      <Col span={6}></Col>
    </Row>
  )
}

export default Review
