import React from 'react'
import { Modal } from 'antd'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import { ModalTitle } from './ModalTitle/ModalTitle'
import ReportModalBody from './ReportModalBody'
import styles from './ReportModal.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { sectionObj } from 'store/report/valueTypes'

type ReportModalProps = {
  reportId?: string
  editMode?: boolean
  isModalVisible: boolean
  closeModal: () => void
  icon: React.ReactNode
  title: string
  isSection?: boolean
  sectionData?: sectionObj
  isDelete?: boolean
  description: string
  footerButtonText: string
  closeCreateAndOpenSuccessModal?: (data?: object) => void
  closeDeleteAndOpenSuccessModal?: () => void
}

const ReportModal = (props: ReportModalProps) => {
  const {
    reportId,
    editMode,
    isModalVisible,
    closeModal,
    icon,
    title,
    isSection,
    sectionData,
    isDelete = false,
    description,
    footerButtonText,
    closeCreateAndOpenSuccessModal = () => {},
    closeDeleteAndOpenSuccessModal = () => {},
  } = props
  return (
    <Modal
      centered
      open={isModalVisible}
      onCancel={closeModal}
      title={<ModalTitle icon={icon} title={title}></ModalTitle>}
      closeIcon={<CloseIcon />}
      wrapClassName={styles.ReportModalWrapper}
      maskClosable={false}
      footer={null}>
      <ReportModalBody
        reportId={reportId}
        editMode={editMode}
        isSection={isSection}
        sectionData={sectionData}
        isDelete={isDelete}
        cancelHandler={closeModal}
        closeCreateAndOpenSuccessModal={closeCreateAndOpenSuccessModal}
        closeDeleteAndOpenSuccessModal={closeDeleteAndOpenSuccessModal}
        description={description}
        footerButtonText={footerButtonText}
      />
    </Modal>
  )
}
export default ReportModal
