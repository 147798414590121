import axios from 'axios'

import { ROUTES } from 'settings/settings'

interface AddSnippetToBookmarkAPIProps {
  payload: {
    entity_id: string
    entity_type: 'SNIPPET'
    bookmark_ids: string[]
  }
}

export const addSnippetToBookmark = async ({ payload }: AddSnippetToBookmarkAPIProps) => {
  let url = `${process.env.REACT_APP_API}/${ROUTES.bookmark}/add`

  const { data } = await axios.post(url, payload)

  return data
}
