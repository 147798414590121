import axios from 'axios'
import { SnippetFilterPayload } from 'models/models'
import { ROUTES } from 'settings/settings'

export const getSummaryV2 = async (
  { query, sort, conditions }: SnippetFilterPayload,
  shortSummary: boolean = false,
  version?: number | null,
) => {
  const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`
  const queryParams = `q=${q}&sort=${sort}${shortSummary ? `&summary_length=short` : ''}${
    version ? `&summary_option=${version}` : ''
  }`

  const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.powerInsightsSummaryV2}?${queryParams}`

  return axios.post(endpoint, { conditions })
}
