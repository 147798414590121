import React, { FunctionComponent, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import './TopicsChart.scss'

type sentimentsType = 'Positive' | 'Negative' | 'Neutral'

type sentimentBarArr = sentimentsType[]

type ChartObj = {
  negative_sentiment: number
  neutral_sentiment: number
  no_sentiment: string | number
  positive_sentiment: number
  topic_name: string
  total_count: string | number
}

type TopicsChartProps = {
  data: ChartObj[]
}

const sentimentsNames = {
  positive_sentiment: 'Positive',
  negative_sentiment: 'Negative',
  neutral_sentiment: 'Neutral',
}

// const chartData = [
//   {
//     topic_name: 'GDPR Compliance: Navigating the Maze',
//     total_count: 134556,
//     positive_sentiment: 4080,
//     negative_sentiment: 40,
//     neutral_sentiment: 900,
//     no_sentiment: 4000,
//   },
//   {
//     topic_name: 'Cookie Consent: Tracking and User Privacy',
//     total_count: 1345,
//     positive_sentiment: 800,
//     negative_sentiment: 70,
//     neutral_sentiment: 110,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: 'Sector Localization: Healthcare',
//     total_count: 1345,
//     positive_sentiment: 1000,
//     negative_sentiment: 50,
//     neutral_sentiment: 500,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: 'Data Deal: US-UK Trade Partnerships',
//     total_count: 1345,
//     positive_sentiment: 510,
//     negative_sentiment: 900,
//     neutral_sentiment: 480,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: 'G20: Data Free Flow With Trust',
//     total_count: 1345,
//     positive_sentiment: 700,
//     negative_sentiment: 20,
//     neutral_sentiment: 70,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: `India's Digital Personal Data Bill`,
//     total_count: 1345,
//     positive_sentiment: 1100,
//     negative_sentiment: 400,
//     neutral_sentiment: 800,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: `Domien Mac's Famous Donut Venue Openend`,
//     total_count: 134556,
//     positive_sentiment: 4080,
//     negative_sentiment: 40,
//     neutral_sentiment: 900,
//     no_sentiment: 4000,
//   },
//   {
//     topic_name: `Domien Mac's Famous Donut Venue Openend two`,
//     total_count: 134556,
//     positive_sentiment: 4080,
//     negative_sentiment: 40,
//     neutral_sentiment: 900,
//     no_sentiment: 4000,
//   },
//   {
//     topic_name: 'GDPR Compliance: Navigating the Maze two',
//     total_count: 134556,
//     positive_sentiment: 4080,
//     negative_sentiment: 40,
//     neutral_sentiment: 900,
//     no_sentiment: 4000,
//   },
//   {
//     topic_name: 'Cookie Consent: Tracking and User Privacy three',
//     total_count: 1345,
//     positive_sentiment: 800,
//     negative_sentiment: 70,
//     neutral_sentiment: 110,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: 'Sector Localization: Healthcare four',
//     total_count: 1345,
//     positive_sentiment: 1000,
//     negative_sentiment: 50,
//     neutral_sentiment: 500,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: 'Data Deal: US-UK Trade Partnerships five',
//     total_count: 1345,
//     positive_sentiment: 510,
//     negative_sentiment: 900,
//     neutral_sentiment: 480,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: 'G20: Data Free Flow With Trust six',
//     total_count: 1345,
//     positive_sentiment: 700,
//     negative_sentiment: 20,
//     neutral_sentiment: 70,
//     no_sentiment: 145,
//   },
//   {
//     topic_name: `India's Digital Personal Data Bill seven`,
//     total_count: 1345,
//     positive_sentiment: 1100,
//     negative_sentiment: 400,
//     neutral_sentiment: 800,
//     no_sentiment: 145,
//   },
// ]

const CustomizedXAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload } = props
  return (
    <>
      <g transform={`translate(${x + 5},${y + 20})`}>
        <text textAnchor='end' className='topics_chat_axis_text'>
          {payload.value}
        </text>
      </g>
    </>
  )
}

const CustomizedYAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload } = props
  const textLength = 34
  return (
    <g transform={`translate(${x - 10},${y})`}>
      <text textAnchor='end' className='topics_chat_axis_text'>
        {payload.value.length > textLength ? payload.value.substr(0, textLength) + '...' : payload.value}
      </text>
    </g>
  )
}

const CustomLegend = (props: any) => {
  const { payload, disabledBars, onClick } = props

  return (
    <ul className='tcc_legend'>
      {payload.map((entry: any, index: number) => {
        const value = sentimentsNames[entry.value as keyof typeof sentimentsNames]
        return (
          <li key={`item-${index}`}>
            <div
              className={`tcc_legend_list${disabledBars.includes(value) ? ' tcc_legend_list_disabled' : ''}`}
              onClick={(e) => {
                e.stopPropagation()
                onClick(value)
              }}>
              <div className='tcc_ll_square' style={{ backgroundColor: entry.color }}></div>
              <p className='tcc_ll_text'>{value}</p>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

function TopicsChart(props: TopicsChartProps) {
  const { data } = props
  const [disabledBars, setDisabledBars] = useState<sentimentBarArr>([])

  const handleLegendClick = (dataKey: string) => {
    if (disabledBars.includes(dataKey as sentimentsType)) {
      setDisabledBars(disabledBars.filter((key) => key !== dataKey))
    } else {
      setDisabledBars([...disabledBars, dataKey as sentimentsType])
    }
  }

  return (
    <div className='topics_chart_container'>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart barSize={40} width={500} height={300} data={data} layout='vertical'>
          <CartesianGrid stroke='#e9ebef' horizontal={false} />

          <XAxis
            axisLine={{ stroke: '#cbd4dc', strokeWidth: 1 }}
            type='number'
            tick={<CustomizedXAxisTick />}
            tickLine={false}
            tickCount={6}
            interval={0}
            // domain={[0, 5000]}
          />
          <YAxis
            axisLine={{ stroke: '#cbd4dc', strokeWidth: 1 }}
            type='category'
            dataKey='topic_name'
            width={242}
            tick={<CustomizedYAxisTick />}
            tickLine={false}
            interval={0}
          />
          <Tooltip />
          <Legend align='left' content={<CustomLegend disabledBars={disabledBars} onClick={handleLegendClick} />} />
          <Bar
            dataKey='negative_sentiment'
            stackId='a'
            // fill='#DE4E4E'
            fill='#c7699e'
            hide={disabledBars.includes('Negative')}
          />
          <Bar
            dataKey='neutral_sentiment'
            stackId='a'
            // fill='#18A0FB'
            fill='#52b0db'
            hide={disabledBars.includes('Neutral')}
          />
          <Bar
            dataKey='positive_sentiment'
            stackId='a'
            // fill='#18B368'
            fill='#80c0c2'
            hide={disabledBars.includes('Positive')}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default TopicsChart
