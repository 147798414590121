import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'
import { SnippetFilterPayload } from 'models/models'

interface Props extends SnippetFilterPayload {
  summaryHints?: string[]
}

export async function getSummary({ query, summaryHints, conditions }: Props) {
  try {
    const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`

    const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.summary}?q=${q}`

    const data = await axios.post(endpoint, { conditions, summary_hints: summaryHints })
    return data?.data
  } catch (error) {
    LogService.error({ message: 'getSummary error', error })
  }
}
