export function getPastDate(days: number) {
  const currentDate = new Date()

  const last90DaysDate = new Date(currentDate)
  last90DaysDate.setDate(currentDate.getDate() - days)

  const formattedDate = last90DaysDate.toISOString().split('T')[0] // This gives the date in YYYY-MM-DD format

  return formattedDate
}

// console.log('Last 90 days date:', getPastDate(90))
// console.log('Last 30 days date:', getPastDate(30))
// console.log('Last 7 days date:', getPastDate(7))
// console.log('Yesterday date:', getPastDate(1))
