import { observer } from 'mobx-react-lite'
import { Avatar, Drawer, Dropdown } from 'antd'
import { store } from 'store'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import profileImage from 'assets/images/LogoiconMobile.svg'
import classNames from 'classnames'
import { ReactComponent as AddIcon } from 'assets/images/add-icon-grey.svg'
import { ReactComponent as MoreItems } from 'assets/images/three-dots.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as BanIcon } from 'assets/images/icons/assets/ban.svg'

import { useEffect, useState } from 'react'
import { flagsAPI } from 'api/api'
import DeactivatedBrands from './DeactivatedBrands'
import { openNotification } from 'services/Util/openNotification'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'

import { ContactUs } from 'components/common/ContactUs/ContactUs'
import CreateAssetModal from 'components/Asset/CreateAssetModal/CreateAssetModal'
import './BrandsListVertical.scss'

type PropsType = {
  showBrandList: boolean
  selectedBrand: AssetListObjType | undefined
  setSelectedBrand: (brand: AssetListObjType) => void
}

const BRANDS = 'Brands'

const BrandsListVertical = observer((props: PropsType) => {
  const { setSelectedBrand, selectedBrand, showBrandList } = props
  const { assetsStore } = store
  const { assetsList, assetsCategories, fetchAssetsList, deleteAsset } = assetsStore
  const [deactivatedBrands, setDeactivatedBrands] = useState([])
  const [isOpenDeactivatedBrandsModal, setIsOpenDeactivatedBrandsModal] = useState(false)
  const [brandIdToDelete, setBrandIdToDelete] = useState<string | undefined>()
  const [isOpenAddNewBrand, setIsOpenAddNewBrand] = useState(false)
  const [brandToEdit, setBrandToEdit] = useState<AssetListObjType>()
  const brandCategory = assetsCategories.find((item) => item.name === BRANDS)
  const [openContactModal, setOpenContactModal] = useState(false)

  const getDeactivatedBrands = async (brandCategory: any) => {
    try {
      const params: AssetListParamsType = {
        page: 1,
        per_page: 100,
        sort: 'name:asc',
        is_asset: true,
        is_active: false,
        q: `category_id:eq:${brandCategory?.category_id}`,
      }
      const response = await flagsAPI.getFlagsList({ params })
      setDeactivatedBrands(response.data.items)
    } catch (error) {}
  }

  useEffect(() => {
    if (assetsCategories.length === 0) return
    const brandCategory = assetsCategories.find((item) => item.name === BRANDS)
    if (!brandCategory) return
    getDeactivatedBrands(brandCategory)
  }, [assetsCategories])

  const onChangeBrandSelection = (brand: AssetListObjType) => setSelectedBrand(brand)

  const refetchBrandsList = () => {
    const brandCategory = assetsCategories.find((item) => item.name === BRANDS)
    if (!brandCategory) return
    getDeactivatedBrands(brandCategory)
    const params: AssetListParamsType = {
      page: 1,
      per_page: 100,
      sort: 'name:asc',
      is_asset: true,
      is_active: true,
      q: `category_id:eq:${brandCategory?.category_id}`,
    }
    fetchAssetsList({ params: params })
  }

  const onStopEvent = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }

  const deactivateBrand = async (id: string) => {
    try {
      await flagsAPI.toggleFlagStatus({ ids: [id], status: 'deactivate' })
      openNotification({
        type: 'info',
        message: 'Brand deactivated',
      })
    } finally {
      refetchBrandsList()
    }
  }

  const deleteHandler = () => {
    if (!brandIdToDelete) return
    deleteAsset({ id: brandIdToDelete }).then((res: any | 'error') => {
      if (res !== 'error') {
        refetchBrandsList()
      }
      openNotification({
        type: 'info',
        message: 'Brand deleted successfully',
      })
    })
  }

  const searchItemActions = (asset: AssetListObjType) => [
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setBrandToEdit(asset)
          }}
          className='action-item'>
          <EditIcon /> Edit
        </div>
      ),
      key: 'edit-item',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setBrandIdToDelete(asset.id)
          }}
          className='action-item'>
          <DeleteIcon /> Delete
        </div>
      ),
      key: 'delete-item',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            deactivateBrand(asset.id)
          }}
          className='action-item'>
          <BanIcon /> Deactivate
        </div>
      ),
      key: 'deactivate-item',
    },
  ]

  return (
    <div
      className={classNames('brandListVertical', {
        hideBrandList: !showBrandList,
      })}>
      <div className='fixed'>
        {assetsList.items.map((asset) => (
          <div
            onClick={() => onChangeBrandSelection(asset)}
            className={classNames(`brand-item`, {
              'brand-item-selected': selectedBrand?.id === asset.id,
            })}>
            <Avatar src={profileImage} />
            <h6 className='title'>{asset.name}</h6>
            <Dropdown menu={{ items: searchItemActions(asset) }} placement='bottomRight' trigger={['click']}>
              <span className='brand-item__search-actions' onClick={(event) => onStopEvent(event)}>
                <MoreItems />
              </span>
            </Dropdown>
          </div>
        ))}
        <div className='lower-section'>
          <div className='add-new-brand' onClick={() => setIsOpenAddNewBrand(true)}>
            <AddIcon /> Add new brand
          </div>
          {deactivatedBrands.length > 0 && (
            <div className='deactivated-brands' onClick={() => setIsOpenDeactivatedBrandsModal(true)}>
              {deactivatedBrands.length} deactivated brands
            </div>
          )}
        </div>
        {isOpenDeactivatedBrandsModal && (
          <DeactivatedBrands
            onClose={(success) => {
              if (success) {
                refetchBrandsList()
              }
              setIsOpenDeactivatedBrandsModal(false)
            }}
            brandsList={deactivatedBrands}
          />
        )}
        {brandIdToDelete && (
          <DeleteConfirmationModal
            title='Are you sure you want to delete the selected Brand?'
            onClose={() => setBrandIdToDelete(undefined)}
            onContinue={deleteHandler}
          />
        )}
        {isOpenAddNewBrand && (
          <Drawer width={630} placement={'right'} open closeIcon={null} className='search-list-drawer'>
            <CreateAssetModal
              onClose={() => setIsOpenAddNewBrand(false)}
              selectedCategory={brandCategory}
              setOpenSuccessModal={() => {
                setIsOpenAddNewBrand(false)
                refetchBrandsList()
                openNotification({
                  type: 'info',
                  message: 'Asset created successfully',
                  description: 'Your created asset will turn ON if your defined rule matches your search.',
                })
              }}
              setOpenContactModal={() => setOpenContactModal(true)}
              noSuccessActionsNeeded
              drawerManagedByParent
            />
          </Drawer>
        )}
        {brandToEdit && (
          <Drawer width={630} placement={'right'} open closeIcon={null} className='search-list-drawer'>
            <CreateAssetModal
              onClose={() => setBrandToEdit(undefined)}
              selectedCategory={brandCategory}
              isEditMode
              editData={brandToEdit}
              setOpenSuccessModal={() => {
                setBrandToEdit(undefined)
                refetchBrandsList()
                openNotification({
                  type: 'info',
                  message: 'Asset edited successfully',
                })
              }}
              setOpenContactModal={() => setOpenContactModal(true)}
              noSuccessActionsNeeded
              drawerManagedByParent
            />
          </Drawer>
        )}
        <ContactUs openModal={openContactModal} setOpenModal={setOpenContactModal} />
      </div>
    </div>
  )
})

export default BrandsListVertical
