import { feedbackAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'

export class FeedbackStore {
  storeType: 'feedback' = 'feedback'

  submitFeedback = async (feedbackText: string) => {
    try {
      await feedbackAPI.submitFeedback(feedbackText)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }
}
