import { store } from 'store'
import { Select, Switch } from 'antd'
import { observer } from 'mobx-react-lite'

import { ReactComponent as ArrowIcon } from 'assets/images/short_down_arrow.svg'

import './ExportList.scss'

export const ClipReportDownloadsBox = observer(() => {
  const { activeClipReport } = store['monitorExportDownloadsStore']

  return (
    <div className='export_report'>
      <div className='export_report__container'>
        <span className='export_report__title'>Your report is ready for export</span>
        <span className='export_report__description'>
          Great news! Your data export has been successfully processed and is now ready for download.
        </span>
        <div className='export_report__info_container'>
          <div className='export_report__info_container__selection_container'>
            <span className='export_report__info_container__selection_container__label'>
              You are about to download a clip report with:
            </span>
            <span className='export_report__info_container__selection_container__text'>
              {`${activeClipReport?.total_snippets || 0} clips selected`}
            </span>
          </div>
          <div className='export_report__info_container__filters'>
            <span className='export_report__info_container__filters__text'>{`${2} filters applied`}</span>
          </div>
        </div>
        <div className='export_report__info_container2'>
          <span className='export_report__info_container2__description'>
            Toggle options to customize your report with preferred format, title , summary, and conversation details:
          </span>
          <div className='export_report__info_container2__div'>
            <div className='export_report__info_container2__div2'>
              <span className='export_report__info_container2__div2__label'>Include Clip Titles</span>
              <Switch checked={activeClipReport?.include_titles} />
            </div>
            <div className='export_report__info_container2__div2'>
              <span className='export_report__info_container2__div2__label'>Include Executive Summary</span>
              <Switch checked={activeClipReport?.include_summary} />
            </div>
            <div className='export_report__info_container2__div2'>
              <span className='export_report__info_container2__div2__label'>Include Watchlist Conversations</span>
              <Switch checked={false} />
            </div>
          </div>
        </div>
        <div className='export_report__info_container2__div3'>
          <span className='export_report__info_container2__div3__label'>File format</span>
          <Select
            className='export_report__select'
            popupClassName='export_report__file_format_dropdown'
            size='large'
            suffixIcon={<ArrowIcon height={20} width={20} />}
            placeholder={'Choose format'}
            disabled
            value={'Word'}>
            <Select.Option value='Word'>Word</Select.Option>
            <Select.Option value='JSON'>JSON</Select.Option>
          </Select>
        </div>
        <button className='export_report__download' disabled>
          Download
        </button>
      </div>
    </div>
  )
})
