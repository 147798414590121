import { Row } from 'antd'

import PulseLeftComponent from './PulseLeftComponent'
import PulseRightComponent from './PulseRightComponent'
import PulseCenterComponent from './PulseCenterComponent'

import './PendulumPulse.scss'

const PendulumPulse = () => {
  return (
    <div className='pulse-dashboard'>
      <Row>
        <PulseLeftComponent />
        <PulseCenterComponent />
        <PulseRightComponent />
      </Row>
    </div>
  )
}

export default PendulumPulse
