import { Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import FlagsCategories from '../FlagsCategories/FlagsCategories'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal'

import { ReactComponent as AddIcon } from 'assets/images/icons/flag/add_icon_20.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/monitor/close_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-20.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/report/delete-icon-size-20.svg'

import { store } from 'store'
import usePermissions from 'hooks/usePermissions'

import { flagsAPI } from 'api/api'

import { displayApiError } from 'utils/helper'
import { FlagCategoryType, FlagListParamsType } from 'store/flags/types'

import './FlagsHeader.scss'
import TagManager from 'react-gtm-module'

type FlagsHeaderProps = {
  selectedRows: string[]
  setSelectedRows: (value: string[]) => void
  addFlagHandler: () => void
  selectedCategory: FlagCategoryType
  setSelectedCategory: (category: FlagCategoryType) => void
}

const FlagsHeader = observer((props: FlagsHeaderProps) => {
  const { flagsStore, toasterStore, userStore } = store
  const { userId, userName, roleId, tenantId } = userStore
  const { flagsList, fetchFlagsList, deleteFlag, setPaginationValues, isFlagCreated, setFlagsList, setFlagsLoading } =
    flagsStore
  const { showToaster } = toasterStore
  const { selectedRows, setSelectedRows, addFlagHandler, selectedCategory, setSelectedCategory } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [prevSearchValue, setPrevSearchValue] = useState<string>('')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const canCreateFlags = usePermissions('flags') === 'create'

  const activeHandler = async (checked: boolean) => {
    try {
      setFlagsLoading(true)
      await flagsAPI.toggleFlagStatus({ ids: selectedRows, status: checked ? 'activate' : 'deactivate' })
      const requestParams: FlagListParamsType = {
        page: 1,
        per_page: 10,
        sort: 'name:asc',
      }
      setPaginationValues({ page: 1, pageSize: 10 })
      setFlagsList({ total_count: 0, items: [] })
      setSelectedRows([])
      fetchFlagsList({ params: requestParams })
      setFlagsLoading(false)
      TagManager.dataLayer({
        dataLayer: {
          event: 'toggle_flag',
          //@ts-ignore
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
          entityId: selectedRows,
          status: checked ? 'activate' : 'deactivate',
        },
      })
    } catch (error: any) {
      setFlagsLoading(false)
      displayApiError(error)
    }
  }

  useEffect(() => {
    if (isFlagCreated) {
      setSearchValue('')
      setPrevSearchValue('')
    }
  }, [isFlagCreated])

  const searchHandler = (value: string) => {
    if (value || prevSearchValue) {
      setPrevSearchValue(value)
      let requestParams: FlagListParamsType = {
        page: 1,
        per_page: 10,
        sort: 'name:asc',
      }
      if (value) {
        requestParams['q'] = requestParams['q'] ? `${requestParams['q']},name:like:${value}` : `name:like:${value}`
      }
      if (selectedCategory.category_id !== '0') {
        requestParams['q'] = requestParams['q']
          ? `${requestParams['q']},category_id:eq:${selectedCategory.category_id}`
          : `category_id:eq:${selectedCategory.category_id}`
      }
      setPaginationValues({ page: 1, pageSize: 10 })
      fetchFlagsList({ params: requestParams })
    }
  }

  const deleteFlagHandler = () => {
    deleteFlag({ id: selectedRows[0] }).then((res: any | 'error') => {
      if (res !== 'error') {
        showToaster({
          iconType: 'success',
          message: `Successfully deleted ${selectedRows.length} ${selectedRows.length > 1 ? 'Flags' : 'Flag'}`,
        })
        setSelectedRows([])
        setOpenDeleteModal(false)
        const requestParams = {
          page: 1,
          per_page: 10,
          sort: 'name:asc',
        }
        setPaginationValues({ page: 1, pageSize: 10 })
        fetchFlagsList({ params: requestParams })
      }
    })
  }
  const selectedItemStatus = () => {
    return flagsList.items.find((item) => item.id === selectedRows[0])?.is_active || false
  }

  return (
    <div className='flags_header_container'>
      <FlagsCategories
        searchValue={searchValue}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div className='fhc_inner_container'>
        <div className='fhc_search_container'>
          <SearchInput
            className='fhc_sc_input_container'
            placeholder='Search'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={() => {
              searchHandler(searchValue)
            }}
            onClear={() => {
              setSearchValue('')
              searchHandler('')
            }}
          />
          {canCreateFlags && (
            <ButtonImage icon={AddIcon} onClick={addFlagHandler} className='fhc_sc_button'>
              Add new flag
            </ButtonImage>
          )}
        </div>
        {selectedRows.length ? (
          <div className='fhc_ic_action_buttons'>
            <ButtonImage icon={DeleteIcon} onClick={() => setOpenDeleteModal(true)} className='fhc_ic_ab_button'>
              Delete
            </ButtonImage>
            <ButtonImage icon={ShareIcon} onClick={() => {}} className='fhc_ic_ab_button' disabled={true}>
              Share
            </ButtonImage>
            <ButtonImage onClick={() => {}} className='fhc_ic_ab_button'>
              Turn on/off{' '}
              <Switch className='fhc_ic_ab_b_switch' onChange={activeHandler} checked={selectedItemStatus()} />
            </ButtonImage>

            <ButtonImage
              icon={CloseIcon}
              onClick={() => {
                setSelectedRows([])
              }}
              className='fhc_ic_ab_button'>
              Cancel
            </ButtonImage>
          </div>
        ) : null}
      </div>
      {openDeleteModal && (
        <DeleteConfirmationModal onClose={() => setOpenDeleteModal(false)} onContinue={deleteFlagHandler} />
      )}
    </div>
  )
})

export default FlagsHeader
