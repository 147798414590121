import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Input, Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffectOnce } from 'react-use'
import classNames from 'classnames'

import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import { ReactComponent as BookmarkIcon } from 'assets/images/icons/bookmark.svg'
import successGif from 'assets/gifs/done.gif'

import { BookmarkListFormData, ListSelectedRowData } from 'models/models'
import { bookmarksAPI } from 'api/api'
import { store } from 'store'

import styles from './CreateBookmarkModal.module.scss'
import TagManager from 'react-gtm-module'

const ModalTitle = ({ id = '' }: { id?: string }) => {
  const { id: bookmarkId } = useParams()
  const isEditMode = !!bookmarkId || !!id

  return (
    <div className={styles.platformTitleWrapper}>
      <span className={styles.icon}>
        <BookmarkIcon />
      </span>
      <span className={styles.titleText}>{isEditMode ? 'Edit' : 'Create new'} bookmark list</span>
    </div>
  )
}

interface BookmarkListFormComponentProps {
  closeModalHandler: () => void
  onSuccessHandler: () => void
  selectedRowsData?: ListSelectedRowData[]
}

const BookmarkListFormComponent: React.FC<BookmarkListFormComponentProps> = observer(
  ({ closeModalHandler, onSuccessHandler, selectedRowsData = [] }) => {
    const { id: bookmarkId } = useParams()
    const isEditMode = !!bookmarkId || !!selectedRowsData[0]?.id

    const { isLoadingBookmarks } = store.loaderStore
    const { userId, userName, tenantId, roleId } = store.userStore

    const [isFormFilled, setIsFormFilled] = useState(false)
    const [bookmarkListData, setBookmarkListData] = useState<BookmarkListFormData>({
      name: '',
      description: '',
    })

    const inputChangedHandler = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name } = event.target

      setBookmarkListData((prevState) => {
        return {
          ...prevState,
          [name]: event.target.value,
        }
      })
    }, [])

    const createOrUpdateBookmarkHandler = useCallback(async () => {
      if (!isFormFilled) {
        return
      }

      if (isEditMode) {
        // send the update bookmark API call
        await bookmarksAPI.updateBookmark({
          bookmarkId: bookmarkId || selectedRowsData[0]?.id,
          bookmarkData: { ...bookmarkListData },
        })
      } else {
        // send the create bookmark API call
        await bookmarksAPI.createBookmark({ bookmarkData: { ...bookmarkListData } })
        // google analytics create bookmark
        TagManager.dataLayer({
          dataLayer: {
            event: 'create_bookmark',
            entityName: bookmarkListData.name,
            entityDescription: bookmarkListData.description,
            user_id: userId,
            user_name: userName,
            tenantId: tenantId,
            roleId: roleId,
          },
        })
      }

      // bookmark is either created or updated successfully. show the success modal now
      onSuccessHandler()
    }, [isFormFilled, bookmarkListData])

    useEffectOnce(() => {
      if (isEditMode) {
        setBookmarkListData({
          name: selectedRowsData[0]?.name || '',
          description: selectedRowsData[0]?.description || '',
        })
      }
    })

    useEffect(() => {
      setIsFormFilled(bookmarkListData.name !== '')
    }, [bookmarkListData.name])

    return (
      <div className={styles.formModalBody}>
        <div className={styles.bodyHeader}>
          <p className={styles.description}>
            {isEditMode
              ? 'Modify bookmark list names effortlessly to match their contents.'
              : 'Leverage the features of your bookmarking list tool to keep your bookmarks well-organized and easily searchable.'}
          </p>
        </div>

        <div className={styles.bookmarkListForm}>
          <div className={styles.floatingInputWithStaticLabel}>
            <label className={styles.inputLabel}>List Name</label>
            <Input
              name='name'
              className={styles.input}
              value={bookmarkListData.name}
              onChange={inputChangedHandler}
              placeholder='Kinetic Risk'
            />
          </div>

          <div className={classNames(styles.floatingInputWithStaticLabel, styles.mt16)}>
            <label className={styles.inputLabel}>Description</label>
            <Input.TextArea
              name='description'
              className={styles.input}
              autoSize={{ minRows: 3, maxRows: 6 }}
              value={bookmarkListData.description}
              onChange={inputChangedHandler}
              placeholder='Add any helpful description'
            />
          </div>

          <div className={styles.modalFooter}>
            <Button className={styles.cancelButton} onClick={closeModalHandler} disabled={isLoadingBookmarks}>
              Cancel
            </Button>

            <Button
              className={styles.createButton}
              onClick={createOrUpdateBookmarkHandler}
              disabled={!isFormFilled}
              loading={isLoadingBookmarks}>
              {isEditMode ? 'Done' : 'Create'}
            </Button>
          </div>
        </div>
      </div>
    )
  },
)

const SuccessBodyComponent = ({ id }: { id?: string }) => {
  const { id: bookmarkId } = useParams()
  const isEditMode = !!bookmarkId || !!id

  return (
    <div className={styles.successModalBody}>
      <div className={styles.gif}>
        <img src={successGif} alt='success gif' />
      </div>

      <div className={styles.message}>
        {isEditMode ? 'List name updated successfully' : 'Bookmarks list created successfully'}
      </div>

      <div className={styles.subMessage}>
        {isEditMode
          ? 'Stay organized by easily editing and fine-tuning bookmarks list names.'
          : 'Organize and categorize your online discoveries with ease.'}
      </div>
    </div>
  )
}

interface CreateBookmarkModalProps {
  selectedRowsData?: ListSelectedRowData[]
  isModalVisible: boolean
  data?: string
  closeModal: (isBookmarkCreatedOrUpdated?: boolean) => void
  onSuccessHandler?: () => void
}

export const CreateBookmarkModal: React.FC<CreateBookmarkModalProps> = ({
  isModalVisible,
  closeModal,
  onSuccessHandler,
  selectedRowsData = [],
}) => {
  const [activeModal, setActiveModal] = useState<'Delete' | 'Success'>('Delete')

  if (activeModal === 'Success') {
    return (
      <Modal
        centered
        width={390}
        closable={false}
        open={isModalVisible}
        onCancel={() => closeModal(true)}
        wrapClassName={styles.createBookmarkModalWrapper}
        footer={
          <div className={classNames(styles.modalFooter, styles.successModalFooter)}>
            <Button className={styles.doneButton} onClick={() => closeModal(true)}>
              Done
            </Button>
          </div>
        }>
        <SuccessBodyComponent id={selectedRowsData[0]?.id} />
      </Modal>
    )
  }

  return (
    <Modal
      centered
      open={isModalVisible}
      onCancel={() => closeModal(false)}
      title={<ModalTitle id={selectedRowsData[0]?.id} />}
      closeIcon={<CloseIcon />}
      wrapClassName={styles.createBookmarkModalWrapper}
      footer={null}>
      <BookmarkListFormComponent
        selectedRowsData={selectedRowsData}
        closeModalHandler={() => closeModal(false)}
        onSuccessHandler={() => {
          onSuccessHandler?.()
          setActiveModal('Success')
        }}
      />
    </Modal>
  )
}
