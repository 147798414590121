import { Button } from 'antd'
import { useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { STORE_LOOKUP_DICTIONARY } from 'settings/settings'

import { monitorMode } from 'models/models'

import deleteGif from 'assets/gifs/delete.gif'

import './DeleteModal.scss'

interface Props {
  callback?: () => void
  isModalVisible: boolean
  mode: monitorMode | 'person'
  deleteButtonLoading?: boolean
  deleteFlag: () => Promise<void>
  setIsModalVisible: (state: boolean) => void
}

export const DeleteModal = observer(
  ({ isModalVisible, setIsModalVisible, deleteFlag, mode, callback, deleteButtonLoading = false }: Props) => {
    const navigate = useNavigate()

    const [activeModal, setActiveModal] = useState<'Delete' | 'Success'>('Delete')
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)

    const onDelete = async () => {
      try {
        await deleteFlag()
        setIsConfirmationModalVisible(true)
        setActiveModal('Success')
        confirmationModal()
      } catch (error) {
      } finally {
        setIsModalVisible(false)
      }
    }

    const confirmationModal = () => {
      return (
        <Modal
          centered
          closable={false}
          width={387}
          open={isConfirmationModalVisible}
          bodyStyle={{ overflow: 'auto' }}
          className='feed-delete-modal'
          footer={
            <div>
              <button
                className='feed-delete-modal__confirmation-close-btn'
                onClick={() => {
                  setIsConfirmationModalVisible(false)
                  setActiveModal('Delete')
                  if (mode === 'person') {
                    callback?.()
                    return
                  }
                  if (mode === 'bookmark') {
                    callback?.()
                    return
                  } else {
                    navigate(`/monitor/${STORE_LOOKUP_DICTIONARY[mode].toLowerCase()}`)
                  }
                }}>
                Close
              </button>
            </div>
          }>
          <div className='feed-delete-modal'>
            <div className='delete-gif'>
              <img src={deleteGif} alt='delete gif' />
            </div>

            <span className='feed-delete-modal__title feed-delete-modal__margin'>
              {`${mode.charAt(0).toUpperCase()}${mode.slice(1)}`} deleted permanently
            </span>
          </div>
        </Modal>
      )
    }

    return activeModal === 'Delete' ? (
      <Modal
        centered
        closable={false}
        width={387}
        open={isModalVisible}
        bodyStyle={{ overflow: 'auto' }}
        className='feed-delete-modal'
        footer={
          <div>
            <button className='feed-delete-modal__cancel-btn' onClick={() => setIsModalVisible(false)}>
              Close
            </button>
            <Button
              type='primary'
              onClick={onDelete}
              loading={deleteButtonLoading}
              className='feed-delete-modal__delete-btn'>
              Delete
            </Button>
          </div>
        }>
        <div className='feed-delete-modal'>
          <span className='feed-delete-modal__title'>
            Are you sure you want to delete
            <br />
            this {mode} permanently
          </span>
          <span className='feed-delete-modal__description'>You can not recover this {mode} once its deleted</span>
        </div>
      </Modal>
    ) : (
      confirmationModal()
    )
  },
)
