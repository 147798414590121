import { Menu, Dropdown, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import './dropdown.css'
import { useEffect } from 'react'

export default function BottomDropdown({ text, items, setMapCrudView, mapValues, DeleteApifunc, mapValue }) {
  useEffect(() => {}, [items])
  return (
    <Dropdown
      overlay={
        <Menu
          style={{
            border: '1px solid var(--Border-Grey, #E2EBFA)',
            backgroundColor: '#fff',
            borderRadius: '5px',
            width: '150px',
            marginBottom: '8px',
          }}>
          {items.map((item, index) => {
            if (mapValue === 'Default Maps' && item.value !== 'create') {
              return []
            } else if (item.value !== 'delete') {
              return (
                <Menu.Item
                  key={index}
                  style={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    height: '30px',
                    display: 'flex',
                    color: 'black',
                    width: '150px',
                    paddingTop: '1.25rem',
                    paddingBottom: '1.25rem',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                  }}
                  onClick={() => {
                    setMapCrudView(item.value)
                  }}>
                  {item.text}
                </Menu.Item>
              )
            } else {
              return (
                <Menu.Item
                  key='4'
                  style={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    height: '30px',
                    color: '#D92D20',
                    width: '150px',
                    borderTop: '1px solid #E2EBFA',
                    paddingTop: '1.25rem',
                    paddingBottom: '1.25rem',
                  }}
                  onClick={() => {
                    if (item.value === 'delete') {
                      DeleteApifunc()
                    }
                    setMapCrudView(item.value)
                  }}>
                  {item.text}
                </Menu.Item>
              )
            }
          })}
        </Menu>
      }>
      <Button
        style={{
          display: 'flex',
          minWidth: '64px',
          padding: '10px 16px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '6px',
          border: '1px solid #16191D',
          color: '#FFF',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '20px',
          background: '#16191D',
        }}>
        {text}
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}
