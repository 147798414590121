import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { Statistics } from './Statistics/Statistics'
import { ExecutiveSummary } from './ExecutiveSummary/ExecutiveSummary'

import { store } from 'store'

import { SubStore } from 'types/types'

import styles from './PowerInsightsSummary.module.scss'

interface PowerInsightsSummaryProps {
  subStore: SubStore
}

export const PowerInsightsSummary: React.FC<PowerInsightsSummaryProps> = observer(({ subStore }) => {
  const { fetchPowerInsightsSummaryData } = store[`${subStore}Store`]

  useEffect(() => {
    fetchPowerInsightsSummaryData()
  }, [])

  return (
    <div className={styles.powerInsightsSummaryWrapper}>
      <ExecutiveSummary subStore={subStore} />
      <Statistics subStore={subStore} />
    </div>
  )
})
