import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { CopilotBodyType } from 'store/flags/types'

export function getBooleanSearchText({ data }: { data: CopilotBodyType }) {
  return axios({
    method: 'post',
    url: ROUTES.copilot,
    data,
  })
}
