import React from 'react'
import CustomModal from 'components/common/CustomModal/CustomModal'
import { ReactComponent as AlertIcon } from 'assets/images/icons/alertTriangle.svg'
import './ConfirmToCancelModal.scss'

type DeleteReportModalProps = {
  onConfirm: () => void
  onClose: () => void
}

const ConfirmToCancelModal = (props: DeleteReportModalProps) => {
  const { onClose, onConfirm } = props

  const confirmHandler = () => {
    onConfirm()
  }

  return (
    <CustomModal
      icon={AlertIcon}
      iconSize={48}
      title='Are you sure to cancel?'
      firstButtonText='Continue Report'
      secondButtonText='Cancel'
      onClose={onClose}
      onConfirm={confirmHandler}
      containerClassName='ctcm_containerClassName'
      boxContainerClassName='ctcm_box_container'
      bodyClassName='ctcm_body_container'
      footerClassName='ctcm_footer_container'>
      <div className='ctcm_modal_body_container'>
        <p className='ctcm_link_text'>Confirming cancel will result in the loss of all your entered data.</p>
      </div>
    </CustomModal>
  )
}

export default ConfirmToCancelModal
