import { Drawer } from 'antd'
import { ContentForm } from '../steps/Content'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { useState } from 'react'
import { SummarizationForm } from '../steps/Summarization'
import { ReactComponent as CloseIcon } from 'assets/images/icon-close.svg'
import { ReactComponent as PencilIcon } from 'assets/images/icons/report/pencil.svg'
import { SectionObjType } from '../CreateReport'
import { RecurrenceOptions } from '../steps/Recurrence'
import './commonStyles.scss'

export default function AddEditContentSection({
  isOpenAddNewSection,
  closeAddEditSection,
  sectionToEdit,
  recurrenceOptions,
  sections,
}: {
  isOpenAddNewSection: boolean
  closeAddEditSection: (section?: SectionObjType) => void
  sectionToEdit: SectionObjType
  recurrenceOptions: RecurrenceOptions
  sections: SectionObjType[]
}) {
  const [sectionStep, setSectionStep] = useState(1)
  const [errors, setErrors] = useState({})
  const [section, setSection] = useState(sectionToEdit)

  const onContinue = () => {
    if (sectionStep === 2) {
      closeAddEditSection(section)
      return
    }
    if (section.title === '') {
      setErrors({ contentTitle: 'Required' })
      return
    }
    if (sections.find((data) => data.title === section.title && section.id !== data.id)) {
      setErrors({ contentTitle: 'A section with same name already exists' })
      return
    }
    setSectionStep(sectionStep + 1)
  }

  const onCancel = () => {
    if (sectionStep === 2) {
      setSectionStep(1)
      return
    }
    closeAddEditSection()
  }

  return (
    <Drawer open={isOpenAddNewSection} onClose={() => closeAddEditSection()} width={718} closable={false}>
      <div className='edit-form-wrapper'>
        <div className='edit-form-wrapper__header'>
          <div className='title'>
            <PencilIcon />
            <span>{section.isNew ? 'Add' : 'Edit'} section</span>
          </div>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => closeAddEditSection()} />
        </div>
        {sectionStep === 1 ? (
          <ContentForm editMode section={section} updateSection={setSection} errors={errors} setErrors={setErrors} />
        ) : (
          <SummarizationForm
            editMode
            section={section}
            updateSection={setSection}
            recurrenceOptions={recurrenceOptions}
          />
        )}
        <div className='review-form-actions '>
          <ButtonImage onClick={onCancel} className='cancel-btn'>
            {sectionStep === 1 ? 'Cancel' : 'Back'}
          </ButtonImage>
          <ButtonImage onClick={onContinue} className='continue-btn'>
            {sectionStep === 1 ? 'Continue' : 'Save'}
          </ButtonImage>
        </div>
      </div>
    </Drawer>
  )
}
