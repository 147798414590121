import { useEffectOnce } from 'react-use'
import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as Check } from 'assets/images/icon-check.svg'
import { ResetPasswordTitle } from '../ResetPasswordTitle/ResetPasswordTitle'
import './ResetPassStage3.scss'
import { useEffect } from 'react'

interface Props {
  timeLeft: number
  startTimer: () => void
}

export const ResetPassStage3 = ({ timeLeft, startTimer }: Props) => {
  const navigate = useNavigate()
  useEffectOnce(() => {
    startTimer()
  })

  useEffect(() => {
    if (timeLeft === 0) navigate('/', { replace: true })
  }, [timeLeft])

  return (
    <>
      <div className='c-reset-pass-stage-3__checkmark-container'>
        <Check className='c-reset-pass-stage-3__checkmark-check' />
      </div>
      <ResetPasswordTitle>Password Updated</ResetPasswordTitle>

      <p className='c-reset-pass-stage-3__succes-info'>
        Password successfully changed. In <b>{timeLeft} seconds</b> you’ll be automatically logged in.
      </p>
      <p className='c-reset-pass-stage-3__succes-info'>
        If nothing happens, please <Link to='/signin'>click here</Link>.
      </p>
    </>
  )
}
