import moment from 'moment'
import { Button, Modal, Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { DateRangePicker } from 'react-date-range'

import InputForTime from './InputForTime'

import './DateTimeRangeFilter.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DateTimeRangeFilter = ({
  open,
  onConfirm,
  onClose,
  dateRange: value,
  hideTimePicker,
}: {
  open: boolean
  onConfirm: ({ startDateAndTime, endDateAndTime }: { startDateAndTime: Date; endDateAndTime: Date }) => void
  onClose: () => void
  dateRange: {
    startDate: Date
    endDate: Date
  }
  hideTimePicker?: boolean
}) => {
  const [dateRange, setDateRange] = useState({
    startDate: value.startDate,
    endDate: value.endDate,
    key: 'selection',
  })

  const [startTime, setStartTime] = useState(
    value.startDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
  )
  const [endTime, setEndTime] = useState(
    value.endDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
  )
  const [isEndTimeEnabled, setIsEndTimeEnabled] = useState(false)

  const handleDateChange = (item: any) => {
    setDateRange(item.selection)
  }

  const handleTimeChange = (time: string, type: 'start' | 'end') => {
    if (type === 'start') {
      setStartTime(time)
    } else {
      setEndTime(time)
    }
  }

  const handleSwitchChange = (checked: boolean) => {
    setIsEndTimeEnabled(checked)
    if (!checked) {
      setEndTime('11:59 PM')
    }
  }

  const combineDateAndTime = (date: Date, time: string) => {
    return moment(date).format('YYYY-MM-DD') + ' ' + time
  }

  const handleApplyDate = () => {
    onConfirm({
      startDateAndTime: new Date(combineDateAndTime(dateRange.startDate, startTime)),
      endDateAndTime: new Date(combineDateAndTime(dateRange.endDate, endTime)),
    })
  }

  return (
    <Modal
      open={open}
      destroyOnClose
      onOk={() => null}
      closable={true}
      onCancel={onClose}
      className='pulse-date-selection-modal'
      footer={
        <div className='pulse-content-selection-modal__footer'>
          <Button type='text' onClick={onClose}>
            Cancel
          </Button>
          <Button type='primary' onClick={handleApplyDate}>
            Confirm
          </Button>
        </div>
      }>
      <div className='date-time-filter-container'>
        <DateRangePicker
          className='date-filter__calendar-dropdown__container'
          onChange={handleDateChange}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[dateRange]}
          direction='horizontal'
          staticRanges={[]}
          inputRanges={[]}
          editableDateInputs
          dateDisplayFormat={'yyyy-MM-dd'}
          monthDisplayFormat={'MMMM'}
          maxDate={new Date()}
        />

        {!hideTimePicker && (
          <div className='time-inputs-container'>
            <div className='input-label-container'>
              <label>Start from</label>
              <InputForTime value={startTime} onChange={(time) => handleTimeChange(time, 'start')} />
            </div>

            <div className='enable-disable-switch'>
              <span>Add end time</span>
              <Switch
                className='amc_switch'
                checked={isEndTimeEnabled}
                onChange={handleSwitchChange}
                aria-label='Turn On/Off'
              />
            </div>

            {isEndTimeEnabled && (
              <div className='input-label-container'>
                <label>End at</label>
                <InputForTime value={endTime} onChange={(time) => handleTimeChange(time, 'end')} />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default observer(DateTimeRangeFilter)
