import { makeAutoObservable } from 'mobx'
import { Whiteboard, IWhiteboardCreate } from 'models/models'
import { UtilService } from 'services/Util/Util'
import { whiteboardsAPI } from 'api/api'

export class WhiteboardStore {
  whiteboards: Whiteboard[] = []
  currentWhiteboard: Whiteboard | null = null
  isLoading: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  getWhiteboards = async () => {
    let errorMsg = ''
    this.isLoading = true
    try {
      const response = await whiteboardsAPI.listWhiteboards()

      if (response.data) {
        this.isLoading = false
        this.whiteboards = response.data
        return response.data
      } else {
        if (response.error !== 'No whiteboard records found!') {
          errorMsg = response.error
        } else {
          UtilService.openNotification({
            type: 'info',
            message: 'No Whiteboards Found',
            description: 'There are currently no available whiteboards. Create a new whiteboard to get started!',
          })
        }
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
    if (errorMsg) {
      UtilService.openError({
        requestId: '',
        statusCode: 400,
        message: errorMsg,
      })
    }
    this.isLoading = false
    return null
  }

  getWhiteboardById = async (whiteboardId: string) => {
    let errorMsg = ''
    this.isLoading = true
    try {
      const response = await whiteboardsAPI.getWhiteboardById(whiteboardId)

      if (response.data) {
        this.isLoading = false
        return response.data
      } else {
        errorMsg = 'Empty Response'
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
    if (errorMsg) {
      UtilService.openError({
        requestId: '',
        statusCode: 400,
        message: errorMsg,
      })
    }
    this.isLoading = false
    return null
  }

  setCurrentWhiteboard = (whiteboard: Whiteboard) => {
    this.currentWhiteboard = whiteboard
  }

  setCurrentWhiteboardJSON = (whiteboardJSON: object) => {
    if (this.currentWhiteboard) {
      this.currentWhiteboard.whiteboard_json = whiteboardJSON
    }
  }

  saveWhiteboard = async (whiteboardJSON: object) => {
    this.isLoading = true
    const whiteboardData: IWhiteboardCreate = {
      name: this.currentWhiteboard!.name,
      description: this.currentWhiteboard!.description,
      whiteboard_json: whiteboardJSON,
      whiteboard_data: [],
      editor_app_name: 'tldraw',
      editor_app_version: '2.0.0-alpha.18',
    }

    try {
      const response = await whiteboardsAPI.createWhiteboardVersion(this.currentWhiteboard!.id, whiteboardData)
      this.isLoading = false
      if (response.data === null) {
        UtilService.openNotification({
          type: 'error',
          message: 'Bad Request',
          description: 'Failed to save the current whiteboard remotely',
        })
      } else {
        UtilService.openNotification({
          type: 'info',
          message: 'Saved Whiteboard Remotely',
          description: `The current whiteboard ${this.currentWhiteboard!.name} has been saved remotely.`,
        })
        this.setCurrentWhiteboardJSON(whiteboardJSON)
      }
      return response
    } catch (error: any) {
      this.isLoading = false
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  saveCurrentWhiteboardRemotely = async () => {
    if (this.currentWhiteboard) {
      this.isLoading = true
      const whiteboardData: IWhiteboardCreate = {
        name: this.currentWhiteboard.name,
        description: this.currentWhiteboard.description,
        whiteboard_json: this.currentWhiteboard.whiteboard_json,
        whiteboard_data: this.currentWhiteboard.whiteboard_data,
        editor_app_name: this.currentWhiteboard.editor_app_name,
        editor_app_version: this.currentWhiteboard.editor_app_version,
      }
      try {
        const response = await whiteboardsAPI.createWhiteboard(whiteboardData)
        this.isLoading = false
        return response
      } catch (error: any) {
        this.isLoading = false
        const response = error.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })

        return 'error'
      }
    } else {
      console.log('No whiteboard has been set! Cannot save remotely!')
    }
  }

  createNewWhiteboard = async (name: string, description: string) => {
    const whiteboardData: IWhiteboardCreate = {
      name: name,
      description: description,
      whiteboard_json: {},
      whiteboard_data: [],
      editor_app_name: 'tldraw',
      editor_app_version: '2.0.0-alpha.18',
    }
    let errorMsg = ''
    this.isLoading = true
    try {
      const response = await whiteboardsAPI.createWhiteboard(whiteboardData)
      if (response.data) {
        this.isLoading = false
        this.currentWhiteboard = response.data
        return response.data
      } else {
        errorMsg = 'Empty Response'
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
    if (errorMsg) {
      UtilService.openError({
        requestId: '',
        statusCode: 400,
        message: errorMsg,
      })
    }
    this.isLoading = false
    return null
  }
}
