import { Radio, RadioChangeEvent } from 'antd'
import './MentionsViewsSwitch.scss'

type Props = {
  view: string
  onUpdateView: (e: RadioChangeEvent) => void
  options: { value: string; label: string }[]
}
export default function CustomSwitch(props: Props) {
  return (
    <Radio.Group value={props.view} className='radio_group' onChange={props.onUpdateView}>
      {props.options.map((option) => (
        <Radio.Button value={option.value} key={option.value}>
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}
