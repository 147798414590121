import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  narrativeId: any
  feedbackValue: 'THUMBS_UP' | 'THUMBS_DOWN'
  channelId: string
  postId: string
  offset: number
  platform: string
  snippetFilters: any
  communities: any
}

export const recordSnippetFeedback = async ({
  narrativeId,
  feedbackValue,
  channelId,
  postId,
  offset,
  platform,
  snippetFilters,
  communities,
}: Props) => {
  let url = process.env.REACT_APP_SEARCH_API + ROUTES.snippetFeedback
  const data = await axios.post(url, {
    narrative_id: narrativeId,
    feedback_value: feedbackValue,
    channel_id: channelId,
    post_id: postId,
    offset,
    platform,
    snippet_filters: snippetFilters,
    communities,
  })
  return data
}
