import { store } from 'store'
import { Col, Spin } from 'antd'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { ReactComponent as ArrowUpIcon } from 'assets/images/icons/dashboard/arrow-up.svg'
import { SnippetFilter } from 'components/Monitor/SnippetFilter/SnippetFilter'
import { SnippetList } from 'components/Monitor/SnippetList/SnippetList'

import './PendulumPulse.scss'
const FIVE_MINUTES_IN_MILLISECONDS = 300000

const PulseCenterComponent = () => {
  const { mainStore, loaderStore } = store
  const { isLoadingFeed, isLoadingDefaultPlatforms } = loaderStore
  const {
    fetchSnippetsInfo,
    snippetsFilter,
    snippetsTotal,
    setSnippetsFilter,
    refetchSnippetsForActiveItem,
    newSnippetsFound,
    fetchSnippetsTotalCount,
    setStartPollingForSnippetsCountUpdate,
    defaultPlatforms,
  } = mainStore

  useEffect(() => {
    if (isLoadingDefaultPlatforms) return
    setSnippetsFilter({ ...snippetsFilter, date_key: 'post_uploaded' })
    fetchSnippetsInfo(null)
    return () => {
      setSnippetsFilter({ ...snippetsFilter, date_key: '' })
      setStartPollingForSnippetsCountUpdate(false)
    }
  }, [isLoadingDefaultPlatforms, defaultPlatforms])

  useEffect(() => {
    if (isLoadingDefaultPlatforms) return
    const intervalId = setInterval(() => {
      fetchSnippetsTotalCount()
    }, FIVE_MINUTES_IN_MILLISECONDS)

    return () => clearInterval(intervalId)
  }, [isLoadingDefaultPlatforms, defaultPlatforms])

  return (
    <Col span={12} flex={'auto'} className='pulse-dashboard__snippets-container'>
      <SnippetFilter
        mode={'asset'}
        activeTab={''}
        page={'assets'}
        pageType={'details'}
        subStore={'main'}
        showDateFilter={false}
        showExportButton={false}
        showResetButton={false}
        date_key='post_uploaded'
        searchPlaceholder='Search Posts...'
      />
      <Spin spinning={isLoadingFeed} className='pulse_snippets_loader' />
      <SnippetList
        mode={'asset'}
        isDashboardSnippet
        showSearchChip={(snippetsFilter?.filter_keyword || '').length > 0}
        searchedDataResults={snippetsTotal}
        subStore={'main'}
      />
      {newSnippetsFound > 0 && (
        <div className='new-snippets-banner' onClick={refetchSnippetsForActiveItem}>
          <ArrowUpIcon /> {newSnippetsFound} new posts
        </div>
      )}
    </Col>
  )
}

export default observer(PulseCenterComponent)
