import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { SnippetFilterPayload } from 'models/models'

export const getTreeMapData = async ({ query, conditions }: SnippetFilterPayload) => {
  const q = `${query.date}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`
  const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.powerInsightsTreeMap}?q=${q}`

  const response = await axios.post(endpoint, { conditions })

  return response
}
