import React from 'react'
import { ReactComponent as PlusIcon } from 'assets/images/icons/sectionplus.svg'
import { ReactComponent as MinusIcon } from 'assets/images/icons/sectionminus.svg'
import styles from './Sections.module.scss'

export default function SectionIcon({ isActive }: any) {
  return (
    <div className={styles.sectionIcon}>
      {isActive && <MinusIcon />}
      {!isActive && <PlusIcon />}
    </div>
  )
}
