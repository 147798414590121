import React, { useState, useRef } from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import useClickAway from 'hooks/useClickAway'
import { LastUpdateObj } from 'store/report/valueTypes'
import { ReactComponent as DropdownArrowIcon } from 'assets/images/dropdown-arrow-size-12.svg'
import { ReactComponent as ClockIcon } from 'assets/images/clock.svg'
import styles from './Dropdown.module.scss'

const LAST_UPDATE_FORMATE = 'DD MMM YYYY'

type DropdownProps = {
  value?: string
  onSelect?: (val: object) => void
  showIcon?: boolean
  className?: string
  headerClassName?: string
  bodyClassName?: string
  data: LastUpdateObj[]
  showHistoryText?: boolean
  closeWhenClick?: boolean
  selectedValue?: LastUpdateObj
}

function Dropdown(props: DropdownProps) {
  const {
    value = '17 Jul 2023',
    onSelect,
    showIcon = true,
    className,
    headerClassName,
    bodyClassName,
    data = [],
    showHistoryText = true,
    closeWhenClick,
    selectedValue,
  } = props
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onClickOutside = () => {
    setIsOpen(false)
  }
  useClickAway(dropdownRef, onClickOutside)

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.dropdown_container, className, {
        [styles.dropdown_container_disabled]: !showIcon,
      })}
      onClick={(e) => {
        e.stopPropagation()
        setIsOpen((prevState) => !prevState)
      }}>
      <div className={classNames(styles.dc_value_container, headerClassName)}>
        <p className={styles.value_text}>{value}</p>
        {showIcon && (
          <DropdownArrowIcon className={classNames(styles.dropdown_icon, { [styles.dropdown_icon_rotate]: isOpen })} />
        )}
      </div>
      {isOpen && (
        <div
          className={classNames(styles.dc_content_container, bodyClassName)}
          onClick={(event) => event.stopPropagation()}>
          <ul className={styles.list_container}>
            {showHistoryText && (
              <li className={styles.lc_list}>
                <div className={styles.lc_l_header_container}>
                  <ClockIcon />
                  <p className={styles.hc_text}>History</p>
                </div>
              </li>
            )}
            {data.map((res) => (
              <li key={res.instance_id} className={styles.lc_list}>
                <div
                  className={classNames(styles.lc_l_options_container, {
                    [styles.lc_l_options_container_active]: res.instance_id === selectedValue?.instance_id,
                  })}
                  onClick={(event) => {
                    event.stopPropagation()
                    onSelect?.(res)
                    if (closeWhenClick) {
                      onClickOutside()
                    }
                  }}>
                  <p className={styles.oc_text}>
                    {dayjs(res.start_date).format(LAST_UPDATE_FORMATE)} -{' '}
                    {dayjs(res.end_date).format(LAST_UPDATE_FORMATE)}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Dropdown
