import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from 'store'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import classNames from 'classnames'

// import Swiper core and required modules
import { Mousewheel, Pagination } from 'swiper/modules'
import type { Swiper as SwiperInterface } from 'swiper'

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react'

// Styles must use direct files imports
import 'swiper/scss' // core Swiper
import 'swiper/scss/pagination' // Pagination module

import { ReactComponent as HeroSectionImg } from 'assets/images/riskwand/welcome/hero.svg'

import { ReactComponent as Risk1Snippet } from 'assets/images/riskwand/welcome/risks/1/snippet-risk-1.svg'
import { ReactComponent as Risk1ChordChart } from 'assets/images/riskwand/welcome/risks/1/chord-chart-risk-1.svg'

import { ReactComponent as Risk2Snippet } from 'assets/images/riskwand/welcome/risks/2/snippet-risk-2.svg'
import { ReactComponent as Risk2ChordChart } from 'assets/images/riskwand/welcome/risks/2/chord-chart-risk-2.svg'

import { ReactComponent as Risk3Snippet } from 'assets/images/riskwand/welcome/risks/3/snippet-risk-3.svg'
import { ReactComponent as Risk3ChordChart } from 'assets/images/riskwand/welcome/risks/3/chord-chart-risk-3.svg'

import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-welcome-screen-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-welcome-screen-icon.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube-welcome-screen-icon.svg'
import { ReactComponent as TiktokIcon } from 'assets/images/tiktok-welcome-screen-icon.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-welcome-screen-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-welcome-screen-icon.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-welcome-screen-icon.svg'

import { ReactComponent as EconomicThreat } from 'assets/images/riskwand/welcome/threats/economic.svg'
import { ReactComponent as PoliticalThreat } from 'assets/images/riskwand/welcome/threats/political.svg'
import { ReactComponent as SocialThreat } from 'assets/images/riskwand/welcome/threats/social.svg'
import { ReactComponent as CyberThreat } from 'assets/images/riskwand/welcome/threats/cyber.svg'
import { ReactComponent as EnvironmentalThreat } from 'assets/images/riskwand/welcome/threats/environmental.svg'
import { ReactComponent as GovernanceThreat } from 'assets/images/riskwand/welcome/threats/governance.svg'

import { ReactComponent as HorizonScanImage } from 'assets/images/horizon-scan-welcome-screen-img.svg'
import { ReactComponent as HorizonScanActiveImage } from 'assets/images/riskwand/welcome/scans/horizon-scan.svg'
import { ReactComponent as MidScanImage } from 'assets/images/mid-scan-welcome-screen-img.svg'
import { ReactComponent as MidScanActiveImage } from 'assets/images/riskwand/welcome/scans/mid-range-scan.svg'
import { ReactComponent as CloseScanImage } from 'assets/images/riskwand/welcome/scans/close-scan-welcome-screen-img.svg'
import { ReactComponent as CloseScanActiveImage } from 'assets/images/riskwand/welcome/scans/close-range-scan.svg'

import { ReactComponent as ArrowIcon } from 'assets/images/launch-icon.svg'
import { ReactComponent as MouseIcon } from 'assets/images/mouse-icon.svg'

import { ThreatsCarousel } from './ThreatsCarousel/ThreatsCarousel'
import { FloatingButton } from 'components/common/FloatingButton/FloatingButton'

import './WelcomeScreen.scss'
import styles from './welcomeScreen.module.scss'

const PLATFORM_ICONS = [
  { name: 'Rumble', icon: <RumbleIcon /> },
  { name: 'Reddit', icon: <RedditIcon /> },
  { name: 'BitChute', icon: <BitChuteIcon /> },
  { name: 'Podcast', icon: <PodcastIcon /> },
  { name: 'Youtube', icon: <YoutubeIcon /> },
  { name: 'Tiktok', icon: <TiktokIcon /> },
  { name: 'News', icon: <NewsIcon /> },
]

const THREAT_TOPICS = [
  <EconomicThreat />,
  <PoliticalThreat />,
  <SocialThreat />,
  <CyberThreat />,
  <EnvironmentalThreat />,
  <GovernanceThreat />,
]

const THREAT_IMG_CHANGE_DURATION = 4000

export const WelcomeScreen = observer(() => {
  const navigate = useNavigate()

  const [swiper, setSwiper] = useState<SwiperInterface>()
  const [activeThreatImg, setActiveThreatImg] = useState(0)

  const { userStore } = store
  const { tenantInfo } = userStore

  const goToScoreBoard = () => navigate(`/anticipatory-intelligence/scoreboard`)

  const updateThreatImage = useCallback(() => {
    setActiveThreatImg((prevIndex) => {
      return (prevIndex + 1) % THREAT_TOPICS.length
    })
  }, [activeThreatImg])

  useEffect(() => {
    setTimeout(updateThreatImage, THREAT_IMG_CHANGE_DURATION)
  }, [updateThreatImage])

  return (
    <div className={styles.welcomeScreenWrapper}>
      <div className={styles.swiperParent}>
        <Swiper
          speed={1000}
          onSwiper={setSwiper}
          allowTouchMove={false}
          direction={'vertical'}
          slidesPerView={1}
          mousewheel={{
            forceToAxis: true,
            thresholdDelta: 30,
          }}
          pagination={{
            clickable: true,
            bulletActiveClass: styles.swiperBulletActive,
          }}
          modules={[Mousewheel, Pagination]}>
          <SwiperSlide>
            <div className={styles.heroSection}>
              <div className='welcome-screen__heading'>
                <span className='welcome-screen__heading--black'>Pendulum</span>
                <span className='welcome-screen__heading--prime'> Risk</span>
                <span className='welcome-screen__heading--black'>Wand</span>
              </div>

              <span className='welcome-screen__heading__for-text'>for</span>
              <img className='welcome-screen__logo' src={tenantInfo.logo_url} alt='logo' />

              <div className='welcome-screen__description'>
                <span>We have automated Narrative threat and risk detection so that</span>
                <span>you can focus on making the business better.</span>
              </div>

              <HeroSectionImg />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='welcome-screen__img-content'>
              <Risk1Snippet />
              <Risk1ChordChart />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='welcome-screen__img-content'>
              <Risk2Snippet />
              <Risk2ChordChart />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='welcome-screen__img-content'>
              <Risk3Snippet />
              <Risk3ChordChart />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div>
              <div className='welcome-screen__blind-text-div'>
                <span className='welcome-screen__blind-text'>No Blind Spots</span>
                <div className='welcome-screen__blind-text__description'>
                  <span>Pendulum scans audio and video web posts and flags important</span>
                  <span>content. We have industry leading breadth and depth of coverage into</span>
                  <span>the platforms where people spend their time</span>
                </div>
              </div>

              <div className='welcome-screen__social-icons-div-and-images'>
                <div className='welcome-screen__social-icons-div'>
                  {PLATFORM_ICONS.map((icon) => {
                    return (
                      <div className='welcome-screen__social-icons-div__container'>
                        {icon.icon}
                        <span className='welcome-screen__social-icons-div__container__title'>{icon.name}</span>
                      </div>
                    )
                  })}
                </div>
                <div className='welcome-screen__images'>
                  <div className='welcome-screen__images__data-source-img'></div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className={styles.automatedThreatSlide}>
              <div className='welcome-screen__automatedThreat__container__left'>
                <span className='welcome-screen__automatedThreat__container__left__title'>
                  Automated Threat Discovery
                  <br />
                  and Monitoring
                </span>
                <span className='welcome-screen__automatedThreat__container__left__description'>
                  We have created a comprehensive list of threat topics
                  <br />
                  that may have an impact on your business. Our AI
                  <br />
                  engines help you isolate each topic and summarize
                  <br />
                  the content so you can quickly assess its importance.
                  <br />
                  We can customize these topics to tailor to your
                  <br />
                  business.
                </span>
              </div>

              {/* <div className='welcome-screen__automatedThreat__container__right'>{THREAT_TOPICS[activeThreatImg]}</div> */}
              <div className='welcome-screen__automatedThreat__container__right'>
                <div className={styles.threatsTransitionGroup}>
                  <SwitchTransition mode={'out-in'}>
                    <CSSTransition
                      key={activeThreatImg}
                      addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                      classNames={{
                        enter: styles.threatEnter,
                        enterActive: styles.threatEnterActive,
                        exit: styles.threatExit,
                        exitActive: styles.threatExitActive,
                      }}>
                      <div className={styles.threatTransition}>{THREAT_TOPICS[activeThreatImg]}</div>
                    </CSSTransition>
                  </SwitchTransition>

                  {/* A hacky way to keep the threats visible when the old threat is being fade-out of the view */}
                  {THREAT_TOPICS[activeThreatImg]}
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className={styles.rangeSlide}>
              <ThreatsCarousel
                title={'Horizon Scan'}
                description={
                  <>
                    Monitor general development of threats to spot new
                    <br />
                    trends, learn about new content or assess Geo-political
                    <br />
                    impact.
                  </>
                }
                image={<HorizonScanImage />}
                active={<HorizonScanActiveImage />}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className={styles.rangeSlide}>
              <ThreatsCarousel
                title={'Mid Range'}
                description={
                  <>
                    As threats develop and grow online they may start
                    <br />
                    morphing into more direct impacts to the business like
                    <br />
                    legislation, taxation, etc. You can quickly see which threats
                    <br />
                    are showing signs of change across these vectors and can
                    <br />
                    advise your business partners accordingly.
                  </>
                }
                image={<MidScanImage />}
                active={<MidScanActiveImage />}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className={styles.rangeSlide}>
              <ThreatsCarousel
                title={'Close Range'}
                description={
                  <>
                    When threats start affecting your brand, executives, or
                    <br />
                    partners directly, it's important to have a plan in place to
                    <br />
                    quickly assess the impact and take action. RiskWand helps
                    <br />
                    you triage your attention by codifying and monitoring the
                    <br />
                    key dimensions of your business.
                  </>
                }
                image={<CloseScanImage />}
                active={<CloseScanActiveImage />}
              />

              <div className='welcome-screen__launch'>
                <button className='welcome-screen__launch__btn' onClick={goToScoreBoard}>
                  {'Launch'}
                  <ArrowIcon />
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <FloatingButton label='Skip to dashboard' onClick={goToScoreBoard} />

      <div className={classNames('welcome-screen__floating-mouse-div', { hidden: swiper?.activeIndex === 8 })}>
        <MouseIcon className='welcome-screen__floating-mouse-div__icon' />
        <span className='welcome-screen__floating-mouse-div__text'>Scroll down to discover more</span>
      </div>
    </div>
  )
})
