import classNames from 'classnames'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import ReportModal from 'components/common/Modal/ReportModal'
import ConfirmToCancelModal from '../ConfirmToCancelModal/ConfirmToCancelModal'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import SuccessBodyModal from 'components/common/Modal/SuccessBodyModal/SuccessBodyModal'

import successGif from 'assets/gifs/reportLoader.gif'
import { ReactComponent as ReportIcon } from 'assets/images/icons/report.svg'
import { ReactComponent as ReportButtonIcon } from 'assets/images/icons/report/report-button-stars-icon.svg'

import usePermissions from 'hooks/usePermissions'

import { store } from 'store'

import styles from './MainViewHeader.module.scss'
import TagManager from 'react-gtm-module'
import { useNavigate } from 'react-router-dom'

type MainViewHeaderProps = {
  className?: string
  value: string
  searchReportOnChange: any
  onPressEnter: () => void
  onClear: () => void
  reportsExist?: boolean
}

const MainViewHeader = observer((props: MainViewHeaderProps) => {
  const navigate = useNavigate()
  const { reportMainViewStore, userStore } = store
  const { userId, userName, tenantId, roleId } = userStore
  const { createReportHandler, getReports, isConfirmLoading } = reportMainViewStore
  const { className, value, searchReportOnChange, onPressEnter, onClear, reportsExist } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSuccessModalVisible, setIsSuccesModalVisible] = useState(false)
  const [isConfirmToCancelModalVisible, setIsConfirmToCancelModalVisible] = useState(false)

  const canReviewAndReceive = usePermissions('monitor') === 'review_and_receive'

  const closeModal = () => {
    setIsConfirmToCancelModalVisible(true)
    // setIsModalVisible(false)
  }
  const closeCreateAndOpenSuccessModal = (data: any) => {
    if (!isConfirmLoading) {
      createReportHandler(data).then((res) => {
        if (res === 'error') {
          return
        }

        getReports()
        setIsSuccesModalVisible(true)
        setIsModalVisible(false)
        setTimeout(closeSuccesModal, 5000)
        TagManager.dataLayer({
          dataLayer: {
            event: 'create_report',
            entityName: data.name,
            entityType: 'report',
            user_id: userId,
            tenantId: tenantId,
            roleId: roleId,
            user_name: userName,
          },
        })
      })
    }
  }
  const closeSuccesModal = () => {
    setIsSuccesModalVisible(false)
  }

  return (
    <div className={classNames(styles.main_view_header_container, className)}>
      <p className={styles.heading}>Create and share recurring reports by defining multiple sections in it.</p>
      <div className={styles.right_side}>
        <SearchInput value={value} onChange={searchReportOnChange} onPressEnter={onPressEnter} onClear={onClear} />
        {!canReviewAndReceive && reportsExist && (
          <ButtonImage
            onClick={() => {
              navigate('/reports/create')
            }}
            icon={ReportButtonIcon}>
            Create Report
          </ButtonImage>
        )}
        {/* ...........the create report button ................... */}
        {isModalVisible && (
          <ReportModal
            isModalVisible
            closeModal={closeModal}
            icon={<ReportIcon />}
            title='Create Report'
            closeCreateAndOpenSuccessModal={closeCreateAndOpenSuccessModal}
            description='Create and schedule your report by adding multiple items.'
            footerButtonText='Generate'
          />
        )}
        {isSuccessModalVisible && (
          <SuccessBodyModal
            visibilityController={isSuccessModalVisible}
            gif={successGif}
            heading='Report is being generated ...'
            subheading='it can take up to 2 minutes for generating a report'
          />
        )}
        {isConfirmToCancelModalVisible && (
          <ConfirmToCancelModal
            onClose={() => {
              document.getElementById('report_modal_body')?.focus()
              setIsConfirmToCancelModalVisible(false)
            }}
            onConfirm={() => {
              setIsModalVisible(false)
              setIsConfirmToCancelModalVisible(false)
            }}
          />
        )}
      </div>
    </div>
  )
})
export default MainViewHeader
