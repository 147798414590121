import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { store } from 'store'
import _ from 'lodash'

import { ReactComponent as SuccessIcon } from 'assets/images/tick-colored-icon.svg'

import { PLATFORM_ICONS_GREY } from 'settings/settings'
import { UtilService } from 'services/Util/Util'
import { tenantsAPI } from 'api/api'

import './TenantPlatforms.scss'

export const TenantPlatforms = observer(() => {
  const { id } = useParams()

  const { tenantsStore, navViewStore } = store
  const { activeTenant, setActiveTenantPlatforms } = tenantsStore
  const { isNavOpen } = navViewStore

  const [activePlatforms, setActivePlatforms] = useState<string[]>(activeTenant?.platforms || [])
  const [hasChanged, setHasChanged] = useState<boolean>(false)

  const changeSelectedPlatforms = (platform: string) => {
    const checkPlatform = activePlatforms.find((value) => value === platform.toLowerCase())
    let newValues: string[] = activePlatforms
    if (checkPlatform) {
      newValues = activePlatforms.filter((value) => value !== platform.toLowerCase())
    } else {
      newValues = [...newValues, platform.toLowerCase()]
    }
    setActivePlatforms(newValues)
    setHasChanged(!_.isEqual(_.sortBy(newValues), _.sortBy(activeTenant?.platforms)))
  }

  const savePlatforms = async () => {
    try {
      if (id && hasChanged) {
        const response = await tenantsAPI.updateTenantConfiguration(id, {
          configuration_name: 'DEFAULT_PLATFORM_SUBSETS',
          config_json: {
            default_platforms: activePlatforms,
          },
        })
        if (response) {
          UtilService.openNotification({
            type: 'info',
            placement: 'bottomLeft',
            description: (
              <span className='tenant-modal__notification__description'>{'Changes saved successfully'}</span>
            ),
            className: classNames('tenant-modal__notification', { 'tenant-modal__notification__margin': isNavOpen }),
            icon: <SuccessIcon />,
          })
          setHasChanged(false)
          setActiveTenantPlatforms(activePlatforms)
        }
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  return (
    <div className='tenant-platforms'>
      <div className='tenant-platforms__header'>
        <span className='tenant-platforms__header__title'>Toggle on or off by clicking on any platform.</span>
        <button className='tenant-platforms__header__save-btn' onClick={savePlatforms} disabled={!hasChanged}>
          Save changes
        </button>
      </div>
      <div className='tenant-platforms__buttons-container'>
        {PLATFORM_ICONS_GREY?.map((platform) => (
          <button
            className={classNames('tenant-platforms__buttons', {
              'tenant-platforms__buttons--active': activePlatforms?.includes(platform.title.toLowerCase()),
            })}
            onClick={() => changeSelectedPlatforms(platform.title)}>
            <span>{platform.icon}</span>
            <span>{platform.title}</span>
          </button>
        ))}
      </div>
    </div>
  )
})
