import { Checkbox } from 'antd'
import './styles.scss'

const CONTENT_SOURCES = [
  { key: 'image_caption', value: 'Caption' },
  { key: 'ocr', value: 'OCR' },
  { key: 'description', value: 'Description' },
  { key: 'transcript', value: 'Transcript' },
  { key: 'title', value: 'Title' },
]

export default function ContentSource({
  selected,
  setSelected,
}: {
  selected: string[]
  setSelected: (event: any, value: string) => void
}) {
  return (
    <div className='content-filter-map'>
      {CONTENT_SOURCES.map((source) => (
        <div>
          <Checkbox
            checked={selected.includes(source.key)}
            onChange={(event) => setSelected(event, source.key)}
            className='content-filter-map__checkbox'>
            {source.value}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
