import downloadHtmlAsImage from 'utils/downloadHtmlAsImage'
import { ReactComponent as DownloadIcon } from 'assets/images/icons/report/download-icon.svg'
import { Tooltip } from 'antd'

export const DownloadOption = ({
  elementToCapture,
  contentScrollable = false,
}: {
  elementToCapture: string
  contentScrollable?: boolean
}) => {
  return (
    <div className='download-icon' style={{ cursor: 'pointer' }}>
      <Tooltip title='Download this visual as image' placement='topLeft'>
        <DownloadIcon onClick={() => downloadHtmlAsImage(elementToCapture, contentScrollable)} />
      </Tooltip>
    </div>
  )
}
