import axios from 'axios'

import { ROUTES } from 'settings/settings'

export const listSpotlights = async () => {
  let url = process.env.REACT_APP_API + ROUTES.watchlist

  url = `${process.env.REACT_APP_API}${ROUTES.spotlight}`

  const { data } = await axios.get(url)

  return data
}
