import axios from 'axios'

import { ROUTES } from 'settings/settings'

export const getTenantDetails = async (id: string) => {
  let url = `${process.env.REACT_APP_API}${ROUTES.tenant}/${id}`

  const { data } = await axios.get(url)

  return data
}
