import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'

import WelcomeImage from 'assets/images/TOS-welcome-image.png'

import './AgreementWelcomeModal.scss'

interface Props {
  isModalOpen: boolean
  setIsModalClose: (state: boolean) => void
}
export const AgreementWelcomeModal = observer(({ isModalOpen, setIsModalClose }: Props) => {
  const closeModal = () => {
    setIsModalClose(false)
  }
  return (
    <Modal
      className='agreement-welcome-modal'
      width={400}
      open={isModalOpen}
      closable={false}
      centered
      footer={
        <div className='agreement-welcome-modal__footer'>
          <button className='agreement-welcome-modal__footer__button--skip' onClick={closeModal}>
            Skip
          </button>
          <button className='agreement-welcome-modal__footer__button--continue' onClick={closeModal}>
            Continue
          </button>
        </div>
      }>
      <div>
        <img src={WelcomeImage} alt='welcome-img' />
        <div className='agreement-welcome-modal__title-container'>
          <span className='agreement-welcome-modal__title-container__text'>{'Welcome to Pendulum'}</span>
          <span className='agreement-welcome-modal__description'>{`We’re glad to have you onboard. Let's get started by setting up your company profile and alerts.`}</span>
        </div>
      </div>
    </Modal>
  )
})
