import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { store } from 'store'
import { Auth } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'

import ProfileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as WarningIcon } from 'assets/images/alert-triangle-icon.svg'
import { ReactComponent as CopyIcon } from 'assets/images/clipboard-icon.svg'

import './AgreementAdminModal.scss'

interface Props {
  openModal: string
  setOpenModal: (state: 'admin' | 'user' | 'close') => void
}

export const AgreementAdminModal = observer(({ openModal, setOpenModal }: Props) => {
  const navigate = useNavigate()
  const userAdmins = JSON.parse(localStorage.getItem('user-tos-assigned-admins') || `{"admins":[]}`)?.admins || []

  const { userStore } = store
  const { setUser } = userStore

  const getTitle = () => {
    return (
      <div className='agreement-admin-modal__header'>
        <div className='agreement-admin-modal__header__icon'>
          <WarningIcon />
        </div>
        <span className='agreement-admin-modal__header__title'>
          {'Action Required: Acceptance of Terms of Service'}
        </span>
      </div>
    )
  }

  const getFooter = () => {
    return (
      <button className='agreement-admin-modal__footer__button' onClick={signOut}>
        Log out
      </button>
    )
  }

  const copyEmail = async (email: string) => {
    await navigator.clipboard.writeText(email)
  }

  const signOut = async () => {
    await Auth.signOut()

    setUser({
      given_name: null,
      family_name: null,
      userName: null,
      userEmail: null,
      tenantId: null,
      userId: null,
      roleId: null,
      subId: null,
    })
    setOpenModal('close')
    localStorage.removeItem('user-tos-status')
    localStorage.removeItem('user-tos-assigned-admins')
    navigate('/', { replace: true })
    sessionStorage.removeItem('snippetDateFilter')
  }

  return (
    <Modal
      className='agreement-admin-modal'
      centered
      open={openModal === 'user'}
      closable={false}
      title={getTitle()}
      footer={getFooter()}>
      <div className='agreement-admin-modal__body'>
        <span className='agreement-admin-modal__description'>
          {`Your tenant Admin needs to accept the Terms of Service. Please contact one of the following individuals who can complete this process:`}
        </span>
        {userAdmins.map((admin: any) => {
          return (
            <div className='agreement-admin-modal__container' key={uuidv4()}>
              <div className='agreement-admin-modal__admin-info--img'>
                <img src={ProfileImage} alt='user-img' />
              </div>
              <div className='agreement-admin-modal__info-container'>
                <div className='agreement-admin-modal__admin-info'>
                  <span className='agreement-admin-modal__admin-info--name'>
                    {`${admin.given_name} ${admin.family_name}`}
                  </span>
                  <span className='agreement-admin-modal__admin-info--email'>{admin.username}</span>
                </div>
                <div className='agreement-admin-modal__copy-button' onClick={() => copyEmail(admin.username)}>
                  <CopyIcon />
                  <button className='agreement-admin-modal__copy-button--text'>Copy email</button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Modal>
  )
})
