import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Tldraw, useEditor, track, StoreSnapshot, TLRecord } from '@tldraw/tldraw'
import { store } from 'store'
import styles from './InvestigateWhiteboardPage.module.scss'
import '@tldraw/tldraw/tldraw.css'
import { UtilService } from 'services/Util/Util'
import { useEffect } from 'react'
import _ from 'lodash'

type whiteboardProps = {
  whiteboardId: string
}

const WhiteboardControls = track((props: whiteboardProps) => {
  const { whiteboardStore } = store
  const { whiteboardId } = props
  const editor = useEditor()

  const getRemoteWhiteboard = () => {
    if (whiteboardId === 'test' || whiteboardId !== whiteboardStore.currentWhiteboard?.id) {
      return null
    }
    const currentSnapshot = editor.store.getSnapshot()
    let description = ''
    let remoteSnapshot = {}
    const whiteboardJSON = whiteboardStore.currentWhiteboard?.whiteboard_json
    if (whiteboardJSON && Object.keys(whiteboardJSON).length !== 0) {
      remoteSnapshot = JSON.parse(JSON.stringify(whiteboardJSON))
    }
    if (Object.keys(remoteSnapshot).length === 0) {
      // This is the first time loading this whiteboard, so save it with the default snapshot
      saveWhiteboardRemotely()
    } else if (!_.isEqual(remoteSnapshot, currentSnapshot)) {
      description =
        'The local changes are out of sync with the remote whiteboard. Please click the Load Whiteboard button to revert to the remote whiteboard, or Save Whiteboard to use the current state.'
    }
    if (description) {
      UtilService.openNotification({
        type: 'info',
        message: 'Whiteboard Update',
        description: description,
        duration: 0,
      })
    }
    return whiteboardStore.currentWhiteboard
  }

  const setRemoteWhiteboard = () => {
    const whiteboardJSON = whiteboardStore.currentWhiteboard?.whiteboard_json
    if (whiteboardJSON && Object.keys(whiteboardJSON).length !== 0) {
      const remoteSnapshot = JSON.parse(JSON.stringify(whiteboardJSON)) as StoreSnapshot<TLRecord>
      try {
        editor.store.loadSnapshot(remoteSnapshot)
        UtilService.openNotification({
          type: 'info',
          message: 'Loaded Whiteboard',
          description: 'The remote whiteboard as been loaded successfully!',
        })
      } catch (error: any) {
        const response = error.response
        UtilService.openError({
          requestId: response?.data?.request_id || '',
          statusCode: response?.status || 400,
          message: response?.data?.err_msg || '',
        })
      }
    } else {
      UtilService.openNotification({
        type: 'error',
        message: 'Empty Whiteboard',
        description: 'The remote whiteboard data is empty! Please save the current whiteboard.',
        duration: 0,
      })
    }
  }

  const saveWhiteboardRemotely = () => {
    const snapshot = editor.store.getSnapshot()
    whiteboardStore.saveWhiteboard(snapshot)
  }

  useEffect(() => {
    getRemoteWhiteboard()
  }, [])

  return (
    <div>
      {whiteboardStore.currentWhiteboard?.name && (
        <div className={styles.whiteboard_title}>
          <h3 className={styles.whiteboard_name}>{whiteboardStore.currentWhiteboard!.name}</h3>
          <h4 className={styles.whiteboard_description}>{whiteboardStore.currentWhiteboard!.description}</h4>
        </div>
      )}
      {whiteboardStore.currentWhiteboard && (
        <div>
          <button className={styles.whiteboard_button} onClick={setRemoteWhiteboard}>
            Load Whiteboard
          </button>
          <br />
        </div>
      )}
      <button className={styles.whiteboard_button} onClick={saveWhiteboardRemotely}>
        Save Whiteboard
      </button>
    </div>
  )
})

const InvesigateWhiteboardPage = observer(() => {
  const { whiteboardId } = useParams()
  const { whiteboardStore } = store

  const getRemoteWhiteboard = async () => {
    if (whiteboardId !== 'test' && whiteboardId !== whiteboardStore.currentWhiteboard?.id!) {
      const whiteboardData = await whiteboardStore.getWhiteboardById(whiteboardId!)
      whiteboardStore.setCurrentWhiteboard(whiteboardData)
      return whiteboardStore.currentWhiteboard
    }
    return null
  }

  useEffect(() => {
    getRemoteWhiteboard()
  }, [])

  return (
    <div className={styles.whiteboard_container}>
      <div style={{ height: '100%' }}>
        {(whiteboardStore.currentWhiteboard?.id === whiteboardId || whiteboardId === 'test') && (
          <Tldraw
            persistenceKey={whiteboardStore.currentWhiteboard?.id || 'test'}
            shareZone={<WhiteboardControls whiteboardId={whiteboardStore.currentWhiteboard?.id || 'test'} />}
          />
        )}
      </div>
    </div>
  )
})

export default InvesigateWhiteboardPage
