import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { Input, Tag } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'

import styles from './tokenizedInput.module.scss'

interface TokenizedInputProps {
  placeholder?: string
  initialTokens?: string[]
  onTokensChange?: (tokens: string[]) => void
  isEditMode?: boolean
}

export const TokenizedInput: React.FC<TokenizedInputProps> = ({
  placeholder = 'Use comma for multiple tags',
  initialTokens = [],
  isEditMode = true,
  onTokensChange,
}) => {
  const isMounted = useRef<boolean>(false)

  const [inputValue, setInputValue] = useState<string>('')
  const [tokens, setTokens] = useState<string[]>(initialTokens)
  const [listRef, setListRef] = useState<HTMLDivElement | null>(null)

  const listRefCallback = useCallback((node: SetStateAction<HTMLDivElement | null>) => {
    setListRef(node)
  }, [])

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }, [])

  const handleInputPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedData = event.clipboardData.getData('text')

      const tokensToAdd = pastedData
        .split(/[,\n]/)
        .map((token) => token.trim())
        .filter((token) => token !== '')

      if (tokensToAdd.length > 0) {
        event.preventDefault()

        const newTokens = [...tokens, ...tokensToAdd]
        setTokens(newTokens)
      }
    },
    [tokens],
  )

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' || event.key === 'Tab' || event.key === ',') {
        event.preventDefault()

        const token = inputValue.trim()
        if (token) {
          setTokens([...tokens, token])
        }

        setInputValue('')
      }
    },
    [inputValue, tokens],
  )

  const handleInputBlur = useCallback(() => {
    const token = inputValue.trim()
    if (token) {
      setTokens([...tokens, token])
    }

    setInputValue('')
  }, [inputValue, tokens])

  const removeToken = useCallback(
    (token: string) => {
      const updatedTokens = tokens.filter((t) => t !== token)
      setTokens(updatedTokens)
    },
    [tokens],
  )

  useEffect(() => {
    // scroll the list of tags to the right whenever a new tag is added
    if (listRef) {
      listRef.scrollLeft = listRef.scrollWidth - listRef.clientWidth
    }
  }, [listRef, tokens])

  useEffect(() => {
    // notify parent component about the change in tokens
    if (isMounted.current && onTokensChange) {
      onTokensChange(tokens)
    } else {
      isMounted.current = true
    }
  }, [tokens, onTokensChange])

  return (
    <div ref={listRefCallback} className={styles.tokenizedInputWrapper}>
      {tokens.length > 0 && (
        <div className={styles.tags}>
          {tokens.map((token) => (
            <Tag
              closable={isEditMode}
              closeIcon={<CloseIcon />}
              onClose={() => removeToken(token)}
              key={uuidv4()}
              className={styles.tag}>
              {token}
            </Tag>
          ))}
        </div>
      )}

      {isEditMode && (
        <Input
          className={styles.channelInput}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onBlur={handleInputBlur}
          onPaste={handleInputPaste}
          placeholder={placeholder}
        />
      )}
    </div>
  )
}
