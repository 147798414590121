import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { ReportFeedsFeedback } from 'store/report/valueTypes'

export function reportFeedsFeedback(data: ReportFeedsFeedback) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SEARCH_API}${ROUTES.snippetFeedback}`,
    data: data,
  })
}
