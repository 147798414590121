import React, { memo } from 'react'
import { ReactComponent as CalendarIcon } from 'assets/images/calendar-icon.svg'
import Dropdown from '../Dropdown/Dropdown'
import { LastUpdateObj } from 'store/report/valueTypes'
import styles from './ReportDateRange.module.scss'

type ReportDateRangeProps = {
  data: LastUpdateObj[]
  onSelect: (obj: LastUpdateObj) => void
  value: string
  selectedValue?: LastUpdateObj
  showHeading?: boolean
}

function ReportDateRange(props: ReportDateRangeProps) {
  const { data, onSelect, value, selectedValue, showHeading = true } = props

  return (
    <div className={styles.rdr_container}>
      {showHeading && (
        <div className={styles.rdr_c_header}>
          <CalendarIcon />
          <p className={styles.rdr_c_header_text}>Report Date Range</p>
        </div>
      )}
      <Dropdown
        selectedValue={selectedValue}
        className={styles.rdr_c_dropdown}
        headerClassName={styles.rdr_c_dropdown_header}
        bodyClassName={styles.rdr_c_dropdown_body}
        data={data}
        showHistoryText={false}
        value={value}
        onSelect={(res) => onSelect(res)}
        closeWhenClick={true}
      />
    </div>
  )
}

export default memo(ReportDateRange)
