import './TextArea.scss'

type TextAreaProps = {
  [key: string]: any
  className?: string
  title?: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  value?: string
}

function TextArea(props: TextAreaProps) {
  const { className = '', title, onChange, value, ...rest } = props
  return (
    <div className={`text_area_container ${className}`}>
      {title ? <p className='tac_title'>{title}</p> : null}
      <textarea className='tac_textarea' value={value} onChange={onChange} {...rest} />
    </div>
  )
}

export default TextArea
