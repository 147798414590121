import { Hub } from 'aws-amplify'
import { useEffect } from 'react'

import { store } from 'store'

export function AuthListener(): JSX.Element {
  const { userStore } = store

  async function setAuthListener() {
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          userStore.setUser({
            userName: data.payload?.data?.username,
            given_name: data.payload?.data?.given_name,
            family_name: data.payload?.data?.family_name,
            userEmail:
              data.payload?.data?.attributes?.email || data.payload?.data?.challengeParam?.userAttributes.email,
            tenantId:
              data?.payload?.data?.attributes?.['custom:tenant_id'] ||
              data.payload?.data?.challengeParam?.userAttributes['custom:tenant_id'],
            subId: data.payload?.data?.attributes?.sub || data.payload?.data?.challengeParam?.userAttributes.sub,
            roleId:
              data.payload?.data?.attributes?.['custom:role_id'] ||
              data.payload?.data?.challengeParam?.userAttributes['custom:role_id'],
            userId:
              data.payload?.data?.attributes?.['custom:user_id'] ||
              data.payload?.data?.challengeParam?.userAttributes['custom:user_id'],
          })
          if (data.payload?.data?.attributes?.['custom:role_id']) {
            userStore.checkAndSetCurrentRole(data.payload?.data?.attributes?.['custom:role_id'])
          } else {
            userStore.checkAndSetCurrentRole(data.payload?.data?.challengeParam?.userAttributes?.['custom:role_id'])
          }

          break

        case 'signOut':
          userStore.setUser({
            userName: null,
            userEmail: null,
            given_name: null,
            family_name: null,
            tenantId: null,
            userId: null,
            roleId: null,
            subId: null,
          })
          break
      }
    })
  }

  useEffect(() => {
    setAuthListener()
  }, [])

  return <span></span>
}
