import axios from 'axios'

import { ROUTES } from 'settings/settings'

interface Props {
  page: number
  pageSize: number
  sort: string
  sortOrder: 'asc' | 'desc'
  searchParam?: string
}
export const getTenantPagination = async ({ page, pageSize, sort, sortOrder, searchParam }: Props) => {
  let query = `?page=${page}&per_page=${pageSize}&sort=${sort}:${sortOrder}`
  if (searchParam && searchParam?.length > 0) query += `&q=name:like:${searchParam}`

  let url = process.env.REACT_APP_API + ROUTES.tenant + query
  const { data } = await axios.get(url)

  return data
}

export const getTenant = async () => {
  let url = process.env.REACT_APP_API + ROUTES.tenant
  const { data } = await axios.get(url)

  return data
}
