import { observer } from 'mobx-react-lite'

import { variant } from 'models/models'
import classNames from 'classnames'
import './Card.scss'

interface Props {
  variant: variant
  className?: string
  style?: any
  header: {
    title: string
    menu?: JSX.Element
    icon?: React.ElementType
    iconClass?: string
    iconClick?: (props: any) => void
  }
  children: JSX.Element | JSX.Element[] | string
}
export const Card = observer(({ variant, header, children }: Props) => {
  const { icon: Icon } = header

  return (
    <div className={classNames('card', `card--${variant}`)}>
      <div className='card__body'>
        <div className={classNames('card__container', `card__container--${variant}`)}>
          <div className='card__header'>
            <span className='card__header__title'>{header.title}</span>
            {header.menu
              ? header.menu
              : Icon && (
                  <Icon className={classNames('card__header__icon', header.iconClass)} onClick={header.iconClick} />
                )}
          </div>
          {children}
        </div>
      </div>
    </div>
  )
})
