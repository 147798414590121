import { Col, Row } from 'antd'
import './ListPageSection.scss'

type Props = {
  left: JSX.Element
  right: JSX.Element
  columns: {
    col1: {
      sm: number
      md: number
      lg: number
    }
    col2: {
      sm: number
      md: number
      lg: number
    }
  }
}

export const ListPageSection = ({ left, right, columns, ...rest }: Props) => {
  return (
    <section className='list-page-section' {...rest}>
      <Row style={{ margin: 0 }}>
        <Col
          className='list-page-section__border'
          sm={{ span: columns.col1.sm }}
          md={{ span: columns.col1.md }}
          lg={{ span: columns.col1.lg }}>
          {left}
        </Col>

        <Col sm={{ span: columns.col2.sm }} md={{ span: columns.col2.md }} lg={{ span: columns.col2.lg }}>
          {right}
        </Col>
      </Row>
    </section>
  )
}
