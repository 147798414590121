import { makeAutoObservable } from 'mobx'
import { flagsAPI, monitorAPI, reportsAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'
import {
  ConditionsPayloadParamsForFlagType,
  CopilotBodyType,
  CreateFlagBodyType,
  FlagCategoryType,
  FlagListParamsType,
  FlagsListResponseType,
  KeyValue,
  ListsObjType,
  ResponseListType,
  FlagGraphDataType,
  FlagListObjType,
} from './types'
import { ConditionsPayloadType, NarrativeParamsType, PaginationValuesType } from 'types/types'
import { ReportRoutesType } from 'store/report/valueTypes'
import { ROUTES } from 'settings/settings'
import { AssetListParamsType } from 'store/asset/types'
import { uniqBy } from 'lodash'
import { displayApiError } from 'utils/helper'

export class FlagsStore {
  flagsCategorties: FlagCategoryType[] = []
  flagsList: FlagsListResponseType = {
    total_count: 0,
    items: [],
  }
  flagsLoading: boolean = true

  flagsRelatedLoading: boolean = true

  booleanSearchLoading: boolean = false

  paginationValues: PaginationValuesType = {
    page: 1,
    pageSize: 10,
  }

  narrativePage: number = 2
  communityPage: number = 2

  narrativesList: ResponseListType = {
    total_count: 0,
    items: [],
  }

  communitiesList: ResponseListType = {
    total_count: 0,
    items: [],
  }
  idsBasedList: ListsObjType[] = []
  isFlagCreated: boolean = false

  flagCategoriesGraphData: { [x: string]: FlagGraphDataType } = {}
  hasCategoriesConditionsFetched: boolean = false

  allFlagsGraphData: { [x: string]: FlagGraphDataType } = {}

  constructor() {
    makeAutoObservable(this)
  }

  setFlagsCategorties = (categories: FlagCategoryType[]) => {
    this.flagsCategorties = categories
  }

  setFlagsList = (flagsRes: FlagsListResponseType) => {
    this.flagsList = flagsRes
  }

  setFlagsLoading = (value: boolean) => {
    this.flagsLoading = value
  }

  setFlagsRelatedLoading = (value: boolean) => {
    this.flagsRelatedLoading = value
  }

  setBooleanSearchLoading = (value: boolean) => {
    this.booleanSearchLoading = value
  }

  setPaginationValues = ({ page, pageSize }: PaginationValuesType) => {
    this.paginationValues = { ...this.paginationValues, page, pageSize }
  }

  setNarrativePage = (val: number) => {
    this.narrativePage = val
  }

  setCommunityPage = (val: number) => {
    this.communityPage = val
  }

  setLists = (key: KeyValue, lists: ResponseListType) => {
    this[key] = lists
  }

  setIdsBasedList = (lists: ListsObjType[]) => {
    this.idsBasedList = lists
  }

  setListsWithParams = (key: KeyValue, lists: ResponseListType) => {
    this[key] = { total_count: lists.total_count, items: [...this[key].items, ...lists.items] }
  }

  setIsFlagCreated = (val: boolean) => {
    this.isFlagCreated = val
  }

  setFlagCategoriesForGraph = (categories: { name: string; category_id: string }[]) => {
    const updatedObj: any = {}
    categories.forEach((category) => {
      updatedObj[category.name] = { name: category.name, category_id: category.category_id, expanded: false }
    })
    this.flagCategoriesGraphData = updatedObj
  }

  setFlagCategoriesGraphData = (category: string, data: any) => {
    if (this.flagCategoriesGraphData) {
      this.flagCategoriesGraphData[category] = {
        ...this.flagCategoriesGraphData[category],
        ...data,
      }
    } else {
      this.flagCategoriesGraphData = {
        [category]: { name: category, category_id: '', subCategories: [], ...data },
      }
    }
  }

  setFlagTypeGraphData = (category: string, data: any) => {
    if (this.flagCategoriesGraphData) {
      this.flagCategoriesGraphData[category] = {
        ...this.flagCategoriesGraphData[category],
        subCategories: data,
      }
    } else {
      this.flagCategoriesGraphData = {
        [category]: { name: category, category_id: '', total_value: 0, subCategories: data, expanded: false },
      }
    }
  }

  setHasCategoriesConditionsFetched = (state: boolean) => {
    this.hasCategoriesConditionsFetched = state
  }

  setAllFlagsGraphData = (data: any) => {
    this.allFlagsGraphData[data.name] = data
  }

  fetchCategories = async () => {
    try {
      const response = await flagsAPI.getCategories()
      this.setFlagsCategorties(response.data)
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchFlagsList = async ({
    params,
    isInfiniteScrolling = false,
  }: {
    params: FlagListParamsType
    isInfiniteScrolling?: boolean
  }) => {
    this.setFlagsLoading(true)
    try {
      const response = await flagsAPI.getFlagsList({ params })
      if (isInfiniteScrolling) {
        this.setFlagsList({
          total_count: response.data.total_count,
          items: uniqBy([...this.flagsList.items, ...response.data?.items], (item) => item.id),
        })
      } else {
        this.setFlagsList(response.data)
      }
      return response.data
    } catch (error: any) {
      displayApiError(error)
      return 'error'
    } finally {
      this.setFlagsLoading(false)
    }
  }

  fetchFlagsListWithConditions = async ({
    params,
    isInfiniteScrolling = false,
  }: {
    params: FlagListParamsType
    isInfiniteScrolling?: boolean
  }) => {
    this.setFlagsLoading(true)

    try {
      const response = await flagsAPI.getFlagsList({ params })

      const conditionsPayloadRepo: { [key: string]: object | undefined } = {}
      const promises = response.data.items.map(async (flag: FlagListObjType) => {
        const conditionObj = await this.fetchDataForObject(flag, 'flag')
        conditionsPayloadRepo[flag.name] = conditionObj.conditions
      })

      await Promise.all(promises)

      const newFlagsList = response.data.items.map((flag: FlagListObjType) => ({
        ...flag,
        conditions: conditionsPayloadRepo[flag.name],
      }))

      if (isInfiniteScrolling) {
        this.setFlagsList({
          total_count: response.data.total_count,
          items: uniqBy([...this.flagsList.items, ...newFlagsList], (item) => item.id),
        })
      } else {
        this.setFlagsList({ ...response.data, items: newFlagsList })
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      this.setFlagsLoading(false)
    }
  }

  createFlag = async ({ data, method, id }: { data: CreateFlagBodyType; method?: 'post' | 'put'; id?: string }) => {
    this.setFlagsRelatedLoading(true)
    try {
      const response = await flagsAPI.postFlag({ data, method, id })
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setFlagsRelatedLoading(false)
    }
  }

  deleteFlag = async ({ id }: { id: string }) => {
    try {
      const response = await flagsAPI.removeFlag({ id })
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchAllFlagsRelatedLists = async ({ params }: { params: NarrativeParamsType }) => {
    this.setFlagsRelatedLoading(true)
    try {
      const [narrativesData, communitiesData] = await Promise.all([
        reportsAPI.getListsWithParams({ endpoint: ROUTES.reportNarrative as ReportRoutesType, params }),
        reportsAPI.getListsWithParams({ endpoint: ROUTES.community as ReportRoutesType, params }),
      ])

      this.setLists('narrativesList', narrativesData.data)
      this.setLists('communitiesList', communitiesData.data)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setFlagsRelatedLoading(false)
    }
  }

  fetchNarrativesWithParams = async ({
    params,
    concatination,
  }: {
    params: NarrativeParamsType
    concatination?: boolean
  }) => {
    try {
      const narrativesData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.reportNarrative as ReportRoutesType,
        params,
      })
      if (concatination) {
        this.setListsWithParams('narrativesList', narrativesData.data)
      } else {
        this.setLists('narrativesList', narrativesData.data)
      }

      return narrativesData.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchCommunitiesWithParams = async ({
    params,
    concatination,
  }: {
    params: NarrativeParamsType
    concatination?: boolean
  }) => {
    try {
      const communitiesData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.community as ReportRoutesType,
        params,
      })
      if (concatination) {
        this.setListsWithParams('communitiesList', communitiesData.data)
      } else {
        this.setLists('communitiesList', communitiesData.data)
      }

      return communitiesData.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchBooleanSearchText = async ({ data }: { data: CopilotBodyType }) => {
    this.setBooleanSearchLoading(true)
    try {
      const response = await flagsAPI.getBooleanSearchText({ data })
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setBooleanSearchLoading(false)
    }
  }

  fetchIdsBasedData = async ({ endPoint, id }: { endPoint: 'narrative' | 'community'; id: string }) => {
    try {
      const response = await flagsAPI.getIdsBasedData({ endPoint, id })

      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchIdsBaseDataForAll = async ({ endPoint, arr }: { endPoint: 'narrative' | 'community'; arr: string[] }) => {
    try {
      // Use Promise.all to await all API calls concurrently
      const response = await Promise.all(
        arr.map(async (item) => {
          const data = await this.fetchIdsBasedData({ endPoint, id: item })
          if (data !== 'error') {
            return data
          }
        }),
      )

      this.setIdsBasedList(response.filter((obj) => Object.keys(obj).length !== 0))
      return response
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchConditionsPayload = async (
    params?: ConditionsPayloadParamsForFlagType | undefined,
    allFlag?: boolean,
    category?: FlagCategoryType,
  ) => {
    try {
      const response = await monitorAPI.getSearchPayload(params)
      if (allFlag) {
        return {
          ...category,
          data: response.data,
        }
      } else return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  fetchDataForObject = async (object: FlagListObjType, type: 'flag' | 'category'): Promise<FlagListObjType> => {
    // Assuming you have an API endpoint to fetch data based on object.id
    const requestParams = {
      q: `${type}_id:eq:${object.id}`,
    }
    const conditionObj: ConditionsPayloadType | 'error' = await this.fetchConditionsPayload(requestParams)

    if (conditionObj !== 'error') {
      object.conditions = conditionObj
    } else {
      object.conditions = {}
    }
    return object
  }

  fetchCategoriesForGraph = async (params?: AssetListParamsType) => {
    try {
      const response = await flagsAPI.getCategories(params)
      this.setFlagCategoriesForGraph(response.data)

      const promises = response.data?.map(async (obj: any) => {
        const requestParams = {
          q: `category_id:eq:${obj.category_id}`,
        }
        const conditionObj: ConditionsPayloadType | 'error' = await this.fetchConditionsPayload(requestParams)

        if (conditionObj !== 'error') {
          this.setFlagCategoriesGraphData(obj.name, { conditions: conditionObj })
        } else {
          this.setFlagCategoriesGraphData(obj.name, { conditions: {} })
        }
      })
      await Promise.all(promises)
      this.setHasCategoriesConditionsFetched(true)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }
}
