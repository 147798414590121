const CreateEditWatchlistNavbar = ({
  step = 1,
  totalSteps,
  stepTitle = '',
}: {
  step: number
  stepTitle: string
  totalSteps: number
}) => {
  return (
    <div className='watchlist-builder__section--center__navbar'>
      <div className='step-counts'>
        Step {step} of {totalSteps}
      </div>
      <div className='step-title'>{stepTitle}</div>
    </div>
  )
}

export default CreateEditWatchlistNavbar
