import axios from 'axios'
import { SnippetFilterPayload } from 'models/models'

import { ROUTES } from 'settings/settings'

interface Props extends SnippetFilterPayload {
  channel_filter: string
}
export const getCreatorsData = async ({
  page = 1,
  per_page = 1,
  query,
  powerInsightSort,
  conditions,
  channel_filter,
}: Props) => {
  const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`
  let queryParams = `q=${q}&sort=${powerInsightSort}&per_page=${per_page}&page=${page}`

  if (channel_filter.length > 0) queryParams += `&channel_filter=${channel_filter}`

  const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.powerInsightsCreators}?${queryParams}`
  const { data } = await axios.post(endpoint, { conditions })
  return data
}
