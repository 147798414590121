import axios from 'axios'
import { ITenant } from 'models/models'

import { ROUTES } from 'settings/settings'

export const createTenant = async (payload: ITenant) => {
  let url = `${process.env.REACT_APP_API}${ROUTES.tenant}`

  const { data } = await axios.post(url, payload)

  return data
}
