import { Modal, notification } from 'antd'
import { ReactComponent as CloseIcon } from 'assets/images/close-icon-red.svg'
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg'

import classNames from 'classnames'
import { store } from 'store'

import './ErrorModal.scss'

interface Props {
  requestId: string
  statusCode: number
  message: string
  title?: string
  type?: 'user message' | 'request failed'
  userErrorInfo?: {
    [x: string]: any
  }
}
export const openError = ({
  requestId,
  statusCode,
  message,
  title,
  type = 'request failed',
  userErrorInfo,
}: Props): void => {
  const { navViewStore } = store
  const { isNavOpen } = navViewStore
  const checkErrorCode = () => {
    const firstDigit = Math.floor(statusCode / 100)
    if (firstDigit === 5) {
      return 'system'
    } else if (firstDigit === 4) {
      return 'user'
    } else {
      return 'system'
    }
  }

  const copyMessage = async () => {
    await navigator.clipboard.writeText(
      'The system encountered an unexpected issue while attempting to process the request with the request. The failure to process the request may be due to various reasons, such as server issues, connectivity problems, or internal errors.',
    )
  }

  if (type === 'user message') {
    notification.error({
      message: (
        <div className='error-notification__container'>
          <div className='error-notification__title'>
            <span className='error-notification__title__text'>{userErrorInfo?.title || ''}</span>
          </div>
          <div className='error-notification__description'>
            <span className='error-modal__description--grey'>{userErrorInfo?.description || ''}</span>
          </div>
        </div>
      ),
      icon: <CloseIcon />,
      duration: 6,
      className: classNames({ 'error-notification__notification-margin': isNavOpen }, 'error-notification'),
      placement: 'bottomLeft',
    })
  } else {
    checkErrorCode() === 'user'
      ? Modal.confirm({
          content: (
            <div className='error-modal__container'>
              <div className='error-modal__title'>
                <CloseIcon />
                <span className='error-modal__title__text'> {title ? title : 'Failed process request'}</span>
              </div>
              <div className='error-modal__description'>
                {requestId && (
                  <span className='error-modal__description__text'>
                    <b>Request ID:</b>
                    {requestId ? ` ${requestId}` : ' N/A'}
                  </span>
                )}
                <span className='error-modal__description__text'>{message} </span>
              </div>
            </div>
          ),
          cancelText: 'Close',
          cancelButtonProps: {
            className: 'error-modal__footer__close',
          },
          okText: 'Report error',
          okButtonProps: {
            className: 'error-modal__footer__report',
          },
          onOk: () => {
            const body = encodeURIComponent(`Dear abc Support Team,
          I am writing to report an error I encountered while using [Specify the Application / Feature / Service]. Below are the details of the issue:
          
          - Error Message: [Provide the exact error message]
          - Steps to Reproduce: [Outline the steps taken before encountering the error]
          - Expected Outcome: [Describe what was expected to happen]
          - Actual Outcome: [Describe what actually occurred]
          
          Additionally, please find attached screenshots of errors that might assist in troubleshooting.
      
          Your prompt attention to this matter is greatly appreciated. If further clarification is needed, please do not hesitate to reach out.
          
          Thank you for your assistance.
          
          Best regards,
          [Your name]`)
            const mailtoLink = `mailto:${'support@pendulum.com'}?subject=${encodeURIComponent(
              'Error- [Brief description of the Issue]',
            )}&body=${body.trim()}`
            window.location.href = mailtoLink
          },
          open: true,
          className: 'error-modal',
          icon: null,
          centered: true,
          wrapClassName: 'error-model-wrapper-container',
        })
      : notification.error({
          message: (
            <div className='error-notification__container'>
              <div className='error-notification__title'>
                <span className='error-notification__title__text'>Failed process request</span>
              </div>
              <div className='error-notification__description'>
                <span className='error-notification__description__text'>
                  <b>Request ID:</b>
                  {requestId ? ` ${requestId}` : ' N/A'}
                </span>
                <button className='error-notification__copy-btn' onClick={copyMessage}>
                  <CopyIcon />
                  Copy message
                </button>
              </div>
            </div>
          ),
          icon: <CloseIcon />,
          duration: 6,
          className: classNames({ 'error-notification__notification-margin': isNavOpen }, 'error-notification'),
          placement: 'bottomLeft',
        })
  }
}
