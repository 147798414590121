import './FloatingButton.scss'

interface Props {
  label: string
  onClick: () => void
}
export const FloatingButton = ({ label, onClick }: Props) => {
  return (
    <button className='floating-button' onClick={onClick}>
      {label}
    </button>
  )
}
