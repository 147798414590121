import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  prompt_text: string
}

export const getBooleanSearchTextFromCopilot = async (payload: Props) => {
  const url = `${process.env.REACT_APP_API}/${ROUTES.copilot}`
  const response = await axios.post(url, payload)

  return response.data
}
