import { IRole } from 'models/models'
import { Form, Input, Select, Row, Col, FormInstance, Checkbox } from 'antd'

import './AddUserForm.scss'

export const AddUserForm = ({ form, roles }: { form: FormInstance<any>; roles: IRole[] }) => {
  const { Option } = Select
  const userRoleID = roles.find((role) => role.role === 'USER')?.id

  const roleNameLookup = {
    USER: 'USER',
    ADMIN: 'ADMIN',
    PENDULUM_ADMIN: 'PENDULUM_ADMIN',
    VIEWER: 'VIEWER',
    SCORECARD_USER: 'SCORECARD_USER',
    DEPLOYMENT_STRATEGIST: 'DEPLOYMENT_STRATEGIST',
    BUILDER: 'BUILDER',
    ANALYST: 'ANALYST',
  }

  return (
    <Form
      autoComplete='off'
      form={form}
      name='basic'
      initialValues={{
        email: '',
        family_name: '',
        given_name: '',
        temporaryPassword: '',
        role: userRoleID,
        notifyUser: false,
      }}
      className='c-add-user-form'>
      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Email*</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='email'
            className='c-add-user-form__item'
            rules={[{ required: true, message: 'Please add an email!', type: 'email' }]}>
            <Input className='c-add-user-form__input' />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>First name*</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='given_name'
            className='c-add-user-form__item'
            rules={[{ required: true, message: 'Please add your first name!' }]}>
            <Input className='c-add-user-form__input' />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Last name*</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='family_name'
            className='c-add-user-form__item'
            rules={[{ required: true, message: 'Please add your last name!' }]}>
            <Input className='c-add-user-form__input' />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Role*</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='role'
            className='c-add-user-form__item'
            rules={[{ required: true, message: 'Please select a role' }]}>
            <Select showSearch style={{ width: '100%' }}>
              {roles?.map((role) => (
                <Option key={role.id} value={role.id}>
                  {roleNameLookup[role.role]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Temp Password</b>
        </Col>
        <Col span={18}>
          <Form.Item
            name='temporaryPassword'
            className='c-add-user-form__item'
            rules={[{ required: false, type: 'string' }]}>
            <Input.Password autoComplete='new-password' className='c-add-user-form__input' />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <Col span={6} style={{ display: 'flex' }}>
          <b className='c-add-user-form__field-name'>Don't Notify</b>
        </Col>
        <Col span={18}>
          <Form.Item name='notifyUser' valuePropName='checked'>
            <Checkbox className='c-add-user-form'> Do not send the user login information </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
