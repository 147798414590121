import { UtilService } from 'services/Util/Util'
import { UsersListProps } from 'store/asset/types'

import profileImage from 'assets/images/LogoiconMobile.svg'

const UsersList = (props: UsersListProps) => {
  const { data = [], className, onClick } = props
  return (
    <>
      {data.map((user: any) => (
        <div className={`users_list_container ${className}`} key={user.id} onClick={() => onClick?.(user)}>
          <div className='ulc_image_container'>
            <img
              src={UtilService.isImageUrl(user.avatar_url) ? user.avatar_url : profileImage}
              alt='danger'
              className='ulc_image'
            />
          </div>
          <div className='ulc_content'>
            <div className='ulc_c_name_container'>
              <p className='ulc_c_name'>{user.given_name}</p>
            </div>
            <p className='ulc_c_email'>{user.email}</p>
          </div>
        </div>
      ))}
    </>
  )
}

export default UsersList
