import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { ConditionsPayloadParamsForAssetType } from 'store/asset/types'
import { ConditionsPayloadParamsType } from 'types/types'

export function getSearchPayload(params?: ConditionsPayloadParamsType | ConditionsPayloadParamsForAssetType) {
  return axios({
    method: 'get',
    url: ROUTES.searchPayload,
    params,
  })
}
