import React from 'react'
import classNames from 'classnames'
import { ReactComponent as SectionIcon } from 'assets/images/icons/report/section-icon.svg'
import { sectionObj } from 'store/report/valueTypes'
import styles from './DetailViewSections.module.scss'

type DetailViewSectionsProps = {
  onClick?: (id: string) => void
  selectedSection?: sectionObj
  setSelectedSection?: (value: object) => void
  data: sectionObj[]
}

function DetailViewSections(props: DetailViewSectionsProps) {
  const { onClick, selectedSection, setSelectedSection, data } = props

  return (
    <div className={styles.detail_view_sections_container}>
      <div className={styles.dvsc_header}>
        <SectionIcon />
        <p className={styles.dvsc_c_header_text}>Sections:</p>
      </div>
      <div className={styles.dvsc_content_container}>
        {data.length
          ? data.map((section) => (
              <p
                key={section.id}
                className={classNames(styles.dvsc_cc_section_text, {
                  [styles.dvsc_cc_section_text_active]: section.id === selectedSection?.id,
                })}
                onClick={() => {
                  setSelectedSection?.(section)
                  onClick?.(section.id!)
                }}>
                {section.title}
              </p>
            ))
          : null}
      </div>
    </div>
  )
}

export default DetailViewSections
