import { Fragment, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import millify from 'millify'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { ReactComponent as LowRiskIcon } from 'assets/images/icons/monitor/low_risk_icon.svg'
import { ReactComponent as HighRiskIcon } from 'assets/images/icons/monitor/high_risk_icon.svg'
import {
  ConditionsDataType,
  ConditionsPayloadParamsType,
  ConditionsPayloadType,
  NarrativeListDataType,
  RiskFlagCategoryType,
} from 'types/types'
import { AssetListObjType } from 'store/asset/types'
import { SubStore } from 'types/types'

import './RiskFlags.scss'
import { IListItem } from 'models/models'

const addClassName = (riskObj: RiskFlagCategoryType) => {
  const riskValue = riskObj.flagsCount?.currentFlagCount! - riskObj.flagsCount?.prevFlagCount!
  if (riskValue === 0 && riskObj.flagsCount?.currentFlagCount === 0) {
    return ' rfc_fl_chunk_disabled'
  }
  if (riskValue < 0) {
    return ' rfc_fl_chunk_low_risk'
  }
  return ''
}

type RiskFlagsPropsType = {
  subStore: SubStore
  title?: string
  data: NarrativeListDataType | AssetListObjType | IListItem
  className?: string
  forGridView?: boolean
  setRiskFlagsLoading?: (flag: boolean) => void
  forAsset?: boolean
  isAssetsFlags?: boolean
  isBookMarksListing?: boolean
}

const RiskFlags = observer((props: RiskFlagsPropsType) => {
  const { fetchRiskFlags, selectedTab, getDate, snippetsFilter, fetchConditionsPayload, fetchInsightsMetrics } =
    store[`${props.subStore}Store`]
  const {
    forAsset,
    data,
    isBookMarksListing = false,
    className = '',
    forGridView = false,
    setRiskFlagsLoading,
    isAssetsFlags,
    title,
  } = props
  const [riskFlagsList, setRiskFlagsList] = useState<RiskFlagCategoryType[]>([])

  const renderConditions = useMemo(() => {
    if (forAsset) {
      return [(data as AssetListObjType).conditions]
    }
    if (isBookMarksListing) {
      return [{ document_ids: (data as IListItem)?.entity_info }]
    }
    if (selectedTab === 'narratives') {
      return [{ narratives: [(data as NarrativeListDataType).narrative_number] }]
    } else if (selectedTab === 'communities') {
      return [{ communities: [(data as NarrativeListDataType).community_number] }]
    } else if (selectedTab === 'watchlists') {
      const uniqueChannelUrls = (data as NarrativeListDataType).channels!.reduce((accumulator: string[], item) => {
        const channelUrls = item.channel_by_platform.map((channel) => channel.channel_url)
        return accumulator.concat(channelUrls.filter((url) => !accumulator.includes(url)))
      }, [])

      return [{ channel_urls: uniqueChannelUrls }]
    }
    return []
  }, [selectedTab, data, forAsset])

  const insightsMetricsHandler = async (object: RiskFlagCategoryType): Promise<RiskFlagCategoryType> => {
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
    let prevStartDate = new Date(startDate)
    let prevEndDate = new Date(startDate)
    prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
    prevEndDate.setDate(prevEndDate.getDate() - 1)
    const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
    const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

    const requestParams = {
      q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
    }
    const requestParamsForPrev = {
      q: `start_date:gte:${prevStartDateFormat},end_date:lte:${prevEndDateFormat}`,
    }
    const requestData: ConditionsDataType = {
      conditions: [object.conditions, ...renderConditions] as ConditionsPayloadType[],
    }

    const [currentFlagCount, prevFlagCount] = await Promise.all([
      fetchInsightsMetrics({ params: requestParams, data: requestData }),
      fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData }),
    ])
    object.flagsCount = { currentFlagCount, prevFlagCount }

    return object
  }

  const fetchDataForObject = async (object: RiskFlagCategoryType): Promise<RiskFlagCategoryType> => {
    // Assuming you have an API endpoint to fetch data based on object.id
    const requestParams: ConditionsPayloadParamsType = {
      q: `category_id:eq:${object.category_id}`,
    }
    if (isAssetsFlags) {
      requestParams['is_asset'] = true
    }
    const conditionObj: ConditionsPayloadType | 'error' = await fetchConditionsPayload(requestParams, true)

    if (conditionObj !== 'error') {
      if (Object.keys(conditionObj).length) {
        object.conditions = conditionObj
        await insightsMetricsHandler(object)
      } else {
        object.conditions = conditionObj
        object.flagsCount = { currentFlagCount: 0, prevFlagCount: 0 }
      }
    } else {
      object.conditions = {}
      object.flagsCount = { currentFlagCount: 0, prevFlagCount: 0 }
    }

    return object
  }

  const addNewDataToObjects = async (): Promise<RiskFlagCategoryType[] | 'error'> => {
    const riskFlagData: RiskFlagCategoryType[] | 'error' = await fetchRiskFlags(isAssetsFlags ? { is_asset: true } : {})
    if (riskFlagData !== 'error') {
      const updatedObjects: RiskFlagCategoryType[] = await Promise.all(
        riskFlagData.map(async (obj) => {
          return await fetchDataForObject(obj)
        }),
      )

      return updatedObjects
    }
    return 'error'
  }

  useEffect(() => {
    setRiskFlagsLoading?.(true)
    addNewDataToObjects().then((res: RiskFlagCategoryType[] | 'error') => {
      if (res !== 'error') {
        const sortedRes = res.sort((a, b) => {
          if (a.flagsCount !== undefined && b.flagsCount !== undefined) {
            return b.flagsCount.currentFlagCount! - a.flagsCount.currentFlagCount!
          }
          return 0
        })
        setRiskFlagsList(sortedRes)
      }
      setRiskFlagsLoading?.(false)
    })
  }, [snippetsFilter.days])

  const renderMoreUI = useMemo(() => {
    if (riskFlagsList.length > 3) {
      const flagText = !forGridView ? (riskFlagsList.length - 3 > 1 ? 'flags' : 'flag') : ''
      return (
        <div className={`rfc_fl_chunk rfc_fl_chunk_more`}>
          <p className='rfc_fl_cm_text'>
            + {riskFlagsList.length - 3} more {flagText}
          </p>
        </div>
      )
    }
  }, [riskFlagsList, forGridView, isAssetsFlags])

  return (
    <div className={`risk_flag_container ${className}`}>
      <p className='rfc_text'>{title}</p>
      <div className='rfc_flags_list'>
        {riskFlagsList.map((riskFlag, index) => {
          if (index > 2) {
            return <Fragment key={riskFlag.category_id}></Fragment>
          }

          const riskValue = riskFlag.flagsCount?.currentFlagCount! - riskFlag.flagsCount?.prevFlagCount!
          return (
            <div key={riskFlag.category_id} className={`rfc_fl_chunk ${addClassName(riskFlag)}`}>
              <p className='rfc_flc_title'>{riskFlag.name}</p>
              <div className='rfc_flc_bottom'>
                <p className='rfc_flcb_text'>
                  {millify(Number(riskFlag.flagsCount?.currentFlagCount) || 0)}{' '}
                  {riskValue ? (
                    <span className='rfc_flcb_t_part'>
                      {riskValue > 0 ? '+' : ''}
                      {millify(riskValue)}{' '}
                      {riskValue > 0 ? (
                        <HighRiskIcon width={8} height={8} className='rfc_flcb_tp_icon' />
                      ) : (
                        <LowRiskIcon width={8} height={8} className='rfc_flcb_tp_icon' />
                      )}
                    </span>
                  ) : null}
                </p>
              </div>
            </div>
          )
        })}
        {renderMoreUI}
      </div>
    </div>
  )
})

export default RiskFlags
