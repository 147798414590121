import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { route } from 'models/models'
import { LogService } from 'services/Log/Log'
import { UtilService } from 'services/Util/Util'

export async function get({
  route,
  page,
  pageSize,
  startDateISO,
  endDateISO,
  selectedSourcesFilter,
  selectedCreatorsFilter,
  selectedCommunitiesFilter,
  selectedNarrativesFilter,
  isPromise = false,
  search,
  getError,
  id,
  filter,
  sort,
  customAPI,
  ids,
  customProps,
  body,
  extraPath,
  in_library,
}: {
  route: route
  page?: number
  pageSize?: number
  startDateISO?: string
  endDateISO?: string
  selectedSourcesFilter?: string[]
  selectedCreatorsFilter?: string[]
  selectedCommunitiesFilter?: string[]
  selectedNarrativesFilter?: string[]
  dataFilter?: string[]
  id?: number | string
  isPromise?: boolean
  search?: string
  getError?: boolean
  filter?: string | null
  sort?: string | null
  customAPI?: string
  ids?: string
  customProps?: any
  body?: { [x: string]: any }
  extraPath?: string
  in_library?: boolean
}): Promise<any> {
  try {
    const useSourcesQuery = selectedSourcesFilter?.length !== 0 && selectedSourcesFilter?.[0] !== 'All'
    const useCreatorsQuery =
      selectedCreatorsFilter?.length !== 0 && selectedCreatorsFilter?.[0] !== 'All' && !route.includes('BubbleChart')
    const useCommunitiesQuery = selectedCommunitiesFilter?.length !== 0 && selectedCommunitiesFilter?.[0] !== 'All'
    const useNarrativesQuery = selectedNarrativesFilter?.length !== 0 && selectedNarrativesFilter?.[0] !== 'All'

    const searchParams = UtilService.generateURLSearchParams({
      search,

      page: page,
      per_page: pageSize,

      sort: sort,

      q: filter,

      startDate: startDateISO,
      endDate: endDateISO,

      ids,
      in_library,
      ...customProps,

      ...(useSourcesQuery && { source: selectedSourcesFilter }),
      ...(useCreatorsQuery && { creator: selectedCreatorsFilter }),
      ...(useCommunitiesQuery && { communities: selectedCommunitiesFilter }),
      ...(useNarrativesQuery && { narratives: selectedNarrativesFilter }),
    })

    const urlId = id ? `/${id}` : ''
    const customApi = customAPI ? customAPI : ''
    const extraURLPath = extraPath ? `/${extraPath}` : ''

    const endpoint = `${customApi}${ROUTES[route as keyof typeof ROUTES]}${urlId}${extraURLPath}${
      searchParams ? '?' : ''
    }${searchParams}`

    if (isPromise) {
      return axios({
        method: 'get',
        url: `${endpoint}`,
        data: JSON.stringify(body),
      })
    } else {
      const { data } = await axios({
        method: 'get',
        url: `${endpoint}`,
        data: JSON.stringify(body),
      })

      return { data, total: data.total_count }
    }
  } catch (error: any) {
    LogService.error({ message: 'get API error', error })

    if (getError) throw error

    return { data: null, total: 0 }
  }
}
