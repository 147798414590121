import axios from 'axios'
import { SnippetFilterPayload } from 'models/models'

export const loadFeed = async ({ page, per_page = 50, query, sort, conditions }: SnippetFilterPayload) => {
  const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`
  const queryParams = `q=${q}&sort=${sort}&per_page=${per_page}&page=${page}`

  const endpoint = `${process.env.REACT_APP_SEARCH_API}feeds?${queryParams}`
  return axios.post(endpoint, { conditions })
}
