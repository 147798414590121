import { Spin } from 'antd'
import classNames from 'classnames'
import React, { useRef } from 'react'

import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/close-with-background-icon.svg'

import useClickAway from 'hooks/useClickAway'

import styles from './SearchInput.module.scss'

type SearchInputProps = {
  value?: string
  className?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onClear?: () => void
  placeholder?: string
  inputClassName?: string
  iconClassName?: string
  prefix?: React.ElementType
  showIcon?: boolean
  lastIcon?: boolean
  contentClassName?: string
  showDrop?: boolean
  disabled?: boolean
  children?: React.ReactNode
  isOpen?: boolean
  loading?: boolean

  setIsOpen?: (value: boolean) => void
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void
}

function SearchInput(props: SearchInputProps) {
  const {
    value,
    className,
    onChange,
    onPressEnter,
    onClear,
    placeholder = 'Search existing reports',
    inputClassName,
    iconClassName,
    prefix: Prefix = SearchIcon,
    showIcon = true,
    lastIcon = false,
    contentClassName,
    showDrop,
    children,
    isOpen,
    setIsOpen,
    onScroll,
    disabled = false,
    loading = false,
    ...rest
  } = props

  const searchRef = useRef<HTMLDivElement>(null)
  const onClickOutside = () => {
    if (disabled) return
    setIsOpen?.(false)
  }
  useClickAway(searchRef, onClickOutside)

  return (
    <div
      className={classNames(styles.search_input_container, className)}
      onClick={() => (disabled ? null : setIsOpen?.(!isOpen))}
      ref={searchRef}>
      {showIcon && <Prefix className={classNames(styles.icon, iconClassName)} />}
      <input
        disabled={disabled}
        className={classNames(styles.input, inputClassName)}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={(event) => {
          event.stopPropagation()
          if (event.key === 'Enter') {
            onPressEnter?.(event)
          }
        }}
        {...rest}
      />
      {lastIcon && <Prefix className={classNames(styles.icon, iconClassName)} />}
      {value && <CloseIcon className={styles.clear_icon} onClick={onClear} />}
      {showDrop && isOpen && (
        <div
          onClick={(e) => e.stopPropagation()}
          onScroll={onScroll}
          className={classNames(styles.search_content_container, contentClassName)}>
          <Spin spinning={loading}>{children}</Spin>
        </div>
      )}
    </div>
  )
}

export default SearchInput
