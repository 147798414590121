import loaderGif from 'assets/gifs/loader.gif'

import styles from './PendulumLoader.module.scss'
import classNames from 'classnames'

const PendulumLoader = ({
  height = '200px',
  overLay = false,
  text = 'Pendulum Co-Pilot is generating your summary.',
}: {
  height?: string
  text?: string
  overLay?: boolean
}) => {
  return (
    <div className={classNames(styles.loadingScreen, { [styles.overLay]: overLay })} style={{ height: height }}>
      <div className={styles.waiting}>
        <div className={styles.gifWrapper}>
          <img src={loaderGif} alt='loading gif' />
        </div>

        <div className={styles.message}>Please Wait ...</div>
      </div>

      <div className={styles.message}>{text}</div>
    </div>
  )
}

export default PendulumLoader
