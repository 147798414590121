import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

export async function getRoles() {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `/${ROUTES.roles}`,
      baseURL: process.env.REACT_APP_API,
    })

    return { data: data.roles }
  } catch (error: any) {
    LogService.error({ message: 'getRoles', error })
    return { data: null, error: error.response?.data?.message }
  }
}
