import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { TopLevelNavigation } from 'components/common/TopLevelNavigation/TopLevelNavigation'

export const AnticipatoryIntelligencePage = observer(() => {
  return (
    <div>
      {<TopLevelNavigation page='Pendulum RiskWand' />}
      <Outlet />
    </div>
  )
})
