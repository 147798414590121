import { debounce, uniqBy } from 'lodash'
import { Form, TablePaginationConfig } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'

import { store } from 'store'

import { Unknown } from 'utils/commonTypes'
import { watchlistAPIs, API } from 'api/api'
import { UtilService } from 'services/Util/Util'
import { ChannelDataType, FormDataType, SortInfo, SortOrder, SupportedPlatformTypes } from './types'

const defaultFormData: FormDataType = {
  name: '',
  description: '',
  addedURLs: [],
  edit_url: '',
  platform: undefined,
  url: '',
  previousURL: '',
}

const useCreateInfluencer = () => {
  const location = useLocation()
  const { id } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState<string>('all')
  const [validateURLLoading, setValidateURLLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [formData, setFormData] = useState<FormDataType>(defaultFormData)
  const [influencerLoading, setInfluencerLoading] = useState(false)
  const [supportedPlatform, setSupportedPlatform] = useState<SupportedPlatformTypes[]>([])
  const [sortStates, setSortStates] = useState<SortInfo>({
    columnName: 'followers',
    direction: 'descend',
  })
  const [filteredData, setFilteredData] = useState<ChannelDataType[]>(defaultFormData.addedURLs)

  const { saveWatchList, setIsWatchlistSuccessModalOpen } = store.defineWatchlistStore
  const { rootPage, setBreadcrumbData } = store.breadcrumbStore
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = store.tenantsStore

  const totalSteps = 3
  const cancelButtonText = activeStep === 1 ? 'Cancel' : 'Back'
  const invalidLinkCount = formData?.addedURLs?.filter((item) => item.isInvalid)?.length
  const continueButtonText =
    activeStep === 3
      ? `${id ? 'Update' : 'Publish'} ${changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)}`
      : selectedRowKeys.length
      ? `Delete ${selectedRowKeys.length} link${selectedRowKeys.length > 1 ? 's' : ''} Selected`
      : 'Continue'

  const saveWatchlist = async () => {
    if (activeStep === totalSteps && !id) {
      try {
        setIsLoading(true)
        const data = await saveWatchList({
          name: formData.name,
          description: formData.description,
          channels: formData.addedURLs?.map((item) => ({
            platform: item.platform,
            channel_urls: [item.channel_url],
          })),
        })
        setIsWatchlistSuccessModalOpen(data?.data?.id)
        navigate(`/${isCorporateCommunicationsTheme ? 'influencers' : 'vectors'}`, {
          state: {
            isCreated: true,
            tabName: 'watchlists',
            comingFrom: location.pathname,
          },
        })
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
      return
    }
  }

  const updateWatchlist = async () => {
    if (activeStep === totalSteps && id) {
      try {
        setIsLoading(true)
        await watchlistAPIs.EditWatchlist(
          {
            name: formData.name,
            description: formData.description,
            channels: formData.addedURLs?.map((item) => ({
              platform: item.platform,
              channel_urls: [item.channel_url],
            })),
          },
          id,
        )
        const previousPath = window.location.pathname?.includes('vectors') ? '/vectors/details' : '/influencers/details'
        navigate(previousPath, {
          state: JSON.parse(
            JSON.stringify({
              name: formData.name,
              conditions: [{ channel_urls: formData.addedURLs?.map((item) => item.channel_url) }],
              mode: 'watchlist',
              id: id,
            }),
          ),
        })
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
      return
    }
  }

  const handleBulkDeleteURLs = useCallback(() => {
    const filteredURLs = filteredData.filter((item) => !selectedRowKeys.includes(item.channel_url))
    const filteredFormDataURLs = formData?.addedURLs.filter((item) => !selectedRowKeys.includes(item.channel_url))
    setFormData((prevData) => ({
      ...prevData,
      addedURLs: filteredFormDataURLs,
    }))
    applyFilterAfterDelete(selectedFilter, filteredURLs?.length ? filteredURLs : filteredFormDataURLs)
    setSelectedRowKeys([])
  }, [filteredData, formData, selectedRowKeys])

  const handleContinueButton = () => {
    const values = form.getFieldsValue()
    setFormData((prevData) => ({ ...prevData, ...values }))
    if (activeStep < totalSteps) {
      setActiveStep((prev) => prev + 1)
    }
  }

  const handleNextStep = useCallback(async () => {
    try {
      await form.validateFields()

      saveWatchlist()
      updateWatchlist()

      if (selectedRowKeys.length === 0) {
        handleContinueButton()
      } else {
        handleBulkDeleteURLs()
      }
    } catch (error) {
      console.error('Validation Failed:', error)
    }
  }, [activeStep, selectedRowKeys])

  const handleEditURL = useCallback(
    (url: string, platform?: string) => {
      setFormData((prevData) => ({
        ...prevData,
        edit_url: url,
        platform: platform,
        previousURL: url,
      }))
    },
    [formData],
  )

  const handleDeleteURL = useCallback(
    (url: string) => {
      const filteredURLs = filteredData.filter((item) => item.channel_url !== url)
      const filteredFormDataURLs = formData?.addedURLs.filter((item) => item.channel_url !== url)
      setFormData((prevData) => ({
        ...prevData,
        addedURLs: prevData.addedURLs.filter((item) => item.channel_url !== url),
      }))
      applyFilterAfterDelete(selectedFilter, filteredURLs?.length ? filteredURLs : filteredFormDataURLs)
      setSelectedRowKeys((prev) => prev?.filter((item) => item !== url))
    },
    [filteredData, selectedRowKeys, formData],
  )

  const onCancelClick = useCallback(() => {
    const values = form.getFieldsValue()
    setFormData((prevData) => ({ ...prevData, ...values }))
    setSelectedRowKeys([])
    if (activeStep > 1) setActiveStep((prev) => prev - 1)
    if (activeStep === 1) navigate(-1)
  }, [activeStep])

  const handleVerifyURLInCaseError = (
    error: Unknown,
    previousURL: string,
    previousURLs: ChannelDataType[],
    url: string,
  ) => {
    const errorDetails = error?.response?.data?.error_details
    setFormData((prevData) => ({
      ...prevData,
      url: '',
      platform: '',
      edit_url: '',
      previousURL: '',
      addedURLs: uniqBy(
        [
          ...previousURLs,
          {
            channel_url: errorDetails?.channel_url || url,
            platform: errorDetails?.platform || undefined,
            isInvalid: true,
          },
        ],
        'channel_url',
      ),
    }))
    setFilteredData(
      uniqBy(
        [
          ...previousURLs,
          {
            channel_url: errorDetails?.channel_url || url,
            platform: errorDetails?.platform || undefined,
            isInvalid: true,
          },
        ],
        'channel_url',
      ),
    )
    setSelectedFilter('all')
  }

  const handleEditURLAndVerify = async (url: string, previousURL: string) => {
    const previousURLs = formData.addedURLs?.filter((item) => item.channel_url !== previousURL)
    try {
      setValidateURLLoading(true)
      if (url) {
        const { data } = await watchlistAPIs.verifyChannelURL({ url })
        setFormData((prevData) => {
          return {
            ...prevData,
            url: '',
            platform: '',
            edit_url: '',
            previousURL: '',
            addedURLs: uniqBy([...previousURLs, { ...data, isInvalid: false }], 'channel_url'),
          }
        })
        setFilteredData(uniqBy([...previousURLs, { ...data, isInvalid: false }], 'channel_url'))
      }
    } catch (error: Unknown) {
      handleVerifyURLInCaseError(error, previousURL, previousURLs, url)
    } finally {
      setValidateURLLoading(false)
    }
  }

  const handleMultipleURLsAndVerify = async (url: string) => {
    const arrayOfLinks = url?.split(/[\s,]+/)
    const accumulatedURLs: ChannelDataType[] = []

    await Promise.all(
      arrayOfLinks.map(async (item) => {
        if (item)
          try {
            setValidateURLLoading(true)
            const { data } = await watchlistAPIs.verifyChannelURL({ url: item?.trim() })
            accumulatedURLs.push({ ...data, isInvalid: false })
          } catch (error: Unknown) {
            const errorDetails = error?.response?.data?.error_details
            accumulatedURLs.push({
              channel_url: errorDetails?.channel_url || item,
              platform: errorDetails?.platform || undefined,
              isInvalid: true,
            })
          } finally {
            setValidateURLLoading(false)
          }
      }),
    )

    setFormData((prevData) => ({
      ...prevData,
      addedURLs: uniqBy([...prevData.addedURLs, ...accumulatedURLs], 'channel_url'),
    }))
    setFilteredData(uniqBy([...formData.addedURLs, ...accumulatedURLs], 'channel_url'))
    setSelectedFilter('all')
  }

  const verifyAndGetPlatform = async (url: string, previousURL?: string) => {
    if (previousURL) {
      await handleEditURLAndVerify(url, previousURL)
    } else {
      await handleMultipleURLsAndVerify(url)
    }
  }

  const handleConfirmEditURL = () => {
    const { edit_url } = form.getFieldsValue()
    verifyAndGetPlatform(edit_url, formData.previousURL)
  }

  const cancelURLEditing = () =>
    setFormData((prevData) => ({
      ...prevData,
      edit_url: '',
      platform: '',
      previousURL: '',
    }))

  const stepTitle = () => {
    switch (activeStep) {
      case 1:
        return `${id ? 'Edit' : 'Add'} ${changeLabelTextBasedOnTheme(
          'Watchlist',
          isCorporateCommunicationsTheme,
        )} details`
      case 2:
        return `${id ? 'Update' : 'Add'} links`
      case 3:
        return `Preview ${changeLabelTextBasedOnTheme('watchlist', isCorporateCommunicationsTheme)}`
      default:
        return ''
    }
  }

  const handleGetInfluencerData = useCallback(async () => {
    if (!id) return
    try {
      setInfluencerLoading(true)
      const { data } = await API.get({ route: 'watchlist', id })
      setFormData({
        ...formData,
        name: data?.name || '',
        description: data?.description || '',
        addedURLs: data?.channels?.flatMap((item: Unknown) =>
          item?.channel_by_platform?.map((channel: Unknown) => ({ ...channel, platform: item.platform })),
        ),
      })
      setFilteredData(
        data?.channels?.flatMap((item: Unknown) =>
          item?.channel_by_platform?.map((channel: Unknown) => ({ ...channel, platform: item.platform })),
        ),
      )
    } catch (error) {
      console.log(error)
    } finally {
      setInfluencerLoading(false)
    }
  }, [id])

  const applyFilters = (platform: string) => {
    if (platform === 'all') {
      setSelectedFilter('all')
      setFilteredData(formData.addedURLs)
    } else if (platform === 'invalid') {
      setSelectedFilter('invalid')
      setFilteredData(formData.addedURLs.filter((item) => item.isInvalid))
    } else {
      setSelectedFilter(platform)
      setFilteredData(formData.addedURLs.filter((item) => item.platform?.toLowerCase() === platform?.toLowerCase()))
    }
  }

  const applyFilterAfterDelete = (platform: string, data: ChannelDataType[]) => {
    if (platform === 'all' || !data?.length) {
      setFilteredData(data)
      setSelectedFilter('all')
      return
    } else if (platform === 'invalid') {
      if (!data.filter((item) => item.isInvalid)?.length) {
        setSelectedFilter('all')
        setFilteredData(data)
        return
      }
      setSelectedFilter('invalid')
      setFilteredData(data.filter((item) => item.isInvalid))
      return
    } else {
      if (!data.filter((item) => item.platform?.toLowerCase() === platform?.toLowerCase())?.length) {
        setSelectedFilter('all')
        setFilteredData(data)
        return
      }
      setSelectedFilter(platform)
      setFilteredData(data.filter((item) => item.platform?.toLowerCase() === platform?.toLowerCase()))
    }
  }

  const handleSearchAddedURLs = useCallback(
    debounce((searchedString: string) => {
      setFilteredData(
        formData.addedURLs.filter((item) => item.channel_url?.toLowerCase()?.includes(searchedString?.toLowerCase())),
      )
    }, 700),
    [formData.addedURLs],
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchAddedURLs(e.target.value)
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ChannelDataType> | SorterResult<ChannelDataType>[],
  ) => {
    let sortColumn = ''
    let sortOrder: SortOrder = null

    if (!Array.isArray(sorter)) {
      sortColumn = sorter.columnKey as string
      // @ts-ignore
      sortOrder = sorter.order
    }

    setSortStates({
      columnName: sortColumn,
      direction: sortOrder,
    })

    const sortedData = [...filteredData].sort((a, b) => {
      if (!sortColumn || !sortOrder) return 0

      const aValue = a[sortColumn as keyof ChannelDataType]
      const bValue = b[sortColumn as keyof ChannelDataType]

      if (sortOrder === 'ascend') {
        // @ts-ignore
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
      } else if (sortOrder === 'descend') {
        // @ts-ignore
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0
      }
      return 0
    })

    setFilteredData(sortedData)
  }

  const handleBulkRowsSelection = () => {
    if (selectedRowKeys?.length === filteredData?.length) setSelectedRowKeys([])
    else setSelectedRowKeys(filteredData?.map((item) => item.channel_url))
  }

  const handleSelectedRows = (channel_url: string) => {
    if (selectedRowKeys?.includes(channel_url))
      setSelectedRowKeys((prev) => prev?.filter((item) => item !== channel_url))
    else setSelectedRowKeys((prev) => [...prev, channel_url])
  }

  const getSupportedPlatform = async () => {
    try {
      const { data } = await watchlistAPIs.getSupportedPlatforms()
      setSupportedPlatform(data?.supported_platforms as SupportedPlatformTypes[])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetInfluencerData()
    getSupportedPlatform()
  }, [id])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData])

  useEffect(() => {
    const newBreadcrumbData = [
      { id: UtilService.generateUuid(), title: rootPage },
      {
        id: UtilService.generateUuid(),
        title: id ? 'Update' : 'Create',
      },
    ]
    setBreadcrumbData(newBreadcrumbData)
  }, [rootPage, id])

  return {
    id,
    form,
    formData,
    isLoading,
    stepTitle,
    sortStates,
    totalSteps,
    activeStep,
    setFormData,
    filteredData,
    applyFilters,
    handleEditURL,
    onCancelClick,
    selectedFilter,
    handleNextStep,
    handleDeleteURL,
    selectedRowKeys,
    invalidLinkCount,
    cancelButtonText,
    cancelURLEditing,
    handleInputChange,
    influencerLoading,
    handleTableChange,
    supportedPlatform,
    handleSelectedRows,
    continueButtonText,
    setSelectedRowKeys,
    validateURLLoading,
    handleConfirmEditURL,
    verifyAndGetPlatform,
    getSupportedPlatform,
    handleBulkRowsSelection,
  }
}

export default useCreateInfluencer
