import { Form, Input, Modal, Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { store } from 'store'

import SampleLogo from 'assets/images/add-logo-sample.svg'
import { ReactComponent as EnterIcon } from 'assets/images/enter-text-icon.svg'

import { ITenant } from 'models/models'

import '../TenantModals.scss'
import './AddTenantModal.scss'

interface Props {
  id: string
  modalOpen: boolean
  setModalOpen: (state: boolean) => void
  setEditFormId: (id: string) => void
}
export const AddTenantModal = observer(({ id, modalOpen, setModalOpen, setEditFormId }: Props) => {
  const [form] = Form.useForm()

  const [tenantData, setTenantData] = useState<ITenant>({
    name: '',
    logo_url: '',
    domain: '',
  })

  const { tenantsStore, loaderStore } = store
  const { tenantsPagination, createTenant, editTenant, fetchTenants, fetchTenantDetails } = tenantsStore
  const { isLoadingTenants } = loaderStore

  useEffect(() => {
    if (id.length > 0) {
      fetchTenantDetails(id).then((response) => {
        form.setFieldsValue(response)
        updateFormData(response)
      })
    }
  }, [id])

  const updateFormData = (newFormData: any) => {
    setTenantData((prev) => {
      return { ...prev, ...newFormData }
    })
  }

  const submitForm = async () => {
    try {
      const check = await form.validateFields()
      if (Object.values(check || {})?.length > 0) {
        const response = id.length > 0 ? await editTenant(id, tenantData) : await createTenant(tenantData)
        if (response) {
          form.resetFields()
          setModalOpen(false)
          fetchTenants({ pageSize: tenantsPagination.pageSize, page: 1 })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onCancel = () => {
    setModalOpen(false)
    setEditFormId('')
  }

  const reset = () => {
    setEditFormId('')
    form.resetFields()
    setTenantData({ name: '', logo_url: '', domain: '' })
  }

  return (
    <Modal
      title={
        <div className='add-tenant__header'>
          <span className='add-tenant__header_title'>{id.length === 0 ? 'Add Tenant' : 'Edit Tenant'}</span>
          <span className='add-tenant__header__description'>{'Add or Remove Tenants for Hassle-Free Management'}</span>
        </div>
      }
      open={modalOpen}
      destroyOnClose={true}
      closable={true}
      className='tenant-modal'
      onCancel={onCancel}
      afterClose={reset}
      footer={
        <div className='add-tenant__footer'>
          <button className='add-tenant__footer__cancel' onClick={onCancel}>
            Cancel
          </button>
          <button className='add-tenant__footer__add' onClick={submitForm}>
            {id.length > 0 ? 'Edit tenant' : 'Add tenant'}
          </button>
        </div>
      }>
      <Spin spinning={isLoadingTenants}>
        <Form form={form} onValuesChange={updateFormData}>
          <div className='add-tenant__body'>
            <div className='add-tenant__body__logo-div'>
              <div className='add-tenant__body__logo'>
                <img
                  src={tenantData.logo_url ?? SampleLogo}
                  alt=''
                  onError={(e: any) => {
                    e.target.src = SampleLogo
                  }}
                  className='add-tenant__body__logo__img'
                />
              </div>
              <div className='tenant-modal__info-div__url'>
                <label className='tenant-modal__info-div__label'>Tenant logo URL (optional)</label>
                <Form.Item name={'logo_url'}>
                  <Input placeholder='Enter URL' suffix={<EnterIcon />} />
                </Form.Item>
              </div>
            </div>
            <div className='tenant-modal__info-div'>
              <label className='tenant-modal__info-div__label'>Tenant Name (required)</label>
              <Form.Item
                name='name'
                rules={[
                  {
                    validator: (_, value: string) => {
                      if (value?.trim()?.length > 0) return Promise.resolve()
                      else return Promise.reject(new Error('Required field'))
                    },
                  },
                ]}>
                <Input placeholder='Enter tenant name' />
              </Form.Item>
            </div>
            <div className='tenant-modal__info-div'>
              <label className='tenant-modal__info-div__label'>Tenant Website (optional) </label>
              <Form.Item
                name='domain'
                rules={[
                  {
                    validator: (_, value: string) => {
                      if (value?.length > 0) {
                        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
                        const result = urlRegex.test(value)
                        return result ? Promise.resolve() : Promise.reject(new Error('Enter valid URL'))
                      } else return Promise.resolve()
                    },
                  },
                ]}>
                <Input placeholder='Enter tenant website' />
              </Form.Item>
            </div>
            <div className='tenant-modal__info-div'>
              <label className='tenant-modal__info-div__label'>Industry (optional) </label>
              <Form.Item name='industry'>
                <Select placeholder={'Choose tenant industry'}>
                  <Select.Option value='1'>Industry 1</Select.Option>
                  <Select.Option value='2'>Industry 2</Select.Option>
                  <Select.Option value='3'>Industry 3</Select.Option>
                  <Select.Option value='4'>Industry 4</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  )
})
