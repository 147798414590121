import { observer } from 'mobx-react-lite'

import { store } from 'store'

import styles from './statistics.module.scss'
import { SubStore } from 'types/types'

interface StatisticsProps {
  subStore: SubStore
}

export const Statistics: React.FC<StatisticsProps> = observer(({ subStore }) => {
  const { powerInsightsData } = store[`${subStore}Store`]

  const { summary: summaryData } = powerInsightsData

  return (
    <div className={styles.statistics}>
      <div className={styles.heading}>
        <h4>Statistics</h4>
      </div>

      <div className={styles.stats}>
        {summaryData.statistics.map((stat) => (
          <div className={styles.statCard} key={stat.label}>
            <span className={styles.statCount}>{stat.value}</span>
            <span className={styles.statName}>{stat.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
})
