import React, { useState, FunctionComponent, useMemo, useEffect } from 'react'
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, YAxis, Legend, ResponsiveContainer } from 'recharts'
import dayjs from 'dayjs'
import classNames from 'classnames'
import millify from 'millify'
import { UtilService } from 'services/Util/Util'
import { sourcePlatform } from 'models/models'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as PostsIcon } from 'assets/images/icons/power-insights/trends-timeline/metric/posts.svg'
import { ReactComponent as ImpressionsIcon } from 'assets/images/icons/power-insights/trends-timeline/metric/impressions.svg'
import { ReactComponent as AllIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/all.svg'
import { ReactComponent as SentimentIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/sentiment.svg'
import { ReactComponent as PlatformIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/platform.svg'
import { ReactComponent as PostsLegendIcon } from 'assets/images/icons/power-insights/trends-timeline/legends/posts.svg'
import { ReactComponent as AverageLegendIcon } from 'assets/images/icons/power-insights/trends-timeline/legends/average.svg'

import './DetailPanleTrends.scss'

type SentimentKey = 'Positive' | 'Neutral' | 'Negative'

const sentimentsNames = {
  Positive: 'Positive',
  Negative: 'Negative',
  Neutral: 'Neutral',
}
const sentimentsColors = {
  Positive: '#18B368',
  Negative: '#DE4E4E',
  Neutral: '#18A0FB',
}

const CustomizedXAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, index, payload } = props
  return (
    <>
      <g transform={`translate(${x - index * 1},${y})`}>
        <text x={15} y={15} textAnchor='end' className='dptc_cc_xaxis_text'>
          {dayjs(payload.value).format('MMM')}
        </text>
      </g>
    </>
  )
}

const CustomizedYAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, index, payload } = props
  return (
    <>
      <g transform={`translate(${x},${y + index * 1})`}>
        <text
          textAnchor='end'
          fill='#666'
          className='dptc_cc_yaxis_text'
          style={{ color: 'red', fontSize: 12, lineHeight: 20 }}>
          {Number.isFinite(payload.value) ? millify(payload.value) : 0}
        </text>
      </g>
    </>
  )
}

const CustomLegend = ({ disabledChartLines, onLegendClick, payload, activePivot }: any) => {
  return (
    <ul className='dptc_custom_legend_container'>
      {payload.map((entry: any, index: number) => {
        if (activePivot === 'all') {
          return (
            <li key={entry.dataKey} className='dptc_clc_legend_li'>
              {entry.dataKey === 'metric_value' ? (
                <div className='dptc_clc_legend_chunk'>
                  <PostsLegendIcon />
                  <p className='dptc_clc_legend_chunk_text'>Posts</p>
                </div>
              ) : (
                <div className='dptc_clc_legend_chunk'>
                  <AverageLegendIcon />
                  <p className='dptc_clc_legend_chunk_text'>Average</p>
                </div>
              )}
            </li>
          )
        } else if (activePivot === 'sentiment' || activePivot === 'platform') {
          let legendLabel = entry.dataKey
          if (activePivot === 'platform') {
            legendLabel = UtilService.getPlatformBrandName(entry.dataKey)
          }

          return (
            <li
              // key={`item-${index}`}
              key={entry.dataKey}
              className='dptc_clc_legend_li dptc_clc_legend_li_clickable'
              onClick={() => onLegendClick(entry.dataKey)}>
              <div className='dptc_clc_legend_chunk'>
                <div className='dptc_clc_legend_icon' style={{ backgroundColor: entry.color }} />{' '}
                <p
                  className={`dptc_clc_legend_chunk_text${
                    disabledChartLines.includes(entry.dataKey) ? ' dptc_clc_legend_chunk_text_strike' : ''
                  }`}>
                  {legendLabel}
                </p>
                {disabledChartLines.includes(entry.dataKey) && (
                  <div className='dptc_clc_legend_chunk_text_strike_Line'></div>
                )}
              </div>
            </li>
          )
        }

        return null
      })}
    </ul>
  )
}

function DetailPanleTrends(props: any) {
  const { data } = props
  const [activeMetric, setActiveMetric] = useState<'posts' | 'impressions'>('posts')
  const [activePivot, setActivePivot] = useState<'all' | 'sentiment' | 'platform'>('all')
  const [hoveredValued, setHoveredValue] = useState<string>('')
  const [xAxisTicks, setXAxisTicks] = useState<string[]>([])
  const [disabledChartLines, setDisabledChartLines] = useState<
    Array<Lowercase<sourcePlatform> | 'Positive' | 'Neutral' | 'Negative'>
  >([])

  useEffect(() => {
    setDisabledChartLines([])
  }, [activeMetric, activePivot])

  useEffect(() => {
    const ticks: string[] = []

    data[activeMetric][activePivot].forEach((point: any) => {
      const date = dayjs(point.date)
      const day = date.get('date')

      if (day === 1) ticks.push(point.date)
    })

    if (
      ticks.length === 0 &&
      data[activeMetric][activePivot].length !== 0 &&
      data[activeMetric][activePivot][0]?.date
    ) {
      ticks.push(data[activeMetric][activePivot][0]?.date)
    }

    setXAxisTicks(ticks)
  }, [data, activeMetric, activePivot])

  const CustomTooltip = ({ active, payload, label }: { active: boolean; payload: any; label: any }) => {
    if (!active || !payload || !payload.length) return null

    const data = payload.filter((item: any) => item.dataKey === hoveredValued)
    if (!data?.length) return null

    return (
      <div className='dpt_tooltip_label_container'>
        <p className='dpt_tlc_label_text'>{` ${millify(data[0]?.value)} | ${dayjs(label).format('MMM DD, YYYY')}`}</p>
      </div>
    )
  }

  const postsSentiments = useMemo(() => {
    const uniqueSentiments = new Set()
    data.posts.sentiment.forEach((dataPoint: any) => {
      Object.keys(dataPoint).forEach((key) => {
        if (sentimentsNames[key as SentimentKey]) {
          uniqueSentiments.add(key)
        }
      })
    })
    return Array.from(uniqueSentiments)
  }, [data.posts])

  const impressionsSentiments = useMemo(() => {
    const uniqueSentiments = new Set()
    data.impressions.sentiment.forEach((dataPoint: any) => {
      Object.keys(dataPoint).forEach((key) => {
        if (sentimentsNames[key as SentimentKey]) {
          uniqueSentiments.add(key)
        }
      })
    })
    return Array.from(uniqueSentiments)
  }, [data.impressions])

  const postsPlatforms = useMemo(() => {
    const uniquePlatforms = new Set<Lowercase<sourcePlatform> | 'date' | 'patriotswin' | 'eucouncil' | 'cspan'>()

    data.posts.platform.forEach((dataPoint: any) => {
      Object.keys(dataPoint).forEach((key) => {
        uniquePlatforms.add(key as Lowercase<sourcePlatform>)
      })
    })

    uniquePlatforms.delete('date')
    if (uniquePlatforms.has('patriotswin')) {
      uniquePlatforms.delete('patriotswin')
      uniquePlatforms.add('patriots.win')
    }
    if (uniquePlatforms.has('eucouncil')) {
      uniquePlatforms.delete('eucouncil')
      uniquePlatforms.add('consilium')
    }
    if (uniquePlatforms.has('cspan')) {
      uniquePlatforms.delete('cspan')
      uniquePlatforms.add('c-span')
    }
    return Array.from(uniquePlatforms) as Lowercase<sourcePlatform>[]
  }, [data.posts])

  const impressionsPlatforms = useMemo(() => {
    const uniquePlatforms = new Set<Lowercase<sourcePlatform> | 'date' | 'patriotswin' | 'eucouncil' | 'cspan'>()

    data.impressions.platform.forEach((dataPoint: any) => {
      Object.keys(dataPoint).forEach((key) => {
        uniquePlatforms.add(key as Lowercase<sourcePlatform>)
      })
    })

    uniquePlatforms.delete('date')
    if (uniquePlatforms.has('patriotswin')) {
      uniquePlatforms.delete('patriotswin')
      uniquePlatforms.add('patriots.win')
    }
    if (uniquePlatforms.has('eucouncil')) {
      uniquePlatforms.delete('eucouncil')
      uniquePlatforms.add('consilium')
    }
    if (uniquePlatforms.has('cspan')) {
      uniquePlatforms.delete('cspan')
      uniquePlatforms.add('c-span')
    }
    return Array.from(uniquePlatforms) as Lowercase<sourcePlatform>[]
  }, [data.impressions])

  const handleLegendClick = (dataKey: Lowercase<sourcePlatform> | 'Positive' | 'Neutral' | 'Negative') => {
    if (disabledChartLines.includes(dataKey)) {
      setDisabledChartLines(disabledChartLines.filter((key) => key !== dataKey))
    } else {
      setDisabledChartLines([...disabledChartLines, dataKey])
    }
  }

  const platformsToRender = activeMetric === 'posts' ? postsPlatforms : impressionsPlatforms
  const sentimentsToRender = activeMetric === 'posts' ? postsSentiments : impressionsSentiments
  return (
    <div className='detail_panel_trends_container'>
      <p className='dptc_heading'>Select any pivot or metric to see trends for your selected text chunk.</p>
      <div className='dptc_mertic_pivot_container'>
        <div className='dptc_buttons_container'>
          <p className='dptc_bc_heading'>Metric</p>
          <div className='dptc_bc_inner_container'>
            <ButtonImage
              icon={PostsIcon}
              className={classNames('dptc_ic_button', { dptc_ic_button_active: activeMetric === 'posts' })}
              onClick={() => {
                setActiveMetric('posts')
              }}>
              Posts
            </ButtonImage>
            <ButtonImage
              icon={ImpressionsIcon}
              className={classNames('dptc_ic_button', { dptc_ic_button_active: activeMetric === 'impressions' })}
              onClick={() => {
                setActiveMetric('impressions')
              }}>
              Impressions
            </ButtonImage>
          </div>
        </div>
        <div className='dptc_buttons_container'>
          <p className='dptc_bc_heading'>Pivot</p>
          <div className='dptc_bc_inner_container'>
            <ButtonImage
              icon={AllIcon}
              className={classNames('dptc_ic_button', { dptc_ic_button_active: activePivot === 'all' })}
              onClick={() => {
                setActivePivot('all')
              }}>
              All
            </ButtonImage>
            <ButtonImage
              icon={SentimentIcon}
              className={classNames('dptc_ic_button', { dptc_ic_button_active: activePivot === 'sentiment' })}
              onClick={() => {
                setActivePivot('sentiment')
              }}>
              Sentiment
            </ButtonImage>
            <ButtonImage
              icon={PlatformIcon}
              className={classNames('dptc_ic_button', { dptc_ic_button_active: activePivot === 'platform' })}
              onClick={() => {
                setActivePivot('platform')
              }}>
              Platform
            </ButtonImage>
          </div>
        </div>
      </div>
      <div className='dptc_chart_container'>
        <ResponsiveContainer>
          <LineChart className='dptc_chart_container_line_chart' data={data[activeMetric][activePivot]}>
            <CartesianGrid stroke='#E4E3E1' vertical={false} />
            <XAxis
              dataKey='date'
              axisLine={{ stroke: '#E4E3E1', strokeWidth: 1 }}
              interval={0}
              // interval='preserveStartEnd'
              ticks={xAxisTicks}
              tick={<CustomizedXAxisTick />}
              tickLine={false}
            />
            <YAxis
              type='number'
              axisLine={{ stroke: '#E4E3E1', strokeWidth: 1 }}
              interval={0}
              tick={<CustomizedYAxisTick />}
              tickLine={false}
              tickCount={11}
            />
            <Tooltip content={<CustomTooltip active={false} payload={undefined} label={undefined} />} />
            <Legend
              // iconSize={10}
              align='left'
              content={
                <CustomLegend
                  disabledChartLines={disabledChartLines}
                  onLegendClick={handleLegendClick}
                  activePivot={activePivot}
                />
              }
            />

            {activePivot === 'all' && (
              <>
                <Line
                  type='monotone'
                  dataKey='metric_value'
                  stroke='#CCA55A'
                  dot={false}
                  activeDot={{
                    stroke: 'transparent',
                    strokeWidth: 20,
                    r: 6,
                    cursor: 'pointer',
                    onMouseOver: () => {
                      setHoveredValue('metric_value')
                    },
                    onMouseOut: () => {
                      setHoveredValue('')
                    },
                    // onClick: handleClick,
                  }}
                  strokeWidth={1.5}
                  connectNulls
                />

                <Line
                  strokeDasharray='8 8'
                  dataKey='metric_running_average'
                  stroke='#8598AD'
                  strokeWidth={1.5}
                  dot={false}
                  activeDot={false}
                  connectNulls
                />
              </>
            )}

            {activePivot === 'sentiment' &&
              sentimentsToRender.map((sentiment) => {
                return (
                  <Line
                    key={sentiment as SentimentKey}
                    type='monotone'
                    dataKey={sentiment as SentimentKey}
                    stroke={sentimentsColors[sentiment as SentimentKey]}
                    strokeWidth={1.5}
                    dot={false}
                    activeDot={{
                      strokeWidth: 0,
                      r: 6,
                      cursor: 'pointer',
                      onMouseOver: () => {
                        setHoveredValue(sentiment as SentimentKey)
                      },
                      onMouseOut: () => {
                        setHoveredValue('')
                      },
                    }}
                    hide={disabledChartLines.includes(sentiment as SentimentKey)}
                    connectNulls
                  />
                )
              })}

            {activePivot === 'platform' &&
              platformsToRender.map((platform, index) => (
                <Line
                  key={platform}
                  type='monotone'
                  dataKey={platform}
                  stroke={UtilService.getPlatformColor(UtilService.getPlatformBrandName(platform))}
                  strokeWidth={1.5}
                  dot={false}
                  activeDot={{
                    strokeWidth: 0,
                    r: 6,
                    cursor: 'pointer',
                    onMouseOver: () => {
                      setHoveredValue(platform)
                    },
                    onMouseOut: () => {
                      setHoveredValue('')
                    },
                  }}
                  hide={disabledChartLines.includes(platform)}
                  connectNulls
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default DetailPanleTrends
