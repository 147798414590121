import { observer } from 'mobx-react-lite'
import { store } from 'store'
import classNames from 'classnames'
import { FolderListObjType, MoveTopFolderDataType, NarrativeListDataType, NarrativeParamsType } from 'types/types'
import { ReactComponent as FolderIcon } from 'assets/images/icons/dashboard/folder.svg'
import { ReactComponent as FolderOpen } from 'assets/images/icons/dashboard/cheveron-down.svg'
import { ReactComponent as DraggedIcon } from 'assets/images/icons/dashboard/menu-alt-4.svg'
import { ReactComponent as DraggedOver } from 'assets/images/icons/my_struff_icon.svg'
import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-icon.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import { ReactComponent as MoreItems } from 'assets/images/three-dots.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { useMemo, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useEffectOnce } from 'react-use'
import { Button, Dropdown, Skeleton, Spin } from 'antd'
import CreateFolderModal from 'components/Monitor/CreateFolderModal/CreateFolderModal'
import { useNavigate } from 'react-router-dom'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import { ShareReportEndpoint } from 'store/report/valueTypes'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import { ListSelectedRowData } from 'models/models'
import AlertModal from 'components/AlertModal/AlertModal'
import { EntityTypes } from 'store/asset/types'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import { ReactComponent as TickIcon } from 'assets/images/icons/monitor/check_icon.svg'
import './SearchList.scss'

type PropsType = {
  showSidebar: boolean
  selectedSearchItem: NarrativeListDataType | undefined
  setSelectedSearchItem: (searchItem: NarrativeListDataType | undefined) => void
}

type SearchItemDictonaryObject = {
  data: { total_count: number; items: any[] }
  per_page: number
  page: number
}

type OtherFolder = { name: string; id: string }
type Folder = { name: string; id: string }

const OTHER = 'OTHER'
const PER_PAGE = 10

const EmptyState = () => (
  <>
    <p className='search-items-loading'>
      <Skeleton paragraph active />
    </p>
    <p className='search-items-loading'>
      <Skeleton paragraph active />
    </p>
    <p className='search-items-loading'>
      <Skeleton paragraph active />
    </p>
  </>
)

const SearchList = observer((props: PropsType) => {
  const navigate = useNavigate()
  const { selectedSearchItem, setSelectedSearchItem, showSidebar } = props
  const { vectorsStore, toasterStore } = store
  const { userFolderList, fetchNarratives, moveToFolder, CDUFolder, fetchFolderList, deleteItem, getSearchData } =
    vectorsStore
  const { showToaster } = toasterStore
  const [currentOpenFolder, setCurrentOpenFolder] = useState<
    { name: string; id: string; collapsed: boolean } | undefined
  >()
  const [searchItemsDictionary, setSearchItemsDictionary] = useState<{
    [key: string]: SearchItemDictonaryObject
  }>({})
  const [movingSearchItem, setMovingSearchItem] = useState(false)
  const [isAddActionsOpen, setIsAddActionsOpen] = useState(false)

  const [searchItemsDictionaryLoaders, setSearchItemsDictionaryLoaders] = useState<{ [key: string]: boolean }>({})
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false)
  const [folderToEdit, setFolderToEdit] = useState<FolderListObjType | OtherFolder | undefined>()
  const [searchItemToEdit, setSearchItemToEdit] = useState<NarrativeListDataType | undefined>()
  const [openShareFolderModal, setOpenShareFolderModal] = useState(false)
  const [openShareSearchItemModal, setOpenShareSearchItemModal] = useState(false)
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState(false)
  const [isOpenDeleteSearchItemModal, setOpenDeleteSearchItemModal] = useState(false)
  const [isOpenSearchItemAlertModal, setOpenSearchItemAlertModal] = useState(false)
  const [isFolderNameEditOn, setIsFolderNameEditOn] = useState(false)
  const [editedFolderName, setEditedFolderName] = useState<string>('')

  useEffectOnce(() => {
    openFolder({
      name: 'Other searches',
      id: OTHER,
    })
  })

  const onChangeSearchItem = (searchItem: NarrativeListDataType) => setSelectedSearchItem(searchItem)

  const openFolder = (folder: Folder) => {
    if (currentOpenFolder?.name === folder.name) {
      setCurrentOpenFolder({ ...currentOpenFolder, collapsed: !currentOpenFolder.collapsed })
      return
    }
    const params: NarrativeParamsType = {
      sort: 'name:asc',
      page: 1,
      per_page: PER_PAGE,
    }
    if (folder.id !== OTHER) {
      params.folder_id = folder.id
    }
    if (searchItemsDictionary[folder.id]) {
      setCurrentOpenFolder({ name: folder.name, id: folder.id, collapsed: false })
    } else {
      setCurrentOpenFolder({ name: folder.name, id: folder.id, collapsed: false })
      setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [folder.id]: true })

      fetchNarratives({ params: params })
        .then((data: any) => {
          setSearchItemsDictionary({
            ...searchItemsDictionary,
            [folder.id]: { data: data, page: 1, per_page: PER_PAGE },
          })
          if (!selectedSearchItem) {
            setSelectedSearchItem(data?.items[0])
          }
        })
        .finally(() => {
          setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [folder.id]: false })
        })
    }
  }

  const handleDragEnd = (result: any) => {
    if (!result.destination || !currentOpenFolder) return
    const { destination, draggableId } = result
    if (destination.droppableId === OTHER) return

    const requestBody: MoveTopFolderDataType = {
      action: 'add',
      entities: [{ entity_id: draggableId, entity_type: 'NARRATIVE' }],
    }
    setMovingSearchItem(true)
    moveToFolder({ id: destination.droppableId, data: requestBody })
      .then((res) => {
        if (res !== 'error') {
          showToaster({
            iconType: 'success',
            message: 'Successfully added item to your Folder',
          })
          const currentFolderData = searchItemsDictionary[currentOpenFolder.id]
          const filteredItems = currentFolderData.data.items.filter((item) => item.id !== draggableId)
          const newFolderData = { ...currentFolderData, data: { ...currentFolderData.data, items: filteredItems } }

          setSearchItemsDictionary({
            ...searchItemsDictionary,
            [currentOpenFolder.id]: draggableId === OTHER ? currentFolderData : newFolderData,
            [destination.droppableId]: null,
          })
        }
      })
      .finally(() => {
        setMovingSearchItem(false)
      })
  }

  const onLoadMore = () => {
    if (!currentOpenFolder) return
    setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [currentOpenFolder.id]: true })

    const currentFolderData = searchItemsDictionary[currentOpenFolder.id]

    const params: NarrativeParamsType = {
      sort: 'name:asc',
      page: currentFolderData.page + 1,
      per_page: PER_PAGE,
    }
    if (currentOpenFolder.id !== OTHER) {
      params.folder_id = currentOpenFolder.id
    }
    fetchNarratives({ params: params })
      .then((data: any) => {
        setSearchItemsDictionary({
          ...searchItemsDictionary,
          [currentOpenFolder.id]: {
            data: { ...data, items: [...currentFolderData.data.items, ...data.items] },
            page: params.page as number,
            per_page: PER_PAGE,
          },
        })
      })
      .finally(() => {
        setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [currentOpenFolder.id]: false })
      })
  }

  const handleOpenChangeForAddActions = (status: boolean) => setIsAddActionsOpen(status)

  const navigateToCreateSearch = () => {
    const state = {
      comingFrom: '/searches',
    }
    navigate('/vectors/create/narrative_builder', { state: JSON.parse(JSON.stringify(state)) })
  }

  const addActionItems = [
    {
      label: (
        <div onClick={() => setOpenCreateFolderModal(true)} className='action-item'>
          <FolderIcon /> Add new folder
        </div>
      ),
      key: 'add-folder',
    },
    {
      label: (
        <div onClick={navigateToCreateSearch} className='action-item'>
          <SearchIcon />
          Add new search
        </div>
      ),
      key: 'add-search',
    },
  ]

  const folderActions = [
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setOpenShareFolderModal(true)
          }}
          className='action-item'>
          <ShareIcon /> Share
        </div>
      ),
      key: 'share-folder',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            if (!folderToEdit) return
            setEditedFolderName(folderToEdit.name)
            setIsFolderNameEditOn(true)
          }}
          className='action-item'>
          <EditIcon />
          Rename
        </div>
      ),
      key: 'rename-folder',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setOpenDeleteFolderModal(true)
          }}
          className='action-item'>
          <DeleteIcon />
          Delete
        </div>
      ),
      key: 'delete-folder',
    },
  ]

  const searchItemActions = (searchItem: NarrativeListDataType) => [
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setOpenShareSearchItemModal(true)
          }}
          className='action-item'>
          <ShareIcon /> Share
        </div>
      ),
      key: 'share-item',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setOpenSearchItemAlertModal(true)
          }}
          className='action-item'>
          {searchItemToEdit?.alert_id ? <FilledNotificationIcon /> : <AlertIcon />}
          Alerts
        </div>
      ),
      key: 'alert-item',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            if (searchItem.permission_level !== 'EDITOR') return
            const state = {
              ...searchItemToEdit,
              comingFrom: '/searches',
              editMode: true,
            }
            navigate('/vectors/details', { state: JSON.parse(JSON.stringify(state)) })
          }}
          className='action-item'>
          <EditIcon />
          Edit
        </div>
      ),
      disabled: searchItem.permission_level !== 'EDITOR',
      key: 'rename-item',
    },

    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            if (searchItem.permission_level !== 'EDITOR') return
            setOpenDeleteSearchItemModal(true)
          }}
          className='action-item'>
          <DeleteIcon />
          Delete
        </div>
      ),
      key: 'delete-item',
      disabled: searchItem.permission_level !== 'EDITOR',
    },
  ]

  const createFolderHandler = (value: string) => {
    if (value) {
      CDUFolder({ method: 'post', data: { name: value } }).then((res) => {
        if (res !== 'error') {
          fetchFolderList({})
        }
        setOpenCreateFolderModal(false)
      })
    }
  }

  const onStopEvent = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }

  const onFolderMoreOptions = (event: React.MouseEvent<HTMLElement>, folder: FolderListObjType | OtherFolder) => {
    onStopEvent(event)
    setFolderToEdit(folder)
  }

  const onSearchItemMoreActions = (event: React.MouseEvent<HTMLElement>, searchItem: NarrativeListDataType) => {
    onStopEvent(event)
    setSearchItemToEdit(searchItem)
  }

  const onFolderDelete = () => {
    if (!folderToEdit) return
    CDUFolder({ method: 'delete', id: folderToEdit.id }).then((res) => {
      if (res !== 'error') {
        fetchFolderList({})
      }
      setFolderToEdit(undefined)
      setOpenDeleteFolderModal(false)
    })
  }

  const onSearchItemDelete = () => {
    if (!searchItemToEdit) return
    deleteItem({ ...searchItemToEdit, ownerId: searchItemToEdit.owner_id } as ListSelectedRowData).then(
      (res: any | 'error') => {
        if (res !== 'error') {
          if (currentOpenFolder) {
            const currentFolderDetails = searchItemsDictionary[currentOpenFolder.id]
            const filteredItems = currentFolderDetails.data.items.filter((item) => item.id !== searchItemToEdit.id)
            const newFolderDetails = {
              ...currentFolderDetails,
              data: {
                ...currentFolderDetails.data,
                items: filteredItems,
                total_count: currentFolderDetails.data.total_count - 1,
              },
            }
            setSearchItemsDictionary({ ...searchItemsDictionary, [currentOpenFolder.id]: newFolderDetails })

            // see if the current item is deleted and replace it with another search item
            const newItemToSelect = filteredItems[0]
            if (selectedSearchItem?.id === searchItemToEdit.id && newItemToSelect) {
              setSelectedSearchItem(newItemToSelect)
            } else if (selectedSearchItem?.id === searchItemToEdit.id && !newItemToSelect) {
              setSelectedSearchItem(undefined)
              openFolder({
                name: 'Other searches',
                id: OTHER,
              })
            }
            setOpenDeleteSearchItemModal(false)
            setSearchItemToEdit(undefined)
          }
        }
      },
    )
  }

  const onAlertUpdateSuccess = async (alertTurnedOff = false) => {
    if (searchItemToEdit) {
      const data = await getSearchData(searchItemToEdit?.id)

      if (!currentOpenFolder) return
      const currentFolderDetails = searchItemsDictionary[currentOpenFolder.id]
      const modifiedItems = currentFolderDetails.data.items.map((item) => {
        if (item.id === searchItemToEdit.id) {
          return { ...item, alert_id: data.alert_id }
        }
        return item
      })
      const newFolderDetails = {
        ...currentFolderDetails,
        data: {
          ...currentFolderDetails.data,
          items: modifiedItems,
        },
      }
      setOpenSearchItemAlertModal(false)
      setSearchItemsDictionary({ ...searchItemsDictionary, [currentOpenFolder.id]: newFolderDetails })
      setSearchItemToEdit(undefined)
      showToaster({
        iconType: 'success',
        message: alertTurnedOff
          ? 'You will stop receiving alerts from now'
          : 'You will start receiving new alerts according to the selected preferences',
      })
    }
  }

  const onOpenFolder = (folder: Folder) => {
    if (folderToEdit?.id === folder.id && isFolderNameEditOn) return
    openFolder(folder)
  }

  const onCloseFolderNameEdit = (event: any) => {
    onStopEvent(event)
    setEditedFolderName('')
    setIsFolderNameEditOn(false)
    setFolderToEdit(undefined)
  }

  const onEditFolderName = (event: any) => {
    onStopEvent(event)
    if (!folderToEdit) return
    CDUFolder({ method: 'put', id: folderToEdit.id, data: { name: editedFolderName } }).then((res) => {
      if (res !== 'error') {
        setEditedFolderName('')
        setIsFolderNameEditOn(false)
        setFolderToEdit(undefined)
        fetchFolderList({})
      }
    })
  }

  const customUserFolderList = useMemo(() => {
    const folders = {
      items: [...userFolderList.items, { name: 'Other searches', id: OTHER }],
      total_count: userFolderList.total_count,
    }
    return folders
  }, [userFolderList])

  return (
    <Spin spinning={movingSearchItem}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='narrativeDroppable' type='narrative'>
          {(provided: any) => (
            <div
              className={classNames('searchItemList', {
                hideSearchItemList: !showSidebar,
              })}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              <div className='fixed'>
                <div className='list-section'>
                  {customUserFolderList.items.map((userFolder: FolderListObjType | OtherFolder) => (
                    <Droppable key={userFolder.id} droppableId={String(userFolder.id)}>
                      {(provided: any, snapshot: any) => (
                        <div className='folder-container' ref={provided.innerRef} {...provided.droppableProps}>
                          <div
                            className={classNames('folder-item', {
                              'folder-item-droppable': snapshot.isDraggingOver,
                              'folder-item-open': currentOpenFolder?.name === userFolder.name,
                            })}
                            onClick={() => onOpenFolder(userFolder)}>
                            {!snapshot.isDraggingOver ? (
                              currentOpenFolder?.name === userFolder.name && !currentOpenFolder.collapsed ? (
                                <FolderOpen />
                              ) : (
                                <FolderIcon />
                              )
                            ) : null}
                            {snapshot.isDraggingOver && <DraggedOver />}
                            {folderToEdit?.id === userFolder.id && isFolderNameEditOn ? (
                              <>
                                <input
                                  className='fpc_lc_c_input'
                                  type='text'
                                  value={editedFolderName}
                                  onChange={(e) => {
                                    setEditedFolderName(e.target.value)
                                  }}
                                />

                                <CloseIcon className='fpc_lc_chunk_dropdown_icon' onClick={onCloseFolderNameEdit} />
                                {editedFolderName ? (
                                  <TickIcon className='fpc_lc_chunk_dropdown_icon' onClick={onEditFolderName} />
                                ) : null}
                              </>
                            ) : (
                              <h6 className='title'>{userFolder.name}</h6>
                            )}
                            {userFolder.id !== OTHER && (
                              <Dropdown menu={{ items: folderActions }} placement='bottomRight' trigger={['click']}>
                                <span
                                  className='folder-actions'
                                  onClick={(event) => onFolderMoreOptions(event, userFolder)}>
                                  <MoreItems />
                                </span>
                              </Dropdown>
                            )}
                          </div>
                          {currentOpenFolder?.name === userFolder.name && !currentOpenFolder.collapsed && (
                            <>
                              <div className='folder-items-list'>
                                {searchItemsDictionary[currentOpenFolder.id]?.data?.items?.map((searchItem, index) => (
                                  <Draggable key={searchItem.id} draggableId={String(searchItem.id)} index={index}>
                                    {(provided: any, snapshot: any) => (
                                      <div
                                        className='item-container'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <div className='link' />
                                        <div
                                          onClick={() => onChangeSearchItem(searchItem)}
                                          className={classNames(`brand-item`, {
                                            'brand-item-selected':
                                              selectedSearchItem?.id === searchItem.id || snapshot.isDragging,
                                          })}>
                                          {snapshot.isDragging && <DraggedIcon />}
                                          <h6 className='title'>{searchItem.name}</h6>
                                          <Dropdown
                                            menu={{ items: searchItemActions(searchItem) }}
                                            placement='bottomRight'
                                            trigger={['click']}>
                                            <span
                                              className='search-actions'
                                              onClick={(event) => onSearchItemMoreActions(event, searchItem)}>
                                              <MoreItems />
                                            </span>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}

                                {provided.placeholder}
                              </div>
                              {searchItemsDictionaryLoaders[currentOpenFolder.id] && <EmptyState />}
                              {!searchItemsDictionaryLoaders[currentOpenFolder.id] &&
                                searchItemsDictionary[currentOpenFolder.id]?.data?.items.length === 0 && (
                                  <p className='search-items-empty'>No results found</p>
                                )}
                              {!searchItemsDictionaryLoaders[currentOpenFolder.id] &&
                                searchItemsDictionary[currentOpenFolder.id]?.data?.items.length !==
                                  searchItemsDictionary[currentOpenFolder.id]?.data?.total_count && (
                                  <p className='search-items-load-more' onClick={onLoadMore}>
                                    Load more
                                  </p>
                                )}
                            </>
                          )}
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
                <div className='action-section'>
                  <Dropdown
                    menu={{ items: addActionItems }}
                    placement='topLeft'
                    onOpenChange={handleOpenChangeForAddActions}
                    trigger={['click']}>
                    <Button className='button'>
                      Add new{' '}
                      <DropdownIcon
                        className={classNames({
                          'button-open': isAddActionsOpen,
                        })}
                      />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {openCreateFolderModal && (
        <CreateFolderModal onClose={() => setOpenCreateFolderModal(false)} onConfirm={createFolderHandler} />
      )}
      {folderToEdit && openShareFolderModal && (
        <ShareReportModal
          endpoint={'folder' as ShareReportEndpoint}
          reportId={folderToEdit.id}
          title='Share Folder'
          desc='Share this folder with one or several team members'
          secondButtonText='Share'
          icon={null}
          onClose={() => {
            setOpenShareFolderModal(false)
            setFolderToEdit(undefined)
          }}
        />
      )}
      {searchItemToEdit && openShareSearchItemModal && (
        <ShareReportModal
          endpoint={searchItemToEdit?.mode as ShareReportEndpoint}
          reportId={searchItemToEdit.id}
          title='Share Search'
          desc='Share this search with one or several team members'
          secondButtonText='Share'
          icon={null}
          onClose={() => {
            setOpenShareSearchItemModal(false)
            setSearchItemToEdit(undefined)
          }}
        />
      )}
      {folderToEdit && openDeleteFolderModal && (
        <DeleteConfirmationModal
          title='Are you sure you want to delete this folder?'
          desc='This action cannot be undone.'
          onClose={() => {
            setOpenDeleteFolderModal(false)
            setFolderToEdit(undefined)
          }}
          onContinue={onFolderDelete}
          disabledSecondButton={!folderToEdit}
        />
      )}
      {searchItemToEdit && isOpenDeleteSearchItemModal && (
        <DeleteConfirmationModal
          title='Are you sure you want to delete this search?'
          desc='This action cannot be undone.'
          onClose={() => {
            setOpenDeleteSearchItemModal(false)
            setSearchItemToEdit(undefined)
          }}
          onContinue={onSearchItemDelete}
          disabledSecondButton={!searchItemToEdit}
        />
      )}
      {isOpenSearchItemAlertModal && (
        <AlertModal
          entity_id={searchItemToEdit?.id as string}
          entity_type={
            searchItemToEdit?.mode === 'community' ? null : (searchItemToEdit?.mode?.toUpperCase() as EntityTypes)
          }
          onClose={() => setOpenSearchItemAlertModal(false)}
          open={isOpenSearchItemAlertModal}
          alertId={searchItemToEdit?.alert_id as string}
          onSuccess={onAlertUpdateSuccess}
          onDeleteSuccess={() => onAlertUpdateSuccess(true)}
        />
      )}
    </Spin>
  )
})

export default SearchList
