import styles from './ModalTitle.module.scss'

export const ModalTitle = (props: any) => {
  return (
    <div className={styles.titleWrapper}>
      <span className={styles.icon}>
        <span className={styles.icon}>{props.icon}</span>
      </span>

      <span className={styles.titleText}>{props.title}</span>
    </div>
  )
}
