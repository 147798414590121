import axios from 'axios'

import { ROUTES } from 'settings/settings'

export const deleteWatchlist = async (watchlistId?: string) => {
  let url = process.env.REACT_APP_API + ROUTES.watchlist

  if (!!watchlistId) url = `${process.env.REACT_APP_API}${ROUTES.watchlist}/${watchlistId}`

  const { data } = await axios.delete(url)

  return data
}
