import { Modal, Spin, Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { store } from 'store'
import classNames from 'classnames'

import { IActiveTenantConfigurationDetails } from 'models/models'

import '../TenantModals.scss'
import './TenantConfigurationEditModal.scss'

interface Props {
  showModal: boolean
  configInfo: IActiveTenantConfigurationDetails | null
  setFetchData: (state: boolean) => void
  setShowModal: (state: boolean) => void
}
export const TenantConfigurationEditModal = observer(({ showModal, configInfo, setFetchData, setShowModal }: Props) => {
  const { id } = useParams()

  const [showError, setShowError] = useState<boolean>(false)
  const [newConfigData, setNewConfigData] = useState<{ [x: string]: any } | undefined>(configInfo?.config_json)

  const { tenantsStore, loaderStore } = store
  const { editTenantConfiguration } = tenantsStore
  const { isLoadingTenants } = loaderStore

  useEffect(() => {
    if (configInfo) setNewConfigData(configInfo?.config_json)
  }, [configInfo])

  const saveChanges = async () => {
    if (id && configInfo && id?.length > 0) {
      return editTenantConfiguration(id, configInfo.configuration_name, newConfigData)
    }
  }

  return (
    <Modal
      className='tenant-modal tenant-configuration-edit-modal'
      title={
        <span className=' tenant-configuration-edit-modal__title'>
          {(configInfo?.label?.length || 0) > 0 ? configInfo?.label : 'Configuration label'}
        </span>
      }
      closable
      destroyOnClose
      open={showModal}
      width={window.innerWidth / 2.3}
      bodyStyle={{ height: window.innerHeight - 170 }}
      onCancel={() => setShowModal(false)}
      footer={
        <div className='tenant-configuration-edit-modal__footer'>
          <button
            className='tenant-configuration-edit-modal__cancel-btn'
            onClick={() => {
              setShowModal(false)
            }}>
            Cancel
          </button>
          <button
            className='tenant-configuration-edit-modal__save-btn'
            onClick={async () => {
              const response = await saveChanges()
              if (response) {
                setShowModal(false)
                setFetchData(true)
              }
            }}
            disabled={!configInfo?.admin_may_edit || showError}>
            Save changes
          </button>
        </div>
      }>
      <Spin spinning={isLoadingTenants}>
        <div className='tenant-configuration-edit-modal__body'>
          <div className='tenant-configuration-edit-modal__permissions'>
            <span className='tenant-configuration-edit-modal__permissions__label'>Admin Permissions:</span>
            <div className='tenant-configuration-edit-modal__permissions__switch'>
              <div className='tenant-configuration-edit-modal__permissions__switch-div'>
                <Switch checked={configInfo?.admin_may_view} disabled />
                <span className='tenant-configuration-edit-modal__permissions__switch-div__label'>Can view</span>
              </div>
              <div className='tenant-configuration-edit-modal__permissions__switch-div'>
                <Switch checked={configInfo?.admin_may_edit} disabled />
                <span className='tenant-configuration-edit-modal__permissions__switch-div__label'>Can edit</span>
              </div>
            </div>
          </div>
          <span
            className={classNames('tenant-configuration-edit-modal__editor__body__error', {
              'tenant-configuration-edit-modal__editor__body__error--hide': showError,
            })}>
            Invalid JSON
          </span>
          <div className='tenant-configuration-edit-modal__editor'>
            <div className='tenant-configuration-edit-modal__editor__body'>
              <div className='tenant-configuration-edit-modal__editor__body__leftbar'>
                {Array.from({ length: 20 }, (_, index) => (
                  <span key={index}>{index + 1}</span>
                ))}
              </div>
              <div className='tenant-configuration-edit-modal__editor__body__code-div'>
                <code>
                  <pre
                    contentEditable={configInfo?.admin_may_edit}
                    onInput={(e: any) => {
                      try {
                        setShowError(false)
                        const checkParsing = JSON.parse(e.target.innerHTML)
                        if (checkParsing) setNewConfigData(JSON.parse(e.target.innerHTML))
                      } catch (error) {
                        setShowError(true)
                      }
                    }}>
                    {JSON.stringify(configInfo?.config_json, null, 4)}
                  </pre>
                </code>
              </div>
            </div>

            <div className='tenant-configuration-edit-modal__editor__footer'>
              <span>LF</span>
              <span>Line 5:36</span>
              <span>UTF8</span>
              <span>2 spaces</span>
              <span>main</span>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
})
