import { BarChart, ResponsiveContainer, XAxis } from 'recharts'

import './BeehiveChartXAxis.scss'

interface Props {
  data: { date: string }[]
  maxWidth: number
}

export const BeehiveChartXAxis = ({ data = [], maxWidth = 0 }: Props): JSX.Element => {
  return (
    <div className='c-bar-chart-x'>
      <ResponsiveContainer width={maxWidth}>
        <BarChart data={data} margin={{ top: 25, right: 0, left: 0, bottom: 25 }}>
          <XAxis
            dataKey='date'
            tickLine={{ stroke: '#DBDAD8', strokeWidth: 3 }}
            axisLine={{ stroke: '#DBDAD8', strokeWidth: 2 }}
            padding={{ left: 50, right: 50 }}
            minTickGap={20}
            style={{
              fontSize: '13px',
              textTransform: 'uppercase',
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
