import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { AssetListParamsType } from 'store/asset/types'
import { FlagListParamsType } from 'store/flags/types'

export function getFlagsList({ params }: { params: FlagListParamsType | AssetListParamsType }) {
  return axios({
    method: 'get',
    url: ROUTES.flagsList,
    params: params,
  })
}
