import { useState } from 'react'
import CustomModal from 'components/common/CustomModal/CustomModal'
import Input from 'components/common/Inputs/Input/Input'
import './CreateFolderModal.scss'

type CreateFolderModalProps = {
  onConfirm: (val: string) => void
  onClose: () => void
}

function CreateFolderModal(props: CreateFolderModalProps) {
  const { onClose, onConfirm } = props
  const [value, setValue] = useState<string>('')
  return (
    <CustomModal
      title='Create new Folder'
      secondButtonText='Confirm'
      onClose={onClose}
      onConfirm={() => onConfirm(value)}
      disabledSecondButton={!value}
      containerClassName='cfm_container'
      boxContainerClassName='cfm_box_container'
      bodyClassName='cfm_body_container'>
      <div className='cfm_modal_body_container'>
        <Input
          className='cfm_mbc_input'
          placeholder='Type in folder name'
          title='Folder Name'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </CustomModal>
  )
}

export default CreateFolderModal
