import { observer } from 'mobx-react-lite'
import { useEffectOnce } from 'react-use'
import { store } from 'store'

import { RiskWandTable } from './RiskWandTable/RiskWandTable'
import { ListPageSection } from '../common/ListPageSection/ListPageSection'
import { DetailsSection } from './DetailsSection/DetailsSection'
import { ChordGraphView } from './ChordGraph/ChordGraphView'
import { useEffect } from 'react'
import ThreeDChart from './ThreeDChart/ThreeDChart'
import { ElectionChordGraphView } from './ChordGraph/Elections/ElectionChordGraphView'

export const AnticipatoryIntelligence = observer(() => {
  const { anticipatoryIntelligenceStore, userStore } = store
  const {
    chartName,
    isSideDetailsOpen,
    tenantInfo,
    setUserTenant,
    fetchThreatTopics,
    fetchRisks,
    fetchAttentionFlags,
    fetchElectionIssues,
    fetchElectionRaces,
    fetchTenantInfo,
  } = anticipatoryIntelligenceStore

  const { userInfo, getUserInfo } = userStore

  useEffectOnce(() => {
    fetchThreatTopics()
    fetchRisks()
    fetchAttentionFlags()
    fetchElectionIssues()
    fetchElectionRaces()
  })

  useEffectOnce(() => {
    if (!userInfo.tenant_id.length) getUserInfo()
  })

  useEffect(() => {
    setUserTenant(userInfo.tenant_id || '')
    if (userInfo && userInfo?.tenant_id && !tenantInfo?.name?.length) fetchTenantInfo(userInfo.tenant_id)
  }, [userInfo.tenant_id])

  if (chartName === 'list')
    return (
      <ListPageSection
        left={<RiskWandTable />}
        right={isSideDetailsOpen.length > 0 ? <DetailsSection /> : <></>}
        columns={{
          col1: {
            sm: isSideDetailsOpen.length > 0 ? 16 : 24,
            md: isSideDetailsOpen.length > 0 ? 16 : 24,
            lg: isSideDetailsOpen.length > 0 ? 16 : 24,
          },
          col2: {
            sm: isSideDetailsOpen.length > 0 ? 8 : 0,
            md: isSideDetailsOpen.length > 0 ? 8 : 0,
            lg: isSideDetailsOpen.length > 0 ? 8 : 0,
          },
        }}
      />
    )
  else if (chartName === 'chord') return <ChordGraphView />
  else if (chartName === '3d') return <ThreeDChart />
  else if (chartName === 'election') return <ElectionChordGraphView />
  else return <></>
})
