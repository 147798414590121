import { Auth } from 'aws-amplify'
import { Checkbox, Modal, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { store } from 'store'
import classNames from 'classnames'

import { verifyAgreement } from 'api/agreement/verifyAgreement.api'

import { TOSDocument } from './TOSDoc/TOSDoc'
import { openError } from 'services/Util/Error/openError'

import './Agreement.scss'

interface Props {
  openModal: string
  setOpenModal: (state: 'admin' | 'user' | 'close') => void
  setOpenAgreementWelcomeModal: (state: boolean) => void
}
export const Agreement = observer(({ openModal, setOpenModal, setOpenAgreementWelcomeModal }: Props) => {
  const navigate = useNavigate()

  const [isTOSChecked, setIsTOSChecked] = useState<boolean>(false)

  const { loaderStore, userStore } = store
  const { isTOS } = loaderStore
  const { setUser } = userStore

  const footer = () => {
    return (
      <Spin spinning={isTOS}>
        <div className='agreement__footer'>
          <button className='agreement__footer__button--decline' onClick={signOut}>
            Decline
          </button>
          <button
            className={classNames(
              'agreement__footer__button',
              { 'agreement__footer__button--active': isTOSChecked },
              { 'agreement__footer__button--disable': !isTOSChecked },
            )}
            disabled={!isTOSChecked}
            onClick={VerifyAgreement}>
            Accept
          </button>
        </div>
      </Spin>
    )
  }

  const VerifyAgreement = async () => {
    try {
      await verifyAgreement()
      localStorage.setItem('user-tos-status', 'verified')
      localStorage.removeItem('user-tos-assigned-admins')
      setOpenAgreementWelcomeModal(true)
    } catch (error: any) {
      const response = error.response
      openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  const signOut = async () => {
    await Auth.signOut()

    setUser({
      given_name: null,
      family_name: null,
      userName: null,
      userEmail: null,
      tenantId: null,
      userId: null,
      roleId: null,
      subId: null,
    })
    setOpenModal('close')
    localStorage.removeItem('user-tos-status')
    localStorage.removeItem('user-tos-assigned-admins')
    navigate('/', { replace: true })
    sessionStorage.removeItem('snippetDateFilter')
  }

  return (
    <Modal
      className='agreement'
      open={openModal === 'admin'}
      centered
      width={718}
      title={
        <div className='agreement__title'>
          <span>{'Terms of Service'}</span>
        </div>
      }
      footer={footer()}>
      <div className='agreement__main-div'>
        <div className='agreement__content-div'>
          <span className='agreement__description'>{`Welcome to Pendulum. Please review and accept our Terms of Service to activate your organization's account. Please scroll through the entire document to proceed.`}</span>

          <div className='agreement__content'>{<TOSDocument />}</div>
        </div>
        <div className='agreement__checkbox'>
          <Checkbox
            className='agreement'
            value={isTOSChecked}
            onChange={(e) => {
              setIsTOSChecked(e.target.checked)
            }}
          />
          <span className='agreement__checkbox__title'>{'I have read and agree to the Terms of Service'}</span>
        </div>
      </div>
    </Modal>
  )
})
