import React from 'react'
import styles from './DetailViewRightSection.module.scss'
import DetailViewSectionDetail from '../DetailViewSectionDetail/DetailViewSectionDetail'
import { sectionObj } from 'store/report/valueTypes'

type tagsObj = {
  id: string
  name: string
  color: string
}
type bookmarksbj = {
  id: string
  name: string
  link: string
}

export type collectionObj = {
  watchlists: bookmarksbj[]
  narratives: bookmarksbj[]
  bookmarks: bookmarksbj[]
  tags: tagsObj[]
  summary: string
}

export type contentObj = {
  id: string
  desc: string
}

// type sectionObj = {
//   id: string
//   name: string
//   content_arr: contentObj[]
//   collection_added: collectionObj
// }

type DetailViewRightSectionProps = {
  data: sectionObj[]
}
function DetailViewRightSection(props: DetailViewRightSectionProps) {
  const { data } = props

  return (
    <div className={styles.detail_view_right_container}>
      {data.map((section) => (
        <DetailViewSectionDetail key={section.id} {...section} sectionData={section} />
      ))}
    </div>
  )
}

export default DetailViewRightSection
