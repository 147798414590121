import axios from 'axios'

import { ROUTES } from 'settings/settings'

interface RemoveSnippetFromBookmarkAPIProps {
  payload: {
    entity_id: string
    entity_type: 'SNIPPET'
  }
}

export const removeSnippetFromBookmark = async ({ payload }: RemoveSnippetFromBookmarkAPIProps) => {
  let url = `${process.env.REACT_APP_API}/${ROUTES.bookmark}/remove`

  const { data } = await axios.post(url, payload)

  return data
}
