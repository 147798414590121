import React from 'react'
import classNames from 'classnames'
import { ReactComponent as EmptyDataIcon } from 'assets/images/icons/report/no-data-icon.svg'
import styles from './DetailViewEmptyData.module.scss'

type DetailViewEmptyDataProps = {
  className?: string
  icon?: React.ElementType | null
  title?: string
  desc?: string
}

function DetailViewEmptyData(props: DetailViewEmptyDataProps) {
  const {
    className,
    icon: Icon = EmptyDataIcon,
    title = 'No Data available for this section',
    desc = 'Try to select any other date. For the moment there is no data available for this section.',
  } = props
  return (
    <div className={classNames(styles.dved_container, className)}>
      {Icon ? <Icon /> : <EmptyDataIcon />}
      <p className={styles.dved_c_heading}>{title}</p>
      <p className={styles.dved_c_desc}>{desc}</p>
    </div>
  )
}

export default DetailViewEmptyData
