import { observer } from 'mobx-react-lite'
import { ChordChartDataset } from 'models/models'
import { useEffect, useState } from 'react'
import { ChordGraph } from './ChordGraph'
import * as d3 from 'd3'
import { ListPageSection } from 'components/common/ListPageSection/ListPageSection'
import { store } from 'store'
import { DetailsSection } from '../DetailsSection/DetailsSection'
import classNames from 'classnames'

import { ReactComponent as ListViewIcon } from 'assets/images/listview-icon.svg'
import { ReactComponent as ChordChartIcon } from 'assets/images/chord-chart-icon.svg'
import { ReactComponent as ThreeDIcon } from 'assets/images/riskwand/3d_chart_icon.svg'
import { ReactComponent as ElectionChordChartIcon } from 'assets/images/elecion-icon.svg'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import { Tooltip } from 'antd'

export const ChordGraphView = observer(() => {
  const width = 310
  const radius = width / 2
  const range = ['C1', 'C2', 'C3', 'C4', 'C5', 'M1', 'M2', 'M3', 'M4', 'M5', 'M6']
  const dataset = [
    { name: 'topic.threat.economic.inflation', label: 'topic.threat.economic.inflation', size: 0, imports: [] },
    { name: 'topic.threat.economic.recession', size: 0.01, imports: [], label: 'topic.threat.economic.recession' },
    {
      name: 'topic.threat.economic.TITLE_economic',
      size: -0.01,
      imports: [],
      label: 'topic.threat.economic.TITLE_economic',
    },
    {
      name: 'topic.threat.economic.cost_of_energy',
      size: -0.03,
      imports: [],
      label: 'topic.threat.economic.cost_of_energy',
    },
    {
      name: 'topic.threat.economic.cost_of_housing',
      size: -0.02,
      imports: [],
      label: 'topic.threat.economic.cost_of_housing',
    },
    {
      name: 'topic.threat.economic.food_shortage',
      size: 0.02,
      imports: [],
      label: 'topic.threat.economic.food_shortage',
    },
    { name: 'topic.threat.economic.strike', size: 0.03, imports: [], label: 'topic.threat.economic.strike' },
    { name: 'topic.threat.political.corruption', size: -0.02, imports: [], label: 'topic.threat.political.corruption' },
    { name: 'topic.threat.political.election', size: -0.01, imports: [], label: 'topic.threat.political.election' },
    {
      name: 'topic.threat.political.TITLE_political',
      size: 0,
      imports: [],
      label: 'topic.threat.political.TITLE_political',
    },
    { name: 'topic.threat.political.sanction', size: 0.01, imports: [], label: 'topic.threat.political.sanction' },
    { name: 'topic.threat.cyber.data_breaches', size: -0.02, imports: [], label: 'topic.threat.cyber.data_breaches' },
    { name: 'topic.threat.cyber.ransomware', size: -0.01, imports: [], label: 'topic.threat.cyber.ransomware' },
    { name: 'topic.threat.cyber.TITLE_cyber', size: 0, imports: [], label: 'topic.threat.cyber.TITLE_cyber' },
    {
      name: 'topic.threat.cyber.digital_privacy',
      size: 0.01,
      imports: [],
      label: 'topic.threat.cyber.digital_privacy',
    },
    {
      name: 'topic.threat.cyber.digitial_rights',
      size: 0.02,
      imports: [],
      label: 'topic.threat.cyber.digitial_rights',
    },
    {
      name: 'topic.threat.environmental.energy_efficiency',
      size: -0.03,
      imports: [],
      label: 'topic.threat.environmental.energy_efficiency',
    },
    {
      name: 'topic.threat.environmental.Water scarcity',
      size: -0.02,
      imports: [],
      label: 'topic.threat.environmental.Water scarcity',
    },
    {
      name: 'topic.threat.environmental.deforestation',
      size: -0.01,
      imports: [],
      label: 'topic.threat.environmental.deforestation',
    },
    {
      name: 'topic.threat.environmental.TITLE_environmental',
      size: 0,
      imports: [],
      label: 'topic.threat.environmental.TITLE_environmental',
    },
    {
      name: 'topic.threat.environmental.climate_change',
      size: 0.01,
      imports: [],
      label: 'topic.threat.environmental.climate_change',
    },
    {
      name: 'topic.threat.environmental.waste_management',
      size: 0.02,
      imports: [],
      label: 'topic.threat.environmental.waste_management',
    },
    {
      name: 'topic.threat.environmental.biodiversity',
      size: 0.03,
      imports: [],
      label: 'topic.threat.environmental.biodiversity',
    },
    {
      name: 'topic.threat.social.wage_exploitation',
      size: -0.05,
      imports: [],
      label: 'topic.threat.social.wage_exploitation',
    },
    { name: 'topic.threat.social.social_unrest', size: -0.04, imports: [], label: 'topic.threat.social.social_unrest' },
    { name: 'topic.threat.social.human_rights', size: -0.03, imports: [], label: 'topic.threat.social.human_rights' },
    { name: 'topic.threat.social.dei', size: -0.02, imports: [], label: 'topic.threat.social.dei' },
    { name: 'topic.threat.social.lgbtq', size: -0.01, imports: [], label: 'topic.threat.social.lgbtq' },
    { name: 'topic.threat.social.TITLE_social', size: 0, imports: [], label: 'topic.threat.social.TITLE_social' },
    { name: 'topic.threat.social.child_labor', size: 0.01, imports: [], label: 'topic.threat.social.child_labor' },
    { name: 'topic.threat.social.unionization', size: 0.02, imports: [], label: 'topic.threat.social.unionization' },
    { name: 'topic.threat.social.1st_amendment', size: 0.03, imports: [], label: 'topic.threat.social.1st_amendment' },
    {
      name: 'topic.threat.social.2nd_ammendment',
      size: 0.04,
      imports: [],
      label: 'topic.threat.social.2nd_ammendment',
    },
    { name: 'topic.threat.social.roe_v_wade', size: 0.05, imports: [], label: 'topic.threat.social.roe_v_wade' },
    {
      name: 'topic.threat.governance.whistleblower_schemes',
      size: -0.02,
      imports: [],
      label: 'topic.threat.governance.whistleblower_schemes',
    },
    {
      name: 'topic.threat.governance.political_contribution',
      size: -0.01,
      imports: [],
      label: 'topic.threat.governance.political_contribution',
    },
    {
      name: 'topic.threat.governance.TITLE_governance',
      size: 0,
      imports: [],
      label: 'topic.threat.governance.TITLE_governance',
    },
    {
      name: 'topic.threat.governance.investor_activism',
      size: 0.01,
      imports: [],
      label: 'topic.threat.governance.investor_activism',
    },
    {
      name: 'topic.threat.governance.executive_compensation',
      size: 0.03,
      imports: [],
      label: 'topic.threat.governance.executive_compensation',
    },
    { name: 'topic.threat.governance.lobbying', size: 0.02, imports: [], label: 'topic.threat.governance.lobbying' },
    {
      name: 'topic.threat.natural_disaster.pandemics',
      size: -0.02,
      imports: [],
      label: 'topic.threat.natural_disaster.pandemics',
    },
    {
      name: 'topic.threat.natural_disaster.drought',
      size: -0.01,
      imports: [],
      label: 'topic.threat.natural_disaster.drought',
    },
    {
      name: 'topic.threat.natural_disaster.TITLE_natural_disaster',
      size: 0,
      imports: [],
      label: 'topic.threat.natural_disaster.TITLE_natural_disaster',
    },
    {
      name: 'topic.threat.natural_disaster.flood',
      size: 0.01,
      imports: [],
      label: 'topic.threat.natural_disaster.flood',
    },
    { name: 'topic.range.closeRange.C1.Brand', size: 0, imports: [], label: 'topic.range.closeRange.C1.Brand' },
    { name: 'topic.range.closeRange.C2.Board', size: 0, imports: [], label: 'topic.range.closeRange.C2.Board' },
    { name: 'topic.range.closeRange.C3.Executive', size: 0, imports: [], label: 'topic.range.closeRange.C3.Executive' },
    { name: 'topic.range.closeRange.C4.Supplier', size: 0, imports: [], label: 'topic.range.closeRange.C4.Supplier' },
    {
      name: 'topic.range.closeRange.C5.Industry',
      size: 0,
      imports: [],
      label: 'topic.range.closeRange.C5.Industry',
    },
    {
      name: 'topic.range.midRange.M1.legislative_regulation',
      size: 0,
      imports: [],
      label: 'topic.range.midRange.M1.legislative_regulation',
    },
    {
      name: 'topic.range.midRange.M2.kinetic_action',
      size: 0,
      imports: [],
      label: 'topic.range.midRange.M2.kinetic_action',
    },
    {
      name: 'topic.range.midRange.M3.legal_action',
      size: 0,
      imports: [],
      label: 'topic.range.midRange.M3.legal_action',
    },
    { name: 'topic.range.midRange.M4.boycott', size: 0, imports: [], label: 'topic.range.midRange.M4.boycott' },
    {
      name: 'topic.range.midRange.M5.social_unrest',
      size: 0,
      imports: [],
      label: 'topic.range.midRange.M5.social_unrest',
    },
    { name: 'topic.range.midRange.M6.taxation', size: 0.02, imports: [], label: 'topic.range.midRange.M6.taxation' },
  ]
  const treeCluster = d3
    .cluster()
    .size([2 * Math.PI, radius - 100])
    .separation((a: any, b: any) => {
      if (a.parent === b.parent) {
        if (b.data.name.includes('TITLE')) return 6
        else if (a.data.name.includes('TITLE')) return 2
        else return 2
      } else if (range.includes(a.parent.data.name)) return 12
      else return 8
    })

  const [data, setData] = useState(dataset)
  const [root, setRoot] = useState<d3.HierarchyPointNode<unknown> | null>(null)

  const { anticipatoryIntelligenceStore } = store
  const {
    chartName,
    isSideDetailsOpen,
    setChartName,
    setIsSideDetailsOpen,
    fetchThreatDetails,
    fetchRisksDetailsListView,
    fetchAttentionFlagsDetailsListView,
    fetchRiskAttentionFlagsDetails,
  } = anticipatoryIntelligenceStore

  useEffect(() => {
    const refinedDataset = hierarchy(data)
    const hierarchialData = d3.hierarchy(refinedDataset)
    const linkedData = bilink(hierarchialData)
    setRoot(treeCluster(linkedData))
  }, [data])

  const bilink = (root: any) => {
    const map = new Map(root.leaves().map((d: any) => [ids(d), d]))
    for (const d of root.leaves()) {
      d.incoming = []
      d.outgoing = d.data.imports.map((i: any) => [d, map.get(i)])
    }
    for (const d of root.leaves()) {
      for (const o of d.outgoing) {
        if (!o[1]) o[1] = []
        if (!o[1].incoming) o[1].incoming = []

        o[1].incoming.push(o)
      }
    }
    return root
  }

  const hierarchy = (data: ChordChartDataset[], delimiter = '.') => {
    let dataWithParent = makeParent(data)

    let root
    const map = new Map()
    dataWithParent?.forEach(function find(dataValue: any) {
      const { name } = dataValue
      if (map.has(name)) return map.get(name)
      const i = name.lastIndexOf(delimiter)
      map.set(name, dataValue)
      if (i >= 0) {
        find({ name: name.substring(0, i), children: [] }).children.push(dataValue)
        dataValue.name = name.substring(i + 1)
      } else root = dataValue
      return dataValue
    })
    return root
  }

  const ids = (node: any): any => {
    return `${node.parent ? ids(node.parent) + '.' : ''}${node.data.name}`
  }

  const makeParent = (data: ChordChartDataset[]) => {
    let newData: ChordChartDataset[] = []
    data.forEach((value) => {
      newData.push({ ...value, name: value.label || value.name })
    })
    return newData
  }

  const removeChordChartImports = () => {
    let newData: any = []
    data.forEach((value) => {
      newData.push({ ...value, imports: [] })
    })
    setData(newData)
  }

  const changeChart = (name: 'list' | 'chord' | '3d' | 'election') => {
    setChartName(name)
    setIsSideDetailsOpen('')
  }

  const onChangeDateFilter = () => {
    fetchThreatDetails()
    fetchRisksDetailsListView()
    fetchAttentionFlagsDetailsListView()
    fetchRiskAttentionFlagsDetails()
    removeChordChartImports()
  }

  return (
    <div>
      <div className='chord-graph__container'>
        <div className='chord-graph__header'>
          <div className='chord-graph__header__filters'>
            <div className='chord-graph__graph-div'>
              <div
                className={classNames({
                  'chord-graph__graph-div__active-chart': chartName === 'list',
                })}
                onClick={() => changeChart('list')}>
                <Tooltip title='List View' placement='bottom'>
                  <ListViewIcon aria-label='List View' />
                </Tooltip>
                {chartName === 'list' && (
                  <span
                    className={classNames('chord-graph__graph-div__title', {
                      'chord-graph__graph-div__title--active': chartName === 'list',
                    })}>
                    {'List View'}
                  </span>
                )}
              </div>
              <div
                className={classNames({
                  'chord-graph__graph-div__active-chart': chartName === 'chord',
                })}
                onClick={() => changeChart('chord')}>
                <Tooltip title='Chord Chart' placement='bottom'>
                  <ChordChartIcon aria-label='Chord Chart' />
                </Tooltip>
                {chartName === 'chord' && (
                  <span
                    className={classNames('chord-graph__graph-div__title', {
                      'chord-graph__graph-div__title--active': chartName === 'chord',
                    })}>
                    {'Chord Chart'}
                  </span>
                )}
              </div>
              <div
                className={classNames({
                  'chord-graph__graph-div__active-chart': chartName === '3d',
                })}
                onClick={() => changeChart('3d')}>
                <Tooltip title='3D Chart' placement='bottom'>
                  <ThreeDIcon aria-label='3D Chart' />
                </Tooltip>
                {chartName === '3d' && (
                  <span
                    className={classNames('chord-graph__graph-div__title', {
                      'chord-graph__graph-div__title--active': chartName === '3d',
                    })}>
                    3D Charts
                  </span>
                )}
              </div>
              <div
                className={classNames({
                  'chord-graph__graph-div__active-chart': chartName === 'election',
                })}
                onClick={() => changeChart('election')}>
                <Tooltip title='Election Chord Chart' placement='bottom'>
                  <ElectionChordChartIcon aria-label='Election Chord Chart' />
                </Tooltip>
                {chartName === 'election' && (
                  <span
                    className={classNames('chord-graph__graph-div__title', {
                      'chord-graph__graph-div__title--active': chartName === 'election',
                    })}>
                    {'Election Chord Chart'}
                  </span>
                )}
              </div>
            </div>
          </div>
          <DateFilter
            days={[1, 7, 30, 90, 180, 365, 730]}
            pageType={'list'}
            subStore={'anticipatoryIntelligence'}
            addCustomDateOption={false}
            onClick={onChangeDateFilter}
          />
        </div>
      </div>
      <ListPageSection
        left={<ChordGraph dataset={data} root={root} setDataset={setData} />}
        right={isSideDetailsOpen.length > 0 ? <DetailsSection /> : <></>}
        columns={{
          col1: {
            sm: isSideDetailsOpen.length > 0 ? 16 : 24,
            md: isSideDetailsOpen.length > 0 ? 16 : 24,
            lg: isSideDetailsOpen.length > 0 ? 16 : 24,
          },
          col2: {
            sm: isSideDetailsOpen.length > 0 ? 8 : 0,
            md: isSideDetailsOpen.length > 0 ? 8 : 0,
            lg: isSideDetailsOpen.length > 0 ? 8 : 0,
          },
        }}
      />
    </div>
  )
})
