import { ReactComponent as RecurringIcon } from 'assets/images/icons/recurring.svg'
import styles from './Recurring.module.scss'
import { Switch } from 'antd'
import classNames from 'classnames'

type RecurringProps = {
  value: boolean
  onChange: (val: boolean) => void
}

export default function Recurring(props: RecurringProps) {
  const { value, onChange } = props
  return (
    <div className={styles.reportListForm}>
      <div className={styles.recurring}>
        <div className={classNames(styles.centerItems, styles.recurringcontainer)}>
          <div className={styles.recurringheader}>
            <RecurringIcon />
            <p className={value ? styles.recurringheaderActive : styles.recurringheaderInactive}>Recurring</p>
          </div>
          <Switch
            checked={value}
            onClick={() => {
              onChange(!value)
            }}
          />
        </div>
      </div>
    </div>
  )
}
