import { Radio } from 'antd'
import './commonStyles.scss'

const RecurrenceCard = ({
  title = '',
  description = '',
  children,
  selected,
  onClick,
}: {
  children?: any
  title: string
  description: string
  selected: boolean
  onClick?: () => void
}) => {
  return (
    <div className={`recurrence-card ${selected && 'recurrence-card-selected'}`}>
      <div className='header'>
        <Radio checked={selected} onClick={onClick} />
        <div className='header__content'>
          <h6 className='title'>{title}</h6>
          <p className='description'>{description}</p>
        </div>
      </div>
      {children}
    </div>
  )
}

export default RecurrenceCard
