import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  boolean_query: string
  languages: string[]
}

export const getBooleanSearchTextTranslation = async (payload: Props) => {
  const url = `${process.env.REACT_APP_API}/${ROUTES.copilotTranslate}`
  const response = await axios.post(url, payload)

  return response.data
}
