import styles from './Sections.module.scss'
import { Input, Tooltip } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Subsection from './Subsection'
import classNames from 'classnames'
import { ReactComponent as InfoIcon } from 'assets/images/reports-tooltip.svg'

export default function Sections(props: any) {
  const handleCollapseChange = () => {}
  return (
    <div className={classNames(styles.sectionInner, { [styles.sectionInnerNotES]: !props.isSection })}>
      <div className={classNames(styles.sectionTitleContainer, { [styles.sectionTitleContainerES]: props.isSection })}>
        <label className={styles.sectionLabel}>Section title</label>
        <Input
          autoComplete='off'
          bordered={false}
          name='title'
          className={styles.sectionInput}
          placeholder='Enter section title'
          value={props.title}
          onChange={(e) => {
            props.sectionTitleOnChange(e)
          }}
        />
      </div>
      <Subsection
        editMode={props.editMode}
        entities={props.entities}
        handleCollapseChange={handleCollapseChange}
        isSection={props.isSection}
        entitiesOnChange={props.entitiesOnChange}
      />
      <div className={styles.summarizationContainer}>
        <div className={styles.inner}>
          <label className={styles.summarizationLabel}>Summarization instructions</label>
          <Tooltip
            placement='bottom'
            overlayClassName='pendulum-themed-tooltip'
            trigger={['click']}
            title={
              <p style={{ marginBottom: 'unset' }}>
                Prompts tell the generative Al tool what to focus on
                <br />
                summarizing for the content collections in this
                <br />
                section. The default prompt is to "summarize as if
                <br /> you are an intelligence analyst providing an
                <br />
                executive update". Type any other prompt here to
                <br /> focus the summary on your areas of interest, or in a<br />
                particular style. Example prompts include:
                <br />
                <br />
                <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                  <li>Summarize the top 10 key topics as bullet points.</li>
                  <li>
                    Summarize all mentions of X. Do not include
                    <br />
                    mentions of Y or Z.
                  </li>
                  <li>
                    You are a security analyst. Tell me about future
                    <br />
                    protests in Location A and list what companies
                    <br />
                    are mentioned.
                  </li>
                </ul>
              </p>
            }>
            <InfoIcon height={12} width={12} />
          </Tooltip>
        </div>
        <TextArea
          bordered={false}
          className={styles.summarizationTextArea}
          placeholder='Incorporate clear instructions for a focused report'
          value={props.summary_prompt}
          onChange={props.summarizationInstructionsOnChange}
        />
      </div>
    </div>
  )
}
