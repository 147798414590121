import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NarrativeListDataType } from 'types/types'
import { AssetListObjType } from 'store/asset/types'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import LoadingState from '../EmptyScreen/LoadingState'
import AreaChartSection from '../AreaChartSection/AreaChartSection'
import RiskFlags from '../RiskFlags/RiskFlags'
import ExtraInfoPopup from '../ExtraInfoPopup/ExtraInfoPopup'
import { ReactComponent as InfoIcon } from 'assets/images/icons/monitor/info_icon_24.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/images/icons/monitor/chevron_right_icon_20.svg'
import { SubStore } from 'types/types'

import './ExpandListChunk.scss'
import { IListItem } from 'models/models'

type ExpandListChunkType = {
  forAsset?: boolean
  subStore: SubStore
  isBookMarksListing?: boolean
  data: NarrativeListDataType | AssetListObjType | IListItem
}

const ExpandListChunk = (props: ExpandListChunkType) => {
  const navigate = useNavigate()
  const { data, forAsset, subStore, isBookMarksListing } = props

  const [lineChartLoading, setLineChartLoading] = useState<boolean>(true)
  const [riskFlagsLoading, setRiskFlagsLoading] = useState<boolean>(true)

  return (
    <div className='expand_list_chunk_container'>
      <div className='elcc_flags_container'>
        <div className='elcc_fc_header'>
          <div className='elcc_fc_h_left_container'>
            <p className='elcc_fc_h_lc_title'>{data.name}</p>
            <InfoIcon className='elcc_fc_h_lc_info_icon' />
            <ExtraInfoPopup data={data as NarrativeListDataType} />
          </div>
          <ButtonImage
            className='elcc_fc_h_lc_button'
            onClick={() => {
              let state: any = {
                name: data.name,
                conditions: data.conditions,
                mode: data.mode,
                id: data?.id,
              }
              if (!forAsset) {
                state['permission_level'] = (data as NarrativeListDataType).permission_level
              }

              navigate(`details`, { state: JSON.parse(JSON.stringify(state)) })
            }}>
            View more <ChevronRightIcon />
          </ButtonImage>
        </div>
        <div className='elcc_fc_inner_container'>
          <RiskFlags
            title='Risk Flags'
            forAsset={forAsset}
            isBookMarksListing={isBookMarksListing}
            data={data}
            setRiskFlagsLoading={setRiskFlagsLoading}
            subStore={subStore}
          />
          {forAsset ? null : (
            <RiskFlags
              className='elcc_fc_ic_assets_flag_container'
              title='Assets Flags'
              isAssetsFlags={true}
              isBookMarksListing={isBookMarksListing}
              data={data}
              setRiskFlagsLoading={setRiskFlagsLoading}
              subStore={subStore}
            />
          )}
        </div>
      </div>

      <AreaChartSection
        forAsset={forAsset}
        className='elcc_area_chart_container'
        data={data}
        isBookMarksListing={isBookMarksListing}
        setLineChartLoading={setLineChartLoading}
        subStore={subStore}
      />
      {lineChartLoading || riskFlagsLoading ? <LoadingState className=' elcc_loading_state' /> : null}
    </div>
  )
}

export default ExpandListChunk
