import { Checkbox } from 'antd'
import { PLATFORM_ICONS_GREY } from 'settings/settings'
import './styles.scss'

export default function PlatformDistribution({
  selected,
  setSelected,
}: {
  selected: string[]
  setSelected: (event: any, value: string) => void
}) {
  return (
    <div className='content-filter-map'>
      {PLATFORM_ICONS_GREY.map(({ title }) => (
        <div>
          <Checkbox
            checked={selected.includes(title)}
            onChange={(event) => setSelected(event, title)}
            className='content-filter-map__checkbox'>
            {title}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
