import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'
import { SnippetFilterPayload } from 'models/models'

interface Props extends SnippetFilterPayload {
  name?: string
}

export async function getTopicChartData({ page, per_page, query, sort, conditions, name }: Props) {
  try {
    const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`
    const queryParams = `q=${q}&sort=${sort}&per_page=${per_page}&page=${page}`
    const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.topicsChartData}?${queryParams}`

    const { data } = await axios.post(endpoint, { conditions })
    return data
  } catch (error) {
    LogService.error({ message: 'getTopicChartData error', error })
  }
}
