import { Modal } from 'antd'

import '../TenantModals.scss'
import './DeleteModal.scss'

interface Props {
  content: {
    title: string
    description?: string
  }
  isModalVisible: string
  setIsModalVisible: (state: string) => void
  onDelete: (id: string) => void
}

export const DeleteModal = ({ content, isModalVisible, setIsModalVisible, onDelete }: Props) => {
  const onDeleteClick = () => {
    let id = ''
    const info = isModalVisible.split(',')
    if (info.length > 1) id = info[1]
    else id = info[0]
    onDelete(id)
    setIsModalVisible('')
  }

  return (
    <Modal
      centered
      closable={false}
      width={387}
      open={isModalVisible.length > 0}
      bodyStyle={{ overflow: 'auto' }}
      className='tenant-modal delete-tenant-modal'
      title={<span className='delete-tenant-modal__title'>{content.title}</span>}
      footer={
        <div className='delete-tenant-modal__footer'>
          <button className='delete-tenant-modal__cancel-btn' onClick={() => setIsModalVisible('')}>
            Close
          </button>
          <button className='delete-tenant-modal__delete-btn' onClick={onDeleteClick}>
            Delete
          </button>
        </div>
      }>
      <div className='delete-tenant-modal'>
        <span className='delete-tenant-modal__description'>{content?.description}</span>
      </div>
    </Modal>
  )
}
