import React from 'react'
import classNames from 'classnames'
import styles from './DetailViewHistory.module.scss'

type DetailViewHistoryProps = {
  icon: React.ElementType
  title: string
  children?: React.ReactNode | string
  className?: string
  onClick?: () => void
}

function DetailViewHistory(props: DetailViewHistoryProps) {
  const { icon: Icon, title, children, className, onClick } = props
  return (
    <div
      className={classNames(styles.detail_view_history_container, className)}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}>
      <Icon className={styles.dvhc_header_icon} />
      <div className={styles.dvhc_header}>
        <p className={styles.dvhc_c_header_text}>{title}</p>
        {children}
      </div>
    </div>
  )
}

export default DetailViewHistory
