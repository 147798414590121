import { Outlet } from 'react-router-dom'
import { TopLevelNavigation } from 'components/common/TopLevelNavigation/TopLevelNavigation'

type OutletContainerProps = {
  pageTitle: string
}

function OutletContainer(props: OutletContainerProps) {
  const { pageTitle } = props
  return (
    <>
      <TopLevelNavigation page={pageTitle} />
      <Outlet />
    </>
  )
}

export default OutletContainer
