import { ReactComponent as CursorIcon } from 'assets/images/surprise-snippet-icon.svg'
import './SurpriseSnippet.scss'

interface Props {
  showSurprise: (val: boolean) => void
}

export const SurpriseSnippet = ({ showSurprise }: Props) => {
  return (
    <div
      onClick={() => {
        showSurprise(true)
      }}
      className='c-surprise-snippet'>
      <div className='c-surprise-snippet__container'>
        <div className='c-surprise-snippet__container--text'>
          <p className='c-surprise-snippet__container--title'>Time for a well deserved break</p>
          <p className='c-surprise-snippet__container--description'>Click for a surprise from Pendulum team!</p>
        </div>
        <div className='c-surprise-snippet__container--svg'>
          <div className='c-surprise-snippet__container__click-btn'>
            <CursorIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
