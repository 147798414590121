import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'
import { IWhiteboardCreate } from 'models/models'

export async function createWhiteboard(whiteboardData: IWhiteboardCreate) {
  try {
    const { data } = await axios({
      method: 'POST',
      url: ROUTES.whiteboard,
      baseURL: process.env.REACT_APP_API,
      data: whiteboardData,
    })

    return { data }
  } catch (error: any) {
    LogService.error({ message: 'createWhiteboard', error })
    return { data: null, error: error.response?.data }
  }
}

export async function createWhiteboardVersion(whiteboardId: string, whiteboardData: IWhiteboardCreate) {
  try {
    const { data } = await axios({
      method: 'POST',
      url: ROUTES.whiteboardById(whiteboardId),
      baseURL: process.env.REACT_APP_API,
      data: whiteboardData,
    })

    return { data }
  } catch (error: any) {
    LogService.error({ message: 'createWhiteboardVersion', error })
    return { data: null, error: error.response?.data }
  }
}
