import { Col, Row } from 'antd'
import StepsHeader from '../components/StepsHeader'
import StepsBanner from '../components/StepsBanner'
import { ReactComponent as SparkleIcon } from 'assets/images/icons/report/sparkles.svg'
import { ReactComponent as GlobeIcon } from 'assets/images/icons/report/globe.svg'
import { ReactComponent as QrCode } from 'assets/images/icons/report/qrcode.svg'
import { ReactComponent as SparkleColoredIcon } from 'assets/images/icons/report/sparkles-colored.svg'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { DefaultSelectedContentItems, SectionObjType } from '../CreateReport'
import { useState } from 'react'
import SimulatePrompt from '../components/SimulatePrompt/SimulatePrompt'
import { RecurrenceOptions } from './Recurrence'
import dayjs from 'dayjs'
import { ConditionsPayloadType } from 'types/types'
import './commonStyles.scss'

const DESCRIPTION =
  "We'll help you summarize your content through the process of condensing information into a shorter version while still capturing the main points. You'll be able to use prompts to guide the summarization process."

const summarizationOptions = [
  {
    icon: SparkleColoredIcon,
    title: 'Create executive summary',
    prompt: 'Summarize as if you are an intelligence analyst providing an executive update',
  },
  {
    icon: QrCode,
    title: 'Analyze unionization discourse',
    prompt: 'Summarize as if you are a corporate communications analyst providing an executive update.',
  },
  {
    icon: GlobeIcon,
    title: 'Gather conspiracy theories',
    prompt: 'Summarize as if you are a corporate communications executive looking the key topics and discussions',
  },
]

type Props = {
  editMode?: boolean
  section: SectionObjType
  updateSection: (sections: SectionObjType) => void
  recurrenceOptions: RecurrenceOptions
}

const TIME_FORMAT = 'YYYY-MM-DD'

const getQueryForSimulations = (filters: any, recurrenceOptions: RecurrenceOptions) => {
  let date = ''
  const endDate = dayjs().format(TIME_FORMAT)

  if (recurrenceOptions.recurrenceType === 'DAILY') {
    const startDate = dayjs(endDate).subtract(1, 'days').format(TIME_FORMAT)
    date = `start_date:gte:${startDate},end_date:lte:${endDate}`
  } else if (recurrenceOptions.recurrenceType === 'WEEKLY') {
    const startDate = dayjs(endDate).subtract(7, 'days').format(TIME_FORMAT)
    date = `start_date:gte:${startDate},end_date:lte:${endDate}`
  } else {
    date = `start_date:gte:${dayjs(recurrenceOptions.dateRange.startDate).format(TIME_FORMAT)},end_date:lte:${dayjs(
      recurrenceOptions.dateRange.endDate,
    ).format(TIME_FORMAT)}`
  }

  const source =
    filters.source.length > 0 ? `,snippet_source:in:${JSON.stringify(filters.source)}`.replaceAll('"', '') : ''
  const platform =
    filters.platform.length > 0 ? `,platform:in:${JSON.stringify(filters.platform)}`.replaceAll('"', '') : ''
  const sentiment =
    filters.sentiment.length > 0 ? `,sentiment:in:${JSON.stringify(filters.sentiment)}`.replaceAll('"', '') : ''
  const language = filters.language.length > 0 ? `,lang:in:${JSON.stringify(filters.language)}`.replaceAll('"', '') : ''

  return `${date}${source}${platform}${language}${sentiment}`
}

export const SummarizationForm = ({ editMode, section, updateSection, recurrenceOptions }: Props) => {
  const [openSimulatePrompt, setOpenSimulatePrompt] = useState(false)
  const onChangePrompt = (event: any) => {
    updateSection({ ...section, summary_prompt: event.target.value })
  }

  const getConditionsForSimulation = (
    mainContentFilters: DefaultSelectedContentItems,
    optionalContentFilters: DefaultSelectedContentItems,
  ) => {
    const conditions: ConditionsPayloadType[] = []

    Object.keys(mainContentFilters).forEach((type) => {
      mainContentFilters[type as keyof typeof mainContentFilters].forEach((item: any) => {
        if (type === 'bookmarks') {
          if (item.entity_info.length > 0) {
            conditions.push({
              document_ids: item?.entity_info?.flatMap((item: { entities: string[] }) => item.entities),
            })
          }
          return
        }
        if (item.conditions) conditions.push(item.conditions)
      })
    })

    Object.keys(optionalContentFilters).forEach((type) => {
      optionalContentFilters[type as keyof typeof optionalContentFilters].forEach((item: any) => {
        if (type === 'bookmarks') {
          if (item.entity_info.length > 0) {
            conditions.push({
              document_ids: item?.entity_info?.flatMap((item: { entities: string[] }) => item.entities),
            })
          }
          return
        }
        if (item.conditions) conditions.push(item.conditions)
      })
    })

    return conditions
  }

  const promptPayload = {
    prompt: section.summary_prompt,
    payload: {
      query: getQueryForSimulations(section.filters, recurrenceOptions),
      conditions: getConditionsForSimulation(section.mainItems, section.optionalItems),
    },
  }

  return (
    <>
      <StepsHeader title='Summarization' stepText={editMode ? 'Step 2 of 2' : 'Step 3'} description={DESCRIPTION} />
      <div className='summarization-samples-list'>
        {summarizationOptions.map(({ icon: Icon, title, prompt }) => (
          <div className='sample' onClick={() => updateSection({ ...section, summary_prompt: prompt })}>
            <div className='icon'>
              <Icon />
              <span>Sample</span>
            </div>
            <p className='title'>{title}</p>
          </div>
        ))}
      </div>
      <div className='prompt-option'>
        <textarea
          className='prompt-option__input'
          placeholder='Enter a prompt here...'
          value={section.summary_prompt}
          onChange={onChangePrompt}
        />
        <div className='prompt-action'>
          <ButtonImage onClick={() => setOpenSimulatePrompt(true)} className='advance-filter-btn'>
            Simulate prompt
          </ButtonImage>
        </div>
        {openSimulatePrompt && <SimulatePrompt onClose={() => setOpenSimulatePrompt(false)} payload={promptPayload} />}
      </div>
    </>
  )
}

const Summarization = (props: Props) => {
  return (
    <Row>
      <Col span={6}></Col>
      <Col span={12}>
        <SummarizationForm {...props} />
      </Col>
      <Col span={6}>
        <StepsBanner
          heading='Summarization'
          description="Choose or enter a prompt that reflects the main idea or key details you'd like to highlight. We’ll generate a concise summary based on your selected prompt."
          icon={SparkleIcon}
        />
      </Col>
    </Row>
  )
}

export default Summarization
