import { Route } from 'react-router-dom'
import { PrivateRoute } from 'components/Auth/PrivateRoute/PrivateRoute'
import DashboardMetrics from './DashboardMetrics/DashboardMatrics'
import { Creators } from './Monitor/PowerInsights/Creators/Creators'
import { MonitorBarChart } from './Monitor/PowerInsights/MonitorBarChart/MonitorBarChart'
import { MonitorHeatMap } from './Monitor/PowerInsights/MonitorHeatMap/MonitorHeatMap'
import { MonitorBeehiveChart } from './Monitor/PowerInsights/MonitorBeehiveChart/MonitorBeehiveChart'
import { MonitorMapChart } from './Monitor/PowerInsights/Map/MonitorMapChart'
import { ExecutiveLanguageRisks } from './Monitor/PowerInsights/ExecutiveLanguageRisks/ExecutiveLanguageRisks'
import { skipRoles } from 'utils/helper'
import { MonitorGraph } from './Monitor/PowerInsights/MonitorGraph/MonitorGraph'
import { BrandAssets } from './Asset/BrandsDashboard/components/PowerInsights/Assets/Assets'
import { MonitorFlags } from './Monitor/PowerInsights/MonitorFlags/MonitorFlags'

const powerInsightsCommonRoutes = ({
  subStore,
  loaderStore,
  tenantsStore,
}: {
  subStore: any
  loaderStore: any
  tenantsStore: any
}) => {
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const {
    statsPeopleLoading,
    statsCompanyLoading,
    statsLanguageLoading,
    isLoadingWatchlist,
    statsPoliticianLoading,
    isLoadingFeed,
    statsLineChartLoading,
    statsBeeSwarmLoading,
    statsTreeDataLoading,
  } = loaderStore

  return [
    <Route index element={<PrivateRoute allowedRoles={skipRoles([])} element={<DashboardMetrics />} />} />,
    <Route
      path='assets'
      element={<PrivateRoute allowedRoles={skipRoles([])} element={<BrandAssets subStore={subStore} />} />}
    />,
    <Route
      path='topics'
      element={<PrivateRoute allowedRoles={skipRoles([])} element={<MonitorFlags subStore={subStore} />} />}
    />,
    <Route
      path='trends'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={<MonitorGraph subStore={subStore} dataLoading={statsLineChartLoading || isLoadingFeed} />}
        />
      }
    />,
    <Route
      path='creators'
      element={<PrivateRoute allowedRoles={skipRoles([])} element={<Creators subStore={subStore} />} />}
    />,
    <Route
      path={`${changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)?.toLowerCase()}`}
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'watchlist'}
              subStore={subStore}
              indexName='watchlistData'
              dataLoading={isLoadingWatchlist || isLoadingFeed}
            />
          }
        />
      }
    />,
    <Route
      path={`${changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme)?.toLowerCase()}`}
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorHeatMap subStore={subStore} selectMode dataLoading={statsTreeDataLoading || isLoadingFeed} />
          }
        />
      }
    />,
    <Route
      path='creator_hive'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={<MonitorBeehiveChart subStore={subStore} dataLoading={statsBeeSwarmLoading || isLoadingFeed} />}
        />
      }
    />,
    <Route
      path={`${changeLabelTextBasedOnTheme('Map', isCorporateCommunicationsTheme)?.toLowerCase()}`}
      element={<PrivateRoute allowedRoles={skipRoles([])} element={<MonitorMapChart subStore={subStore} />} />}
    />,
    <Route
      path={`${changeLabelTextBasedOnTheme('Language Risk', isCorporateCommunicationsTheme)?.toLowerCase()}`}
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <ExecutiveLanguageRisks
              subStore={subStore}
              isPowerInsightsMode
              dataLoading={statsLineChartLoading || isLoadingFeed}
            />
          }
        />
      }
    />,
    <Route
      path='companies'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'company'}
              subStore={subStore}
              indexName='companyData'
              dataLoading={statsCompanyLoading || isLoadingFeed}
            />
          }
        />
      }
    />,
    <Route
      path='people'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'people'}
              subStore={subStore}
              indexName='peopleData'
              dataLoading={statsPeopleLoading || isLoadingFeed}
            />
          }
        />
      }
    />,
    <Route
      path='politician'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'politician'}
              subStore={subStore}
              indexName='politicianData'
              dataLoading={statsPoliticianLoading || isLoadingFeed}
            />
          }
        />
      }
    />,
    <Route
      path='languages'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'language'}
              subStore={subStore}
              indexName='languagesData'
              dataLoading={statsLanguageLoading || isLoadingFeed}
            />
          }
        />
      }
    />,
  ]
}

export default powerInsightsCommonRoutes
