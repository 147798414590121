import styles from './TagRender.module.scss'
import { Tag } from 'antd'
import React, { useState } from 'react'

export default function TagRender(props: any) {
  const [selectedColor, setSelectedColor] = useState('')
  const colors = props.name === 'tags' ? ['#E2EBFA', '#FEF4D3', '#F1E1E9', '#F5DFCC', '#E7F3E0'] : ['#E2EBFA']
  function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * colors.length)
    setSelectedColor(colors[randomIndex])
  }
  const { label, closable, onClose } = props
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }
  return (
    <Tag
      color={(selectedColor === '' && getRandomColor()) || selectedColor}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      className={styles.tagrender}>
      {label}
    </Tag>
  )
}
