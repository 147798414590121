import { Checkbox } from 'antd'
import { SUPPORTED_LANGUAGES } from 'settings/settings'
import './styles.scss'

export default function Language({
  selected,
  setSelected,
}: {
  selected: string[]
  setSelected: (event: any, value: string) => void
}) {
  return (
    <div className='content-filter-map'>
      {Object.keys(SUPPORTED_LANGUAGES).map((key) => (
        <div>
          <Checkbox
            checked={selected.includes(SUPPORTED_LANGUAGES[key])}
            onChange={(event) => setSelected(event, SUPPORTED_LANGUAGES[key])}
            className='content-filter-map__checkbox'>
            {key}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
