import React, { createContext, useContext, useState, ReactNode } from 'react'

type UserRoleContextProps = {
  userRole: string
  setUserRole: (role: string) => void
}

const UserRoleContext = createContext<UserRoleContextProps | undefined>(undefined)

export const UserRoleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userRole, setUserRole] = useState<string>('VIEWER')

  return <UserRoleContext.Provider value={{ userRole, setUserRole }}>{children}</UserRoleContext.Provider>
}

export const useUserRole = (): UserRoleContextProps => {
  const context = useContext(UserRoleContext)
  if (!context) {
    throw new Error('useUserRole must be used within a UserRoleProvider')
  }
  return context
}
