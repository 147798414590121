import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { store } from 'store'
import { useEffectOnce } from 'react-use'
import { Spin } from 'antd'

import { ReactComponent as CloseIcon } from 'assets/images/cross_black.svg'
import { ReactComponent as SummaryIcon } from 'assets/images/anticipatory-summary-icon.svg'
import { ReactComponent as LikeIcon } from 'assets/images/like-thumb-icon.svg'
import { ReactComponent as DislikeIcon } from 'assets/images/dislike-thumb-icon.svg'
import { ReactComponent as SolidLikeIcon } from 'assets/images/like-thumb-solid-icon.svg'
import { ReactComponent as SolidDislikeIcon } from 'assets/images/dislike-thumb-solid-icon.svg'

import './DetailsSection.scss'
import { containsHTML } from 'utils/helper'

export const DetailsSection = observer(() => {
  const navigate = useNavigate()

  const [isExecutiveSummaryTooLong, setIsExecutiveSummaryTooLong] = useState(false)
  const [showFullExecutiveSummary, setShowFullExecutiveSummary] = useState(true)
  const [isSummaryLiked, setIsSummaryLiked] = useState<'like' | 'dislike' | 'none'>('none')
  const [likeDislikeTransition, setLikeDislikeTransition] = useState('alse')

  const { anticipatoryIntelligenceStore, loaderStore, userStore } = store
  const {
    tenantInfo,

    activeThreatNarrative,
    activeThreatNarrativeAdvancedData,
    activeThreatNarrativeConditions,
    sideDetails,
    setUserTenant,
    setIsSideDetailsOpen,
    setSideDetails,
    setActiveThreatNarrative,
    fetchTenantInfo,

    fetchSummary,
  } = anticipatoryIntelligenceStore

  const {
    isLoadingElection,
    isLoadingElectionRaces,
    isLoadingThreatTopics,
    isLoadingRisks,
    isLoadingAttentionFlags,
    isLoadingNoOfPosts,
    isLoadingFeed,
    isLoadingStats,
    isLoadingSummary,
  } = loaderStore

  const { userInfo, getUserInfo } = userStore

  useEffectOnce(() => {
    if (!userInfo.tenant_id.length) getUserInfo()
  })

  useEffect(() => {
    setUserTenant(userInfo.tenant_id || '')
    if (userInfo && userInfo?.tenant_id && !tenantInfo?.name?.length) fetchTenantInfo(userInfo.tenant_id)
  }, [userInfo.tenant_id])

  useEffect(() => {
    if (activeThreatNarrativeConditions.length > 0) {
      fetchSummary()
    }
  }, [activeThreatNarrative, activeThreatNarrativeConditions])

  const onClickFeedView = (title: string) => {
    setIsSideDetailsOpen('')
    setSideDetails({
      topic: '',
      category: '',
      riskOrFlagName: '',
      tab: '',
      title: '',
    })
    const state = {
      name: title || '',
      conditions: activeThreatNarrative?.conditions,
      mode: '',
    }
    setActiveThreatNarrative({ ...activeThreatNarrative, conditions: activeThreatNarrative?.conditions })
    navigate('details', { state: JSON.parse(JSON.stringify(state)) })
  }

  const onClickClose = () => {
    setIsSideDetailsOpen('')
    setSideDetails({
      topic: '',
      category: '',
      riskOrFlagName: '',
      tab: '',
      title: '',
    })
  }

  const toggleFullExecutiveSummaryHandler = useCallback(() => {
    if (!isExecutiveSummaryTooLong) {
      return
    }

    setShowFullExecutiveSummary((prevShow) => !prevShow)
  }, [isExecutiveSummaryTooLong])

  useEffect(() => {
    if (activeThreatNarrativeAdvancedData?.summaryHints?.length > 500) {
      setIsExecutiveSummaryTooLong(true)
    } else {
      setIsExecutiveSummaryTooLong(false)
    }
  }, [activeThreatNarrativeAdvancedData?.summaryHints])

  const renderSummary = () => {
    const content =
      isExecutiveSummaryTooLong && !showFullExecutiveSummary
        ? activeThreatNarrativeAdvancedData.summaryHints.slice(0, 500) + '...'
        : activeThreatNarrativeAdvancedData.summaryHints

    return (
      <div className='summary-text'>
        {containsHTML(content) ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <ReactMarkdown>{content}</ReactMarkdown>
        )}
        {isExecutiveSummaryTooLong && (
          <span className='details-section__description--more-btn' onClick={toggleFullExecutiveSummaryHandler}>
            &nbsp;Read {showFullExecutiveSummary ? 'less' : 'more'}
          </span>
        )}
      </div>
    )
  }

  const thumbsUpFeedback = (valueForFeedback: 'like' | 'dislike') => {
    setIsSummaryLiked(valueForFeedback)
    setLikeDislikeTransition('details-section__summary__right__like-dislike--active')
    setTimeout(() => {
      setLikeDislikeTransition('')
    }, 500)
  }

  return (
    <Spin
      spinning={
        isLoadingElection ||
        isLoadingElectionRaces ||
        isLoadingThreatTopics ||
        isLoadingRisks ||
        isLoadingAttentionFlags ||
        isLoadingNoOfPosts ||
        isLoadingFeed ||
        isLoadingStats
      }>
      <div className={'details-section'}>
        <CloseIcon className='details-section__close-icon' onClick={onClickClose} />

        <div className='details-section__header-div'>
          <div className='details-section__title'>
            <span>{sideDetails.title.replace(',', ' ∞ ')}</span>
          </div>

          <button
            className='details-section__view-feed-btn'
            onClick={() => {
              onClickFeedView(sideDetails.title.replace(',', ' ∞ '))
            }}>
            View Feed
          </button>
        </div>
        {(activeThreatNarrativeAdvancedData.summaryHints?.length > 0 || isLoadingSummary) && (
          <>
            <div className='details-section__summary'>
              <div className='details-section__summary__title-div'>
                <span className='details-section__summary__title'>Executive Summary</span>
                <SummaryIcon className='details-section__summary__icon' />
              </div>
              <button className='details-section__summary__right__like-dislike-btn'>
                <div className={'details-section__summary__right__like-dislike'}>
                  {isSummaryLiked === 'like' ? (
                    <SolidLikeIcon
                      className={likeDislikeTransition}
                      onClick={() => {
                        thumbsUpFeedback('like')
                      }}
                    />
                  ) : (
                    <LikeIcon
                      className='ds_s_r_ld_icon'
                      onClick={() => {
                        thumbsUpFeedback('like')
                      }}
                    />
                  )}
                  {isSummaryLiked === 'dislike' ? (
                    <SolidDislikeIcon
                      className={likeDislikeTransition}
                      onClick={() => {
                        thumbsUpFeedback('dislike')
                      }}
                    />
                  ) : (
                    <DislikeIcon
                      className='ds_s_r_ld_icon'
                      onClick={() => {
                        thumbsUpFeedback('dislike')
                      }}
                    />
                  )}
                </div>
              </button>
            </div>
            {isLoadingSummary ? (
              <div className='details-section__loading'>
                <Spin spinning />
                <span className='details-section__loading__wait-text'>{'Please Wait ...'}</span>
                <span className='details-section__loading__description'>
                  {'Pendulum Co-Pilot is generating your summary.'}
                </span>
              </div>
            ) : (
              renderSummary()
            )}
          </>
        )}
      </div>
    </Spin>
  )
})
