import { Modal } from 'antd'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: (state: boolean) => void
}

export const SuccessModal = ({ isModalVisible, setIsModalVisible }: Props) => {
  return (
    <Modal
      centered
      closable
      width={387}
      open={isModalVisible}
      bodyStyle={{ overflow: 'auto' }}
      className='flags-modal'
      footer={
        <div>
          <button className='flags-modal__close-btn' onClick={() => setIsModalVisible(false)}>
            Close
          </button>
        </div>
      }>
      <div className='flags-modal'>
        <span className='flags-modal__title'> Request sent, thank you!</span>
        <span className='flags-modal__description'>We will contact you in less than 48 hours.</span>
      </div>
    </Modal>
  )
}
