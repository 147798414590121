const {
  REACT_APP_REGION: REGION,
  REACT_APP_USER_POOL_ID: USER_POOL_ID,
  REACT_APP_APP_CLIENT_ID: APP_CLIENT_ID,
  REACT_APP_IDENTITY_POOL_ID: IDENTITY_POOL_ID,
  REACT_APP_AUTH_DOMAIN: AUTH_DOMAIN,
  REACT_APP_REDIRECT_SIGNIN: REDIRECT_SIGNIN,
  REACT_APP_REDIRECT_SIGNOUT: REDIRECT_SIGNOUT,
} = process.env

export const cognitoConfig = {
  REGION,
  USER_POOL_ID,
  APP_CLIENT_ID,
  IDENTITY_POOL_ID,
  AUTH_DOMAIN,
  REDIRECT_SIGNIN,
  REDIRECT_SIGNOUT,
}
