import { Tooltip } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import Checkbox from 'components/common/Checkbox/Checkbox'
import { ReactComponent as CubeIcon } from 'assets/images/icons/monitor/cube.svg'
import { ConditionsDataType, ConditionsPayloadType, NarrativeListDataType } from 'types/types'
import './VectorsListChunk.scss'

type VectorsListChunkProps = {
  isActive: boolean
  highlighted?: boolean
  checkBoxHandler: () => void
  onClick: () => void
  data: NarrativeListDataType
}

const VectorsListChunk = observer((props: VectorsListChunkProps) => {
  const { vectorsStore } = store
  const { conditionsPayloadForAssets, selectedTab, fetchInsightsMetrics, getDate, snippetsFilter } = vectorsStore
  const { isActive, highlighted, checkBoxHandler, onClick, data } = props
  const [flagsCount, setFlagsCount] = useState<number>(0)

  const { isCorporateCommunicationsTheme } = store['tenantsStore']

  const renderConditions = useMemo(() => {
    if (conditionsPayloadForAssets && Object.keys(conditionsPayloadForAssets).length) {
      if (selectedTab === 'narratives') {
        return [conditionsPayloadForAssets, { narratives: [data.narrative_number] }]
      } else if (selectedTab === 'communities') {
        return [conditionsPayloadForAssets, { communities: [data.community_number] }]
      } else if (selectedTab === 'watchlists') {
        const uniqueChannelUrls = data.channels!.reduce((accumulator: string[], item) => {
          const channelUrls = item.channel_by_platform.map((channel) => channel.channel_url)
          return accumulator.concat(channelUrls.filter((url) => !accumulator.includes(url)))
        }, [])

        return [conditionsPayloadForAssets, { channel_urls: uniqueChannelUrls }]
      }
    }
    return []
  }, [selectedTab, data, conditionsPayloadForAssets])

  const insightsMetricsHandler = async () => {
    if (renderConditions.length) {
      const startDate = getDate.startDate
      const endDate = getDate.endDate

      const requestParams = {
        q: `start_date:gte:${startDate},end_date:lte:${endDate}`,
      }

      const requestData: ConditionsDataType = {
        conditions: renderConditions as ConditionsPayloadType[],
      }

      fetchInsightsMetrics({ params: requestParams, data: requestData }).then((count) => {
        setFlagsCount(count === 'error' ? 0 : count)
      })
      return
    }
    setFlagsCount(0)
  }

  useEffect(() => {
    insightsMetricsHandler()
  }, [renderConditions, snippetsFilter.days])

  return (
    <div
      className={`vectors_list_chunk_container ${isActive ? ' vectors_list_chunk_container_active' : ''}${
        highlighted ? ' vectors_list_chunk_container_highlighted' : ''
      }`}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}>
      <Tooltip title='Select' placement='bottom'>
        <Checkbox onClick={checkBoxHandler} checked={isActive} aria-label='Select' />
        <></>
      </Tooltip>
      <p className='vlcc_text'>{data.name}</p>
      {flagsCount ? (
        <div
          className='vlcc_cube_icon_container'
          data-tooltip={(isCorporateCommunicationsTheme ? 'Brands ' : 'Assets ') + 'mentioned'}>
          <CubeIcon className='vlcc_cube_icon' />
        </div>
      ) : null}
    </div>
  )
})

export default VectorsListChunk
