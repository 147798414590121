import { API, monitorAPI } from 'api/api'
import { makeObservable, observable, action } from 'mobx'

import { UtilService } from 'services/Util/Util'
import {
  CreateAndUpdateFolderDataType,
  FolderListResponseType,
  FolderMethod,
  FolderParamsType,
  MoveTopFolderDataType,
  NarrativeListDataType,
  NarrativeParamsType,
  NarrativesResponseType,
  PaginationValuesType,
  URLType,
} from 'types/types'
import { MainStore } from '../main/main.store'
import { ROUTES } from 'settings/settings'
import { uniqBy } from 'lodash'
import { displayApiError } from 'utils/helper'

export class VectorsStore extends MainStore {
  narrativesListData: NarrativesResponseType = {
    total_count: 0,
    items: [],
  }
  communitiesListData: NarrativesResponseType = {
    total_count: 0,
    items: [],
  }
  watchlistListData: NarrativesResponseType = {
    total_count: 0,
    items: [],
  }
  folderList: FolderListResponseType = {
    total_count: 0,
    items: [],
  }
  userFolderList: FolderListResponseType = {
    total_count: 0,
    items: [],
  }

  paginationValues: PaginationValuesType = {
    page: 1,
    pageSize: 10,
  }

  narrativeLoading: boolean = false
  watchListLoading: boolean = false
  folderListLoading: boolean = false
  graphColorPalette: { [x: string]: string[] } = {
    Executives: ['#2E125E', '#5720B7', '#875BF7', '#C3B5FD', '#ECE9FE'],
    Suppliers: ['#053321', '#085D3A', '#17B26A', '#75E0A7', '#DCFAE6'],
    Brands: ['#7A6336', '#A38448', '#CCA55A', '#E0C99C', '#F0E4CE'],
    Competitors: ['#932F19', '#E62E05', '#FF692E', '#FFE6D5', '#FF9C66'],
    Sentiment: ['#7A6336', '#A38448', '#E0C99C'],
  }

  constructor() {
    super()
    makeObservable(this, {
      narrativesListData: observable,
      communitiesListData: observable,
      watchlistListData: observable,
      folderList: observable,
      userFolderList: observable,
      paginationValues: observable,
      narrativeLoading: observable,
      folderListLoading: observable,
      watchListLoading: observable,
      setWatchListLoading: action.bound,
      setNarrativesListData: action.bound,
      setCommunitiesListData: action.bound,
      setWatchlistListData: action.bound,
      setFolderList: action.bound,
      setUserFolderList: action.bound,
      setPaginationValues: action.bound,
      setNarrativeLoading: action.bound,
      setFolderListLoading: action.bound,
    })
  }

  setNarrativesListData = (narrativesData: NarrativesResponseType) => {
    this.narrativesListData = narrativesData
  }

  setCommunitiesListData = (communitiesData: NarrativesResponseType) => {
    this.communitiesListData = communitiesData
  }

  setWatchlistListData = (watchlistData: NarrativesResponseType) => {
    this.watchlistListData = watchlistData
  }

  setFolderList = (folders: FolderListResponseType) => {
    this.folderList = folders
  }

  setUserFolderList = (folders: FolderListResponseType) => {
    this.userFolderList = folders
  }

  setPaginationValues = ({ page, pageSize }: PaginationValuesType) => {
    this.paginationValues = { ...this.paginationValues, page, pageSize }
  }

  setNarrativeLoading = (narrativeflag: boolean) => {
    this.narrativeLoading = narrativeflag
  }

  setWatchListLoading = (watchListFlag: boolean) => {
    this.watchListLoading = watchListFlag
  }

  setFolderListLoading = (flag: boolean) => {
    this.folderListLoading = flag
  }

  fetchNarratives = async ({
    params,
    isInfiniteScrolling = false,
  }: {
    params: NarrativeParamsType
    isInfiniteScrolling?: boolean
  }) => {
    this.setNarrativeLoading(true)
    try {
      const response = await monitorAPI.getMonitors({ url: ROUTES.narrative as URLType, params })
      let updatedResponse = response.data.items
      if (response.data?.items.length) {
        updatedResponse = response.data.items.map((narrative: NarrativeListDataType) => {
          return { ...narrative, mode: 'narrative', conditions: { narratives: [narrative.narrative_number] } }
        })
      }

      if (isInfiniteScrolling) {
        this.setNarrativesListData({
          total_count: response.data.total_count,
          items: uniqBy([...this.narrativesListData.items, ...updatedResponse], (item) => item.id),
        })
      } else {
        this.setNarrativesListData({ total_count: response.data.total_count, items: updatedResponse })
      }
      return { total_count: response.data.total_count, items: updatedResponse }
    } catch (error: any) {
      displayApiError(error)
      return 'error'
    } finally {
      this.setNarrativeLoading(false)
    }
  }

  fetchCommunities = async ({ params }: { params: NarrativeParamsType }) => {
    this.setNarrativeLoading(true)
    try {
      const response = await monitorAPI.getMonitors({ url: ROUTES.community as URLType, params })
      let updatedResponse = response.data.items
      if (response.data?.items.length) {
        updatedResponse = response.data.items.map((community: NarrativeListDataType) => {
          return { ...community, mode: 'community', conditions: { communities: [community.community_number] } }
        })
      }
      this.setCommunitiesListData({ total_count: response.data.total_count, items: updatedResponse })
      return { total_count: response.data.total_count, items: updatedResponse }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setNarrativeLoading(false)
    }
  }

  fetchWatchlist = async ({
    params,
    isInfiniteScrolling = false,
  }: {
    params: NarrativeParamsType
    isInfiniteScrolling?: boolean
  }) => {
    this.setNarrativeLoading(true)
    this.setWatchListLoading(true)
    try {
      const response = await monitorAPI.getMonitors({ url: ROUTES.reportWatchlist as URLType, params })
      let updatedResponse = response.data.items
      if (response.data?.items.length) {
        updatedResponse = response.data.items.map((watchlist: NarrativeListDataType) => {
          const uniqueChannelUrls = watchlist.channels!.reduce((accumulator: string[], item) => {
            const channelUrls = item.channel_by_platform.map((channel) => channel.channel_url)
            return accumulator.concat(channelUrls.filter((url) => !accumulator.includes(url)))
          }, [])
          return { ...watchlist, mode: 'watchlist', conditions: { channel_urls: uniqueChannelUrls } }
        })
      }
      if (isInfiniteScrolling) {
        this.setWatchlistListData({
          total_count: response.data.total_count,
          items: uniqBy([...this.watchlistListData.items, ...updatedResponse], (item) => item.id),
        })
      } else {
        this.setWatchlistListData({ total_count: response.data.total_count, items: updatedResponse })
      }
      return { total_count: response.data.total_count, items: updatedResponse }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setWatchListLoading(false)
      this.setNarrativeLoading(false)
    }
  }

  fetchFolderList = async ({ params }: { params?: FolderParamsType }) => {
    this.setFolderListLoading(true)
    try {
      const response = await monitorAPI.folderCRUD({ method: 'get', params })
      params?.vector_lib ? this.setFolderList(response.data) : this.setUserFolderList(response.data)
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    } finally {
      this.setFolderListLoading(false)
    }
  }

  CDUFolder = async ({
    method,
    id,
    data,
  }: {
    method: FolderMethod
    id?: string
    data?: CreateAndUpdateFolderDataType
  }) => {
    try {
      const response = await monitorAPI.folderCRUD({ method, id, data })
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  moveToFolder = async ({ id, data }: { id: string; data: MoveTopFolderDataType }) => {
    try {
      const response = await monitorAPI.moveToFolder({ id, data })
      return response.data
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  getSearchData = async (id: string) => {
    try {
      const { data: narrativeData } = await API.get({ route: 'narrative', id })
      return narrativeData
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }
}
