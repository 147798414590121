import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

interface Props {
  name: string
  description: string
  display_label: string
  insight_type: string
  insight_condition: string[]
  tenant_ids?: string[]
}

export async function editFlag(id: string, payload: Props) {
  try {
    let url = `${process.env.REACT_APP_API}${ROUTES.attentionFlags}${id}`
    const { data } = await axios.put(url, payload)
    return data
  } catch (error) {
    LogService.error({ message: 'getScorecardsAttentionFlags error', error })
  }
}
