import { ReactComponent as WaitingClockIcon } from 'assets/images/icons/flag/waiting_clock.svg'
import { ReactComponent as EmptySearchIcon } from 'assets/images/icons/flag/empty_search.svg'

import Styles from './NoDataMessages.module.scss'

const PowerInsightsNoDataMessage = () => {
  return (
    <div className={Styles.insightContainer}>
      <WaitingClockIcon />
      <div className={Styles.heading}> Please wait while we are adding the data</div>
      <div className={Styles.description}>
        The creator links you are adding are not currently in the Pendulum platform. When you click{' '}
        <span className={Styles.info}>Save Watchlist</span> we will add them to our data collection and you will see
        data overnight.
      </div>
    </div>
  )
}

const FeedsNoDataMessage = () => {
  return (
    <div className={Styles.snippetsContainer}>
      <EmptySearchIcon />
      <div className={Styles.message}>
        <div className={Styles.heading}>No results</div>
        <div className={Styles.description}>Please wait while we are adding the data</div>
      </div>
    </div>
  )
}

export { PowerInsightsNoDataMessage, FeedsNoDataMessage }
