import axios from 'axios'
import { SnippetFilterPayload } from 'models/models'
import { ROUTES } from 'settings/settings'

export const getPowerInsightsSummaryStatistics = async ({ query, sort, conditions }: SnippetFilterPayload) => {
  try {
    const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`
    const queryParams = `q=${q}&sort=${sort}`

    const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.powerInsightsSummary}?${queryParams}`

    const data = await axios.post(endpoint, { conditions })
    return data?.data
  } catch (error) {
    console.log('error in getPowerInsightsSummaryStatistics:', error)
  }
}
