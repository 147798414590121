import { Checkbox } from 'antd'
import './styles.scss'

const SENTIMENT_FILTERS = [
  { key: 'positive', value: 'Positive' },
  { key: 'negative', value: 'Negative' },
  { key: 'neutral', value: 'Neutral' },
]

export default function Sentiment({
  selected,
  setSelected,
}: {
  selected: string[]
  setSelected: (event: any, value: string) => void
}) {
  return (
    <div className='content-filter-map'>
      {SENTIMENT_FILTERS.map((sentiment) => (
        <div key={sentiment.key}>
          <Checkbox
            checked={selected.includes(sentiment.value)}
            onChange={(event) => setSelected(event, sentiment.value)}
            className='content-filter-map__checkbox'>
            {sentiment.value}
          </Checkbox>
        </div>
      ))}
    </div>
  )
}
