import { UtilService } from './Util'
import { sourcePlatform } from 'models/models'
import { ReactComponent as IconPlayVideo } from 'assets/images/play-video.svg'
import RumbleLogo from 'assets/images/rumble-full-logo.svg'
import PodcastsLogo from 'assets/images/microphone-2.svg'

interface Props {
  videoId: string
  platform: sourcePlatform
  mediaUrl?: string | null
}

export const getVideoFrame = ({ videoId, platform, mediaUrl }: Props) => {
  const videoSrc = UtilService.getVideoURL({ platform: platform, videoId })

  switch (platform) {
    case 'YouTube':
      return (
        <iframe
          src={videoSrc}
          title='YouTube video player'
          style={{ border: 0 }}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      )
    case 'BitChute':
      return (
        <iframe
          src={videoSrc}
          title='Bitchute video player'
          style={{ border: 0 }}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      )
    case 'Rumble':
      return (
        <>
          <div
            onClick={() => {
              window.open(
                platform === 'Rumble'
                  ? `https://rumble.com/${videoId}`
                  : platform === 'Podcast'
                  ? mediaUrl !== null
                    ? mediaUrl
                    : ''
                  : '',
                '_blank',
              )
            }}>
            <img src={RumbleLogo} alt='Rumble link'></img>
            <IconPlayVideo />
          </div>
        </>
      )
    case 'Podcast':
    default:
      return (
        <div
          onClick={() => {
            window.open(mediaUrl ?? '', `_blank`)
          }}>
          <img src={PodcastsLogo} alt='Podcast link'></img>
        </div>
      )
  }
}
