import { useState, useEffect } from 'react'

// Define a type for the value that will be debounced
type DebouncedValue<T> = T | undefined

function useDebounce<T>(value: T, delay: number = 500): DebouncedValue<T> {
  // State to keep track of the debounced value
  const [debouncedValue, setDebouncedValue] = useState<DebouncedValue<T>>(value)

  useEffect(() => {
    // Set a timeout to update the debounced value after the specified delay
    const debounceTimer = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Clear the timeout if the value changes before the delay period
    return () => {
      clearTimeout(debounceTimer)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
