import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input, Modal, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { observer } from 'mobx-react-lite'
import { useEffectOnce } from 'react-use'
import classNames from 'classnames'

import type { InputRef } from 'antd'

import { ReactComponent as CancelIcon } from 'assets/images/icons/outline/x.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/images/icons/outline/check.svg'
import { ReactComponent as DropdownArrowIcon } from 'assets/images/executive-intelligence/icons/add-person/dropdown-arrow.svg'
import { ReactComponent as SolidCheckmarkDark } from 'assets/images/icons/solid-checkmark-dark.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import { ReactComponent as BookmarkIcon } from 'assets/images/icons/bookmark.svg'
import successGif from 'assets/gifs/done.gif'

import { ISnippet } from 'models/models'
import { bookmarksAPI } from 'api/api'
import { store } from 'store'

import styles from './AddSnippetToBookmarksListModal.module.scss'
import TagManager from 'react-gtm-module'
import { convertMobXProxiesToJSObject } from 'utils/helper'

const ModalTitle = () => {
  return (
    <div className={styles.platformTitleWrapper}>
      <span className={styles.icon}>
        <BookmarkIcon />
      </span>
      <span className={styles.titleText}>Bookmark</span>
    </div>
  )
}

interface BookmarksListSelectComponentProps {
  snippets: ISnippet[]
  closeModalHandler: () => void
  onSuccessHandler: () => void
}

const BookmarksListSelectComponent: React.FC<BookmarksListSelectComponentProps> = observer(
  ({ snippets, closeModalHandler, onSuccessHandler }) => {
    // global store data
    const { userId, userName, roleId, tenantId } = store.userStore
    const { isLoadingBookmarks } = store.loaderStore
    const { userBookmarkedSnippets, bookmarksData, fetchAllBookmarksForFeed, fetchUserBookmarkedSnippets } =
      store.bookmarkStore

    // refs
    const createBookmarkInputRef = useRef<InputRef>(null)

    // local component state
    const [bookmarkName, setBookmarkName] = useState('')
    const [showCreateBookmarkForm, setShowCreateBookmarkForm] = useState(false)
    const [selectedBookmarkLists, setSelectedBookmarkLists] = useState<string[]>([])
    const [isBookmarkListsSelected, setIsBookmarkListsSelected] = useState(false)

    // event handler callbacks
    const filterSelectOptions = useCallback((inputValue: string, option: DefaultOptionType | undefined) => {
      return option?.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
    }, [])

    const closeCreateBookmarkFormHandler = useCallback(() => {
      setShowCreateBookmarkForm(false)
      setBookmarkName('')
    }, [])

    const createNewBookmarkHandler = useCallback(async () => {
      if (bookmarkName.length === 0) {
        return
      }

      const bookmarkCreated = await bookmarksAPI.createBookmark({
        bookmarkData: {
          name: bookmarkName,
        },
      })

      // bookmark is created. refresh the list of bookmarks now
      await fetchAllBookmarksForFeed()
      closeCreateBookmarkFormHandler()

      // also, add the newly created bookmark to the list of selected bookmark lists
      setSelectedBookmarkLists((prevSelectedBookmarkLists) => [...prevSelectedBookmarkLists, bookmarkCreated.id])
    }, [bookmarkName])

    const selectBookmarkListsChangedHandler = useCallback((selectedBookmarks: string[]) => {
      setSelectedBookmarkLists(selectedBookmarks)
    }, [])

    const saveSnippetToBookmarkLists = useCallback(async () => {
      if (!isBookmarkListsSelected) {
        return
      }
      console.log(convertMobXProxiesToJSObject(userBookmarkedSnippets))

      const filteredSnippets = snippets.filter((snippet) => !userBookmarkedSnippets.includes(snippet.documentId || ''))

      filteredSnippets?.forEach((snippet) => {
        bookmarksAPI.addSnippetToBookmark({
          payload: {
            entity_id: snippet.documentId!,
            entity_type: 'SNIPPET',
            bookmark_ids: selectedBookmarkLists,
          },
        })

        // google analytics add snippet to bookmark
        TagManager.dataLayer({
          dataLayer: {
            event: 'add_snippet_to_bookmark',
            entityId: selectedBookmarkLists,
            snippetId: snippet.documentId!,
            user_id: userId,
            user_name: userName,
            roleId: roleId,
            tenantId: tenantId,
          },
        })
      })

      // bookmark is either created or updated successfully. show the success modal now
      onSuccessHandler()
    }, [isBookmarkListsSelected, selectedBookmarkLists])

    // side effects
    useEffectOnce(() => {
      fetchAllBookmarksForFeed()
      fetchUserBookmarkedSnippets()
    })

    useEffect(() => {
      setIsBookmarkListsSelected(selectedBookmarkLists.length > 0)
    }, [selectedBookmarkLists])

    useEffect(() => {
      if (showCreateBookmarkForm) {
        createBookmarkInputRef.current?.focus()
      }
    }, [showCreateBookmarkForm])

    console.log('bookmarksData', convertMobXProxiesToJSObject(bookmarksData))

    // Render JSX
    return (
      <div className={styles.formModalBody}>
        <div className={styles.bodyHeader}>
          <p className={styles.description}>Save the snippet in a single or multiple bookmark lists of your choice.</p>
        </div>

        <div className={styles.selectBookmarksWrapper}>
          <h4 className={styles.labelText}>
            Select bookmark lists{' '}
            {selectedBookmarkLists.length > 0 && (
              <span className={styles.selectedListsCount}>{selectedBookmarkLists.length}</span>
            )}
          </h4>

          {showCreateBookmarkForm ? (
            <div className={styles.createBookmarkInputWrapper}>
              <Input.Group compact>
                <Input
                  ref={createBookmarkInputRef}
                  bordered={false}
                  className={styles.input}
                  style={{ width: 'calc(100% - 48px)' }}
                  value={bookmarkName}
                  onChange={(event) => setBookmarkName(event.target.value)}
                  placeholder='List name'
                />

                <Button
                  icon={<CancelIcon />}
                  className={styles.actionBtnIcon}
                  onClick={closeCreateBookmarkFormHandler}
                />

                <Button
                  icon={<CheckmarkIcon />}
                  className={styles.actionBtnIcon}
                  disabled={bookmarkName.length === 0}
                  loading={isLoadingBookmarks}
                  onClick={createNewBookmarkHandler}
                />
              </Input.Group>
            </div>
          ) : (
            <Select
              showArrow
              mode='multiple'
              loading={isLoadingBookmarks}
              className={styles.selectDropdown}
              filterOption={filterSelectOptions}
              menuItemSelectedIcon={<SolidCheckmarkDark />}
              suffixIcon={<DropdownArrowIcon />}
              value={selectedBookmarkLists}
              onChange={selectBookmarkListsChangedHandler}
              dropdownRender={(menu) => (
                <>
                  <div>
                    <Button
                      type='text'
                      className={styles.createListBtn}
                      onClick={() => setShowCreateBookmarkForm(true)}>
                      + Create new list
                    </Button>
                  </div>
                  {menu}
                </>
              )}
              placeholder='Choose any existing lists or create new'>
              {bookmarksData.items?.map((bookmarkList) => (
                <Select.Option key={bookmarkList.id} value={bookmarkList.id} label={bookmarkList.name}>
                  {bookmarkList.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>

        <div className={styles.modalFooter}>
          <Button className={styles.cancelButton} onClick={closeModalHandler} disabled={isLoadingBookmarks}>
            Cancel
          </Button>

          <Button
            className={styles.createButton}
            onClick={saveSnippetToBookmarkLists}
            disabled={showCreateBookmarkForm || !isBookmarkListsSelected}
            loading={isLoadingBookmarks}>
            Save
          </Button>
        </div>
      </div>
    )
  },
)

const SuccessBodyComponent = () => {
  return (
    <div className={styles.successModalBody}>
      <div className={styles.gif}>
        <img src={successGif} alt='success gif' />
      </div>

      <div className={styles.mainContent}>
        <div className={styles.message}>Snippet bookmarked successfully</div>
        <div className={styles.subMessage}>Explore your collection of saved bookmarks</div>
      </div>

      <div className={styles.openBookmarksLink}>
        <Link to='/investigate/bookmarks' className={styles.link}>
          Open Bookmark list
        </Link>
      </div>
    </div>
  )
}

interface AddSnippetToBookmarksListModalProps {
  snippets: ISnippet[]
  isModalVisible: boolean
  closeModal: (isBookmarkCreatedOrUpdated?: boolean) => void
}

export const AddSnippetToBookmarksListModal: React.FC<AddSnippetToBookmarksListModalProps> = ({
  snippets,
  isModalVisible,
  closeModal,
}) => {
  const [activeModal, setActiveModal] = useState<'Delete' | 'Success'>('Delete')

  if (activeModal === 'Success') {
    return (
      <Modal
        centered
        width={387}
        closable={false}
        zIndex={9999}
        open={isModalVisible}
        onCancel={() => {
          closeModal(true)
          setActiveModal('Delete')
        }}
        destroyOnClose
        wrapClassName={styles.addSnippetToBookmarksListModalWrapper}
        footer={
          <div className={classNames(styles.modalFooter, styles.successModalFooter)}>
            <Button
              className={styles.doneButton}
              onClick={() => {
                closeModal(true)
                setActiveModal('Delete')
              }}>
              Close
            </Button>
          </div>
        }>
        <SuccessBodyComponent />
      </Modal>
    )
  }

  return (
    <Modal
      centered
      open={isModalVisible}
      zIndex={9999}
      onCancel={() => closeModal(false)}
      title={<ModalTitle />}
      closeIcon={<CloseIcon />}
      wrapClassName={styles.addSnippetToBookmarksListModalWrapper}
      footer={null}
      destroyOnClose>
      <BookmarksListSelectComponent
        snippets={snippets}
        closeModalHandler={() => closeModal(false)}
        onSuccessHandler={() => setActiveModal('Success')}
      />
    </Modal>
  )
}
