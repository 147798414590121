import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Form, Input, Spin, Alert } from 'antd'
import { Link } from 'react-router-dom'

import { Button } from 'components/common/Button/Button'
import { Card } from 'components/common/Card/Card'
import { Heading } from 'components/common/Heading/Heading'

import { getAgreementStatus } from 'api/agreement/getAgreementStatus.api'

import { ReactComponent as IconPendulumLogo } from 'assets/images/pendulum.svg'
//import { AmplifyAuthenticator } from '@aws-amplify/ui-react'
import './SignIn.scss'
import '@aws-amplify/ui-react/styles.css'

export function SignIn(): JSX.Element {
  const [form] = Form.useForm()
  const [showNewPasswordField, setShowNewPasswordField] = useState<boolean>(false)
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<{ message: string; name: string; code: string } | null>(null)

  async function onFinish(values: any) {
    const { userEmail, password, newpassword }: { userEmail: string; password: string; newpassword: string } = values

    try {
      let isNewUser = false
      setLoading(true)
      const user = await Auth.signIn(userEmail, password)

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setLoading(false)
        setShowNewPasswordField(true)
        isNewUser = true
      }

      if (newpassword) {
        setLoading(true)
        await Auth.completeNewPassword(user, newpassword)
        isNewUser = false
      }
      if (!isNewUser) {
        const response = await getAgreementStatus()
        if (response) {
          if (!response?.require_tos) localStorage.setItem('user-tos-status', 'verified')
          else {
            localStorage.setItem('user-tos-status', 'non-verified')
            localStorage.setItem(
              'user-tos-assigned-admins',
              JSON.stringify({ admins: response?.assigned_admins || [] }),
            )
          }
        }
      }
    } catch (error: any) {
      setLoading(false)
      setError(error)
    }
  }

  const checkEmailAndProceed = async () => {
    try {
      setError(null)
      const check = await form.validateFields()

      if (Object.values(check || {})?.length > 0) {
        const email: string = form.getFieldValue('userEmail') || ''
        if (process.env.REACT_APP_IDP_EMAIL_DOMAIN && email.endsWith(process.env.REACT_APP_IDP_EMAIL_DOMAIN)) {
          window.location.href = process.env.REACT_APP_IDP_URL || ''
        } else {
          setShowPasswordField(true)
        }
      }
    } catch (error: any) {
      setError({ message: 'Please input your email!', name: 'string', code: '400' })
    }
  }

  const onEmailChange = () => {
    setError(null)
    setShowPasswordField(false)
    setShowNewPasswordField(false)
    form.setFieldValue('password', '')
  }

  useEffect(() => {
    document.title = 'Login'
  }, [])

  return (
    <div className='c-sign-in' data-testid='sign-in'>
      {/* HACK
      Added this because we need it for cypress testing. See if there is a way to extract the required functionality from AmplifyAuthenticator to log in from cypress, and the app would recognize the login.
      */}
      {/* <div style={{ display: 'none' }}>
        <AmplifyAuthenticator />
      </div> */}

      <Card variant='2'>
        <Spin size='large' spinning={loading}>
          <IconPendulumLogo className='c-sign-in__logo' />

          <Form form={form} layout='vertical' onFinish={showPasswordField ? onFinish : checkEmailAndProceed}>
            <Heading level='1' variant='2'>
              Access your account
            </Heading>
            {error && <Alert message={error.message} type='error' />}
            <Form.Item label='Email' name='userEmail' rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input placeholder='name@mail.com' type='email' onChange={onEmailChange} />
            </Form.Item>

            {showNewPasswordField && (
              <Form.Item
                label='New Password'
                name='newpassword'
                validateStatus={showNewPasswordField ? 'warning' : ''}
                help='Please enter your new password'>
                <Input.Password placeholder='Strong password' />
              </Form.Item>
            )}
            {showPasswordField && (
              <>
                <Form.Item
                  label='Password'
                  name='password'
                  rules={[{ required: true, message: 'Please input your password!' }]}>
                  <Input.Password />
                </Form.Item>
                <Button htmlType='submit' type='secondary'>
                  Sign In
                </Button>
              </>
            )}
            {/* <Button
              htmlType='submit'
              type='secondary'
              onClick={() => {
                window.location.href =
                  'https://auth.dev.pendulumintel.com/oauth2/authorize?identity_provider=AmazonIdentityProvider&redirect_uri=https://dev.pendulumintel.com&response_type=CODE&client_id=5vgk115r7q8qgprifvtrut66p3&scope=aws.cognito.signin.user.admin%20email%20openid%20profile'
              }}> */}

            {!showPasswordField && (
              <Button
                type='secondary'
                onClick={() => {
                  checkEmailAndProceed()
                }}>
                Continue
              </Button>
            )}
            <p className='c-sign-in__reset'>
              Don't remember your password? <Link to='/reset'>Reset password</Link>
            </p>
          </Form>
        </Spin>
      </Card>
    </div>
  )
}
