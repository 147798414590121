import React from 'react'
import { Button } from 'antd'
import styles from './ModalFooter.module.scss'

type ModalFooterProps = {
  isDelete: boolean
  onCancel: () => void
  onClick: () => void
  disabled: boolean
  buttonText: string
}

export default function ModalFooter(props: ModalFooterProps) {
  const { isDelete, onCancel, onClick, disabled, buttonText } = props
  return (
    <div className={styles.modalFooter}>
      <Button className={styles.cancelButton} onClick={onCancel}>
        Cancel
      </Button>

      <Button
        className={isDelete ? styles.deleteButton : styles.createButton}
        onClick={onClick}
        disabled={isDelete ? false : disabled}>
        {buttonText}
      </Button>
    </div>
  )
}
