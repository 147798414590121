import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

export async function getUserBookmarkedSnippets() {
  try {
    const endpoint = `${process.env.REACT_APP_API}/${ROUTES.bookmark}/snippets`
    const { data } = await axios.get(endpoint)

    return data
  } catch (error) {
    LogService.error({ message: 'getUserBookmarkedSnippets error', error })
    throw error
  }
}
