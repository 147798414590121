import axios from 'axios'
import { ITenant } from 'models/models'

import { ROUTES } from 'settings/settings'

export const editTenant = async (id: string, payload: ITenant) => {
  let url = `${process.env.REACT_APP_API}${ROUTES.tenant}/${id}`
  const { data } = await axios.put(url, payload)
  return data
}
