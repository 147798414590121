import axios from 'axios'
import { SnippetFilterPayload, monitorMode } from 'models/models'
import { ROUTES } from 'settings/settings'

interface Props extends SnippetFilterPayload {
  name: string
  entity_id: string
  entity_type: monitorMode
  sort_by: string
  order_by: string
  filters: {
    platform: string[]
    sentiment: string[]
    risk_score: string
  }
  total_snippets: number
  start_date: string
  end_date: string
}

export const exportFeed = async ({ page, per_page = 50, query, sort, conditions, ...restParams }: Props) => {
  const { name, entity_id, entity_type, sort_by, order_by, filters, total_snippets, start_date, end_date } = restParams
  const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`

  const query_params = `q=${q}&sort=${sort}&per_page=${per_page}&page=${page}`

  const endpoint = `${process.env.REACT_APP_API}${ROUTES.feedExport}`
  const payload = {
    name,
    entity_id,
    entity_type: entity_type.toUpperCase(),
    sort_by,
    order_by,
    filters,
    total_snippets,
    query_params,
    query_payload: { conditions },
    start_date,
    end_date,
  }
  return axios.post(endpoint, payload)
}
