import classNames from 'classnames'
import './MediaTypeSelector.scss'

export default function MediaTypeSelector() {
  return (
    <div className='media-wrapper'>
      <div
        className={classNames('media-type', {
          'media-selected': true,
        })}>
        Earned Media
      </div>
      <div
        className={classNames('media-type', {
          'media-selected': false,
          'media-disabled': true,
        })}>
        Owned Media
      </div>
    </div>
  )
}
