import { useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { AreaChart, Area, ResponsiveContainer, Legend } from 'recharts'
import { UtilService } from 'services/Util/Util'
import { IListItem, sourcePlatform } from 'models/models'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { MetricType, NarrativeListDataType, PivotType, SentimentKey } from 'types/types'
import { AssetListObjType } from 'store/asset/types'
import { ReactComponent as InfoIcon } from 'assets/images/info-icon.svg'
import { ReactComponent as PostsIcon } from 'assets/images/icons/power-insights/trends-timeline/metric/posts.svg'
import { ReactComponent as ImpressionsIcon } from 'assets/images/icons/power-insights/trends-timeline/metric/impressions.svg'
import { ReactComponent as AllIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/all.svg'
import { ReactComponent as SentimentIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/sentiment.svg'
import { ReactComponent as PlatformIcon } from 'assets/images/icons/power-insights/trends-timeline/pivot/platform.svg'
import { SubStore } from 'types/types'
import './AreaChartSection.scss'

type ExpandListChunkType = {
  data: NarrativeListDataType | AssetListObjType | IListItem
  className?: string
  setLineChartLoading?: (val: boolean) => void
  forAsset?: boolean
  isBookMarksListing?: boolean
  subStore: SubStore
}

type PayloadType = {
  color: string
  dataKey: string
  type: string
  value: string
}

type CustomLegendType = {
  align?: string
  payload?: PayloadType[]
  activePivot?: PivotType
}

const sentimentsNames = {
  Positive: 'Positive',
  Negative: 'Negative',
  Neutral: 'Neutral',
}
const sentimentsColors = {
  Positive: '#18B368',
  Negative: '#DE4E4E',
  Neutral: '#18A0FB',
}

const CustomLegend = (props: CustomLegendType) => {
  const { payload, activePivot } = props
  return (
    <ul className='acsc_custom_legend_container'>
      {payload!.map((entry) => {
        if (activePivot === 'all') {
          return (
            <li key={entry.dataKey} className='acsc_clc_li'>
              <div className='acsc_clc_l_chunk'>
                <div className='acsc_clc_lc_bar' style={{ backgroundColor: entry.color }}></div>
                <p className='acsc_clc_lc_text'>
                  {entry.dataKey === 'amt'
                    ? 'Selected posts'
                    : entry.dataKey === 'metric_value'
                    ? 'All Post'
                    : 'Risk posts'}
                </p>
                <InfoIcon className='acsc_clc_lc_icon' height={12} width={12} />
              </div>
            </li>
          )
        } else if (activePivot === 'sentiment' || activePivot === 'platform') {
          let legendLabel = entry.dataKey
          if (activePivot === 'platform') {
            legendLabel = UtilService.getPlatformBrandName(entry.dataKey as Lowercase<sourcePlatform>)
          }

          return (
            <li key={entry.dataKey} className='acsc_clc_li'>
              <div className='acsc_clc_l_chunk'>
                <div className='acsc_clc_lc_bar' style={{ backgroundColor: entry.color }}></div>
                <p className='acsc_clc_lc_text'>{legendLabel}</p>
              </div>
            </li>
          )
        }
        return null
      })}
    </ul>
  )
}

// const CustomTooltip = ({ active, payload, label }: any) => {
//   if (active && payload && payload.length) {
//     return (
//       <div className='custom-tooltip'>
//         <p className='label'>{`${label} : ${payload[0].value}`}</p>
//         <div>
//           {payload.map((pld: any) => (
//             <div style={{ display: 'inline-block', padding: 10 }}>
//               <div style={{ color: pld.fill }}>{pld.value}</div>
//               <div>{pld.dataKey}</div>
//             </div>
//           ))}
//         </div>
//       </div>
//     )
//   }

//   return null
// }

const AreaChartSection = observer((props: ExpandListChunkType) => {
  const { data, className, setLineChartLoading, forAsset, subStore, isBookMarksListing = false } = props

  const { fetchLineChartStats, selectedTab, getDate, snippetsFilter } = store[`${subStore}Store`]

  const [activeMetric, setActiveMetric] = useState<MetricType>('posts')
  const [activePivot, setActivePivot] = useState<PivotType>('all')
  const [lineChartData, setLineChartData] = useState({
    posts: { all: [], sentiment: [], platform: [] },
    impressions: { all: [], sentiment: [], platform: [] },
  })

  const renderConditions = useMemo(() => {
    if (forAsset) {
      return [(data as AssetListObjType).conditions]
    }
    if (isBookMarksListing) {
      return [{ document_ids: (data as IListItem)?.entity_info }]
    }
    if (selectedTab === 'narratives' && data.mode === 'narrative') {
      return [{ narratives: [(data as NarrativeListDataType).narrative_number] }]
    } else if (selectedTab === 'communities' && data.mode === 'communities') {
      return [{ communities: [(data as NarrativeListDataType).community_number] }]
    } else if (selectedTab === 'watchlists' && data.mode === 'watchlist') {
      const uniqueChannelUrls = (data as NarrativeListDataType).channels!.reduce((accumulator: string[], item) => {
        const channelUrls = item.channel_by_platform.map((channel) => channel.channel_url)
        return accumulator.concat(channelUrls.filter((url) => !accumulator.includes(url)))
      }, [])

      return [{ channel_urls: uniqueChannelUrls }]
    }
    return []
  }, [selectedTab, data, forAsset])

  useEffect(() => {
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    if (renderConditions.length) {
      const requestData = {
        conditions: renderConditions,
      }
      const riskScore = { min: 0, max: 100 }
      setLineChartLoading?.(true)
      // @ts-ignore
      fetchLineChartStats({ startDate, endDate, data: requestData, riskScore }).then((res) => {
        if (res !== 'error') {
          setLineChartData(JSON.parse(JSON.stringify(res)))
          setLineChartLoading?.(false)
        }
      })
    }
  }, [renderConditions, snippetsFilter.days])

  const postsSentiments = useMemo(() => {
    const uniqueSentiments = new Set()
    lineChartData.posts.sentiment.forEach((dataPoint: any) => {
      Object.keys(dataPoint).forEach((key) => {
        if (sentimentsNames[key as SentimentKey]) {
          uniqueSentiments.add(key)
        }
      })
    })
    return Array.from(uniqueSentiments)
  }, [lineChartData.posts])

  const impressionsSentiments = useMemo(() => {
    const uniqueSentiments = new Set<SentimentKey>()
    lineChartData.impressions.sentiment.forEach((dataPoint: SentimentKey) => {
      Object.keys(dataPoint).forEach((key) => {
        if (sentimentsNames[key as SentimentKey]) {
          uniqueSentiments.add(key as SentimentKey)
        }
      })
    })
    return Array.from(uniqueSentiments)
  }, [lineChartData.impressions])

  const postsPlatforms = useMemo(() => {
    const uniquePlatforms = new Set<Lowercase<sourcePlatform> | 'date' | 'patriotswin' | 'eucouncil' | 'cspan'>()

    lineChartData.posts.platform.forEach((dataPoint: sourcePlatform) => {
      Object.keys(dataPoint).forEach((key) => {
        uniquePlatforms.add(key as Lowercase<sourcePlatform>)
      })
    })

    uniquePlatforms.delete('date')
    if (uniquePlatforms.has('patriotswin')) {
      uniquePlatforms.delete('patriotswin')
      uniquePlatforms.add('patriots.win')
    }
    if (uniquePlatforms.has('eucouncil')) {
      uniquePlatforms.delete('eucouncil')
      uniquePlatforms.add('consilium')
    }
    if (uniquePlatforms.has('cspan')) {
      uniquePlatforms.delete('cspan')
      uniquePlatforms.add('c-span')
    }
    return Array.from(uniquePlatforms) as Lowercase<sourcePlatform>[]
  }, [lineChartData.posts])

  const impressionsPlatforms = useMemo(() => {
    const uniquePlatforms = new Set<Lowercase<sourcePlatform> | 'date' | 'patriotswin' | 'eucouncil' | 'cspan'>()

    lineChartData.impressions.platform.forEach((dataPoint: sourcePlatform) => {
      Object.keys(dataPoint).forEach((key) => {
        uniquePlatforms.add(key as Lowercase<sourcePlatform>)
      })
    })

    uniquePlatforms.delete('date')
    if (uniquePlatforms.has('patriotswin')) {
      uniquePlatforms.delete('patriotswin')
      uniquePlatforms.add('patriots.win')
    }
    if (uniquePlatforms.has('eucouncil')) {
      uniquePlatforms.delete('eucouncil')
      uniquePlatforms.add('consilium')
    }
    if (uniquePlatforms.has('cspan')) {
      uniquePlatforms.delete('cspan')
      uniquePlatforms.add('c-span')
    }
    return Array.from(uniquePlatforms) as Lowercase<sourcePlatform>[]
  }, [lineChartData.impressions])

  const platformsToRender = useMemo(() => {
    if (activeMetric === 'posts') {
      return postsPlatforms
    }
    return impressionsPlatforms
  }, [activeMetric, activePivot])

  const sentimentsToRender = useMemo(() => {
    if (activeMetric === 'posts') {
      return postsSentiments
    }
    return impressionsSentiments
  }, [activeMetric, activePivot])

  return (
    <div className={`area_chart_section_container ${className}`}>
      <div className='acsc_buttons_container'>
        <div className='acsc_bc_left_section'>
          <ButtonImage
            icon={PostsIcon}
            className={`acsc_bc_s_button${activeMetric === 'posts' ? ' acsc_bc_s_button_active' : ''}`}
            onClick={() => {
              setActiveMetric('posts')
            }}>
            Posts
          </ButtonImage>
          <ButtonImage
            icon={ImpressionsIcon}
            className={`acsc_bc_s_button${activeMetric === 'impressions' ? ' acsc_bc_s_button_active' : ''}`}
            onClick={() => {
              setActiveMetric('impressions')
            }}>
            Impressions
          </ButtonImage>
        </div>
        <div className='acsc_bc_right_section'>
          <ButtonImage
            icon={AllIcon}
            className={`acsc_bc_s_button${activePivot === 'all' ? ' acsc_bc_s_button_active' : ''}`}
            onClick={() => {
              setActivePivot('all')
            }}>
            All
          </ButtonImage>
          <ButtonImage
            icon={SentimentIcon}
            className={`acsc_bc_s_button${activePivot === 'sentiment' ? ' acsc_bc_s_button_active' : ''}`}
            onClick={() => {
              setActivePivot('sentiment')
            }}>
            Sentiment
          </ButtonImage>
          <ButtonImage
            icon={PlatformIcon}
            className={`acsc_bc_s_button${activePivot === 'platform' ? ' acsc_bc_s_button_active' : ''}`}
            onClick={() => {
              setActivePivot('platform')
            }}>
            Platform
          </ButtonImage>
        </div>
      </div>
      <div className='acsc_area_chart_container'>
        <div className='acsc_acc_inner_container'>
          <ResponsiveContainer width='100%' height='100%'>
            <AreaChart
              width={500}
              height={400}
              data={lineChartData[activeMetric][activePivot]}
              margin={{
                top: 20,
              }}>
              <defs>
                {activePivot === 'all' && (
                  <>
                    <linearGradient id='colorRisk' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#FEC84B' stopOpacity={0.2} />
                      <stop offset='95%' stopColor='#FEC84B' stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorPost' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor='#17B26A' stopOpacity={0.2} />
                      <stop offset='95%' stopColor='#17B26A' stopOpacity={0} />
                    </linearGradient>

                    {/* <linearGradient id='colorAmt' x1='0' y1='0' x2='0' y2='1'>
                        <stop offset='5%' stopColor='#6927DA' stopOpacity={0.2} />
                        <stop offset='95%' stopColor='#6927DA' stopOpacity={0} />
                      </linearGradient> */}
                  </>
                )}

                {activePivot === 'sentiment' &&
                  sentimentsToRender.map((sentiment) => (
                    <linearGradient
                      key={sentiment as SentimentKey}
                      id={`color${sentiment}`}
                      x1='0'
                      y1='0'
                      x2='0'
                      y2='1'>
                      <stop offset='5%' stopColor={sentimentsColors[sentiment as SentimentKey]} stopOpacity={0.2} />
                      <stop offset='95%' stopColor={sentimentsColors[sentiment as SentimentKey]} stopOpacity={0} />
                    </linearGradient>
                  ))}

                {activePivot === 'platform' &&
                  platformsToRender.map((platform) => (
                    <linearGradient key={platform} id={`color${platform}`} x1='0' y1='0' x2='0' y2='1'>
                      <stop
                        offset='5%'
                        stopColor={UtilService.getPlatformColor(UtilService.getPlatformBrandName(platform))}
                        stopOpacity={0.2}
                      />
                      <stop
                        offset='95%'
                        stopColor={UtilService.getPlatformColor(UtilService.getPlatformBrandName(platform))}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  ))}

                {/* <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#17B26A' stopOpacity={0.2} />
                    <stop offset='95%' stopColor='#17B26A' stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#FEC84B' stopOpacity={0.2} />
                    <stop offset='95%' stopColor='#FEC84B' stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id='colorAmt' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#6927DA' stopOpacity={0.2} />
                    <stop offset='95%' stopColor='#6927DA' stopOpacity={0} />
                  </linearGradient> */}
              </defs>
              {/* <CartesianGrid strokeDasharray='3 3' /> */}
              {/* <XAxis dataKey='name' /> */}
              {/* <YAxis /> */}
              {/* <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} /> */}
              <Legend align='left' content={<CustomLegend activePivot={activePivot} />} />

              {activePivot === 'all' && (
                <>
                  <Area
                    type='monotone'
                    dataKey={activeMetric === 'posts' ? 'metric_value_risk' : 'metric_running_average'}
                    stackId='1'
                    strokeWidth={3}
                    stroke='#FEC84B'
                    fillOpacity={1}
                    fill='url(#colorRisk)'
                  />
                  <Area
                    type='monotone'
                    dataKey='metric_value'
                    stackId='1'
                    strokeWidth={2}
                    stroke='#17B26A'
                    fillOpacity={1}
                    fill='url(#colorPost)'
                  />
                  {/* <Area
                      type='monotone'
                      dataKey='amt'
                      stackId='1'
                      strokeWidth={2}
                      stroke='#6927DA'
                      fillOpacity={1}
                      fill='url(#colorAmt)'
                    /> */}
                </>
              )}

              {activePivot === 'sentiment' &&
                sentimentsToRender.map((sentiment) => (
                  <Area
                    key={sentiment as SentimentKey}
                    type='monotone'
                    dataKey={sentiment as SentimentKey}
                    stackId='1'
                    strokeWidth={2}
                    stroke={sentimentsColors[sentiment as SentimentKey]}
                    fillOpacity={1}
                    fill={`url(#color${sentiment})`}
                  />
                ))}

              {activePivot === 'platform' &&
                platformsToRender.map((platform) => (
                  <Area
                    key={platform}
                    type='monotone'
                    dataKey={platform}
                    stackId='1'
                    strokeWidth={2}
                    stroke={UtilService.getPlatformColor(UtilService.getPlatformBrandName(platform))}
                    fillOpacity={1}
                    fill={`url(#color${platform})`}
                  />
                ))}

              {/* <Area
                  type='monotone'
                  dataKey='pv'
                  stackId='1'
                  strokeWidth={2}
                  stroke='#17B26A'
                  fillOpacity={1}
                  fill='url(#colorPv)'
                />
                <Area
                  type='monotone'
                  dataKey='uv'
                  stackId='1'
                  strokeWidth={2}
                  stroke='#FEC84B'
                  fillOpacity={1}
                  fill='url(#colorUv)'
                />
                <Area
                  type='monotone'
                  dataKey='amt'
                  stackId='1'
                  strokeWidth={2}
                  stroke='#6927DA'
                  fillOpacity={1}
                  fill='url(#colorAmt)'
                /> */}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
})

export default AreaChartSection
