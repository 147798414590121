import React, { useState } from 'react'
import { ReactComponent as ChevronDown } from 'assets/images/icons/outline/chevron-down.svg'
import './Dropdown.scss'

export type DropdownOption = {
  id: string
  label: string
}

type DropdownProps<T extends DropdownOption> = {
  data: T[]
  className?: string
  title?: string
  placeholder?: string
  value?: string
  onSelect?: (data: T) => void
  disabled?: boolean
}

function Dropdown<T extends DropdownOption>(props: DropdownProps<T>) {
  const { className = '', data, title, placeholder, value, onSelect, disabled } = props
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)

  const handleDropdownToggle = () => {
    setOpenDropdown((prevState) => !prevState)
  }

  const handleOptionClick = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>, option: T) => {
    e.stopPropagation()
    setOpenDropdown(false)
    onSelect?.(option)
  }

  return (
    <div className={`dropdown_container_input ${className}`}>
      {title ? <p className='dc_title'>{title}</p> : null}
      <div
        className={`dc_input_container${openDropdown ? ' dc_input_container_open' : ''}${
          disabled ? ' dc_input_container_disabled' : ''
        }`}
        onClick={handleDropdownToggle}>
        <p className={`dc_ic_text${value ? ' dc_ic_text_value' : ''}`}>{value || placeholder}</p>
        <ChevronDown className='dc_ic_icon' height={20} width={20} />
        {openDropdown && (
          <div className='dc_ic_dropdown'>
            {data.map((option) => (
              <p key={option.id} className='dc_ic_d_option' onClick={(e) => handleOptionClick(e, option)}>
                {option.label}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Dropdown
