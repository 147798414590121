import axios from 'axios'
import { ROUTES } from 'settings/settings'

export function createSummarySubscription({
  data,
  method = 'post',
  id,
}: {
  data: { search_type: string; subscription_frequency: 'DAILY' | 'WEEKLY'; weekly_subscription_day?: any }
  method?: 'post' | 'put'
  id: string
}) {
  return axios({
    method: method,
    url: ROUTES.summarySubscription.replace(':id', id),
    data,
  })
}
