import axios from 'axios'
import { ROUTES } from 'settings/settings'

export function getReportSummary(id: string, params: { start_date: string; end_date: string }) {
  return axios({
    method: 'get',
    url: `${ROUTES.reports}/${id}/instance-summary`,
    params: params,
  })
}
