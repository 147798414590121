import React, { useState, useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import ReportDateRange from '../ReportDateRange/ReportDateRange'
import { ReactComponent as BackBtnIcon } from 'assets/images/icons/arrow-left.svg'
import { LastUpdateObj } from 'store/report/valueTypes'
import profileImage from 'assets/images/LogoiconMobile.svg'
import './DetailViewLeftSectionViewHistory.scss'

const DATE_RANGE_FORMATE = 'MMM DD, YYYY'
const HISTORY_DATE_FORMATE = 'MMMM DD, h:mm A'

type DetailViewLeftSectionProps = {
  viewHistoryHandler?: () => void
  instanceId: string
}

const DetailViewLeftSectionViewHistory = observer((props: DetailViewLeftSectionProps) => {
  const navigate = useNavigate()
  const { viewHistoryHandler, instanceId } = props
  const { reportMainViewStore } = store
  const { reportDetails, reportDetailsEntities, reportSummaryHistory, isSummaryHistoryLoading } = reportMainViewStore
  const [dateRangeValue, setDateRangeValue] = useState<LastUpdateObj>({})
  const [selectedVersionId, setSelectedVersionId] = useState<string>(instanceId)

  const dateRangeHandler = (obj: LastUpdateObj) => {
    setDateRangeValue(obj)
    navigate(`/reports/${reportDetails.id}?instance_id=${obj.instance_id}`, { replace: true })
  }

  useEffect(() => {
    if (instanceId) {
      setSelectedVersionId(instanceId)
    }
  }, [instanceId])

  const dateRange = useMemo(() => {
    if (Object.keys(dateRangeValue).length) {
      return `${dayjs(dateRangeValue.start_date).format(DATE_RANGE_FORMATE)} - ${dayjs(dateRangeValue.end_date).format(
        DATE_RANGE_FORMATE,
      )}`
    } else if (reportDetailsEntities.start_date) {
      return `${dayjs(reportDetailsEntities.start_date).format(DATE_RANGE_FORMATE)} - ${dayjs(
        reportDetailsEntities.end_date,
      ).format(DATE_RANGE_FORMATE)}`
    }
    return ''
  }, [reportDetailsEntities.start_date, reportDetailsEntities.end_date, dateRangeValue])

  useEffect(() => {
    if (reportDetailsEntities.instance_id) {
      setDateRangeValue({
        instance_id: reportDetailsEntities.instance_id,
        start_date: new Date(reportDetailsEntities.start_date!),
        end_date: new Date(reportDetailsEntities.end_date!),
      })
    }
  }, [reportDetailsEntities.instance_id])

  return (
    <div className='detail_view_left_section_view_history_container'>
      <div className='dvlc_inner_container'>
        <div className='dvlc_header'>
          <BackBtnIcon className='dvlc_h_back_icon' onClick={viewHistoryHandler} />
          <p className='dvlc_h_heading'>Version history</p>
        </div>
        <ReportDateRange
          data={reportDetails.report_instance_history}
          value={dateRange}
          selectedValue={dateRangeValue}
          onSelect={(res) => dateRangeHandler(res)}
          showHeading={false}
        />
      </div>
      <Spin size='large' spinning={isSummaryHistoryLoading}>
        <div className='dvlc_history_card_container'>
          {reportSummaryHistory.items.length
            ? reportSummaryHistory.items.map((history) => (
                <div
                  key={history.id}
                  className={`dvlc_hcc_card${history.id === selectedVersionId ? ' dvlc_hcc_card_active' : ''}`}
                  onClick={() => {
                    navigate(`/reports/${reportDetails.id}?instance_id=${history.id}`, {
                      replace: true,
                    })
                  }}>
                  <p className='dvlc_hcc_c_date'>{dayjs(history.last_updated).format(HISTORY_DATE_FORMATE)}</p>

                  <div className='dvlc_hcc_c_users_list'>
                    <div className='dvlc_hcc_c_ul_card'>
                      <div className='dvlc_hcc_c_uc_image_container'>
                        <img
                          className='dvlc_hcc_c_uc_ic_image'
                          src={history.image_url || profileImage}
                          alt='profile pic'
                        />
                      </div>
                      <p className='dvlc_hcc_c_uc_name'>{history.last_updated_by_given_name}</p>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </Spin>
    </div>
  )
})

export default DetailViewLeftSectionViewHistory
