import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { CreateReportObj } from 'store/report/valueTypes'

export function updateReport(id: string, data: CreateReportObj) {
  return axios({
    method: 'put',
    url: `${ROUTES.reports}/${id}`,
    data,
  })
}
