import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  configuration_name: string
  config_json: any
}
export const updateTenantConfiguration = async (id: string, payload: Props) => {
  let url = `${process.env.REACT_APP_API}${ROUTES.tenantConfiguration}?tenant_id=${id}`
  const { data } = await axios.put(url, payload)
  return data
}
