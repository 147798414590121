import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { ReportFieldParams, ReportFeedsData } from 'store/report/valueTypes'

export function fetchReportFeeds(params: ReportFieldParams, data: ReportFeedsData) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_SEARCH_API}${ROUTES.reportsFeeds}`,
    params: params,
    data: data,
  })
}
