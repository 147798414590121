import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import useClickAway from 'hooks/useClickAway'
import { ReactComponent as DropdownArrowIcon } from 'assets/images/icons/report/dropdownicon-size-16.svg'
import { ReactComponent as CheckIcon } from 'assets/images/icons/outline/check.svg'
import './ShareUserDropdown.scss'

export type ShareUserDataType = {
  id: string
  option: string
  value: string
}

type ShareUserDropdownProps = {
  value?: string
  onSelect?: (val: ShareUserDataType) => void
  showIcon?: boolean
  className?: string
  headerClassName?: string
  bodyClassName?: string
  data?: ShareUserDataType[]
  showHistoryText?: boolean
  closeWhenClick?: boolean
  selectedValue?: ShareUserDataType
  onRemove?: () => void
  ShareAll?: boolean
}

function ShareUserDropdown(props: ShareUserDropdownProps) {
  const {
    value = '',
    onSelect,
    showIcon = true,
    className,
    headerClassName,
    bodyClassName,
    data = [],
    closeWhenClick,
    selectedValue,
    onRemove,
    ShareAll,
  } = props
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onClickOutside = () => {
    setIsOpen(false)
  }
  useClickAway(dropdownRef, onClickOutside)

  return (
    <div
      ref={dropdownRef}
      className={classNames('dropdown_container', className, {
        dropdown_container_disabled: !showIcon,
      })}
      onClick={(e) => {
        e.stopPropagation()
        setIsOpen((prevState) => !prevState)
      }}>
      <div className={classNames('dc_value_container', headerClassName)}>
        <p className='value_text'>{value}</p>
        {showIcon && <DropdownArrowIcon className={classNames('dropdown_icon', { dropdown_icon_rotate: isOpen })} />}
      </div>
      {isOpen && (
        <div className={classNames('dc_content_container', bodyClassName)} onClick={(event) => event.stopPropagation()}>
          <ul className='list_container'>
            {data.map((res: any) => (
              <li key={res.id} className='lc_list'>
                <div
                  className={classNames('lc_l_options_container', {
                    lc_l_options_container_active: res.id === selectedValue?.id,
                  })}
                  onClick={(event) => {
                    event.stopPropagation()
                    onSelect?.(res)
                    if (closeWhenClick) {
                      onClickOutside()
                    }
                  }}>
                  <CheckIcon
                    className={classNames('oc_check_icon', { oc_check_icon_active: res.id === selectedValue?.id })}
                    height={20}
                    width={20}
                  />
                  <p className='oc_text'>{res.option}</p>
                </div>
              </li>
            ))}
            {!ShareAll && (
              <>
                <hr className='lc_l_divider' />
                <li className='lc_list'>
                  <div
                    className='lc_l_options_container lc_l_options_container_remove'
                    onClick={(event) => {
                      event.stopPropagation()
                      onRemove?.()
                      if (closeWhenClick) {
                        onClickOutside()
                      }
                    }}>
                    <CheckIcon className='oc_check_icon' height={20} width={20} />
                    <p className='oc_text'>Remove</p>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ShareUserDropdown
