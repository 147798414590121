import { store } from 'store'
import { Dropdown, Menu, MenuProps, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { exportAPI } from 'api/api'
import { useState } from 'react'

import { ReactComponent as YoutubeIcon } from 'assets/images/youtube-platform-icon.svg'
import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-grey-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-platform-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-platform-icon.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-platform-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-grey-icon.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-grey-icon.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-grey-icon.svg'
import { ReactComponent as TruthSocialIcon } from 'assets/images/truth-social-grey-icon.svg'
import { ReactComponent as GabIcon } from 'assets/images/gab-grey-icon.svg'
import { ReactComponent as PatriotsIcon } from 'assets/images/patriots-grey-icon.svg'
import { ReactComponent as ChanIcon } from 'assets/images/4-chan-grey-icon.svg'
import { ReactComponent as VkIcon } from 'assets/images/vk-icon-current-color.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-icon-current-color.svg'
import { ReactComponent as ThreadsIcon } from 'assets/images/threads-icon-current-color.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/telegram-icon-current-color.svg'
import { ReactComponent as CSpanIcon } from 'assets/images/c-span-grey-icon.svg'
import { ReactComponent as ConsiliumIcon } from 'assets/images/consilium-grey-icon.svg'
import { ReactComponent as WebSearchIcon } from 'assets/images/web-search-grey-icon.svg'
import { ReactComponent as PositiveSentimentIcon } from 'assets/images/positive-sentiment-icon.svg'
import { ReactComponent as NegativeIconSentimentIcon } from 'assets/images/negative-sentiments-icon.svg'
import { ReactComponent as NeutralIconSentimentIcon } from 'assets/images/neutral-sentiment-icon.svg'
import { ReactComponent as DropdownIcon } from 'assets/images/executive-intelligence/icons/add-person/dropdown-arrow.svg'
import { ReactComponent as InfoIcon } from 'assets/images/icon-info.svg'
import { ReactComponent as BrighteonIcon } from 'assets/images/brighteon-grey-icon.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/snapchat-grey-icon.svg'
import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-grey-icon.svg'

import DefaultUserAvatar from 'assets/images/LogoiconMobile.svg'

import { UtilService } from 'services/Util/Util'

import './ExportList.scss'

export const ExportDownloadBox = observer(() => {
  const PLATFORM_ICONS = [
    { title: 'YouTube', icon: <YoutubeIcon /> },
    { title: 'BitChute', icon: <BitChuteIcon /> },
    { title: 'Rumble', icon: <RumbleIcon /> },
    { title: 'Podcast', icon: <PodcastIcon /> },
    { title: 'TikTok', icon: <TikTokIcon /> },
    { title: 'News', icon: <NewsIcon /> },
    { title: 'Twitter', icon: <TwitterIcon /> },
    { title: 'Reddit', icon: <RedditIcon /> },
    { title: '4chan', icon: <ChanIcon /> },
    { title: 'Gab', icon: <GabIcon /> },
    { title: 'TruthSocial', icon: <TruthSocialIcon /> },
    { title: 'Patriots.win', icon: <PatriotsIcon /> },
    { title: 'vk', icon: <VkIcon className='vk-icon' /> },
    { title: 'Instagram', icon: <InstagramIcon /> },
    { title: 'Threads', icon: <ThreadsIcon className='threads-icon' /> },
    { title: 'telegram', icon: <TelegramIcon className='telegram-icon' /> },
    { title: 'C-SPAN', icon: <CSpanIcon />, exclude: ['watchlist'] },
    { title: 'WebSearch', icon: <WebSearchIcon />, exclude: ['watchlist'] },
    { title: 'Consilium', icon: <ConsiliumIcon />, exclude: ['watchlist'] },
    { title: 'brighteon', icon: <BrighteonIcon /> },
    { title: 'snapchat', icon: <SnapchatIcon className='snapchat-icon' /> },
    { title: 'odysee', icon: <OdyseeIcon /> },
  ]
  const SENTIMENT_ICONS = [
    { title: 'Positive', icon: <PositiveSentimentIcon /> },
    { title: 'Neutral', icon: <NeutralIconSentimentIcon height={20} /> },
    { title: 'Negative', icon: <NegativeIconSentimentIcon /> },
  ]
  const [fileType, setFileType] = useState<'csv' | 'json'>('csv')

  const { activeExport } = store['monitorExportDownloadsStore']
  const { isLoadingFeedDownloads } = store['loaderStore']

  const getFilters = () => {
    const filters: MenuProps['items'] = []
    if ((Object.values(activeExport?.filters || {})?.length || 0) > 0) {
      if ((activeExport?.filters?.platform?.length || 0) > 0) {
        filters.push({
          key: 'Platform',
          label: <span className='export__info__filter-selection__filters__label'>Platform</span>,
          disabled: true,
        })

        const platforms = activeExport?.filters?.platform || []
        platforms?.forEach((platform) => {
          filters.push({
            key: 'platform' + platform,
            className: 'feed-filters__dropdown-item--inline feed-filters__dropdown-item__mode-btn--margin',
            label: (
              <button
                className={classNames(
                  'feed-filters__dropdown-item__mode-btn',
                  'feed-filters__dropdown-item__mode-btn--active',
                )}>
                <span className='feed-filters__dropdown-item__mode-btn__title'>
                  {PLATFORM_ICONS.find((el) => el.title === platform)?.icon || <></>}
                  {platform}
                </span>
              </button>
            ),
          })
        })
      }

      if ((activeExport?.filters?.sentiment?.length || 0) > 0) {
        filters.push({
          key: 'Sentiments',
          label: <span className='export__info__filter-selection__filters__label'>Sentiments</span>,
          disabled: true,
        })

        const sentiments = activeExport?.filters?.sentiment || []
        sentiments?.forEach((sentiment) => {
          filters.push({
            key: 'sentiment' + sentiment,
            className: 'feed-filters__dropdown-item--inline feed-filters__dropdown-item__mode-btn--margin',
            label: (
              <button
                className={classNames(
                  'feed-filters__dropdown-item__mode-btn',
                  'feed-filters__dropdown-item__mode-btn--active',
                )}>
                <span className='feed-filters__dropdown-item__mode-btn__title'>
                  {SENTIMENT_ICONS.find((el) => el.title === sentiment)?.icon || <></>}
                  {sentiment}
                </span>
              </button>
            ),
          })
        })
      }
    }
    return filters
  }

  const downloadExport = async (e: any) => {
    try {
      const response = await exportAPI.downloadExportedFeed({ exportId: activeExport?.id || '', fileType })
      if (response && response?.file_path?.length > 0) {
        const link = document.createElement('a')
        link.href = response.file_path
        link.click()
      } else throw new Error('download link not working')
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  return (
    <div className='export__info'>
      <div className='export__info__container'>
        <div className='export__info__expired'>
          <span className='export__info__expired__title'>Export</span>
          <div className='export__info__expired__type'>
            <span className='export__info__title'>{activeExport?.entity_id}</span>
            <div className='export__info__expired__type__text'>{activeExport?.entity_type}</div>
          </div>
          <span className='export__info__expired__date'>{dayjs(activeExport?.created).format('DD MMMM YYYY')}</span>
          <div className='export__info__expired__creation-info'>
            <span className='export__info__expired__created-by'>Created by:</span>
            <img
              src={DefaultUserAvatar}
              alt=''
              onError={(e: any) => {
                e.target.src = DefaultUserAvatar
              }}
            />
            <div className='export__info__expired__user-div'>
              <span className='export__info__expired__user'>{`${activeExport?.created_by_given_name} ${activeExport?.created_by_family_name}`}</span>
              <span className='export__info__expired__user--designation'>
                {activeExport?.created_by_role?.replaceAll('_', ' ').toLowerCase()}
              </span>
            </div>
          </div>
        </div>
        {!activeExport?.is_expired && (
          <>
            <span className='export__info__title'>Your file is ready for export</span>
            <span className='export__info__description'>
              Great news! Your data export has been successfully processed and is now ready for download.
            </span>
          </>
        )}
        <div className='export__info__filters'>
          <div className='export__info__filters__left-div'>
            <div className='export__info__filters__left-div__inner'>
              <span className='export__info__filters__left-div__inner__export-text'>
                {activeExport?.is_expired ? 'Exported' : 'Exporting'}
              </span>
              <span className='export__info__filters__left-div__inner__days-text'>{`${dayjs(
                activeExport?.start_date,
              ).format('MMM DD, YYYY')} to ${dayjs(activeExport?.end_date).format('MMM DD, YYYY')}`}</span>
              <span className='export__info__filters__left-div__inner__description-text'>
                {<b>{activeExport?.total_snippets} </b>}
                {activeExport?.is_expired ? 'snippets exported' : 'snippets selected'}

                {!activeExport?.is_expired && (activeExport?.total_snippets || 0) > 10000 && (
                  <>
                    {'/ First '}
                    {<b>10,000</b>}
                    {' snippets will be exported'}
                  </>
                )}
              </span>
            </div>
          </div>
          {getFilters().length > 0 && (
            <div>
              <Dropdown
                overlayClassName='export__info__filter-selection__filters'
                overlay={<Menu items={getFilters()}></Menu>}>
                <button className='export__info__filters__right-div__filters-btn'>{'3 filters applied'} </button>
              </Dropdown>
            </div>
          )}
        </div>
        {!activeExport?.is_expired && (
          <div className='export__info__format'>
            <span className='export__info__format__label'>File format</span>
            <Select
              popupClassName='export__info__dropdown'
              value={fileType}
              onChange={(value) => setFileType(value)}
              suffixIcon={<DropdownIcon className='export__info__filter-selection__order__icon' />}>
              <Select.Option key={'csv'} value={'csv'}>
                <span className='export__info__filter-selection__order__label'>{'CSV'}</span>
              </Select.Option>
              <Select.Option key={'json'} value={'json'}>
                <span className='export__info__filter-selection__order__label'>{'JSON'}</span>
              </Select.Option>
            </Select>
          </div>
        )}

        <button
          className='export__info__download'
          disabled={activeExport?.is_expired || isLoadingFeedDownloads}
          onClick={downloadExport}>
          {activeExport?.is_expired ? 'Export link expired' : 'Download'}
        </button>
        {activeExport?.is_expired && (
          <div className='export__info__expired__note'>
            <InfoIcon />
            <span className='export__info__expired__note__description'>
              The export link has expired. To access this data again, a new export request will be necessary.
            </span>
          </div>
        )}
      </div>
    </div>
  )
})
