import { observer } from 'mobx-react-lite'
import { ChordChartDataset } from 'models/models'
import { useEffect, useState } from 'react'
import { ElectionChordGraph } from './ElectionChordGraph'
import * as d3 from 'd3'
import { ListPageSection } from 'components/common/ListPageSection/ListPageSection'
import { store } from 'store'
import { DetailsSection } from '../../DetailsSection/DetailsSection'
import classNames from 'classnames'

import { ReactComponent as ListViewIcon } from 'assets/images/listview-icon.svg'
import { ReactComponent as ChordChartIcon } from 'assets/images/chord-chart-icon.svg'
import { ReactComponent as ThreeDIcon } from 'assets/images/riskwand/3d_chart_icon.svg'
import { ReactComponent as ElectionChordChartIcon } from 'assets/images/elecion-icon.svg'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import { Tooltip } from 'antd'

export const ElectionChordGraphView = observer(() => {
  const width = 310
  const radius = width / 2
  const range = ['C1', 'C2', 'C3', 'R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8', 'R9']
  const dataset = [
    {
      name: 'election.issues.economic.Inflation',
      size: -0.03,
      imports: [],
      label: 'election.issues.economic.Inflation',
    },
    {
      name: 'election.issues.economic.Recession',
      size: -0.02,
      imports: [],
      label: 'election.issues.economic.Recession',
    },
    {
      name: 'election.issues.economic.Labor Strike',
      size: -0.01,
      imports: [],
      label: 'election.issues.economic.Labor Strike',
    },
    {
      name: 'election.issues.economic.TITLE_economic',
      size: 0,
      imports: [],
      label: 'election.issues.economic.TITLE_economic',
    },
    {
      name: 'election.issues.economic.cost_of_energy',
      size: 0.01,
      imports: [],
      label: 'election.issues.economic.cost_of_energy',
    },
    {
      name: 'election.issues.economic.cost_of_housing',
      size: 0.02,
      imports: [],
      label: 'election.issues.economic.cost_of_housing',
    },
    {
      name: 'election.issues.economic.Wage Exploitation',
      size: 0.03,
      imports: [],
      label: 'election.issues.economic.Wage Exploitation',
    },
    {
      name: 'election.issues.economic.Taxation - All Mentions',
      size: 0.04,
      imports: [],
      label: 'election.issues.economic.Taxation - All Mentions',
    },
    {
      name: 'election.issues.economic.Labor & Unionization',
      label: 'election.issues.economic.Labor & Unionization',
      size: 0.05,
      imports: [],
    },
    {
      name: 'election.issues.economic.Unemployment & Layoffs',
      size: 0.06,
      imports: [],
      label: 'election.issues.economic.Unemployment & Layoffs',
    },
    {
      name: 'election.issues.political.Immigration',
      size: -0.02,
      imports: [],
      label: 'election.issues.political.Immigration',
    },
    {
      name: 'election.issues.political.China & Taiwan',
      size: -0.01,
      imports: [],
      label: 'election.issues.political.China & Taiwan',
    },
    {
      name: 'election.issues.political.TITLE_political',
      size: 0,
      imports: [],
      label: 'election.issues.political.TITLE_political',
    },
    {
      name: 'election.issues.political.Russia & Ukraine',
      size: 0.01,
      imports: [],
      label: 'election.issues.political.Russia & Ukraine',
    },
    {
      name: 'election.issues.political.Israel & Hamas',
      size: 0.02,
      imports: [],
      label: 'election.issues.political.Israel & Hamas',
    },
    {
      name: 'election.issues.environmental.climate_change',
      size: -0.01,
      imports: [],
      label: 'election.issues.environmental.climate_change',
    },
    {
      name: 'election.issues.environmental.TITLE_environmental',
      size: 0,
      imports: [],
      label: 'election.issues.environmental.TITLE_environmental',
    },

    {
      name: 'election.issues.environmental.Environmental Activism',
      size: 0.01,
      imports: [],
      label: 'election.issues.environmental.Environmental Activism',
    },
    {
      name: 'election.issues.environmental.Renewable and Alternative Energy',
      size: 0.02,
      imports: [],
      label: 'election.issues.environmental.Renewable and Alternative Energy',
    },
    {
      name: 'election.issues.social.Diversity, Equity, Inclusion',
      size: -0.03,
      imports: [],
      label: 'election.issues.social.Diversity, Equity, Inclusion',
    },
    {
      name: 'election.issues.social.LGBTQ+ and Related Events',
      size: -0.02,
      imports: [],
      label: 'election.issues.social.LGBTQ+ and Related Events',
    },
    {
      name: 'election.issues.social.Human Rights',
      size: -0.01,
      imports: [],
      label: 'election.issues.social.Human Rights',
    },
    {
      name: 'election.issues.social.TITLE_social',
      size: 0,
      imports: [],
      label: 'election.issues.social.TITLE_social',
    },
    {
      name: 'election.issues.social.Antisemetic Mentions',
      size: 0.01,
      imports: [],
      label: 'election.issues.social.Antisemetic Mentions',
    },
    {
      name: 'election.issues.social.2nd Amendment',
      size: 0.02,
      imports: [],
      label: 'election.issues.social.2nd Amendment',
    },
    {
      name: 'election.issues.social.Reproductive Rights',
      size: 0.03,
      imports: [],
      label: 'election.issues.social.Reproductive Rights',
    },

    {
      name: 'election.issues.public_health.General Healthcare',
      size: -0.02,
      imports: [],
      label: 'election.issues.public_health.General Healthcare',
    },
    {
      name: 'election.issues.public_health.Opiod and Fentanyl Epidemic',
      size: -0.01,
      imports: [],
      label: 'election.issues.public_health.Opiod and Fentanyl Epidemic',
    },
    {
      name: 'election.issues.public_health.TITLE_public_health',
      size: 0,
      imports: [],
      label: 'election.issues.public_health.TITLE_public_health',
    },
    {
      name: 'election.issues.public_health.Crime & Public Safety',
      size: 0.01,
      imports: [],
      label: 'election.issues.public_health.Crime & Public Safety',
    },
    {
      name: 'election.issues.public_health.Healthcare Affordability',
      size: 0.02,
      imports: [],
      label: 'election.issues.public_health.Healthcare Affordability',
    },
    { name: 'election.range.company.C1.Brand', size: 0, imports: [], label: 'election.range.company.C1.Brand' },
    { name: 'election.range.company.C2.Executive', size: 0, imports: [], label: 'election.range.company.C2.Executive' },
    {
      name: 'election.range.company.C3.Industry',
      size: 0,
      imports: [],
      label: 'election.range.company.C3.Industry',
    },
    {
      name: 'election.range.races.R1.presidential',
      size: 0,
      color: 'purple',
      imports: [],
      label: 'election.range.races.R1.presidential',
    },
    {
      name: 'election.range.races.R2.north_east_d',
      size: 0,
      color: 'blue',
      imports: [],
      label: 'election.range.races.R2.north_east_d',
    },
    {
      name: 'election.range.races.R3.north_east_r',
      size: 0,
      color: 'red',
      imports: [],
      label: 'election.range.races.R3.north_east_r',
    },
    {
      name: 'election.range.races.R4.midwest_d',
      size: 0,
      color: 'blue',
      imports: [],
      label: 'election.range.races.R4.midwest_d',
    },
    {
      name: 'election.range.races.R5.midwest_r',
      size: 0,
      color: 'red',
      imports: [],
      label: 'election.range.races.R5.midwest_r',
    },
    {
      name: 'election.range.races.R6.south_d',
      size: 0.02,
      color: 'blue',
      imports: [],
      label: 'election.range.races.R6.south_d',
    },
    {
      name: 'election.range.races.R7.south_r',
      size: 0.02,
      color: 'red',
      imports: [],
      label: 'election.range.races.R7.south_r',
    },
    {
      name: 'election.range.races.R8.west_d',
      size: 0.02,
      color: 'blue',
      imports: [],
      label: 'election.range.races.R8.west_d',
    },
    {
      name: 'election.range.races.R9.west_r',
      size: 0.02,
      color: 'red',
      imports: [],
      label: 'election.range.races.R9.west_r',
    },
  ]
  const treeCluster = d3
    .cluster()
    .size([2 * Math.PI, radius - 100])
    .separation((a: any, b: any) => {
      if (a.parent === b.parent) {
        if (b.data.name.includes('TITLE')) return 6
        else if (a.data.name.includes('TITLE')) return 2
        else return 2
      } else if (range.includes(a.parent.data.name)) return 12
      else return 8
    })

  const [data, setData] = useState(dataset)
  const [root, setRoot] = useState<d3.HierarchyPointNode<unknown> | null>(null)

  const { anticipatoryIntelligenceStore } = store
  const {
    chartName,
    isSideDetailsOpen,
    setChartName,
    setIsSideDetailsOpen,
    fetchElectionIssues,
    fetchIssuesRacesDetails,
    fetchIssuesAttentionFlagsDetails,
    fetchRacesAttentionFlagsDetails,
  } = anticipatoryIntelligenceStore

  useEffect(() => {
    const refinedDataset = hierarchy(data)
    const hierarchialData = d3.hierarchy(refinedDataset)
    const linkedData = bilink(hierarchialData)
    setRoot(treeCluster(linkedData))
  }, [data])

  const bilink = (root: any) => {
    const map = new Map(root.leaves().map((d: any) => [ids(d), d]))
    for (const d of root.leaves()) {
      d.incoming = []
      d.outgoing = d.data.imports.map((i: any) => [d, map.get(i)])
    }
    for (const d of root.leaves()) {
      for (const o of d.outgoing) {
        if (!o[1]) o[1] = []
        if (!o[1].incoming) o[1].incoming = []

        o[1].incoming.push(o)
      }
    }
    return root
  }

  const hierarchy = (data: ChordChartDataset[], delimiter = '.') => {
    let dataWithParent = makeParent(data)

    let root
    const map = new Map()
    dataWithParent?.forEach(function find(dataValue: any) {
      const { name } = dataValue
      if (map.has(name)) return map.get(name)
      const i = name.lastIndexOf(delimiter)
      map.set(name, dataValue)
      if (i >= 0) {
        find({ name: name.substring(0, i), children: [] }).children.push(dataValue)
        dataValue.name = name.substring(i + 1)
      } else root = dataValue
      return dataValue
    })
    return root
  }

  const ids = (node: any): any => {
    return `${node.parent ? ids(node.parent) + '.' : ''}${node.data.name}`
  }

  const makeParent = (data: ChordChartDataset[]) => {
    let newData: ChordChartDataset[] = []
    data.forEach((value) => {
      newData.push({ ...value, name: value.label || value.name })
    })
    return newData
  }

  const removeChordChartImports = () => {
    let newData: any = []
    data.forEach((value) => {
      newData.push({ ...value, imports: [] })
    })
    setData(newData)
  }

  const changeChart = (name: 'list' | 'chord' | '3d' | 'election') => {
    setChartName(name)
    setIsSideDetailsOpen('')
  }

  const onChangeDateFilter = () => {
    fetchElectionIssues()
    fetchIssuesRacesDetails()
    fetchIssuesAttentionFlagsDetails()
    fetchRacesAttentionFlagsDetails()
    removeChordChartImports && removeChordChartImports()
  }

  return (
    <div>
      <div className='chord-graph__container'>
        <div className='chord-graph__header'>
          <div className='chord-graph__header__filters'>
            <div className='chord-graph__election-graph-div'>
              <div className={'chord-graph__election-graph-div___tab'} onClick={() => changeChart('list')}>
                <Tooltip title='List View' placement='bottom'>
                  <ListViewIcon aria-label='List View' />
                </Tooltip>
              </div>
              <div className={'chord-graph__election-graph-div___tab'} onClick={() => changeChart('chord')}>
                <Tooltip title='Chord Chart' placement='bottom'>
                  <ChordChartIcon aria-label='Chord Chart' />
                </Tooltip>
              </div>
              <div className={'chord-graph__election-graph-div___tab'} onClick={() => changeChart('3d')}>
                <Tooltip title='3D Chart' placement='bottom'>
                  <ThreeDIcon aria-label='3D Chart' />
                </Tooltip>
              </div>
              <div
                className={classNames('chord-graph__election-graph-div___tab', {
                  'chord-graph__election-graph-div___tab--active': chartName === 'election',
                })}
                onClick={() => changeChart('election')}>
                <Tooltip title='Election Chord Chart' placement='bottom'>
                  <ElectionChordChartIcon aria-label='Election Chord Chart' />
                </Tooltip>
                <span
                  className={classNames('chord-graph__election-graph-div___tab__title', {
                    'chord-graph__election-graph-div___tab__title--active': chartName === 'election',
                  })}>
                  {'Election Chord Chart'}
                </span>
              </div>
            </div>
          </div>
          <DateFilter
            days={[1, 7, 30, 90, 180, 365, 730]}
            pageType={'list'}
            subStore={'anticipatoryIntelligence'}
            addCustomDateOption={false}
            onClick={onChangeDateFilter}
          />
        </div>
      </div>
      <ListPageSection
        left={<ElectionChordGraph dataset={data} root={root} setDataset={setData} />}
        right={isSideDetailsOpen.length > 0 ? <DetailsSection /> : <></>}
        columns={{
          col1: {
            sm: isSideDetailsOpen.length > 0 ? 16 : 24,
            md: isSideDetailsOpen.length > 0 ? 16 : 24,
            lg: isSideDetailsOpen.length > 0 ? 16 : 24,
          },
          col2: {
            sm: isSideDetailsOpen.length > 0 ? 8 : 0,
            md: isSideDetailsOpen.length > 0 ? 8 : 0,
            lg: isSideDetailsOpen.length > 0 ? 8 : 0,
          },
        }}
      />
    </div>
  )
})
