import { Button, Modal } from 'antd'
import styles from './SuccessBodyModal.module.scss'

const SuccessBodyModal = (props: any) => {
  return (
    <Modal
      centered
      open={props.visibilityController}
      title={null}
      closeIcon={null}
      footer={null}
      closable={false}
      wrapClassName={styles.successModal}>
      <SuccessBody
        gif={props.gif}
        heading={props.heading}
        subheading={props.subheading}
        closeDeleteSuccess={props.closeDeleteSuccess}
        buttonText={props.buttonText}
      />
    </Modal>
  )
}
export default SuccessBodyModal

export const SuccessBody = (props: any) => {
  return (
    <div className={styles.successModalBody}>
      <div className={styles.gif}>
        <img src={props.gif} alt='success gif' />
      </div>
      <div className={styles.message}>{props.heading}</div>
      <div className={styles.subMessage}>{props.subheading}</div>
      {props.buttonText && (
        <Button className={styles.deleteDone} onClick={props.closeDeleteSuccess}>
          {props.buttonText}
        </Button>
      )}
    </div>
  )
}
