import { Skeleton } from 'antd'
import { ReactComponent as DoghnutEmptyState } from 'assets/images/icons/dashboard/doghnut-empty-state.svg'

export default function EmptyState({ title }: { title: string }) {
  return (
    <>
      <div className='chart-heading'>
        <span className='monitor-assets__graph__title'>{title}</span>
      </div>
      <div className='share-of-empty-state'>
        <div className='doghnut'>
          <DoghnutEmptyState />
        </div>
        <div>
          <p className='skeleton-paragraph__small'>
            <Skeleton paragraph active />
          </p>
          <p className='skeleton-paragraph__large'>
            <Skeleton paragraph active />
          </p>
          <p className='skeleton-paragraph__small'>
            <Skeleton paragraph active />
          </p>
          <p className='skeleton-paragraph__large'>
            <Skeleton paragraph active />
          </p>
        </div>
      </div>
    </>
  )
}
