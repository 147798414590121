import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import { Input, Form } from 'antd'

import { store } from 'store'
import dayjs from 'dayjs'
import { Whiteboard } from 'models/models'
import Table, { IColumnType } from 'components/common/Table/Table'
import styles from './InvestigateWhiteboardListView.module.scss'
import TagManager from 'react-gtm-module'

const TABLE_DATE_FORMAT = 'MMM DD, YYYY'

type ModalProps = {
  close: () => void
  createWhiteboard: (name: string, description: string) => void
  open: boolean
}

type FieldType = {
  name?: string
  description?: string
}

const NewWhiteboardModel = observer((props: ModalProps) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const { open, close, createWhiteboard } = props

  return (
    <Modal centered open={open} onCancel={close} title={'New Whiteboard'} maskClosable={false} footer={null}>
      <Form
        name='whiteboardCreate'
        layout='vertical'
        onFinish={() => createWhiteboard(name, description)}
        onFinishFailed={() => {}}
        autoComplete='off'>
        <Form.Item<FieldType>
          label='Enter Whiteboard Name'
          name='name'
          rules={[{ required: true, message: 'Please provide a name for the whiteboard' }]}>
          <Input onChange={(event: any) => setName(event.target.value)} />
        </Form.Item>

        <Form.Item<FieldType>
          label='Enter whiteboard description'
          name='description'
          rules={[{ required: true, message: 'Please provide a description for the whiteboard' }]}>
          <Input onChange={(event: any) => setDescription(event.target.value)} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <button className={styles.whiteboard_button_create}>Create Whiteboard</button>
        </Form.Item>
      </Form>
    </Modal>
  )
})

const InvesigateWhiteboardListView = observer(() => {
  const { whiteboardStore } = store
  const navigate = useNavigate()
  const [isCreating, setIsCreating] = useState(false)

  const selectWhiteboardHandler = (id: string) => {
    navigate(`/investigate/whiteboards/${id}`)
  }

  const columns: IColumnType<Whiteboard>[] = [
    {
      key: 'name',
      title: 'Name',
      width: 310,
      render: (column, item) => {
        const { name } = item
        return (
          <div>
            <p>{name}</p>
          </div>
        )
      },
    },
    {
      key: 'description',
      title: 'Description',
      width: 310,
      render: (column, item) => {
        const { description } = item
        return (
          <div>
            <p>{description}</p>
          </div>
        )
      },
    },
    {
      key: 'createdOn',
      title: 'Created on',
      width: 150,
      render: (column, item) => {
        const { created } = item
        return <span>{dayjs(created).format(TABLE_DATE_FORMAT)}</span>
      },
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 150,
      render: (column, item) => {
        return (
          <button className={styles.whiteboard_button} onClick={() => selectWhiteboardHandler(item.id)}>
            Open
          </button>
        )
      },
    },
  ]

  useEffect(() => {
    whiteboardStore.getWhiteboards()
  }, [])

  const createWhiteboard = async (name: string, description: string) => {
    const { userStore } = store
    const { userId, userName, tenantId, roleId } = userStore
    const whiteboard: Whiteboard = await whiteboardStore.createNewWhiteboard(name, description)
    if (whiteboard) {
      selectWhiteboardHandler(whiteboard.id)
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'create_whiteboard',
        //@ts-ignore
        user_id: userId,
        user_name: userName,
        tenantId: tenantId,
        roleId: roleId,
        entityName: name,
      },
    })
  }

  return (
    <div className={styles.whiteboard_container}>
      <button className={styles.whiteboard_button} onClick={() => setIsCreating(true)}>
        + New Whiteboard
      </button>
      {isCreating && (
        <NewWhiteboardModel open={isCreating} close={() => setIsCreating(false)} createWhiteboard={createWhiteboard} />
      )}
      <Table
        data={whiteboardStore.whiteboards}
        columns={columns}
        onRowClick={() => {
          console.log('action')
        }}
      />
    </div>
  )
})

export default InvesigateWhiteboardListView
