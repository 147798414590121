import axios from 'axios'

import { ROUTES } from 'settings/settings'

interface Props {
  id?: string
  recipients?: any
  brief?: string
}

export const sendSpotlight = async (spotlight: Props) => {
  let url = process.env.REACT_APP_API + ROUTES.watchlist

  if (!!spotlight.id) url = `${process.env.REACT_APP_API}${ROUTES.spotlight}/${spotlight.id}/send`

  const { data } = await axios.post(url, spotlight)

  return data
}
