import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'

import { SnippetFilterDaysInfo } from 'models/models'
import DataFetchIcon from 'assets/images/fetching-page-icon.svg'
import './DataFetching.scss'

interface Props {
  title?: string
  page: 'fetch' | 'notFound' | 'searchNotFound'
  daysFilters?: SnippetFilterDaysInfo
  showDescription?: boolean
  className?: string
}
export const DataFetching = observer(({ title, page, daysFilters, showDescription = true, className }: Props) => {
  return (
    <div className={`data-fetching ${className ? className : ''}`}>
      <div className='data-fetching__title-icon'>{['fetch', 'searchNotFound'].includes(page) && <h2>{title}</h2>}</div>
      <div className='data-fetching__main-content'>
        <img src={DataFetchIcon} alt='' />
        {page === 'fetch' ? (
          <>
            <h3>Data Fetching in Progress</h3>
            <p>Pendulum engines are running to fetch the useful data to you. This usually takes 60 mins.</p>
          </>
        ) : page === 'searchNotFound' ? (
          <>
            <h3>No Data Found</h3>
            <p>None of your specified creators are part of the Pendulum pipeline.</p>
          </>
        ) : (
          <>
            {daysFilters?.custom.isApplied ? (
              <h3>{`No Data Found for ${dayjs(daysFilters.custom.dateRange[0]).format('MMM DD, YYYY')} - ${dayjs(
                daysFilters?.custom.dateRange[1],
              ).format('MMM DD, YYYY')}`}</h3>
            ) : daysFilters?.noOfDays && daysFilters?.noOfDays > -1 ? (
              <h3>{`No Data Found ${
                daysFilters?.noOfDays === 1 ? 'for yesterday' : `for last ${daysFilters?.noOfDays} days`
              }`}</h3>
            ) : (
              <h3>{`No Data Found`}</h3>
            )}
            {}
            {showDescription ? (
              <p>
                Pendulum updates data every 12 hours. You can also edit this narrative to refine scope of this
                narrative.
              </p>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
})
