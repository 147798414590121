import DataTranslateIcon from 'assets/images/data-translating-icon.svg'
import { observer } from 'mobx-react-lite'
import './DataFetching.scss'

export const DataTranslating = observer(() => {
  return (
    <div className='data-fetching'>
      <div className='data-fetching__main-content'>
        <img src={DataTranslateIcon} alt='' />
        <h3>Translating ...</h3>
        <p>Pendulum is translating your feed. This will take a few more seconds. Thank you for your patience!</p>
      </div>
    </div>
  )
})
