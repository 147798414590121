import { Form, Input, Spin } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'

import { ChannelDataType } from 'pages/Vectors/CreateEditWatchListStepper/types'

const CreateEditWatchlistHeader = ({
  id,
  addedURLs,
  activeStep,
  validateURLLoading,
  verifyAndGetPlatform,
}: {
  id: string
  activeStep: number
  validateURLLoading: boolean
  addedURLs: ChannelDataType[]
  verifyAndGetPlatform: (val: string) => void
}) => {
  const totalURLs = addedURLs?.length
  const totalUnRegisteredURLs = addedURLs?.filter((item) => !item.registered)?.length
  return (
    <div className='watchlist-builder__section--center__header'>
      {activeStep === 2 ? (
        <Form.Item name='url' shouldUpdate={(prev, current) => prev.field !== current.field}>
          <Input
            key={'watchlist-header-url-input'}
            size='large'
            placeholder='Paste the link here...'
            prefix={<LinkOutlined />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                verifyAndGetPlatform((e.target as HTMLInputElement).value)
              }
            }}
          />
        </Form.Item>
      ) : (
        <>
          {!!totalUnRegisteredURLs && (
            <div className='edit-url-modal__info'>
              <div className='edit-url-modal__info__icon'>
                <InfoIcon />
              </div>
              <div className='edit-url-modal__info__text'>
                <div className='edit-url-modal__info__text__title'>
                  {totalURLs - totalUnRegisteredURLs} out of {totalURLs} accounts were found in the database
                </div>
                <div className='edit-url-modal__info__text__description'>
                  {totalURLs - totalUnRegisteredURLs} of the {totalURLs} selected accounts already exist in our
                  database. The remaining {totalUnRegisteredURLs} accounts will be added to Pendulum’s data pipeline and
                  processed within 6 hours after publishing the watchlist.
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <Spin spinning={validateURLLoading} />
    </div>
  )
}

export default CreateEditWatchlistHeader
