import axios from 'axios'

import { ROUTES } from 'settings/settings'

export async function updateUserRole({ username, newRoleId }: { username: string; newRoleId: string }) {
  try {
    const { data } = await axios.put(`${process.env.REACT_APP_API}/${ROUTES.user}/${username}`, {
      role_id: newRoleId,
    })
    return { data }
  } catch (error: any) {
    throw error
  }
}
