import React, { useMemo } from 'react'
import classNames from 'classnames'
import profileImage from 'assets/images/LogoiconMobile.svg'
import { UtilService } from 'services/Util/Util'
import { SharedUsersObj } from 'store/report/valueTypes'
import styles from './ShareWIth.module.scss'

type ShareWithProps = {
  data?: SharedUsersObj[]
  className?: string
  maxShow?: number
}

function ShareWith(props: ShareWithProps) {
  const { data = [], className, maxShow = 6 } = props
  const filterData = useMemo(() => {
    if (data.length && data.length > maxShow) {
      return data.filter((res, index) => index < maxShow - 1)
    }
    return data
  }, [data, maxShow])

  return (
    <div className={classNames(styles.share_with_container, className)}>
      {filterData.map((share) => (
        <div
          key={share.user_id}
          className={classNames(styles.swc_image_container, { [styles.swc_add_margin]: data.length > maxShow - 1 })}>
          <img
            src={UtilService.isImageUrl(share.image_url) ? share.image_url : profileImage}
            alt='danger'
            className={styles.swc_ic_image}
          />
        </div>
      ))}
      {data.length > maxShow && (
        <div className={classNames(styles.swc_image_container, styles.swc_count_container, styles.swc_add_margin)}>
          <p className={styles.swc_count}>+{data.length - (maxShow - 1)}</p>
        </div>
      )}
    </div>
  )
}

export default ShareWith
