import { observer } from 'mobx-react-lite'
import { Menu } from 'antd'
import { millify } from 'millify'

import { ICommunityLight, ICreator } from 'models/models'

import UserAvatar from 'assets/images/LogoiconMobile.svg'

import './CreatorInfo.scss'

interface Props {
  creator: ICreator
  allCommunities: ICommunityLight[]
  watchlists: string[]
  setOpenCreateWatchlist: (state: boolean) => void
  setOpenUrl: (val: string) => void
}
export const CreatorInfo = observer(
  ({ creator, allCommunities, watchlists, setOpenCreateWatchlist, setOpenUrl }: Props) => {
    const communities: string[] = []
    const onWatchlistCreateClick = () => {
      setOpenCreateWatchlist(true)
    }

    creator?.communities?.forEach((community) => {
      const comm = allCommunities?.find((comm) => comm.id === community)
      if (comm?.name) communities.push(comm?.name)
    })

    return (
      <Menu className='creator-info'>
        <Menu.Item>
          <div className='creator-info__heading'>
            <div className='creator-info__heading__user'>
              <img
                className='creator-info__heading__user-avatar'
                src={creator.channel_thumb_url ?? UserAvatar}
                alt=''
                onError={(e: any) => {
                  e.target.src = UserAvatar
                }}
              />
              <div className='creator-info__heading__user-title'>
                <div
                  className='creator-info__heading__user-title__link'
                  onClick={() => {
                    setOpenUrl(creator.channel_url)
                  }}>
                  <span>{creator.channel_title || creator.channel_name || 'Title N/A'}</span>
                </div>
              </div>
            </div>
          </div>
          <div className='creator-info__following'>
            <span>
              <b>{millify(creator.followers_count || 0)}</b> Followers
            </span>
          </div>
          <div className='creator-info__communities-watchlists'>
            <span>
              <b style={{ fontWeight: 700 }}>Communities: </b>{' '}
              {communities?.length > 0 ? communities.join(' | ') : 'None'}
            </span>
          </div>
          <div className='creator-info__communities-watchlists creator-info__communities-watchlists--no-margin'>
            <span>
              <b style={{ fontWeight: 700 }}>Watchlists: </b> {watchlists?.length > 0 ? watchlists.join(' | ') : 'None'}
            </span>
          </div>
          <button className='creator-info__heading__watchlist-creator-btn' onClick={onWatchlistCreateClick}>
            Add to Watchlist
          </button>
        </Menu.Item>
      </Menu>
    )
  },
)
