import axios from 'axios'

import { ROUTES } from 'settings/settings'
interface IData {
  status: 'activate' | 'deactivate'
  ids: string[]
}

export async function toggleFlagStatus(payload: IData) {
  return await axios.post(ROUTES.flagsStatus, payload)
}
