import axios from 'axios'

import { SnippetFilterPayload } from 'models/models'
import { ROUTES } from 'settings/settings'

interface Props extends SnippetFilterPayload {
  copilot_text: string
}

export const evaluateNarrative = async ({ copilot_text, query, sort, conditions }: Props) => {
  const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`
  const queryParams = `q=${q}&sort=${sort}`
  let endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.evaluation}?${queryParams}`

  const { data } = await axios.post(endpoint, { copilot_text, conditions })

  return data
}
