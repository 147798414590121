import React from 'react'
import classNames from 'classnames'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import styles from './Table.module.scss'

export type IColumnType<T> = {
  key: string
  title: string | (() => any)
  width?: number
  align?: 'left' | 'center' | 'right'
  elementTitle?: string
  render?: (column: IColumnType<T>, item: T) => any
  onCell?: (record: T) => React.TdHTMLAttributes<HTMLTableDataCellElement>
}

export type ExpandableType<T> = {
  expandedRowRender?: (record: T) => any
  rowExpandable?: (record: T) => any
  setExpandedRows?: (record: T) => any
  expandedRowsCondition?: (record: T) => boolean
}

type TableProps<T> = {
  data: T[]
  columns: IColumnType<T>[]
  className?: string
  onRowClick?: (data: T) => void
  disabledRow?: (data: T) => boolean
  expandable?: ExpandableType<T>
  selectedRows?: string[]
}

function Table<T>(props: TableProps<T>): JSX.Element {
  const { data, columns, expandable = {}, className, onRowClick, disabledRow, selectedRows } = props
  return (
    <table className={classNames(styles.table_wapper, className)}>
      <thead className={styles.table_header}>
        <TableHeader expandable={expandable} columns={columns} />
      </thead>
      <tbody className={styles.table_body}>
        <TableRow
          data={data}
          columns={columns}
          expandable={expandable}
          onRowClick={onRowClick}
          disabledRow={disabledRow}
          selectedRows={selectedRows}
        />
      </tbody>
    </table>
  )
}

export default Table
