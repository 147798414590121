import { useEffect, useState } from 'react'
import { Modal, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import ReactECharts from 'echarts-for-react'
import { store } from 'store'

import { evaluateNarrative } from 'api/defineNarrative/evaluateNarrative.api'
import { openError } from 'services/Util/Error/openError'

import './NarrativeEvaluator.scss'

interface Props {
  isModalOpen: boolean
  setIsModalOpen: (status: boolean) => void
}
export const NarrativeEvaluator = observer(({ isModalOpen, setIsModalOpen }: Props) => {
  const [evaluationResult, setEvaluationResult] = useState<number>(0)
  const [spinning, setSpinning] = useState<boolean>(false)
  const { vectorsStore, defineNarrativeStore } = store

  const { formData } = defineNarrativeStore
  const { getSnippetFilterPayload } = vectorsStore

  useEffect(() => {
    if (isModalOpen) {
      setSpinning(true)
      getSnippetFilterPayload().then((payload) => {
        evaluateNarrative({
          ...payload,
          copilot_text: formData['booleanSearch'],
        })
          .then((response) => {
            setEvaluationResult(Number(Number(response?.evaluation_score || 0).toFixed(2)) || 0)
            setSpinning(false)
          })
          .catch((error: any) => {
            setSpinning(false)
            const response = error.response
            openError({
              requestId: response?.data?.request_id || '',
              statusCode: response?.status || 400,
              message: response?.data?.err_msg || '',
            })
          })
      })
    }
  }, [isModalOpen])

  return (
    <Modal
      className='narrative-evaluator-modal'
      open={isModalOpen}
      title={<span className='narrative-evaluator-modal__title'> Match Quality</span>}
      footer={
        <button
          className='narrative-evaluator-modal__footer--button'
          onClick={() => {
            setIsModalOpen(false)
            setEvaluationResult(0)
          }}
          disabled={spinning}>
          Done
        </button>
      }
      onCancel={() => {
        setIsModalOpen(false)
        setEvaluationResult(0)
      }}
      destroyOnClose>
      <Spin spinning={spinning}>
        <ReactECharts
          option={{
            series: [
              {
                type: 'gauge',
                center: ['45%', '40%'],
                progress: {
                  show: true,
                  width: 12,
                  itemStyle: {
                    color: '#cca55a',
                  },
                },
                axisLine: {
                  lineStyle: {
                    width: 12,
                    color: [[1, '#e7e8f8']],
                  },
                },
                axisTick: {
                  show: false,
                },
                splitLine: {
                  length: 10,
                  lineStyle: {
                    width: 1.5,
                    color: '#000',
                  },
                },
                axisLabel: {
                  distance: 15,
                  color: '#000',
                  fontSize: 15,
                  fontWeight: 400,
                  fontFamily: 'Roboto',
                },
                anchor: {
                  show: true,
                  showAbove: true,
                  size: 20,
                  itemStyle: {
                    borderWidth: 5,
                    color: '#8F733F',
                    borderColor: '#cca55a',
                  },
                },
                title: {
                  show: false,
                },
                pointer: {
                  itemStyle: {
                    color: '#cca55a',
                  },
                },
                detail: {
                  valueAnimation: true,
                  fontSize: 60,
                  offsetCenter: [0, '70%'],
                },
                data: [
                  {
                    value: evaluationResult,
                  },
                ],
              },
            ],
          }}
          style={{ height: '600px', width: '500px' }}
        />
      </Spin>
    </Modal>
  )
})
