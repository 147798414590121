import { Divider, Form, Input, Spin, message } from 'antd'
import { Button } from 'components/common/Button/Button'
import { observer } from 'mobx-react-lite'
import { Auth } from 'aws-amplify'
import { useState } from 'react'

import { store } from 'store'

import { Heading } from 'components/common/Heading/Heading'
import { Card } from 'components/common/Card/Card'
import './Profile.scss'

export const Profile = observer((): JSX.Element => {
  const {
    userStore: { userName, userEmail, given_name, family_name },
  } = store

  const [loading, setLoading] = useState<boolean>(false)
  const [changePasswordIsVisible, setChangePasswordIsVisible] = useState<boolean>(false)

  async function changePassword(values: any) {
    const { oldPassword, newPassword }: { oldPassword: string; newPassword: string } = values

    try {
      setLoading(true)

      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)

      const successMessage = () => {
        message.success('Password changed successfully', 4)
      }

      successMessage()
      setChangePasswordIsVisible(false)
      setLoading(false)
    } catch (error: any) {
      const errorMessage = () => {
        message.error(error.message, 4)
      }
      errorMessage()

      setLoading(false)
    }
  }

  return (
    <div className='c-profile'>
      <Card variant='1' className='u-p-t-32'>
        <Spin size='large' spinning={loading}>
          <Heading level='1' variant='2' className='u-m-b-8'>
            Your Profile
          </Heading>

          <Divider plain></Divider>

          <Heading level='4' variant='5' className='u-m-t-32 u-m-b-24'>
            Account details
          </Heading>

          <Form
            layout='vertical'
            onFinish={changePassword}
            initialValues={{ userName, userEmail, given_name, family_name }}>
            <Form.Item label='Name' name='userName'>
              <Input disabled />
            </Form.Item>

            <Form.Item label='Email' name='userEmail'>
              <Input disabled />
            </Form.Item>
            <Form.Item label='Given Name' name='given_name'>
              <Input disabled />
            </Form.Item>
            <Form.Item label='Family Name' name='family_name'>
              <Input disabled />
            </Form.Item>

            {changePasswordIsVisible && (
              <>
                <Form.Item label='Old Password' name='oldPassword'>
                  <Input.Password />
                </Form.Item>

                <Form.Item label='New Password' name='newPassword'>
                  <Input.Password />
                </Form.Item>
              </>
            )}

            {!changePasswordIsVisible && (
              <Form.Item>
                <Button type='secondary' onClick={() => setChangePasswordIsVisible(true)}>
                  Change password
                </Button>
              </Form.Item>
            )}

            {changePasswordIsVisible && (
              <Form.Item>
                <Button htmlType='submit' type='secondary'>
                  Save changes
                </Button>
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Card>
    </div>
  )
})
