import { Skeleton } from 'antd'
import issueImage from 'assets/images/icons/dashboard/issue.png'
import millify from 'millify'
import Summary from './Summary'
import { AssetListObjType } from 'store/asset/types'
import { ReactComponent as NegativeSentiment } from 'assets/images/sentiment-negative-icon.svg'
import { ReactComponent as PositiveSentiment } from 'assets/images/sentiment-positive-icon.svg'
import './BrandDiscussions.scss'

export default function IssueCard({
  emptyState = false,
  item,
  onSelectItem,
}: {
  emptyState?: boolean
  item?: (AssetListObjType & { sentiment: any }) | undefined
  onSelectItem: (item: AssetListObjType) => void
}) {
  if (emptyState || !item) {
    return (
      <div className='followed-issue-card'>
        <Skeleton.Image className='skeleton-image' active />
        <div className='summary'>
          <h6 className='skeleton-title'>
            <Skeleton paragraph active />
          </h6>
          <p className='skeleton-description'>
            <Skeleton paragraph active />
          </p>
        </div>
        <div className='stats'>
          <div className='item'>
            <h6>Mentions</h6>
            <p className='skeleton-stats-item'>
              <Skeleton paragraph active />
            </p>
          </div>
          <div className='item'>
            <h6>Impressions</h6>
            <p className='skeleton-stats-item'>
              <Skeleton paragraph active />
            </p>
          </div>
          <div className='item'>
            <h6>Engagment</h6>
            <p className='skeleton-stats-item'>
              <Skeleton paragraph active />
            </p>
          </div>
          <div className='item'>
            <h6>Sentiment</h6>
            <p className='skeleton-stats-item'>
              <Skeleton paragraph active />
            </p>
          </div>
        </div>
      </div>
    )
  }

  const totalSentimentScore =
    item.metric.sentiment.positive + item.metric.sentiment.neutral + item.metric.sentiment.negative

  const positivePercentage =
    totalSentimentScore === 0 ? 0 : (item.metric.sentiment.positive / totalSentimentScore) * 100
  const negativePercentage =
    totalSentimentScore === 0 ? 0 : (item.metric.sentiment.negative / totalSentimentScore) * 100

  return (
    <div className='followed-issue-card' onClick={() => onSelectItem(item)}>
      <div className='img-wrapper'>
        <img src={issueImage} alt='Issue' className='img' />
      </div>
      <div className='summary'>
        <h6 className='title'>{item.name}</h6>
        <Summary item={item} />
      </div>
      <div className='stats'>
        <div className='item'>
          <h6>Mentions</h6>
          <p>{millify(item.metric.views)}</p>
        </div>
        <div className='item'>
          <h6>Impressions</h6>
          <p>{millify(item.metric.impressions)}</p>
        </div>
        <div className='item'>
          <h6>Engagment</h6>
          <p>{millify(item.metric.engagement)}</p>
        </div>
        <div className='item'>
          <h6>Sentiment</h6>
          <p className='sentiment'>
            <span className='positive'>
              <PositiveSentiment width={16} height={16} />
              {positivePercentage.toFixed(0)}%
            </span>
            <span className='negative'>
              <NegativeSentiment width={16} height={16} />
              {negativePercentage.toFixed(0)}%
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}
