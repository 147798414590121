import Avatar from 'assets/images/LogoiconMobile.svg'
import { ICreatorData, sourcePlatform } from 'models/models'
import { PLATFORM_ICONS_URLS } from 'settings/settings'

import './FilterChips.scss'

interface Props {
  creator: ICreatorData
}

export const CreatorInfo = (props: Props) => {
  const { creator } = props
  return (
    <div className='filters-chips_creator_info__container'>
      <div className='filters-chips_creator_info__container2'>
        <img
          className='filters-chips_creator_info__avatar'
          src={creator.channel_thumb_url || Avatar}
          alt='user'
          onError={(e: any) => {
            e.target.src = Avatar
          }}
        />
        <div className='filters-chips_creator_info__container3'>
          <span className='power-insights-creator-info__name'>{creator.channel_title || ''}</span>
          <span className='power-insights-creator-info__link'>{creator.channel_url || ''}</span>
        </div>
      </div>
      <img
        className='filters-chips_creator_info__platform'
        src={PLATFORM_ICONS_URLS[creator.platform as Lowercase<sourcePlatform>]}
        alt='platform'></img>
    </div>
  )
}
