/* eslint-disable prettier/prettier */
import dayjs from 'dayjs'
import { store } from 'store'
import utc from 'dayjs/plugin/utc'
import classNames from 'classnames'
import { Auth, Hub } from 'aws-amplify'
import TagManager from 'react-gtm-module'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import 'antd/dist/antd.min.css' // don't move it from here or the css will break. I need to investicate why

import Toaster from 'components/common/Toaster/Toaster'
import { Agreement } from 'components/Agreement/Agreement'
import PendulumPulse from 'pages/PendulumPulse/PendulumPulse'
import { Tenants } from 'components/Settings/Tenants/Tenants'
import { PrivateRoute } from 'components/Auth/PrivateRoute/PrivateRoute'
import { AuthListener } from 'components/Auth/AuthListener/AuthListener'
import { LeftNavigation } from 'components/LeftNavigation/LeftNavigation'
import { ExportList } from 'components/common/ExportDownloads/ExportList'
import BrandDashboard from 'components/Asset/BrandsDashboard/BrandDashboard'
import { TenantList } from 'components/Settings/Tenants/TenantList/TenantList'
import { TenantDetails } from 'components/Settings/Tenants/TenantDetails/TenantDetails'
import { AnticipatoryFeed } from 'components/AnticipatoryIntelligence/Feed/AnticipatoryFeed'

import { TermsPage } from 'pages/Policies/Terms.page'
import { SignInPage } from 'pages/Signin/SignIn.page'
import Flags from 'pages/Flags/Flags'
import Asset from 'pages/Asset/Asset'
import { PrivacyPage } from 'pages/Policies/Privacy.page'
import { FeedbackPage } from 'pages/Policies/Feedback.page'
import { ResetPassPage } from 'pages/Signin/ResetPass.page'
import { AnalyzePage } from 'pages/Analyze/Analyze.page'
import { Reports } from 'pages/Reports/Reports.page'
import { UsersPage } from 'pages/Settings/Users.page'
import { ApiDocsPage } from 'pages/Settings/ApiDocs.page'
import { ProfilePage } from 'pages/Settings/Profile.page'
import { SettingsPage } from 'pages/Settings/Settings.page'
import { SpotlightPage } from 'pages/Settings/Spotlight.page'
import BookmarkListing from 'pages/Investigate/BookmarkListing'
import VectorsLibrary from 'pages/VectorsLibrary/VectorsLibrary'
import { InvestigatePage } from 'pages/Investigate/Investigate.page'
import VectorsMainPage from 'pages/Vectors/VectorsMainPage/VectorsMainPage'
import OutletContainer from 'components/common/OutletContainer/OutletContainer'
import CombineDetailsView from 'pages/Vectors/CombineDetailsView/CombineDetailsView'
import ReportDetailsPanel from 'pages/Investigate/ReportDetailsPanel/ReportDetailsPanel'
import { WelcomeScreen } from 'components/AnticipatoryIntelligence/WelcomeScreen/WelcomeScreen'
import { AgreementAdminModal } from 'components/Agreement/AgreementAdminModal/AgreementAdminModal'
import { AnticipatoryIntelligence } from 'components/AnticipatoryIntelligence/AnticipatoryIntelligence'
import { AgreementWelcomeModal } from 'components/Agreement/AgreementWelcomeModal/AgreementWelcomeModal'
import { AnticipatoryIntelligencePage } from 'pages/AnticipatoryIntelligence/AnticipatoryIntelligence.page'
import CreateNarrativeDetailsView from 'pages/Vectors/CreateNarrativeDetailsView/CreateNarrativeDetailsView'
import CreateWatchlistDetailsView from 'pages/Vectors/CreateWatchlistDetailsView/CreateWatchlistDetailsView'
import InvestigateWhiteboardPage from 'pages/Investigate/InvestigateWhiteboardPage/InvestigateWhiteboard.page'
import CreateNarrativeAndWatchList from 'pages/Vectors/CreateNarrativeAndWatchList/CreateNarrativeAndWatchList'
import InvesigateReportsDetailPage from 'pages/Investigate/InvesigateReportsDetailPage/InvesigateReportsDetail.Page'
import InvestigateReportsMainViewPage from 'pages/Investigate/InvestigateReportsMainViewPage/InvestigateReportsMainView.page'
import InvestigateWhiteboardListView from 'pages/Investigate/InvestigateWhiteboardListView/InvestigateWhiteboardListView.page'

import { UtilService } from 'services/Util/Util'
import { useUserRole } from 'hooks/UserRoleContext'
import { skipRoles, checkSessionValidity } from 'utils/helper'
import { getAgreementStatus } from 'api/agreement/getAgreementStatus.api'
import powerInsightsCommonRoutes from 'components/powerInsightsCommonRoutes'
import VectorsDashboard from 'pages/Vectors/VectorsDashboard/VectorsDashboard'
import CreateReport from 'pages/Investigate/CreateReport/CreateReport'
import CreateEditWatchListStepper from 'pages/Vectors/CreateEditWatchListStepper/CreateEditWatchListStepper'
import EditReport from 'pages/Investigate/CreateReport/EditReport'

import './App.css'
import 'styles/index.scss'

dayjs.extend(utc)

const pendingCalls = new Map<string, number>()

const getBaseUrl = (url: string) => url.split('?')[0]

axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const baseUrl = getBaseUrl(config.url || '')
    const url = config.url?.split('?')
    const query = url && url.length > 1 ? url[1] : ''

    pendingCalls.set(baseUrl, (pendingCalls.get(baseUrl) || 0) + 1)
    // @ts-ignore
    store.loaderStore.setIsLoading({ route: baseUrl, isLoading: true, query })

    const token = await UtilService.getAuthToken()
    const isExpiredToken = await checkSessionValidity()

    if (!isExpiredToken && token) {
      await Auth.signOut()
      sessionStorage.clear()
      localStorage.clear()
      const count = pendingCalls.get(baseUrl) || 0
      if (count > 1) {
        pendingCalls.set(baseUrl, count - 1)
      } else {
        pendingCalls.delete(baseUrl)
      }
      // @ts-ignore
      store.loaderStore.setIsLoading({ route: baseUrl, isLoading: false, query })
      const errorResponse = {
        requestId: '',
        statusCode: 401,
        title: 'Session Expired',
        data: { err_msg: 'Session Expired. Please log in again.' },
      }
      return Promise.reject({ response: errorResponse })
    }

    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  (error) => {
    const baseUrl = getBaseUrl(error.config?.url || '')
    const url = error.config?.url?.split('?')
    const query = url && url.length > 1 ? url[1] : ''

    const count = pendingCalls.get(baseUrl) || 0
    if (count > 1) {
      pendingCalls.set(baseUrl, count - 1)
    } else {
      pendingCalls.delete(baseUrl)
    }
    // @ts-ignore
    store.loaderStore.setIsLoading({ route: baseUrl, isLoading: false, query })

    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    const baseUrl = getBaseUrl(response.config?.url || '')
    const url = response.config?.url?.split('?')
    const query = url && url.length > 1 ? url[1] : ''

    const count = pendingCalls.get(baseUrl) || 0
    if (count > 1) {
      pendingCalls.set(baseUrl, count - 1)
    } else {
      pendingCalls.delete(baseUrl)
    }

    const isStillLoading = pendingCalls.has(baseUrl)

    if (baseUrl?.includes('labelVideo')) {
      store.loaderStore.setIsLoading({
        // @ts-ignore
        route: baseUrl,
        isLoading: isStillLoading,
        extra: response?.config?.data,
      })
    } else {
      store.loaderStore.setIsLoading({
        // @ts-ignore
        route: baseUrl,
        isLoading: isStillLoading,
        query,
      })
    }

    return response
  },
  (error) => {
    const baseUrl = getBaseUrl(error.config?.url || '')
    const url = error.config?.url?.split('?')
    const query = url && url.length > 1 ? url[1] : ''

    const count = pendingCalls.get(baseUrl) || 0
    if (count > 1) {
      pendingCalls.set(baseUrl, count - 1)
    } else {
      pendingCalls.delete(baseUrl)
    }

    const isStillLoading = pendingCalls.has(baseUrl)

    store.loaderStore.setIsLoading({
      // @ts-ignore
      route: baseUrl,
      isLoading: isStillLoading,
      query,
    })

    return Promise.reject(error)
  },
)

const App = observer((): JSX.Element => {
  const { userStore, toasterStore, tenantsStore, loaderStore } = store
  const { userName, currentRole } = userStore
  const { showToast } = toasterStore

  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore

  const { setUserRole } = useUserRole()

  const [openAgreementWelcomeModal, setOpenAgreementWelcomeModal] = useState(false)
  const [openAgreementModal, setOpenAgreementModal] = useState<'admin' | 'user' | 'close'>('close')

  const [displayRouter, setDisplayRouter] = useState<boolean>(false)

  const createGTMLogInEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'user_login',
        //@ts-ignore
        user_id: userStore.userId,
        user_name: userStore.userName,
        tenantId: userStore.tenantId,
        roleId: userStore.roleId,
      },
    })
  }

  const checkTOSStatus = async (user: string) => {
    try {
      let requireVerification = localStorage.getItem('user-tos-status') || ''

      if (!requireVerification?.length || !['non-verified', 'verified'].includes(requireVerification)) {
        const response = await getAgreementStatus()
        if (!response?.require_tos) localStorage.setItem('user-tos-status', 'verified')
        else {
          localStorage.setItem('user-tos-status', 'non-verified')
          localStorage.setItem('user-tos-assigned-admins', JSON.stringify({ admins: response?.assigned_admins || [] }))
        }
        requireVerification = localStorage.getItem('user-tos-status') || ''
      }
      if (requireVerification === 'non-verified') {
        if (currentRole === 'ADMIN') setOpenAgreementModal('admin')
        else setOpenAgreementModal('user')
      } else setOpenAgreementModal('close')
    } catch (error) {
      console.log(error)
    }
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setUserRole(user?.attributes['custom:role_id'])
      userStore.checkAndSetCurrentRole(user?.attributes['custom:role_id'])

      const userName = user.username
      const userEmail = user.attributes?.email
      const tenantId = user?.attributes['custom:tenant_id']
      const userId = user.attributes['custom:user_id']
      const roleId = user.attributes['custom:role_id']
      const subId = user.attributes?.sub
      const given_name = user.attributes?.given_name
      const family_name = user.attributes?.family_name
      const authToken = user.signInUserSession.idToken.jwtToken
      userStore.setUser({ userName, userEmail, given_name, family_name, tenantId, userId, roleId, subId, authToken })

      createGTMLogInEvent()
    } catch (error) {
      userStore.setUser({
        userName: null,
        userEmail: null,
        given_name: null,
        family_name: null,
        tenantId: null,
        userId: null,
        roleId: null,
        subId: null,
        authToken: null,
      })
    }

    setDisplayRouter(true)
  }

  const updateUserStore = (authData: any) => {
    if (authData) {
      const userName = authData.username
      const userEmail = authData.attributes.email
      const tenantId = authData.attributes['custom:tenant_id']
      const userId = authData.attributes['custom:user_id']
      const roleId = authData.attributes['custom:role_id']
      const subId = authData.attributes.sub
      const given_name = authData.attributes.given_name
      const family_name = authData.attributes.family_name
      userStore.setUser({ userName, userEmail, family_name, given_name, tenantId, userId, roleId, subId })
    }
  }

  const nonAdminRoles = ['USER', 'VIEWER', 'SCORECARD_USER', 'DEPLOYMENT_STRATEGIST', 'BUILDER', 'ANALYST']

  useEffect(() => {
    checkUser()

    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
          checkUser() // Re-check auth state if signed in
          updateUserStore(data.payload.data)
          break
        case 'signOut':
          break
        default:
          break
      }
    }

    Hub.listen('auth', listener)

    // Cleanup
    return () => Hub.remove('auth', listener)
  }, [])

  useEffect(() => {
    if (userName) {
      checkTOSStatus(userName)
    }
  }, [userName, localStorage.getItem('user-tos-status')])

  return (
    <>
      <AuthListener />

      {displayRouter && (
        <>
          <Router>
            <div className={classNames('content-container', 'd-flex')}>
              {userName && <LeftNavigation />}
              {showToast ? <Toaster /> : null}
              <div className={classNames('content-wrapper', 'left-margin')}>
                <Routes>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        element={
                          currentRole === 'SCORECARD_USER' ? (
                            <Navigate to='/anticipatory-intelligence/welcome' replace />
                          ) : (
                            <Navigate to='/assets' replace />
                          )
                        }
                        allowedRoles={skipRoles([])}
                      />
                    }
                  />

                  <Route
                    path='/signin'
                    element={
                      userName ? (
                        currentRole === 'SCORECARD_USER' ? (
                          <Navigate replace to='/anticipatory-intelligence/welcome' />
                        ) : (
                          <Navigate replace to={'/assets'} />
                        )
                      ) : (
                        <SignInPage />
                      )
                    }
                  />

                  <Route path='/' element={!userName && <Navigate replace to='/signin' />} />

                  <Route path='/reset' element={!userName ? <ResetPassPage /> : <Navigate replace to='/assets' />} />

                  <Route
                    path='/settings/*'
                    element={<PrivateRoute allowedRoles={skipRoles([])} element={<SettingsPage />} />}>
                    <Route
                      path='profile'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<ProfilePage />} />}
                    />
                    <Route
                      path='users'
                      element={<PrivateRoute allowedRoles={skipRoles(nonAdminRoles)} element={<UsersPage />} />}
                    />
                    <Route
                      path='spotlight'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<SpotlightPage />} />}
                    />
                    <Route
                      path='api-docs'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<ApiDocsPage />} />}
                    />

                    <Route
                      path='tenants/*'
                      element={<PrivateRoute allowedRoles={skipRoles(nonAdminRoles)} element={<Tenants />} />}>
                      <Route
                        path='list'
                        element={<PrivateRoute allowedRoles={skipRoles(nonAdminRoles)} element={<TenantList />} />}
                      />
                      <Route
                        path='configuration/:id'
                        element={<PrivateRoute allowedRoles={skipRoles(nonAdminRoles)} element={<TenantDetails />} />}
                      />
                    </Route>
                  </Route>

                  <Route
                    path='/terms'
                    element={<PrivateRoute allowedRoles={skipRoles([])} element={<TermsPage />} />}
                  />

                  <Route
                    path='/privacy'
                    element={<PrivateRoute allowedRoles={skipRoles([])} element={<PrivacyPage />} />}
                  />

                  <Route
                    path='/feedback'
                    element={<PrivateRoute allowedRoles={skipRoles([])} element={<FeedbackPage />} />}
                  />

                  <Route
                    path='/analyze/*'
                    element={<PrivateRoute allowedRoles={skipRoles([])} element={<AnalyzePage />} />}
                  />

                  <Route
                    path='/investigate/*'
                    element={<PrivateRoute allowedRoles={skipRoles([])} element={<InvestigatePage />} />}>
                    <Route
                      path='bookmarks'
                      element={<PrivateRoute allowedRoles={skipRoles(['VIEWER'])} element={<BookmarkListing />} />}
                    />
                    <Route
                      path='bookmarks/details'
                      element={
                        <PrivateRoute
                          allowedRoles={skipRoles([])}
                          element={<CombineDetailsView pageType={'details'} subStore={'main'} page={'vectors'} />}
                        />
                      }
                    />
                    <Route
                      path='whiteboards'
                      element={
                        <PrivateRoute allowedRoles={skipRoles([])} element={<InvestigateWhiteboardListView />} />
                      }
                    />

                    <Route
                      path='whiteboards/:whiteboardId'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<InvestigateWhiteboardPage />} />}
                    />
                    <Route
                      path='downloads'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<ExportList />} />}
                    />
                  </Route>

                  <Route
                    path='anticipatory-intelligence/*'
                    element={<PrivateRoute allowedRoles={skipRoles([])} element={<AnticipatoryIntelligencePage />} />}>
                    <Route path='scoreboard'>
                      <Route index element={<AnticipatoryIntelligence />} />
                      <Route
                        path='details'
                        element={
                          <CombineDetailsView
                            pageType={'details'}
                            subStore={'anticipatoryIntelligence'}
                            page={'anticipatoryIntelligence'}
                          />
                        }
                      />
                    </Route>

                    <Route path='details/:name' element={<AnticipatoryFeed />} />
                    <Route path='welcome/' element={<WelcomeScreen />} />
                  </Route>

                  <Route path='pulse' element={<OutletContainer pageTitle='Pendulum Pulse' />}>
                    <Route index element={<PrivateRoute allowedRoles={skipRoles([])} element={<PendulumPulse />} />} />
                  </Route>

                  <Route path='reports' element={<Reports />}>
                    <Route
                      index
                      element={
                        <PrivateRoute allowedRoles={skipRoles([])} element={<InvestigateReportsMainViewPage />} />
                      }
                    />
                    <Route
                      path=':reportId'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<InvesigateReportsDetailPage />} />}
                    />
                    <Route
                      path='details/:reportId'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<ReportDetailsPanel />} />}
                    />
                    <Route
                      path='create'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<CreateReport />} />}
                    />
                    <Route
                      path='edit/:reportId'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<EditReport />} />}
                    />
                  </Route>

                  <Route
                    path='flags'
                    element={<PrivateRoute allowedRoles={skipRoles(['VIEWER'])} element={<Flags />} />}
                  />
                  <Route
                    path='library'
                    element={
                      <OutletContainer
                        pageTitle={changeLabelTextBasedOnTheme('Vectors Library', isCorporateCommunicationsTheme)}
                      />
                    }>
                    <Route
                      index
                      element={<PrivateRoute allowedRoles={skipRoles(['VIEWER'])} element={<VectorsLibrary />} />}
                    />
                    <Route
                      path='details'
                      element={
                        <PrivateRoute
                          allowedRoles={skipRoles(['VIEWER'])}
                          element={<CombineDetailsView pageType={'details'} subStore={'vectors'} page={'library'} />}
                        />
                      }
                    />
                  </Route>
                  <Route path='assets' element={<OutletContainer pageTitle='Assets' />}>
                    <Route index element={<PrivateRoute allowedRoles={skipRoles([])} element={<Asset />} />} />
                    <Route
                      path='details'
                      element={
                        <PrivateRoute
                          allowedRoles={skipRoles([])}
                          element={<CombineDetailsView pageType={'details'} subStore={'assets'} page={'assets'} />}
                        />
                      }
                    />
                  </Route>
                  <Route path='brand' element={<OutletContainer pageTitle='Brand Dashboard' />}>
                    <Route index element={<PrivateRoute allowedRoles={skipRoles([])} element={<BrandDashboard />} />} />
                    <Route path='power-insights' element={<BrandDashboard />}>
                      {powerInsightsCommonRoutes({ loaderStore: loaderStore, subStore: 'assets', tenantsStore })}
                    </Route>
                  </Route>

                  <Route
                    path='vectors'
                    element={
                      <OutletContainer
                        pageTitle={changeLabelTextBasedOnTheme('Vectors', isCorporateCommunicationsTheme)}
                      />
                    }>
                    <Route
                      path='watchlist_builder'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<CreateEditWatchListStepper />} />}
                    />
                    <Route
                      path='watchlist_builder/:id'
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<CreateEditWatchListStepper />} />}
                    />
                    <Route
                      index
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<VectorsMainPage />} />}
                    />
                    <Route
                      path='details'
                      element={
                        <PrivateRoute
                          allowedRoles={skipRoles([])}
                          element={<CombineDetailsView pageType={'details'} subStore={'vectors'} page={'vectors'} />}
                        />
                      }
                    />

                    <Route path='create'>
                      <Route
                        index
                        element={
                          <PrivateRoute allowedRoles={skipRoles([])} element={<CreateNarrativeAndWatchList />} />
                        }
                      />
                      <Route
                        path='narrative_builder'
                        element={<PrivateRoute allowedRoles={skipRoles([])} element={<CreateNarrativeDetailsView />} />}
                      />

                      {!isCorporateCommunicationsTheme && (
                        <Route
                          path='watchlist_builder'
                          element={
                            <PrivateRoute allowedRoles={skipRoles([])} element={<CreateWatchlistDetailsView />} />
                          }
                        />
                      )}
                    </Route>
                  </Route>
                  <Route path='searches' element={<OutletContainer pageTitle='Searches Dashboard' />}>
                    <Route
                      index
                      element={<PrivateRoute allowedRoles={skipRoles([])} element={<VectorsDashboard />} />}
                    />
                    <Route path='power-insights' element={<VectorsDashboard />}>
                      {powerInsightsCommonRoutes({ loaderStore: loaderStore, subStore: 'vectors', tenantsStore })}
                    </Route>
                  </Route>

                  {isCorporateCommunicationsTheme && (
                    <Route path='influencers' element={<OutletContainer pageTitle={'Influencers'} />}>
                      <Route
                        index
                        element={<PrivateRoute allowedRoles={skipRoles([])} element={<VectorsMainPage />} />}
                      />
                      <Route
                        path='details'
                        element={
                          <PrivateRoute
                            allowedRoles={skipRoles([])}
                            element={<CombineDetailsView pageType={'details'} subStore={'vectors'} page={'vectors'} />}
                          />
                        }
                      />

                      <Route
                        path='watchlist_builder'
                        element={<PrivateRoute allowedRoles={skipRoles([])} element={<CreateEditWatchListStepper />} />}
                      />
                      <Route
                        path='watchlist_builder/:id'
                        element={<PrivateRoute allowedRoles={skipRoles([])} element={<CreateEditWatchListStepper />} />}
                      />
                      <Route path='create'>
                        <Route
                          index
                          element={
                            <PrivateRoute allowedRoles={skipRoles([])} element={<CreateNarrativeAndWatchList />} />
                          }
                        />

                        <Route
                          path='watchlist_builder'
                          element={
                            <PrivateRoute allowedRoles={skipRoles([])} element={<CreateWatchlistDetailsView />} />
                          }
                        />
                      </Route>
                    </Route>
                  )}

                  <Route path='/not-authorized' element={<h2>UnAuthorized</h2>} />

                  <Route
                    path='*'
                    element={
                      <PrivateRoute
                        allowedRoles={skipRoles([])}
                        element={
                          currentRole === 'SCORECARD_USER' ? (
                            <Navigate to='/anticipatory-intelligence/welcome' />
                          ) : (
                            <Navigate to='/assets' />
                          )
                        }
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
            <Agreement
              openModal={openAgreementModal}
              setOpenModal={setOpenAgreementModal}
              setOpenAgreementWelcomeModal={setOpenAgreementWelcomeModal}
            />
            <AgreementAdminModal openModal={openAgreementModal} setOpenModal={setOpenAgreementModal} />
            <AgreementWelcomeModal
              isModalOpen={openAgreementWelcomeModal}
              setIsModalClose={setOpenAgreementWelcomeModal}
            />
          </Router>
        </>
      )}
    </>
  )
})

export default App
