export const CountryDataCodes = {
  FJI: {
    ADM0_A3: 'FJI',
    ISO_A2: 'FJ',
    NAME: 'Fiji',
  },
  TZA: {
    ADM0_A3: 'TZA',
    ISO_A2: 'TZ',
    NAME: 'Tanzania',
  },
  SAH: {
    ADM0_A3: 'SAH',
    ISO_A2: 'EH',
    NAME: 'W. Sahara',
  },
  CAN: {
    ADM0_A3: 'CAN',
    ISO_A2: 'CA',
    NAME: 'Canada',
  },
  USA: {
    ADM0_A3: 'USA',
    ISO_A2: 'US',
    NAME: 'United States of America',
  },
  KAZ: {
    ADM0_A3: 'KAZ',
    ISO_A2: 'KZ',
    NAME: 'Kazakhstan',
  },
  UZB: {
    ADM0_A3: 'UZB',
    ISO_A2: 'UZ',
    NAME: 'Uzbekistan',
  },
  PNG: {
    ADM0_A3: 'PNG',
    ISO_A2: 'PG',
    NAME: 'Papua New Guinea',
  },
  IDN: {
    ADM0_A3: 'IDN',
    ISO_A2: 'ID',
    NAME: 'Indonesia',
  },
  ARG: {
    ADM0_A3: 'ARG',
    ISO_A2: 'AR',
    NAME: 'Argentina',
  },
  CHL: {
    ADM0_A3: 'CHL',
    ISO_A2: 'CL',
    NAME: 'Chile',
  },
  COD: {
    ADM0_A3: 'COD',
    ISO_A2: 'CD',
    NAME: 'Dem. Rep. Congo',
  },
  SOM: {
    ADM0_A3: 'SOM',
    ISO_A2: 'SO',
    NAME: 'Somalia',
  },
  KEN: {
    ADM0_A3: 'KEN',
    ISO_A2: 'KE',
    NAME: 'Kenya',
  },
  SDN: {
    ADM0_A3: 'SDN',
    ISO_A2: 'SD',
    NAME: 'Sudan',
  },
  TCD: {
    ADM0_A3: 'TCD',
    ISO_A2: 'TD',
    NAME: 'Chad',
  },
  HTI: {
    ADM0_A3: 'HTI',
    ISO_A2: 'HT',
    NAME: 'Haiti',
  },
  DOM: {
    ADM0_A3: 'DOM',
    ISO_A2: 'DO',
    NAME: 'Dominican Rep.',
  },
  RUS: {
    ADM0_A3: 'RUS',
    ISO_A2: 'RU',
    NAME: 'Russia',
  },
  BHS: {
    ADM0_A3: 'BHS',
    ISO_A2: 'BS',
    NAME: 'Bahamas',
  },
  FLK: {
    ADM0_A3: 'FLK',
    ISO_A2: 'FK',
    NAME: 'Falkland Is.',
  },
  NOR: {
    ADM0_A3: 'NOR',
    ISO_A2: '-99',
    NAME: 'Norway',
  },
  GRL: {
    ADM0_A3: 'GRL',
    ISO_A2: 'GL',
    NAME: 'Greenland',
  },
  ATF: {
    ADM0_A3: 'ATF',
    ISO_A2: 'TF',
    NAME: 'Fr. S. Antarctic Lands',
  },
  TLS: {
    ADM0_A3: 'TLS',
    ISO_A2: 'TL',
    NAME: 'Timor-Leste',
  },
  ZAF: {
    ADM0_A3: 'ZAF',
    ISO_A2: 'ZA',
    NAME: 'South Africa',
  },
  LSO: {
    ADM0_A3: 'LSO',
    ISO_A2: 'LS',
    NAME: 'Lesotho',
  },
  MEX: {
    ADM0_A3: 'MEX',
    ISO_A2: 'MX',
    NAME: 'Mexico',
  },
  URY: {
    ADM0_A3: 'URY',
    ISO_A2: 'UY',
    NAME: 'Uruguay',
  },
  BRA: {
    ADM0_A3: 'BRA',
    ISO_A2: 'BR',
    NAME: 'Brazil',
  },
  BOL: {
    ADM0_A3: 'BOL',
    ISO_A2: 'BO',
    NAME: 'Bolivia',
  },
  PER: {
    ADM0_A3: 'PER',
    ISO_A2: 'PE',
    NAME: 'Peru',
  },
  COL: {
    ADM0_A3: 'COL',
    ISO_A2: 'CO',
    NAME: 'Colombia',
  },
  PAN: {
    ADM0_A3: 'PAN',
    ISO_A2: 'PA',
    NAME: 'Panama',
  },
  CRI: {
    ADM0_A3: 'CRI',
    ISO_A2: 'CR',
    NAME: 'Costa Rica',
  },
  NIC: {
    ADM0_A3: 'NIC',
    ISO_A2: 'NI',
    NAME: 'Nicaragua',
  },
  HND: {
    ADM0_A3: 'HND',
    ISO_A2: 'HN',
    NAME: 'Honduras',
  },
  SLV: {
    ADM0_A3: 'SLV',
    ISO_A2: 'SV',
    NAME: 'El Salvador',
  },
  GTM: {
    ADM0_A3: 'GTM',
    ISO_A2: 'GT',
    NAME: 'Guatemala',
  },
  BLZ: {
    ADM0_A3: 'BLZ',
    ISO_A2: 'BZ',
    NAME: 'Belize',
  },
  VEN: {
    ADM0_A3: 'VEN',
    ISO_A2: 'VE',
    NAME: 'Venezuela',
  },
  GUY: {
    ADM0_A3: 'GUY',
    ISO_A2: 'GY',
    NAME: 'Guyana',
  },
  SUR: {
    ADM0_A3: 'SUR',
    ISO_A2: 'SR',
    NAME: 'Suriname',
  },
  FRA: {
    ADM0_A3: 'FRA',
    ISO_A2: '-99',
    NAME: 'France',
  },
  ECU: {
    ADM0_A3: 'ECU',
    ISO_A2: 'EC',
    NAME: 'Ecuador',
  },
  PRI: {
    ADM0_A3: 'PRI',
    ISO_A2: 'PR',
    NAME: 'Puerto Rico',
  },
  JAM: {
    ADM0_A3: 'JAM',
    ISO_A2: 'JM',
    NAME: 'Jamaica',
  },
  CUB: {
    ADM0_A3: 'CUB',
    ISO_A2: 'CU',
    NAME: 'Cuba',
  },
  ZWE: {
    ADM0_A3: 'ZWE',
    ISO_A2: 'ZW',
    NAME: 'Zimbabwe',
  },
  BWA: {
    ADM0_A3: 'BWA',
    ISO_A2: 'BW',
    NAME: 'Botswana',
  },
  NAM: {
    ADM0_A3: 'NAM',
    ISO_A2: 'NA',
    NAME: 'Namibia',
  },
  SEN: {
    ADM0_A3: 'SEN',
    ISO_A2: 'SN',
    NAME: 'Senegal',
  },
  MLI: {
    ADM0_A3: 'MLI',
    ISO_A2: 'ML',
    NAME: 'Mali',
  },
  MRT: {
    ADM0_A3: 'MRT',
    ISO_A2: 'MR',
    NAME: 'Mauritania',
  },
  BEN: {
    ADM0_A3: 'BEN',
    ISO_A2: 'BJ',
    NAME: 'Benin',
  },
  NER: {
    ADM0_A3: 'NER',
    ISO_A2: 'NE',
    NAME: 'Niger',
  },
  NGA: {
    ADM0_A3: 'NGA',
    ISO_A2: 'NG',
    NAME: 'Nigeria',
  },
  CMR: {
    ADM0_A3: 'CMR',
    ISO_A2: 'CM',
    NAME: 'Cameroon',
  },
  TGO: {
    ADM0_A3: 'TGO',
    ISO_A2: 'TG',
    NAME: 'Togo',
  },
  GHA: {
    ADM0_A3: 'GHA',
    ISO_A2: 'GH',
    NAME: 'Ghana',
  },
  CIV: {
    ADM0_A3: 'CIV',
    ISO_A2: 'CI',
    NAME: "Côte d'Ivoire",
  },
  GIN: {
    ADM0_A3: 'GIN',
    ISO_A2: 'GN',
    NAME: 'Guinea',
  },
  GNB: {
    ADM0_A3: 'GNB',
    ISO_A2: 'GW',
    NAME: 'Guinea-Bissau',
  },
  LBR: {
    ADM0_A3: 'LBR',
    ISO_A2: 'LR',
    NAME: 'Liberia',
  },
  SLE: {
    ADM0_A3: 'SLE',
    ISO_A2: 'SL',
    NAME: 'Sierra Leone',
  },
  BFA: {
    ADM0_A3: 'BFA',
    ISO_A2: 'BF',
    NAME: 'Burkina Faso',
  },
  CAF: {
    ADM0_A3: 'CAF',
    ISO_A2: 'CF',
    NAME: 'Central African Rep.',
  },
  COG: {
    ADM0_A3: 'COG',
    ISO_A2: 'CG',
    NAME: 'Congo',
  },
  GAB: {
    ADM0_A3: 'GAB',
    ISO_A2: 'GA',
    NAME: 'Gabon',
  },
  GNQ: {
    ADM0_A3: 'GNQ',
    ISO_A2: 'GQ',
    NAME: 'Eq. Guinea',
  },
  ZMB: {
    ADM0_A3: 'ZMB',
    ISO_A2: 'ZM',
    NAME: 'Zambia',
  },
  MWI: {
    ADM0_A3: 'MWI',
    ISO_A2: 'MW',
    NAME: 'Malawi',
  },
  MOZ: {
    ADM0_A3: 'MOZ',
    ISO_A2: 'MZ',
    NAME: 'Mozambique',
  },
  SWZ: {
    ADM0_A3: 'SWZ',
    ISO_A2: 'SZ',
    NAME: 'eSwatini',
  },
  AGO: {
    ADM0_A3: 'AGO',
    ISO_A2: 'AO',
    NAME: 'Angola',
  },
  BDI: {
    ADM0_A3: 'BDI',
    ISO_A2: 'BI',
    NAME: 'Burundi',
  },
  ISR: {
    ADM0_A3: 'ISR',
    ISO_A2: 'IL',
    NAME: 'Israel',
  },
  LBN: {
    ADM0_A3: 'LBN',
    ISO_A2: 'LB',
    NAME: 'Lebanon',
  },
  MDG: {
    ADM0_A3: 'MDG',
    ISO_A2: 'MG',
    NAME: 'Madagascar',
  },
  PSX: {
    ADM0_A3: 'PSX',
    ISO_A2: 'PS',
    NAME: 'Palestine',
  },
  GMB: {
    ADM0_A3: 'GMB',
    ISO_A2: 'GM',
    NAME: 'Gambia',
  },
  TUN: {
    ADM0_A3: 'TUN',
    ISO_A2: 'TN',
    NAME: 'Tunisia',
  },
  DZA: {
    ADM0_A3: 'DZA',
    ISO_A2: 'DZ',
    NAME: 'Algeria',
  },
  JOR: {
    ADM0_A3: 'JOR',
    ISO_A2: 'JO',
    NAME: 'Jordan',
  },
  ARE: {
    ADM0_A3: 'ARE',
    ISO_A2: 'AE',
    NAME: 'United Arab Emirates',
  },
  QAT: {
    ADM0_A3: 'QAT',
    ISO_A2: 'QA',
    NAME: 'Qatar',
  },
  KWT: {
    ADM0_A3: 'KWT',
    ISO_A2: 'KW',
    NAME: 'Kuwait',
  },
  IRQ: {
    ADM0_A3: 'IRQ',
    ISO_A2: 'IQ',
    NAME: 'Iraq',
  },
  OMN: {
    ADM0_A3: 'OMN',
    ISO_A2: 'OM',
    NAME: 'Oman',
  },
  VUT: {
    ADM0_A3: 'VUT',
    ISO_A2: 'VU',
    NAME: 'Vanuatu',
  },
  KHM: {
    ADM0_A3: 'KHM',
    ISO_A2: 'KH',
    NAME: 'Cambodia',
  },
  THA: {
    ADM0_A3: 'THA',
    ISO_A2: 'TH',
    NAME: 'Thailand',
  },
  LAO: {
    ADM0_A3: 'LAO',
    ISO_A2: 'LA',
    NAME: 'Laos',
  },
  MMR: {
    ADM0_A3: 'MMR',
    ISO_A2: 'MM',
    NAME: 'Myanmar',
  },
  VNM: {
    ADM0_A3: 'VNM',
    ISO_A2: 'VN',
    NAME: 'Vietnam',
  },
  PRK: {
    ADM0_A3: 'PRK',
    ISO_A2: 'KP',
    NAME: 'North Korea',
  },
  KOR: {
    ADM0_A3: 'KOR',
    ISO_A2: 'KR',
    NAME: 'South Korea',
  },
  MNG: {
    ADM0_A3: 'MNG',
    ISO_A2: 'MN',
    NAME: 'Mongolia',
  },
  IND: {
    ADM0_A3: 'IND',
    ISO_A2: 'IN',
    NAME: 'India',
  },
  BGD: {
    ADM0_A3: 'BGD',
    ISO_A2: 'BD',
    NAME: 'Bangladesh',
  },
  BTN: {
    ADM0_A3: 'BTN',
    ISO_A2: 'BT',
    NAME: 'Bhutan',
  },
  NPL: {
    ADM0_A3: 'NPL',
    ISO_A2: 'NP',
    NAME: 'Nepal',
  },
  PAK: {
    ADM0_A3: 'PAK',
    ISO_A2: 'PK',
    NAME: 'Pakistan',
  },
  AFG: {
    ADM0_A3: 'AFG',
    ISO_A2: 'AF',
    NAME: 'Afghanistan',
  },
  TJK: {
    ADM0_A3: 'TJK',
    ISO_A2: 'TJ',
    NAME: 'Tajikistan',
  },
  KGZ: {
    ADM0_A3: 'KGZ',
    ISO_A2: 'KG',
    NAME: 'Kyrgyzstan',
  },
  TKM: {
    ADM0_A3: 'TKM',
    ISO_A2: 'TM',
    NAME: 'Turkmenistan',
  },
  IRN: {
    ADM0_A3: 'IRN',
    ISO_A2: 'IR',
    NAME: 'Iran',
  },
  SYR: {
    ADM0_A3: 'SYR',
    ISO_A2: 'SY',
    NAME: 'Syria',
  },
  ARM: {
    ADM0_A3: 'ARM',
    ISO_A2: 'AM',
    NAME: 'Armenia',
  },
  SWE: {
    ADM0_A3: 'SWE',
    ISO_A2: 'SE',
    NAME: 'Sweden',
  },
  BLR: {
    ADM0_A3: 'BLR',
    ISO_A2: 'BY',
    NAME: 'Belarus',
  },
  UKR: {
    ADM0_A3: 'UKR',
    ISO_A2: 'UA',
    NAME: 'Ukraine',
  },
  POL: {
    ADM0_A3: 'POL',
    ISO_A2: 'PL',
    NAME: 'Poland',
  },
  AUT: {
    ADM0_A3: 'AUT',
    ISO_A2: 'AT',
    NAME: 'Austria',
  },
  HUN: {
    ADM0_A3: 'HUN',
    ISO_A2: 'HU',
    NAME: 'Hungary',
  },
  MDA: {
    ADM0_A3: 'MDA',
    ISO_A2: 'MD',
    NAME: 'Moldova',
  },
  ROU: {
    ADM0_A3: 'ROU',
    ISO_A2: 'RO',
    NAME: 'Romania',
  },
  LTU: {
    ADM0_A3: 'LTU',
    ISO_A2: 'LT',
    NAME: 'Lithuania',
  },
  LVA: {
    ADM0_A3: 'LVA',
    ISO_A2: 'LV',
    NAME: 'Latvia',
  },
  EST: {
    ADM0_A3: 'EST',
    ISO_A2: 'EE',
    NAME: 'Estonia',
  },
  DEU: {
    ADM0_A3: 'DEU',
    ISO_A2: 'DE',
    NAME: 'Germany',
  },
  BGR: {
    ADM0_A3: 'BGR',
    ISO_A2: 'BG',
    NAME: 'Bulgaria',
  },
  GRC: {
    ADM0_A3: 'GRC',
    ISO_A2: 'GR',
    NAME: 'Greece',
  },
  TUR: {
    ADM0_A3: 'TUR',
    ISO_A2: 'TR',
    NAME: 'Turkey',
  },
  ALB: {
    ADM0_A3: 'ALB',
    ISO_A2: 'AL',
    NAME: 'Albania',
  },
  HRV: {
    ADM0_A3: 'HRV',
    ISO_A2: 'HR',
    NAME: 'Croatia',
  },
  CHE: {
    ADM0_A3: 'CHE',
    ISO_A2: 'CH',
    NAME: 'Switzerland',
  },
  LUX: {
    ADM0_A3: 'LUX',
    ISO_A2: 'LU',
    NAME: 'Luxembourg',
  },
  BEL: {
    ADM0_A3: 'BEL',
    ISO_A2: 'BE',
    NAME: 'Belgium',
  },
  NLD: {
    ADM0_A3: 'NLD',
    ISO_A2: 'NL',
    NAME: 'Netherlands',
  },
  PRT: {
    ADM0_A3: 'PRT',
    ISO_A2: 'PT',
    NAME: 'Portugal',
  },
  ESP: {
    ADM0_A3: 'ESP',
    ISO_A2: 'ES',
    NAME: 'Spain',
  },
  IRL: {
    ADM0_A3: 'IRL',
    ISO_A2: 'IE',
    NAME: 'Ireland',
  },
  NCL: {
    ADM0_A3: 'NCL',
    ISO_A2: 'NC',
    NAME: 'New Caledonia',
  },
  SLB: {
    ADM0_A3: 'SLB',
    ISO_A2: 'SB',
    NAME: 'Solomon Is.',
  },
  NZL: {
    ADM0_A3: 'NZL',
    ISO_A2: 'NZ',
    NAME: 'New Zealand',
  },
  AUS: {
    ADM0_A3: 'AUS',
    ISO_A2: 'AU',
    NAME: 'Australia',
  },
  LKA: {
    ADM0_A3: 'LKA',
    ISO_A2: 'LK',
    NAME: 'Sri Lanka',
  },
  CHN: {
    ADM0_A3: 'CHN',
    ISO_A2: 'CN',
    NAME: 'China',
  },
  TWN: {
    ADM0_A3: 'TWN',
    ISO_A2: 'CN-TW',
    NAME: 'Taiwan',
  },
  ITA: {
    ADM0_A3: 'ITA',
    ISO_A2: 'IT',
    NAME: 'Italy',
  },
  DNK: {
    ADM0_A3: 'DNK',
    ISO_A2: 'DK',
    NAME: 'Denmark',
  },
  GBR: {
    ADM0_A3: 'GBR',
    ISO_A2: 'GB',
    NAME: 'United Kingdom',
  },
  ISL: {
    ADM0_A3: 'ISL',
    ISO_A2: 'IS',
    NAME: 'Iceland',
  },
  AZE: {
    ADM0_A3: 'AZE',
    ISO_A2: 'AZ',
    NAME: 'Azerbaijan',
  },
  GEO: {
    ADM0_A3: 'GEO',
    ISO_A2: 'GE',
    NAME: 'Georgia',
  },
  PHL: {
    ADM0_A3: 'PHL',
    ISO_A2: 'PH',
    NAME: 'Philippines',
  },
  MYS: {
    ADM0_A3: 'MYS',
    ISO_A2: 'MY',
    NAME: 'Malaysia',
  },
  BRN: {
    ADM0_A3: 'BRN',
    ISO_A2: 'BN',
    NAME: 'Brunei',
  },
  SVN: {
    ADM0_A3: 'SVN',
    ISO_A2: 'SI',
    NAME: 'Slovenia',
  },
  FIN: {
    ADM0_A3: 'FIN',
    ISO_A2: 'FI',
    NAME: 'Finland',
  },
  SVK: {
    ADM0_A3: 'SVK',
    ISO_A2: 'SK',
    NAME: 'Slovakia',
  },
  CZE: {
    ADM0_A3: 'CZE',
    ISO_A2: 'CZ',
    NAME: 'Czechia',
  },
  ERI: {
    ADM0_A3: 'ERI',
    ISO_A2: 'ER',
    NAME: 'Eritrea',
  },
  JPN: {
    ADM0_A3: 'JPN',
    ISO_A2: 'JP',
    NAME: 'Japan',
  },
  PRY: {
    ADM0_A3: 'PRY',
    ISO_A2: 'PY',
    NAME: 'Paraguay',
  },
  YEM: {
    ADM0_A3: 'YEM',
    ISO_A2: 'YE',
    NAME: 'Yemen',
  },
  SAU: {
    ADM0_A3: 'SAU',
    ISO_A2: 'SA',
    NAME: 'Saudi Arabia',
  },
  CYN: {
    ADM0_A3: 'CYN',
    ISO_A2: '-99',
    NAME: 'N. Cyprus',
  },
  CYP: {
    ADM0_A3: 'CYP',
    ISO_A2: 'CY',
    NAME: 'Cyprus',
  },
  MAR: {
    ADM0_A3: 'MAR',
    ISO_A2: 'MA',
    NAME: 'Morocco',
  },
  EGY: {
    ADM0_A3: 'EGY',
    ISO_A2: 'EG',
    NAME: 'Egypt',
  },
  LBY: {
    ADM0_A3: 'LBY',
    ISO_A2: 'LY',
    NAME: 'Libya',
  },
  ETH: {
    ADM0_A3: 'ETH',
    ISO_A2: 'ET',
    NAME: 'Ethiopia',
  },
  DJI: {
    ADM0_A3: 'DJI',
    ISO_A2: 'DJ',
    NAME: 'Djibouti',
  },
  SOL: {
    ADM0_A3: 'SOL',
    ISO_A2: '-99',
    NAME: 'Somaliland',
  },
  UGA: {
    ADM0_A3: 'UGA',
    ISO_A2: 'UG',
    NAME: 'Uganda',
  },
  RWA: {
    ADM0_A3: 'RWA',
    ISO_A2: 'RW',
    NAME: 'Rwanda',
  },
  BIH: {
    ADM0_A3: 'BIH',
    ISO_A2: 'BA',
    NAME: 'Bosnia and Herz.',
  },
  MKD: {
    ADM0_A3: 'MKD',
    ISO_A2: 'MK',
    NAME: 'North Macedonia',
  },
  SRB: {
    ADM0_A3: 'SRB',
    ISO_A2: 'RS',
    NAME: 'Serbia',
  },
  MNE: {
    ADM0_A3: 'MNE',
    ISO_A2: 'ME',
    NAME: 'Montenegro',
  },
  KOS: {
    ADM0_A3: 'KOS',
    ISO_A2: '-99',
    NAME: 'Kosovo',
  },
  TTO: {
    ADM0_A3: 'TTO',
    ISO_A2: 'TT',
    NAME: 'Trinidad and Tobago',
  },
  SDS: {
    ADM0_A3: 'SDS',
    ISO_A2: 'SS',
    NAME: 'S. Sudan',
  },
}
