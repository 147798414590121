import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'

interface Props {
  narrativeId: number[]
  startDate?: string
  endDate?: string
  name?: string
}
export async function getNarrativeCount({ narrativeId, startDate, endDate, name }: Props) {
  try {
    const date = startDate?.length && endDate?.length ? `start_date=${startDate}&end_date=${endDate}` : ''

    let url = process.env.REACT_APP_SEARCH_API + `${ROUTES.narrativeCount}?narrative_ids=${narrativeId}&${date}`

    const { data } = await axios.get(url)
    data['name'] = name || ''
    data['ids'] = narrativeId.join(',')
    return data
  } catch (error) {
    LogService.error({ message: 'getNarrativeCount error', error })
  }
}
