import './commonStyles.scss'

export default function StepsBanner({
  heading,
  description,
  icon: Icon,
}: {
  heading: string
  description: string
  icon: any
}) {
  return (
    <div className='steps-banner'>
      <div className='icon'>
        <Icon />
      </div>
      <h5>{heading}</h5>
      <p>{description}</p>
    </div>
  )
}
