import { useMeasure } from 'react-use'
import { Area, AreaChart as RechartsAreaChart } from 'recharts'
import { EMPTY_GRAPH_DATA_POINTS } from 'settings/settings'

import './AreaChart.scss'

interface Props {
  data: { metric_value: number }[]
  mode: '1' | '2' | '3' | '4' | '5'
  color?: string
}

export const AreaChart = ({ data, mode, color = '#D6B77B' }: Props) => {
  const [measureRef, { width: responsiveContainerWidth }] = useMeasure<HTMLDivElement>()

  const grayColor = '#AEABA5'
  const goldColor = '#D6B77B'

  const graphColor = color ? color : goldColor

  const disabled = data.length === 0

  const correctColor = !disabled ? graphColor : grayColor

  const actualData = disabled ? EMPTY_GRAPH_DATA_POINTS : data

  const variants = {
    '1': { width: 200, height: 40 },
    '2': { width: 80, height: 52 },
    '3': { width: 118, height: 37 },
    '4': { width: 250, height: 40 },
    '5': { width: responsiveContainerWidth, height: 30 }, // for new executive details page
  }

  let sizes = variants[mode]

  return (
    <div ref={measureRef}>
      <RechartsAreaChart data={actualData} width={sizes.width} height={sizes.height} style={{ cursor: 'pointer' }}>
        <defs>
          <linearGradient id='colorValue' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor={graphColor} stopOpacity={0.6} />
            <stop offset='95%' stopColor={graphColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          dataKey='metric_value'
          dot={false}
          type='monotone'
          stroke={correctColor}
          strokeWidth={2}
          fill={!disabled ? 'url(#colorValue)' : '#00000000'}
        />
      </RechartsAreaChart>
    </div>
  )
}
